import { createContext, useMemo, useRef } from 'react';
import { CellMeasurerCache } from 'react-virtualized';

export type ResponsesCellsStateContextType = {
  cellCache: CellMeasurerCache;
  previousWidthRef: React.MutableRefObject<number>;
  clearCache: (rowIndex?: number, columnIndex?: number) => void;
};

export const ResponsesCellsStateContext = createContext<ResponsesCellsStateContextType>({
  clearCache: () => {},
  previousWidthRef: { current: 0 },
  cellCache: new CellMeasurerCache({}),
});

export const ResponsesCellsStateContextProvider = ({ children }: React.PropsWithChildren<object>) => {
  const previousWidthRef = useRef<number>(0);

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 100,
        minHeight: 70,
        fixedWidth: true,
      }),
    [],
  );

  function handleClearCache(rowIndex?: number, columnIndex?: number) {
    // clear all
    if (rowIndex == undefined && columnIndex == undefined) {
      cache.clearAll();
      return;
    }
    // clear row
    cache.clear(rowIndex ?? 0, columnIndex ?? 0);
  }

  return (
    <ResponsesCellsStateContext.Provider
      value={{
        previousWidthRef,
        cellCache: cache,
        clearCache: handleClearCache,
      }}
    >
      {children}
    </ResponsesCellsStateContext.Provider>
  );
};
