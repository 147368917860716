import { useContext, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteAuth } from '../../../store/thunks/connectors';
import { NOTIFICATIONS } from '../../Notification/constants';
import { SEVERITY } from '../../Notification/types';
import { NotificationsContext } from '../../Notification/NotificationsContext';
import { clearLastDeletedAuth, closeDeleteAuthModal } from '../../../store/actions/connectors';

import DeleteAuthModal from './DeleteAuthModal';

function DeleteAuthModalContainer() {
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const {
    deleteAuth: { submitting: isSubmitting, open: isOpen, lastDeletedId, lastDeletedSuccess },
  } = useAppSelector((state) => state.connectors);
  const dispatch = useAppDispatch();

  function onDelete() {
    dispatch(deleteAuth());
  }

  useEffect(() => {
    if (!lastDeletedId) {
      return;
    }

    if (lastDeletedSuccess) {
      removeNotification(NOTIFICATIONS.ConnectorsAuthDeleteSuccess);
      addNotification({
        id: NOTIFICATIONS.ConnectorsConnectionDeleteSuccess,
        title: 'Authentication deleted successfully.',
        severity: SEVERITY.SUCCESS,
        persistent: false,
      });
    } else {
      addNotification({
        id: NOTIFICATIONS.ConnectorsAuthDeleteError,
        title: 'Failed to delete authentication.',
        severity: SEVERITY.ERROR,
        persistent: true,
      });
    }
    dispatch(clearLastDeletedAuth());
  }, [addNotification, dispatch, lastDeletedId, lastDeletedSuccess, removeNotification]);

  function onClose() {
    dispatch(closeDeleteAuthModal());
  }

  return <DeleteAuthModal isOpen={isOpen} isSubmitting={isSubmitting} onClose={onClose} onDelete={onDelete} />;
}

export default DeleteAuthModalContainer;
