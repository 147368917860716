import React from 'react';
import { TextOverflowEllipsis } from '../../../../UI';
import { AnswerItemTextContainer, ChildLabel, ParentLabel } from './style';

interface IAnswerItem {
  text: string;
  selected: boolean;
  clickable: boolean;
  disabled: boolean;
  isParent: boolean;
  onClick: () => void;
}
export function AnswerItem({ text, selected, clickable, onClick, disabled, isParent }: IAnswerItem) {
  if (clickable) {
    return <AnswerItemButton text={text} selected={selected} onClick={onClick} disabled={disabled} isParent={isParent} />;
  }
  return <AnswerItemText text={text} />;
}

interface IAnswerItemText {
  text: string;
}
export function AnswerItemText({ text }: IAnswerItemText) {
  return (
    <AnswerItemTextContainer>
      <TextOverflowEllipsis data-tooltip-id='base-tooltip' data-tooltip-content={text}>
        {text}
      </TextOverflowEllipsis>
    </AnswerItemTextContainer>
  );
}

interface IAnswerItemButton {
  text: string;
  selected: boolean;
  disabled: boolean;
  isParent: boolean;
  onClick: () => void;
}
export function AnswerItemButton({ text, onClick, selected, disabled, isParent }: IAnswerItemButton) {
  return (
    <AnswerItemTextContainer className='label-button-wrapper' data-tooltip-id='base-tooltip' data-tooltip-content={text}>
      {renderLabel()}
    </AnswerItemTextContainer>
  );

  function renderLabel() {
    if (isParent) {
      return (
        <ParentLabel $isDisabled={disabled} $isSelected={selected} onClick={onClick}>
          <TextOverflowEllipsis>{text}</TextOverflowEllipsis>
        </ParentLabel>
      );
    }

    return (
      <ChildLabel $isDisabled={disabled} $isSelected={selected} onClick={onClick}>
        <TextOverflowEllipsis>{text}</TextOverflowEllipsis>
      </ChildLabel>
    );
  }
}
