import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Box from '@material-ui/core/Box';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

import { dashboardUpdateWidget } from '../../store/actions/dashboard';
import { prepareBarsWidgetConfig, prepareTrendsWidgetConfig } from './lib';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import * as api from '../tools/api';
import useFilters from '../../hooks/useFilters';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { getAppliedFiltersCount } from '../UI/lib';
import { WIDGET_TYPE } from '../../constants';
import { dashboardCloneWidget, dashboardRemoveWidget } from '../../store/thunks/dashboard';

const WidgetMenuItem = styled(MenuItem)`
  &.Mui-selected {
    font-weight: bold;
    background-color: transparent;
  }
`;

export const WidgetMenuWithBarsOption = (props) => {
  return <WidgetMenu {...props} enableBarsOption />;
};

export const WidgetMenuWithTrendsOption = (props) => {
  return <WidgetMenu {...props} enableTrendsOption />;
};

const WidgetMenu = ({ config, onEdit, onWidgetTypeChange, enableBarsOption, enableTrendsOption, groupTitle, unfixable }) => {
  const [filters] = useFilters();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const dataSourceId = config.settings.dataSources[0].id;
  const dataSource = useSelector((state) => state.dashboard.dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceId));
  const dashboardDataSource = useSelector((state) =>
    state.dashboard.dashboard.dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceId),
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dataViewSelectorsDisabled = getAppliedFiltersCount(filters) === 0;

  const disabledDataViewsTooltip = dataViewSelectorsDisabled ? 'Apply filters to select different data views' : '';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    eventsTracker.track(EVENTS.WIDGET_EDIT, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
    handleClose();
    onEdit();
  };

  const handleDuplicateClick = () => {
    eventsTracker.track(EVENTS.WIDGET_DUPLICATE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      'Widget Filter': !!config.base.filter,
    });
    dispatch(dashboardCloneWidget(config));
    handleClose();
  };

  const handleRemoveClick = () => {
    eventsTracker.track(EVENTS.WIDGET_REMOVE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
    dispatch(dashboardRemoveWidget(config.base.id));
  };

  const handleTrendsClick = () => {
    const trendsConfig = prepareTrendsWidgetConfig(config);
    eventsTracker.track(EVENTS.WIDGET_CHANGE_TYPE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Old Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      'New Widget Type': capitalize(WIDGET_TYPE.TRENDS),
    });
    dispatch(dashboardUpdateWidget(trendsConfig));
    api.updateWidget(trendsConfig);
    onWidgetTypeChange(WIDGET_TYPE.TRENDS);
  };

  const handleBarsClick = () => {
    const barsConfig = prepareBarsWidgetConfig(config, dataSource, dashboardDataSource);
    eventsTracker.track(EVENTS.WIDGET_CHANGE_TYPE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Old Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      'New Widget Type': barsConfig.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(barsConfig.base.type) : 'Open-ended',
    });
    dispatch(dashboardUpdateWidget(barsConfig));
    api.updateWidget(barsConfig);
    onWidgetTypeChange(dashboardDataSource.type);
  };

  const handleDataViewClick = (value) => {
    eventsTracker.track(EVENTS.WIDGET_CHANGE_DATA_VIEW, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      'Data View': value ? 'Average' : 'Compare',
    });
    const newConfig = {
      ...config,
      settings: { ...config.settings, merge: value },
    };
    dispatch(dashboardUpdateWidget(newConfig));
    api.updateWidget(newConfig);
    handleClose();
  };

  return (
    <>
      <MoreVertIcon onClick={handleClick} style={{ cursor: 'pointer', marginTop: '2px' }} data-testid='widget-menu-button' />
      <Menu data-testid='widget-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <WidgetMenuItem onClick={handleEdit} disabled={unfixable}>
          <EditIcon fontSize='small' /> Edit
        </WidgetMenuItem>
        <WidgetMenuItem onClick={handleDuplicateClick} disabled={unfixable}>
          <FileCopyOutlinedIcon fontSize='small' /> Duplicate
        </WidgetMenuItem>
        <WidgetMenuItem onClick={() => setShowConfirmation(true)} data-testid='widget-menu-item-remove'>
          <DeleteForeverIcon fontSize='small' /> Remove
        </WidgetMenuItem>
        <Divider />
        <ListSubheader>Change Chart Type</ListSubheader>
        <WidgetMenuItem onClick={handleTrendsClick} disabled={!enableTrendsOption || unfixable} data-testid='widget-menu-item-trend'>
          <TimelineIcon fontSize='small' /> Trend
        </WidgetMenuItem>
        <WidgetMenuItem onClick={handleBarsClick} disabled={!enableBarsOption || unfixable} data-testid='widget-menu-item-bars'>
          <BarChartIcon fontSize='small' /> Bars
        </WidgetMenuItem>
        <Divider />
        <ListSubheader>Change Data View</ListSubheader>
        <Box data-tooltip-id='base-tooltip' data-tooltip-content={disabledDataViewsTooltip}>
          <WidgetMenuItem
            onClick={() => handleDataViewClick(false)}
            disabled={dataViewSelectorsDisabled || unfixable}
            selected={!config.settings.merge}
            data-testid='widget-menu-item-compare'
          >
            <SyncAltIcon fontSize='small' /> Compare
          </WidgetMenuItem>
        </Box>
        <Box data-tooltip-id='base-tooltip' data-tooltip-content={disabledDataViewsTooltip}>
          <WidgetMenuItem
            onClick={() => handleDataViewClick(true)}
            disabled={dataViewSelectorsDisabled || unfixable}
            selected={config.settings.merge}
            data-testid='widget-menu-item-average'
          >
            <MergeTypeIcon fontSize='small' /> Average
          </WidgetMenuItem>
        </Box>
      </Menu>
      <ConfirmationDialog
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleRemoveClick}
        open={showConfirmation}
        title='Remove Widget'
        content={`Widget "${config.base.title}" will be removed. All settings will be lost.`}
      />
    </>
  );
};

export default WidgetMenu;
