import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';

import { SEVERITY } from '../../../Notification/types';

const AlertContainer = styled.div<{ severity: SEVERITY }>`
  width: 100%;
  padding: 16px;
  border-radius: 4px;

  display: flex;
  gap: 8px;
  ${(props) => props.severity === SEVERITY.INFO && `background-color: var(--light-purple-50-transparency);`}
  ${(props) => props.severity === SEVERITY.WARNING && `background-color: var(--light-yellow);`}
`;

const AlertTitle = styled.div<{ severity: SEVERITY }>`
  ${(props) => props.severity === SEVERITY.INFO && `color: var(--dark-purple);`}
  ${(props) => props.severity === SEVERITY.WARNING && `color: var(--dark-brown);`}
`;

const Info = styled(InfoIcon)`
  width: 24px;
  height: 24px;
  margin: -0.1em 0;
`;

const Warning = styled(WarningIcon)`
  width: 24px;
  height: 24px;
  margin: -0.1em 0;
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

interface IAlert {
  title: React.ReactNode;
  severity?: SEVERITY;
}

function Alert({ title, children, severity = SEVERITY.INFO }: React.PropsWithChildren<IAlert>) {
  return (
    <AlertContainer severity={severity}>
      {severity === SEVERITY.INFO && <Info htmlColor='var(--dark-purple)' />}
      {severity === SEVERITY.WARNING && <Warning htmlColor='var(--sentiment-yellow)' />}
      <AlertContent>
        <AlertTitle severity={severity}>{title}</AlertTitle>
        {children && children}
      </AlertContent>
    </AlertContainer>
  );
}

export default Alert;
