import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import palette from '../tools/palette';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { PERCENT_DECIMAL_NUM } from '../../constants';
import Snackbar from '@material-ui/core/Snackbar';

export const BAR_HEIGHT = 20;
const MARGIN_VALUE = 2;

export const WidgetContainer = styled(Grid)`
  &.MuiGrid-root {
    padding: ${({ $withBottomPadding }) => ($withBottomPadding ? '16px' : '16px 16px 0')};
  }

  .MuiButton-outlined {
    border-color: ${palette.dividerColor};
  }

  .label-button {
    background-color: #fff;
  }
`;

export const AppButton = styled(Button)`
  &.MuiButton-root {
    .MuiButton-label {
      text-transform: none;
    }
  }
`;

export const CancelButton = AppButton;

export const SaveButton = styled(Button)`
  &.MuiButton-root {
    border-color: ${palette.dividerColor};

    .MuiButton-label {
      text-transform: none;
      color: ${palette.primaryColorLight};
    }
  }
  &.Mui-disabled {
    background-color: ${palette.linkColorLight4};
  }
`;

export const AnswerBar = styled('div')`
  display: inline-block;
  background-color: ${(props) => props.color};
  height: ${BAR_HEIGHT}px;
  line-height: ${BAR_HEIGHT}px;
  border-radius: 3px;
`;
export const AnswerBarContainer = styled(AnswerBar)`
  background-color: ${palette.linkColorLight4};
  width: calc(100% - ${MARGIN_VALUE + PERCENT_DECIMAL_NUM}em - 8px);
  margin-left: ${MARGIN_VALUE + PERCENT_DECIMAL_NUM}em;
  border-radius: var(--border-radius);
  border: 1px solid ${palette.linkColorLight3};
  margin-top: -1px;
`;

export const TotalNumberContainer = styled('div')`
  position: absolute;
  right: 12px;
  height: ${BAR_HEIGHT}px;
  line-height: ${BAR_HEIGHT}px;
  font-size: 0.8em;
`;

export const ExpandContainer = styled(Grid)`
  cursor: pointer;
  font-size: 12px;

  * {
    color: ${palette.linkColorLight};
  }
`;

export const DataViewButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    color: #909090;
    background-color: #dddddd;
  }
  &.active {
    color: #5000e8;
    background-color: #ccbdf9;
  }
`;

export const WidgetMessageContent = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const TextOverflowEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WidgetTotalBlock = styled(Box)`
  position: absolute;
  right: 20px;
  bottom: 2px;
  font-size: 14px;
  color: ${palette.linkColorLight};
`;

export const WhiteButton = styled(Button)`
  background-color: white;
`;

export const CustomSnackbar = styled(Snackbar)`
  text-align: left;
  color: white;

  .MuiSnackbarContent-message {
    font-family: monospace;
    white-space: pre;
    font-weight: bold;
  }

  &.error * {
    background-color: #f44336;
  }

  &.warning * {
    background-color: #ff9800;
  }
`;
