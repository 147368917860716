import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

interface IButton {
  $isWarning: boolean;
  $isDisabled: boolean;
}

const Button = styled.button<IButton>`
  display: inline-block;
  min-height: 32px;
  color: ${({ $isDisabled, $isWarning }) =>
    $isDisabled ? 'var(--gray-disabled)' : $isWarning ? 'var(--error-message)' : 'var(--dark-purple)'};
  border-color: ${({ $isDisabled, $isWarning }) =>
    $isDisabled ? 'var(--dark-gray)' : $isWarning ? 'var(--error-message)' : 'var(--dark-purple)'};
  border-width: 1px;
  border-radius: var(--double-border-radius);
  background-color: ${({ $isDisabled }) => ($isDisabled ? 'var(--medium-gray)' : 'var(--white)')};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  padding: 0px 16px;

  &:hover {
    background-color: var(--beehive-purple-transparent-10);
  }
`;

interface IInsightsButton {
  title?: string;
  children?: React.ReactNode;
  tooltipText?: string;
  isDisabled?: boolean;
  isWarning?: boolean;
  onClick?: () => void;
}

function InsightsButton({ title, isWarning = false, isDisabled = false, tooltipText, children, onClick }: IInsightsButton) {
  if (!children && (title === undefined || title === null)) {
    throw new Error('Either title or Component should be provided');
  }

  function handleClick(event: SyntheticEvent) {
    if (isDisabled) {
      event.preventDefault();
      return;
    }
    if (onClick) {
      onClick();
    }
  }
  return (
    <Button $isDisabled={isDisabled} $isWarning={isWarning} title={tooltipText} onClick={handleClick}>
      {title ? title : children}
    </Button>
  );
}

export default InsightsButton;
