import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Grid } from '@material-ui/core';

const ICON_COLOR = '#aaa';
const ICON_SIZE = 18;

const LoadingLabel = ({
  loading,
  showIcon = true,
  showText = true,
  iconSize = ICON_SIZE,
  textContent = 'Loading...',
  style = {},
  className = '',
}) => {
  if (!loading) {
    return null;
  }

  const icon = (
    <Grid item style={{ color: ICON_COLOR, paddingRight: '10px' }}>
      <CircularProgress size={iconSize} color='inherit' />
    </Grid>
  );
  const text = <Grid item>{textContent}</Grid>;

  return (
    <Grid container direction='row' justifyContent='center' style={style} className={className}>
      {showIcon && icon}
      {showText && text}
    </Grid>
  );
};

export default LoadingLabel;
