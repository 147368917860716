import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { DateRangePicker } from 'materialui-daterange-picker2';
import format from 'date-fns/format';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '../../../models/TimeRange';

const DateRangeButton = styled(Button)`
  font-size: 16px;
  text-transform: none;
`;

const DateRangeDialogActions = styled(DialogActions)`
  justify-content: flex-start;
  & > *:first-child {
    margin-right: auto;
  }
`;

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 'max-content',
    '& > div': {
      boxShadow: 'none',
    },
  },
}));

const ControlDateRangeSelector = ({ value, disabled, onChange, testID }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    setDateRange(value);
    if (!value || !value.startDate) {
      setStartDate('');
      return;
    }
    setStartDate(format(value.startDate, DEFAULT_DATE_FORMAT));
    setEndDate(format(value.endDate, DEFAULT_DATE_FORMAT));
  }, [value]);

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };

  const handleOk = () => {
    if (dateRange) {
      onChange({ ...dateRange });
    }
    setOpen(false);
  };

  const handleClear = () => {
    onChange('');
    setOpen(false);
  };

  return (
    <Box>
      <DateRangeButton
        variant='outlined'
        size='medium'
        startIcon={<DateRangeIcon />}
        disabled={disabled}
        onClick={handleOpen}
        data-testid={testID}
      >
        {startDate ? `${startDate} – ${endDate}` : 'Select date range'}
      </DateRangeButton>

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DateRangePicker
          closeOnClickOutside={false}
          initialDateRange={value}
          onChange={handleDateRangeChange}
          open={true}
          toggle={handleClose}
          wrapperClassName={classes.wrapper}
        />
        <DateRangeDialogActions>
          <Button color='primary' onClick={handleClear}>
            Clear
          </Button>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button color='primary' onClick={handleOk}>
            OK
          </Button>
        </DateRangeDialogActions>
      </Dialog>
    </Box>
  );
};

export default ControlDateRangeSelector;
