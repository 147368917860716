import { useSelector } from 'react-redux';
import { Box, Grid, Icon } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import palette from '../tools/palette';
import WeightIndicator from '../UI/WeightIndicator';
import WidgetExportMenu from '../WidgetMenu/WidgetExportMenu';
import React from 'react';
import WidgetFilterMenu from './WidgetFilterMenu';

const UNTITLED = 'Untitled';

function WidgetHeader({
  config,
  onCollapseClick,
  onMaximizeClick,
  refHeader,
  menuComponent,
  onCsvClick,
  onPngClick,
  onErrorClick,
  onFilterApply,
  groupTitle,
  refFiltersCount,
  unfixable,
  isMaximizable,
}) {
  const { translationUsed } = useSelector((state) => state.app);
  const hasWeights = useSelector((state) => state.dashboard.dashboard.hasWeights);

  const isCollapsed = config.base.collapsed;
  const ArrowIcon = isCollapsed ? KeyboardArrowRightIcon : KeyboardArrowDownIcon;
  const title = translationUsed ? config.base.title : config.base.originalTitle;
  const isUntitled = title === '';
  const displayTitle = isUntitled ? UNTITLED : title;

  const handleMouseDown = (event) => {
    const widgetContainer = event.target.closest('.widget-container');
    widgetContainer.draggable = true;
  };

  const handleMouseUp = (event) => {
    const widgetContainer = event.target.closest('.widget-container');
    widgetContainer.draggable = false;
  };

  return (
    <Grid item style={{ width: '100%', height: '46px' }} ref={refHeader}>
      <Box className='test' display='flex'>
        <Box flexGrow={1} style={{ minWidth: '0' }}>
          <ArrowIcon onClick={onCollapseClick} data-testid='widget-toggle' style={{ fontSize: 30, float: 'left', cursor: 'pointer' }} />
          <Box
            className='widget-draggable-handle'
            data-tooltip-id='base-tooltip'
            data-tooltip-content={displayTitle}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            component='h4'
            style={{
              cursor: 'move',
              marginTop: '5px',
              color: isUntitled ? palette.linkColorLight2 : 'inherit',
            }}
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
          >
            {displayTitle}
          </Box>
        </Box>
        {!unfixable && (
          <>
            {!config.validation.valid && (
              <Box paddingRight='6px' marginTop='1px'>
                <ErrorOutlineIcon
                  data-tooltip-id='base-tooltip'
                  data-tooltip-content='Actions are needed. Please click to edit the widget.'
                  color='error'
                  onClick={onErrorClick}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            )}
            {!isCollapsed && (
              <Box paddingRight='6px'>
                <WidgetFilterMenu config={config} onFilterApply={onFilterApply} groupTitle={groupTitle} />
              </Box>
            )}
            {hasWeights && (
              <Box paddingRight='6px' marginTop='2px'>
                <WeightIndicator />
              </Box>
            )}
            {onCsvClick && !config.base.collapsed && (
              <Box paddingRight='6px' paddingTop='2px'>
                <WidgetExportMenu
                  onCsvClick={onCsvClick}
                  onPngClick={onPngClick}
                  groupTitle={groupTitle}
                  config={config}
                  refFiltersCount={refFiltersCount}
                />
              </Box>
            )}
          </>
        )}
        {renderMaximiziedIcon()}
        {menuComponent}
      </Box>
    </Grid>
  );

  function renderMaximiziedIcon() {
    if (!isMaximizable) {
      return null;
    }
    return (
      <Box style={{ cursor: 'pointer', paddingTop: '5px' }} onClick={onMaximizeClick}>
        {config.base.maximized && (
          <Icon data-tooltip-id='base-tooltip' data-tooltip-content='Minimize' style={{ fontSize: '18px' }}>
            minimize
          </Icon>
        )}
        {!config.base.maximized && (
          <Icon data-tooltip-id='base-tooltip' data-tooltip-content='Expand' style={{ fontSize: '18px' }}>
            open_in_full
          </Icon>
        )}
      </Box>
    );
  }
}

export default WidgetHeader;
