import mixpanel from 'mixpanel-browser';

import CONFIG from '../../config';
import { API_BASE_URL } from '../../constants';
import { IEventsTrackerService } from './EventsTrackerService';

class MixpanelTracker implements IEventsTrackerService {
  constructor() {
    const config = {
      api_host: `${API_BASE_URL}/mp`,
      ignore_dnt: true,
      property_blacklist: ['$city', '$region', '$device', '$device_id'],
    };
    mixpanel.init(CONFIG.MIXPANEL.TOKEN, config);
  }

  identify(uniqId: string) {
    mixpanel.identify(uniqId);
  }

  track(event: string, properties = {}) {
    const props = properties;
    if ((props as any).email) {
      delete (props as any).email;
    }
    mixpanel.track(event, props);
  }

  trackImmediately(event: string, properties = {}) {
    const props = properties;
    if ((props as any).email) {
      delete (props as any).email;
    }

    mixpanel.track(event, props, {
      send_immediately: true,
      transport: 'sendBeacon',
    });
  }

  disableTracking() {
    mixpanel.register({ $ignore: true });
  }

  enableTracking() {
    mixpanel.unregister('$ignore');
  }
}

export default MixpanelTracker;
