import React from 'react';
import styled from 'styled-components';
import { CircularProgress, IconButton, MenuItem, Select, TableRow, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import SaveIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';

import { Connection, ConnectionStatusEnum } from '../../../types/connections';
import palette from '../../tools/palette';
import { tooltipClasses } from '../../../style';

import { ScheduleEnum } from '../types';
import { StyledConnectionCell, getColumnStyles } from '../style';
import { COLUMNS } from './constants';

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 14px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    font-size: 14px;
  }
`;

function renderSchedule(interval: ScheduleEnum) {
  switch (interval) {
    case ScheduleEnum.ONE_TIME:
      return 'One-time only';
    case ScheduleEnum.HOURLY:
      return 'Every Hour';
    case ScheduleEnum.DAILY:
      return 'Once a day';
    case ScheduleEnum.WEEKLY:
      return 'Weekly';
    default:
      return '';
  }
}

function renderStatus(status: ConnectionStatusEnum) {
  switch (status) {
    case ConnectionStatusEnum.CONNECTED:
      return 'Active';
    case ConnectionStatusEnum.PAUSED:
      return 'Paused';
    case ConnectionStatusEnum.RUNNING:
      return 'Running';
    default:
      return '';
  }
}

interface IConnectionRow {
  connection: Connection;
  status: ConnectionStatusEnum;
  interval: ScheduleEnum;
  isReadonly: boolean;
  isUpdating: boolean;
  isEditingConnection: boolean;
  isEditEnabled: boolean;
  isIntegrationEditEnabled: boolean;
  connectionErrorMessage: string;
  onBeginInlineEdit: () => void;
  onToggleEditingConnection: () => void;
  onChangeInterval: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onChangeStatus: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onDeleteConnection: () => void;
}

function ConnectionRow({
  connection,
  status,
  interval,
  isReadonly,
  isIntegrationEditEnabled,
  isEditEnabled,
  isUpdating,
  isEditingConnection,
  connectionErrorMessage,
  onBeginInlineEdit,
  onToggleEditingConnection,
  onChangeInterval,
  onChangeStatus,
  onDeleteConnection,
}: IConnectionRow) {
  const { id, name, actionTypeString, nextRunString, lastRunString } = connection;
  return (
    <TableRow data-testid='connection-row-row' key={id}>
      <StyledConnectionCell data-testid='connection-row-name' $colProps={getColumnStyles(COLUMNS.NAME)}>
        {name}
      </StyledConnectionCell>
      <StyledConnectionCell data-testid='connection-row-type' $colProps={getColumnStyles(COLUMNS.TYPE)}>
        {actionTypeString}
      </StyledConnectionCell>
      <StyledConnectionCell $colProps={getColumnStyles(COLUMNS.NEXT_RUN)}>{nextRunString}</StyledConnectionCell>
      <StyledConnectionCell $colProps={getColumnStyles(COLUMNS.LAST_RUN)}>{lastRunString}</StyledConnectionCell>
      <StyledConnectionCell $colProps={getColumnStyles(COLUMNS.SCHEDULE)}>
        {isReadonly ? (
          renderSchedule(interval)
        ) : (
          <StyledSelect
            data-testid='connection-row-interval'
            value={interval}
            labelId='interval-select-label'
            onChange={onChangeInterval}
            onClick={onBeginInlineEdit}
            readOnly={isReadonly}
          >
            <StyledMenuItem value={ScheduleEnum.ONE_TIME}>One-time only</StyledMenuItem>
            <StyledMenuItem value={ScheduleEnum.HOURLY}>Every Hour</StyledMenuItem>
            <StyledMenuItem value={ScheduleEnum.DAILY}>Once a day</StyledMenuItem>
            <StyledMenuItem value={ScheduleEnum.WEEKLY}>Weekly</StyledMenuItem>
          </StyledSelect>
        )}
      </StyledConnectionCell>
      <StyledConnectionCell data-testid='connection-row-status-cell' $colProps={getColumnStyles(COLUMNS.STATUS)}>
        {status === ConnectionStatusEnum.ERROR ? (
          <Tooltip
            data-testid='connection-row-error-tooltip'
            arrow
            classes={tooltipClasses()}
            placement='top'
            title={connectionErrorMessage}
          >
            <ErrorIcon style={{ color: palette.status.error }} />
          </Tooltip>
        ) : isReadonly ? (
          renderStatus(status)
        ) : (
          <StyledSelect
            data-testid='connection-row-status'
            value={status}
            labelId='status-select-label'
            onChange={onChangeStatus}
            onClick={onBeginInlineEdit}
            readOnly={isReadonly}
          >
            <StyledMenuItem value={ConnectionStatusEnum.CONNECTED}>Active</StyledMenuItem>
            <StyledMenuItem value={ConnectionStatusEnum.PAUSED}>Paused</StyledMenuItem>
          </StyledSelect>
        )}
      </StyledConnectionCell>
      {isIntegrationEditEnabled && (
        <StyledConnectionCell $colProps={getColumnStyles(COLUMNS.EDIT)}>
          {isEditEnabled && !isReadonly && (
            <IconButton data-testid='connection-row-edit-button' size='small' onClick={onToggleEditingConnection}>
              {isUpdating ? <CircularProgress size={20} /> : isEditingConnection ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
        </StyledConnectionCell>
      )}
      {isIntegrationEditEnabled && (
        <StyledConnectionCell $colProps={getColumnStyles(COLUMNS.DELETE)}>
          {isEditEnabled && (
            <IconButton size='small' data-testid='connection-row-delete-button' onClick={onDeleteConnection}>
              <DeleteIcon />
            </IconButton>
          )}
        </StyledConnectionCell>
      )}
    </TableRow>
  );
}

export default ConnectionRow;
