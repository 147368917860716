export const SETTINGS_TOGGLE_WIDGET_GROUP_STATE = 'SETTINGS_TOGGLE_WIDGET_GROUP_STATE';
export const SETTINGS_SET_TOTAL_BADGE_EXPANDED = 'SETTINGS_SET_TOTAL_BADGE_EXPANDED';

export const settingsToggleWidgetGroupState = (widgetGroupId: string, isCollapsed: boolean) => ({
  type: SETTINGS_TOGGLE_WIDGET_GROUP_STATE,
  payload: {
    widgetGroupId,
    isCollapsed,
  },
});

export const settingsSetTotalBadgeExpanded = (dashboardId: string, state: boolean) => ({
  type: SETTINGS_SET_TOTAL_BADGE_EXPANDED,
  payload: {
    dashboardId,
    state,
  },
});
