import { CardContent as cardContent, Dialog, IconButton, Card as card } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import upload from '../../assets/images/upload.png';
import connect from '../../assets/images/connect.png';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';

export enum DATA_TYPES {
  COLLECTION = 'collection',
  FILE = 'file',
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Card = styled(card)`
  margin: 45px;
  width: 300px;
  height: 220px;
  border-radius: 8px;
  background-color: #edf0ff;
  cursor: pointer;
`;

const CardContent = styled(cardContent)`
  text-align: center;
`;

const CardTitle = styled.div`
  font-size: 20px;
`;

interface IAddDataDialog {
  open: boolean;
  onSelect: (dataType: DATA_TYPES) => void;
  onClose: () => void;
}

export default function AddDataDialog({ open, onSelect, onClose }: IAddDataDialog) {
  function handleSelect(dataType: DATA_TYPES) {
    if (dataType === DATA_TYPES.COLLECTION) {
      eventsTracker.track(EVENTS.CONNECT_DATA_COLLECTION);
    } else {
      eventsTracker.track(EVENTS.DATA_UPLOAD);
    }
    onSelect(dataType);
    onClose();
  }

  return (
    <Dialog open={open} maxWidth={false}>
      <CloseButtonContainer>
        <IconButton size='small' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
      <Container>
        <Card onClick={handleSelect.bind(null, DATA_TYPES.FILE)}>
          <CardContent>
            <CardTitle>Upload a file</CardTitle>
            <img src={upload} alt='upload a file' style={{ width: '130px' }} />
            <div>Upload a file</div>
          </CardContent>
        </Card>
        <Card onClick={handleSelect.bind(null, DATA_TYPES.COLLECTION)}>
          <CardContent>
            <CardTitle>Connect data collection</CardTitle>
            <img src={connect} alt='connect data collection' style={{ width: '130px' }} />
            <div>Connect or start a new collection</div>
          </CardContent>
        </Card>
      </Container>
    </Dialog>
  );
}
