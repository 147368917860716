import parse from 'date-fns/parse';
import add from 'date-fns/add';

import { DATE_FORMAT } from '../../Widgets/TrendsWidget/lib';
import { PERIOD } from '../../../constants';

export const fillMissedDates = (trends, period) => {
  // get min max dates from surveys
  let minDate = new Date(3000, 12, 31);
  let maxDate = new Date(1000, 1, 1);
  const fixedDate = new Date();
  const surveys = {}; // existing date to value entries for each survey
  for (let trend of trends) {
    const timeToValue = new Map();
    for (let bucket of trend.buckets) {
      const date = parse(bucket.date, DATE_FORMAT, fixedDate);
      timeToValue.set(date.getTime(), bucket.base.value);
      minDate = date < minDate ? date : minDate;
      maxDate = date > maxDate ? date : maxDate;
    }
    surveys[trend.surveyID] = timeToValue;
  }

  return trends.map((trend) => {
    const values = [];
    const timeToValue = surveys[trend.surveyID];
    let currentDate = minDate;
    while (currentDate <= maxDate) {
      values.push({
        date: currentDate,
        value: timeToValue.has(currentDate.getTime()) ? timeToValue.get(currentDate.getTime()) : 0,
      });
      currentDate = period === PERIOD.QUARTER ? add(currentDate, { months: 3 }) : add(currentDate, { [`${period}s`]: 1 });
    }
    return {
      values,
      surveyID: trend.surveyID,
      surveyTitle: trend.surveyTitle,
    };
  });
};
