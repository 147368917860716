export const AZURE_UPDATE_FILES = 'AZURE_UPDATE_FILES';
export const AZURE_RESET_FILES = 'AZURE_RESET_FILES';

interface IUpdateFiles {
  files?: string[];
  error?: boolean;
}
export const updateFiles = ({ files, error }: IUpdateFiles) => ({
  type: AZURE_UPDATE_FILES,
  payload: {
    files,
    error,
  },
});

export const resetFiles = () => ({
  type: AZURE_RESET_FILES,
});
