import React, { MutableRefObject } from 'react';
import Grid from '@material-ui/core/Grid';
import { Query } from '../../types/Query';

import FilterFormButtons from './FilterFormButtons';
import CustomQueryBuilder from './CustomQueryBuilder';
import { SegmentInfoPaper } from './FilterSegmentInfo/FilterSegmentInfo';
import FilterSegmentInfoContainer from './FilterSegmentInfo/FilterSegmentInfoContainer';
import SegmentDescriptionContainer from './SegmentDescription/SegmentDescriptionContainer';

interface IFilterForm {
  currentFilterQuery: null | Query;
  filterQuery: Query;
  isFilterEdit: boolean;
  isValid: boolean;
  percentageRef: MutableRefObject<number | null>;
  query: Query;
  savedFilter: any;
  isLoading: boolean;
  onApplyClick: any;
  onChangeClick: any;
  onResetClick: any;
  onSaveClick: any;
}
function FilterForm({
  currentFilterQuery,
  filterQuery,
  isFilterEdit,
  isValid,
  percentageRef,
  query,
  savedFilter,
  isLoading,
  onApplyClick,
  onChangeClick,
  onResetClick,
  onSaveClick,
}: IFilterForm) {
  return (
    <Grid container direction='column' data-testid='filter-form' style={{ flex: 1 }}>
      <Grid item>
        <strong>Create filter</strong>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Grid container direction='row' wrap='nowrap'>
          <Grid item xs>
            <CustomQueryBuilder value={query} onChange={onChangeClick} isFilterEdit={isFilterEdit} />
          </Grid>
          <Grid item xs='auto' style={{ paddingLeft: '15px' }}>
            <SegmentInfoPaper>
              <FilterSegmentInfoContainer filterQuery={filterQuery} percentageRef={percentageRef} />
              <SegmentDescriptionContainer filterQuery={filterQuery} />
            </SegmentInfoPaper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: '10px' }}>
        <FilterFormButtons
          disabled={!isValid}
          resetDisabled={!currentFilterQuery}
          saving={isLoading}
          filterName={savedFilter ? savedFilter.name : ''}
          filterDescription={savedFilter ? savedFilter.description : ''}
          onSave={onSaveClick}
          onApply={onApplyClick}
          onReset={onResetClick}
          isFilterEdit={isFilterEdit}
        />
      </Grid>
    </Grid>
  );
}

export default FilterForm;
