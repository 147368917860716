import React, { useMemo } from 'react';

import { Concept, GenerationStatus as CurrentGenerationStatus } from '../../../../../../../store/reducers/aiInsights';
import InsightsButton from '../InsightsButton';
import { AIInsightContainer, FailureIcon, HeaderContainer, HeaderContainerLeftPart, ProgressIndicator, StyledBeehiveLogo } from '../styles';
import InsightPrompt from '../InsightPrompt';
import { ActionsContainer, LoadingContainer, StyledReloadIcon } from './styles';

interface IGenerationStatus {
  id: string;
  statuses: CurrentGenerationStatus[];
  availableFilters: string[];
  selectedConcept?: Concept;
  customPrompt?: string;
  segmentsNames?: string[];
  onTryAgainClick: () => void;
}

function GenerationStatus({
  id,
  availableFilters,
  customPrompt,
  selectedConcept,
  statuses,
  segmentsNames,
  onTryAgainClick,
}: IGenerationStatus) {
  const lastStatus = useMemo(() => statuses[statuses.length - 1], [statuses]);

  return (
    <AIInsightContainer data-testid={`generation-status-${id}`}>
      <HeaderContainer>
        <HeaderContainerLeftPart>
          {renderStatusIcon()}
          {renderPrompt()}
        </HeaderContainerLeftPart>
      </HeaderContainer>
      <ActionsContainer>{renderActionButton()}</ActionsContainer>
    </AIInsightContainer>
  );

  function renderStatusIcon() {
    if (lastStatus === CurrentGenerationStatus.FAILURE) {
      return <FailureIcon data-testid='failure-icon' />;
    }

    return <StyledBeehiveLogo data-testid='success-icon' />;
  }

  function renderPrompt() {
    if (lastStatus === CurrentGenerationStatus.FAILURE) {
      return <div data-testid='prompt'>Insights cannot be generated at this time. Please try again later.</div>;
    }

    return (
      <InsightPrompt
        data-testid='prompt'
        availableFilters={availableFilters}
        customPrompt={customPrompt}
        selectedConcept={selectedConcept}
        segmentsNames={segmentsNames}
      />
    );
  }

  function renderActionButton() {
    if (lastStatus === CurrentGenerationStatus.FAILURE) {
      return (
        <InsightsButton onClick={onTryAgainClick}>
          <LoadingContainer data-testid='generate-button'>
            <StyledReloadIcon /> Generate
          </LoadingContainer>
        </InsightsButton>
      );
    }

    return (
      <LoadingContainer data-testid='loading-indicator'>
        <ProgressIndicator /> Analyzing
      </LoadingContainer>
    );
  }
}

export default GenerationStatus;
