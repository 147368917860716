import { ChangeEvent } from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

import { ExportTypeEnum } from '../types';
import { StyledFormControl } from './style';

interface IConnectionExportTypeSelect {
  selectedExportType: ExportTypeEnum;
  onSelectExportType: (exportType: ExportTypeEnum) => void;
}

function ConnectionExportTypeSelect({ selectedExportType, onSelectExportType }: IConnectionExportTypeSelect) {
  function handleChangeExportType(e: ChangeEvent<{ value: unknown }>) {
    onSelectExportType(e.target.value as ExportTypeEnum);
  }

  return (
    <StyledFormControl>
      <InputLabel id='export-type-select-label'>Select Export Type</InputLabel>
      <Select
        data-testid='connection-export-type-select'
        value={selectedExportType}
        labelId='export-type-select-label'
        onChange={handleChangeExportType}
      >
        <MenuItem value={ExportTypeEnum.Standard}>Standard</MenuItem>
        <MenuItem value={ExportTypeEnum.NotAggregate}>Not Aggregate</MenuItem>
        <MenuItem value={ExportTypeEnum.Flat}>Flat</MenuItem>
      </Select>
    </StyledFormControl>
  );
}

export default ConnectionExportTypeSelect;
