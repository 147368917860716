// @ts-nocheck
export const groupToRglLayout = (groupLayout) =>
  groupLayout.map((item) => ({
    x: item.x,
    y: item.y,
    w: item.width,
    h: item.height,
    i: item.widgetID,
  }));

export const rglToGroupLayout = (rglLayout, groupLayout) => {
  const widgetId2Id = groupLayout.reduce((acc, item) => ({ ...acc, [item.widgetID]: item.id }), {});
  return rglLayout.map((item) => ({
    id: widgetId2Id[item.i],
    widgetID: item.i,
    height: item.h,
    width: item.w,
    x: item.x,
    y: item.y,
  }));
};
