import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { filterReset, filterSetQuery } from '../../store/actions/dashboardFilter';
import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createSavedFilter, updateSavedFilter } from '../../store/thunks/dashboardFilters';
import { COMBINATOR } from './constants';
import FilterForm from './FilterForm';
import {
  countProperties,
  EMPTY_FILTER_QUERY,
  EMPTY_QUERY,
  findChangedCombinator,
  isQueryValid,
  removeInvalidRulesAndGroups,
  transformFilterToQuery,
  transformQueryToFilter,
} from './lib';
import { CurrentDashboardInfo } from '../../store/reducers/dashboard/types';

interface IFilterFormContainer {
  savedFilter: any;
  onFilterSave: () => void;
}

function FilterFormContainer({ savedFilter = null, onFilterSave }: IFilterFormContainer) {
  const { fetchSavedFilters } = useSavedFilters();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { id: dashboardId } = useAppSelector((state) => state.dashboard.dashboard as CurrentDashboardInfo);
  const { query: currentFilterQuery, isLoading } = useAppSelector((state) => state.filter);

  const [wasLoading, setWasLoading] = useState(false);
  const [query, setQuery] = useState(() => {
    if (currentFilterQuery) {
      return transformFilterToQuery(currentFilterQuery);
    } else {
      if (savedFilter) {
        return transformFilterToQuery(savedFilter.query);
      } else {
        return EMPTY_QUERY;
      }
    }
  });

  const percentageRef = useRef<null | number>(null);
  const filterQuery = useMemo(() => transformQueryToFilter(query), [query]);

  const validFilterQuery = useMemo(() => {
    const possibleQuery = removeInvalidRulesAndGroups(filterQuery);
    if (!isEqual(EMPTY_FILTER_QUERY, possibleQuery)) {
      return possibleQuery;
    }
    return EMPTY_FILTER_QUERY;
  }, [filterQuery]);
  const isValid = isQueryValid(filterQuery);
  const isFilterEdit = savedFilter && savedFilter.id;

  useEffect(() => {
    if (savedFilter) {
      setQuery(transformFilterToQuery(savedFilter.query));
    }
  }, [savedFilter]);

  useEffect(() => {
    if (isLoading) {
      setWasLoading(true);
    }
    if (wasLoading && !isLoading) {
      setWasLoading(false);
      fetchSavedFilters(dashboardId);
      onFilterSave();
    }
  }, [wasLoading, dashboardId, isLoading]);

  function handleSaveClick(name: string, description = '') {
    const propertyCount = countProperties(filterQuery);
    if (isFilterEdit) {
      const oldPropertyCount = countProperties(savedFilter.query);
      eventsTracker.track(EVENTS.SAVED_SEGMENTS_EDIT_SAVE, {
        'Old Property Count': oldPropertyCount,
        'New Property Count': propertyCount,
        'Old Filter Name': savedFilter.name,
        'New Filter Name': name,
        'Old Filter Description': savedFilter.description,
        'New Filter Description': description,
      });
    } else {
      eventsTracker.track(EVENTS.FILTER_SAVE_CONFIRM, {
        'Result Percentage': percentageRef.current,
        'Filter Name': name,
        'Filter Description': description,
        'Property Count': propertyCount,
      });
    }

    if (isFilterEdit) {
      dispatch(
        updateSavedFilter({
          dashboardId,
          savedFilterID: savedFilter.id,
          name,
          filterQuery,
          description,
        }),
      );
    } else {
      dispatch(createSavedFilter({ dashboardId, name, filterQuery, description }));
    }
  }

  function handleApplyClick() {
    const propertyCount = countProperties(filterQuery);
    const tab = location.pathname.split('/').pop();
    eventsTracker.track(EVENTS.FILTER_APPLY, {
      Tab: capitalize(tab),
      'Result Percentage': percentageRef.current,
      'Property Count': propertyCount,
    });
    dispatch(filterSetQuery(filterQuery));
  }

  function handleResetClick() {
    const propertyCount = countProperties(filterQuery);
    eventsTracker.track(EVENTS.FILTER_RESET, {
      'Result Percentage': percentageRef.current,
      'Property Count': propertyCount,
    });
    setQuery(EMPTY_QUERY);
    dispatch(filterReset());
  }

  function handleChangeClick(value: any) {
    const changedCombinator = findChangedCombinator(value, query);
    if (changedCombinator !== null) {
      const propertyCount = countProperties(transformQueryToFilter(value));
      eventsTracker.track(EVENTS.FILTER_TOGGLE_OPERATOR, {
        'AND Operator': changedCombinator === COMBINATOR.AND,
        Combinator: changedCombinator === COMBINATOR.AND ? 'And' : 'Or',
        'Property Count': propertyCount,
        'Filter Origin': isFilterEdit ? 'Edit' : 'New',
      });
    }

    setQuery(value);
  }

  return (
    <FilterForm
      currentFilterQuery={currentFilterQuery}
      filterQuery={validFilterQuery}
      isFilterEdit={isFilterEdit}
      isValid={isValid}
      percentageRef={percentageRef}
      query={query}
      savedFilter={savedFilter}
      isLoading={isLoading}
      onApplyClick={handleApplyClick}
      onChangeClick={handleChangeClick}
      onResetClick={handleResetClick}
      onSaveClick={handleSaveClick}
    />
  );
}

export default FilterFormContainer;
