import { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';

import Chart from '../UI/Chart';
import { calculateQuestionHeight, OVERVIEW_BAR_HEIGHT, OVERVIEW_GAP_BETWEEN_QUESTIONS, OVERVIEW_SEPARATOR_HEIGHT } from './lib';
import Color from 'color';

const xAxisDefault = {
  type: 'value',
  min: 0,
  max: 100,
  splitLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: false,
  },
};

const yAxisDefault = {
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    align: 'left',
    overflow: 'break',
    width: 100,
    margin: 105,
  },
};

const renderItem = (params, api) => {
  const categoryIndex = api.value(0);
  const startPoint = api.coord([api.value(1), categoryIndex]);
  const endPoint = api.coord([api.value(2), categoryIndex]);
  const height = api.size([0, 1])[1] - 1;

  const number = api.value(4);
  const response = api.value(5);
  const percent = api.value(6);
  const color = api.value(7);
  const index = api.value(8);
  const questionHeight = api.value(9);

  const y =
    OVERVIEW_BAR_HEIGHT * categoryIndex +
    (categoryIndex * OVERVIEW_SEPARATOR_HEIGHT) / 2 +
    index * (questionHeight + OVERVIEW_GAP_BETWEEN_QUESTIONS);

  const rectShape = echarts.graphic.clipRectByRect(
    {
      x: startPoint[0],
      y: y,
      width: endPoint[0] - startPoint[0],
      height: height,
    },
    {
      // Bounding box of the current coordinate system (cartesian).
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    },
  );

  let res = {
    type: 'rect',
    shape: rectShape,
    style: {
      fill: color,
    },
  };

  // limit labels displaying by items with more that 5%
  res = {
    ...res,
    textContent: {
      style: {
        text: percent > 5 ? `${number} (${percent}%)\n\n${response}` : '',
        fill: Color(color).isLight() ? '#000' : '#fff',
        overflow: 'truncate',
        width: endPoint[0] - startPoint[0],
        fontSize: '14px',
        lineHeight: 12,
      },
    },
    textConfig: {
      position: 'inside',
    },
  };

  return rectShape && res;
};

const OverviewChartWrapper = ({ option, height, onChartReady }) => {
  const [chartOption, setChartOption] = useState(option);
  // two useEffects below are needed to apply option changes, they should be reset before applying new option
  useEffect(() => {
    setChartOption({ ...option, series: null, legend: null });
  }, [option]);
  useEffect(() => {
    if (chartOption !== option) {
      setChartOption(option);
    }
  }, [chartOption, option]);

  return <Chart option={chartOption} style={{ height: `${height}px`, width: '100%' }} onChartReady={onChartReady} />;
};

const OverviewChart = ({ rows, sortedDataSources, sortedBuckets, filtersToShow, height }) => {
  const series = [];
  const elementsPerQuestion = (sortedBuckets.length || 1) * (filtersToShow.length || 1);
  const questionHeight = calculateQuestionHeight(filtersToShow.length, sortedBuckets.length);

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    let offset = 0;
    const data = [];
    const rowIndex = i % elementsPerQuestion;
    const index = Math.floor(i / elementsPerQuestion);

    for (let item of row) {
      data.push({
        name: item.label,
        value: [
          rowIndex,
          offset,
          offset + item.realPercent,
          rowIndex,
          item.value,
          item.label,
          item.percent,
          item.color,
          index,
          questionHeight,
        ],
      });
      offset += item.realPercent;
    }
    series.push({
      dimensions: [
        null,
        null,
        null,
        null,
        null,
        { type: 'ordinal' }, // label
        null,
        { type: 'ordinal' }, // color
      ],
      renderItem,
      data: data,
      type: 'custom',
      xAxisIndex: index,
      yAxisIndex: index,
    });
  }

  const grid = [];
  const xAxis = [];
  const yAxis = [];
  const size = sortedDataSources.length;

  const chartLeft = 200 + (filtersToShow.length > 1 ? 120 : 0) + (sortedBuckets.length ? 120 : 0);
  const graphic = []; // contains question names, filter names, bucket names + filters dividers

  // add question names
  for (let i = 0; i < sortedDataSources.length; i++) {
    const dataSource = sortedDataSources[i];
    graphic.push({
      type: 'text',
      tooltip: {
        show: true,
        formatter: () => dataSource.title,
      },
      left: 0,
      top: questionHeight / 2 + i * (questionHeight + OVERVIEW_GAP_BETWEEN_QUESTIONS) - 8,
      style: {
        fill: 'rgb(68, 68, 68)',
        width: 180,
        overflow: 'truncate',
        text: dataSource.title,
        fontSize: '14px',
        textVerticalAlign: 'bottom',
      },
    });
  }

  // add bar names
  let rowLabels;
  if (sortedBuckets.length > 0) {
    rowLabels = new Array(filtersToShow.length || 1).fill(sortedBuckets).flatMap((x) => x);
  } else {
    rowLabels = filtersToShow.length > 0 ? filtersToShow.map((f) => (filtersToShow.length === 1 ? '' : f.name)) : [''];
  }
  if (filtersToShow.length > 0 || sortedBuckets.length > 0) {
    for (let j = 0; j < sortedDataSources.length; j++) {
      for (let i = 0; i < rowLabels.length; i++) {
        const label = rowLabels[i];
        graphic.push({
          type: 'text',
          tooltip: {
            show: true,
            formatter: () => label,
          },
          left: chartLeft - 105,
          top:
            OVERVIEW_BAR_HEIGHT / 2 +
            i * (OVERVIEW_BAR_HEIGHT + OVERVIEW_SEPARATOR_HEIGHT) +
            j * (questionHeight + OVERVIEW_GAP_BETWEEN_QUESTIONS) -
            6,
          style: {
            text: label,
            width: 100,
            overflow: 'truncate',
            textAlign: 'right',
            textVerticalAlign: 'bottom',
            fill: '#9da2c6',
          },
        });
      }
    }
  }

  const bucketsCount = sortedBuckets.length;
  const bucketsHeight = OVERVIEW_BAR_HEIGHT * bucketsCount + (bucketsCount - 1) * (OVERVIEW_SEPARATOR_HEIGHT / 2);

  if (filtersToShow.length > 1 && sortedBuckets.length > 1) {
    for (let y = 0; y < sortedDataSources.length; y++) {
      for (let i = 0; i < filtersToShow.length; i++) {
        // add filter name
        const filterTop = bucketsHeight * i + bucketsHeight / 2 + y * (questionHeight + OVERVIEW_GAP_BETWEEN_QUESTIONS) - 4;
        graphic.push({
          type: 'text',
          tooltip: {
            show: true,
            formatter: () => {
              return filtersToShow[i].name;
            },
          },
          left: 200,
          top: filterTop,
          style: {
            fill: '#333',
            width: 120,
            overflow: 'truncate',
            text: filtersToShow[i].name,
            fontSize: '14px',
          },
        });
        // add separator between filters
        if (i < filtersToShow.length - 1) {
          const lineTop = (i + 1) * bucketsHeight + y * (questionHeight + OVERVIEW_GAP_BETWEEN_QUESTIONS);
          graphic.push({
            type: 'line',
            left: 200,
            top: lineTop,
            shape: {
              x1: 0,
              x2: 238,
            },
            style: {
              stroke: '#ddd',
            },
          });
        }
      }
    }
  }

  // prepare axis & grids
  for (let i = 0; i < size; i++) {
    grid.push({
      top: questionHeight * i + OVERVIEW_GAP_BETWEEN_QUESTIONS * i,
      height: questionHeight,
      left: chartLeft,
      right: 0,
    });
    xAxis.push({
      ...xAxisDefault,
      gridIndex: i,
    });
    yAxis.push({
      ...yAxisDefault,
      data: rowLabels,
      gridIndex: i,
      axisLabel: {
        show: false,
        width: 200,
      },
    });
  }

  const option = {
    tooltip: {
      confine: true,
      formatter: function (params) {
        const number = params.data.value[4];
        const response = params.data.value[5];
        const percent = params.data.value[6];
        const color = params.data.value[7];
        return `<div style="color: ${color}">${number} (${percent}%)<br/>${response}</div>`;
      },
    },
    grid: grid,
    xAxis: xAxis,
    yAxis: yAxis,
    series: series,
    graphic: graphic,
    animation: false,
    textStyle: {
      fontFamily: 'Rubik',
      fontSize: '12px',
    },
  };

  function handleChartReady(echarts) {
    // Timeout for echarts to resize when react-grid-layout takes the full width of the screen
    setTimeout(() => echarts.resize(), 500);
  }
  return <OverviewChartWrapper option={option} height={height} onChartReady={handleChartReady} />;
};

export default OverviewChart;
