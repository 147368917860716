import { fetchAzureFiles } from '../../components/tools/api';
import { updateFiles } from '../actions/azure';
import { Action } from '../hooks';

interface IInitializeAzureFiles {
  authID: string;
}
export const initializeAzureFiles =
  ({ authID }: IInitializeAzureFiles): Action =>
  async (dispatch) => {
    const res = await fetchAzureFiles(authID);
    if (res) {
      dispatch(updateFiles({ files: res }));
    } else {
      dispatch(updateFiles({ error: true }));
    }
  };
