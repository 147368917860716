import React from 'react';

import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

import { ReactComponent as FileUploadIcon } from '../../../assets/icons/file-upload-icon.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg';

import LoadingLabel from '../../LoadingLabel/LoadingLabel';
import { FilledButton } from '../../UI/Button';
import { CloseButtonContainer, CloseIcon } from './styles';

import CSVUploadHelp from './CSVUploadHelp';
import Dropzone from './Dropzone';
import FileUploadDropdownContainer from './FileUploadDropdownContainer';
import SuccessfulUploadDialog from './SuccessfulUploadDialog';

const StyledFileUploadIcon = styled(FileUploadIcon)`
  width: 64px;
  height: 64px;
  fill: currentColor;
`;

const StyledFileIcon = styled(FileIcon)`
  width: 64px;
  height: 64px;
  fill: currentColor;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 100px;

  > * {
    margin-bottom: 16px;
  }

  .fw {
    width: 100%;
  }
`;

const DialogTitle = styled.div`
  text-align: center;
  font-size: 24px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  border: 2px dashed #e0e0e0;
  border-radius: var(--double-border-radius);
  box-sizing: border-box;
`;

const UploadButton = styled(FilledButton)`
  margin-top: 16px;
`;

const FileTitle = styled.div`
  margin: 24px 0 40px 0;
`;

interface IFileUploadDialog {
  dashboards: string[];
  selectedDashboards: string[];
  newSelectedDashboards: string[];
  fileSource: string;
  isOpen: boolean;
  isSubmitDisabled: boolean;
  isUploading: boolean;
  isSuccessfulUploadDialogOpen: boolean;
  fileName: string;
  setFile: (file: File | null) => void;
  handleClose: () => void;
  handleSubmit: () => void;
  handleSelectFile: (e: any) => void;
  onSelectedDashboardsSet: (dashboards: string[]) => void;
  onNewSelectedDashboardsSet: (newDashboards: string[]) => void;
  onFileSourceChange: (source: string) => void;
  onIsSuccessfulUploadDialogOpenChange: (isSuccessfulUploadDialogOpen: boolean) => void;
}

function FileUploadDialog({
  dashboards,
  selectedDashboards,
  newSelectedDashboards,
  fileSource,
  isOpen,
  isSubmitDisabled,
  isUploading,
  isSuccessfulUploadDialogOpen,
  fileName,
  setFile,
  handleClose,
  onSelectedDashboardsSet,
  handleSubmit,
  handleSelectFile,
  onNewSelectedDashboardsSet,
  onFileSourceChange,
  onIsSuccessfulUploadDialogOpenChange,
}: IFileUploadDialog) {
  if (isUploading) {
    return (
      <Container>
        <LoadingLabel loading />
      </Container>
    );
  }

  return (
    <div>
      <Dialog open={isOpen} maxWidth={'xl'} fullWidth>
        <CloseButtonContainer data-testid='file-upload-dialog-close' onClick={handleClose}>
          <CloseIcon />
        </CloseButtonContainer>
        <Container>
          <DialogTitle>Connect with Beehive AI</DialogTitle>
          <CSVUploadHelp />
          {renderDropzone()}
          {renderDropdown()}
          {renderUploadButton()}
        </Container>
      </Dialog>
      <SuccessfulUploadDialog
        fileName={fileName}
        isSuccessfulUploadDialogOpen={isSuccessfulUploadDialogOpen}
        handleIsSuccessfulUploadDialogOpenChange={onIsSuccessfulUploadDialogOpenChange}
      />
    </div>
  );

  function renderDropzone() {
    return (
      <Dropzone
        rootContainer={UploadContainer}
        setFile={setFile}
        handleSelectFile={handleSelectFile}
        options={{
          className: 'fw',
          accept: {
            'text/csv': ['.csv'],
            'text/plain': ['.txt'],
            'text/vtt': ['.vtt'],
          },
          maxFiles: 1,
          noClick: true,
        }}
      >
        {({ file, handleSelectFile: handleSelectFileInternal }) => renderFileConditional(file, handleSelectFileInternal)}
      </Dropzone>
    );
  }

  function renderFileConditional(file: File | null, handleSelectFileInternal: (e: any) => void) {
    if (file) {
      return renderUploadFileContent(file.name, handleSelectFileInternal);
    }
    return renderFileContent('Select a file or drag here.', handleSelectFileInternal);
  }

  function renderFileContent(FileTitleName: string, handleSelectFileInternal: (e: any) => void) {
    return (
      <>
        <StyledFileUploadIcon />
        <FileTitle data-testid='file-upload-dialog-file'>{FileTitleName}</FileTitle>
        <FilledButton data-testid='file-upload-dialog-upload' onClick={handleSelectFileInternal}>
          Select a file
        </FilledButton>
      </>
    );
  }

  function renderUploadFileContent(FileTitleName: string, handleSelectFileInternal: (e: any) => void) {
    return (
      <>
        <StyledFileIcon />
        <FileTitle data-testid='file-upload-dialog-file'>{FileTitleName}</FileTitle>
        <FilledButton data-testid='file-upload-dialog-upload' onClick={handleSelectFileInternal}>
          Select a file
        </FilledButton>
      </>
    );
  }

  function renderDropdown() {
    return (
      <FileUploadDropdownContainer
        dashboards={dashboards}
        fileSource={fileSource}
        newSelectedDashboards={newSelectedDashboards}
        selectedDashboards={selectedDashboards}
        handleSelectedDashboardsSet={onSelectedDashboardsSet}
        onNewSelectedDashboardsSet={onNewSelectedDashboardsSet}
        onFileSourceChange={onFileSourceChange}
      />
    );
  }

  function renderUploadButton() {
    return (
      <UploadButton data-testid='file-upload-dialog-submit' disabled={isSubmitDisabled} variant='contained' onClick={handleSubmit}>
        Upload
      </UploadButton>
    );
  }
}

export default FileUploadDialog;
