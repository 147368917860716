import { useAppSelector } from '../../../store/hooks';
import { Integration } from '../../../types/connections';

import IntegrationsList from './IntegrationsList';

interface IIntegrationsListContainer {
  isConnectorsLoading: boolean;
  isSurveysLoading: boolean;
  handleSelect: (integration: Integration) => void;
  handleEdit: (integration: Integration) => void;
}

function IntegrationsListContainer({ isConnectorsLoading, isSurveysLoading, handleSelect, handleEdit }: IIntegrationsListContainer) {
  const integrations = useAppSelector((state) => state.connectors).integrations;
  const selectedIntegration = useAppSelector((state) => state.connectors).selectedIntegration?.source;

  return (
    <IntegrationsList
      integrations={integrations}
      selectedIntegration={selectedIntegration}
      isSurveysLoading={isSurveysLoading}
      isConnectorsLoading={isConnectorsLoading}
      handleSelect={handleSelect}
      handleEdit={handleEdit}
    />
  );
}

export default IntegrationsListContainer;
