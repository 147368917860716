import { useEffect, useState } from 'react';
import { FETCH_STATUS, PERIOD } from '../../../constants';
import { SurveyTotalTrendsChartWrapper, useSurveyTotalTrends } from '../../../components/UI/SurveyTotalTrends';
import { Box } from '@material-ui/core';
import palette from '../../../components/tools/palette';

const ControlPanelDialogChart = ({ dashboardId, total }) => {
  const [period, setPeriod] = useState(PERIOD.QUARTER);
  const [loading, surveysTrends, fetchedPeriod] = useSurveyTotalTrends(dashboardId, period);

  useEffect(() => {
    if (loading === FETCH_STATUS.LOADED) {
      setPeriod(fetchedPeriod);
    }
  }, [loading, fetchedPeriod]);

  const handlePeriodClick = (value) => {
    setPeriod(value);
  };

  return (
    <Box border={`var(--border-radius) solid ${palette.responseChipColor}`} borderRadius={8}>
      <SurveyTotalTrendsChartWrapper
        loading={loading}
        onSelectPeriod={handlePeriodClick}
        period={fetchedPeriod}
        surveysTrends={surveysTrends}
        total={total}
      />
    </Box>
  );
};

export default ControlPanelDialogChart;
