import Grid from '@material-ui/core/Grid';

import { SegmentItemValueBarBackgroundGrid, SegmentItemValueBarNegative, SegmentItemValueBarPositive } from './style';
import { TextOverflowEllipsis } from '../../../components/UI';

const DeltaPlot = ({ traitValues }) => (
  <Grid container>
    {traitValues.map(
      /** @type SegmentItemValueModel */ (value) => {
        const numbers = `(${value.percent}% / ${value.size})`;

        return (
          <Grid item xs={12} key={value.displayName}>
            <Grid container>
              <Grid item xs={5}>
                <Grid container wrap='nowrap' style={{ fontSize: '12px', color: '#505d6f' }}>
                  <Grid item zeroMinWidth>
                    <TextOverflowEllipsis data-tooltip-id='base-tooltip' data-tooltip-content={value.displayName}>
                      {value.displayName}
                    </TextOverflowEllipsis>
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {numbers}
                  </Grid>
                </Grid>
              </Grid>
              <SegmentItemValueBarBackgroundGrid item xs={7}>
                <Grid container>
                  <Grid item xs={6}>
                    {value.delta < 0 && (
                      <SegmentItemValueBarNegative style={{ width: `${Math.abs(value.delta)}%` }}>
                        <div>{value.delta}%</div>
                      </SegmentItemValueBarNegative>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {value.delta > 0 && (
                      <SegmentItemValueBarPositive style={{ width: `${Math.abs(value.delta)}%` }}>
                        <div>+{value.delta}%</div>
                      </SegmentItemValueBarPositive>
                    )}
                  </Grid>
                </Grid>
              </SegmentItemValueBarBackgroundGrid>
            </Grid>
          </Grid>
        );
      },
    )}
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={5}></Grid>
        <Grid item xs={7}>
          <Grid container justifyContent='space-between' style={{ fontSize: '10px', color: '#9aa1a9', marginTop: '5px' }}>
            <Grid item style={{ marginLeft: '-12px' }}>
              -100
            </Grid>
            <Grid item style={{ marginLeft: '-10px' }}>
              -50
            </Grid>
            <Grid item>0</Grid>
            <Grid item style={{ marginRight: '-8px' }}>
              50
            </Grid>
            <Grid item style={{ marginRight: '-7px' }}>
              100
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default DeltaPlot;
