import { Config } from '../../../types/Config';
import {
  DASHBOARD_ADD_WIDGET,
  DASHBOARD_FETCH_FAIL,
  DASHBOARD_FETCH_START,
  DASHBOARD_FETCH_SUCCESS,
  DASHBOARD_REMOVE_WIDGET,
  DASHBOARD_RESET,
  DASHBOARD_SET_DATA_SOURCES,
  DASHBOARD_SET_SENTIMENT_SUMMARIES,
  DASHBOARD_SET_WIDGET_GROUPS,
  DASHBOARD_SET_WIDGETS,
  DASHBOARD_UPDATE_TITLE,
  DASHBOARD_UPDATE_WIDGET,
  DASHBOARD_CREATE_LABEL_START,
  DASHBOARD_CREATE_LABEL_FAILURE,
  DASHBOARD_CREATE_LABEL_SUCCESS,
  DASHBOARD_RENAME_LABEL_START,
  DASHBOARD_RENAME_LABEL_FAILURE,
  DASHBOARD_RENAME_LABEL_SUCCESS,
  DASHBOARD_DELETE_LABEL_START,
  DASHBOARD_DELETE_LABEL_FAILURE,
  DASHBOARD_DELETE_LABEL_SUCCESS,
  DASHBOARD_LABEL_STATE_RESET,
  DASHBOARD_SET_DATA_SOURCES_PENDING_REFRESH,
  addWidget,
  dashboardReset,
  dashboardSetWidgets,
  dashboardUpdateWidget,
  fetchFail,
  fetchStart,
  fetchSuccess,
  removeWidget,
  setDataSources,
  setSentimentSummaries,
  setWidgetGroups,
  updateDashboardTitle,
  dashboardCreateLabelStart,
  dashboardCreateLabelFailure,
  dashboardCreateLabelSuccess,
  dashboardLabelStateReset,
  dashboardRenameLabelStart,
  dashboardRenameLabelFailure,
  dashboardRenameLabelSuccess,
  dashboardDeleteLabelStart,
  dashboardDeleteLabelFailure,
  dashboardDeleteLabelSuccess,
  dashboardSetDataSourcesPendingRefresh,
  dashboardWidgetReloadResponsesSuccess,
  DASHBOARD_WIDGET_RELOAD_RESPONSES_SUCCESS,
  DASHBOARD_FETCH_SUMMARY_START,
  DASHBOARD_FETCH_SUMMARY_FAILURE,
  DASHBOARD_FETCH_SUMMARY_SUCCESS,
  DASHBOARD_FETCH_SENTIMENT_SUCCESS,
  DASHBOARD_FETCH_SENTIMENT_START,
  DASHBOARD_FETCH_SENTIMENT_FAILURE,
  dashboardFetchSummaryStart,
  dashboardFetchSummaryFailure,
  dashboardFetchSummarySuccess,
  dashboardFetchSentimentStart,
  dashboardFetchSentimentFailure,
  dashboardFetchSentimentSuccess,
  dashboardShouldReloadResponses,
  DASHBOARD_SHOULD_RELOAD_RESPONSES,
  dashboardRegenerationInProgress,
  dashboardGetRegenerationStatusStart,
  dashboardGetRegenerationStatusSuccess,
  dashboardGetRegenerationStatusFailure,
  DASHBOARD_REGENERATION_IN_PROGRESS,
  DASHBOARD_GET_REGENERATION_STATUS_START,
  DASHBOARD_GET_REGENERATION_STATUS_SUCCESS,
  DASHBOARD_GET_REGENERATION_STATUS_FAILURE,
} from '../../actions/dashboard';
import { overviewSetBreakdownFilter } from '../../actions/overview';
import { addValidationToWidget, preprocessOverviewWidgetConfig } from '../../lib';
import { WIDGET_TYPE } from '../../../constants';
import { withoutValidation } from '../../../components/tools';
import {
  CurrentDashboardInfo,
  DataSource,
  DataSourceRegeneratingStatus,
  DataSourceState,
  LabelOperationType,
  LabelState,
  RegeneratingDataSourceState,
  ResponsesByDataSourceId,
  SentimentSummary,
  WidgetGroup,
} from './types';
import { prepareWidgetGroups } from './utils';
import { RegenerationStatus } from '../../../types/DataSource';

export interface IDashboardReducer {
  loading: boolean;
  fetched: boolean;
  withValidation: boolean;
  dashboard: null | CurrentDashboardInfo;
  dataSources: DataSource[];
  sentimentSummaries: SentimentSummary[];
  widgetGroups: WidgetGroup[];
  widgets: Config[];
  responsesByDataSourceId: ResponsesByDataSourceId;
  labelsStateByDataSourceId: { [key: string]: { [key: string]: LabelState } };
  regeneratingStatesByDataSourceID: { [key: string]: RegeneratingDataSourceState };
  shouldBeReloadedByWidgetID: { [key: string]: boolean };
  statesByDataSourceID: { [key: string]: DataSourceState };
  error: string | null;
}

const initialState: IDashboardReducer = {
  loading: false,
  fetched: false,
  withValidation: false,
  error: null,
  dashboard: null,
  dataSources: [],
  sentimentSummaries: [],
  widgetGroups: [],
  widgets: [],
  responsesByDataSourceId: {},
  labelsStateByDataSourceId: {},
  regeneratingStatesByDataSourceID: {},
  shouldBeReloadedByWidgetID: {},
  statesByDataSourceID: {},
};

type actionTypes = ReturnType<typeof dashboardReset> &
  ReturnType<typeof setWidgetGroups> &
  ReturnType<typeof updateDashboardTitle> &
  ReturnType<typeof dashboardSetWidgets> &
  ReturnType<typeof setDataSources> &
  ReturnType<typeof setSentimentSummaries> &
  ReturnType<typeof overviewSetBreakdownFilter> &
  ReturnType<typeof fetchStart> &
  ReturnType<typeof fetchSuccess> &
  ReturnType<typeof addWidget> &
  ReturnType<typeof removeWidget> &
  ReturnType<typeof dashboardUpdateWidget> &
  ReturnType<typeof fetchFail> &
  ReturnType<typeof dashboardCreateLabelStart> &
  ReturnType<typeof dashboardCreateLabelFailure> &
  ReturnType<typeof dashboardCreateLabelSuccess> &
  ReturnType<typeof dashboardRenameLabelStart> &
  ReturnType<typeof dashboardRenameLabelFailure> &
  ReturnType<typeof dashboardRenameLabelSuccess> &
  ReturnType<typeof dashboardDeleteLabelStart> &
  ReturnType<typeof dashboardDeleteLabelFailure> &
  ReturnType<typeof dashboardDeleteLabelSuccess> &
  ReturnType<typeof dashboardLabelStateReset> &
  ReturnType<typeof dashboardSetDataSourcesPendingRefresh> &
  ReturnType<typeof dashboardShouldReloadResponses> &
  ReturnType<typeof dashboardWidgetReloadResponsesSuccess> &
  ReturnType<typeof dashboardFetchSummaryStart> &
  ReturnType<typeof dashboardFetchSummaryFailure> &
  ReturnType<typeof dashboardFetchSummarySuccess> &
  ReturnType<typeof dashboardFetchSentimentStart> &
  ReturnType<typeof dashboardFetchSentimentFailure> &
  ReturnType<typeof dashboardFetchSentimentSuccess> &
  ReturnType<typeof dashboardRegenerationInProgress> &
  ReturnType<typeof dashboardGetRegenerationStatusStart> &
  ReturnType<typeof dashboardGetRegenerationStatusSuccess> &
  ReturnType<typeof dashboardGetRegenerationStatusFailure>;

const dashboardReducer = (state = initialState, action: actionTypes) => {
  switch (action.type) {
    case DASHBOARD_FETCH_START:
      return handleDashboardFetchStart(state);
    case DASHBOARD_FETCH_SUCCESS:
      return handleDashboardFetchSuccess(state, action);
    case DASHBOARD_FETCH_FAIL:
      return handleDashboardFetchFail(state, action);
    case DASHBOARD_UPDATE_TITLE:
      return handleDashboardUpdateTitle(state, action);
    case DASHBOARD_SET_DATA_SOURCES:
      return handleDashboardSetDataSources(state, action);
    case DASHBOARD_SET_SENTIMENT_SUMMARIES:
      return handleDashboardSetSentimentSummaries(state, action);
    case DASHBOARD_SET_WIDGETS:
      return handleDashboardSetWidgets(state, action);
    case DASHBOARD_SET_WIDGET_GROUPS:
      return handleDashboardSetWidgetGroups(state, action);
    case DASHBOARD_UPDATE_WIDGET:
      return handleDashboardUpdateWidget(state, action);
    case DASHBOARD_ADD_WIDGET:
      return handleDashboardAddWidget(state, action);
    case DASHBOARD_REMOVE_WIDGET:
      return handleDashboardRemoveWidget(state, action);
    case DASHBOARD_RESET:
      return handleDashboardReset();
    case DASHBOARD_CREATE_LABEL_START:
      return handleDashboardCreateLabelStart(state, action);
    case DASHBOARD_CREATE_LABEL_FAILURE:
      return handleDashboardCreateLabelFailure(state, action);
    case DASHBOARD_CREATE_LABEL_SUCCESS:
      return handleDashboardCreateLabelSuccess(state, action);
    case DASHBOARD_RENAME_LABEL_START:
      return handleDashboardRenameLabelStart(state, action);
    case DASHBOARD_RENAME_LABEL_FAILURE:
      return handleDashboardRenameLabelFailure(state, action);
    case DASHBOARD_RENAME_LABEL_SUCCESS:
      return handleDashboardRenameLabelSuccess(state, action);
    case DASHBOARD_DELETE_LABEL_START:
      return handleDashboardDeleteLabelStart(state, action);
    case DASHBOARD_DELETE_LABEL_FAILURE:
      return handleDashboardDeleteLabelFailure(state, action);
    case DASHBOARD_DELETE_LABEL_SUCCESS:
      return handleDashboardDeleteLabelSuccess(state, action);
    case DASHBOARD_LABEL_STATE_RESET:
      return handleDashboardLabelStateReset(state, action);
    case DASHBOARD_SHOULD_RELOAD_RESPONSES:
      return handleDashboardShouldReloadResponses(state, action);
    case DASHBOARD_WIDGET_RELOAD_RESPONSES_SUCCESS:
      return handleDashboardWidgetReloadSuccess(state, action);
    case DASHBOARD_FETCH_SUMMARY_START:
      return handleDashboardFetchSummaryStart(state, action);
    case DASHBOARD_FETCH_SUMMARY_FAILURE:
      return handleDashboardFetchSummaryFailure(state, action);
    case DASHBOARD_FETCH_SUMMARY_SUCCESS:
      return handleDashboardFetchSummarySuccess(state, action);
    case DASHBOARD_FETCH_SENTIMENT_START:
      return handleDashboardFetchSentimentStart(state, action);
    case DASHBOARD_FETCH_SENTIMENT_FAILURE:
      return handleDashboardFetchSentimentFailure(state, action);
    case DASHBOARD_FETCH_SENTIMENT_SUCCESS:
      return handleDashboardFetchSentimentSuccess(state, action);
    case DASHBOARD_SET_DATA_SOURCES_PENDING_REFRESH:
      return handleDashboardSetDataSourcesPendingRefresh(state, action);
    case DASHBOARD_REGENERATION_IN_PROGRESS:
      return handleDashboardRegenerationInProgress(state, action);
    case DASHBOARD_GET_REGENERATION_STATUS_START:
      return handleDashboardGetRegenerationStatusStart(state, action);
    case DASHBOARD_GET_REGENERATION_STATUS_SUCCESS:
      return handleDashboardGetRegenerationStatusSuccess(state, action);
    case DASHBOARD_GET_REGENERATION_STATUS_FAILURE:
      return handleGetRegenerationStatusFailure(state, action);
    default:
      return state;
  }
};

function handleDashboardFetchStart(state: IDashboardReducer): IDashboardReducer {
  return {
    ...state,
    fetched: false,
    loading: true,
  };
}
function handleDashboardFetchSuccess(state: IDashboardReducer, action: ReturnType<typeof fetchSuccess>): IDashboardReducer {
  const withValidation = action.payload.isInternal && !withoutValidation();
  const dashboard: CurrentDashboardInfo = {
    ...(action.payload.dashboard as CurrentDashboardInfo),
    withValidation,
    groups: action.payload.dashboard.groups || [],
  };
  const widgets = action.payload.dashboard.widgets.map((widget) => {
    if (widget.base.type === WIDGET_TYPE.OVERVIEW) {
      return preprocessOverviewWidgetConfig(withValidation, widget, state.dataSources, dashboard.dataSources);
    }
    return addValidationToWidget(withValidation, widget, action.payload.responsesByDataSourceId);
  });
  const widgetGroups = prepareWidgetGroups(dashboard.widgetGroups as WidgetGroup[], widgets, true);

  return {
    ...state,
    widgetGroups,
    dashboard,
    widgets,
    responsesByDataSourceId: action.payload.responsesByDataSourceId,
    loading: false,
    fetched: true,
    error: null,
  };
}
function handleDashboardFetchFail(state: IDashboardReducer, action: ReturnType<typeof fetchFail>): IDashboardReducer {
  return {
    ...state,
    error: action.payload.error,
  };
}

function handleDashboardUpdateTitle(state: IDashboardReducer, action: ReturnType<typeof updateDashboardTitle>): IDashboardReducer {
  return {
    ...state,
    dashboard: {
      ...Object.assign({}, state.dashboard),
      title: action.payload.title,
    },
  };
}

function handleDashboardSetDataSources(state: IDashboardReducer, action: ReturnType<typeof setDataSources>): IDashboardReducer {
  return {
    ...state,
    dataSources: action.payload.dataSources,
  };
}

function handleDashboardSetSentimentSummaries(
  state: IDashboardReducer,
  action: ReturnType<typeof setSentimentSummaries>,
): IDashboardReducer {
  return {
    ...state,
    sentimentSummaries: action.payload.sentimentSummaries,
  };
}

function handleDashboardUpdateWidget(state: IDashboardReducer, action: ReturnType<typeof dashboardUpdateWidget>): IDashboardReducer {
  const updatedWidget = addValidationToWidget(state.withValidation, action.payload.widget, state.responsesByDataSourceId);
  const updatedWidgets = state.widgets.map((widget) => {
    if (widget.base.id === action.payload.widget.base.id) {
      return updatedWidget;
    }
    return widget;
  });

  return {
    ...state,
    widgets: updatedWidgets,
  };
}
function handleDashboardAddWidget(state: IDashboardReducer, action: ReturnType<typeof addWidget>): IDashboardReducer {
  const newWidget = addValidationToWidget(state.withValidation, action.payload.widget, state.responsesByDataSourceId);
  return {
    ...state,
    widgets: [...state.widgets, newWidget],
  };
}
function handleDashboardRemoveWidget(state: IDashboardReducer, action: ReturnType<typeof removeWidget>): IDashboardReducer {
  return {
    ...state,
    widgets: state.widgets.filter((widget) => widget.base.id !== action.payload.widgetId),
  };
}

function handleDashboardSetWidgets(state: IDashboardReducer, action: ReturnType<typeof dashboardSetWidgets>): IDashboardReducer {
  return {
    ...state,
    widgets: action.payload.widgets.map((config) => addValidationToWidget(state.withValidation, config, state.responsesByDataSourceId)),
  };
}

function handleDashboardSetWidgetGroups(state: IDashboardReducer, action: ReturnType<typeof setWidgetGroups>): IDashboardReducer {
  const newWidgetGroups = prepareWidgetGroups(action.payload.widgetGroups, state.widgets);
  return {
    ...state,
    widgetGroups: newWidgetGroups,
  };
}

function handleDashboardReset() {
  return {
    ...initialState,
  };
}

function handleDashboardCreateLabelStart(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardCreateLabelStart>,
): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: LabelOperationType.Create,
          isLoading: true,
          isDoneSuccessful: false,
          isError: false,
        },
      },
    },
  };
}
function handleDashboardCreateLabelFailure(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardCreateLabelFailure>,
): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: LabelOperationType.Create,
          isLoading: false,
          isDoneSuccessful: false,
          isError: true,
        },
      },
    },
  };
}
function handleDashboardCreateLabelSuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardCreateLabelSuccess>,
): IDashboardReducer {
  const { dataSourceID, label } = action.payload;
  const dataSource = state.dataSources.find((ds) => ds.dataSourceID === dataSourceID);

  if (!dataSource) {
    console.error(`handleDashboardCreateLabelSuccess: dataSource ${dataSourceID} not found`);
    return state;
  }

  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [dataSourceID]: {
        ...state.labelsStateByDataSourceId[dataSourceID],
        [label]: {
          operation: LabelOperationType.Create,
          isLoading: false,
          isDoneSuccessful: true,
          isError: false,
        },
      },
    },
  };
}

function handleDashboardRenameLabelStart(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardRenameLabelStart>,
): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: LabelOperationType.Rename,
          isLoading: true,
          isDoneSuccessful: false,
          isError: false,
        },
      },
    },
  };
}
function handleDashboardRenameLabelFailure(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardRenameLabelFailure>,
): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: LabelOperationType.Rename,
          isLoading: false,
          isDoneSuccessful: false,
          isError: true,
        },
      },
    },
  };
}
function handleDashboardRenameLabelSuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardRenameLabelSuccess>,
): IDashboardReducer {
  const { dataSourceID, originalLabel, newLabel } = action.payload;
  const dataSource = state.dataSources.find((ds) => ds.dataSourceID === dataSourceID);
  if (!dataSource) {
    console.error(`handleDashboardRenameLabelSuccess: dataSource ${action.payload.dataSourceID} not found`);
    return state;
  }
  const item = dataSource.items.find((item) => item.response === originalLabel);
  if (!item) {
    console.error(`handleDashboardRenameLabelSuccess: Label "${originalLabel}" not found in dataSource ${dataSourceID}`);
    return state;
  }

  return {
    ...state,
    dataSources: [...state.dataSources, { ...dataSource }],
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[dataSourceID],
        [originalLabel]: {
          operation: LabelOperationType.Rename,
          isLoading: false,
          isDoneSuccessful: false,
          isError: false,
        },
        [newLabel]: {
          operation: LabelOperationType.Rename,
          isLoading: false,
          isDoneSuccessful: true,
          isError: false,
        },
      },
    },
  };
}

function handleDashboardDeleteLabelStart(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardDeleteLabelStart>,
): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: LabelOperationType.Delete,
          isLoading: true,
          isDoneSuccessful: false,
          isError: false,
        },
      },
    },
  };
}
function handleDashboardDeleteLabelFailure(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardDeleteLabelFailure>,
): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: LabelOperationType.Delete,
          isLoading: false,
          isDoneSuccessful: false,
          isError: true,
        },
      },
    },
  };
}
function handleDashboardDeleteLabelSuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardDeleteLabelSuccess>,
): IDashboardReducer {
  const { dataSourceID, label } = action.payload;
  const dataSource = state.dataSources.find((ds) => ds.dataSourceID === dataSourceID);
  if (!dataSource) {
    console.error(`handleDashboardDeleteLabelSuccess: dataSource ${action.payload.dataSourceID} not found`);
    return state;
  }

  const index = dataSource.items.findIndex((item) => item.response === label);
  if (index === -1) {
    console.error(`handleDashboardDeleteLabelSuccess: Label "${action.payload.label}" not found in dataSource ${dataSourceID}`);
    return state;
  }

  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [label]: {
          operation: LabelOperationType.Delete,
          isLoading: false,
          isDoneSuccessful: true,
          isError: false,
        },
      },
    },
  };
}

function handleDashboardLabelStateReset(state: IDashboardReducer, action: ReturnType<typeof dashboardLabelStateReset>): IDashboardReducer {
  return {
    ...state,
    labelsStateByDataSourceId: {
      ...state.labelsStateByDataSourceId,
      [action.payload.dataSourceID]: {
        ...state.labelsStateByDataSourceId[action.payload.dataSourceID],
        [action.payload.label]: {
          operation: null,
          isLoading: false,
          isDoneSuccessful: false,
          isError: false,
        },
      },
    },
  };
}

function handleDashboardShouldReloadResponses(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardShouldReloadResponses>,
): IDashboardReducer {
  const { dataSourceID } = action.payload;
  let shouldBeReloadedByWidgetID = { ...state.shouldBeReloadedByWidgetID };
  state.widgets
    .filter((widget) => widget.settings.dataSources.map((x) => x.id).includes(dataSourceID))
    .forEach((widget) => {
      if (!widget.base.id) {
        console.error(`Widget ${JSON.stringify(widget)} does not have ID`);
        return;
      }
      shouldBeReloadedByWidgetID = {
        ...shouldBeReloadedByWidgetID,
        [widget.base.id]: true,
      };
    });

  return {
    ...state,
    shouldBeReloadedByWidgetID: shouldBeReloadedByWidgetID,
  };
}

function handleDashboardWidgetReloadSuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardWidgetReloadResponsesSuccess>,
): IDashboardReducer {
  return {
    ...state,
    shouldBeReloadedByWidgetID: {
      ...state.shouldBeReloadedByWidgetID,
      [action.payload.widgetID]: false,
    },
  };
}

function handleDashboardFetchSummaryStart(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardFetchSummaryStart>,
): IDashboardReducer {
  const { dataSourceID } = action.payload;
  return {
    ...state,
    statesByDataSourceID: {
      ...state.statesByDataSourceID,
      [dataSourceID]: {
        isLoading: true,
        isLoadingSentiment: false,
        isError: false,
      },
    },
  };
}

function handleDashboardFetchSummaryFailure(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardFetchSummaryFailure>,
): IDashboardReducer {
  const { dataSourceID } = action.payload;
  return {
    ...state,
    statesByDataSourceID: {
      ...state.statesByDataSourceID,
      [dataSourceID]: {
        isLoading: false,
        isLoadingSentiment: false,
        isError: true,
      },
    },
  };
}

function handleDashboardFetchSummarySuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardFetchSummarySuccess>,
): IDashboardReducer {
  const { dataSource, dataSourceID } = action.payload;

  const datasources = state.dataSources;
  const index = datasources.findIndex((ds) => ds.dataSourceID === dataSourceID);
  if (index === -1) {
    console.error(`handleDashboardFetchSummarySuccess: dataSource ${dataSourceID} not found`);
    return state;
  }
  const updatedDataSources = datasources.toSpliced(index, 1, dataSource);
  return {
    ...state,
    dataSources: updatedDataSources,
  };
}

function handleDashboardFetchSentimentStart(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardFetchSentimentStart>,
): IDashboardReducer {
  const { dataSourceID } = action.payload;
  return {
    ...state,
    statesByDataSourceID: {
      ...state.statesByDataSourceID,
      [dataSourceID]: {
        isLoading: false,
        isLoadingSentiment: true,
        isError: false,
      },
    },
  };
}

function handleDashboardFetchSentimentFailure(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardFetchSentimentFailure>,
): IDashboardReducer {
  const { dataSourceID } = action.payload;
  return {
    ...state,
    statesByDataSourceID: {
      ...state.statesByDataSourceID,
      [dataSourceID]: {
        isLoading: false,
        isLoadingSentiment: false,
        isError: true,
      },
    },
  };
}

function handleDashboardFetchSentimentSuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardFetchSentimentSuccess>,
): IDashboardReducer {
  const { dataSourceID, sentiment } = action.payload;
  const sentimentSummaries = state.sentimentSummaries;
  const index = sentimentSummaries.findIndex((ss) => ss.dataSourceID === dataSourceID);
  if (index === -1) {
    console.error(`handleDashboardFetchSentimentSuccess: dataSource ${dataSourceID} not found`);
    return state;
  }
  const updatedSentimentSummaries = sentimentSummaries.toSpliced(index, 1, sentiment);

  return {
    ...state,
    sentimentSummaries: updatedSentimentSummaries,
  };
}

function handleDashboardSetDataSourcesPendingRefresh(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardSetDataSourcesPendingRefresh>,
): IDashboardReducer {
  const regeneratingStates = { ...state.regeneratingStatesByDataSourceID };
  for (const ds of action.payload.pendingRefreshDataSources) {
    regeneratingStates[ds.dataSourceID] = {
      ...regeneratingStates[ds.dataSourceID],
      status: ds.status,
      isError: false,
    };
  }

  return {
    ...state,
    regeneratingStatesByDataSourceID: regeneratingStates,
  };
}

function handleDashboardRegenerationInProgress(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardRegenerationInProgress>,
): IDashboardReducer {
  const { dataSourceIDs } = action.payload;
  const dataSourceIDsSet = new Set(dataSourceIDs);

  const regeneratingStates = { ...state.regeneratingStatesByDataSourceID };
  for (const ds of dataSourceIDsSet) {
    regeneratingStates[ds] = {
      ...regeneratingStates[ds],
      status: DataSourceRegeneratingStatus.PendingRegenerating,
      isError: false,
    };
  }

  return {
    ...state,
    regeneratingStatesByDataSourceID: regeneratingStates,
  };
}

function handleDashboardGetRegenerationStatusStart(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardGetRegenerationStatusStart>,
): IDashboardReducer {
  return state;
}

function handleDashboardGetRegenerationStatusSuccess(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardGetRegenerationStatusSuccess>,
): IDashboardReducer {
  const { items } = action.payload;
  const regeneratingStates = { ...state.regeneratingStatesByDataSourceID };

  for (const { dataSourceID, status } of items) {
    if (status === RegenerationStatus.Generated) {
      regeneratingStates[dataSourceID] = {
        ...regeneratingStates[dataSourceID],
        status: null,
        isError: false,
      };
    }
  }

  return {
    ...state,
    regeneratingStatesByDataSourceID: regeneratingStates,
  };
}

function handleGetRegenerationStatusFailure(
  state: IDashboardReducer,
  action: ReturnType<typeof dashboardGetRegenerationStatusFailure>,
): IDashboardReducer {
  const { dataSourceIDs } = action.payload;
  const dataSourceIDsSet = new Set(dataSourceIDs);
  const regeneratingStates = { ...state.regeneratingStatesByDataSourceID };

  for (const ds of dataSourceIDsSet) {
    regeneratingStates[ds] = {
      ...regeneratingStates[ds],
      isError: true,
    };
  }

  return {
    ...state,
    regeneratingStatesByDataSourceID: regeneratingStates,
  };
}

export default dashboardReducer;
