import styled from 'styled-components';
import { createFilterOptions } from '@material-ui/lab';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/cross-transparent-icon.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-icon.svg';

import { CustomAutocomplete, FilteringTextField } from './style';
import { useState } from 'react';

const SelectedSpan = styled.span`
  background-color: var(--light-purple-50-transparency);
`;

const AddNewContainer = styled.li`
  color: var(--dark-gray);
  & svg {
    margin-right: 14px;
  }
`;

const PlusIcon = styled(CloseIcon)`
  fill: var(--dark-gray);
  rotate: 45deg;
`;

interface ILabelsAutocomplete {
  labels: string[];
  onAddLabelClick: (label: string) => void;
}

interface IOption {
  label: string;
  isNew?: boolean;
}

function LabelsAutocomplete({ labels, onAddLabelClick }: ILabelsAutocomplete) {
  const [value, setValue] = useState<IOption | null>(null);

  const options = labels.map((label) => {
    return {
      label: label,
    };
  });

  function handleOnChange(_: any, newValue: unknown) {
    if (!newValue) {
      return;
    }
    const tNewValue = newValue as IOption;
    onAddLabelClick(tNewValue.label);

    // reset selection
    setValue({ label: '' });
  }

  return (
    <CustomAutocomplete
      onChange={handleOnChange}
      value={value}
      disablePortal
      freeSolo
      clearOnBlur
      clearOnEscape
      disableClearable
      options={options}
      filterOptions={(options, state) => {
        let filtered = filter(options as IOption[], state);
        const { inputValue } = state;
        const isExisting = (options as IOption[]).some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered = [
            {
              label: inputValue,
              isNew: true,
            },
            ...filtered,
          ];
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        return (option as IOption).label;
      }}
      renderOption={(option, state) => {
        const tOption = option as IOption;
        const { inputValue } = state;
        const { label } = tOption;

        if (tOption.isNew) {
          return (
            <AddNewContainer>
              <PlusIcon />
              {`Add "${label}"`}
            </AddNewContainer>
          );
        }

        if (inputValue) {
          const startIndex = label.indexOf(inputValue);
          if (startIndex >= 0) {
            return (
              <li>
                {label.slice(0, startIndex)}
                <SelectedSpan>{label.slice(startIndex, startIndex + inputValue.length)}</SelectedSpan>
                {label.slice(startIndex + inputValue.length)}
              </li>
            );
          }
        }

        return <li>{label}</li>;
      }}
      renderInput={(params) => {
        return (
          <FilteringTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: <Adornment />,
            }}
            variant='outlined'
          />
        );
      }}
    />
  );
}

const filter = createFilterOptions<IOption>();

function Adornment() {
  return <SearchIcon />;
}

export default LabelsAutocomplete;
