import { useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import { isOptionGroupArray } from 'react-querybuilder';

import { joinWith, splitBy } from './utils';

export const toOptions = (arr) => {
  if (isOptionGroupArray(arr)) {
    const optArray = [];
    for (const og of arr) {
      optArray.push(
        <ListSubheader key={og.label}>{og.label}</ListSubheader>,
        ...og.options.map((opt) => (
          <MenuItem key={opt.name} value={opt.name} disabled={!!opt.disabled}>
            {opt.label}
          </MenuItem>
        )),
      );
    }
    return optArray;
  }
  /* istanbul ignore else */
  if (Array.isArray(arr)) {
    return arr.map((opt) => (
      <MenuItem key={opt.name} value={opt.name} disabled={!!opt.disabled}>
        {opt.label}
      </MenuItem>
    ));
  }
  /* istanbul ignore next */
  return null;
};

export const MaterialValueSelector = ({
  className,
  handleOnChange,
  options,
  value,
  disabled,
  title,
  multiple,
  listsAsArrays,
  // Props that should not be in extraProps
  testID: _testID,
  rules: _rules,
  level: _level,
  path: _path,
  context: _context,
  validation: _validation,
  operator: _operator,
  field: _field,
  fieldData: _fieldData,
  ...extraProps
}) => {
  const onChange = useMemo(
    () =>
      multiple
        ? ({ target: { value: v } }) =>
            handleOnChange(Array.isArray(v) ? (listsAsArrays ? v : joinWith(v, ',')) : /* istanbul ignore next */ v)
        : ({ target: { value: v } }) => handleOnChange(v),
    [handleOnChange, listsAsArrays, multiple],
  );

  const val = multiple ? (Array.isArray(value) ? value : splitBy(value, ',')) : value;

  return (
    <FormControl variant='outlined' className={className} title={title} disabled={disabled} size='small'>
      <Select
        value={val}
        // @ts-expect-error onChange cannot accept string[]
        onChange={onChange}
        multiple={!!multiple}
        data-testid={_testID}
        data-value={value}
        {...extraProps}
      >
        {toOptions(options)}
      </Select>
    </FormControl>
  );
};

MaterialValueSelector.displayName = 'MaterialValueSelector';
