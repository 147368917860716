import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    padding: 12px;
    font-size: 14px;
    color: #525777;

    width: 300px;
    overflow-x: hidden;
  }
`;

const styles = {
  root: {
    flexGrow: 1,
  },
  paperTitle: {
    textAlign: 'left',
    boxShadow: 'none',
  },
  paperTime: {
    textAlign: 'right',
    boxShadow: 'none',
  },
  title: {
    paddingLeft: 20,
  },
};

const QuestionsDropdownTitleWrapper = styled('div')`
  h3 {
    margin-top: 0;
  }
`;

class QuestionsDropdownTitle extends Component {
  constructor(props) {
    super(props);

    this.menuButtonRef = null;

    this.state = {
      args: {
        anchorEl: null,
        anchorOriginVertical: 'bottom',
        anchorOriginHorizontal: 'right',
        transformOriginVertical: 'top',
        transformOriginHorizontal: 'right',
        anchorReference: 'anchorEl',
      },
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: this.menuButtonRef });
  };
  handleSetPrimary = (id, e) => {
    this.props.handlePrimaryQuestionChange(id);
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, questions, mainQuestionId, loading, header } = this.props;

    const data = Object.entries(questions);

    const handleChange = (e) => {};

    return (
      <QuestionsDropdownTitleWrapper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paperTitle}>
              <Grid container alignItems='stretch'>
                <h3>{header ? header : 'Responses Over Time'}</h3>
                &nbsp;&nbsp;&nbsp;{loading && <CircularProgress size={22} />}
              </Grid>
              <Grid container direction='row'>
                <FormControl variant='outlined'>
                  <StyledSelect value={questions[mainQuestionId] || ''} onChange={handleChange}>
                    {data.length > 0 &&
                      data.map(([id, question]) => (
                        <MenuItem key={id} value={question} onClick={this.handleSetPrimary.bind(this, id)}>
                          {question}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                </FormControl>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </QuestionsDropdownTitleWrapper>
    );
  }
}

QuestionsDropdownTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuestionsDropdownTitle);
