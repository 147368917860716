const palette = {
  primaryColorLight: '#7d68ff',
  primaryColorLightHover: '#6c57ee',
  primaryColorDark: '#32375b',
  primaryColorDarkHover: '#43486c',
  linkColorDark: '#353c52',
  linkColorLight: '#8b92a6',
  linkColorLight1: '#9da2c6',
  linkColorLight2: '#9ca0ab',
  linkColorLight3: '#f9faff',
  linkColorLight4: '#e9eaef',
  linkColorLight5: '#e0e0e5',
  responseChipColor: '#f7f8fd',
  toolbarBackgroundColor: '#fff',
  dividerColor: '#e3e3f5',

  // New colors
  textPrimary: '#0f0c0c',
  beehivePurple: '#8268ff',
  beehivePurpleLight10: '#F3F0FF',
  darkPurple: '#4a399e',
  mediumGray: '#e0e0e0',
  lightGray: '#f7f8fd',
  textGray: '#a6a6a6',
  lightPurpleTransparent50: 'rgba(192, 187, 250, 0.50)',
  black: '#0f0c0c',
  lightBlue: '#ebf5fe',
  link: '#4397F9',

  status: {
    active: '#2A9D49',
    analyzing: '#4397F9',
    configuration: '#FC7A23',
    error: '#A94442',
    review: '#FBC94D',
  },
};

export default palette;
