import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { ChangeEvent } from 'react';

import { StyledFormControl } from './style';

interface IConnectionDashboardSelect {
  dashboards: { id: string; title: string }[];
  selectedDashboard: string;
  onSelectDashboard: (dashboard: string) => void;
}

function ConnectionDashboardSelect({ dashboards, selectedDashboard, onSelectDashboard }: IConnectionDashboardSelect) {
  function handleSelectDashboard(e: ChangeEvent<{ value: unknown }>) {
    onSelectDashboard(e.target.value as string);
  }

  return (
    <StyledFormControl>
      <InputLabel id='dashboard-select-label'>Select Dashboard</InputLabel>
      <Select
        data-testid='connection-dashboard-select'
        value={selectedDashboard}
        labelId='dashboard-select-label'
        onChange={handleSelectDashboard}
      >
        {dashboards.map(({ id, title }) => (
          <MenuItem value={id} key={id}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default ConnectionDashboardSelect;
