const getDefaultRules = () => ({
  isValid: false,
  isLengthValid: false,
  qualifiedRules: {
    isLowerCasePresent: false,
    isUpperCasePresent: false,
    areNumbersPresent: false,
    areSpecialCharactersPresent: false,
  },
  isLessThanTwoEqualSymbolsInRow: false,
});

export function checkRules(str: string) {
  const rules = getDefaultRules();
  const strToCheck = str.trim();

  if (strToCheck.length === 0) {
    return rules;
  }
  rules.isLengthValid = isLengthValid(strToCheck);

  rules.qualifiedRules.isLowerCasePresent = isLowerCasePresent(strToCheck);
  rules.qualifiedRules.isUpperCasePresent = isUpperCasePresent(strToCheck);
  rules.qualifiedRules.areNumbersPresent = areNumbersPresent(strToCheck);
  rules.qualifiedRules.areSpecialCharactersPresent = areSpecialCharactersPresent(strToCheck);

  rules.isLessThanTwoEqualSymbolsInRow = isLessThanTwoEqualSymbolsInRow(strToCheck);

  const passesQualificationRules = Object.values(rules.qualifiedRules).reduce((acc, cur) => {
    if (cur) {
      acc += 1;
    }

    return acc;
  }, 0);

  rules.isValid = passesQualificationRules > 2 && rules.isLengthValid && rules.isLessThanTwoEqualSymbolsInRow;

  return rules;
}

function isLengthValid(str: string) {
  return str.length > 7;
}

function isLowerCasePresent(str: string) {
  return /[a-z]/.test(str);
}

function isUpperCasePresent(str: string) {
  return /[A-Z]/.test(str);
}

function areNumbersPresent(str: string) {
  return /[0-9]/.test(str);
}

function areSpecialCharactersPresent(str: string) {
  const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

function isLessThanTwoEqualSymbolsInRow(str: string) {
  let prevSym = '';
  let counts = 0;
  for (let i = 0; i < str.length; i++) {
    if (i === 0) {
      prevSym = str[i];
      counts += 1;
      continue;
    }

    const curSym = str[i];
    if (prevSym === curSym) {
      counts += 1;
      if (counts > 2) {
        return false;
      }
    } else {
      prevSym = curSym;
      counts = 1;
    }
  }

  return true;
}
