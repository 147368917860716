import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import SegmentsRowContainer from '../components/SegmentsRow/SegmentsRowContainer';
import TrendsWidgetChart from './Chart/TrendsWidgetChart';
import { useColorsBySelectedSavedFilterId } from '../../overview/lib';
import { TRENDS_DISPLAY_MODE } from './Settings/TrendsWidgetSettings';
import { prepareTrends } from './lib';
import { exportTrendsBreakdown, exportTrendsPlain, getTimestampString } from '../../tools/export';
import { WidgetTotalsContext } from '../WidgetTotalsContext';
import useSelectedFilterIdsToggle from '../../../hooks/useSelectedFilterIdsToggle';
import LoadingOverlay from '../../UI/LoadingOverlay';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { getAppliedFiltersCount } from '../../UI/lib';
import { DATA_SOURCE_TYPE } from '../../../constants';

const TrendsWidgetChartWrapper = ({
  config,
  loading,
  plainResponses,
  breakdownFilter,
  filters,
  refCsvExport,
  refContent,
  refFiltersCount,
}) => {
  const { loading: totalsLoading, totalsByDataSourcesAndFiltersIds } = useContext(WidgetTotalsContext);
  const colorByFilterId = useColorsBySelectedSavedFilterId();
  const { weightedMetricsUsed, translationUsed } = useSelector((state) => state.app);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);

  refFiltersCount.current = selectedFilterIds.length;

  const isPercentage = config.settings.displayMode === TRENDS_DISPLAY_MODE.PERCENTAGE;
  const groupBy = config.settings.aggregationRange;
  const dataSourceID = config.settings.dataSources[0].id;

  refCsvExport.current = () => {
    const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceID);
    const titleField = translationUsed ? 'title' : 'originalTitle';
    const title = dashboardDataSource[titleField];
    const isOpenEnded = dashboardDataSource.type === DATA_SOURCE_TYPE.OPEN_ENDED;
    const dataSource = dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceID);
    const selectedItems =
      config.settings.dataSources[0].selectedItems.length > 0
        ? config.settings.dataSources[0].selectedItems
            .map((selectedItem) => dataSource.items.find((item) => item.response === selectedItem))
            .filter((item) => !!item)
        : dataSource.items;
    const timestampString = getTimestampString();
    if (breakdownFilter) {
      const breakdownTitle = dashboardDataSources.find(
        (dashboardDataSource) => dashboardDataSource.dataSourceID === breakdownFilter.dataSourceID,
      )[titleField];
      const filename = `${title} (breakdown by ${breakdownTitle}) - Trends by ${config.settings.aggregationRange}s - ${timestampString}`;
      const breakdownDataSource = dataSources.find((dataSource) => dataSource.dataSourceID === breakdownFilter.dataSourceID);
      const breakdownItems = breakdownFilter.values.map((value) => breakdownDataSource.items.find((item) => item.response === value));
      exportTrendsBreakdown(
        plainResponses,
        breakdownItems,
        filters,
        groupBy,
        selectedItems,
        selectedFilterIds,
        translationUsed,
        weightedMetricsUsed,
        filename,
        isOpenEnded,
      );
    } else {
      const filename = `${title} - Trends by ${config.settings.aggregationRange}s - ${timestampString}`;
      exportTrendsPlain(
        plainResponses,
        filters,
        groupBy,
        selectedItems,
        selectedFilterIds,
        translationUsed,
        weightedMetricsUsed,
        filename,
        isOpenEnded,
      );
    }
  };

  const average = config.settings.merge && getAppliedFiltersCount(filters) > 0;
  const trends = useMemo(() => {
    if (loading) {
      return [];
    }

    return prepareTrends(
      plainResponses,
      breakdownFilter,
      colorByFilterId,
      groupBy,
      selectedFilterIds,
      translationUsed,
      weightedMetricsUsed,
      average,
    );
  }, [
    loading,
    plainResponses,
    breakdownFilter,
    colorByFilterId,
    groupBy,
    selectedFilterIds,
    translationUsed,
    weightedMetricsUsed,
    average,
  ]);

  const showFilters = getAppliedFiltersCount(filters) > 0;

  const handleToggleView = (state) => {
    eventsTracker.track(EVENTS.WIDGET_TRENDS_VIEW_TOGGLE, {
      'Widget Name': config.base.title,
      'View Setting': state,
    });
  };

  return (
    <>
      <Grid item style={{ display: 'flex', flex: '1', width: '100%' }} ref={refContent}>
        <Grid container direction='column'>
          <Grid item>
            <SegmentsRowContainer
              onChange={setSelectedFilterIds}
              value={selectedFilterIds}
              filters={filters}
              totalsPerFilter={totalsByDataSourcesAndFiltersIds[dataSourceID]}
              loading={totalsLoading}
              widgetTitle={config.base.title}
            />
          </Grid>
          <Grid item style={{ width: '100%', height: '0', flex: '1' }}>
            <TrendsWidgetChart
              trends={trends}
              isPercentage={isPercentage}
              period={groupBy}
              showFilters={showFilters}
              showBreakdown={!!breakdownFilter}
              handleToggleView={handleToggleView}
            />
          </Grid>
        </Grid>
      </Grid>
      {loading && <LoadingOverlay />}
    </>
  );
};

export default TrendsWidgetChartWrapper;
