import Color from 'color';

export const orange1 = new Color('#EA8A01');
export const orange2 = new Color('#EA4701');
export const orange3 = new Color('#EE6169');
export const green1 = new Color('#008402');
export const green2 = new Color('#2ABA01');
export const purple1 = new Color('#8645F1');
export const purple2 = new Color('#4408BF');
export const purple3 = new Color('#960061');
export const purple4 = new Color('#A604F0');
export const blue1 = new Color('#8645F1');
export const blue2 = new Color('#014E85');
export const blue3 = new Color('#008384');
export const blue4 = new Color('#00BEBA');
export const blue5 = new Color('#0011F7');
export const blue6 = new Color('#33B0FC');
export const pink1 = new Color('#D8269A');
export const pink2 = new Color('#F976D5');
export const red1 = new Color('#9E040D');
export const red2 = new Color('#D0012E');
export const yellow1 = new Color('#EAC101');

// export const colors = [orange1, green1, purple1, blue1, pink1, blue2, red1, orange2, blue3,
// 	purple2, blue4, blue5, purple3, orange3, yellow1, green2, purple4, pink2, blue6, red2 ];

export const segmentColors = [blue6, blue3, blue2];
export const colors = [blue1, blue2, blue3, blue5, blue6];
export default colors;
