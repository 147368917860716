import { MaterialValueSelector } from './MaterialValueSelector';
import Input from '@material-ui/core/Input';
import { useValueEditor } from './useValueEditor';
import { MaterialAutoComplete } from './MaterialAutoComplete';
import ControlDatePicker from './ControlDatePicker';
import ControlDateRangeSelector from './ControlDateRangeSelector';
import { OPERATOR_NAME } from '../constants';

export const MaterialValueEditor = ({
  fieldData,
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values = [],
  listsAsArrays,
  valueSource: _vs,
  disabled,
  testID,
  ...props
}) => {
  useValueEditor({ handleOnChange, inputType, operator, value });

  if (operator === 'null' || operator === 'notNull') {
    return null;
  }

  const placeHolderText = fieldData?.placeholder ?? '';
  const inputTypeCoerced = ['between', 'notBetween', 'in', 'notIn'].includes(operator) ? 'text' : inputType || 'text';

  switch (type) {
    case 'multiselect':
    case 'select':
      return (
        <MaterialValueSelector
          {...props}
          className={className}
          handleOnChange={handleOnChange}
          options={values}
          value={value}
          disabled={disabled}
          title={title}
          multiple={type === 'multiselect'}
          listsAsArrays={listsAsArrays}
        />
      );
    case 'labelSelector':
      return (
        <MaterialAutoComplete
          {...props}
          className={className}
          handleOnChange={handleOnChange}
          options={values}
          value={value}
          disabled={disabled}
          title={title}
          listsAsArrays={listsAsArrays}
          testID={testID}
        />
      );
    case 'date':
      if (operator === OPERATOR_NAME.DATE_RANGE) {
        return <ControlDateRangeSelector {...props} value={value} onChange={handleOnChange} testID={testID} />;
      }
      return <ControlDatePicker disabled={disabled} value={value} onChange={handleOnChange} testID={testID} />;
    default:
      return (
        <Input
          type={inputTypeCoerced}
          value={value}
          title={title}
          disabled={disabled}
          className={className}
          placeholder={placeHolderText}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
  }
};

MaterialValueEditor.displayName = 'MaterialValueEditor';
