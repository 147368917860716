import React, { ReactElement } from 'react';

import { BorderedContainer, ResultsContainer, Title } from './style';

// TODO: use in for OE widget

interface IWidgetElement {
  title: string;
  dataTestId?: string;
  renderChild: () => ReactElement;
}

function WidgetElement({ title, dataTestId, renderChild }: IWidgetElement) {
  return (
    <BorderedContainer data-testid={dataTestId}>
      <ResultsContainer>
        <Title>{title} </Title>
        {renderChild()}
      </ResultsContainer>
    </BorderedContainer>
  );
}

export default WidgetElement;
