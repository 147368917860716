import styled from 'styled-components';

import { DropdownElement } from './styles';

import { ReactComponent as CheckboxUncheckedIcon } from '../../../assets/icons/checkbox-unchecked-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/icons/checkbox-checked-icon.svg';

const Checkbox = styled(CheckboxCheckedIcon)`
  margin-right: 12px;
  cursor: pointer;
`;

const CheckboxUnchecked = styled(CheckboxUncheckedIcon)`
  margin-right: 12px;
  cursor: pointer;
`;

const DropdownElementContainer = styled.div`
  max-height: 240px;
  overflow: auto;
`;

interface IUploadDropdownElements {
  elements: string[];
  selectedElements: string[];
  paddingHorizontal: string;
  handleCheckboxChange: (value: string) => void;
}

function UploadDropdownElements({ elements, selectedElements, paddingHorizontal, handleCheckboxChange }: IUploadDropdownElements) {
  return (
    <DropdownElementContainer data-testid='dashboard-dropdown-container'>
      {elements.map((element) => (
        <DropdownElement key={element} paddingHorizontal={paddingHorizontal}>
          {selectedElements && selectedElements.includes(element) ? (
            <Checkbox onClick={handleCheckboxChange.bind(null, element)} />
          ) : (
            <CheckboxUnchecked onClick={handleCheckboxChange.bind(null, element)} />
          )}
          {element}
        </DropdownElement>
      ))}
    </DropdownElementContainer>
  );
}

export default UploadDropdownElements;
