import { useMemo } from 'react';
import useFilters from '../../../../hooks/useFilters';
import { FILTER_ID_ALL } from '../../../../constants';
import { WidgetDataSource } from '../../../../types/DataSource';
import { Filter } from '../../../../types/Query';
import { ResponseListItem } from '../../../overview/lib';
import { prepareLabelsList } from '../../utils/lib';
import { SortingQuery } from '../../../../types/SortingQuery';

export function useDataFilters(preview: boolean): {
  filters: Filter[];
  filtersById: { [key: string]: Filter };
} {
  const [origFilters, filtersById] = useFilters();
  const filters = useMemo(() => {
    return preview ? ((origFilters as Filter[]).filter((filter) => filter.id === FILTER_ID_ALL) as Filter[]) : (origFilters as Filter[]);
  }, [origFilters, preview]);

  return { filters, filtersById };
}

export function useLabels(
  overviewDataById: Map<string, ResponseListItem>,
  dataSource: WidgetDataSource,
  filtersById: { [key: string]: Filter },
  selectedFilterIds: string[],
  selectedItems: string[],
  sorting: SortingQuery,
) {
  const labels = useMemo(() => {
    const ids = [...overviewDataById.keys()];
    if (!selectedFilterIds.every((id) => ids.includes(id))) {
      return [];
    }
    return prepareLabelsList(overviewDataById, dataSource, filtersById, selectedFilterIds, false, selectedItems, sorting);
  }, [overviewDataById, dataSource, filtersById, selectedFilterIds, false, selectedItems, sorting]);

  return { labels };
}
