import React from 'react';
import { useIntercom } from 'react-use-intercom';
import QualtricsInstructions from './QualtricsInstructions';

function QualtricsInstructionsContainer() {
  const { showNewMessages } = useIntercom();

  function handleShowHelpMessageClick() {
    showNewMessages();
  }

  return <QualtricsInstructions onShowHelpMessageClick={handleShowHelpMessageClick} />;
}

export default QualtricsInstructionsContainer;
