import React from 'react';

import SegmentItem from './SegmentItem';
import { FILTER_ID_ALL } from '../../../../constants';
import { Filter } from '../../../../types/Query';
import { TotalsByDataSourcesAndFiltersIDS } from '../../OpenEndedWidget/Data/OpenWidgetData';
import { Segment, Segments } from './styles';

interface ISegmentsRow {
  selectedFilterIds: string[];
  filters: Filter[];
  totalsPerFilter: TotalsByDataSourcesAndFiltersIDS;
  weightedMetricsUsed: boolean;
  disabled?: boolean;
  loading?: boolean;
  onSegmentClick: (filter: Filter) => void;
}
function SegmentsRow({
  selectedFilterIds,
  filters,
  totalsPerFilter,
  disabled = false,
  loading = false,
  weightedMetricsUsed,
  onSegmentClick,
}: ISegmentsRow) {
  return <Segments data-testid='widget-filter-buttons'>{filters.map(renderSegment)}</Segments>;

  function renderSegment(filter: Filter) {
    let total = null;
    if (filter.id !== FILTER_ID_ALL && totalsPerFilter) {
      total = totalsPerFilter[filter.id] ? totalsPerFilter[filter.id][weightedMetricsUsed ? 'weighted' : 'base'] : 0;
    }

    return (
      <Segment key={filter.id}>
        <SegmentItem
          selected={selectedFilterIds.includes(filter.id)}
          disabled={disabled}
          text={filter.name}
          total={total}
          loading={loading}
          color={filter.color}
          onClick={onSegmentClick.bind(null, filter)}
        />
      </Segment>
    );
  }
}

export default SegmentsRow;
