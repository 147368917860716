import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';

import {
  DATA_SOURCE_TYPE,
  FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE,
  LOG_MESSAGE_LEVEL,
  LOG_MESSAGE_TYPE,
  WIDGET_TYPE,
} from '../../constants';
import LoadingLabel from '../LoadingLabel/LoadingLabel';
import LandingError from '../Landing/LandingError';
import useFormatLogMessage from '../../hooks/useFormatLogMessage';
import { TestsContext } from '../../contexts/TestsContext';
import { getTestsLinks, withoutValidation } from '../tools';
import { recursiveValidation } from '../../contexts/lib';

const DATASOURCE_MAX_ITEMS_NUMBER = 1000;

const ValidationWrapper = ({ children }) => {
  const { isUnsafe, isDebug } = useContext(TestsContext);
  const links = getTestsLinks();
  const isInternal = useSelector((state) => state.profile.user.isInternal);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const widgets = useSelector((state) => state.dashboard.widgets);
  const widgetGroups = useSelector((state) => state.dashboard.widgetGroups);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const responsesByDataSourceId = useSelector((state) => state.dashboard.responsesByDataSourceId);
  const [isValidating, setIsValidating] = useState(true);
  const [messages, setMessages] = useState([]);
  const formatLogMessage = useFormatLogMessage();
  const overviewWidgetConfig = widgets.find((w) => w.base.type === 'overview');

  const messageBase = {
    unsafeLink: links.unsafe,
    debugLink: links.debug,
    validationLink: links.validation,
  };

  useEffect(() => {
    // We don't run these validations for our customers because it might greatly slow down the page rendering
    if (!isInternal || (isInternal && withoutValidation())) {
      setIsValidating(false);
      return;
    }

    const t0 = performance.now();
    const dashboardDataSourcesById = dashboardDataSources.reduce(
      (acc, dashboardDataSource) => ({
        ...acc,
        [dashboardDataSource.dataSourceID]: dashboardDataSource,
      }),
      {},
    );
    const validationMessages = [];

    const openEndedDataSourceIDs = dashboardDataSources
      .filter((dashboardDataSource) => dashboardDataSource.type === DATA_SOURCE_TYPE.OPEN_ENDED)
      .map((dashboardDataSource) => dashboardDataSource.dataSourceID);

    for (let dataSource of dataSources) {
      const dataSourceTitle = dashboardDataSourcesById[dataSource.dataSourceID].title;

      // filter out invisible data sources because they are not processed in the app
      if (!dashboardDataSourcesById[dataSource.dataSourceID].visible) {
        continue;
      }

      // check if items number is beyond the limit
      if (dataSource.items.length > DATASOURCE_MAX_ITEMS_NUMBER) {
        const logMessage = formatLogMessage('Max items number is reached');
        const debugMessage = `Max items number is reached (${dataSource.items.length}), it could slow down the browser. Data source: ${dataSourceTitle}`;
        validationMessages.push({
          ...messageBase,
          logMessage,
          debugMessage,
          level: LOG_MESSAGE_LEVEL.WARNING,
          type: LOG_MESSAGE_TYPE.TOO_MANY_ITEMS,
        });
      }

      // check total percentage
      if (
        dashboardDataSourcesById[dataSource.dataSourceID].type === DATA_SOURCE_TYPE.OTHER &&
        overviewWidgetConfig &&
        overviewWidgetConfig.settings.dataSources.some((ds) => ds.id === dataSource.dataSourceID)
      ) {
        const sum = dataSource.items.reduce(
          (acc, item) => {
            return {
              base: acc.base + item.base.percentage,
              weighted: acc.weighted + item.weighted.percentage,
            };
          },
          { base: 0, weighted: 0 },
        );
        if (
          sum.base.toFixed(FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE) > 100 ||
          sum.weighted.toFixed(FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE) > 100
        ) {
          const logMessage = formatLogMessage('Total percentage is over 100');
          const debugMessage = `Total percentage is over 100 (base: ${sum.base}, weighted: ${sum.weighted}). Data source: ${dataSourceTitle}`;
          validationMessages.push({
            ...messageBase,
            logMessage,
            debugMessage,
            level: LOG_MESSAGE_LEVEL.ERROR,
            type: LOG_MESSAGE_TYPE.TOTAL_PERCENTAGE_IS_OVER_100,
          });
        }
      }

      // validate open-ended data sources
      if (openEndedDataSourceIDs.includes(dataSource.dataSourceID)) {
        const labelToParentMap = new Map();
        // detect case when item belongs to multiple parents
        for (let item of dataSource.items) {
          if (item.children) {
            for (let child of item.children) {
              if (labelToParentMap.has(child)) {
                const logMessage = formatLogMessage('Label belongs to multiple parents');
                const debugMessage = `"${child}" label belongs to two parents: "${item.response}" and "${labelToParentMap.get(child)}". Data source: ${dataSourceTitle}`;
                validationMessages.push({
                  ...messageBase,
                  logMessage,
                  debugMessage,
                  level: LOG_MESSAGE_LEVEL.ERROR,
                  type: LOG_MESSAGE_TYPE.LABEL_WITH_MULTIPLE_PARENTS,
                });
              } else {
                labelToParentMap.set(child, item.response);
              }
            }
          }
        }

        const children = [...labelToParentMap.keys()];
        for (let item of dataSource.items) {
          if (item.children) {
            // detect case when item is a child and a parent at the same time
            if (children.includes(item.response)) {
              const logMessage = formatLogMessage('Label is used as a parent and a child');
              const debugMessage = `"${item.response}" is used as a child and a parent. Data source: ${dataSourceTitle}`;
              validationMessages.push({
                ...messageBase,
                logMessage,
                debugMessage,
                level: LOG_MESSAGE_LEVEL.ERROR,
                type: LOG_MESSAGE_TYPE.LABEL_IS_USED_AS_A_PARENT_AND_A_CHILD,
              });
            }
          }
        }
      }
    }

    const attachedWidgetIds = widgetGroups.reduce((acc, widgetGroup) => {
      const widgetIds = widgetGroup.layout.map((item) => item.widgetID);
      return acc.concat(widgetIds);
    }, []);

    // validate widgets config
    for (let widget of widgets) {
      if (attachedWidgetIds.includes(widget.base.id)) {
        if (widget.base.type !== WIDGET_TYPE.OVERVIEW && !widget.validation.valid) {
          const missedDataSourceIds = widget.validation.errors.missedDataSourceIds;
          const missedLabelsByDataSourceId = widget.validation.errors.missedLabelsByDataSourceId;
          if (missedDataSourceIds || missedLabelsByDataSourceId) {
            const lines = [`"${widget.base.title}" widget has invalid configuration.`];
            if (missedDataSourceIds) {
              lines.push(`Missed data source ids: ${missedDataSourceIds.join(', ')}`);
            }
            if (missedLabelsByDataSourceId) {
              lines.push('Missed labels:');
              for (let [id, labels] of Object.entries(missedLabelsByDataSourceId)) {
                lines.push(`\t${dashboardDataSourcesById[id].title}: ${labels.join(', ')}`);
              }
            }
            const logMessage = formatLogMessage('Invalid widget configuration');
            const debugMessage = lines.join('\n');
            validationMessages.push({
              ...messageBase,
              logMessage,
              debugMessage,
              silent: true,
              level: LOG_MESSAGE_LEVEL.ERROR,
              type: LOG_MESSAGE_TYPE.INVALID_WIDGET_CONFIGURATION,
            });
          }
        }
        // validate widget filter
        if (!!widget.base?.filter?.query) {
          const { valid, missedValues, missedDataSourceIds } = recursiveValidation(widget.base.filter.query, responsesByDataSourceId);
          if (!valid) {
            const lines = [
              'Invalid widget filter',
              `Widget: ${widget.base.title}`,
              `Missed data source ids: ${missedDataSourceIds.join(', ')}`,
              `Missed values: ${missedValues.join(', ')}`,
            ];
            const logMessage = formatLogMessage('Invalid widget filter');
            const debugMessage = lines.join('\n');
            validationMessages.push({
              ...messageBase,
              logMessage,
              debugMessage,
              silent: true,
              level: LOG_MESSAGE_LEVEL.WARNING,
              type: LOG_MESSAGE_TYPE.INVALID_WIDGET_FILTER,
            });
          }
        }
      } else {
        const lines = [`"${widget.base.title}" widget is detached.`, `Widget ID: ${widget.base.id}`];
        const logMessage = formatLogMessage('Detached widget');
        const debugMessage = lines.join('\n');
        validationMessages.push({
          ...messageBase,
          logMessage,
          debugMessage,
          silent: true,
          level: LOG_MESSAGE_LEVEL.WARNING,
          type: LOG_MESSAGE_TYPE.DETACHED_WIDGET,
        });
      }
    }

    const t1 = performance.now();
    if (validationMessages.length > 0 || isDebug) {
      const message = `Validation took ${t1 - t0} milliseconds.`;
      validationMessages.push({
        level: LOG_MESSAGE_LEVEL.INFO,
        type: LOG_MESSAGE_TYPE.VALIDATION_COMPLETE,
        logMessage: message,
        debugMessage: message,
      });
    }

    for (let message of validationMessages) {
      const { level, logMessage, debugMessage, ...context } = message;
      switch (level) {
        case LOG_MESSAGE_LEVEL.ERROR:
          datadogLogs.logger.error(logMessage, context);
          break;
        case LOG_MESSAGE_LEVEL.WARNING:
          datadogLogs.logger.warn(logMessage, context);
          break;
        default:
          datadogLogs.logger.info(logMessage, context);
      }
    }

    setMessages(validationMessages);
    setIsValidating(false);
  }, []);

  if (isValidating) {
    return <LoadingLabel />;
  }

  const hasError = messages.some((message) => message.level === LOG_MESSAGE_LEVEL.ERROR && !message.silent);

  if (hasError || isDebug) {
    if (isUnsafe) {
      messages.forEach((message) => console.log(`[${message.level}]\n${message.debugMessage}`));
      return children;
    }
    const debug = isDebug ? messages.map((message) => `[${message.level}]\n${message.debugMessage}`).join('\n\n') : null;
    return <LandingError debug={debug} />;
  }

  return children;
};

export default ValidationWrapper;
