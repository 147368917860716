import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

export const ControlAddActionButton = ({
  className,
  handleOnClick,
  label,
  title,
  disabled,
  disabledTranslation,
  testID,
  // Props that should not be in extraProps
  testID: _testID,
  rules: _rules,
  level: _level,
  path: _path,
  context: _context,
  validation: _validation,
  ...extraProps
}) => {
  const displayTitle = disabledTranslation && disabled ? disabledTranslation.title : title;
  return (
    <span data-tooltip-id='base-tooltip' data-tooltip-content={displayTitle}>
      <Button
        startIcon={<AddIcon />}
        style={{ textTransform: 'none' }}
        className={className}
        size='small'
        disabled={disabled && !disabledTranslation}
        onClick={(e) => handleOnClick(e)}
        data-testid={testID}
        {...extraProps}
      >
        {disabledTranslation && disabled ? disabledTranslation.label : label}
      </Button>
    </span>
  );
};

ControlAddActionButton.displayName = 'ControlAddActionButton';
