import { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { WIDGET_TYPE } from '../../constants';

const WidgetExportMenu = ({ onCsvClick, onPngClick, groupTitle, config, refFiltersCount }) => {
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const [anchorEl, setAnchorEl] = useState(null);

  const track = (type) => {
    eventsTracker.track(EVENTS.WIDGET_EXPORT, {
      'Export Type': type,
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Filter Count': refFiltersCount.current,
      'Widget Filter': config.base.filter && !!(config.base.filter.filterID || config.base.filter.query),
      'Weighted Metrics Applied': weightedMetricsUsed,
      'Breakdown Applied': !!breakdownFilter,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCsvClick = () => {
    track('CSV');
    onCsvClick();
    handleClose();
  };

  const handlePngClick = () => {
    track('PNG');
    onPngClick();
    handleClose();
  };

  return (
    <>
      <GetAppIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleCsvClick} data-action='edit'>
          CSV
        </MenuItem>
        <MenuItem onClick={handlePngClick} data-action='edit'>
          PNG
        </MenuItem>
      </Menu>
    </>
  );
};

export default WidgetExportMenu;
