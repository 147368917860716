import { useState } from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FormControl from '@material-ui/core/FormControl';

import { SaveButton } from '../../UI';
import DashboardGenerationDialog from '../../DashboardGenerationDialog/DashboardGenerationDialog';
import { DASHBOARD_TEMPLATE_ID } from '../../../constants';

const EmptyDashboard = () => {
  const [templateId, setTemplateId] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const handleTemplateChange = (event) => {
    setTemplateId(event.target.value);
  };

  const handleStartClick = () => {
    setShowProgress(true);
  };

  return (
    <>
      <Grid container direction='column' alignContent='center' style={{ width: '100%' }}>
        <Grid item style={{ textAlign: 'center', marginTop: '24px' }}>
          <DashboardOutlinedIcon style={{ color: '#FB00C2', fontSize: '128px' }} />
          <AddToPhotosOutlinedIcon style={{ color: '#F76D20', fontSize: '128px' }} />
        </Grid>
        <Grid item style={{ textAlign: 'center', fontSize: '18px' }}>
          Add a widget to get started
          <br />
          or generate a dashboard from a template.
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            width: '100%',
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormControl variant='outlined' size='small'>
            <Select
              placeholder={'Select template'}
              value={templateId}
              onChange={handleTemplateChange}
              displayEmpty
              style={{ fontSize: '14px' }}
            >
              {!templateId && (
                <MenuItem value='' disabled style={{ fontSize: '14px' }}>
                  Select template
                </MenuItem>
              )}
              <MenuItem value={DASHBOARD_TEMPLATE_ID.BASIC}>Basic</MenuItem>
            </Select>
          </FormControl>
          {templateId && (
            <SaveButton onClick={handleStartClick} style={{ marginLeft: '5px' }}>
              Start
            </SaveButton>
          )}
        </Grid>
      </Grid>
      <DashboardGenerationDialog open={showProgress} onClose={() => setShowProgress(false)} templateId={templateId} />
    </>
  );
};

export default EmptyDashboard;
