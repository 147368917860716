import { useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import RGL, { WidthProvider } from 'react-grid-layout';
import difference from 'lodash/difference';

import { RGL_MARGIN_VALUE, RGL_ROW_HEIGHT } from '../overview/lib';
import Widget from '../Widgets/Widget';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { dashboardUpdateGroupLayout, dashboardWidgetDimensionChange } from '../../store/thunks/dashboard';
import { useAppDispatch } from '../../store/hooks';

const ReactGridLayout = WidthProvider(RGL);

const RGL_MARGIN = [RGL_MARGIN_VALUE, RGL_MARGIN_VALUE];
const RGL_EMPTY_MARGIN = [0, 0];

const WidgetsGroupLayout = ({ groupId, layout, withoutMargin, groupTitle }) => {
  const offset = useRef(null);
  const eventTimer = useRef(null);
  const isDragOutsideTriggered = useRef(false);
  const isDragged = useRef(false);
  const dispatch = useAppDispatch();
  const handleDimensionsChange = useCallback(
    (id, width, height, save = false) => {
      dispatch(dashboardWidgetDimensionChange(groupId, id, width, height, save));
    },
    [dispatch, groupId],
  );
  const prevWidgetIds = useRef(layout.map((item) => item.widgetID));

  let newWidgetIds = [];
  const currentWidgetIds = layout.map((item) => item.widgetID);
  if (!isEqual(currentWidgetIds, prevWidgetIds.current)) {
    newWidgetIds = difference(currentWidgetIds, prevWidgetIds.current);
    prevWidgetIds.current = [...currentWidgetIds];
  }

  const rglLayout = layout.map((item) => ({
    i: item.widgetID,
    x: item.x,
    y: item.y,
    w: item.width,
    h: item.height,
  }));
  const items = layout.map((item) => {
    return (
      <div key={item.widgetID} id={`w-${item.widgetID}`} unselectable='on' className={'widget-container'}>
        <Widget
          id={item.widgetID}
          onDimensionsChange={handleDimensionsChange}
          isNew={newWidgetIds.includes(item.widgetID)}
          groupTitle={groupTitle}
        />
      </div>
    );
  });

  const handleDragStop = (currentLayout) => {
    clearTimeout(eventTimer.current);
    eventTimer.current = null;
    isDragged.current = true;

    const prevLayout = layout.map((item) => ({
      x: item.x,
      y: item.y,
      h: item.height,
      w: item.width,
      i: item.widgetID,
    }));
    const curLayout = currentLayout.map((item) => {
      const { x, y, h, w, i } = item;
      return { x, y, h, w, i };
    });
    if (!isEqual(prevLayout, curLayout)) {
      const widgetId2Id = layout.reduce((acc, item) => ({ ...acc, [item.widgetID]: item.id }), {});
      const updatedLayout = curLayout.map((item) => ({
        id: widgetId2Id[item.i],
        widgetID: item.i,
        height: item.h,
        width: item.w,
        x: item.x,
        y: item.y,
      }));
      dispatch(dashboardUpdateGroupLayout(groupId, updatedLayout));
    }
  };

  const handleDrag = (layout, oldItem, newItem, placeholder, e, element) => {
    // Detect dragging out of a grid by comparing differences between event and element positions.
    // It's a constant while the user drags the item within a grid.
    // It changes when the user tries to move the item outside a grid because the element keeps the position and the event changes it.
    const { x, y } = element.getBoundingClientRect();
    const { screenX, screenY } = e;
    const currentOffset = { x: screenX - x, y: screenY - y };

    if (offset.current.x !== currentOffset.x || offset.current.y !== currentOffset.y) {
      offset.current = currentOffset;
      if (!eventTimer.current && !isDragOutsideTriggered.current) {
        eventTimer.current = setTimeout(() => {
          eventsTracker.track(EVENTS.WIDGET_DRAG_OUTSIDE, {
            'Group Name': groupTitle,
            'Widget ID': newItem.i,
          });
          isDragOutsideTriggered.current = true;
        }, 200);
      }
    } else {
      clearTimeout(eventTimer.current);
      eventTimer.current = null;
    }
  };

  const handleDragStart = (layout, oldItem, newItem, placeholder, e, element) => {
    const { x, y } = element.getBoundingClientRect();
    const { screenX, screenY } = e;
    offset.current = { x: screenX - x, y: screenY - y };
    isDragOutsideTriggered.current = false;
  };

  return (
    <ReactGridLayout
      className='layout'
      cols={2}
      containerPadding={[0, 0]} // workaround https://github.com/react-grid-layout/react-grid-layout/issues/1990
      margin={withoutMargin ? RGL_EMPTY_MARGIN : RGL_MARGIN}
      rowHeight={RGL_ROW_HEIGHT}
      layout={rglLayout}
      isDroppable={false}
      onDrag={handleDrag}
      onDragStart={handleDragStart}
      onDragStop={handleDragStop}
      isBounded={false}
      isResizable={false}
      autoSize={true}
      draggableHandle='.widget-draggable-handle'
    >
      {items}
    </ReactGridLayout>
  );
};

export default WidgetsGroupLayout;
