import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OverviewAnswerModel, OverviewItemModel } from '../../models/Overview';

const useSummaryPlainProcessing = (responses, visibleDataSourcesIds) => {
  const { weightedMetricsUsed, translationUsed } = useSelector((state) => state.app);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);

  return useMemo(() => {
    const valueType = weightedMetricsUsed ? 'weighted' : 'base';
    const data = [];
    responses.forEach((response) => {
      const list = visibleDataSourcesIds.map((dataSourceID) => {
        const dataSourceInfo = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceID);
        const data = response.find((data) => data.dataSourceID === dataSourceID);
        const items = data
          ? [...data.items]
              .sort((a, b) => (dataSourceInfo.ordered ? 1 : a[valueType].value - b[valueType].value))
              .map((item) => {
                const displayResponse = translationUsed ? item.response : item.originalResponse;
                const numbers = item[valueType];
                const sentiment = item.sentiment;
                const sentimentPercentage = item.sentimentPercentage;
                return new OverviewAnswerModel(
                  item.response,
                  item.originalResponse,
                  displayResponse,
                  numbers.value,
                  numbers.percentage,
                  item.children,
                  sentiment,
                  sentimentPercentage,
                );
              })
          : [];
        const title = translationUsed ? dataSourceInfo.title : dataSourceInfo.originalTitle;
        const model = new OverviewItemModel(
          dataSourceID,
          title,
          items,
          data ? data.total : 0,
          false,
          dataSourceInfo.ordered,
          dataSourceInfo.title,
          dataSourceInfo.originalTitle,
        );
        model.items = items;
        return model;
      });
      data.push({ items: list });
    });
    return data;
  }, [responses, dashboardDataSources, weightedMetricsUsed, translationUsed, visibleDataSourcesIds]);
};

export default useSummaryPlainProcessing;
