import styled from 'styled-components';

const Container = styled.div`
  flex: 0 0 320px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 280px;
  }
`;

const HelpMessageLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

interface IHelp {
  testID?: string;
  onSendHelpMessage: () => void;
}

function Help({ testID, onSendHelpMessage }: IHelp) {
  return (
    <Container data-testid={testID}>
      <div>
        Have any questions or concerns? Please{' '}
        <HelpMessageLink onClick={onSendHelpMessage}>reach out to a Beehive AI representative</HelpMessageLink>.
      </div>
    </Container>
  );
}

export default Help;
