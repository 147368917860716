import SvgIcon from '@material-ui/core/SvgIcon';
/*
 * Based on the https://fonts.google.com/icons?selected=Material%20Symbols%20Sharp%3Abalance%3AFILL%400%3Bwght%40100%3BGRAD%400%3Bopsz%4024
 */
const BalanceIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M3.3 19.85V19.15H11.65V7.825Q10.925 7.725 10.363 7.2Q9.8 6.675 9.675 5.85H6.15L9 13.125Q8.925 14.05 8.1 14.7Q7.275 15.35 6.15 15.35Q5.025 15.35 4.2 14.7Q3.375 14.05 3.3 13.125L6.15 5.85H4.3V5.15H9.675Q9.8 4.325 10.438 3.737Q11.075 3.15 12 3.15Q12.925 3.15 13.562 3.737Q14.2 4.325 14.325 5.15H19.7V5.85H17.85L20.7 13.125Q20.625 14.05 19.8 14.7Q18.975 15.35 17.85 15.35Q16.725 15.35 15.9 14.7Q15.075 14.05 15 13.125L17.85 5.85H14.325Q14.2 6.675 13.638 7.2Q13.075 7.725 12.35 7.825V19.15H20.7V19.85ZM15.8 13H19.9L17.85 7.775ZM4.1 13H8.2L6.15 7.775ZM12 7.15Q12.675 7.15 13.163 6.662Q13.65 6.175 13.65 5.5Q13.65 4.825 13.163 4.337Q12.675 3.85 12 3.85Q11.325 3.85 10.838 4.337Q10.35 4.825 10.35 5.5Q10.35 6.175 10.838 6.662Q11.325 7.15 12 7.15Z' />
  </SvgIcon>
);

export default BalanceIcon;
