import React, { useState } from 'react';
import { Concept } from '../../../../../../../store/reducers/aiInsights';
import GeneratedInsights from './GeneratedInsights';
import { GeneratedInsight } from '../../../../../../../store/actions/aiInsights';

export enum ThumbStatus {
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface IGeneratedInsightsContainer {
  id: string;
  availableFilters: string[];
  generatedInsights: GeneratedInsight[];
  relatedConcepts: Concept[];
  segmentsNames?: string[];
  customPrompt?: string;
  widgetSelectedConcept?: Concept;
  selectedConcept?: Concept;
  isDisabled?: boolean;
  onGenerateClick: (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => void;
  onClearAllInsights: () => void;
  onCopyClick: () => void;
  onThumbsClick: (thumbsStatus: ThumbStatus) => void;
  onQuoteClick: (insight: GeneratedInsight) => void;
}

function GeneratedInsightsContainer(props: IGeneratedInsightsContainer) {
  const [thumbStatus, setThumbs] = useState<undefined | ThumbStatus>();
  function handleThumbsClick(thumbsStatus: ThumbStatus) {
    setThumbs(thumbsStatus);
    props.onThumbsClick(thumbsStatus);
  }

  function handleCopyClick(html?: string, text?: string | null) {
    props.onCopyClick();
    const htmlContent = new Blob([html ?? ''], { type: 'text/html' });
    const textContent = new Blob([text ?? ''], { type: 'text/plain' });
    const data = [
      new ClipboardItem({
        'text/html': htmlContent,
        'text/plain': textContent,
      }),
    ];

    navigator.clipboard.write(data);
  }

  return (
    <GeneratedInsights
      {...props}
      relatedConcepts={props.relatedConcepts.filter((c) => c.isEnabled)}
      thumbStatus={thumbStatus}
      onThumbsClick={handleThumbsClick}
      onCopyClick={handleCopyClick}
    />
  );
}

export default GeneratedInsightsContainer;
