const CONFIG = {
  APP: {
    ENV: process.env.REACT_APP_ENV || 'develop',
    VERSION: process.env.REACT_APP_VERSION,
  },
  AUTH0: {
    DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  GOOGLE_ANALYTICS: {
    TRACKING_ID: process.env.REACT_APP_ENV_GOOGLE_ANALYTICS || 'G-SJ8LLQCVY5',
  },
  HOTJAR: {
    ID: 1742085,
    VERSION: 6,
  },
  INTERCOM: {
    APP_ID: 'gmpr0w36',
  },
  DATADOG: {
    CLIENT_TOKEN: 'pub35584d534993e3b4f4ca30e2bd2964f1',
  },
  MIXPANEL: {
    TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || 'c8d477b3b70d6b978a68c39726cf42dc', // develop token by default
  },
};

export default CONFIG;
