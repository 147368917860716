import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import capitalize from 'lodash/capitalize';

import { dashboardReset } from '../../store/actions/dashboard';
import LoadingLabel from '../../components/LoadingLabel/LoadingLabel';
import { SavedFiltersProvider } from '../../contexts/SavedFiltersContext';
import SavedFiltersURLController from '../../components/SavedFiltersURLController/SavedFiltersURLController';
import ValidationWrapper from '../../components/ValidationWrapper/ValidationWrapper';
import { TestsContext } from '../../contexts/TestsContext';
import { eventsTracker, EVENTS, EVENT_PROPERTY_DASHBOARD_NAME } from '../../services/EventTrackerService';
import DashboardContent from './DashboardContent';
import { resetAIInsights } from '../../store/actions/aiInsights';
import { dashboardFetch } from '../../store/thunks/dashboard';

const Dashboard = ({
  match: {
    params: { dashboardId },
  },
}) => {
  const { isUnsafe } = useContext(TestsContext);
  const { pathname } = useLocation();
  const { company } = useSelector((state) => state.profile);
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const fetched = useSelector((state) => state.dashboard.fetched);
  const loading = useSelector((state) => state.dashboard.loading);
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);
  const dispatch = useDispatch();
  const { update: intercomUpdate } = useIntercom();
  const tracked = useRef(false);

  useEffect(() => {
    return () => {
      dispatch(dashboardReset()); // reset dashboard state on unmount
      dispatch(resetAIInsights());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(dashboardFetch(dashboardId, isUnsafe));
  }, [dispatch, dashboardId, isUnsafe]);

  useEffect(() => {
    if (fetched && !tracked.current) {
      intercomUpdate({
        customAttributes: {
          company: company.displayName,
          dID: dashboard.id,
          dTitle: dashboard.title,
        },
      });
      const pathItems = pathname.split('/');
      eventsTracker.addToContext({
        [EVENT_PROPERTY_DASHBOARD_NAME]: dashboard.title,
      });
      eventsTracker.track(EVENTS.DASHBOARD_VISIT, {
        'Weighted Metrics': weightedMetricsUsed,
        'Dashboard Tab': pathItems[5] ? capitalize(pathItems[5]) : 'Responses',
        'Dashboard Access': !dashboard.underMaintenance,
      });
      tracked.current = true;
    }
  }, [fetched, dashboard, weightedMetricsUsed, pathname, tracked, company, intercomUpdate]);

  if (loading || !fetched || (dashboard && dashboard.id && dashboard.id !== dashboardId)) {
    return <LoadingLabel loading={true} style={{ marginTop: '30vh' }} />;
  }

  const isBlocked = dashboard.underMaintenance && !isUnsafe;

  return (
    <SavedFiltersProvider>
      <SavedFiltersURLController dashboardId={dashboardId} skipLoading={isBlocked}>
        <ValidationWrapper>
          <DashboardContent />
        </ValidationWrapper>
      </SavedFiltersURLController>
    </SavedFiltersProvider>
  );
};

export default Dashboard;
