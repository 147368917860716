import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useSegments from '../hooks/useSegments';
import { prepareSegments } from '../lib/lib';
import LoadingLabel from '../../../components/LoadingLabel/LoadingLabel';
import Segment from './Segment';
import { DashboardContainer } from '../../../components/dashboard/style';
import { FETCH_STATUS } from '../../../constants';

const Segments = ({ routeProps, responsesURL }) => {
  const dashboardId = useSelector((state) => state.dashboard.dashboard.id);
  const { data, totals, init, loadMore, status } = useSegments(dashboardId);
  const { weightedMetricsUsed, translationUsed } = useSelector((state) => state.app);

  const segments = prepareSegments(data, weightedMetricsUsed, translationUsed);
  const total = weightedMetricsUsed ? totals.weighted : totals.base;
  const loadedCount = segments.length;
  const loading = status === FETCH_STATUS.LOADING;

  useEffect(() => {
    init();
  }, [init]);

  const handleLoadMore = (offset) => {
    loadMore(offset);
  };

  let content = <LoadingLabel textContent='Loading Segments...' loading={true} style={{ marginTop: '30vh' }} />;
  let loadMoreControl = null;

  if (loadedCount !== null) {
    if (loadedCount > 0) {
      content = segments.map((segment, index) => {
        return <Segment key={index} responsesURL={responsesURL} routeProps={routeProps} segment={segment} />;
      });
    } else {
      content = <></>;
    }

    const loadingMoreButton = (
      <Button
        size='small'
        variant='contained'
        style={{
          textTransform: 'none',
          backgroundColor: '#a9dcfe',
          color: 'white',
        }}
        onClick={() => handleLoadMore(loadedCount)}
      >
        Load more
      </Button>
    );

    loadMoreControl = (
      <>
        <Box
          style={{
            textAlign: 'center',
            padding: '20px',
            fontSize: '12px',
            color: 'gray',
          }}
        >
          {!loading && (
            <>
              Viewing {loadedCount} of {total} Segments
            </>
          )}
          <Box style={{ paddingTop: '10px' }}>
            {!loading && loadedCount < total && loadingMoreButton}
            {loading && <LoadingLabel textContent='Loading Segments...' loading={true} />}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <DashboardContainer>
        {content}
        {loadMoreControl}
      </DashboardContainer>
    </>
  );
};

export default Segments;
