import { useMemo } from 'react';
import Color from 'color';
import { OverviewItemModel } from '../../models/Overview';
import { useAppSelector } from '../../store/hooks';
import { getSchemeColors } from '../../style/colors';
import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import { FILTER_ID_ALL, FILTER_ID_CURRENT } from '../../constants';
import { BreakdownData } from '../../types/BreakdownData';
import { Filter } from '../../types/Query';

export const RGL_ROW_HEIGHT = 10;
export const RGL_MARGIN_VALUE = 16;
export const OVERVIEW_GAP_BETWEEN_QUESTIONS = 10;
export const OVERVIEW_SEPARATOR_HEIGHT = 2;
export const OVERVIEW_BAR_HEIGHT = 70;

const colorCache = new Map();
export type TraceDataItemAnswer = {
  response: string;
  originalResponse: string;
  displayResponse: string;
  number: number;
  percent: number;
  realPercent: number;
  label: string;
  children?: any;
};
export type TraceDataItems = {
  id: string;
  question: string;
  answers: TraceDataItemAnswer[];
  peopleCount: number;
  type: string;
  ordered: boolean;
  title: string;
  originalTitle: string;
  items: TraceDataItemAnswer[];
};
export type TraceData = {
  items: TraceDataItems[];
};

export const generateColorMap = (tracesData: TraceData[], colorSchemeName: string) => {
  const colorMap = new Map();
  // prepare map based on question id and uniq answers
  for (const trace of tracesData) {
    for (const question of trace.items) {
      if (!colorMap.has(question.id)) {
        colorMap.set(question.id, new Map());
      }
      // order of assigning colors is the same as items order in the overview
      const answers = question.ordered ? question.answers : [...question.answers].sort((a, b) => b.percent - a.percent);
      for (const answer of answers) {
        colorMap.get(question.id).set(answer.response, '#FFFFFF');
      }
    }
  }

  // create palette of distinct colors for each question and assign colors to answers
  for (const questionId of colorMap.keys()) {
    const answersMap = colorMap.get(questionId);
    const colorCacheKey = `${colorSchemeName}-${answersMap.size}`;
    if (!colorCache.has(colorCacheKey)) {
      colorCache.set(colorCacheKey, getSchemeColors(colorSchemeName, answersMap.size));
    }
    let i = 0;
    for (const answer of answersMap.keys()) {
      answersMap.set(answer, colorCache.get(colorCacheKey)[i]);
      i++;
    }
  }
  return colorMap;
};

export const useColorsBySelectedSavedFilterId = () => {
  const { selectedSavedFiltersArray } = useSavedFilters();
  const colorSchemeName = useAppSelector((state) => state.app.colorSchemeName);

  return useMemo(() => {
    const schemeColors = getSchemeColors(colorSchemeName, selectedSavedFiltersArray.length + 2);
    const colors = schemeColors.map((color) => new Color(color).darken(0.25).hex());

    const colorsById: { [key: string]: string } = {
      [FILTER_ID_ALL]: colors.pop() as string,
      [FILTER_ID_CURRENT]: colors.pop() as string,
    };

    for (const savedFilterId of selectedSavedFiltersArray) {
      colorsById[savedFilterId] = colors.pop() as string;
    }

    return colorsById;
  }, [selectedSavedFiltersArray, colorSchemeName]);
};

export type ResponseListItem = { items: OverviewItemModel[] };
export const useDataBySelectedSavedFilterId = (responsesList: ResponseListItem[] = [], filters: Filter[]) => {
  return useMemo(() => {
    const map = new Map<string, ResponseListItem>();
    for (let index = 0; index < filters.length; index++) {
      if (responsesList[index]) {
        map.set(filters[index].id, responsesList[index]);
      }
    }
    return map;
  }, [responsesList, filters]);
};

export const generateOverviewRows = (
  tracesData: TraceData[],
  breakdownData: BreakdownData,
  sortedDataSources: { dataSourceID: string; title: string }[],
  filtersToShow: Filter[],
  sortedBuckets: string[],
  colorsMap: Map<string, Map<string, string>>,
) => {
  type RowInfo = {
    label: string;
    percent: number;
    realPercent: number;
    value: number;
    color: string;
  };
  const rows: RowInfo[][] = [];
  if (sortedBuckets.length) {
    for (const sortedDataSource of sortedDataSources) {
      const dataSourceId = sortedDataSource.dataSourceID;
      for (const filter of filtersToShow) {
        const filterId = filter.id;
        for (const bucket of sortedBuckets) {
          const dataSourceItems = breakdownData[filterId as string]?.[bucket as string]?.[dataSourceId as string]?.items || [];
          const items = dataSourceItems.map(
            (item) =>
              ({
                label: item.displayResponse,
                percent: +item.percentage!.toFixed(),
                realPercent: +item.percentage!,
                value: item.value,
                color: colorsMap.get(dataSourceId)!.get(item.response),
              }) as RowInfo,
          );
          rows.push(items);
        }
      }
    }
  } else {
    if (tracesData.length) {
      //@todo implement better way to skip initialization
      const length = sortedDataSources.length * filtersToShow.length;
      rows.push(...new Array(length));
      for (let i = 0; i < tracesData.length; i++) {
        for (const sortedDataSource of sortedDataSources) {
          const dataSourceId = sortedDataSource.dataSourceID;
          const dataSourceIndex = tracesData[i].items.findIndex((item: { id: any }) => item.id === dataSourceId);
          const pos = dataSourceIndex * tracesData.length + i;
          rows[pos] = tracesData[i].items[dataSourceIndex].items.map(
            (item) =>
              ({
                label: item.displayResponse,
                percent: item.percent,
                realPercent: item.realPercent,
                value: item.number,
                color: colorsMap.get(dataSourceId)!.get(item.response),
              }) as RowInfo,
          );
        }
      }
    }
  }
  return rows;
};

export const calculateQuestionHeight = (filtersCount: number, bucketsCount: number) => {
  const barsCount = filtersCount * (bucketsCount ? bucketsCount : 1);
  const separatorsHeight = (barsCount - 1) * OVERVIEW_SEPARATOR_HEIGHT;
  return OVERVIEW_BAR_HEIGHT * barsCount + separatorsHeight;
};

export const calculateChartHeight = (dataSourcesCount: number, filtersCount: number, bucketsCount: number) => {
  const questionHeight = calculateQuestionHeight(filtersCount, bucketsCount);
  return questionHeight * dataSourcesCount + OVERVIEW_GAP_BETWEEN_QUESTIONS * (dataSourcesCount - 1);
};
