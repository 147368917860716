import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import Loading from '../../components/LoadingLabel/LoadingLabel';

import HivesSidebar from './HivesSidebar';
import Hives from './Hives';
import HiveEditDialog from './HiveEditDialog';
import HiveDeleteConfirmationDialog from './HiveDeleteConfirmationDialog';
import DateRangeFilter, { DATE_RANGE, DATE_RANGE_ID } from './DateRangeFilter';
import { CreateNewButton } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EVENTS, EVENT_PROPERTY_DASHBOARD_NAME, eventsTracker } from '../../services/EventTrackerService';
import { filterReset } from '../../store/actions/dashboardFilter';
import { DASHBOARD_SORT_FIELD_NAME, SORT_ORDER } from '../../constants';
import AddDataDialog, { DATA_TYPES } from './AddDataDialog';
import FileUploadDialogContainer from '../../components/ConnectorsTab/AddCSV/FileUploadDialogContainer';
import {
  createDashboardsView,
  dashboardsInitialize,
  deleteDashboardsView,
  setDashboardsDateRange,
  updateDashboardsView,
} from '../../store/thunks/dashboards';
import HivesBannerContainer from './HivesBannerContainer';

function HivesTab() {
  const { user } = useSelector<any, any>((state) => state.profile);
  const [selectedHiveId, setSelectedHiveId] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showAddDataDialog, setShowAddDataDialog] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedHive, setSelectedHive] = useState<any>(null);
  const { initialized, dashboards, views, dateRange, status } = useSelector<any, any>((state) => state.dashboards);
  const dispatch = useDispatch();
  const history = useHistory();
  const dateRangeRef = useRef(DATE_RANGE[DATE_RANGE_ID.ALL]);

  useEffect(() => {
    if (!initialized) {
      dispatch(dashboardsInitialize());
    }
  }, [initialized, dispatch]);

  useEffect(() => {
    eventsTracker.removeFromContext([EVENT_PROPERTY_DASHBOARD_NAME]);
    eventsTracker.track(EVENTS.VIEW_COMPANY_DASHBOARDS);
    dispatch(filterReset());
  }, [dispatch]);

  const hives = useMemo(() => {
    const hives = [];
    const dashboardsById = dashboards.reduce((acc: any, dashboard: any) => ({ ...acc, [dashboard.id]: dashboard }), {});
    const ids = new Set(Object.keys(dashboardsById));
    for (const view of views) {
      const viewDashboards = [];
      if (view.dashboardIDs) {
        for (const dashboardID of view.dashboardIDs) {
          if (dashboardsById[dashboardID]) {
            viewDashboards.push(dashboardsById[dashboardID]);
            ids.delete(dashboardID);
          }
        }
        hives.push({
          ...view,
          dashboards: viewDashboards,
        });
      } else {
        hives.push({
          ...view,
          dashboards: [],
        });
      }
    }

    // add unattached dashboards to the first hive (it's a synthetic hive with id = null)
    if (ids.size > 0) {
      hives[0].dashboards = [...ids.values()].map((id) => dashboardsById[id]);
    }

    return hives;
  }, [views, dashboards]);

  function handleEditClick(hive: any) {
    if (hive) {
      eventsTracker.track(EVENTS.HIVE_EDIT_VIEW);
      setSelectedHive(hive);
    } else {
      eventsTracker.track(EVENTS.HIVE_NEW_CREATE);
      setSelectedHive({ dashboardIDs: [], title: '' });
    }
    setShowDialog(true);
  }

  function handleDialogClose() {
    eventsTracker.track(selectedHive.id ? EVENTS.HIVE_EDIT_CANCEL : EVENTS.HIVE_NEW_CANCEL);
    setShowDialog(false);
  }

  function handleDialogSaveClick(hive: any) {
    const { userID, ...baseView } = hive;
    if (hive.id) {
      eventsTracker.track(EVENTS.HIVE_EDIT_SAVE, {
        'Old Hive Name': selectedHive.title,
        'New Hive Name': hive.title,
        'Old Privacy': selectedHive.private,
        'New Privacy': hive.private,
        'Old Dashboard Count': selectedHive.dashboardIDs ? selectedHive.dashboardIDs.length : 0,
        'New Dashboard Count': hive.dashboardIDs.length,
      });
      dispatch(updateDashboardsView(hive));
    } else {
      eventsTracker.track(EVENTS.HIVE_NEW_SAVE, {
        'Hive Name': baseView.title,
        'Dashboard Count': baseView.dashboardIDs.length,
        Private: baseView.private,
      });
      dispatch(createDashboardsView(baseView, user.id));
    }
    setShowDialog(false);
  }
  function handleDialogDeleteClick() {
    setShowDialog(false);
    setShowConfirmation(true);
  }

  function handleRemoveConfirmation() {
    eventsTracker.track(EVENTS.HIVE_EDIT_DELETE, {
      'Hive Name': selectedHive.title,
    });
    dispatch(deleteDashboardsView(selectedHive));
    setShowConfirmation(false);
  }

  function handleConnectDataClick() {
    history.push('./connections');
  }

  function handleDateRangeChange(value: any, dr: any) {
    dispatch(setDashboardsDateRange(value));
    dateRangeRef.current = dr;
  }

  function handleHiveClick(hiveId: string) {
    const hive = hives.find((hive) => hive.id === hiveId);
    eventsTracker.track(EVENTS.HIVE_FILTER, { 'Hive Name': hive.title });
    setSelectedHiveId(hiveId);
  }

  function handleSort(field: string, order: string) {
    const hive = hives.find((hive) => hive.id === selectedHiveId);
    eventsTracker.track(EVENTS.DASHBOARD_SORT, {
      'Sort Criteria': DASHBOARD_SORT_FIELD_NAME[field],
      'Sort Order': order === SORT_ORDER.ASC ? 'Ascending' : 'Descending',
      'Time Filter': dateRangeRef.current.label,
      'Hive Filter': hive ? hive.title : '',
    });
  }

  function handleCancelDelete() {
    setShowConfirmation(false);
    setShowDialog(true);
  }

  function handleAddDataSelect(dataType: DATA_TYPES) {
    switch (dataType) {
      case DATA_TYPES.FILE:
        setFileUploadOpen(true);
        break;
      case DATA_TYPES.COLLECTION:
        handleConnectDataClick();
        break;
    }
  }

  function handleCreateNewClick() {
    setShowAddDataDialog(true);
  }

  return (
    <Box flexGrow={1}>
      {!initialized && <Loading loading={true} style={{ marginTop: '20px' }} />}
      {initialized && (
        <Grid container direction='row' style={{ padding: '20px' }}>
          <Grid item xs={2}>
            <HivesSidebar selectedHiveId={selectedHiveId} hives={hives} onClick={handleHiveClick} onEditClick={handleEditClick} />
          </Grid>
          <Grid item xs={10}>
            <HivesBannerContainer dashboardsLength={dashboards.length} />
            <Box display='flex' flexDirection='row'>
              <Box flexGrow={1}>
                {dashboards.length > 0 && <DateRangeFilter onChange={handleDateRangeChange} value={dateRange} status={status} />}
              </Box>
              <CreateNewButton
                data-tf-popup='qPCQQ73Y'
                variant='contained'
                color='primary'
                onClick={handleCreateNewClick}
                startIcon={<AddIcon />}
              >
                Upload Data
              </CreateNewButton>
            </Box>
            <Hives selectedHiveId={selectedHiveId} hives={hives} onEditClick={handleEditClick} onSort={handleSort} />
          </Grid>
        </Grid>
      )}

      <AddDataDialog open={showAddDataDialog} onSelect={handleAddDataSelect} onClose={setShowAddDataDialog.bind(null, false)} />
      <FileUploadDialogContainer isOpen={fileUploadOpen} onClose={setFileUploadOpen.bind(null, false)} />
      {showDialog && (
        <HiveEditDialog
          hive={selectedHive}
          dashboards={dashboards}
          onClose={handleDialogClose}
          onSave={handleDialogSaveClick}
          onDelete={handleDialogDeleteClick}
        />
      )}
      {showConfirmation && (
        <HiveDeleteConfirmationDialog
          onCancel={handleCancelDelete}
          onConfirm={handleRemoveConfirmation}
          open={showConfirmation}
          title={selectedHive.title}
        />
      )}
    </Box>
  );
}

export default HivesTab;
