// table to convert t-stat into p-value
// based on https://en.wikipedia.org/wiki/Student's_t-distribution#Table_of_selected_values for infinite degrees and two-sided critical regions
export const TPTable = [
  {
    t: 0.674,
    p: 0.5,
  },
  {
    t: 0.842,
    p: 0.4,
  },
  {
    t: 1.036,
    p: 0.3,
  },
  {
    t: 1.282,
    p: 0.2,
  },
  {
    t: 1.645,
    p: 0.1,
  },
  {
    t: 1.96,
    p: 0.05,
  },
  {
    t: 2.326,
    p: 0.02,
  },
  {
    t: 2.576,
    p: 0.01,
  },
  {
    t: 2.807,
    p: 0.005,
  },
  {
    t: 3.09,
    p: 0.002,
  },
  {
    t: 3.291,
    p: 0.001,
  },
];

/**
 * Returns t-stat based on percentages and numbers of elements
 * See more: https://help.surveymonkey.com/en/surveymonkey/analyze/significant-differences/
 * @param percentage1
 * @param percentage2
 * @param n1
 * @param n2
 */
export const calculateTStat = (percentage1: number, percentage2: number, n1: number, n2: number) => {
  const p1 = percentage1 / 100;
  const p2 = percentage2 / 100;
  const a1 = p1 * n1;
  const b1 = p2 * n2;
  const p = (a1 + b1) / (n1 + n2);
  const SE = Math.sqrt(p * (1 - p) * (1 / n1 + 1 / n2));
  return Math.abs((p1 - p2) / SE);
};

/**
 * Returns p-value based on the t-stat
 * @param t t-stat
 */
export const tStatToPValue = (t: number) => {
  if (t < TPTable[0].t) {
    return 0.6;
  }
  for (let i = 0; i < TPTable.length - 1; i++) {
    if (TPTable[i].t <= t && t < TPTable[i + 1].t) {
      return TPTable[i].p;
    }
  }
  return 0.001;
};

/**
 * Returns p-value based on percentages and numbers of elements
 * See more: https://help.surveymonkey.com/en/surveymonkey/analyze/significant-differences/
 * @param percentage1
 * @param percentage2
 * @param n1
 * @param n2
 */
export const calculatePValue = (percentage1: number, percentage2: number, n1: number, n2: number) => {
  const tStat = calculateTStat(percentage1, percentage2, n1, n2);
  return tStatToPValue(tStat);
};
