import { WIDGET_HEIGHT, WIDGET_TYPE } from '../../../constants';
import { Config } from '../../../types/Config';
import { sortWidgetGroups } from '../../lib';
import { Widget, WidgetGroup } from './types';

export const prepareWidgetGroups = (widgetGroups: WidgetGroup[], widgets: Config[], fixLayout = false) => {
  const overviewWidgetIds = widgets.filter((widget) => widget.base.type === WIDGET_TYPE.OVERVIEW).map((widget) => widget.base.id);

  const widgetById = widgets.reduce((acc, widget) => ({ ...acc, [widget.base.id as string]: widget }), {} as { [key: string]: Config });
  const groups = widgetGroups.map((group) => {
    const widgetGroup = { ...group };
    if (fixLayout) {
      widgetGroup.layout = widgetGroup.layout.map((item) => {
        const fixedItem = { ...item };
        const widget = widgetById[item.widgetID];
        if (widget) {
          fixedItem.width = widget.base.maximized ? 2 : 1;
          if (widget.base.collapsed) {
            fixedItem.height = WIDGET_HEIGHT.COLLAPSED;
          } else {
            let height = WIDGET_HEIGHT.DEFAULT;

            if (widget.base.type === WIDGET_TYPE.OPEN_ENDED) {
              height = WIDGET_HEIGHT.DEFAULT_OPEN_ENDED;
            }
            if (widget.base.type === WIDGET_TYPE.INTERVIEW) {
              height = WIDGET_HEIGHT.DEFAULT_INTERVIEW;
            }

            fixedItem.height = widget.base.expanded ? WIDGET_HEIGHT.EXPANDED : height;
          }
        }
        return fixedItem;
      });
    }

    //
    if (widgetGroup.layout.some((item) => overviewWidgetIds.includes(item.widgetID))) {
      return { ...widgetGroup, isOverview: true };
    }
    if (widgetGroup.title === '') {
      return { ...widgetGroup, isDefault: true };
    }
    return widgetGroup;
  });

  return sortWidgetGroups(groups);
};
