import { Box, TablePagination } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const PaginationComponent: React.FC<{
  className?: string;
  selectedCount: number;
  children: React.ReactElement[];
}> = ({ children, className, selectedCount }) => {
  return (
    <Box display='flex' flexDirection='row' alignItems='center' fontSize='14px'>
      <Box flexGrow={1}>{selectedCount > 0 ? `Rows currently selected: ${selectedCount}` : ''}</Box>
      {React.Children.map(children, (child) => React.cloneElement(child as React.ReactElement<{ className?: string }>, { className }))}
    </Box>
  );
};

const CustomTablePagination = styled(TablePagination)`
  .MuiTablePagination-selectIcon {
    top: calc(50% - 0.5em);
  }
` as typeof TablePagination;

interface ICompareTablePagination {
  count: number;
  selectedCount?: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const CompareTablePagination: React.FC<ICompareTablePagination> = ({
  count,
  selectedCount = 0,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  return (
    <CustomTablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component={(props: any) => <PaginationComponent selectedCount={selectedCount} {...props} />}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onPageChange={onChangePage}
    />
  );
};

export default CompareTablePagination;
