import React from 'react';
import { EVENTS, eventsTracker } from '../../../../../../services/EventTrackerService';
import { Concept } from '../../../../../../store/reducers/aiInsights';
import { ResponsesSorting } from '../../../../../../store/reducers/openEndedWidget';
import { Label } from '../../../../../../types/Label';
import ResponsesListFilter from './ResponsesListFilter';

export const SORTING_ORDER = {
  UP: 'UP',
  DOWN: 'DOWN',
};
export const RESPONSES_SORT_FIELDS = {
  LENGTH: 'length',
  NUMBER_OF_LABELS: 'labelsNum',
  DATE: 'date',
  SENTIMENT: 'sentiment',
};

export const SORT_OPTION_FIELDS = [
  RESPONSES_SORT_FIELDS.NUMBER_OF_LABELS,
  RESPONSES_SORT_FIELDS.LENGTH,
  RESPONSES_SORT_FIELDS.DATE,
  RESPONSES_SORT_FIELDS.SENTIMENT,
];

export const RESPONSES_SORT_FIELD_NAME = {
  [RESPONSES_SORT_FIELDS.LENGTH]: 'Response Length',
  [RESPONSES_SORT_FIELDS.NUMBER_OF_LABELS]: '# of Concepts (default)',
  [RESPONSES_SORT_FIELDS.DATE]: 'Date',
  [RESPONSES_SORT_FIELDS.SENTIMENT]: 'Sentiment Score',
};

interface IResponsesListFilterContainer {
  widgetName: string;
  isBookmarkedOnly: boolean;
  sorting: ResponsesSorting;
  selectedLabelItem: Concept | null;
  labels: Label[];
  searchString: string;
  onSortingChange: (sorting: ResponsesSorting) => void;
  onSearchStringChange: (searchString: string) => void;
  onLabelSelect: (label: string) => void;
  onBookmarkClick: () => void;
}
function ResponsesListFilterContainer({
  widgetName,
  isBookmarkedOnly,
  sorting,
  selectedLabelItem,
  labels,
  searchString,
  onSortingChange,
  onSearchStringChange,
  onLabelSelect,
  onBookmarkClick,
}: IResponsesListFilterContainer) {
  function handleFieldChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const sortBy = event.target.value as string;
    onSortingChange({ ...sorting, sortBy });
    eventsTracker.track(EVENTS.WIDGET_RESPONSES_SORT, {
      'Widget Name': widgetName,
      'Sort Parameter': RESPONSES_SORT_FIELD_NAME[sortBy],
      'Sort Order': sorting.desc ? 'Descending' : 'Ascending',
      'Label Selected': !!selectedLabelItem,
    });
  }

  function handleOrderChange(order: string) {
    onSortingChange({ ...sorting, desc: order === SORTING_ORDER.DOWN });
    eventsTracker.track(EVENTS.WIDGET_RESPONSES_SORT_TOGGLE, {
      'Widget Name': widgetName,
      'Sort Parameter': RESPONSES_SORT_FIELD_NAME[sorting.sortBy],
      'Sort Order': sorting.desc ? 'Descending' : 'Ascending',
      'Label Selected': !!selectedLabelItem,
    });
  }

  function handleBookmarkClick() {
    eventsTracker.track(EVENTS.WIDGET_RESPONSES_FILTER_SAVED, {
      'Widget Name': widgetName,
      'Filter Saved Active': !isBookmarkedOnly,
      'Label Selected': !!selectedLabelItem,
    });
    onBookmarkClick();
  }

  return (
    <ResponsesListFilter
      isBookmarkedOnly={isBookmarkedOnly}
      sorting={sorting}
      selectedLabelItem={selectedLabelItem}
      labels={labels}
      searchString={searchString}
      widgetName={widgetName}
      onOrderChange={handleOrderChange}
      onBookmarkClick={handleBookmarkClick}
      onFieldChange={handleFieldChange}
      onSearchStringChange={onSearchStringChange}
      onLabelSelect={onLabelSelect}
    />
  );
}
export default ResponsesListFilterContainer;
