import * as api from '../../components/tools/api';
import { Query } from '../../types/Query';
import {
  assignTopicToResponseFailure,
  assignTopicToResponseStart,
  assignTopicToResponseSuccess,
  loadAnswersFailure,
  loadAnswersStart,
  loadAnswersSuccess,
  loadParticipantsFailure,
  loadParticipantsStart,
  loadParticipantsSuccess,
  removeTopicFromResponseFailure,
  removeTopicFromResponseStart,
  removeTopicFromResponseSuccess,
} from '../actions/interviewWidget';
import { Action } from '../hooks';
import { CurrentDashboardInfo } from '../reducers/dashboard/types';

import { dashboardSetDataSourcePendingRefresh, dashboardDataSourceReload } from './dashboard';

// TODO: Should be the same models for OE/FG
export type Responses = {
  total: number;
  items: Response[];
};

export type Response = {
  id: string;
  value: string;
  labels: string[];
  date: string;
  originalValue: string;
  sentimentScore: number;
  sentiment: string;
  bookmarked: boolean;
  customerID: string;
  start: number;
};

interface ILoadResponses {
  widgetID: string;
  filterQuery: Query;
  offset: number;
  isBookmarkOnly: boolean;
}
export const DEFAULT_PAGE_SIZE = 50;
export const loadResponses =
  ({ widgetID, filterQuery, offset, isBookmarkOnly }: ILoadResponses): Action =>
  async (dispatch, getState) => {
    dispatch(loadAnswersStart({ widgetID, filterQuery, offset, isBookmarkOnly }));
    const state = getState();
    const { id: dashboardId } = state.dashboard.dashboard as CurrentDashboardInfo;
    const { dataSourceID } = state.interviewWidgets.configsByWidgetID[widgetID];

    try {
      const response = await api.fetchResponses(
        dashboardId,
        dataSourceID,
        offset,
        DEFAULT_PAGE_SIZE,
        filterQuery,
        { sortBy: 'startTime', desc: false },
        isBookmarkOnly,
      );
      dispatch(
        loadAnswersSuccess({
          widgetID,
          responses: response.items,
          totalResponses: response.total,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(loadAnswersFailure({ widgetID }));
    }
  };

interface ILoadParticipants {
  dashboardID: string;
  dataSourceID: string;
}
export const loadParticipants =
  ({ dashboardID, dataSourceID }: ILoadParticipants): Action =>
  async (dispatch, getState) => {
    dispatch(loadParticipantsStart({ dataSourceID }));
    try {
      const response = await api.fetchCustomers(dashboardID, dataSourceID);
      dispatch(
        loadParticipantsSuccess({
          dataSourceID,
          participants: response,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(loadParticipantsFailure({ dataSourceID }));
    }
  };

interface ICreateParticipant {}
export const createParticipant =
  ({}: ICreateParticipant): Action =>
  async (dispatch, getState) => {
    // TODO:
  };

interface IUpdateParticipant {}
export const updateParticipant =
  ({}: IUpdateParticipant): Action =>
  async (dispatch, getState) => {
    // TODO:
  };

interface ICreateTopic {}
export const createTopic =
  ({}: ICreateTopic): Action =>
  async (dispatch, getState) => {
    // TODO:
  };

interface IRenameTopic {}
export const renameTopic =
  ({}: IRenameTopic): Action =>
  async (dispatch, getState) => {
    // TODO:
  };

interface IDeleteTopic {}
export const deleteTopic =
  ({}: IDeleteTopic): Action =>
  async (dispatch, getState) => {
    // TODO:
  };

interface IAssignTopicToResponse {
  widgetID: string;
  responseID: string;
  label: string;
}
export const assignTopicToResponse =
  ({ widgetID, responseID, label }: IAssignTopicToResponse): Action =>
  async (dispatch, getState) => {
    dispatch(assignTopicToResponseStart({ widgetID, responseID }));

    const state = getState();
    const { dashboard } = state.dashboard;
    const { dataSourceID } = state.interviewWidgets.configsByWidgetID[widgetID];

    if (!dashboard) {
      dispatch(assignTopicToResponseFailure({ widgetID, responseID }));
      return;
    }

    try {
      await api.assignTopicToResponse(dashboard.id, dataSourceID, responseID, { title: label });
      dispatch(assignTopicToResponseSuccess({ widgetID, responseID, label }));
      dispatch(dashboardSetDataSourcePendingRefresh(dataSourceID));
      dispatch(dashboardDataSourceReload({ dataSourceID, widgetID, shouldReloadResponses: false }));
    } catch (e) {
      console.error(e);
      dispatch(assignTopicToResponseFailure({ widgetID, responseID }));
    }
  };

interface IRemoveTopicFromResponse {
  widgetID: string;
  responseID: string;
  label: string;
}
export const removeTopicFromResponse =
  ({ widgetID, responseID, label }: IRemoveTopicFromResponse): Action =>
  async (dispatch, getState) => {
    dispatch(removeTopicFromResponseStart({ widgetID, responseID }));
    const state = getState();
    const { id: dashboardId } = state.dashboard.dashboard as CurrentDashboardInfo;
    const { dataSourceID } = state.interviewWidgets.configsByWidgetID[widgetID];

    try {
      await api.removeTopicFromResponse(dashboardId, dataSourceID, responseID, { title: label });
      dispatch(removeTopicFromResponseSuccess({ widgetID, responseID, label }));
      dispatch(dashboardSetDataSourcePendingRefresh(dataSourceID));
      dispatch(dashboardDataSourceReload({ dataSourceID, widgetID, shouldReloadResponses: false }));
    } catch (e) {
      console.error(e);
      dispatch(removeTopicFromResponseFailure({ widgetID, responseID }));
    }
  };

interface IChangeResponseParticipant {}
export const changeResponseParticipant =
  ({}: IChangeResponseParticipant): Action =>
  async (dispatch, getState) => {
    // TODO
  };
