// these are supported authentications for third party integrations
export enum AuthSourceEnum {
  AZURE_SAS = 'Azure',
  QUALTRICS = 'Qualtrics',
}

export enum ConnectionStatusEnum {
  CONNECTED = 'Active',
  PAUSED = 'Pause',
  RUNNING = 'Running',
  ERROR = 'Error',
}

export enum OperationTypeEnum {
  IMPORT = 'Import',
  EXPORT = 'Export',
}

// this should match the supported subset of proto.SurveySource
export enum SurveySourceEnum {
  CSV = 'csv',
  TYPEFORM = 'typeform',
  SURVEYMONKEY = 'surveymonkey',
  AZURE = 'azure',
  QUALTRICS = 'qualtrics',
}

export enum SurveyStatus {
  PROCESSING = 'PROCESSING',
}

export enum CsvUploadErrorEnum {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  DUBLICATE_NAME = 'DUPLICATE_NAME',
}

type EnrichmentSource = {
  id: string;
  name: string;
  updatedAt: number;
  updatedAtString?: string;
};

export type BaseSurvey = {
  id: string;
  title: string;
  source: string;
  responsesTotal: number;
  updatedAt: number;
  dashboards: any[];
  enrichmentSources: EnrichmentSource[];
};

export type Survey = BaseSurvey & {
  source: SurveySourceEnum;
  updatedAtString: string;
  dashboardsConnectedTo: number;
  enrichmentSource: EnrichmentSource;
  status: SurveyStatus;
};

export type SurveyGroup = {
  source: SurveySourceEnum;
  responsesTotal: number;
  lastUpdated: number;
  lastUpdatedString?: string;
  dashboardsConnectedTo: number;
  surveys: Partial<Survey>[];
};

export type BaseConnection = {
  actionType: OperationTypeEnum;
  authID: string;
  id: string;
  name: string;
  nextRun: number;
  lastRun: number;
  interval: number;
  status: ConnectionStatusEnum;
};

export interface Connection extends BaseConnection {
  actionTypeString: string;
  nextRunString: string;
  lastRunString: string;
}

export interface ConnectionUpdate {
  id: string;
  source: SurveySourceEnum;
  interval?: number;
  status?: ConnectionStatusEnum;
}

export type AuthProvider = {
  id: string;
  name: AuthSourceEnum;
  displayName: string;
  logoURL: string;
};

export type BaseProviderAuthentication = {
  id: string;
  systemType: AuthSourceEnum;
  providerID: string;
  expiry: number;
  config: string;
  active: boolean;
  hasAccess: boolean;
  error?: any;
};

export interface ProviderAuthentication extends BaseProviderAuthentication {
  authProvider: AuthProvider;
}

export interface Integration {
  name: string;
  displayName: string;
  source: SurveySourceEnum;
  authentication?: ProviderAuthentication;
  connections: Connection[];
  icon: string;
  logoURL: string;
  authProviderID?: string;
}
