import styled from 'styled-components';

import { FilledButton } from '../../components/UI/Button';

import { BeehiveLogoStyled, PageContainer } from './styles';

import congratulation1x from '../../assets/images/welcome/congratulations@1x.png';
import congratulation2x from '../../assets/images/welcome/congratulations@2x.png';
import congratulation3x from '../../assets/images/welcome/congratulations@3x.png';

const PageContainerStyled = styled(PageContainer)`
  height: 100vh;
`;

const Container = styled.div`
  position: relative;

  display: flex;
  width: 800px;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
  background-color: var(--white);

  gap: 40px;

  border-radius: 8px;
  box-shadow: 0px 2px 6px 2px #0f0c0c26;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 32px 24px;
    width: 440px;
  }
  @media screen and (max-width: 1023px) {
    padding: 32px 24px;
    width: 328px;
  }
`;

const CongratulationImage = styled.img`
  width: 310px;
  height: 162px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Header = styled.div`
  color: var(--black);
  font-size: 32px;
  font-weight: 500;
`;

const SubHeader = styled.div`
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
  text-align: center;
`;

interface ICongratulationsPage {
  onGoToWorkspaceClick: () => void;
}
function CongratulationsPage({ onGoToWorkspaceClick }: ICongratulationsPage) {
  return (
    <PageContainerStyled>
      <Container>
        <BeehiveLogoStyled />
        <CongratulationImage srcSet={`${congratulation1x} 1x, ${congratulation2x} 2x, ${congratulation3x} 3x`} />
        <HeaderContainer>
          <Header>Congratulations!</Header>
          <SubHeader>Welcome! We created your Workplace and it is ready to use.</SubHeader>
        </HeaderContainer>
        <FilledButton onClick={onGoToWorkspaceClick}>Go to my Workspace</FilledButton>
      </Container>
    </PageContainerStyled>
  );
}

export default CongratulationsPage;
