import { useRef } from 'react';
import Box from '@material-ui/core/Box';
import parse from 'date-fns/parse';
import add from 'date-fns/add';
import format from 'date-fns/format';

import useFilters from '../../hooks/useFilters';
import Chart from '../UI/Chart';
import { AVERAGE_POSTFIX, MOVING_AVERAGE_WINDOW_SIZE, PERIOD } from '../../constants';
import { DATE_FORMAT, TOOLTIP_DATE_FORMAT } from '../Widgets/TrendsWidget/lib';
import palette from '../tools/palette';
import { TOOLBOX_MOVING_AVERAGE_BASE } from '../UI/lib';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';

const NO_FILTER_LABEL = 'No filter';

const BaseOption = {
  animation: false,
  legend: {
    show: false,
  },
  tooltip: {
    show: true,
    formatter: (params) => {
      const data = params.dimensionNames.reduce((acc, dimensionName, index) => {
        return { ...acc, [dimensionName]: params.value[index] };
      }, {});
      const isAverage = params.seriesName.includes(AVERAGE_POSTFIX);
      const lines = [];
      lines.push(data.filterName ? data.filterName : '');
      if (isAverage) {
        lines.push(`${MOVING_AVERAGE_WINDOW_SIZE}-${PERIOD.WEEK}s moving average`);
      }
      lines.push(data.date);
      lines.push(data.answer);
      lines.push(`${data.realPercent.toFixed(0)}%${isAverage ? '' : ` / ${data.number}`}`);
      return lines.join('<br/>');
    },
  },
  grid: {
    left: 80,
    right: 80,
    top: 50,
    bottom: 20,
  },
  xAxis: {
    type: 'time',
    axisLabel: {
      hideOverlap: true,
    },
  },
  yAxis: {
    type: 'value',
  },
  title: {
    left: 'center',
    top: 0,
    textAlign: 'center',
    textStyle: {
      color: '#444',
      fontWeight: 'normal',
      fontFamily: 'Rubik',
    },
  },
};

const TimeLineCharts = ({ enabledItems, timelineData, colorByLabel }) => {
  const [filters] = useFilters();
  const showAverageRefs = useRef({});

  const options = [];
  for (let i = 0; i < filters.length; i++) {
    const transformers = [];
    const source = [];
    let index = 0;

    const data = timelineData[i];
    if (!data) {
      continue;
    }
    const series = [];
    const filterName = i === 0 ? NO_FILTER_LABEL : filters[i].name;
    const items = timelineData[i].filter((item) => enabledItems.includes(item.answer));

    // get min max values
    let minDate = '3000/12/31';
    let maxDate = '1000/01/01';
    for (let item of items) {
      for (let bucket of item.buckets) {
        minDate = bucket.__rawJson.date < minDate ? bucket.__rawJson.date : minDate;
        maxDate = bucket.__rawJson.date > maxDate ? bucket.__rawJson.date : maxDate;
      }
    }

    // create dates list
    const dates = [];
    let currentDate = minDate;
    const fixedDate = new Date();
    while (currentDate <= maxDate) {
      dates.push(currentDate);
      const parsed = parse(currentDate, DATE_FORMAT, fixedDate);
      const nextDate = add(parsed, { [`${PERIOD.WEEK}s`]: 1 });
      currentDate = format(nextDate, DATE_FORMAT);
    }

    const averageNames = [];
    for (let item of items) {
      for (let date of dates) {
        const bucket = item.buckets.find((bucket) => bucket.__rawJson.date === date);
        if (bucket) {
          source.push([bucket.__rawJson.date, bucket.realPercent, bucket.date, bucket.percent, bucket.number, filterName, item.answer]);
        } else {
          source.push([date, 0, format(parse(currentDate, DATE_FORMAT, fixedDate), TOOLTIP_DATE_FORMAT), 0, 0, filterName, item.answer]);
        }
      }
      transformers.push({
        transform: [
          {
            type: 'filter',
            config: {
              dimension: 'answer',
              '=': item.answer,
            },
          },
        ],
      });
      series.push({
        datasetIndex: index + 1,
        name: item.answer,
        type: 'bar',
        itemStyle: {
          color: colorByLabel[item.answer],
        },
      });
      transformers.push({
        transform: [
          {
            type: 'filter',
            config: {
              dimension: 'answer',
              '=': item.answer,
            },
          },
          {
            type: 'transformers:movingAverage',
            config: {
              windowSize: MOVING_AVERAGE_WINDOW_SIZE,
              dimension: 'realPercent',
            },
          },
        ],
      });
      const averageName = `${item.answer}${AVERAGE_POSTFIX}`;
      series.push({
        datasetIndex: index + 2,
        type: 'line',
        lineStyle: {
          type: 'dotted',
          color: colorByLabel[item.answer],
        },
        itemStyle: {
          color: colorByLabel[item.answer],
        },
        name: averageName,
      });

      averageNames.push(averageName);

      index += 2;
    }
    options.push({
      ...BaseOption,
      toolbox: {
        top: 7,
        right: 84,
        feature: {
          myMovingAverage: {
            ...TOOLBOX_MOVING_AVERAGE_BASE,
            onclick: function (ecModel, echartInstance) {
              showAverageRefs.current[i] = showAverageRefs.current[i] === undefined ? false : !showAverageRefs.current[i];
              const color = showAverageRefs.current[i] ? palette.primaryColorLightHover : '#ccc';
              ecModel.setOption(
                {
                  toolbox: {
                    feature: {
                      myMovingAverage: {
                        iconStyle: {
                          color: color,
                          borderColor: color,
                        },
                      },
                    },
                  },
                },
                {
                  lazyUpdate: false,
                },
              );
              eventsTracker.track(EVENTS.TRENDS_MOVING_AVERAGE_TOGGLE, {
                'Average Shown': showAverageRefs.current[i],
                Filtered: i > 0,
              });
              const batch = averageNames.map((name) => ({ name }));
              echartInstance.dispatchAction({
                type: showAverageRefs.current[i] ? 'legendSelect' : 'legendUnSelect',
                batch,
              });
            },
          },
        },
      },
      dataset: [
        {
          dimensions: ['date', 'realPercent', 'formattedDate', 'percent', 'number', 'filterName', 'answer'],
          source,
        },
        ...transformers,
      ],
      series,
      id: filters[i].id,
      title: {
        ...BaseOption.title,
        text: filterName,
      },
    });
  }
  return options.map((option) => (
    <Box key={option.id} style={{ height: '45vh', width: '100%' }}>
      <Chart option={option} />
    </Box>
  ));
};

export default TimeLineCharts;
