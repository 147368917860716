import { getTimestampString, prepareBody, saveCsv } from '../../../components/tools/export';
import { ICompareRow } from '../compare';

export const NO_DATA = 'No data';

export const compareTableColumns = [
  'Data Source',
  'Answered',
  'Value',
  'Max Segment Name',
  'Max Segment Total',
  'Max Segment Percentage',
  'Min Segment Name',
  'Min Segment Total',
  'Min Segment Percentage',
  'Difference (absolute)',
  'Difference (percentage)',
  'P-value',
];

export const prepareExportData = (rows: ICompareRow[], filterById: Record<string, any>): any[] => {
  return rows.map((row) => {
    if (row.data.length === 0) {
      console.warn(`Row ${row.dataSourceTitle}/${row.concept} has no data`);
      return [row.dataSourceTitle, row.dataSourceTotal, row.concept, NO_DATA];
    }
    return [
      row.dataSourceTitle,
      row.dataSourceTotal,
      row.concept,
      filterById[row.data[0].filterMaxID].name,
      row.data[0].filterMaxValue,
      row.data[0].filterMaxPercentage,
      filterById[row.data[0].filterMinID].name,
      row.data[0].filterMinValue,
      row.data[0].filterMinPercentage,
      row.data[0].differenceAbsolute,
      row.data[0].differencePercentage,
      row.data[0].pValue,
    ];
  });
};

export const saveToCSV = (rows: ICompareRow[], filterById: Record<string, any>, dashboardTitle: string): void => {
  const columns = compareTableColumns;
  const data = prepareExportData(rows, filterById);
  const body = prepareBody(columns, data);
  const timestampString = getTimestampString();
  saveCsv(body, `${dashboardTitle} - Compare - ${timestampString}`);
};
