import React from 'react';
import styled from 'styled-components';
import { TableBody, TableRow } from '@material-ui/core';

import { Connection, ProviderAuthentication, SurveySourceEnum } from '../../../types/connections';

import { StyledConnectionCell, StyledTable, StyledTableHead, getColumnStyles } from '../style';
import { COLUMNS } from './constants';
import DeleteConnectionModalContainer from './DeleteConnectionModalContainer';
import ConnectionRowContainer from './ConnectionRowContainer';

const TableContainer = styled.div`
  overflow-x: auto;

  th,
  td {
    padding: 0 8px;
  }
`;

const NoData = styled.div`
  text-align: center;
  padding: 16px;
`;

const HeaderCell = styled(StyledConnectionCell)`
  && {
    font-weight: bold;
  }
`;

interface IConnectionsList {
  connections: Connection[];
  source: SurveySourceEnum;
  isEditEnabled: boolean;
  authentication?: ProviderAuthentication;
}

function ConnectionsList({ connections, isEditEnabled, source, authentication }: IConnectionsList) {
  if (!connections.length) {
    return <NoData data-testid='connections-list-nodata'>Click Add Connection to start connecting.</NoData>;
  }

  return (
    <TableContainer>
      <StyledTable data-testid='connections-list-table' style={{ height: '100%' }}>
        <StyledTableHead>
          <TableRow>
            <HeaderCell $colProps={getColumnStyles(COLUMNS.NAME)}>Connection Name</HeaderCell>
            <HeaderCell $colProps={getColumnStyles(COLUMNS.TYPE)}>Type</HeaderCell>
            <HeaderCell $colProps={getColumnStyles(COLUMNS.NEXT_RUN)}>Next run</HeaderCell>
            <HeaderCell $colProps={getColumnStyles(COLUMNS.LAST_RUN)}>Last run</HeaderCell>
            <HeaderCell $colProps={getColumnStyles(COLUMNS.SCHEDULE)}>Run Schedule</HeaderCell>
            <HeaderCell $colProps={getColumnStyles(COLUMNS.STATUS)}>Connection Status</HeaderCell>
            {isEditEnabled && <HeaderCell $colProps={getColumnStyles(COLUMNS.EDIT)}>Edit</HeaderCell>}
            {isEditEnabled && <HeaderCell $colProps={getColumnStyles(COLUMNS.DELETE)}>Delete</HeaderCell>}
          </TableRow>
        </StyledTableHead>
        <TableBody style={{ overflow: 'auto' }}>
          {connections.map((connection) => (
            <ConnectionRowContainer
              source={source}
              connection={connection}
              isEditEnabled={Boolean(isEditEnabled && authentication?.id === connection.authID)}
              isIntegrationEditEnabled={isEditEnabled}
              key={connection.id}
            />
          ))}
        </TableBody>
      </StyledTable>
      <DeleteConnectionModalContainer />
    </TableContainer>
  );
}

export default ConnectionsList;
