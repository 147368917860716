import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, CellMeasurer, InfiniteLoader, List } from 'react-virtualized';
import emptyResult from '../../../../../assets/images/empty-result.png';
import { ResponseModel } from '../../../../../models/Response';
import { ResponsesSorting } from '../../../../../store/reducers/openEndedWidget';
import { DEFAULT_PAGE_SIZE } from '../../../../../store/thunks/openEndedWidget';
import { Config } from '../../../../../types/Config';
import FullRecordDialog from '../../../../FullRecordDialog/FullRecordDialog';
import LoadingLabel from '../../../../LoadingLabel/LoadingLabel';
import { ResponseLoadingOverlay, ScrollToTopIcon } from '../style';
import AnswerMoreMenu from './Components/AnswerMoreMenu';
import ResponseItem from './Components/ResponseItem';
import ResponsesListFilterContainer from './Components/ResponsesListFilterContainer';

interface IResponsesList {
  cache: any;
  config: Config;
  dashboardDataSourcesById: any;
  hasSentiment: any;
  hiddenLabels: string[];
  isBookmarkedOnly: boolean;
  isTranslationUsed: any;
  labels: any;
  labelToParent: any;
  listRef: React.MutableRefObject<List | null>;
  rowCount: number;
  searchString: string;
  selectedItems: any;
  selectedLabelItem: any;
  shouldReset: boolean;
  isLoading: boolean;
  sorting: ResponsesSorting;
  onBookmarkClick: any;
  onLabelClick: any;
  onIsRowLoaded: ({ index }: { index: number }) => boolean;
  onAdditionalLabelsLoad: any;
  onLoadMoreRows: any;
  onResponseBookmarkClick: any;
  onResponseForRowGet: (rowIndex: number) => ResponseModel | null;
  onResetFinish: any;
  onSearchStringChange: any;
  onSortingChange: any;
  onWidthChange: any;
  onFeedbackClick: (responseID: string) => void;
}

function ResponsesList({
  cache,
  config,
  dashboardDataSourcesById,
  hasSentiment,
  hiddenLabels,
  isBookmarkedOnly,
  isTranslationUsed,
  labels,
  labelToParent,
  listRef,
  rowCount,
  searchString,
  selectedItems,
  selectedLabelItem,
  shouldReset,
  isLoading,
  sorting,
  onBookmarkClick,
  onLabelClick,
  onAdditionalLabelsLoad,
  onLoadMoreRows,
  onIsRowLoaded,
  onResponseBookmarkClick,
  onResponseForRowGet,
  onResetFinish,
  onSearchStringChange,
  onSortingChange,
  onWidthChange,
  onFeedbackClick,
}: IResponsesList) {
  const [scrollTop, setScrollTop] = useState(0);
  const [moreMenuParams, setMoreMenuParams] = useState<null | {
    anchorEl: any;
    responseID: string;
  }>(null);
  const [fullRecordResponseID, setFullRecordResponseID] = useState<string | null>(null);

  const infLoaderRef = useRef<InfiniteLoader | null>(null);
  const previousWidthRef = useRef<number>(0);
  useEffect(() => {
    if (shouldReset && infLoaderRef?.current) {
      infLoaderRef.current.resetLoadMoreRowsCache(true);
      onResetFinish();
    }
  }, [shouldReset]);

  function handleScroll(data: any) {
    setScrollTop(data.scrollTop);
  }

  function handleScrollToTopClick() {
    listRef.current?.scrollToPosition(0);
  }

  function handleWidthChange(width: number) {
    onWidthChange();
    previousWidthRef.current = width;
  }

  function handleOpenResponseMenuClick(response: ResponseModel, responseIndex: number, anchorEl: any) {
    setMoreMenuParams({ anchorEl, responseID: response.id });
  }

  function handleResponseMenuClose() {
    setMoreMenuParams(null);
  }

  function handleShowFullRecordOpenClick(responseID: string) {
    setFullRecordResponseID(responseID);
    setMoreMenuParams(null);
  }

  function handleShowFullRecordCloseClick() {
    setFullRecordResponseID(null);
  }

  function handleSendFeedbackClick(responseID: string) {
    onFeedbackClick(responseID);
    setMoreMenuParams(null);
  }

  return (
    <Grid container direction='column' style={{ backgroundColor: 'transparent', height: '100%' }}>
      <Grid item xs='auto' style={{ position: 'relative', width: '100%' }}>
        <ResponsesListFilterContainer
          isBookmarkedOnly={isBookmarkedOnly}
          widgetName={config.base.title}
          labels={labels}
          onBookmarkClick={onBookmarkClick}
          onLabelSelect={(label: string) => onLabelClick(label)}
          onSearchStringChange={onSearchStringChange}
          onSortingChange={onSortingChange}
          searchString={searchString}
          selectedLabelItem={selectedLabelItem}
          sorting={sorting}
        />
      </Grid>
      <Grid
        item
        xs
        style={{
          height: '100%',
          width: '100%',
          padding: '4px',
          position: 'relative',
        }}
      >
        <Grid item style={{ padding: '4px 0', height: '100%' }}>
          <InfiniteLoader
            ref={infLoaderRef}
            minimumBatchSize={DEFAULT_PAGE_SIZE}
            threshold={10}
            isRowLoaded={onIsRowLoaded}
            loadMoreRows={onLoadMoreRows}
            rowCount={rowCount}
          >
            {infLoaderChild}
          </InfiniteLoader>
          {renderScrollToTop()}
          {isLoading && (
            <ResponseLoadingOverlay>
              <LoadingLabel loading={true} />
            </ResponseLoadingOverlay>
          )}
          {rowCount === 0 && (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              height='100%'
              data-cy='no-results-message'
            >
              <Box textAlign='center'>
                There are no responses that match <br /> your filter(s) or search criteria.
              </Box>
              <Box textAlign='center' paddingTop='20px'>
                <img src={emptyResult} alt='No responses' style={{ width: '130px' }} />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <AnswerMoreMenu
        isOpen={moreMenuParams !== null}
        anchorEl={moreMenuParams?.anchorEl}
        responseID={moreMenuParams?.responseID}
        onCloseClick={handleResponseMenuClose}
        onSendFeedbackClick={handleSendFeedbackClick}
        onShowFullRecordClick={handleShowFullRecordOpenClick}
      />
      <FullRecordDialog answerId={fullRecordResponseID} open={fullRecordResponseID !== null} onClose={handleShowFullRecordCloseClick} />
    </Grid>
  );

  function renderScrollToTop() {
    if (scrollTop > 0 && rowCount > 0 && !isLoading) {
      return (
        <ScrollToTopIcon
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Scroll to top'
          onClick={handleScrollToTopClick}
          style={{ position: 'absolute', bottom: '2px', right: '17px' }}
        />
      );
    }
    return null;
  }

  function infLoaderChild({ onRowsRendered, registerChild }: { onRowsRendered: any; registerChild: any }) {
    return <AutoSizer>{autoSizerChild.bind(null, onRowsRendered, registerChild)}</AutoSizer>;
  }

  function autoSizerChild(onRowsRendered: any, registerChild: any, { height, width }: { height: number; width: number }) {
    // clear cache of calculated row sizes on width change
    if (previousWidthRef.current !== width) {
      handleWidthChange(width);
    }

    return (
      <List
        overscanRowCount={3}
        ref={(ref) => {
          listRef.current = ref;
          registerChild(ref);
        }}
        className='responses-list'
        height={height}
        width={width}
        onRowsRendered={onRowsRendered}
        onScroll={handleScroll}
        rowCount={rowCount}
        deferredMeasurementCache={cache}
        rowHeight={cache.rowHeight}
        rowWidth={width}
        rowRenderer={responseRenderer}
      />
    );
  }

  function responseRenderer({
    index,
    key,
    parent,
    style,
    isVisible,
  }: {
    index: any;
    key: any;
    parent: any;
    style: any;
    isVisible: boolean;
  }) {
    let response = onResponseForRowGet(index);

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ measure, registerChild }) => {
          if (!response) {
            response = new ResponseModel(null, '', 'Loading...');
          } else {
            response.setDisplayAnswerText(isTranslationUsed);
          }

          return (
            <ResponseItem
              measure={measure}
              registerChild={registerChild}
              style={style}
              measurerKey={key}
              hasSentiment={hasSentiment}
              hiddenLabels={hiddenLabels}
              isVisible={isVisible}
              loading={false}
              {...response}
              selectedLabelItem={selectedLabelItem}
              widgetConfig={config}
              dashboardDataSourcesById={dashboardDataSourcesById}
              selectedItems={selectedItems}
              labelToParent={labelToParent}
              fetchAdditionalLabels={onAdditionalLabelsLoad.bind(null, response, index)}
              onLabelClick={onLabelClick}
              onBookmarkClick={onResponseBookmarkClick.bind(null, response, index)}
              onOpenResponseMenuClick={handleOpenResponseMenuClick.bind(null, response, index)}
            />
          );
        }}
      </CellMeasurer>
    );
  }
}

export default ResponsesList;
