import React from 'react';

import palette from '../tools/palette';
import { AuthSourceEnum, OperationTypeEnum, SurveySourceEnum, SurveyStatus } from '../../types/connections';

import { TabEnum } from './types';

export const getStatusCellContent = (status?: SurveyStatus) => {
  switch (status) {
    case SurveyStatus.PROCESSING:
      return <div style={{ color: palette.status.configuration }}>Processing...</div>;
    default:
      return '';
  }
};

export const getConnectionTypeString = (type: OperationTypeEnum) => {
  switch (type) {
    case OperationTypeEnum.IMPORT:
      return 'Import';
    case OperationTypeEnum.EXPORT:
      return 'Export';
    default:
      return '';
  }
};

export const authSourceToSurveySource = (source: AuthSourceEnum) => {
  switch (source) {
    case AuthSourceEnum.AZURE_SAS:
      return SurveySourceEnum.AZURE;
    case AuthSourceEnum.QUALTRICS:
      return SurveySourceEnum.QUALTRICS;
    default:
      return '';
  }
};
export const surveySourceToAuthSource = (source: SurveySourceEnum) => {
  switch (source) {
    case SurveySourceEnum.AZURE:
      return AuthSourceEnum.AZURE_SAS;
    case SurveySourceEnum.QUALTRICS:
      return AuthSourceEnum.QUALTRICS;
    default:
      return '';
  }
};

export const TAB_TO_LABEL = {
  [TabEnum.INTEGRATIONS]: 'Apps & Integrations',
  [TabEnum.CSV]: 'Uploads',
};
