import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import LoadingLabel from '../../LoadingLabel/LoadingLabel';
import palette from '../../tools/palette';

export const TotalBadgeContainer = styled(Box)`
  float: left;
  position: relative;
  width: 540px;
  height: 226px;
  padding-top: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
`;

export const TrendsLoadingLabel = styled(LoadingLabel)`
  position: absolute;
  top: 150px;
  color: #bbb;
`;

export const PeriodsToggle = styled(Box)`
  position: absolute;
  top: 2px;
  right: 20px;
`;

export const Period = styled('span')`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => (props.$selected ? palette.primaryColorLightHover : palette.linkColorLight1)};
  margin-left: 5px;
`;
