import styled from 'styled-components';

import { Integration, ProviderAuthentication, SurveySourceEnum } from '../../../types/connections';
import { ReactComponent as AuthIcon } from '../../../assets/icons/auth-icon.svg';
import { ReactComponent as ConnectIcon } from '../../../assets/icons/connect-icon.svg';

import { Header, SourceIcon, Title } from './style';
import ErrorNotification from './ErrorNotification';
import ConnectAzureContainer from './Azure/ConnectAzureContainer';
import QualtricsConnectionContainer from './Qualtrics/QualtricsConnectionContainer';
import AuthSASContainer from './Azure/AuthSASContainer';
import AuthQualtricsContainer from './Qualtrics/AuthQualtricsContainer';

const StyledAuthIcon = styled(AuthIcon)`
  width: 24px;
  height: 24px;
`;

const StyledConnectIcon = styled(ConnectIcon)`
  width: 24px;
  height: 24px;
`;

type IConnectionState = 'connect' | 'auth';

interface IRenderContent extends IAddConnection {
  connectionState: IConnectionState;
}

function RenderContent({ integration, auth, connectionState, isEditingAuth, onUpdateError }: IRenderContent) {
  if (!integration) return null;

  const shouldShowConnect = connectionState === 'connect' && auth;
  const shouldShowAuth = connectionState === 'auth';

  if (shouldShowConnect) {
    switch (integration.source) {
      case SurveySourceEnum.AZURE:
        return <ConnectAzureContainer onUpdateError={onUpdateError} authentication={auth} />;
      case SurveySourceEnum.QUALTRICS:
        return <QualtricsConnectionContainer onUpdateError={onUpdateError} authentication={auth} />;
      default:
        return null;
    }
  }
  if (shouldShowAuth) {
    switch (integration.source) {
      case SurveySourceEnum.AZURE:
        return <AuthSASContainer integration={integration} isEditMode={isEditingAuth} onUpdateError={onUpdateError} />;
      case SurveySourceEnum.QUALTRICS:
        return <AuthQualtricsContainer integration={integration} onUpdateError={onUpdateError} />;
      default:
        return null;
    }
  }

  return null;
}

interface IAddConnection {
  isEditingAuth: boolean;
  error: string;
  integration?: Integration;
  auth?: ProviderAuthentication;
  onUpdateError: (err: string) => void;
}

function AddConnection(props: IAddConnection) {
  const { isEditingAuth, integration, error, auth, onUpdateError } = props;
  const connectionState: IConnectionState = !!auth?.id && auth.active === true && !isEditingAuth ? 'connect' : 'auth';

  if (!integration) return null;

  return (
    <>
      <Header>
        <SourceIcon src={integration.logoURL} alt='icon' />
        {connectionState === 'auth' && <StyledAuthIcon />}
        {connectionState === 'connect' && <StyledConnectIcon />}
        <Title>{connectionState === 'auth' ? 'Authenticate' : 'Connect'}</Title>
        <ErrorNotification error={error} onClose={onUpdateError.bind(null, '')} />
      </Header>
      <RenderContent {...props} connectionState={connectionState} />
    </>
  );
}

export default AddConnection;
