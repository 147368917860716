import { Grid } from '@material-ui/core';
import { LABEL_NOT_APPLICABLE } from '../lib';
import ArrowIconToggle from '../../../../tools/ArrowIconToggle';
import createLabelsListComponent from './createLabesList';
import { AnswerItem } from './AnswerItem';
import { TextOverflowEllipsis } from '../../../../UI';
import FiltersBars from '../../../../UI/FiltersBars/FiltersBars';

const BreakdownLabelRenderer = ({
  label,
  selected,
  opened,
  clickable,
  onLabelClick,
  onToggleClick,
  disabled,
  average,
  filtersById,
  selectedFilterIds,
}) => {
  const labelText = label.title === LABEL_NOT_APPLICABLE ? 'Not Applicable' : label.displayTitle;

  if (label.parentLabel) {
    return (
      <Grid container direction='row'>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <AnswerItem
            hidden={!label.parentLabel}
            text={labelText}
            onClick={onLabelClick}
            clickable={clickable}
            disabled={disabled}
            selected={selected}
          />
        </Grid>
        <Grid item xs={4}>
          <FiltersBars dataByFilterId={label.filters} average={average} filtersById={filtersById} selectedFilterIds={selectedFilterIds} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction='row'>
      <Grid item xs={3}>
        {label.top && <AnswerItem text={labelText} onClick={onLabelClick} clickable={clickable} disabled={disabled} selected={selected} />}
      </Grid>
      <Grid item xs={1}>
        {label.hasChild && <ArrowIconToggle state={opened} onClick={onToggleClick} />}
      </Grid>
      <Grid item xs={3}>
        <TextOverflowEllipsis
          data-tooltip-id='base-tooltip'
          data-tooltip-content={label.breakdownValue}
          style={{ fontSize: '12px', marginTop: '5px' }}
        >
          {label.breakdownValue}
        </TextOverflowEllipsis>
      </Grid>
      <Grid item xs={5}>
        <FiltersBars dataByFilterId={label.filters} average={average} filtersById={filtersById} selectedFilterIds={selectedFilterIds} />
      </Grid>
    </Grid>
  );
};

export default createLabelsListComponent(BreakdownLabelRenderer);
