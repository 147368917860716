import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { v4 } from 'uuid';
import { Backdrop } from '@material-ui/core';
import { useSelector } from 'react-redux';

import './App.css';
import Companies from './pages/Companies/Companies';
import CompanyRouter from './components/Company/CompanyRouter';
import { LOGOUT_URL, SESSION_ID } from './constants';
import AppInitializerWrapper from './components/AppInitializerWrapper/AppInitializerWrapper';
import WelcomePageContainer from './pages/Welcome/WelcomePageContainer';
import CongratulationsPageContainer from './pages/Welcome/CongratulationsPageContainer';
import ProfileContainer from './pages/Profile/ProfileContainer';

const ProtectedAppInitializerWrapper = withAuthenticationRequired(AppInitializerWrapper);

const InnerApp = () => {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const showBlocker = useSelector((state) => state.app.showBlocker || false);

  const session_id = localStorage.getItem(SESSION_ID);
  if (
    (!isAuthenticated && !isLoading) || // no auth
    (isAuthenticated && !isLoading && !session_id) // auth, no session id
  ) {
    localStorage.setItem(SESSION_ID, v4());
  }

  if (showBlocker) {
    return <Backdrop open style={{ zIndex: 10 }} invisible={true} />;
  }

  return (
    <ProtectedAppInitializerWrapper>
      <Router>
        <Switch>
          <Route
            path='/signout'
            component={() => {
              logout({ returnTo: LOGOUT_URL, client_id: null });
              return <></>;
            }}
          />
          <Route path={'/signup/congratulations'} component={CongratulationsPageContainer} />
          <Route path='/signup/welcome' component={WelcomePageContainer} />
          <Route path='/company/:company' component={CompanyRouter} />
          <Route path='/profile' component={ProfileContainer} />
          <Route path='/' component={Companies} />
        </Switch>
      </Router>
    </ProtectedAppInitializerWrapper>
  );
};

export default InnerApp;
