import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';

import StyledDivider from '../../../Divider/Divider';
import SettingsSwitchFormControl from '../../../UI/SettingsSwitchFormControl';
import { dashboardUpdateWidget } from '../../../../store/actions/dashboard';
import { CancelButton, DataViewButton, SaveButton } from '../../../UI';
import * as api from '../../../tools/api';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import WidgetLabelsAutocompleteControl from '../../../UI/LabelsAutocompleteControl/WidgetLabelsAutocompleteControl';
import { WIDGET_TYPE } from '../../../../constants';
import { DATA_VIEW } from '../../utils/lib';

// TODO: do not use Grid
const WidgetButtonsGrid = styled(Grid)`
  padding: 16px;
  text-align: right;
  flex-grow: 1;
  display: flex;
  align-items: end;
  justify-content: end;
`;

const WidgetSettings = ({ config, onClose, hideConfig, componentRef, groupTitle }) => {
  const { translationUsed } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [settings, setSettings] = useState(config.settings);
  const initialTitle = translationUsed ? config.base.title : config.base.originalTitle;
  const [title, setTitle] = useState(initialTitle);
  const [titleError, setTitleError] = useState(false);
  const [selectedItems, setSelectedItems] = useState(config.settings.dataSources[0].selectedItems);
  const dataSourceId = config.settings.dataSources[0].id;

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
    setTitleError(value.length === 0);
  };

  const updateSettings = (param, value) => {
    setSettings({
      ...settings,
      [param]: value,
    });
  };

  const handleSave = () => {
    const resetFiltering = config.settings.showFilters !== settings.showFilters || config.settings.detailed !== settings.detailed;
    let updatedConfig = {
      ...config,
      settings: {
        ...settings,
        dataSources: [
          {
            selectedItems,
            id: dataSourceId,
          },
        ],
      },
    };
    const trimmedTitle = title.trim();
    if (trimmedTitle !== initialTitle) {
      updatedConfig = {
        ...updatedConfig,
        base: {
          ...updatedConfig.base,
          [translationUsed ? 'title' : 'originalTitle']: trimmedTitle,
        },
      };
    }
    const trackProperties =
      config.base.type === WIDGET_TYPE.INTERVIEW
        ? {
            'Show responses': settings.showResponses,
            'Show status': settings.showStatus,
            'Show filters': settings.showFilters,
            Data: settings.detailed === DATA_VIEW.HIGH_LEVEL ? 'High-level' : 'Detailed',
          }
        : {};
    eventsTracker.track(EVENTS.WIDGET_EDIT_SAVE, {
      ...trackProperties,
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.INTERVIEW ? capitalize(config.base.type) : 'Interview',
    });
    dispatch(dashboardUpdateWidget(updatedConfig));
    api.updateWidget(updatedConfig);
    onClose(resetFiltering);
  };

  const handleCancelClick = () => {
    eventsTracker.track(EVENTS.WIDGET_EDIT_CANCEL, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.INTERVIEW ? capitalize(config.base.type) : 'Interview',
    });
    onClose();
  };

  return (
    <Grid container direction='column' ref={componentRef} style={{ minHeight: '100%' }}>
      <Grid item style={{ padding: '16px' }}>
        <TextField
          error={titleError}
          size='small'
          variant='outlined'
          value={title}
          style={{ width: '100%' }}
          onChange={handleTitleChange}
        />
      </Grid>
      <Grid item>
        <StyledDivider />
      </Grid>
      <Grid item style={{ padding: '16px 16px 0 16px' }}>
        <strong style={{ color: '#2F3151' }}>Selected Items</strong>
        <div style={{ marginTop: '16px' }}>
          <WidgetLabelsAutocompleteControl
            dataSourceID={dataSourceId}
            value={selectedItems}
            onChange={(items) => setSelectedItems(items)}
          />
        </div>
      </Grid>
      {!hideConfig && (
        <Grid item style={{ padding: '16px 16px 0 16px' }}>
          <strong style={{ color: '#2F3151' }}>Display</strong>
          <Grid container direction='column' style={{ margin: '8px 16px' }}>
            <Grid item>
              <SettingsSwitchFormControl
                title='Responses'
                checked={settings.showResponses}
                onChange={() => updateSettings('showResponses', !settings.showResponses)}
              />
            </Grid>
            <Grid item>
              <SettingsSwitchFormControl
                title='Status'
                checked={settings.showStatus}
                onChange={() => updateSettings('showStatus', !settings.showStatus)}
              />
            </Grid>
            <Grid item>
              <SettingsSwitchFormControl
                title='Filters'
                checked={settings.showFilters}
                onChange={() => updateSettings('showFilters', !settings.showFilters)}
              />
            </Grid>
          </Grid>
          <strong style={{ color: '#2F3151' }}>Data</strong>
          <Grid container direction='row' style={{ margin: '16px 16px 0 16px' }}>
            <Grid item style={{ marginRight: '16px' }}>
              <DataViewButton
                size='small'
                className={settings.detailed === DATA_VIEW.HIGH_LEVEL ? 'active' : ''}
                disableElevation
                variant='contained'
                onClick={() => updateSettings('detailed', DATA_VIEW.HIGH_LEVEL)}
              >
                High-Level
              </DataViewButton>
            </Grid>
            <Grid item>
              <DataViewButton
                size='small'
                className={settings.detailed === DATA_VIEW.DETAILED ? 'active' : ''}
                disableElevation
                variant='contained'
                onClick={() => updateSettings('detailed', DATA_VIEW.DETAILED)}
              >
                Detailed
              </DataViewButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <WidgetButtonsGrid item>
        <CancelButton onClick={handleCancelClick} style={{ marginRight: '16px' }}>
          Cancel
        </CancelButton>
        <SaveButton variant='outlined' onClick={handleSave} disabled={titleError}>
          Save
        </SaveButton>
      </WidgetButtonsGrid>
    </Grid>
  );
};

export default WidgetSettings;
