import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FETCH_STATUS_STATE } from '../../constants';
import { profileSetUserProfleFetchState, profileSetUserProfleUpdateState } from '../../store/actions/profile';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { UserProfile } from '../../store/reducers/profile';
import { fetchUserProfile, updateUserProfile } from '../../store/thunks/profile';
import { NotificationsContext } from '../../components/Notification/NotificationsContext';
import { SEVERITY } from '../../components/Notification/types';

import Profile from './Profile';

function ProfileContainer() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { addNotification } = useContext(NotificationsContext);

  const { userProfile, userProfileFetchState, userProfileUpdateState } = useAppSelector((state) => state.profile);

  const [profile, setProfile] = useState<UserProfile | null>(null);

  const isLoading = userProfileFetchState === FETCH_STATUS_STATE.LOADING || userProfileUpdateState === FETCH_STATUS_STATE.LOADING;

  useEffect(() => {
    return () => {
      dispatch(profileSetUserProfleFetchState(FETCH_STATUS_STATE.IDLE));
      dispatch(profileSetUserProfleUpdateState(FETCH_STATUS_STATE.IDLE));
    };
  }, []);

  useEffect(() => {
    switch (userProfileFetchState) {
      case FETCH_STATUS_STATE.ERROR:
        addNotification({
          title: 'Something went wrong. Please try again later.',
          severity: SEVERITY.ERROR,
          id: 'PROFILE_PAGE',
        });
        break;
      case FETCH_STATUS_STATE.IDLE:
        dispatch(fetchUserProfile());
        break;
      case FETCH_STATUS_STATE.LOADED:
        setProfile(userProfile);
        break;
      default:
        dispatch(fetchUserProfile());
        break;
    }
  }, [userProfileFetchState]);

  useEffect(() => {
    switch (userProfileFetchState) {
      case FETCH_STATUS_STATE.LOADED:
        redirect();
        break;
      case FETCH_STATUS_STATE.ERROR:
        addNotification({
          title: 'Something went wrong. Please try again later.',
          severity: SEVERITY.ERROR,
          id: 'PROFILE_PAGE',
        });
        break;
      default:
        break;
    }
  }, [userProfileUpdateState]);

  function redirect() {
    const hasBackLink = location.state && (location.state as { internal?: boolean })?.internal;

    if (hasBackLink) {
      history.goBack();
    } else {
      history.push('/');
    }
  }

  function handleSubmit() {
    if (profile) {
      dispatch(updateUserProfile(profile));
    }
  }

  function handleBack() {
    redirect();
  }

  return <Profile profile={profile} isLoading={isLoading} onChangeProfile={setProfile} onBackClick={handleBack} onSubmit={handleSubmit} />;
}

export default ProfileContainer;
