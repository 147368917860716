import { Query } from '../../types/Query';

import {
  DASHBOARD_CREATE_SAVED_FILTER_ERROR,
  DASHBOARD_CREATE_SAVED_FILTER_START,
  DASHBOARD_CREATE_SAVED_FILTER_SUCCESS,
  DASHBOARD_FETCH_TOTALS_ERROR,
  DASHBOARD_FETCH_TOTALS_START,
  DASHBOARD_FETCH_TOTALS_SUCCESS,
  DASHBOARD_UPDATE_SAVED_FILTER_ERROR,
  DASHBOARD_UPDATE_SAVED_FILTER_START,
  DASHBOARD_UPDATE_SAVED_FILTER_SUCCESS,
  FILTER_RESET,
  FILTER_SET_QUERY,
  dashboardCreateSavedFilterError,
  dashboardCreateSavedFilterStart,
  dashboardCreateSavedFilterSuccess,
  dashboardFetchTotalsError,
  dashboardFetchTotalsStart,
  dashboardFetchTotalsSuccess,
  dashboardUpdateSavedFilterError,
  dashboardUpdateSavedFilterStart,
  dashboardUpdateSavedFilterSuccess,
  filterReset,
  filterSetQuery,
} from '../actions/dashboardFilter';

export type TotalCustomersForQueryResult = {
  weighted: number;
  total: number;
};

export type TotalCustomersForQuery = {
  isLoading: boolean;
  isError: boolean;
  result: null | TotalCustomersForQueryResult;
};

export interface IDashboardFilterReducer {
  query: null | Query;
  isLoading: boolean;
  isError: boolean;
  totalCustomers: TotalCustomersForQuery;
}
export const defaultFilterState: IDashboardFilterReducer = {
  query: null,
  isLoading: false,
  isError: false,
  totalCustomers: {
    isLoading: false,
    isError: false,
    result: null,
  },
};
type dashboardFilterActionTypes = ReturnType<typeof dashboardUpdateSavedFilterStart> &
  ReturnType<typeof dashboardUpdateSavedFilterSuccess> &
  ReturnType<typeof dashboardUpdateSavedFilterError> &
  ReturnType<typeof dashboardCreateSavedFilterStart> &
  ReturnType<typeof dashboardCreateSavedFilterSuccess> &
  ReturnType<typeof dashboardCreateSavedFilterError> &
  ReturnType<typeof dashboardFetchTotalsStart> &
  ReturnType<typeof dashboardFetchTotalsSuccess> &
  ReturnType<typeof dashboardFetchTotalsError> &
  ReturnType<typeof filterReset> &
  ReturnType<typeof filterSetQuery>;
const dashboardFilter = (state = defaultFilterState, action: dashboardFilterActionTypes) => {
  switch (action.type) {
    case FILTER_RESET:
      return handleDashboardFilterReset();
    case FILTER_SET_QUERY:
      return handleDashboardSetFilterQuery(state, action);
    case DASHBOARD_UPDATE_SAVED_FILTER_START:
    case DASHBOARD_CREATE_SAVED_FILTER_START:
      return handleDashboardFilterLoadStart(state);
    case DASHBOARD_UPDATE_SAVED_FILTER_SUCCESS:
    case DASHBOARD_CREATE_SAVED_FILTER_SUCCESS:
      return handleDashboardFilterLoadSuccess(state);
    case DASHBOARD_UPDATE_SAVED_FILTER_ERROR:
    case DASHBOARD_CREATE_SAVED_FILTER_ERROR:
      return handleDashboardFilterLoadError(state);
    case DASHBOARD_FETCH_TOTALS_START:
      return handleDashboardFetchTotalsStart(state);
    case DASHBOARD_FETCH_TOTALS_SUCCESS:
      return handleDashboardFetchTotalsSuccess(state, action);
    case DASHBOARD_FETCH_TOTALS_ERROR:
      return handleDashboardFetchTotalsError(state);
    default:
      return state;
  }
};

function handleDashboardSetFilterQuery(state: IDashboardFilterReducer, action: ReturnType<typeof filterSetQuery>) {
  return {
    ...state,
    query: action.payload.query,
  };
}

function handleDashboardFilterReset() {
  return {
    ...defaultFilterState,
  };
}

function handleDashboardFilterLoadStart(state: IDashboardFilterReducer) {
  return {
    ...state,
    isLoading: true,
    isError: false,
  };
}

function handleDashboardFilterLoadSuccess(state: IDashboardFilterReducer) {
  return {
    ...state,
    isLoading: false,
    isError: false,
  };
}

function handleDashboardFilterLoadError(state: IDashboardFilterReducer) {
  return {
    ...state,
    isLoading: false,
    isError: true,
  };
}

function handleDashboardFetchTotalsStart(state: IDashboardFilterReducer) {
  return {
    ...state,
    totalCustomers: {
      isLoading: true,
      isError: false,
      result: null,
    },
  };
}
function handleDashboardFetchTotalsSuccess(state: IDashboardFilterReducer, action: ReturnType<typeof dashboardFetchTotalsSuccess>) {
  return {
    ...state,
    totalCustomers: {
      isLoading: false,
      isError: false,
      result: action.payload.result,
    },
  };
}
function handleDashboardFetchTotalsError(state: IDashboardFilterReducer) {
  return {
    ...state,
    totalCustomers: {
      isLoading: false,
      isError: true,
      result: null,
    },
  };
}

export default dashboardFilter;
