const LogoSvg = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M2.0013 2.7578C2.0013 1.23471 3.23601 0 4.7591 0H26.8297C28.3528 0 29.5875 1.23471 29.5875 2.7578V3.8412C29.5875 5.36429 28.3528 6.59899 26.8297 6.59899H4.7591C3.23601 6.59899 2.0013 5.36428 2.0013 3.84119V2.7578Z'
        fill='#FBED1F'
      />
      <path
        d='M2.0013 20.4284C2.0013 18.9053 3.23601 17.6706 4.7591 17.6706H26.8297C28.3528 17.6706 29.5875 18.9053 29.5875 20.4284V21.5118C29.5875 23.0349 28.3528 24.2696 26.8297 24.2696H4.7591C3.23601 24.2696 2.0013 23.0349 2.0013 21.5118V20.4284Z'
        fill='#FF37CD'
      />
      <path
        d='M0 11.5924C0 10.0693 1.23471 8.83464 2.7578 8.83464H29.2422C30.7653 8.83464 32 10.0693 32 11.5924V12.6758C32 14.1989 30.7653 15.4336 29.2422 15.4336H2.7578C1.23471 15.4336 0 14.1989 0 12.6758V11.5924Z'
        fill='#FA8329'
      />
      <path
        d='M8.00195 28.1588C8.00195 26.6357 9.23666 25.401 10.7598 25.401H21.7959C23.319 25.401 24.5537 26.6357 24.5537 28.1588V29.2422C24.5537 30.7653 23.319 32 21.7959 32H10.7598C9.23666 32 8.00195 30.7653 8.00195 29.2422V28.1588Z'
        fill='#8268FF'
      />
    </svg>
  );
};

export default LogoSvg;
