import { useDispatch, useSelector } from 'react-redux';
import TranslateIcon from '@material-ui/icons/Translate';

import SidebarItem from './SidebarItem';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { toggleTranslationUsage } from '../../../store/actions/actionsCreators';

const SidebarItemTranslation = () => {
  const translationUsed = useSelector((state) => state.app.translationUsed);
  const dispatch = useDispatch();

  const handleClick = () => {
    eventsTracker.track(EVENTS.TOGGLE_TRANSLATION, {
      Translate: !translationUsed,
    });
    dispatch(toggleTranslationUsage(!translationUsed));
  };

  return <SidebarItem icon={<TranslateIcon />} onClick={handleClick} selected={translationUsed} tooltip='Translate Responses' />;
};

export default SidebarItemTranslation;
