import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const WidgetGroupRenameDialog = ({ title, originalTitle, onCancel, onSave, open }) => {
  const [useTheSameName, setUseTheSameName] = useState(title === originalTitle);
  const [newTitle, setNewTitle] = useState(title);
  const [newOriginalTitle, setNewOriginalTitle] = useState(originalTitle);

  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  useEffect(() => {
    setNewOriginalTitle(originalTitle);
  }, [originalTitle]);

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setNewTitle(value);
    if (useTheSameName) {
      setNewOriginalTitle(value);
    }
  };

  const handleOriginalTitleChange = (event) => {
    const value = event.target.value;
    setNewOriginalTitle(value);
  };

  const disabled = newTitle.trim().length === 0 || (originalTitle !== newOriginalTitle && newOriginalTitle.trim().length === 0);

  return (
    <Dialog maxWidth='sm' fullWidth={true} open={open}>
      <DialogTitle>Rename Group</DialogTitle>
      <DialogContent dividers>
        <TextField fullWidth size='small' variant='outlined' value={newTitle} onChange={handleTitleChange} />
        <FormControlLabel
          control={<Checkbox checked={useTheSameName} onChange={() => setUseTheSameName((value) => !value)} />}
          label='Use the same name in the original language'
        />
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          disabled={useTheSameName}
          value={newOriginalTitle}
          onChange={handleOriginalTitleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={() => onSave(newTitle, newOriginalTitle.trim())} disabled={disabled} color='primary'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WidgetGroupRenameDialog;
