import React from 'react';
import { useSelector } from 'react-redux';

import { ICompareRow } from '../compare';
import { saveToCSV } from '../lib/export';
import useFilters from '../../../hooks/useFilters';
import { FileDownloadOutlined, SelectorButton } from './UI';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';

interface ITableExport {
  rows: ICompareRow[];
}

const TableExportContainer: React.FC<ITableExport> = ({ rows }) => {
  const dashboardTitle = useSelector((state: any) => state.dashboard.dashboard.title);
  const [, filtersById] = useFilters() as [any, any];

  function handleClick() {
    eventsTracker.track(EVENTS.EXPORT_TABLE);
    saveToCSV(rows, filtersById, dashboardTitle);
  }

  return (
    <SelectorButton onClick={handleClick} size='small' startIcon={<FileDownloadOutlined />}>
      Export
    </SelectorButton>
  );
};

export default TableExportContainer;
