import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import html2canvas from 'html2canvas';

import Chart from '../../../../components/UI/Chart';
import { ICompareRow } from '../../compare';
import { DashboardDataSource } from '../../../../types';
import palette from '../../../../components/tools/palette';
import { prepareChartData, prepareChartSeries } from '../../lib/chart';
import { savePng } from '../../../../components/tools/export';
import { FileDownloadOutlined, SelectorButton } from '../UI';
import CompareChartLabels from './CompareChartLabels';
import CompareFilterButtons from '../CompareFilterButtons';
import useFilters from '../../../../hooks/useFilters';

const HIDDEN_FILTERS_ID = '__hidden_filters_id__';

const ChartWrapper = styled.div<{ $expanded: boolean }>`
  position: relative;
  height: ${({ $expanded }) => ($expanded ? 'calc(100vh - 300px)' : '300px')};
`;

const ChartHolder = styled.div`
  margin: 0 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: calc(50% - 46px);
  }
`;

const BackButton = styled(CustomIconButton)`
  left: 40px;
`;

const ForwardButton = styled(CustomIconButton)`
  right: 30px;
`;

const EmptyChartMessage = styled.div`
  position: absolute;
  bottom: 25px;
  left: 185px;
  color: ${palette.textGray};
  font-weight: bold;
`;

const StyledSelectorButton = styled(SelectorButton)`
  &.MuiButton-root {
    position: absolute;
    top: 10px;
    right: 0;
  }
`;

const HiddenFiltersBlock = styled.div`
  display: none;
`;

function removeUnselectedFiltersFromElement(element: Element) {
  if (!element.firstElementChild) {
    return;
  }
  const unselectedButtons = element.firstElementChild.querySelectorAll('.MuiGrid-item');
  for (const btn of unselectedButtons) {
    if (!btn.firstElementChild) {
      continue;
    }
    if (btn.firstElementChild.getAttribute('data-selected') === 'false') {
      (btn as HTMLElement).style.display = 'none';
    }
  }
}

interface ICompareChartProps {
  pageRows: ICompareRow[];
  page: number;
  pagesCount: number;
  orderedFilterIds: string[];
  dashboardDataSources: DashboardDataSource[];
  filtersById: Record<string, any>;
  expanded: boolean;
  onExportClick: () => void;
  onPrevPageClick: () => void;
  onNextPageClick: () => void;
}

function CompareChart({
  pageRows,
  page,
  pagesCount,
  orderedFilterIds,
  dashboardDataSources,
  filtersById,
  expanded,
  onExportClick,
  onPrevPageClick,
  onNextPageClick,
}: ICompareChartProps) {
  const dashboardTitle = useSelector((state: any) => state.dashboard.dashboard.title);
  const chartRef = React.useRef<any>(null);
  const categories = ['id', ...orderedFilterIds];
  const { data, markPointDataByFilterId } = prepareChartData(pageRows, orderedFilterIds);
  const source = [categories, ...data];
  const series = prepareChartSeries(orderedFilterIds, filtersById, markPointDataByFilterId);
  const [filters] = useFilters();

  const chartLabels = pageRows.map((row) => {
    const dashboardDataSource = dashboardDataSources.find((ds) => ds.dataSourceID === row.dataSourceID);
    return {
      dataSource: dashboardDataSource ? dashboardDataSource.title : '',
      concept: row.concept,
    };
  });

  function tooltipFormatter(params: { name: string; seriesName: string }) {
    const row = pageRows.find((r) => r.id === params.name);
    const lines = [];
    lines.push(`<b>${filtersById[params.seriesName].name}</b>`);
    if (row) {
      lines.push(row.concept);
      const dashboardDataSource = dashboardDataSources.find((ds) => ds.dataSourceID === row.dataSourceID);
      if (dashboardDataSource) {
        const title = dashboardDataSource.title;
        lines.push(title);
      }
    }

    return '<div style="white-space: break-spaces">' + lines.join('<br/>') + '</div>';
  }

  const option = {
    legend: {
      show: false,
    },
    tooltip: {
      formatter: tooltipFormatter,
      confine: true,
    },
    dataset: {
      source,
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      max: 100,
      interval: 10,
      axisLine: {
        show: true,
      },
      axisLabel: {
        formatter: function (value: number) {
          return value === 100 ? '100%' : '';
        },
      },
      axisTick: {
        show: true,
      },
    },
    series: series,
    grid: {
      top: 10,
      left: 40,
      right: 40,
      bottom: 5,
    },
  };

  async function handleExportClick() {
    const filtersBlock = document.getElementById(HIDDEN_FILTERS_ID);
    if (filtersBlock) {
      onExportClick();
      // get height of filters block without unselected filters
      const clonedFiltersBlock = filtersBlock.cloneNode(true) as HTMLElement;
      removeUnselectedFiltersFromElement(clonedFiltersBlock);
      clonedFiltersBlock.style.position = 'absolute';
      clonedFiltersBlock.style.top = '-5000px';
      clonedFiltersBlock.style.left = '-5000px';
      clonedFiltersBlock.style.display = 'block';
      document.body.append(clonedFiltersBlock);
      const offset = clonedFiltersBlock.offsetHeight;
      clonedFiltersBlock.remove();

      const canvas = await html2canvas(chartRef.current, {
        height: offset + chartRef.current.offsetHeight,
        onclone: (document: Document, element: HTMLElement) => {
          if (element.firstElementChild) {
            removeUnselectedFiltersFromElement(element.firstElementChild);
            (element.firstElementChild as HTMLElement).style.display = 'block';
          }
        },
      });
      const image = canvas.toDataURL('image/png', 1.0);
      savePng(image, `${dashboardTitle} - Compare`);
    }
  }

  return (
    <ChartWrapper $expanded={expanded}>
      <ChartHolder ref={chartRef}>
        <HiddenFiltersBlock id={HIDDEN_FILTERS_ID}>
          <CompareFilterButtons onFilterClick={() => {}} selectedFilterIds={orderedFilterIds} filters={filters} />
        </HiddenFiltersBlock>
        <Chart option={option} style={{ height: '100%', maxHeight: '100%' }} />
        <CompareChartLabels chartLabels={chartLabels} />
      </ChartHolder>

      {pageRows.length === 0 && (
        <EmptyChartMessage>
          {orderedFilterIds.length < 2 ? (
            <>To view comparisons here, re-select one or more of the applied segments listed above</>
          ) : (
            <>Select a row in the table below to see a comparison</>
          )}
        </EmptyChartMessage>
      )}

      {pageRows.length > 0 && (
        <StyledSelectorButton size='small' startIcon={<FileDownloadOutlined />} onClick={handleExportClick}>
          Save image
        </StyledSelectorButton>
      )}

      <BackButton disabled={page === 0} onClick={onPrevPageClick}>
        <ArrowBackIosOutlinedIcon fontSize='large' />
      </BackButton>

      <ForwardButton disabled={page >= pagesCount} onClick={onNextPageClick}>
        <ArrowForwardIosOutlinedIcon fontSize='large' />
      </ForwardButton>
    </ChartWrapper>
  );
}

export default CompareChart;
