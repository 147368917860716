import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import palette from '../tools/palette';
import { withStyles } from '@material-ui/styles';
import { sharedStyles } from './styles';
import styled from 'styled-components';

const ToolbarLink = styled.a`
  ${sharedStyles};
  font-size: 16px;
  text-decoration: none;
`;
const ToolbarTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

export const PseudoLink = styled.span`
  ${sharedStyles};
  font-size: 16px;
  cursor: pointer;
`;

export const ExitLink = () => (
  <Box>
    <Link to='/signout' onClick={() => eventsTracker.trackImmediately(EVENTS.LOGOUT)} component={ToolbarLink}>
      Logout
    </Link>
  </Box>
);

export const AccountLink = () => (
  <LinkContainer>
    <PersonOutlineOutlinedIcon style={{ color: palette.textPrimary }} />
    <Link
      to={{ pathname: '/profile', state: { internal: true } }}
      onClick={() => eventsTracker.track(EVENTS.MY_ACCOUNT)}
      component={ToolbarLink}
      style={{ marginLeft: '4px' }}
    >
      My account
    </Link>
  </LinkContainer>
);

interface IInviteLink {
  disabled: boolean;
  company?: string;
}
export const InviteLink = ({ company, disabled }: IInviteLink) => {
  const color = disabled ? palette.linkColorLight2 : palette.textPrimary;
  const link = disabled ? (
    <ToolbarTooltip
      arrow
      title='You must be an administrator to manage users in this workspace. Please get in touch with an admin to invite more users.'
    >
      <PseudoLink style={{ color }}>+ Invite Users</PseudoLink>
    </ToolbarTooltip>
  ) : (
    <ToolbarLink href={`/auth/invites/new?company=${company}`} onClick={() => eventsTracker.trackImmediately(EVENTS.INVITE_USER)}>
      + Invite Users
    </ToolbarLink>
  );

  return <LinkContainer>{link}</LinkContainer>;
};
