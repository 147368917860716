import { useLocation } from 'react-router-dom';
import { SAVED_FILTERS_URL_ARG_NAME } from '../SavedFilters/SavedFilters';
import React, { useEffect, useMemo, useState } from 'react';
import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import LoadingLabel from '../LoadingLabel/LoadingLabel';
import { CustomSnackbar } from '../UI';

const containsAll = (arr1, arr2) => arr2.every((arr2Item) => arr1.includes(arr2Item));

const SavedFiltersURLController = ({ dashboardId, skipLoading, children }) => {
  const [isReady, setIsReady] = useState(false);
  const [showError, setShowError] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const savedFiltersIdsFromUrl = query.get(SAVED_FILTERS_URL_ARG_NAME);
  const { savedFilters, savedFiltersById, fetchSavedFilters, selectedSavedFiltersArray, toggleSelectedSavedFilter, isSavedFiltersLoaded } =
    useSavedFilters(skipLoading);

  useEffect(() => {
    if (!isSavedFiltersLoaded) {
      fetchSavedFilters(dashboardId);
    }
  }, [dashboardId, isSavedFiltersLoaded, fetchSavedFilters]);

  // TODO: create state value not empty but populated with URL args.

  useEffect(() => {
    if (savedFiltersIdsFromUrl && savedFiltersIdsFromUrl.length > 0 && savedFilters.length > 0 && selectedSavedFiltersArray.length === 0) {
      const filterIdsFromUrl = savedFiltersIdsFromUrl.split(',');
      const savedFiltersIdsFromUrlArray = filterIdsFromUrl.filter((id) => !!savedFiltersById[id] && savedFiltersById[id].valid);
      if (!containsAll(selectedSavedFiltersArray, savedFiltersIdsFromUrlArray)) {
        savedFiltersIdsFromUrlArray.forEach((id) => id.length > 0 && toggleSelectedSavedFilter(id));
      }
      if (filterIdsFromUrl.length !== savedFiltersIdsFromUrlArray.length && showError === null) {
        setShowError(true);
      }
    }
    setIsReady(true);
  }, [savedFilters, savedFiltersById, savedFiltersIdsFromUrl, selectedSavedFiltersArray, showError, toggleSelectedSavedFilter]);

  return useMemo(() => {
    if (!isSavedFiltersLoaded || !isReady) {
      return <LoadingLabel loading={true} style={{ marginTop: '30vh' }} />;
    }
    return (
      <>
        <CustomSnackbar
          data-cy='invalid-filter-warning'
          style={{ top: 24 }}
          className='warning'
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showError}
          autoHideDuration={5000}
          message='Some filters are not applied because of data change. Please review marked saved segments.'
          onClose={() => setShowError(false)}
        />
        {children}
      </>
    );
  }, [isSavedFiltersLoaded, isReady, children, showError]);
};

export default SavedFiltersURLController;
