import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Middleware } from 'redux';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { v4 } from 'uuid';

import {
  AI_INSIGHTS_GENERATE,
  AI_INSIGHTS_RESET,
  generateInsightsRequest,
  generateInsightsRequestFailure,
  generateInsightsRequestStart,
} from '../actions/aiInsights';
import {
  loadAnswersFailure,
  loadAnswersSuccess,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS,
} from '../actions/openEndedWidget';
import { GenerationStatus } from '../reducers/aiInsights';
import { AppDispatch, RootState } from '../hooks';
import { Query } from '../../types/Query';
import { generateInsights, InsightsFilter } from '../thunks/aiInsights';

type InsightInfo = {
  responsesQuery: Query;
  insightsQuery: InsightsFilter[];
  insightID: string;
  dashboardID: string;
  dataSourceID: string;
  isTryAgain: boolean;
};

const insightsInProgress = {} as { [key: string]: InsightInfo[] };

const insightsLoader: Middleware<object, unknown, ThunkDispatch<unknown, unknown, AnyAction>> =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (!action) {
      return;
    }

    if (action?.type === AI_INSIGHTS_GENERATE) {
      const typedAction = action as ReturnType<typeof generateInsightsRequest>;
      const {
        availableFilters,
        customPrompt,
        segmentsNames,
        selectedConcept,
        widgetID,
        insightID,
        isTryAgain = false,
        insightsQuery,
        responsesQuery,
        dashboardID,
        dataSourceID,
      } = typedAction.payload;
      const state = getState() as RootState;
      const insightsForWidget = state.aiInsights.insightsByWidgetID.find((insights) => insights.widgetID === widgetID);
      const insight = insightsForWidget?.insights?.find((insight) => insight.id === insightID);
      let id = insightID ?? v4();

      if (isTryAgain && !insight?.generationStatuses?.includes(GenerationStatus.FAILURE)) {
        id = v4();
      }

      if (!isTryAgain) {
        if (!insightsInProgress[widgetID]) {
          insightsInProgress[widgetID] = [
            cloneDeep({
              insightsQuery,
              responsesQuery,
              insightID: id,
              isTryAgain,
              dashboardID,
              dataSourceID,
            }),
          ];
        } else {
          insightsInProgress[widgetID].push(
            cloneDeep({
              insightsQuery,
              responsesQuery,
              insightID: id,
              isTryAgain,
              dashboardID,
              dataSourceID,
            }),
          );
        }

        dispatch(
          generateInsightsRequestStart({
            id,
            timestamp: new Date().toISOString(),
            widgetID,
            customPrompt,
            selectedConcept,
            filter: insightsQuery,
            segmentsNames,
            isTryAgain,
            availableFilters,
          }),
        );
      } else {
        const customPrompt = insight!.customPrompt;
        const concept = insight!.concept;
        const totalResponses = insight!.totalResponses!;
        const filter = insight!.filter;
        const segmentsNames = insight!.segmentsNames!;

        dispatch(
          generateInsightsRequestStart({
            id,
            timestamp: new Date().toISOString(),
            widgetID,
            customPrompt,
            selectedConcept: concept,
            filter,
            segmentsNames,
            isTryAgain,
            availableFilters,
          }),
        );
        (dispatch as AppDispatch)(
          generateInsights({
            insightID: id,
            dashboardID,
            dataSourceID,
            widgetID,
            customPrompt,
            selectedConcept: concept,
            isTryAgain,
            filter,
            totalResponses,
          }),
        );
      }

      return;
    }

    if (action?.type === OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS) {
      const typedAction = action as ReturnType<typeof loadAnswersSuccess>;

      const { totalResponses, widgetID } = typedAction.payload;
      const state = getState() as RootState;
      const { filter } = state.openEndedWidgets.configsByWidgetID[widgetID];

      const infoIndex = insightsInProgress[widgetID].findIndex((iip) => isEqual(iip.responsesQuery, filter));
      if (infoIndex >= 0) {
        const { insightID, dashboardID, dataSourceID, isTryAgain, insightsQuery } = insightsInProgress[widgetID][infoIndex];
        const insightsIndex = state.aiInsights.insightsByWidgetID.findIndex((i) => i.widgetID === widgetID);
        if (insightsIndex >= 0) {
          const insightIndex = state.aiInsights.insightsByWidgetID[insightsIndex].insights.findIndex((i) => i.id === insightID);
          if (insightsIndex >= 0) {
            const { customPrompt, concept } = state.aiInsights.insightsByWidgetID[insightsIndex].insights[insightIndex];

            if (totalResponses > 0) {
              (dispatch as AppDispatch)(
                generateInsights({
                  insightID,
                  dashboardID,
                  dataSourceID,
                  widgetID,
                  customPrompt,
                  selectedConcept: concept,
                  isTryAgain,
                  filter: insightsQuery,
                  totalResponses,
                }),
              );
            } else {
              dispatch(
                generateInsightsRequestFailure({
                  id: insightID,
                  widgetID,
                  timestamp: new Date().toISOString(),
                  totalResponses,
                }),
              );
            }

            insightsInProgress[widgetID].splice(infoIndex, 1);
          } else {
            console.error(
              `Could not locate the insight with id: ${insightID} in widget with id: ${widgetID} for response with filter ${JSON.stringify(filter)}`,
            );
          }
        } else {
          console.error(
            `Could not locate the insights in widget with id: ${widgetID}  for response with filter ${JSON.stringify(filter)} and insights id: ${insightID}`,
          );
        }
      }
    }

    if (action?.type === OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE) {
      const typedAction = action as ReturnType<typeof loadAnswersFailure>;

      const { widgetID } = typedAction.payload;
      const state = getState() as RootState;
      const { filter } = state.openEndedWidgets.configsByWidgetID[widgetID];

      const infoIndex = insightsInProgress[widgetID].findIndex((iip) => isEqual(iip.responsesQuery, filter));
      if (infoIndex >= 0) {
        const { insightID, dashboardID, dataSourceID, isTryAgain, insightsQuery } = insightsInProgress[widgetID][infoIndex];
        const insightsIndex = state.aiInsights.insightsByWidgetID.findIndex((i) => i.widgetID === widgetID);
        if (insightsIndex >= 0) {
          const insightIndex = state.aiInsights.insightsByWidgetID[insightsIndex].insights.findIndex((i) => i.id === insightID);
          if (insightsIndex >= 0) {
            const { customPrompt, concept } = state.aiInsights.insightsByWidgetID[insightsIndex].insights[insightIndex];
            (dispatch as AppDispatch)(
              generateInsights({
                insightID,
                dashboardID,
                dataSourceID,
                widgetID,
                customPrompt,
                selectedConcept: concept,
                isTryAgain,
                filter: insightsQuery,
                totalResponses: 0,
              }),
            );

            insightsInProgress[widgetID].splice(infoIndex, 1);
          } else {
            console.error(
              `Could not locate the insight with id: ${insightID} in widget with id: ${widgetID} for response with filter ${JSON.stringify(filter)}`,
            );
          }
        } else {
          console.error(
            `Could not locate the insights in widget with id: ${widgetID}  for response with filter ${JSON.stringify(filter)} and insights id: ${insightID}`,
          );
        }
      }
    }

    if (action?.type === AI_INSIGHTS_RESET) {
      for (const key of Object.keys(insightsInProgress)) {
        delete insightsInProgress[key];
      }
    }
    // Otherwise, pass the action down the middleware chain as usual
    return next(action);
  };

export default insightsLoader;
