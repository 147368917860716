import React from 'react';
import styled from 'styled-components';

import ParticipantsSelect from '../../../components/Responses/ParticipantsSelect';
import { Participant } from '../../../../../store/reducers/interviewWidget/types';
import LabelsSelectContainer from '../../../components/LabelsSelect/LabelsSelectContainer';
import { Concept } from '../../../../../store/reducers/aiInsights';
import { Label } from '../../../../../types/Label';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  gap: 8px;
  background-color: var(--light-gray);
  padding: 8px;
  border-radius: 8px;
`;

const InputContainer = styled.div`
  max-width: 300px;
  width: 100%;
  min-width: 160px;
  height: 100%;
  flex-grow: 1;
`;

interface IConversationFilters {
  participants: Participant[];
  widgetName: string;
  selectedLabelItem: Concept | null;
  searchString: string;
  labels: Label[];
  onCustomersFilterChange: (ids: string[]) => void;
  onSearchStringChange: (searchString: string) => void;
  onLabelSelect: (label: string) => void;
}

function ConversationFilters({
  participants,
  widgetName,
  selectedLabelItem,
  searchString,
  labels,
  onCustomersFilterChange,
  onSearchStringChange,
  onLabelSelect,
}: IConversationFilters) {
  return (
    <Container>
      <InputContainer>
        <ParticipantsSelect participants={participants} onChange={onCustomersFilterChange} />
      </InputContainer>
      <InputContainer>
        <LabelsSelectContainer
          widgetName={widgetName}
          selectedLabelItem={selectedLabelItem}
          searchString={searchString}
          labels={labels}
          onSearchStringChange={onSearchStringChange}
          onLabelSelect={onLabelSelect}
        />
      </InputContainer>
    </Container>
  );
}

export default ConversationFilters;
