import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CompareTableIcon } from '../../../assets/icons/compare-table.svg';
import { ReactComponent as CompareChartIcon } from '../../../assets/icons/compare-chart.svg';
import { ReactComponent as CompareCombinedIcon } from '../../../assets/icons/compare-combined.svg';
import { CompareView } from '../constants';
import palette from '../../../components/tools/palette';

const ToggleContainer = styled.div`
  align-items: center;
  color: ${palette.mediumGray};
  display: flex;
  margin: 10px 20px 0 20px;
`;

const IconsContainer = styled.div`
  color: gray;
  display: flex;
`;

const IconWrapper = styled.div<{ $selected?: boolean }>`
  border: 1px solid ${palette.mediumGray};
  border-right-width: 0;
  cursor: pointer;
  padding: 4px;
  ${({ $selected }) => $selected && `color: ${palette.linkColorDark};`}
  &:hover {
    color: ${palette.linkColorDark};
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    border-right-width: 1px;
  }
  svg {
    display: block;
  }
`;

const SplitLine = styled.div`
  border-top: 1px solid ${palette.mediumGray};
  flex-grow: 1;
  height: 1px;
`;

interface IChartTableToggle {
  view: CompareView;
  onChange: (view: CompareView) => void;
}

function ChartTableToggle({ view, onChange }: IChartTableToggle) {
  return (
    <ToggleContainer>
      <SplitLine />
      <IconsContainer>
        <IconWrapper
          $selected={view === CompareView.TABLE}
          onClick={onChange.bind(null, CompareView.TABLE)}
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Table'
        >
          <CompareTableIcon />
        </IconWrapper>
        <IconWrapper
          $selected={view === CompareView.COMBINED}
          onClick={onChange.bind(null, CompareView.COMBINED)}
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Combined'
        >
          <CompareCombinedIcon />
        </IconWrapper>
        <IconWrapper
          $selected={view === CompareView.CHART}
          onClick={onChange.bind(null, CompareView.CHART)}
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Chart'
        >
          <CompareChartIcon />
        </IconWrapper>
      </IconsContainer>
      <SplitLine />
    </ToggleContainer>
  );
}

export default ChartTableToggle;
