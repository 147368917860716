import { Button, Container } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import landingError from '../../assets/images/landing-error.png';
import palette from '../tools/palette';

const LandingContainer = styled(Container)`
  && {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const TextContainer = styled('div')`
  text-align: center;
  color: ${palette.primaryColorDark};
  margin-bottom: 25px;
`;

const TextBlock = styled('p')`
  font-weight: 100;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
`;

const HomeButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    color: ${palette.primaryColorDark};
    background-color: #fff;
  }
`;

const Landing = ({ title, text, debug }) => {
  const { company: selectedCompany } = useParams();

  return (
    <LandingContainer fixed maxWidth='lg'>
      <TextContainer>
        <h1>{title}</h1>
        <TextBlock>{text}</TextBlock>
      </TextContainer>
      {!debug && <img src={landingError} alt="We'll be back soon!" style={{ width: '60%' }} />}
      {debug && <textarea rows='30' cols='120' style={{ marginBottom: '25px' }} defaultValue={debug} />}
      <HomeButton variant='outlined' component={Link} to={selectedCompany ? `/company/${selectedCompany}` : '/'}>
        Back to Home
      </HomeButton>
    </LandingContainer>
  );
};

export default Landing;
