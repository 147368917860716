import { useAppSelector } from '../../../../store/hooks';
import { SurveySourceEnum } from '../../../../types/connections';

import CSVTab from './CSVTab';

interface ICSVTabContainer {
  handleAdd: () => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string) => void;
}

function CSVTabContainter({ handleAdd, handleEnrich }: ICSVTabContainer) {
  const { csvSurveys, surveysLoading: isSurveysLoading } = useAppSelector((state) => state.connectors);

  return <CSVTab csvSurveys={csvSurveys} isSurveysLoading={isSurveysLoading} handleAdd={handleAdd} handleEnrich={handleEnrich} />;
}

export default CSVTabContainter;
