import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { Route, Switch } from 'react-router-dom';

import { setSelectedCompany } from '../../contexts/api-routes';
import { EVENT_PROPERTY_COMPANY_NAME } from '../../services/EventTrackerService/events';
import { profileSetCompanies, profileSetCompany, profileSetHmac } from '../../store/actions/profile';
import { dashboardsReset } from '../../store/actions/dashboards';
import Loading from '../LoadingLabel/LoadingLabel';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { eventsTracker } from '../../services/EventTrackerService';
import * as api from '../tools/api';
import Programs from '../../pages/Programs/Programs';

const CompanyRouter = ({ match }) => {
  const { update: intercomUpdate } = useIntercom();
  const { user } = useSelector((state) => state.profile);
  const { company: selectedCompany } = match.params;
  const { companies, company } = useSelector((state) => state.profile);
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companies && !fetched) {
      const fetchCompanies = async () => {
        const userData = await api.fetchUser();
        const companiesList = userData.companies;
        dispatch(profileSetCompanies(companiesList));
        dispatch(profileSetHmac(userData.user_hmac));
        setFetched(true);
      };
      fetchCompanies();
    }
  }, [companies, fetched, dispatch]);

  useEffect(() => {
    if (companies && selectedCompany) {
      const profileCompany = companies.find((cmp) => cmp.name === selectedCompany);
      dispatch(profileSetCompany(profileCompany || {}));
      dispatch(dashboardsReset());
    }
  }, [companies, selectedCompany, dispatch]);

  setSelectedCompany(selectedCompany);

  if (!(user && company)) {
    return <Loading />;
  }

  eventsTracker.addToContext({ [EVENT_PROPERTY_COMPANY_NAME]: company.name });

  // Intercom
  intercomUpdate({
    customAttributes: {
      company: company.displayName,
    },
  });

  return (
    <Switch>
      <Route path='/company/:company/dashboard/:dashboardId' component={Dashboard} />
      <Route path='/company/:company' component={Programs} />
    </Switch>
  );
};

export default CompanyRouter;
