import React from 'react';
import styled from 'styled-components';

import { SEVERITY } from '../../../Notification/types';
import { useAppSelector } from '../../../../store/hooks';
import { SurveySourceEnum } from '../../../../types/connections';

import { StepDescription, StepLink, StepBold, GroupsContainer, GroupContainer, GroupTitle, StepsContainer } from './styles';
import Alert from './Alert';
import InstructionStep from './InstructionStep';
import Copyable from './Copyable';

import g1s1 from '../../../../assets/images/qualtrics/g1s1.png';
import g1s2 from '../../../../assets/images/qualtrics/g1s2.png';
import g2s1 from '../../../../assets/images/qualtrics/g1s1.png';
import g2s2 from '../../../../assets/images/qualtrics/g2s2.png';
import g2s3 from '../../../../assets/images/qualtrics/g2s3.png';
import g2s4_1 from '../../../../assets/images/qualtrics/g2s4_1.png';
import g2s4_2 from '../../../../assets/images/qualtrics/g2s4_2.png';
import g2s5 from '../../../../assets/images/qualtrics/g2s5.png';
import g2s6_1 from '../../../../assets/images/qualtrics/g2s6_1.png';
import g2s6_2 from '../../../../assets/images/qualtrics/g2s6_2.png';

// Duplicate from 'import { SURVEY_SOURCE_TO_SCOPES } from "../../constants"', to solve the cyclic dependency, that affects the CYPRESS
const SURVEY_SOURCE_TO_SCOPES: Record<SurveySourceEnum, string[]> = {
  [SurveySourceEnum.AZURE]: [],
  [SurveySourceEnum.QUALTRICS]: ['read:survey_responses', 'read:surveys'],
  [SurveySourceEnum.SURVEYMONKEY]: [],
  [SurveySourceEnum.TYPEFORM]: [],
  [SurveySourceEnum.CSV]: [],
};

const STEPS_CONTAINER_WIDTH = 650;

const Container = styled.div`
  width: 100%;
`;

const MultiImagesContainer = styled.div`
  display: flex;
  gap: 1em;
  align-items: flex-start;
  padding-right: 1em;
`;

const MoreContainer = styled.div`
  margin-bottom: 2em;
`;

const MoreLink = styled.a`
  color: var(--black);
  text-decoration: underline;
  cursor: pointer;
`;

const ScopesListContainer = styled.ul`
  list-style-type: none;
  padding-left: 1em;
  margin: 0;
`;

const StyledImage = styled.img<{ sourceWidth: string }>`
  object-fit: contain;
  object-position: 0 0;
  width: 100%;
  ${(props) => `max-width: ${props.sourceWidth};`}
  @media (min-width: 2560px) {
    ${(props) => `max-width: calc(1.5 * ${props.sourceWidth});`}
  }
`;

const StyledMultiImage = styled.img<{ sourceWidth: string }>`
  object-fit: contain;
  object-position: 0 0;
  width: 50%;

  ${(props) => `max-width: ${props.sourceWidth};`}
  @media (min-width: 2560px) {
    ${(props) => `max-width: calc(1.5 * ${props.sourceWidth});`}
  }
`;

const CopyableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const CopyableTitle = styled.div`
  color: var(--dark-purple);
`;

const Group2Step6DetailsContainer = styled.p`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

function Group1Step1Summary() {
  return (
    <StepDescription>
      Login to{' '}
      <StepLink href='https://www.qualtrics.com/' target='_blank'>
        Qualtrics XM - Experience Management Software,
      </StepLink>{' '}
      then select the user settings icon in the top right-hand corner. Select <StepBold>Account Settings</StepBold> in user settings
      dropdown.
    </StepDescription>
  );
}

function Group1Step2Summary() {
  return (
    <StepDescription>
      Next, select <StepBold>Qualtrics IDs</StepBold>. Then (in the IDs tab) find your <StepBold>Datacenter ID</StepBold> in the User
      section.
    </StepDescription>
  );
}

function Group2Step1Summary() {
  return (
    <StepDescription>
      Login to{' '}
      <StepLink href='https://www.qualtrics.com/' target='_blank'>
        Qualtrics XM - Experience Management Software,
      </StepLink>{' '}
      then select the user settings icon in the top right-hand corner. Select <StepBold>Account Settings</StepBold> in user settings
      dropdown.
    </StepDescription>
  );
}

function Group2Step2Summary() {
  return (
    <StepDescription>
      Next, select <StepBold>Qualtrics IDs</StepBold>. Navigate to <StepBold>OAuth Client Manager</StepBold>, and then select{' '}
      <StepBold>+ Create Client</StepBold>
    </StepDescription>
  );
}

function Group2Step3Summary() {
  return (
    <StepDescription>
      Enter <StepBold>Name</StepBold> and <StepBold>Contact</StepBold> information.
    </StepDescription>
  );
}

function Group2Step4Summary() {
  return (
    <StepDescription>
      Next, define <StepBold>Scopes</StepBold> from the dropdown either by finding them in the list or searching for them.
    </StepDescription>
  );
}

function Group2Step4Details() {
  const scopes = SURVEY_SOURCE_TO_SCOPES[SurveySourceEnum.QUALTRICS].map((scope) => (
    <li key={scope}>
      <StepDescription>• {scope}</StepDescription>
    </li>
  ));
  return (
    <>
      <p>
        <StepDescription withPadding>
          • Select your preferred scope(s) by selecting them from options listed in the dropdown. Continue for all the scopes you wish the
          client to have.
        </StepDescription>
      </p>
      <Alert title='Required scopes for Beehive AI integration:'>
        <ScopesListContainer>{scopes}</ScopesListContainer>
      </Alert>
    </>
  );
}

function Group2Step4Images() {
  return (
    <MultiImagesContainer>
      <StyledMultiImage src={g2s4_1} sourceWidth='500px' alt='Step 4 - 1' />
      <StyledMultiImage src={g2s4_2} sourceWidth='500px' alt='Step 4 - 2' />
    </MultiImagesContainer>
  );
}

function Group2Step5Summary() {
  return (
    <StepDescription>
      Select the <StepBold>Authorization Code</StepBold> as the <StepBold>Grant Type</StepBold>.
    </StepDescription>
  );
}

function Group2Step6Summary() {
  return (
    <StepDescription>
      Finally, enter your <StepBold>Redirect URL</StepBold> and click <StepBold>+ Create Client</StepBold> to see the{' '}
      <StepBold>Client ID</StepBold> and <StepBold>Client Secret</StepBold>.
    </StepDescription>
  );
}

function Group2Step6Warning() {
  return (
    <span>
      Note both the <StepBold>Client ID</StepBold> and <StepBold>Client Secret</StepBold> before navigating away, as this will not be
      displayed again later.
    </span>
  );
}

function Group2Step6Details() {
  const { company } = useAppSelector((state) => state.profile);

  const getRedirectURL = (workspaceName: string) => {
    const origin = window.location.origin;
    return `${origin}/oauth/${workspaceName}/qualtrics`;
  };

  return (
    <Group2Step6DetailsContainer>
      <CopyableContainer>
        <CopyableTitle>Your Redirect URL</CopyableTitle>
        <Copyable copiedText={getRedirectURL(company?.name ?? '{{workspaceName}}')}></Copyable>
      </CopyableContainer>
      <Alert title={<Group2Step6Warning />} severity={SEVERITY.WARNING} />
    </Group2Step6DetailsContainer>
  );
}

interface IMore {
  onShowHelpMessageClick: () => void;
}
function More({ onShowHelpMessageClick }: IMore) {
  return (
    <span data-testid='qualtrics-instructions-more'>
      For more information view our{' '}
      <MoreLink href='https://help.beehive.ai/en/articles/9087915-integrating-your-qualtrics-with-beehive-ai' target='_blank'>
        Help page
      </MoreLink>
      , the{' '}
      <MoreLink href='https://api.qualtrics.com/6c02f17c3109f-o-auth-authentication-auth-code' target='_blank'>
        Qualtrics Instruction page
      </MoreLink>
      , and/or <MoreLink onClick={onShowHelpMessageClick}>get in touch</MoreLink> with a Beehive AI representative.
    </span>
  );
}

function Group2Step6Images() {
  return (
    <MultiImagesContainer>
      <StyledMultiImage src={g2s6_1} sourceWidth='500px' alt='Step 6 - 1' width={'50%'} />
      <StyledMultiImage src={g2s6_2} sourceWidth='500px' alt='Step 6 - 2' width={'50%'} />
    </MultiImagesContainer>
  );
}

interface IQualtricsInstructions {
  onShowHelpMessageClick: () => void;
}
function QualtricsInstructions({ onShowHelpMessageClick }: IQualtricsInstructions) {
  return (
    <Container data-testid='qualtrics-instructions'>
      <GroupsContainer>
        <GroupContainer data-testid='qualtrics-instructions-group-1'>
          <GroupTitle>Locating your “Datacenter ID”</GroupTitle>
          <StepsContainer width={STEPS_CONTAINER_WIDTH}>
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 1'
              summary={<Group1Step1Summary />}
              images={<StyledImage src={g1s1} sourceWidth='452px' />}
            />
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 2'
              summary={<Group1Step2Summary />}
              images={<StyledImage src={g1s2} sourceWidth='970px' />}
            />
          </StepsContainer>
        </GroupContainer>
        <GroupContainer data-testid='qualtrics-instructions-group-2'>
          <GroupTitle>Creating a “Client Secret”</GroupTitle>
          <StepsContainer width={STEPS_CONTAINER_WIDTH}>
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 1'
              summary={<Group2Step1Summary />}
              images={<StyledImage src={g2s1} alt='Step 1' sourceWidth='452px' />}
            />
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 2'
              summary={<Group2Step2Summary />}
              images={<StyledImage src={g2s2} alt='Step 2' sourceWidth='970px' />}
            />
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 3'
              summary={<Group2Step3Summary />}
              images={<StyledImage src={g2s3} alt='Step 3' sourceWidth='500px' />}
            />
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 4'
              summary={<Group2Step4Summary />}
              additionalDetails={<Group2Step4Details />}
              images={<Group2Step4Images />}
            />
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 5'
              summary={<Group2Step5Summary />}
              images={<StyledImage src={g2s5} alt='Step 5' sourceWidth='500px' />}
            />
            <InstructionStep
              containerWidth={STEPS_CONTAINER_WIDTH}
              title='Step 6'
              summary={<Group2Step6Summary />}
              additionalDetails={<Group2Step6Details />}
              images={<Group2Step6Images />}
            />
          </StepsContainer>
        </GroupContainer>
        <MoreContainer>
          <More onShowHelpMessageClick={onShowHelpMessageClick} />
        </MoreContainer>
      </GroupsContainer>
    </Container>
  );
}

export default QualtricsInstructions;
