import { ChangeEvent } from 'react';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ControlDatePicker from '../../FilterForm/Controls/ControlDatePicker';
import { OperationTypeEnum } from '../../../types/connections';

import { ScheduleEnum } from '../types';
import { FormGroup, StyledFormControl } from './style';

const IntervalSelectContainer = styled.div`
  margin-left: 80px;
`;

interface IConnectionScheduleGroup {
  scheduleStartDate: string;
  updateInterval: ScheduleEnum;
  operationType?: OperationTypeEnum;
  onChangeUpdateInterval: (interval: ScheduleEnum) => void;
  onChangeScheduleStartDate: (date: string) => void;
}

function ConnectionScheduleGroup({
  scheduleStartDate,
  updateInterval,
  operationType = OperationTypeEnum.IMPORT,
  onChangeScheduleStartDate,
  onChangeUpdateInterval,
}: IConnectionScheduleGroup) {
  function handleChangeUpdateInterval(e: ChangeEvent<{ value: unknown }>) {
    onChangeUpdateInterval(e.target.value as ScheduleEnum);
  }

  return (
    <FormGroup>
      <div data-testid='connection-schedule-group-date-picker'>
        <ControlDatePicker
          value={scheduleStartDate}
          onChange={onChangeScheduleStartDate}
          disabled={false}
          label={operationType === OperationTypeEnum.IMPORT ? 'Schedule Import' : 'Schedule Export'}
          testID='connection-schedule-group-start-date'
        />
      </div>
      <IntervalSelectContainer>
        <StyledFormControl>
          <InputLabel id='interval-select-label'>Select Update Interval</InputLabel>
          <Select
            data-testid='connection-schedule-group-interval'
            value={updateInterval}
            labelId='interval-select-label'
            onChange={handleChangeUpdateInterval}
          >
            <MenuItem value={ScheduleEnum.ONE_TIME}>One-time only</MenuItem>
            <MenuItem value={ScheduleEnum.HOURLY}>Every Hour</MenuItem>
            <MenuItem value={ScheduleEnum.DAILY}>Once a day</MenuItem>
            <MenuItem value={ScheduleEnum.WEEKLY}>Weekly</MenuItem>
          </Select>
        </StyledFormControl>
      </IntervalSelectContainer>
    </FormGroup>
  );
}

export default ConnectionScheduleGroup;
