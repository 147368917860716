import { useMemo } from 'react';

import { MaterialValueSelector } from './MaterialValueSelector';
import { OPTION_SELECT_OPERATOR } from '../constants';

const ControlOperatorSelector = ({ options, ...props }) => {
  const preparedOptions = useMemo(
    () =>
      options.map((option) => {
        if (option.id === OPTION_SELECT_OPERATOR.name) {
          return { ...option, disabled: true };
        }
        return option;
      }),
    [options],
  );

  return <MaterialValueSelector options={preparedOptions} {...props} />;
};

export default ControlOperatorSelector;
