import {
  AuthSourceEnum,
  ConnectionUpdate,
  CsvUploadErrorEnum,
  Integration,
  OperationTypeEnum,
  ProviderAuthentication,
  Survey,
  SurveySourceEnum,
} from '../../types/connections';

// Connectors
export const CONNECTORS_UPDATE_CONNECTORS = 'CONNECTIONS_UPDATE_CONNECTIONS';
export const CONNECTORS_UPDATE_CONNECTORS_LOADING = 'CONNECTIONS_UPDATE_CONNECTORSS_LOADING';
export const CONNECTORS_UPDATE_SELECTED_INTEGRATION = 'CONNECTIONS_UPDATE_SELECTED_INTEGRATION';
export const CONNECTORS_UPDATE_SURVEYS_LOADING = 'CONNECTIONS_UPDATE_SURVEYS_LOADING';

// Add Connection
export const CONNECTORS_OPEN_CONNECTIONS_MODAL = 'CONNECTIONS_OPEN_CONNECTIONS_MODAL';
export const CONNECTORS_CLOSE_CONNECTIONS_MODAL = 'CONNECTIONS_CLOSE_CONNECTIONS_MODAL';
export const CONNECTORS_UPDATE_SUBMITTING_ADD_CONNECTION = 'CONNECTIONS_UPDATE_SUBMITTING_ADD_CONNECTION';
export const CONNECTORS_UPDATE_LAST_ADDED_CONNECTION = 'CONNECTIONS_UPDATE_LAST_ADDED_CONNECTION';
export const CONNECTORS_CLEAR_LAST_ADDED_CONNECTION = 'CONNECTIONS_CLEAR_LAST_ADDED_CONNECTION';

// Add CSV
export const CONNECTORS_UPDATE_SUBMITTING_UPLOAD = 'CONNECTIONS_UPDATE_SUBMITTING_UPLOAD';
export const CONNECTORS_UPDATE_UPLOAD_RESULT = 'CONNECTIONS_UPDATE_UPLOAD_RESULT';

// Update Connection
export const CONNECTORS_UPDATE_CONNECTION = 'CONNECTIONS_UPDATE_CONNECTION';
export const CONNECTORS_UPDATE_CONNECTION_UPDATE_RESULT = 'CONNECTIONS_UPDATE_CONNECTION_UPDATE_RESULT';
export const CONNECTORS_UPDATE_SUBMITTING_CONNECTION_UPDATE = 'CONNECTIONS_UPDATE_SUBMITTING_CONNECTION_UPDATE';
export const CONNECTORS_CLEAR_CONNECTION_UPDATE = 'CONNECTIONS_CLEAR_CONNECTION_UPDATE';

// Delete Connection
export const CONNECTORS_OPEN_DELETE_CONNECTION_MODAL = 'CONNECTIONS_OPEN_DELETE_CONNECTION_MODAL';
export const CONNECTORS_CLOSE_DELETE_CONNECTION_MODAL = 'CONNECTIONS_CLOSE_DELETE_CONNECTION_MODAL';
export const CONNECTORS_UPDATE_SUBMITTING_DELETE_CONNECTION = 'CONNECTIONS_UPDATE_SUBMITTING_DELETE_CONNECTION';
export const CONNECTORS_UPDATE_LAST_DELETED_CONNECTION = 'CONNECTIONS_UPDATE_LAST_DELETED_CONNECTION';
export const CONNECTORS_CLEAR_LAST_DELETED_CONNECTION = 'CONNECTIONS_CLEAR_LAST_DELETED_CONNECTION';
export const CONNECTORS_REMOVE_CONNECTION = 'CONNECTIONS_REMOVE_CONNECTION';

// Update / Create Auth
export const CONNECTORS_UPDATE_LAST_EDITED_AUTH = 'CONNECTIONS_UPDATE_LAST_EDITED_AUTH';
export const CONNECTORS_CLEAR_LAST_EDITED_AUTH = 'CONNECTIONS_CLEAR_LAST_EDITED_AUTH';
export const CONNECTORS_UPDATE_SUBMITTING_AUTH = 'CONNECTIONS_UPDATE_SUBMITTING_AUTH';
export const CONNECTORS_SET_AUTH = 'CONNECTIONS_SET_AUTH';

// Delete Auth
export const CONNECTORS_OPEN_DELETE_AUTH_MODAL = 'CONNECTIONS_OPEN_DELETE_AUTH_MODAL';
export const CONNECTORS_CLOSE_DELETE_AUTH_MODAL = 'CONNECTIONS_CLOSE_DELETE_AUTH_MODAL';
export const CONNECTORS_UPDATE_SUBMITTING_DELETE_AUTH = 'CONNECTIONS_UPDATE_SUBMITTING_DELETE_AUTH';
export const CONNECTORS_UPDATE_LAST_DELETED_AUTH = 'CONNECTIONS_UPDATE_LAST_DELETED_AUTH';
export const CONNECTORS_CLEAR_LAST_DELETED_AUTH = 'CONNECTIONS_CLEAR_LAST_DELETED_AUTH';
export const CONNECTORS_REMOVE_AUTH = 'CONNECTIONS_REMOVE_AUTH';

// Connectors
interface IUpdateConnectorsLoading {
  loading: boolean;
  error?: boolean;
}
export const updateConnectorsLoading = ({ loading, error }: IUpdateConnectorsLoading) => ({
  type: CONNECTORS_UPDATE_CONNECTORS_LOADING,
  payload: {
    loading,
    error,
  },
});

interface IUpdateSurveysLoading {
  loading: boolean;
  error?: boolean;
}
export const updateSurveysLoading = ({ loading, error }: IUpdateSurveysLoading) => ({
  type: CONNECTORS_UPDATE_SURVEYS_LOADING,
  payload: {
    loading,
    error,
  },
});

interface IUpdateConnectors {
  integrations: Integration[];
  csvSurveys?: Partial<Survey>[];
}
export const updateConnectors = ({ integrations, csvSurveys }: IUpdateConnectors) => ({
  type: CONNECTORS_UPDATE_CONNECTORS,
  payload: {
    integrations,
    csvSurveys,
  },
});

interface IUpdateSelectedIntegration {
  source: SurveySourceEnum | null;
}
export const updateSelectedIntegration = ({ source }: IUpdateSelectedIntegration) => ({
  type: CONNECTORS_UPDATE_SELECTED_INTEGRATION,
  payload: {
    source,
  },
});

// Add Connection

interface IUpdateSubmittingAddConnection {
  submitting: boolean;
}
export const updateSubmittingAddConnection = ({ submitting }: IUpdateSubmittingAddConnection) => ({
  type: CONNECTORS_UPDATE_SUBMITTING_ADD_CONNECTION,
  payload: {
    submitting,
  },
});

interface IOpenConnectionsModal {
  source: SurveySourceEnum | null;
  isAuthEdit?: boolean;
}
export const openConnectionsModal = ({ source, isAuthEdit }: IOpenConnectionsModal) => ({
  type: CONNECTORS_OPEN_CONNECTIONS_MODAL,
  payload: {
    source,
    isAuthEdit,
  },
});

export const closeConnectionsModal = () => ({
  type: CONNECTORS_CLOSE_CONNECTIONS_MODAL,
  payload: {},
});

interface IUpdateLastAddedConnection {
  success: boolean;
  source: SurveySourceEnum;
  type: OperationTypeEnum;
}
export const updateLastAddedConnection = ({ success, source, type }: IUpdateLastAddedConnection) => ({
  type: CONNECTORS_UPDATE_LAST_ADDED_CONNECTION,
  payload: {
    success,
    source,
    type,
  },
});

export const clearLastAddedConnection = () => ({
  type: CONNECTORS_CLEAR_LAST_ADDED_CONNECTION,
  payload: {},
});

// Add CSV

interface IUpdateUploadResult {
  success: boolean;
  error?: CsvUploadErrorEnum;
}
export const updateUploadResult = ({ success, error }: IUpdateUploadResult) => ({
  type: CONNECTORS_UPDATE_UPLOAD_RESULT,
  payload: {
    success,
    error,
  },
});

interface IUpdateSubmittingUpload {
  submitting: boolean;
}
export const updateSubmittingUpload = ({ submitting }: IUpdateSubmittingUpload) => ({
  type: CONNECTORS_UPDATE_SUBMITTING_UPLOAD,
  payload: {
    submitting,
  },
});

export const resetUploadResult = () => ({
  type: CONNECTORS_UPDATE_UPLOAD_RESULT,
  payload: {
    success: undefined,
    error: undefined,
  },
});

// Update Connection

interface IUpdateConnection {
  connectionUpdate: ConnectionUpdate;
}
export const updateConnection = ({ connectionUpdate }: IUpdateConnection) => ({
  type: CONNECTORS_UPDATE_CONNECTION,
  payload: {
    connectionUpdate,
  },
});

interface IClearConnectionUpdate {
  id: string;
}
export const clearConnectionUpdate = ({ id }: IClearConnectionUpdate) => ({
  type: CONNECTORS_CLEAR_CONNECTION_UPDATE,
  payload: {
    id,
  },
});

interface IUpdateConnectionUpdateResult {
  id: string;
  success: boolean;
}
export const updateConnectionUpdateResult = ({ id, success }: IUpdateConnectionUpdateResult) => ({
  type: CONNECTORS_UPDATE_CONNECTION_UPDATE_RESULT,
  payload: {
    id,
    success,
  },
});

interface IUpdateSubmittingConnectionUpdate {
  id: string;
  submitting: boolean;
}
export const updateSubmittingConnectionUpdate = ({ id, submitting }: IUpdateSubmittingConnectionUpdate) => ({
  type: CONNECTORS_UPDATE_SUBMITTING_CONNECTION_UPDATE,
  payload: {
    id,
    submitting,
  },
});

// Delete Connection

interface IOpenDeleteConnectionModal {
  id: string;
  source: SurveySourceEnum;
}
export const openDeleteConnectionModal = ({ id, source }: IOpenDeleteConnectionModal) => ({
  type: CONNECTORS_OPEN_DELETE_CONNECTION_MODAL,
  payload: {
    id,
    source,
  },
});

export const closeDeleteConnectionModal = () => ({
  type: CONNECTORS_CLOSE_DELETE_CONNECTION_MODAL,
  payload: {},
});

interface IUpdateSubmittingDeleteConnection {
  submitting: boolean;
}
export const updateSubmittingDeleteConnection = ({ submitting }: IUpdateSubmittingDeleteConnection) => ({
  type: CONNECTORS_UPDATE_SUBMITTING_DELETE_CONNECTION,
  payload: {
    submitting,
  },
});

interface IUpdateLastDeletedConnection {
  success: boolean;
}
export const updateLastDeletedConnection = ({ success }: IUpdateLastDeletedConnection) => ({
  type: CONNECTORS_UPDATE_LAST_DELETED_CONNECTION,
  payload: {
    success,
  },
});

export const clearLastDeletedConnection = () => ({
  type: CONNECTORS_CLEAR_LAST_DELETED_CONNECTION,
  payload: {},
});

interface IRemoveConnection {
  id: string;
  source: SurveySourceEnum;
}
export const removeConnection = ({ id, source }: IRemoveConnection) => ({
  type: CONNECTORS_REMOVE_CONNECTION,
  payload: {
    id,
    source,
  },
});

// Update / Create Auth

interface ISetAuth {
  auth: ProviderAuthentication;
  source: SurveySourceEnum;
}
export const setAuth = ({ auth, source }: ISetAuth) => ({
  type: CONNECTORS_SET_AUTH,
  payload: {
    auth,
    source,
  },
});

interface IUpdateSubmittingAuth {
  submitting: boolean;
}
export const updateSubmittingAuth = ({ submitting }: IUpdateSubmittingAuth) => ({
  type: CONNECTORS_UPDATE_SUBMITTING_AUTH,
  payload: {
    submitting,
  },
});

interface IUpdateLastEditedAuth {
  success: boolean;
  source: AuthSourceEnum;
}
export const updateLastEditedAuth = ({ success, source }: IUpdateLastEditedAuth) => ({
  type: CONNECTORS_UPDATE_LAST_EDITED_AUTH,
  payload: {
    success,
    source,
  },
});

export const clearLastEditedAuth = () => ({
  type: CONNECTORS_CLEAR_LAST_EDITED_AUTH,
  payload: {},
});

interface IOpenDeleteAuthModal {
  id: string;
}
export const openDeleteAuthModal = ({ id }: IOpenDeleteAuthModal) => ({
  type: CONNECTORS_OPEN_DELETE_AUTH_MODAL,
  payload: {
    id,
  },
});

export const closeDeleteAuthModal = () => ({
  type: CONNECTORS_CLOSE_DELETE_AUTH_MODAL,
  payload: {},
});

interface IUpdateSubmittingDeleteAuth {
  submitting: boolean;
}
export const updateSubmittingDeleteAuth = ({ submitting }: IUpdateSubmittingDeleteAuth) => ({
  type: CONNECTORS_UPDATE_SUBMITTING_DELETE_AUTH,
  payload: {
    submitting,
  },
});

interface IUpdateLastDeletedAuth {
  success: boolean;
}
export const updateLastDeletedAuth = ({ success }: IUpdateLastDeletedAuth) => ({
  type: CONNECTORS_UPDATE_LAST_DELETED_AUTH,
  payload: {
    success,
  },
});

export const clearLastDeletedAuth = () => ({
  type: CONNECTORS_CLEAR_LAST_DELETED_AUTH,
  payload: {},
});

interface IRemoveAuth {
  id: string;
}
export const removeAuth = ({ id }: IRemoveAuth) => ({
  type: CONNECTORS_REMOVE_AUTH,
  payload: {
    id,
  },
});
