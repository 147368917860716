import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import uniq from 'lodash/uniq';

import LoadingLabel from '../LoadingLabel/LoadingLabel';
import palette from '../tools/palette';
import { OverviewItemModel } from '../../models/Overview';
import { ResponseChip } from '../../style';
import * as api from '../tools/api';

const useStyles = makeStyles({
  item: {
    marginBottom: '16px',
    backgroundColor: '#fff',
    borderRadius: '10px',
  },
  question: {
    padding: '16px 24px 16px',
    fontSize: '16px',
    color: palette.linkColorLight,
  },
  response: {
    padding: '16px 24px 16px 0',
    fontSize: '16px',
    color: '#060606',
  },
});

const FullRecordDialogContent = ({ answerId }) => {
  const { id: dashboardId, dataSources } = useSelector((state) => state.dashboard.dashboard);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const [entries, setEntries] = useState([]);
  const translationUsed = useSelector((state) => state.app.translationUsed);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const data = await api.fetchFullRecord(dashboardId, answerId);
      setId(data.id);
      setEntries(data.entries);
      setLoading(false);
    };
    fetchData();

    return () => {
      setLoading(false);
    };
  }, [dashboardId, answerId]);

  const responsesByDataSourceId = entries.reduce((acc, { dataSourceID, response, originalResponse, labels }) => {
    const nonEmptyLabels = labels ? labels.filter((label) => label !== '') : [];
    const displayResponse = translationUsed ? response : originalResponse;
    let value = acc[dataSourceID];

    if (value) {
      value = {
        responses: [...value.responses, displayResponse],
        labels: [...value.labels, ...nonEmptyLabels],
      };
    } else {
      value = {
        responses: [displayResponse],
        labels: [...nonEmptyLabels],
      };
    }
    return { ...acc, [dataSourceID]: value };
  }, {});

  if (loading) {
    return (
      <div style={{ padding: '15vh' }}>
        <LoadingLabel loading={true} />
      </div>
    );
  }

  return (
    <>
      <Grid container direction='row' className={classes.item}>
        <Grid item xs={4} className={classes.question}>
          ID
        </Grid>
        <Grid item xs={8} className={classes.response}>
          {id}
        </Grid>
      </Grid>
      {dataSources.map((dataSource) => {
        const data = responsesByDataSourceId[dataSource.dataSourceID];

        return (
          data && (
            <Grid container key={dataSource.id} direction='row' className={classes.item}>
              <Grid item xs={4} className={classes.question}>
                {translationUsed ? dataSource.title : dataSource.originalTitle}
              </Grid>
              <Grid item xs={8} className={classes.response}>
                {dataSource.type === OverviewItemModel.TYPE_OPEN_ENDED && (
                  <>
                    {data.responses.map((response, idx) => (
                      <React.Fragment key={idx}>
                        {response}
                        {idx < data.responses.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                    {data.labels.length > 0 && (
                      <div style={{ marginTop: '8px' }}>
                        {uniq(data.labels).map((label) => (
                          <ResponseChip clickable={false} key={label} size='small' label={label} />
                        ))}
                      </div>
                    )}
                  </>
                )}
                {dataSource.type !== OverviewItemModel.TYPE_OPEN_ENDED && (
                  <>
                    {data.responses.map((label) => (
                      <ResponseChip
                        key={label}
                        data-tooltip-id='base-tooltip'
                        data-tooltip-content={label}
                        clickable={false}
                        style={{ maxWidth: '100%' }}
                        label={label}
                      />
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          )
        );
      })}
    </>
  );
};

export default FullRecordDialogContent;
