import GoogleTracker from './GoogleTracker';
import HubSpotTracker from './HubSpotTracker';
import MixpanelTracker from './MixpanelTracker';
import EventsTrackerService from './EventsTrackerService';
// import ConsoleTracker from "./ConsoleTracker";
import { EVENTS, EVENT_PROPERTY_DASHBOARD_NAME } from './events';

const eventsTracker = new EventsTrackerService();

// const consoleTracker = new ConsoleTracker();
// eventsTracker.registerTracker('console', consoleTracker);

const mixpanelTracker = new MixpanelTracker();
const googleTracker = new GoogleTracker();
const hubSpotTracker = new HubSpotTracker();
eventsTracker.registerTracker('mixpanel', mixpanelTracker);
eventsTracker.registerTracker('google', googleTracker);
eventsTracker.registerTracker('hubspot', hubSpotTracker);

export { eventsTracker, EVENTS, EVENT_PROPERTY_DASHBOARD_NAME };
