import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import OpenEndedWidgetDataPlain from './Data/OpenWidgetDataContainer';
import withSummaryPlainLoader from '../hocLoaders/withSummaryPlainLoader';
import withSummaryBreakdownLoader from '../hocLoaders/withSummaryBreakdownLoader';
import WidgetErrorContent from '../WidgetErrorContent';
import { eventsTracker, EVENTS } from '../../../services/EventTrackerService';

const ComponentPlain = withSummaryPlainLoader(OpenEndedWidgetDataPlain);
const ComponentBreakdown = withSummaryBreakdownLoader(OpenEndedWidgetDataPlain);

function OpenEndedWidgetContent({
  config,
  onExpand,
  refCsvExport,
  refContent,
  refFiltersCount,
  query,
  groupTitle,
  preview = false,
  onResetAllFilters,
}) {
  const { showNewMessages } = useIntercom();
  const sentimentSummaries = useSelector((state) => state.dashboard.sentimentSummaries);
  const { dataSources: dashboardDataSources, title: dashboardTitle } = useSelector((state) => state.dashboard.dashboard);
  const { translationUsed } = useSelector((state) => state.app);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);

  // open-ended doesn't support multiple data sources, so 1st one is used
  const { id: dataSourceId, selectedItems } = config.settings.dataSources[0];

  const handleFeedbackClick = (selectedLabelItem) => {
    const widgetTitle = config.base[translationUsed ? 'title' : 'originalTitle'];
    let message = `Please make the following update(s) to “${dashboardTitle}” dashboard, `;
    if (selectedLabelItem !== null) {
      message += `regarding the label/s “${selectedLabelItem.title}”, `;
    }
    message += `in the “${widgetTitle}” widget: `;

    showNewMessages(message);
  };

  const currentDashboardDataSourceInfo = useMemo(() => {
    const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceId);
    const sentimentSummary = sentimentSummaries.find((sentimentSummary) => sentimentSummary.dataSourceID === dataSourceId) || {};

    if (!dashboardDataSource) {
      return null;
    }

    return {
      ...dashboardDataSource,
      sentimentSummary: sentimentSummary.sentiment,
      usersNum: dashboardDataSource.total,
    };
  }, [sentimentSummaries, dashboardDataSources, dataSourceId]);

  const trackLabelSelection = useCallback(
    (label, isParent, count) => {
      eventsTracker.track(EVENTS.WIDGET_LABEL_SELECT, {
        'Group Name': groupTitle,
        'Widget Name': config.base.title,
        'Label Type': isParent ? 'Parent' : 'Child',
        'Response Count': count,
      });
    },
    [config, groupTitle],
  );

  function handleLabelExpand() {
    eventsTracker.track(EVENTS.WIDGET_LABEL_PARENT_EXPAND, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
    });
  }

  if (currentDashboardDataSourceInfo === null) {
    return <WidgetErrorContent config={config} />;
  }

  if (breakdownFilter && !preview) {
    return (
      <ComponentBreakdown
        config={config}
        currentDashboardDataSourceInfo={currentDashboardDataSourceInfo}
        onExpand={onExpand}
        onFeedbackClick={handleFeedbackClick}
        selectedItems={selectedItems}
        refCsvExport={refCsvExport}
        refContent={refContent}
        refFiltersCount={refFiltersCount}
        query={query}
        onLabelSelectionTrack={trackLabelSelection}
        onResetAllFilters={onResetAllFilters}
        onLabelExpand={handleLabelExpand}
        isPreview={false}
        isBreakdownView={breakdownFilter && !preview}
      />
    );
  } else {
    return (
      <ComponentPlain
        config={config}
        currentDashboardDataSourceInfo={currentDashboardDataSourceInfo}
        onExpand={onExpand}
        onFeedbackClick={handleFeedbackClick}
        selectedItems={selectedItems}
        refCsvExport={refCsvExport}
        refContent={refContent}
        refFiltersCount={refFiltersCount}
        query={query}
        onLabelSelectionTrack={trackLabelSelection}
        isPreview={preview}
        onResetAllFilters={onResetAllFilters}
        onLabelExpand={handleLabelExpand}
        isBreakdownView={breakdownFilter && !preview}
      />
    );
  }
}

export default OpenEndedWidgetContent;
