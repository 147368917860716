import {
  updateConnectors,
  CONNECTORS_UPDATE_CONNECTORS,
  updateConnection,
  CONNECTORS_UPDATE_CONNECTION,
  CONNECTORS_UPDATE_SELECTED_INTEGRATION,
  updateSelectedIntegration,
  openConnectionsModal,
  CONNECTORS_OPEN_CONNECTIONS_MODAL,
  CONNECTORS_UPDATE_SUBMITTING_DELETE_CONNECTION,
  CONNECTORS_UPDATE_SUBMITTING_DELETE_AUTH,
  updateSubmittingDeleteAuth,
  updateSubmittingDeleteConnection,
  openDeleteConnectionModal,
  openDeleteAuthModal,
  CONNECTORS_CLOSE_DELETE_CONNECTION_MODAL,
  CONNECTORS_OPEN_DELETE_CONNECTION_MODAL,
  CONNECTORS_OPEN_DELETE_AUTH_MODAL,
  CONNECTORS_CLOSE_DELETE_AUTH_MODAL,
  removeConnection,
  removeAuth,
  CONNECTORS_REMOVE_CONNECTION,
  CONNECTORS_REMOVE_AUTH,
  CONNECTORS_CLOSE_CONNECTIONS_MODAL,
  setAuth,
  CONNECTORS_SET_AUTH,
  updateLastDeletedAuth,
  updateLastDeletedConnection,
  CONNECTORS_UPDATE_LAST_DELETED_CONNECTION,
  CONNECTORS_UPDATE_LAST_DELETED_AUTH,
  clearLastDeletedAuth,
  clearLastDeletedConnection,
  CONNECTORS_CLEAR_LAST_DELETED_CONNECTION,
  CONNECTORS_CLEAR_LAST_DELETED_AUTH,
  clearConnectionUpdate,
  CONNECTORS_CLEAR_CONNECTION_UPDATE,
  updateConnectionUpdateResult,
  updateSubmittingConnectionUpdate,
  CONNECTORS_UPDATE_CONNECTION_UPDATE_RESULT,
  CONNECTORS_UPDATE_SUBMITTING_CONNECTION_UPDATE,
  updateLastAddedConnection,
  updateLastEditedAuth,
  CONNECTORS_UPDATE_LAST_ADDED_CONNECTION,
  CONNECTORS_UPDATE_LAST_EDITED_AUTH,
  CONNECTORS_CLEAR_LAST_ADDED_CONNECTION,
  CONNECTORS_CLEAR_LAST_EDITED_AUTH,
  updateSubmittingAuth,
  CONNECTORS_UPDATE_SUBMITTING_AUTH,
  updateSubmittingAddConnection,
  CONNECTORS_UPDATE_SUBMITTING_ADD_CONNECTION,
  updateConnectorsLoading,
  CONNECTORS_UPDATE_CONNECTORS_LOADING,
  CONNECTORS_UPDATE_SURVEYS_LOADING,
  updateSurveysLoading,
  CONNECTORS_UPDATE_SUBMITTING_UPLOAD,
  updateSubmittingUpload,
  CONNECTORS_UPDATE_UPLOAD_RESULT,
  updateUploadResult,
} from '../actions/connectors';
import { AuthSourceEnum, CsvUploadErrorEnum, Integration, OperationTypeEnum, Survey, SurveySourceEnum } from '../../types/connections';
import { v4 } from 'uuid';

export interface IConnectorsReducer {
  csvSurveys: Partial<Survey>[];
  integrations: Integration[];
  selectedIntegration?: Integration;
  connectorsLoading: boolean;
  connectorsLoadingError?: boolean;
  surveysLoading: boolean;
  surveysLoadingError?: boolean;
  addCSV: {
    uploadingSuccess?: boolean;
    uploadingError?: CsvUploadErrorEnum;
    submittingUpload?: boolean;
  };
  addConnection: {
    open: boolean;
    source: string | null;
    isAuthEdit?: boolean;
    lastAddedConnection?: {
      id: string;
      success: boolean;
      type: OperationTypeEnum;
      source: SurveySourceEnum;
    };
    lastEditedAuth?: {
      id: string;
      success: boolean;
      source: AuthSourceEnum;
    };
    submittingAuth?: boolean;
    submittingConnection?: boolean;
  };
  connectionUpdates: Record<string, { success?: boolean; submitting?: boolean }>;
  deleteConnection: {
    open: boolean;
    id?: string;
    source?: SurveySourceEnum;
    submitting: boolean;
    lastDeletedId?: string;
    lastDeletedSuccess?: boolean;
  };
  deleteAuth: {
    open: boolean;
    id?: string;
    submitting: boolean;
    lastDeletedId?: string;
    lastDeletedSuccess?: boolean;
  };
}

const initialState: IConnectorsReducer = {
  csvSurveys: [],
  integrations: [],
  connectorsLoading: false,
  surveysLoading: false,
  addCSV: {},
  addConnection: {
    open: false,
    source: null,
  },
  connectionUpdates: {},
  deleteConnection: {
    open: false,
    submitting: false,
  },
  deleteAuth: {
    open: false,
    submitting: false,
  },
};

type connectionsActionTypes = ReturnType<typeof updateConnectors> &
  ReturnType<typeof updateConnectorsLoading> &
  ReturnType<typeof updateSurveysLoading> &
  ReturnType<typeof updateSelectedIntegration> &
  ReturnType<typeof updateConnection> &
  ReturnType<typeof updateConnectionUpdateResult> &
  ReturnType<typeof updateSubmittingConnectionUpdate> &
  ReturnType<typeof clearConnectionUpdate> &
  ReturnType<typeof openConnectionsModal> &
  ReturnType<typeof updateLastAddedConnection> &
  ReturnType<typeof updateLastEditedAuth> &
  ReturnType<typeof updateSubmittingDeleteAuth> &
  ReturnType<typeof updateLastDeletedAuth> &
  ReturnType<typeof clearLastDeletedAuth> &
  ReturnType<typeof updateSubmittingDeleteConnection> &
  ReturnType<typeof updateLastDeletedConnection> &
  ReturnType<typeof clearLastDeletedConnection> &
  ReturnType<typeof openDeleteConnectionModal> &
  ReturnType<typeof openDeleteAuthModal> &
  ReturnType<typeof removeConnection> &
  ReturnType<typeof removeAuth> &
  ReturnType<typeof setAuth> &
  ReturnType<typeof updateSubmittingAuth>;

export const connectorsReducer = (state: IConnectorsReducer = initialState, action: connectionsActionTypes) => {
  switch (action.type) {
    // Connectors
    case CONNECTORS_UPDATE_CONNECTORS:
      return handleUpdateConnectors(state, action);
    case CONNECTORS_UPDATE_CONNECTORS_LOADING:
      return handleUpdateConnectorsLoading(state, action);
    case CONNECTORS_UPDATE_SELECTED_INTEGRATION:
      return handleUpdateSelectedIntegration(state, action);
    case CONNECTORS_UPDATE_SURVEYS_LOADING:
      return handleUpdateSurveysLoading(state, action);
    // Add Connection
    case CONNECTORS_UPDATE_SUBMITTING_ADD_CONNECTION:
      return handleUpdateSubmittingAddConnection(state, action);
    case CONNECTORS_OPEN_CONNECTIONS_MODAL:
      return handleOpenConnectionsModal(state, action);
    case CONNECTORS_CLOSE_CONNECTIONS_MODAL:
      return handleCloseConnectionsModal(state);
    case CONNECTORS_UPDATE_LAST_ADDED_CONNECTION:
      return handleUpdateLastAddedConnection(state, action);
    case CONNECTORS_CLEAR_LAST_ADDED_CONNECTION:
      return handleClearLastAddedConnection(state);
    // Add CSV
    case CONNECTORS_UPDATE_SUBMITTING_UPLOAD:
      return handleUpdateSubmittingUpload(state, action);
    case CONNECTORS_UPDATE_UPLOAD_RESULT:
      return handleUpdateUploadResult(state, action);
    // Update Connection
    case CONNECTORS_UPDATE_CONNECTION:
      return handleUpdateConnection(state, action);
    case CONNECTORS_UPDATE_CONNECTION_UPDATE_RESULT:
      return handleUpdateConnectionUpdateResult(state, action);
    case CONNECTORS_UPDATE_SUBMITTING_CONNECTION_UPDATE:
      return handleUpdateSubmittingConnectionUpdate(state, action);
    case CONNECTORS_CLEAR_CONNECTION_UPDATE:
      return handleClearConnectionUpdate(state, action);
    // Delete Connection
    case CONNECTORS_UPDATE_SUBMITTING_DELETE_CONNECTION:
      return handleUpdateSubmittingDeleteConnection(state, action);
    case CONNECTORS_UPDATE_LAST_DELETED_CONNECTION:
      return handleUpdateLastDeletedConnection(state, action);
    case CONNECTORS_CLEAR_LAST_DELETED_CONNECTION:
      return handleClearLastDeletedConnection(state);
    case CONNECTORS_OPEN_DELETE_CONNECTION_MODAL:
      return handleOpenDeleteConnectionModal(state, action);
    case CONNECTORS_CLOSE_DELETE_CONNECTION_MODAL:
      return handleCloseDeleteConnectionModal(state);
    case CONNECTORS_REMOVE_CONNECTION:
      return handleRemoveConnection(state, action);
    // Update / Create Auth
    case CONNECTORS_UPDATE_LAST_EDITED_AUTH:
      return handleUpdateLastEditedAuth(state, action);
    case CONNECTORS_CLEAR_LAST_EDITED_AUTH:
      return handleClearLastEditedAuth(state);
    case CONNECTORS_UPDATE_SUBMITTING_AUTH:
      return handleUpdateSubmittingAuth(state, action);
    case CONNECTORS_SET_AUTH:
      return handleSetAuth(state, action);
    // Delete Auth
    case CONNECTORS_UPDATE_SUBMITTING_DELETE_AUTH:
      return handleUpdateSubmittingDeleteAuth(state, action);
    case CONNECTORS_UPDATE_LAST_DELETED_AUTH:
      return handleUpdateLastDeletedAuth(state, action);
    case CONNECTORS_CLEAR_LAST_DELETED_AUTH:
      return handleClearLastDeletedAuth(state);
    case CONNECTORS_OPEN_DELETE_AUTH_MODAL:
      return handleOpenDeleteAuthModal(state, action);
    case CONNECTORS_CLOSE_DELETE_AUTH_MODAL:
      return handleCloseDeleteAuthModal(state);
    case CONNECTORS_REMOVE_AUTH:
      return handleRemoveAuth(state, action);
    default:
      return state;
  }
};

// Connectors
function handleUpdateConnectors(state: IConnectorsReducer, action: ReturnType<typeof updateConnectors>): IConnectorsReducer {
  // Because surveymonkey and typeform connections are currently built from surveys that are fetched separately,
  // we need to handle updating connections by source
  const newIntegrations = [...action.payload.integrations].reduce((integrations, integration) => {
    const index = integrations.findIndex(({ source: integrationSource }) => integrationSource === integration.source);
    if (index === -1) {
      integrations.push(integration);
      return integrations;
    }
    integrations[index] = integration;
    return integrations;
  }, state.integrations);
  const selectedIntegration = newIntegrations.find(
    ({ source: integrationSource }) => integrationSource === state.selectedIntegration?.source,
  );
  return {
    ...state,
    integrations: newIntegrations,
    csvSurveys: action.payload.csvSurveys ?? state.csvSurveys,
    selectedIntegration,
  };
}

function handleUpdateSelectedIntegration(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateSelectedIntegration>,
): IConnectorsReducer {
  const source = action.payload.source;
  const selectedIntegration = state.integrations.find(({ source: integrationSource }) => integrationSource === source);

  return {
    ...state,
    selectedIntegration,
  };
}

export function handleUpdateConnectorsLoading(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateConnectorsLoading>,
): IConnectorsReducer {
  return {
    ...state,
    connectorsLoading: action.payload.loading,
    connectorsLoadingError: action.payload.error,
  };
}

export function handleUpdateSurveysLoading(state: IConnectorsReducer, action: ReturnType<typeof updateSurveysLoading>): IConnectorsReducer {
  return {
    ...state,
    surveysLoading: action.payload.loading,
    surveysLoadingError: action.payload.error,
  };
}

// Add Connection
function handleOpenConnectionsModal(state: IConnectorsReducer, action: ReturnType<typeof openConnectionsModal>): IConnectorsReducer {
  const selectedIntegration = state.integrations.find(({ source: integrationSource }) => integrationSource === action.payload.source);
  return {
    ...state,
    addConnection: {
      open: true,
      source: action.payload.source,
      isAuthEdit: action.payload.isAuthEdit,
    },
    selectedIntegration,
  };
}

function handleCloseConnectionsModal(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      open: false,
      source: null,
    },
  };
}

function handleUpdateLastAddedConnection(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateLastAddedConnection>,
): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      ...state.addConnection,
      lastAddedConnection: {
        id: v4(),
        success: action.payload.success,
        type: action.payload.type,
        source: action.payload.source,
      },
    },
  };
}

function handleClearLastAddedConnection(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      ...state.addConnection,
      lastAddedConnection: undefined,
    },
  };
}

function handleUpdateSubmittingAddConnection(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateSubmittingAddConnection>,
): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      ...state.addConnection,
      submittingConnection: action.payload.submitting,
    },
  };
}

// Add CSV

function handleUpdateSubmittingUpload(state: IConnectorsReducer, action: ReturnType<typeof updateSubmittingUpload>): IConnectorsReducer {
  return {
    ...state,
    addCSV: {
      ...state.addCSV,
      submittingUpload: action.payload.submitting,
    },
  };
}

function handleUpdateUploadResult(state: IConnectorsReducer, action: ReturnType<typeof updateUploadResult>): IConnectorsReducer {
  return {
    ...state,
    addCSV: {
      ...state.addCSV,
      uploadingSuccess: action.payload.success,
      uploadingError: action.payload.error,
    },
  };
}

// Update Connection
function handleUpdateConnection(state: IConnectorsReducer, action: ReturnType<typeof updateConnection>): IConnectorsReducer {
  const { source, id, ...connection } = action.payload.connectionUpdate;
  const integrationIndex = state.integrations.findIndex(({ source: integrationSource }) => integrationSource === source);
  if (integrationIndex === -1) {
    console.error('Unable to update connection. Integration not found');
    return state;
  }
  const connectionIndex = state.integrations[integrationIndex].connections.findIndex(({ id: connectionId }) => connectionId === id);
  if (connectionIndex === -1) {
    console.error('Unable to update connection. Connection not found');
    return state;
  }
  const newIntegrations = [...state.integrations];
  const currentConnection = newIntegrations[integrationIndex].connections[connectionIndex];
  newIntegrations[integrationIndex].connections[connectionIndex] = {
    ...currentConnection,
    ...connection,
  };
  return {
    ...state,
    integrations: newIntegrations,
    selectedIntegration: newIntegrations[integrationIndex],
  };
}

function handleUpdateConnectionUpdateResult(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateConnectionUpdateResult>,
): IConnectorsReducer {
  const { id, success } = action.payload;
  const connectionUpdates = { ...state.connectionUpdates };
  connectionUpdates[id] = {
    ...connectionUpdates[id],
    success,
  };
  return {
    ...state,
    connectionUpdates,
  };
}

function handleUpdateSubmittingConnectionUpdate(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateSubmittingConnectionUpdate>,
): IConnectorsReducer {
  const { id, submitting } = action.payload;
  const connectionUpdates = { ...state.connectionUpdates };
  connectionUpdates[id] = {
    ...connectionUpdates[id],
    submitting,
  };
  return {
    ...state,
    connectionUpdates,
  };
}

function handleClearConnectionUpdate(state: IConnectorsReducer, action: ReturnType<typeof clearConnectionUpdate>): IConnectorsReducer {
  const { id } = action.payload;
  const connectionUpdates = { ...state.connectionUpdates };
  delete connectionUpdates[id];
  return {
    ...state,
    connectionUpdates,
  };
}

// Delete Connection
function handleUpdateSubmittingDeleteConnection(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateSubmittingDeleteConnection>,
): IConnectorsReducer {
  return {
    ...state,
    deleteConnection: {
      ...state.deleteConnection,
      submitting: action.payload.submitting,
    },
  };
}

function handleUpdateLastDeletedConnection(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateLastDeletedConnection>,
): IConnectorsReducer {
  return {
    ...state,
    deleteConnection: {
      ...state.deleteConnection,
      lastDeletedId: v4(),
      lastDeletedSuccess: action.payload.success,
    },
  };
}

function handleClearLastDeletedConnection(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    deleteConnection: {
      ...state.deleteConnection,
      lastDeletedId: undefined,
      lastDeletedSuccess: undefined,
    },
  };
}

function handleOpenDeleteConnectionModal(
  state: IConnectorsReducer,
  action: ReturnType<typeof openDeleteConnectionModal>,
): IConnectorsReducer {
  return {
    ...state,
    deleteConnection: {
      ...state.deleteConnection,
      open: true,
      id: action.payload.id,
      source: action.payload.source,
    },
  };
}

function handleCloseDeleteConnectionModal(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    deleteConnection: {
      ...state.deleteConnection,
      open: false,
      id: undefined,
      source: undefined,
    },
  };
}

function handleRemoveConnection(state: IConnectorsReducer, action: ReturnType<typeof removeConnection>): IConnectorsReducer {
  const { source, id } = action.payload;
  const integrationIndex = state.integrations.findIndex(({ source: integrationSource }) => integrationSource === source);
  if (integrationIndex === -1) {
    console.error('Unable to remove connection. Integration not found');
    return state;
  }
  const connectionIndex = state.integrations[integrationIndex].connections.findIndex(({ id: connectionId }) => connectionId === id);
  if (connectionIndex === -1) {
    console.error('Unable to remove connection. Connection not found');
    return state;
  }
  const newIntegrations = [...state.integrations];
  newIntegrations[integrationIndex].connections.splice(connectionIndex, 1);
  return {
    ...state,
    integrations: newIntegrations,
    selectedIntegration: newIntegrations[integrationIndex],
  };
}

// Update / Create Auth
function handleUpdateLastEditedAuth(state: IConnectorsReducer, action: ReturnType<typeof updateLastEditedAuth>): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      ...state.addConnection,
      lastEditedAuth: {
        id: v4(),
        success: action.payload.success,
        source: action.payload.source,
      },
    },
  };
}

function handleClearLastEditedAuth(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      ...state.addConnection,
      lastEditedAuth: undefined,
    },
  };
}

function handleUpdateSubmittingAuth(state: IConnectorsReducer, action: ReturnType<typeof updateSubmittingAuth>): IConnectorsReducer {
  return {
    ...state,
    addConnection: {
      ...state.addConnection,
      submittingAuth: action.payload.submitting,
    },
  };
}

function handleSetAuth(state: IConnectorsReducer, action: ReturnType<typeof setAuth>): IConnectorsReducer {
  const { auth, source } = action.payload;
  const integrationIndex = state.integrations.findIndex((integration) => integration.source === source);
  if (integrationIndex === -1) {
    return state;
  }
  const newIntegrations = [...state.integrations];
  newIntegrations[integrationIndex].authentication = { ...auth };
  return {
    ...state,
    integrations: newIntegrations,
    selectedIntegration: newIntegrations[integrationIndex],
  };
}

// Delete Auth
function handleUpdateSubmittingDeleteAuth(
  state: IConnectorsReducer,
  action: ReturnType<typeof updateSubmittingDeleteAuth>,
): IConnectorsReducer {
  return {
    ...state,
    deleteAuth: {
      ...state.deleteAuth,
      submitting: action.payload.submitting,
    },
  };
}

function handleUpdateLastDeletedAuth(state: IConnectorsReducer, action: ReturnType<typeof updateLastDeletedAuth>): IConnectorsReducer {
  return {
    ...state,
    deleteAuth: {
      ...state.deleteAuth,
      lastDeletedId: v4(),
      lastDeletedSuccess: action.payload.success,
    },
  };
}

function handleClearLastDeletedAuth(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    deleteAuth: {
      ...state.deleteAuth,
      lastDeletedId: undefined,
      lastDeletedSuccess: undefined,
    },
  };
}

function handleOpenDeleteAuthModal(state: IConnectorsReducer, action: ReturnType<typeof openDeleteAuthModal>): IConnectorsReducer {
  return {
    ...state,
    deleteAuth: {
      ...state.deleteAuth,
      open: true,
      id: action.payload.id,
    },
  };
}

function handleCloseDeleteAuthModal(state: IConnectorsReducer): IConnectorsReducer {
  return {
    ...state,
    deleteAuth: {
      ...state.deleteAuth,
      open: false,
      id: undefined,
    },
  };
}

function handleRemoveAuth(state: IConnectorsReducer, action: ReturnType<typeof removeAuth>): IConnectorsReducer {
  const { id } = action.payload;
  const integrationIndex = state.integrations.findIndex(({ authentication }) => authentication?.id === id);
  if (integrationIndex === -1) {
    return state;
  }
  const newIntegrations = [...state.integrations];
  delete newIntegrations[integrationIndex].authentication;
  return {
    ...state,
    integrations: newIntegrations,
    selectedIntegration: newIntegrations[integrationIndex],
  };
}

export default connectorsReducer;
