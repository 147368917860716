import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import TrendsWidgetContentBreakdown from './TrendsWidgetContent/TrendsWidgetContentBreakdown';
import TrendsWidgetContentPlain from './TrendsWidgetContent/TrendsWidgetContentPlain';
import useFilters from '../../../hooks/useFilters';
import WidgetErrorContent from '../WidgetErrorContent';
import { WidgetMessageContent } from '../../UI';
import { FILTER_ID_ALL } from '../../../constants';

const TrendsWidgetContent = ({ config, refCsvExport, refContent, refFiltersCount, query, preview = false }) => {
  const [origFilters] = useFilters();
  const filters = useMemo(() => {
    return preview ? origFilters.filter((filter) => filter.id === FILTER_ID_ALL) : origFilters;
  }, [origFilters, preview]);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);

  const dashboardId = config.base.dashboardID;
  const dataSourceId = config.settings.dataSources[0].id;
  const settingsSelectedItems = config.settings.dataSources[0].selectedItems;
  const aggregationRange = config.settings.aggregationRange;
  const dataSource = dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceId);
  const dashboardDataSource = dashboardDataSources.find((dds) => dds.dataSourceID === dataSourceId);
  const dataSourceLabels = useMemo(() => {
    if (!dashboardDataSource) {
      return [];
    }
    return dataSource.items.map((item) => item.response);
  }, [dataSource, dashboardDataSource]);

  const selectedItems = settingsSelectedItems.length === 0 ? dataSourceLabels : settingsSelectedItems;

  if (!dashboardDataSource) {
    return <WidgetErrorContent config={config} />;
  }

  if (dashboardDataSource.isEmpty) {
    return <WidgetMessageContent>This widget doesn't have any responses yet.</WidgetMessageContent>;
  }

  if (breakdownFilter && !preview) {
    return (
      <TrendsWidgetContentBreakdown
        config={config}
        filters={filters}
        dashboardId={dashboardId}
        dataSourceId={dataSourceId}
        aggregationRange={aggregationRange}
        selectedItems={selectedItems}
        breakdownFilter={breakdownFilter}
        refCsvExport={refCsvExport}
        refContent={refContent}
        refFiltersCount={refFiltersCount}
        query={query}
      />
    );
  } else {
    return (
      <TrendsWidgetContentPlain
        config={config}
        filters={filters}
        dashboardId={dashboardId}
        dataSourceId={dataSourceId}
        aggregationRange={aggregationRange}
        selectedItems={selectedItems}
        refCsvExport={refCsvExport}
        refContent={refContent}
        refFiltersCount={refFiltersCount}
        preview={preview}
        query={query}
      />
    );
  }
};

export default TrendsWidgetContent;
