import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Box, Tab } from '@material-ui/core';
import styled from 'styled-components';

import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { TestsContext } from '../../contexts/TestsContext';
import InfoBadgesArea from '../../components/ResultsTab/InfoBadgesArea';
import { StyledResultsTabs } from '../../components/dashboard/style';
import ResponsesTab from '../../components/ResultsTab/ResponsesTab/ResponsesTab';
import TrendsTab from '../../components/dashboard/results/TrendsTab';
import SegmentsTab from '../../components/SegmentsTab/SegmentsTab';
import DashboardToolbar from '../../components/DashboardToolbar/DashboardToolbarContainer';
import { Sidebar, SidebarDashboardProvider } from '../../components/Sidebar';
import SidebarItemExport from '../../components/Sidebar/SidebarItems/SidebarItemExport';
import SidebarItemShare from '../../components/Sidebar/SidebarItems/SidebarItemShare';
import SidebarItemControlPanel from '../../components/Sidebar/SidebarItems/SidebarItemControlPanel';
import SidebarItemTranslation from '../../components/Sidebar/SidebarItems/SidebarItemTranslation';
import SidebarItemWeightedMetrics from '../../components/Sidebar/SidebarItems/SidebarItemWeightedMetrics';
import SidebarItemColorPalette from '../../components/Sidebar/SidebarItems/SidebarItemColorPalette';
import SidebarItemBreakdown from '../../components/Sidebar/SidebarItems/SidebarItemBreakdown';
import SidebarItemAddToDashboard from '../../components/Sidebar/SidebarItems/SidebarItemAddToDashboard';
import SidebarItemCreateFilter from '../../components/Sidebar/SidebarItems/SidebarItemCreateFilter';
import SidebarItemSavedFilters from '../../components/Sidebar/SidebarItems/SidebarItemSavedFilters';
import { toggleWeightedMetricsUsage } from '../../store/actions/actionsCreators';
import { SURVEY_STATUS_CONFIG } from '../../constants';
import CompareTab from '../../components/CompareTab/CompareTab';
import TabTutorialBlockCompare from './TabTutorialBlockCompare';
import FlexSpacer from '../../components/UI/FlexSpacer';
import { dashboardInitWidgetWatcher, dashboardClearWatcher } from '../../store/actions/dashboard';

import GenerationFeedbackContainer from './components/Feedback/GenerationFeedbackContainer';
import KeyQuotesContainer from './components/KeyQuotes/KeyQuotesContainer';
import RefreshAIContainer from './components/RefreshAI/RefreshAIContainer';
import { ControlPanelDialog } from './ControlPanelDialog';

const TAB_ID = {
  RESPONSES: 'responses-tab',
  TRENDS: 'trends-tab',
  COMPARE: 'compare-tab',
  SEGMENTS: 'segments-tab',
};

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const DashboardContent = () => {
  const { isUnsafe } = useContext(TestsContext);
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const { company } = useSelector((state) => state.profile);
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);
  const hasWeights = useSelector((state) => state.dashboard.dashboard.hasWeights);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const { pathname, search } = useLocation();
  const [showCompareTutorialLink, setShowCompareTutorialLink] = useState(true);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const underMaintenance = dashboard.underMaintenance;
  useEffect(() => {
    dispatch(dashboardInitWidgetWatcher({ dashboardID: dashboard.id }));

    return () => {
      dispatch(dashboardClearWatcher({ dashboardID: dashboard.id }));
    };
  }, [dispatch]);
  useEffect(() => {
    setShowControlPanel(underMaintenance);
  }, [underMaintenance]);

  useEffect(() => {
    if (!hasWeights) {
      dispatch(toggleWeightedMetricsUsage(false));
    }
  }, [hasWeights, dispatch]);

  const tabs = [
    {
      event: EVENTS.RESPONSES_TAB_SELECT,
      id: TAB_ID.RESPONSES,
      props: { label: 'Responses', to: `${url}/responses` },
      render: () => <ResponsesTab />,
    },
    {
      event: EVENTS.COMPARE_TAB_SELECT,
      id: TAB_ID.COMPARE,
      props: { label: 'Compare', to: `${url}/compare` },
      render: () => <CompareTab />,
    },
    {
      event: EVENTS.TRENDS_TAB_SELECT,
      id: TAB_ID.TRENDS,
      props: { label: 'Trends', to: `${url}/trends` },
      render: () => <TrendsTab />,
    },
    {
      event: EVENTS.SEGMENTS_TAB,
      id: TAB_ID.SEGMENTS,
      props: { label: 'Segments', to: `${url}/segments` },
      render: (routeProps) => <SegmentsTab routeProps={routeProps} responsesURL={`${url}/responses`} />,
      hidden: true, // TODO: Uncomment when logic is fixed
    },
  ].filter((tab) => !tab.hidden || tab.props.to === pathname);

  let selectedTabIndex = tabs.findIndex((t) => t.props.to === pathname);
  selectedTabIndex = selectedTabIndex === -1 ? 0 : selectedTabIndex;
  const selectedTab = tabs[selectedTabIndex];

  const handleTabClick = (tab) => {
    const prevTabLabel = typeof selectedTab.props.label === 'string' ? selectedTab.props.label : selectedTab.label;
    eventsTracker.track(tab.event, {
      Origin: prevTabLabel,
      'Weighted Metrics': weightedMetricsUsed,
    });
  };

  const handleControlPanelIconClick = () => {
    eventsTracker.track(EVENTS.OPEN_CONTROL_PANEL, {
      Status: dashboard.status in SURVEY_STATUS_CONFIG ? SURVEY_STATUS_CONFIG[dashboard.status].title : dashboard.status,
      'Dashboard Access': !dashboard.underMaintenance,
    });
    setShowControlPanel(true);
  };

  const isBlocked = dashboard.underMaintenance && !isUnsafe;

  const handleStatusDialogClose = () => {
    if (isBlocked) {
      // prevent closing the dialog when the dashboard isn't ready
      return;
    }
    setShowControlPanel(false);
  };

  return (
    <Box>
      <DashboardToolbar
        dashboardId={dashboard.id}
        title={dashboard.title}
        showInviteLink
        disableInviteLink={!company.admin}
        exitText
        showAccountLink
      />
      <SidebarDashboardProvider key={selectedTab.id}>
        <Box display='flex'>
          <Sidebar>
            {selectedTab.id !== TAB_ID.SEGMENTS && <SidebarItemCreateFilter />}
            {selectedTab.id !== TAB_ID.SEGMENTS && <SidebarItemSavedFilters />}
            {selectedTab.id === TAB_ID.RESPONSES && <SidebarItemBreakdown />}
            {selectedTab.id === TAB_ID.RESPONSES && <SidebarItemAddToDashboard />}
            <SidebarItemTranslation />
            {hasWeights && <SidebarItemWeightedMetrics />}
            {selectedTab.id !== TAB_ID.SEGMENTS && <SidebarItemColorPalette />}
            <SidebarItemShare />
            <SidebarItemExport />
            <SidebarItemControlPanel onClick={handleControlPanelIconClick} />
          </Sidebar>
          <Box flexGrow={1} style={{ minWidth: 0 }}>
            {selectedTab.id !== TAB_ID.COMPARE && <InfoBadgesArea showFilters={selectedTab.id !== TAB_ID.SEGMENTS} />}

            <TabsWrapper>
              <StyledResultsTabs value={selectedTabIndex} indicatorColor='primary' textColor='primary'>
                {tabs.map((tabData) => (
                  <Tab
                    {...tabData.props}
                    component={Link}
                    data-testid={tabData.id}
                    onClick={handleTabClick.bind(null, tabData)}
                    key={tabData.props.label}
                    to={tabData.props.to + search}
                  />
                ))}
              </StyledResultsTabs>
              <FlexSpacer />
              {selectedTab.id === TAB_ID.COMPARE && showCompareTutorialLink && (
                <TabTutorialBlockCompare onClose={setShowCompareTutorialLink.bind(null, false)} />
              )}
            </TabsWrapper>

            <Switch>
              {tabs.map((tabData) => (
                <Route key={tabData.props.label} path={tabData.props.to} render={tabData.render} />
              ))}
              <Redirect from={url} to={tabs[0].props.to + search} />
            </Switch>

            {showControlPanel && <ControlPanelDialog dashboard={dashboard} onClose={handleStatusDialogClose} />}
          </Box>
        </Box>
      </SidebarDashboardProvider>
      <GenerationFeedbackContainer />
      <KeyQuotesContainer />
      <RefreshAIContainer />
    </Box>
  );
};

export default DashboardContent;
