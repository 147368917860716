import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useFilters from '../../../hooks/useFilters';
import useSummaryPlainFetch from '../../../hooks/fetch/useSummaryPlainFetch';
import useSummaryPlainProcessing from '../../../hooks/processing/useSummaryPlainProcessing';

const withSummaryPlainLoader = (BaseComponent) => {
  return (props) => {
    const { config, query } = props;
    const [filters] = useFilters();
    const dashboardId = useSelector((state) => state.dashboard.dashboard.id);
    const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);

    const skipFetching = config.base.collapsed;

    const dataSourceId = config.settings.dataSources[0].id;
    const dashboardDataSource = dashboardDataSources.find((dds) => dds.dataSourceID === dataSourceId);
    const visibleDataSourcesIds = useMemo(
      () => (dashboardDataSource && !dashboardDataSource.isEmpty ? [dataSourceId] : []),
      [dashboardDataSource, dataSourceId],
    );

    const { responses, status } = useSummaryPlainFetch(dashboardId, filters, visibleDataSourcesIds, query, skipFetching);
    const overviewData = useSummaryPlainProcessing(responses, visibleDataSourcesIds);

    return <BaseComponent overviewAPIOutput={overviewData} loading={status} {...props} />;
  };
};

export default withSummaryPlainLoader;
