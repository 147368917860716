import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import { DATA_SOURCE_TYPE, WIDGET_TYPE } from '../../../../constants';
import WidgetLabelsAutocompleteControl from '../../../UI/LabelsAutocompleteControl/WidgetLabelsAutocompleteControl';

const DATA_SOURCE_ALLOWED_WIDGETS = {
  [DATA_SOURCE_TYPE.OPEN_ENDED]: [WIDGET_TYPE.OPEN_ENDED, WIDGET_TYPE.TRENDS, WIDGET_TYPE.BARS],
  [DATA_SOURCE_TYPE.INTERVIEW]: [WIDGET_TYPE.INTERVIEW],
  [DATA_SOURCE_TYPE.MULTI_CHOICE]: [WIDGET_TYPE.TRENDS, WIDGET_TYPE.BARS],
  [DATA_SOURCE_TYPE.OTHER]: [WIDGET_TYPE.TRENDS, WIDGET_TYPE.BARS],
};

const GridControlWrapper = styled(Grid)`
  padding-right: 10px;
`;

const CreateWidgetsDialogRow = ({ data, onChange, onDelete, enableDelete, groupList }) => {
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const { translationUsed } = useSelector((state) => state.app);
  const [dataSourceType, setDataSourceType] = useState();
  const [groupValue, setGroupValue] = useState('');

  const visibleDataSources = useMemo(() => {
    return dashboardDataSources.filter((dataSource) => dataSource.visible);
  }, [dashboardDataSources]);

  const handleDataSourceIdChange = (event) => {
    const dataSourceId = event.target.value;
    const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceId);
    setDataSourceType(dashboardDataSource.type);
    onChange({ ...data, dataSourceId });
  };

  const handleTypeChange = (event) => {
    onChange({ ...data, type: event.target.value });
  };

  const handleGroupChange = (event, value) => {
    setGroupValue(value);
    onChange({ ...data, groupTitle: value });
  };

  const handleGroupValueChange = (event) => {
    setGroupValue(event.target.value);
  };

  const handleBlur = (event) => {
    onChange({ ...data, groupTitle: groupValue });
  };

  const handleItemsChange = (items) => {
    onChange({ ...data, meta: { items } });
  };

  return (
    <Grid container direction='column' onFocus={(event) => event.stopPropagation()}>
      <Grid item xs={12}>
        <Grid container direction='row'>
          <GridControlWrapper item xs={5}>
            <FormControl fullWidth variant='outlined' size='small'>
              <Select data-testid='select-data-source' value={data.dataSourceId} onChange={handleDataSourceIdChange} displayEmpty>
                {!data.dataSourceId && (
                  <MenuItem value='' disabled>
                    Source
                  </MenuItem>
                )}
                {visibleDataSources.map((dataSource) => (
                  <MenuItem key={dataSource.dataSourceID} value={dataSource.dataSourceID}>
                    {translationUsed ? dataSource.title : dataSource.originalTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridControlWrapper>
          <GridControlWrapper item xs={2}>
            <FormControl fullWidth variant='outlined' size='small' label={'Hello'}>
              <Select data-testid='select-type' value={data.type} onChange={handleTypeChange} displayEmpty disabled={!data.dataSourceId}>
                {!data.type && (
                  <MenuItem value='' disabled>
                    Type
                  </MenuItem>
                )}
                {dataSourceType && DATA_SOURCE_ALLOWED_WIDGETS[dataSourceType].includes(WIDGET_TYPE.BARS) && (
                  <MenuItem key={WIDGET_TYPE.BARS} value={WIDGET_TYPE.BARS}>
                    Bars
                  </MenuItem>
                )}
                {dataSourceType && DATA_SOURCE_ALLOWED_WIDGETS[dataSourceType].includes(WIDGET_TYPE.TRENDS) && (
                  <MenuItem key={WIDGET_TYPE.TRENDS} value={WIDGET_TYPE.TRENDS}>
                    Trends
                  </MenuItem>
                )}
                {dataSourceType && DATA_SOURCE_ALLOWED_WIDGETS[dataSourceType].includes(WIDGET_TYPE.OPEN_ENDED) && (
                  <MenuItem key={WIDGET_TYPE.OPEN_ENDED} value={WIDGET_TYPE.OPEN_ENDED}>
                    Open-ended
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridControlWrapper>
          <GridControlWrapper item xs={4}>
            <Autocomplete
              freeSolo
              onBlur={handleBlur}
              onChange={handleGroupChange}
              onInputChange={handleGroupValueChange}
              options={groupList}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant='outlined' size='small' placeholder='Group' data-testid='group-name' />
              )}
            />
          </GridControlWrapper>
          <Grid item xs={1}>
            {enableDelete && (
              <IconButton size='small' onClick={onDelete}>
                <CancelIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      {data.type === WIDGET_TYPE.TRENDS && (
        <GridControlWrapper item xs={11} style={{ paddingTop: '10px' }}>
          <Grid container direction='row' alignItems='center'>
            <Grid item xs={2}>
              Items
            </Grid>
            <Grid item xs>
              <WidgetLabelsAutocompleteControl dataSourceID={data.dataSourceId} value={data.meta.items} onChange={handleItemsChange} />
            </Grid>
          </Grid>
        </GridControlWrapper>
      )}
    </Grid>
  );
};

export default CreateWidgetsDialogRow;
