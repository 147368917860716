import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useSummaryBreakdownProcessing = (responses, filters, dataSourceId, visibleDataSourcesIds, breakdownFilter, mapBasedBreakdown) => {
  const { weightedMetricsUsed, translationUsed } = useSelector((state) => state.app);
  const {
    dataSources,
    dashboard: { dataSources: dashboardDataSources },
  } = useSelector((state) => state.dashboard);

  const breakdownBucketIndexes = useMemo(() => {
    return dataSources
      .find((dataSource) => dataSource.dataSourceID === breakdownFilter.dataSourceID)
      .items.reduce((acc, item, index) => {
        return { ...acc, [item.response]: index };
      }, {});
  }, [breakdownFilter, dataSources]);

  // TODO: Unify breakdown processing. Now, Bars and OpenEnded uses different formats
  return useMemo(() => {
    let breakdownData;
    const valueType = weightedMetricsUsed ? 'weighted' : 'base';
    if (mapBasedBreakdown) {
      const result = new Map();
      responses.forEach((response, responseId) => {
        if (filters[responseId]) {
          const filterId = filters[responseId].id;
          [...response]
            .sort((a, b) => breakdownBucketIndexes[a.value] - breakdownBucketIndexes[b.value])
            .forEach((bucket) => {
              const bucketName = bucket.value;
              const bucketDataSource = bucket.items[0];
              const dataSource = dataSources.find((ds) => ds.dataSourceID === dataSourceId);
              const dataByLabel = (bucketDataSource ? bucketDataSource.items : []).reduce((acc, item) => {
                return { ...acc, [item.response]: item[valueType] };
              }, {});
              for (let item of dataSource.items) {
                const displayResponse = translationUsed ? item.response : item.originalResponse;
                const data = dataByLabel[item.response]
                  ? dataByLabel[item.response]
                  : {
                      percentage: 0,
                      value: 0,
                    };
                if (!result.get(displayResponse)) {
                  result.set(displayResponse, new Map());
                }
                if (!result.get(displayResponse).has(bucketName)) {
                  result.get(displayResponse).set(bucketName, []);
                }
                result
                  .get(displayResponse)
                  .get(bucketName)
                  .push({
                    ...data,
                    filterId,
                    displayResponse,
                  });
              }
            });
        }
      });
      breakdownData = result;
    } else {
      const result = {};
      responses.forEach((response, responseId) => {
        if (filters[responseId]) {
          const filterId = filters[responseId].id;
          [...response]
            .sort((a, b) => breakdownBucketIndexes[a.value] - breakdownBucketIndexes[b.value])
            .forEach((bucket) => {
              const bucketName = bucket.value;
              const bucketDataSources = bucket.items;
              for (let visibleDataSourcesId of visibleDataSourcesIds) {
                let bucketDataSource = bucketDataSources.find((bDS) => bDS.dataSourceID === visibleDataSourcesId);
                let missed = false;
                const ds = dataSources.find((ds) => ds.dataSourceID === visibleDataSourcesId);
                if (!bucketDataSource) {
                  bucketDataSource = ds;
                  missed = true;
                }
                const dataByLabel = bucketDataSource.items.reduce((acc, item) => {
                  const data = missed ? { percentage: 0, value: 0 } : item[valueType];
                  return { ...acc, [item.response]: data };
                }, {});
                if (!result[filterId]) {
                  result[filterId] = {};
                }
                if (!result[filterId][bucketName]) {
                  result[filterId][bucketName] = {};
                }
                const dataSourceInfo = dashboardDataSources.find(
                  (dashboardDataSource) => dashboardDataSource.dataSourceID === bucketDataSource.dataSourceID,
                );
                const items = ds.items
                  .map((item) => {
                    const data = dataByLabel[item.response] ? dataByLabel[item.response] : { percentage: 0, value: 0 };
                    return {
                      ...item,
                      ...data,
                      displayResponse: translationUsed ? item.response : item.originalResponse,
                    };
                  })
                  .sort((a, b) => (dataSourceInfo.ordered ? 1 : a[valueType].value - b[valueType].value));
                result[filterId][bucketName][bucketDataSource.dataSourceID] = {
                  ...bucketDataSource,
                  items,
                };
              }
            });
        }
      });
      breakdownData = result;
    }
    return breakdownData;
  }, [
    responses,
    filters,
    dataSourceId,
    visibleDataSourcesIds,
    breakdownBucketIndexes,
    mapBasedBreakdown,
    weightedMetricsUsed,
    translationUsed,
    dataSources,
    dashboardDataSources,
  ]);
};

export default useSummaryBreakdownProcessing;
