import uniq from 'lodash/uniq';

import { ICompareRow, ResponseField, SegmentsMinMax, SortDirection, ThresholdRange, Thresholds } from '../compare';
import { SEGMENT_ID_ANY, SortField } from '../constants';

export const expandAggregatedRows = (rows: ICompareRow[]): ICompareRow[] => {
  const res: ICompareRow[] = [];
  for (const row of rows) {
    for (const subRow of row.data) {
      res.push({
        ...row,
        id: `${row.id}-${subRow.filterMinID}-${subRow.filterMaxID}`,
        data: [subRow],
      });
    }
  }
  return res;
};

const isValueInRange = (value: number, range: ThresholdRange): boolean => {
  return value >= range[0] && value <= range[1];
};

export const filterRows = (
  rows: ICompareRow[],
  selectedFilterIds: string[],
  thresholds: Thresholds,
  selectedDataSourceIDs: string[],
  segmentsMinMax: SegmentsMinMax,
): ICompareRow[] => {
  return rows
    .reduce((acc: ICompareRow[], preparedRow) => {
      if (thresholds.dataSourceTotal && !isValueInRange(preparedRow.dataSourceTotal, thresholds.dataSourceTotal)) {
        return acc;
      }
      if (!selectedDataSourceIDs.includes(preparedRow.dataSourceID)) {
        return acc;
      }
      const newData = preparedRow.data.filter((subRow) => {
        if (
          (segmentsMinMax.max !== SEGMENT_ID_ANY && subRow.filterMaxID !== segmentsMinMax.max) ||
          (segmentsMinMax.min !== SEGMENT_ID_ANY && subRow.filterMinID !== segmentsMinMax.min)
        ) {
          return false;
        }

        if (!selectedFilterIds.includes(subRow.filterMinID) || !selectedFilterIds.includes(subRow.filterMaxID)) {
          return false;
        }
        if (thresholds.differencePercentage && !isValueInRange(subRow.differencePercentage, thresholds.differencePercentage)) {
          return false;
        }
        if (thresholds.differenceAbsolute && !isValueInRange(subRow.differenceAbsolute, thresholds.differenceAbsolute)) {
          return false;
        }
        return !thresholds.pValue || isValueInRange(subRow.pValue, thresholds.pValue);
      });

      if (newData.length === 0) {
        return acc;
      }

      return acc.concat({ ...preparedRow, data: newData });
    }, [])
    .sort((a, b) => {
      const maxA = a.data.reduce((acc, subRow) => Math.max(acc, subRow.differencePercentage), 0);
      const maxB = b.data.reduce((acc, subRow) => Math.max(acc, subRow.differencePercentage), 0);
      return maxB - maxA;
    })
    .map((row, index) => ({ ...row, index: index + 1 }));
};

export const translateRows = (rows: ICompareRow[], dashboardDataSources: any[], translationUsed: boolean): ICompareRow[] => {
  const responseField: ResponseField = translationUsed ? 'response' : 'originalResponse';
  const titleField: string = translationUsed ? 'title' : 'originalTitle';
  const titleByID = dashboardDataSources.reduce<{ [id: string]: string }>((acc, dashboardDataSource) => {
    return {
      ...acc,
      [dashboardDataSource.dataSourceID]: dashboardDataSource[titleField],
    };
  }, {});

  return rows.map((row) => ({
    ...row,
    concept: row[responseField],
    dataSourceTitle: titleByID[row.dataSourceID],
  }));
};

export const sortRows = (rows: ICompareRow[], sortBy: SortField, direction: SortDirection): ICompareRow[] => {
  return rows.sort((a, b) => {
    const maxA = a.data.reduce((acc, subRow) => Math.max(acc, subRow.differencePercentage), 0);
    const maxB = b.data.reduce((acc, subRow) => Math.max(acc, subRow.differencePercentage), 0);
    let comp;

    switch (sortBy) {
      case SortField.DIFFERENCE_ABSOLUTE:
      case SortField.FILTER_MIN_PERCENTAGE:
      case SortField.FILTER_MIN_VALUE:
      case SortField.FILTER_MAX_PERCENTAGE:
      case SortField.FILTER_MAX_VALUE:
        const subRowValueA = a.data.reduce((acc, subRow) => Math.min(acc, subRow[sortBy]), Number.MAX_VALUE);
        const subRowValueB = b.data.reduce((acc, subRow) => Math.min(acc, subRow[sortBy]), Number.MAX_VALUE);
        comp = direction === 'asc' ? subRowValueA - subRowValueB : subRowValueB - subRowValueA;
        break;
      case SortField.P_VALUE:
        const subRowMinPValueA = a.data.reduce((acc, subRow) => Math.min(acc, subRow[sortBy]), 1);
        const subRowMinPValueB = b.data.reduce((acc, subRow) => Math.min(acc, subRow[sortBy]), 1);
        comp = direction === 'asc' ? subRowMinPValueA - subRowMinPValueB : subRowMinPValueB - subRowMinPValueA;
        break;
      case SortField.DATA_SOURCE_TOTAL:
        comp = direction === 'asc' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
        break;
      case SortField.DATA_SOURCE_TITLE:
      case SortField.CONCEPT:
        comp = direction === 'asc' ? a[sortBy].localeCompare(b[sortBy]) : -a[sortBy].localeCompare(b[sortBy]);
        break;
      default:
        comp = direction === 'asc' ? maxA - maxB : maxB - maxA;
    }
    return comp === 0 && sortBy !== SortField.DIFFERENCE_PERCENTAGE ? maxB - maxA : comp;
  });
};

export const getUniqIds = (rows: ICompareRow[]): string[] => {
  const allIds = rows.map((row) => `${row.concept}-${row.dataSourceID}`);
  return uniq(allIds);
};
