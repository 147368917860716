import { useEffect, useRef, useState } from 'react';

import { FETCH_STATUS, PERIOD } from '../../../constants';
import * as api from '../../tools/api';
import { fillMissedDates } from './lib';

const POINTS_NUMBER_THRESHOLD = 7;

const useSurveyTotalTrends = (dashboardId, period) => {
  const [fetchPeriod, setFetchPeriod] = useState(period);
  const [loading, setLoading] = useState(FETCH_STATUS.IDLE);
  const [surveysTrends, setSurveysTrends] = useState([]);
  const firstLoad = useRef(true);

  useEffect(() => {
    setFetchPeriod(period);
  }, [period]);

  useEffect(() => {
    let mounted = true;

    const fetchTrends = async () => {
      setLoading(FETCH_STATUS.LOADING);
      setSurveysTrends([]);
      const trends = await api.fetchTotalTrends(dashboardId, fetchPeriod);

      // check if number of points is acceptable, otherwise change the period
      let pointsNumber = 0;
      if (trends) {
        for (let trend of trends) {
          pointsNumber = Math.max(pointsNumber, trend.buckets.length);
        }
      }
      if (firstLoad.current) {
        if (pointsNumber < POINTS_NUMBER_THRESHOLD) {
          switch (fetchPeriod) {
            case PERIOD.QUARTER:
              setFetchPeriod(PERIOD.MONTH);
              return;
            case PERIOD.MONTH:
              setFetchPeriod(PERIOD.WEEK);
              return;
            case PERIOD.WEEK:
              setFetchPeriod(PERIOD.DAY);
              return;
            default:
            // not enough data
          }
        }
        firstLoad.current = false;
      }

      if (mounted) {
        const data = fillMissedDates(trends, fetchPeriod);
        setSurveysTrends(data);
        setLoading(FETCH_STATUS.LOADED);
      }

      return () => {
        mounted = false;
      };
    };

    fetchTrends();
  }, [dashboardId, fetchPeriod]);

  return [loading, surveysTrends, fetchPeriod];
};

export default useSurveyTotalTrends;
