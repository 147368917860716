export const OPTION_SELECT_SOURCE = {
  name: 'OPTION_SELECT_SOURCE',
  label: 'Select source',
  group: null,
  valueEditorType: 'labelSelector',
};

export const OPTION_SELECT_OPERATOR = {
  name: 'OPTION_SELECT_OPERATOR',
  label: 'Select operator',
  group: null,
  valueEditorType: 'labelSelector',
};

export const OPERATOR_NAME = {
  IN: 'in',
  NOT_IN: 'notIn',
  SENTIMENT: 'sentiment',
  EQUAL_TO: '=',
  GREATER_THAN_OR_EQUAL_TO: '>=',
  LESS_THAN_OR_EQUAL_TO: '<=',
  DATE_RANGE: 'dateRange',
  CONTAINS: 'contains',
};

export const OPERATOR_NAME_LABEL = {
  [OPERATOR_NAME.IN]: 'Is',
  [OPERATOR_NAME.NOT_IN]: 'Is Not',
  [OPERATOR_NAME.SENTIMENT]: 'Sentiment',
  [OPERATOR_NAME.EQUAL_TO]: 'On',
  [OPERATOR_NAME.GREATER_THAN_OR_EQUAL_TO]: 'Since',
  [OPERATOR_NAME.LESS_THAN_OR_EQUAL_TO]: 'Before',
  [OPERATOR_NAME.DATE_RANGE]: 'Date range',
};

export const OPERATOR = Object.keys(OPERATOR_NAME).reduce((acc, key) => {
  return {
    ...acc,
    [key]: {
      name: OPERATOR_NAME[key],
      label: OPERATOR_NAME_LABEL[OPERATOR_NAME[key]],
    },
  };
}, {});

export const COMBINATOR = {
  AND: 'and',
  OR: 'or',
};

export const RULE_TYPE = {
  VALUES: 'values',
  SENTIMENT: 'sentiment',
  DATE: 'date',
  GROUP: 'group',
  CUSTOMERS: 'customers',
};
