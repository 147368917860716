import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { ChangeEvent } from 'react';

import { StyledFormControl } from './style';

interface IConnectionAzureFileSelect {
  files: string[];
  selectedFile: string;
  onSelectFile: (file: string) => void;
}

function ConnectionAzureFileSelect({ files, selectedFile, onSelectFile }: IConnectionAzureFileSelect) {
  function handleSelectFile(e: ChangeEvent<{ value: unknown }>) {
    onSelectFile(e.target.value as string);
  }

  return (
    <StyledFormControl>
      <InputLabel id='file-select-label'>Select File</InputLabel>
      <Select data-testid='connection-azure-file-select' value={selectedFile} labelId='file-select-label' onChange={handleSelectFile}>
        {files.map((file) => (
          <MenuItem value={file} key={file}>
            {file}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default ConnectionAzureFileSelect;
