import { useState } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

import Hive from './Hive';
import { DASHBOARD_SORT_FIELDS, SORT_ORDER } from '../../constants';

const HiveContainer = styled(Box)`
  padding-bottom: 10px;
  border-bottom: ${(props) => (props.$isLast ? 'none' : '1px solid rgba(151, 151, 151, 0.29)')};
`;

const Hives = ({ selectedHiveId, hives, onEditClick, onSort }) => {
  const [sort, setSort] = useState({
    field: DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE,
    order: SORT_ORDER.DESC,
  });

  const handleSortClick = (value) => {
    let field = value;
    let order = SORT_ORDER.ASC;

    if (sort.field === value) {
      if (sort.order === SORT_ORDER.ASC) {
        order = SORT_ORDER.DESC;
      }
    }
    onSort(field, order);
    setSort({ field, order });
  };

  const nonEmptyHives = hives.filter(
    (hive) => (selectedHiveId === null || hive.id === selectedHiveId) && (hive.id !== null || hive.dashboards.length > 0),
  );
  return nonEmptyHives.map((hive, index) => (
    <HiveContainer $isLast={index === nonEmptyHives.length - 1} key={hive.id}>
      <Hive hive={hive} showSorting={index === 0} onEditClick={onEditClick} sort={sort} onSortClick={handleSortClick} />
    </HiveContainer>
  ));
};

export default Hives;
