import { PropsWithChildren } from 'react';
import { ClickAwayListener as MUIClickAwayListener } from '@material-ui/core';

interface IClickAwayListener {
  onClickAway: () => void;
}

function ClickAwayListener({ children, onClickAway }: PropsWithChildren<IClickAwayListener>) {
  return <MUIClickAwayListener onClickAway={onClickAway}>{children}</MUIClickAwayListener>;
}

export default ClickAwayListener;
