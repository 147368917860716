import { UserProfile } from '../../store/reducers/profile';

import { countriesList } from './constants';
import ProfileAccountDetails from './ProfileAccountDetails';

export enum FIELDS {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  JOB = 'JOB',
  COUNTRY = 'COUNTRY',
  OTP = 'OTP',
}

interface IProfileAccountDetailsContainer {
  profile: UserProfile;
  isLoading: boolean;
  onChangeProfile: (profile: UserProfile) => void;
  onSubmit: () => void;
}

function ProfileAccountDetailsContainer({ profile, isLoading, onChangeProfile, onSubmit }: IProfileAccountDetailsContainer) {
  const errors: Record<FIELDS, boolean> = {
    [FIELDS.FIRST_NAME]: profile.firstName.trim() === '',
    [FIELDS.LAST_NAME]: profile.lastName.trim() === '',
    [FIELDS.JOB]: profile.position.trim() === '',
    [FIELDS.COUNTRY]: !profile.country,
    [FIELDS.OTP]: false,
  };

  const hasErrors = Object.values(errors).some((error) => error);
  const disabled = hasErrors || isLoading;

  return (
    <ProfileAccountDetails
      profile={profile}
      isLoading={isLoading}
      disabled={disabled}
      errors={errors}
      countries={countriesList}
      onChangeProfile={onChangeProfile}
      onSubmit={onSubmit}
    />
  );
}

export default ProfileAccountDetailsContainer;
