import { useEffect, useState } from 'react';

import { FETCH_STATUS } from '../../constants';
import * as api from '../../components/tools/api';
import { narrowQueryWithRule } from '../../components/FilterForm/lib';

/**
 * It's used to fetch breakdown summary data.
 *
 * Accepts:
 * - dashboardId: dashboard id
 * - filters: list of applied filters
 * - breakdownFilter: applied breakdown filter
 * - visibleDataSourcesIds: list of data source ids
 *
 * Returns:
 * - responses: array of fetched results
 * - status: fetch status (see FETCH_STATUS);
 */
const useSummaryBreakdownFetch = (dashboardId, filters, breakdownFilter, visibleDataSourcesIds, skip, query) => {
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (skip) {
      return;
    }
    let mounted = true;

    const fetchData = async () => {
      const params = [];
      setStatus(FETCH_STATUS.LOADING);

      for (let filter of filters) {
        params.push({
          dataSourceIDs: visibleDataSourcesIds,
          filter: query ? narrowQueryWithRule(filter.query, query) : filter.query,
        });
      }

      if (params.length > 0) {
        const responsesList = await api.fetchSummariesBreakdown(dashboardId, breakdownFilter, params);
        if (mounted) {
          setResponses(responsesList);
          setStatus(FETCH_STATUS.LOADED);
        }
      } else {
        setResponses([]);
        setStatus(FETCH_STATUS.LOADED);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [dashboardId, filters, breakdownFilter, visibleDataSourcesIds, skip, query]);

  return { responses, status };
};

export default useSummaryBreakdownFetch;
