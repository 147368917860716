export default class JsonModelError {
  constructor(message, data) {
    this.message = message;
    this.data = data;
  }
}

export const setNonEditableProp = (obj, propName, propValue) => {
  Object.defineProperty(obj, propName, {
    configurable: false,
    enumerable: false,
    value: propValue,
    writable: false,
  });
};

export class JsonModel {
  constructor() {
    const id = Math.random().toString().replace('.', '-');
    setNonEditableProp(this, '__internalId', id);
  }

  toJsonString() {
    return JSON.stringify(this);
  }

  toJson() {
    return JSON.parse(this.toJsonString());
  }

  fromJson(_json) {
    setNonEditableProp(this, '__rawJson', _json);
    return this;
  }

  clone() {
    if (this.__rawJson) {
      return new this.constructor().fromJson(this.__rawJson);
    } else {
      throw new JsonModelError('Unable to clone. Object is not created using fromJson() method.', JSON.stringify(this));
    }
  }
}
