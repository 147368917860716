import { AnyAction } from 'redux';
import { createSelectorHook, useDispatch } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import store from './store';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type Action = (dispatch: AppDispatch, getState: () => RootState) => void;

export const useAppDispatch = () => useDispatch<TypedDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = createSelectorHook<RootState>();
