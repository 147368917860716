import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, FormControl } from '@material-ui/core';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--light-gray);
`;

export const SourceIcon = styled.img`
  height: 32px;
  margin-right: 16px;
`;

export const Container = styled.div`
  background: var(--light-gray);
  border-radius: 0 0 16px 16px;
  margin: 0 12px 24px 12px;
  padding: 24px;
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: end;
`;

export const FormGroup = styled.div`
  background: var(--white);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  align-items: end;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Title = styled(Typography)`
  && {
    flex-grow: 1;
    color: #000;
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-left: 8px;
  }
`;

export const AuthSubtitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 40px;
`;

export const AuthContent = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const AuthFormContainer = styled.div`
  flex: 1 1 auto;
  padding-right: 24px;
`;

export const AuthFormContent = styled.div`
  width: 60%;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
`;

export const AuthTextField = styled(TextField)`
  && {
    margin-bottom: 8px;
    min-width: 350px;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin-right: 12px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    min-width: 200px;
    width: 25%;
    :not(:first-child) {
      margin-left: 80px;
    }
  }
`;
