import { LANGUAGES_UPDATE_LANGUAGES, updateLanguages } from '../actions/languages';

export interface ILanguagesReducer {
  languages: Record<string, string>;
  initialized: boolean;
  error?: boolean;
}

const initialState: ILanguagesReducer = {
  languages: {},
  initialized: false,
};

type LanguagesActionTypes = ReturnType<typeof updateLanguages>;

export const languagesReducer = (state = initialState, action: LanguagesActionTypes) => {
  switch (action.type) {
    case LANGUAGES_UPDATE_LANGUAGES:
      return handleUpdateLanguages(state, action);
    default:
      return state;
  }
};

const handleUpdateLanguages = (state: ILanguagesReducer, action: ReturnType<typeof updateLanguages>): ILanguagesReducer => {
  const { languages, error } = action.payload;
  return {
    ...state,
    languages: languages || {},
    error,
    initialized: !error,
  };
};
