import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import styled from 'styled-components';

import { OverviewItemModel } from '../../../models/Overview';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { overviewSetBreakdownFilter } from '../../../store/actions/overview';
import { OutlinedButtonNarrow, SavedFilterButton } from '../../UI/Button';

const BREAKDOWN_VALUES_LIMIT = 10;

const BreakdownButton = styled(SavedFilterButton)`
  &&& {
    max-width: 300px;
    .MuiButton-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
`;

const SearchTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-left: 10px;
    width: 30%;
    vertical-align: baseline;
  }
  .MuiOutlinedInput-inputMarginDense {
    font-size: 13px;
    padding: 6px;
  }
`;

const SidebarItemBreakdownOverlay = () => {
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const { translationUsed, weightedMetricsUsed } = useSelector((state) => state.app);
  const { dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dispatch = useDispatch();
  const [selectedDataSourceID, setSelectedDataSourceID] = useState(breakdownFilter ? breakdownFilter.dataSourceID : null);
  const [searchText, setSearchText] = useState('');

  const titleField = translationUsed ? 'title' : 'originalTitle';
  const applyDisabled = !selectedDataSourceID || (breakdownFilter && selectedDataSourceID === breakdownFilter.dataSourceID);

  const menuDataSources = dashboardDataSources.filter(
    (datasource) =>
      datasource.type !== OverviewItemModel.TYPE_OPEN_ENDED &&
      datasource.visible &&
      !datasource.isEmpty &&
      datasource[titleField].toLowerCase().includes(searchText.toLowerCase()),
  );

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleClick = (dataSource) => {
    setSelectedDataSourceID((current) => (current === dataSource.dataSourceID ? null : dataSource.dataSourceID));
  };

  const handleApply = () => {
    eventsTracker.track(EVENTS.BREAKDOWN_DASHBOARD, {
      'Weighted Metrics': weightedMetricsUsed,
      'Breakdown Applied': true,
    });
    const dataSource = dataSources.find((dataSource) => dataSource.dataSourceID === selectedDataSourceID);
    const breakdownValues = [...dataSource.items]
      .sort((a, b) => b.base.value - a.base.value)
      .slice(0, BREAKDOWN_VALUES_LIMIT)
      .map((item) => item.response);
    const breakdownFilter = {
      dataSourceID: selectedDataSourceID,
      values: breakdownValues,
    };
    dispatch(overviewSetBreakdownFilter(breakdownFilter));
  };

  const handleReset = () => {
    eventsTracker.track(EVENTS.BREAKDOWN_DASHBOARD, {
      'Weighted Metrics': weightedMetricsUsed,
      'Breakdown Applied': false,
    });
    setSelectedDataSourceID(null);
    dispatch(overviewSetBreakdownFilter(null));
  };

  return (
    <Grid container direction='column' style={{ flex: 1, marginTop: '-3px' }}>
      <Grid item>
        <strong>Breakdown By</strong>
        <SearchTextField value={searchText} onChange={handleSearch} variant='outlined' size='small' placeholder='Search…' />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Grid container direction='row' wrap='nowrap' style={{ marginTop: '10px' }}>
          <Grid item xs>
            <Grid container direction='row'>
              {menuDataSources.map((ds) => (
                <div key={ds.dataSourceID} style={{ margin: '4px', display: 'inline-block' }}>
                  <BreakdownButton
                    variant='contained'
                    size='small'
                    disableElevation
                    onClick={() => handleClick(ds)}
                    selected={ds.dataSourceID === selectedDataSourceID}
                    data-testid='data-source'
                    data-title={ds[titleField]}
                    data-tooltip-id='base-tooltip'
                    data-tooltip-content={ds[titleField]}
                  >
                    {ds[titleField]}
                  </BreakdownButton>
                </div>
              ))}
            </Grid>
          </Grid>
          <Grid item xs='auto' style={{ paddingLeft: '15px', width: '190px', fontSize: '14px' }}>
            Breakdown the dashboard by a structured multiple choice question in the program.
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: '10px', textAlign: 'right' }}>
        <OutlinedButtonNarrow data-testid='reset' variant='outlined' onClick={handleReset} style={{ marginRight: '10px' }}>
          Reset
        </OutlinedButtonNarrow>
        <OutlinedButtonNarrow data-testid='apply' variant='outlined' onClick={handleApply} disabled={applyDisabled}>
          Apply
        </OutlinedButtonNarrow>
      </Grid>
    </Grid>
  );
};

export default SidebarItemBreakdownOverlay;
