import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FETCH_STATUS, FILTER_ID_ALL } from '../constants';
import useFilters from '../hooks/useFilters';
import * as api from '../components/tools/api';

export const SummaryDataContext = React.createContext();

export const getTotalsByDataSourcesAndFiltersIds = (results, filters) => {
  const res = {};
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    const summary = results[i];
    for (let dataSource of summary) {
      if (!(dataSource.dataSourceID in res)) {
        res[dataSource.dataSourceID] = {};
      }
      res[dataSource.dataSourceID][filter.id] = {
        base: dataSource.total,
        weighted: dataSource.weightedTotal,
      };
    }
  }
  return res;
};

const SummaryDataProvider = ({ children }) => {
  const [filters] = useFilters();
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dashboardId = useSelector((state) => state.dashboard.dashboard.id);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const visibleDataSourceIds = useMemo(
    () => dashboardDataSources.filter((dataSource) => dataSource.visible).map((dataSource) => dataSource.dataSourceID),
    [dashboardDataSources],
  );

  const [value, setValue] = useState({
    loading: null,
    status: FETCH_STATUS.IDLE,
    data: [],
    totalsByDataSourcesAndFiltersIds: {},
  });

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const params = [];
      const data = [];

      setValue({
        loading: true,
        status: FETCH_STATUS.LOADING,
        data: [],
        totalsByDataSourcesAndFiltersIds: {},
      });

      const list = visibleDataSourceIds.reduce((acc, dsId) => {
        const dataSource = dataSources.find((ds) => ds.dataSourceID === dsId);
        if (!dataSource) {
          return acc;
        }
        return [...acc, { ...dataSource, dataSourceID: dsId }];
      }, []);
      data.push(list);

      for (let filter of filters) {
        if (filter.id === FILTER_ID_ALL) {
          continue;
        }
        params.push({
          visibleDataSourceIds,
          filter: filter.query,
        });
      }

      if (params.length) {
        const responses = await api.fetchSummaries(dashboardId, params);
        if (mounted) {
          const results = [...data, ...responses];
          setValue({
            loading: false,
            status: FETCH_STATUS.LOADED,
            data: results,
            totalsByDataSourcesAndFiltersIds: getTotalsByDataSourcesAndFiltersIds(results, filters),
          });
        }
      } else {
        const results = [...data];
        setValue({
          loading: false,
          status: FETCH_STATUS.LOADED,
          data: results,
          totalsByDataSourcesAndFiltersIds: getTotalsByDataSourcesAndFiltersIds(results, filters),
        });
      }
    };
    fetchData();

    return () => {
      mounted = false;
    };
  }, [filters, dataSources, visibleDataSourceIds, dashboardId]);

  return <SummaryDataContext.Provider value={value}>{children}</SummaryDataContext.Provider>;
};

export default SummaryDataProvider;
