import { AZURE_RESET_FILES, AZURE_UPDATE_FILES, resetFiles, updateFiles } from '../actions/azure';

export interface IAzureReducer {
  files: string[];
  initialized: boolean;
  error?: boolean;
}

const initialState: IAzureReducer = {
  files: [],
  initialized: false,
};

type AzureActionTypes = ReturnType<typeof updateFiles>;

export const azureReducer = (state = initialState, action: AzureActionTypes) => {
  switch (action.type) {
    case AZURE_UPDATE_FILES:
      return handleUpdateFiles(state, action);
    case AZURE_RESET_FILES:
      return handleResetFiles(state, action);
    default:
      return state;
  }
};

const handleUpdateFiles = (state: IAzureReducer, action: ReturnType<typeof updateFiles>): IAzureReducer => {
  const { files, error } = action.payload;
  return {
    ...state,
    files: files || [],
    error,
    initialized: !error,
  };
};

const handleResetFiles = (state: IAzureReducer, action: ReturnType<typeof resetFiles>): IAzureReducer => {
  return {
    ...state,
    files: [],
    error: false,
    initialized: false,
  };
};
