import { SENTIMENT, SENTIMENT_TITLE } from '../../../../../../models/Response';

interface IResponseSentiment {
  sentiment: string;
  score: number;
  count?: number;
}
function ResponseSentiment({ sentiment, score, count }: IResponseSentiment) {
  let backgroundColor;
  let title;
  switch (sentiment) {
    case SENTIMENT.NEGATIVE:
      backgroundColor = 'var(--sentiment-orange)';
      title = SENTIMENT_TITLE[SENTIMENT.NEGATIVE];
      break;
    case SENTIMENT.POSITIVE:
      backgroundColor = 'var(--sentiment-green)';
      title = SENTIMENT_TITLE[SENTIMENT.POSITIVE];
      break;
    case SENTIMENT.NEUTRAL:
      backgroundColor = 'var(--sentiment-yellow)';
      title = SENTIMENT_TITLE[SENTIMENT.NEUTRAL];
      break;
    default:
      backgroundColor = 'var(--sentiment-gray)';
      title = SENTIMENT_TITLE[SENTIMENT.UNKNOWN];
  }
  const pct = Math.abs(score) * 100;
  const width = pct + '%';
  const sentimentPct = Math.round(pct);
  const sentimentCount = count ? `(${count})` : '';
  title = `${title}: ${sentimentPct}% ${sentimentCount}`;

  return (
    <div
      data-tooltip-id='base-tooltip'
      data-tooltip-content={title}
      style={{
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
      }}
    >
      <div style={{ backgroundColor, width, height: '8px', borderRadius: '8px' }} />
    </div>
  );
}

export default ResponseSentiment;
