import Dialog from '@material-ui/core/Dialog';
import { Box, Grid, Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ControlPanelDialogChart from './ControlPanelDialogChart';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import { Link, useParams } from 'react-router-dom';

import palette from '../../../components/tools/palette';
import { TestsContext } from '../../../contexts/TestsContext';
import { SURVEY_STATUS_CONFIG, SURVEY_STATUSES_ORDERED } from '../../../constants';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { AppButton } from '../../../components/UI';

const NO_SCROLL_HEIGHT = '880px';

const CustomDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    @media (max-height: ${NO_SCROLL_HEIGHT}) {
      padding-bottom: 0;
    }
  }
`;

const CustomConnector = styled(StepConnector)`
  &.MuiStepConnector-alternativeLabel {
    left: calc(-50% + 6px);
    right: calc(50% + 6px);
    .MuiStepConnector-line {
      border-color: ${palette.dividerColor};
    }
    &.MuiStepConnector-completed .MuiStepConnector-line,
    &.MuiStepConnector-active .MuiStepConnector-line {
      border-color: ${palette.primaryColorLight};
    }
  }
`;

const CustomStepper = styled(Stepper)`
  &.MuiStepper-root {
    @media (max-height: ${NO_SCROLL_HEIGHT}) {
      padding: 15px 0 10px;
    }
  }
`;

const CustomStepIcon = styled('div')`
  background-color: ${(props) => (props.completed || props.active ? palette.primaryColorLight : palette.dividerColor)};
  border-radius: 50%;
  height: 12px;
  margin-top: 50%;
  width: 12px;
`;

const AiIndicator = styled('div')`
  background-color: #229e46;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin-right: 2px;
  width: 12px;
`;

const RubikTypography = styled(Typography)`
  font-family: Rubik, sans-serif;
`;

const SpaceAround = styled('div')`
  margin: 10px 0;
  @media (max-height: ${NO_SCROLL_HEIGHT}) {
    margin: 2px 0 8px;
  }
`;

const getColoredPercentage = (percentage) => {
  const value = Math.round(percentage * 100);

  let color = '#229e46';
  if (value < 80) {
    color = '#fcca3d';
    if (value < 50) {
      color = 'red';
    }
  }

  return <span style={{ color }}>{value}%</span>;
};

const ControlPanelDialog = ({ dashboard, onClose }) => {
  const { isUnsafe } = useContext(TestsContext);
  const [selectedSurvey, setSelectedSurvey] = useState(dashboard.surveys && dashboard.surveys.length > 0 ? dashboard.surveys[0] : null);
  const { company: selectedCompany } = useParams();

  const handleSurveyChange = (_, value) => {
    if (!!value) {
      eventsTracker.track(EVENTS.SELECT_DATA_SOURCE, {
        'Data Source Name': value.title,
      });
    }
    setSelectedSurvey(value);
  };

  let status = 'n/a';
  let numberOfModels = 'n/a';
  let coverage = 'n/a';

  if (selectedSurvey !== null) {
    if (selectedSurvey.coverage < 0.2) {
      status = (
        <>
          <CircularProgress size={12} style={{ color: '#fcca3d', marginRight: '2px' }} />
          Waiting on Data
        </>
      );
      coverage = <>Initializing&hellip;</>;
    } else {
      numberOfModels = selectedSurvey.models_num;
      coverage = getColoredPercentage(selectedSurvey.coverage);
      status =
        selectedSurvey.coverage < 0.8 ? (
          <>
            <CircularProgress size={12} style={{ color: '#87c895', marginRight: '2px' }} />
            AI Learning
          </>
        ) : (
          <>
            <AiIndicator />
            AI Active
          </>
        );
    }
  }

  const disabled = dashboard.underMaintenance && !isUnsafe;

  return (
    <Dialog fullWidth maxWidth='md' open={true} onClose={onClose} disableEnforceFocus={true}>
      <CustomDialogTitle disableTypography>
        <RubikTypography variant='h4' align='center' noWrap data-tooltip-id='base-tooltip' data-tooltip-content={dashboard.title}>
          {dashboard.title}
        </RubikTypography>
      </CustomDialogTitle>
      <DialogContent style={{ padding: '0 40px' }}>
        <SpaceAround>
          <RubikTypography variant='h5'>Total Responses</RubikTypography>
        </SpaceAround>
        <Box display='flex' justifyContent='center'>
          <ControlPanelDialogChart dashboardId={dashboard.id} total={dashboard.total} />
        </Box>

        <SpaceAround>
          <RubikTypography variant='h5'>Program(s)</RubikTypography>
        </SpaceAround>

        <Autocomplete
          style={{ width: 300 }}
          size='small'
          options={dashboard.surveys}
          autoHighlight
          getOptionLabel={(option) => option.title}
          renderOption={(option) => (
            <>
              {option.title} ({SURVEY_STATUS_CONFIG[option.status].title})
            </>
          )}
          onChange={handleSurveyChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Choose a program'
              variant='outlined'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          value={selectedSurvey}
        />

        <CustomStepper
          activeStep={selectedSurvey ? SURVEY_STATUSES_ORDERED.findIndex((status) => status === selectedSurvey.status) : -1}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {SURVEY_STATUSES_ORDERED.map((status) => (
            <Step key={status}>
              <StepLabel StepIconComponent={CustomStepIcon}>{SURVEY_STATUS_CONFIG[status].title}</StepLabel>
            </Step>
          ))}
        </CustomStepper>

        <SpaceAround>
          <RubikTypography variant='h5'>AI Status</RubikTypography>
        </SpaceAround>
        <SpaceAround>
          <Grid container direction='column' style={{ textAlign: 'center' }}>
            <Grid item>
              <Grid container direction='row'>
                <Grid item xs={4}>
                  Status
                </Grid>
                <Grid item xs={4}>
                  # of models
                </Grid>
                <Grid item xs={4}>
                  Coverage
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row'>
                <Grid item xs={4}>
                  {status}
                </Grid>
                <Grid item xs={4}>
                  {numberOfModels}
                </Grid>
                <Grid item xs={4}>
                  {coverage}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpaceAround>
      </DialogContent>
      <DialogActions style={{ margin: '0 20px 20px' }}>
        <AppButton component={Link} to={`/company/${selectedCompany}`} color='primary' style={{ textDecoration: 'underline' }}>
          Return to Hives
        </AppButton>
        <AppButton
          onClick={onClose}
          variant='contained'
          disabled={disabled}
          style={disabled ? {} : { backgroundColor: palette.primaryColorLight, color: '#fff' }}
        >
          Explore Data
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default ControlPanelDialog;
