import { fetchQualtricsSurveys } from '../../components/tools/api';
import { updateSurveys } from '../actions/qualtrics';
import { Action } from '../hooks';

interface IInitializeQualtricsSurveys {
  authID: string;
}

export const initializeQualtricsSurveys =
  ({ authID }: IInitializeQualtricsSurveys): Action =>
  async (dispatch) => {
    const res = await fetchQualtricsSurveys(authID);
    if (res) {
      dispatch(updateSurveys({ surveys: res }));
    } else {
      dispatch(updateSurveys({ error: true }));
    }
  };
