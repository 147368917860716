import Color from 'color';
import * as scheme from 'd3-scale-chromatic';
import distinctColors from 'distinct-colors';

export const availableColorSchemes = [
  //
  'customBeehive',
  'customDistinct',
  //
  'interpolateBrBG',
  'interpolatePRGn',
  'interpolatePiYG',
  'interpolatePuOr',
  'interpolateRdBu',
  'interpolateRdGy',
  'interpolateRdYlBu',
  'interpolateRdYlGn',
  'interpolateSpectral',
  //
  'interpolateBlues',
  'interpolateGreens',
  'interpolateGreys',
  'interpolateOranges',
  'interpolatePurples',
  'interpolateReds',
  //
  'interpolateTurbo',
  'interpolateViridis',
  'interpolateInferno',
  'interpolateMagma',
  'interpolatePlasma',
  'interpolateCividis',
  'interpolateWarm',
  'interpolateCool',
  'interpolateCubehelixDefault',
  'interpolateBuGn',
  'interpolateBuPu',
  'interpolateGnBu',
  'interpolateOrRd',
  'interpolatePuBuGn',
  'interpolatePuBu',
  'interpolatePuRd',
  'interpolateRdPu',
  'interpolateYlGnBu',
  'interpolateYlGn',
  'interpolateYlOrBr',
  'interpolateYlOrRd',
  //
  'interpolateRainbow',
  'interpolateSinebow',
];
export const DEFAULT_COLOR_SCHEME_NAME = 'customDistinct';

export const getSchemeColors = (name: string, count: number) => {
  const schemeColors = [];

  if (name in scheme) {
    const colorGenerator = (scheme as { [key: string]: any })[name];
    // reverse color order
    // colorGenerator = (t) => scheme[name](1 - t)

    if (count === 1) {
      return [colorGenerator(1)];
    }

    const step = 1 / (count - 1);
    for (let i = 0; i < count; i++) {
      const color = colorGenerator(step * i);
      schemeColors.push(color);
    }
  } else {
    switch (name) {
      case 'customDistinct':
        schemeColors.push(
          ...distinctColors({
            count: count,
            hueMin: 0,
            hueMax: 360,
            chromaMin: 20,
            chromaMax: 100,
            lightMin: 15,
            lightMax: 80,
          }).map((c) => c.hex()),
        );
        break;
      default:
        const lightnessThreshold = 100 - 90 / count;
        const lightnessDelta = lightnessThreshold / count / 120;
        for (let i = 0; i < count; i++) {
          const color = new Color('#8645F1').lightness(lightnessThreshold).darken(i * lightnessDelta);
          schemeColors.push(color.hex());
        }
        schemeColors.reverse();
    }
  }

  return schemeColors;
};
