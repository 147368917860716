import React from 'react';

import styled from 'styled-components';
import { CloseButtonContainer, CloseIcon, Container, DropdownElement, FullWidth, StyledSelect, TextField } from './styles';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';

import FileUploadNewDashboardContainer from './FileUploadNewDashboardContainer';
import UploadDropdownSelectContainer from './UploadDropdownSelectContainer';
import UploadDropdownElements from './UploadDropdownElements';

const FieldHeader = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
`;

const SurveyDropdownElement = styled(DropdownElement)`
  cursor: pointer;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 76px;
  right: 32px;
`;

const BorderContainer = styled.div`
  border: 1px solid var(--medium-gray);
  border-radius: var(--square-top-corners);
`;

const SelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 8px 16px;
`;

const StyledContainer = styled(Container)`
  padding: 0;
`;

const DashboardLength = styled.div`
  font-weight: 700;
`;

const DropdownPlaceholderText = styled.div`
  color: var(--grey);
`;

interface IFileUploadDropdown {
  selectedDashboards: string[];
  fileSource: string;
  dashboards: string[];
  filteredDashboards: string[];
  newSelectedDashboards: string[];
  fileSourceOptions: string[];
  isOpen: boolean;
  onOpen: () => void;
  handleClose: () => void;
  onSelectedDashboardsSet: (dashboards: string[]) => void;
  onNewSelectedDashboardsSet: (newDashboards: string[]) => void;
  handleFileSourceChange: (source: string) => void;
  handleDashboardSearchChange: (dashboard: string) => void;
  handleCheckboxChange: (dashboard: string) => void;
}

function FileUploadDropdown({
  selectedDashboards,
  fileSource,
  fileSourceOptions,
  dashboards,
  filteredDashboards,
  newSelectedDashboards,
  isOpen,
  onOpen,
  handleClose,
  onSelectedDashboardsSet,
  onNewSelectedDashboardsSet,
  handleFileSourceChange,
  handleDashboardSearchChange,
  handleCheckboxChange,
}: IFileUploadDropdown) {
  return (
    <FullWidth>
      <FieldHeader>Select Dashboard(s)</FieldHeader>
      <UploadDropdownSelectContainer
        selectedDashboards={selectedDashboards}
        open={isOpen}
        bubbleColor='beehive-purple'
        anchorOriginVertical='top'
        transformOriginVertical='bottom'
        onCheckboxChange={handleCheckboxChange}
        onOpen={onOpen}
        onClose={handleClose}
        UploadDropdownContent={renderDashboardDropdownContent}
      />
      <FieldHeader>Select File Source</FieldHeader>
      {renderSurveySelect()}
    </FullWidth>
  );

  function renderDashboardDropdownContent() {
    const handleDashboardSearchUpdating = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleDashboardSearchChange(e.currentTarget.value);
    };
    return (
      <StyledContainer>
        <DashboardLength> {dashboards.length + newSelectedDashboards.length} Dashboards</DashboardLength>
        <CloseButtonContainer>
          <CloseIcon data-testid='file-upload-dropdown-close' onClick={handleClose} />
        </CloseButtonContainer>
        <FullWidth>
          <TextField
            data-testid='file-upload-dropdown-search'
            name='dashboardSearch'
            placeholder='Search dashboards'
            onChange={handleDashboardSearchUpdating}
          />
          <StyledSearchIcon />
          <BorderContainer>
            <FileUploadNewDashboardContainer
              selectedDashboards={selectedDashboards}
              dashboards={dashboards}
              newSelectedDashboards={newSelectedDashboards}
              handleSelectedDashboardsSet={onSelectedDashboardsSet}
              handleNewSelectedDashboardsSet={onNewSelectedDashboardsSet}
            />
            {renderDashboardDropdownContainer()}
          </BorderContainer>
        </FullWidth>
      </StyledContainer>
    );
  }

  function renderDashboardDropdownContainer() {
    return (
      <UploadDropdownElements
        elements={filteredDashboards}
        selectedElements={selectedDashboards}
        paddingHorizontal='16px'
        handleCheckboxChange={handleCheckboxChange}
      />
    );
  }

  function renderSurveySelect() {
    return (
      <StyledSelect
        data-testid='file-upload-dropdown-survey'
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        name='fileSource'
        displayEmpty
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
        renderValue={renderSurveyDropdownOutput}
      >
        {fileSourceOptions.map((source) => (
          <SurveyDropdownElement
            key={source}
            paddingHorizontal='16px'
            onClick={handleFileSourceChange.bind(null, source)}
            data-testid={`file-source-option-${source}`}
          >
            {source}
          </SurveyDropdownElement>
        ))}
      </StyledSelect>
    );
  }

  function renderSurveyDropdownOutput() {
    return (
      <SelectContainer data-testid='file-source-option-select'>
        {fileSource === '' ? <DropdownPlaceholderText>Specify where this file originated</DropdownPlaceholderText> : fileSource}
      </SelectContainer>
    );
  }
}

export default FileUploadDropdown;
