import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateSelectedIntegration } from '../../../../store/actions/connectors';
import { Integration, SurveySourceEnum } from '../../../../types/connections';

import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';

import IntegrationsTab from './IntegrationsTab';

interface IIntegrationsTabContainer {
  handleAddConnection: (source: SurveySourceEnum) => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string) => void;
  handleEditAuthentication: (integration: Integration) => void;
}

function IntegrationsTabContainer({ handleAddConnection, handleEnrich, handleEditAuthentication }: IIntegrationsTabContainer) {
  const {
    selectedIntegration,
    connectorsLoading: isConnectorsLoading,
    surveysLoading: isSurveysLoading,
  } = useAppSelector((state) => state.connectors);
  const dispatch = useAppDispatch();

  function handleSelect(integration: Integration) {
    if (integration.source === selectedIntegration?.source) {
      dispatch(updateSelectedIntegration({ source: null }));
      return;
    }
    eventsTracker.track(EVENTS.CONNECTIONS_APP_AND_INTEGRATION_CARD_SELECT, {
      'App/Integration name': integration.source,
      'Authentication Status': !!integration.authentication,
      'Authenticated by': integration.authProviderID,
    });
    dispatch(updateSelectedIntegration({ source: integration.source }));
  }

  return (
    <IntegrationsTab
      isSurveysLoading={isSurveysLoading}
      isConnectorsLoading={isConnectorsLoading}
      handleSelect={handleSelect}
      handleEditAuthentication={handleEditAuthentication}
      handleAddConnection={handleAddConnection}
      handleEnrich={handleEnrich}
    />
  );
}

export default IntegrationsTabContainer;
