import { useState } from 'react';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { MAX_STEPS_CONTAINER_WIDTH } from './styles';

const TITLE_WIDTH = 150;
const PADDING = 32;
const ICON_SIZE = 48;

const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  & .MuiIconButton-edgeEnd {
    margin-right: 0;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
`;

const SummaryTitle = styled.div`
  min-width: ${TITLE_WIDTH}px;
`;

const SummaryDescription = styled.div<{ containerWidth: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) =>
    `max-width: max(calc(${MAX_STEPS_CONTAINER_WIDTH} - ${TITLE_WIDTH + ICON_SIZE + 3 * PADDING}px), ${props.containerWidth - TITLE_WIDTH - ICON_SIZE - 3 * PADDING}px);`}
`;

const DetailsContainer = styled.div`
  width: 100%;
`;

interface IInstructionStep {
  title: string;
  containerWidth: number;
  summary: React.ReactNode;
  additionalDetails?: React.ReactNode;
  images?: React.ReactNode;
}

function InstructionStep({ title, summary, containerWidth, additionalDetails, images }: IInstructionStep) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      data-testid='instruction-step'
      expanded={expanded}
      onChange={(_, newExpanded) => {
        setExpanded(newExpanded);
      }}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <SummaryContainer>
          <SummaryTitle>{title}</SummaryTitle>
          {expanded === false && <SummaryDescription containerWidth={containerWidth}>{summary}</SummaryDescription>}
        </SummaryContainer>
      </StyledAccordionSummary>
      <AccordionDetails>
        <DetailsContainer>
          <p>
            {summary}
            {additionalDetails && <div>{additionalDetails}</div>}
          </p>
          {images && <div>{images}</div>}
        </DetailsContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default InstructionStep;
