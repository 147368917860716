import React from 'react';

import { DashboardSentimentSummariesSentiment } from '../../../../store/reducers/dashboard/types';
import { numberWithCommas } from '../../../tools';
import { Participant } from '../../../../store/reducers/interviewWidget/types';
import SentimentStatus from '../../components/StatusPanel/SentimentStatus';
import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import { Counter, WidgetStatusPanelContainer } from '../../components/StatusPanel/style';
import WidgetElement from '../../components/StatusPanel/WidgetElement';
import { formatMilliseconds } from '../../../../utils/helpers';

interface IStatusPanel {
  duration: number;
  parcipantsCount: number;
  isParticipantsLoading: boolean;
  participants: Participant[];
  topicsCount: number;
  total: number;
  hasSentiment: boolean;
  sentimentSummary: DashboardSentimentSummariesSentiment;
}

function StatusPanel({
  duration,
  isParticipantsLoading,
  parcipantsCount,
  topicsCount,
  total,
  hasSentiment,
  sentimentSummary,
}: IStatusPanel) {
  const isShowSentiment = hasSentiment && sentimentSummary;

  return (
    <WidgetStatusPanelContainer data-testid='status-panel'>
      <WidgetElement title='Duration' renderChild={renderDuration} />
      <WidgetElement title='Participants' renderChild={renderParticipants} />
      <WidgetElement title='Topics' renderChild={renderTopics} />
      {isShowSentiment && <WidgetElement title='Sentiment' dataTestId='status-panel-sentiment-statuses' renderChild={renderSentiment} />}
    </WidgetStatusPanelContainer>
  );

  function renderDuration() {
    return <Counter data-testid='status-panel-duration'>{formatMilliseconds(duration, true)}</Counter>;
  }

  function renderParticipants() {
    if (isParticipantsLoading) {
      return (
        <Counter data-testid='status-panel-participants-loading'>
          <LoadingLabel loading={true} showText={false}></LoadingLabel>
        </Counter>
      );
    }
    return <Counter data-testid='status-panel-participants'>{numberWithCommas(parcipantsCount)}</Counter>;
  }

  function renderTopics() {
    return <Counter data-testid='status-panel-topics'>{numberWithCommas(topicsCount)}</Counter>;
  }

  function renderSentiment() {
    return <SentimentStatus sentimentSummary={sentimentSummary} total={total} />;
  }
}

export default StatusPanel;
