import { CircularProgress, Dialog, IconButton } from '@material-ui/core';
import styled from 'styled-components';

import { FilledButton, OutlinedButton } from '../../UI/Button';
import { StyledCloseIcon } from '../style';

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Container = styled.div`
  padding: 32px 30px 24px 30px;
  width: 400px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 24px;
  color: var(--white);
`;

const DeleteButton = styled(FilledButton)`
  && {
    margin-left: 12px;
  }
`;

interface IDeleteAuthModal {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onDelete: () => void;
}

function DeleteAuthModal({ isOpen, isSubmitting, onClose, onDelete }: IDeleteAuthModal) {
  return (
    <Dialog open={isOpen} maxWidth={false}>
      <CloseButtonContainer>
        <IconButton size='small' onClick={onClose}>
          <StyledCloseIcon />
        </IconButton>
      </CloseButtonContainer>
      <Container>
        <Title>Are you sure you want to delete this authentication configuration?</Title>
        <div>This action cannot be undone.</div>
        <ActionsContainer>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <DeleteButton data-testid='delete-auth-modal-delete' onClick={onDelete}>
            {isSubmitting && <StyledCircularProgress size='18px' />}
            Delete
          </DeleteButton>
        </ActionsContainer>
      </Container>
    </Dialog>
  );
}

export default DeleteAuthModal;
