import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CachedIcon from '@material-ui/icons/Cached';
import { FETCH_STATUS, PERIOD } from '../../constants';
import { numberWithCommas } from '../tools';
import Badge from '../tools/badge';
import { settingsSetTotalBadgeExpanded } from '../../store/actions/settings';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import capitalize from 'lodash/capitalize';
import { useSurveyTotalTrends, SurveyTotalTrendsChartWrapper } from '../UI/SurveyTotalTrends';

const TotalBadge = ({ total }) => {
  const dispatch = useDispatch();
  const {
    dashboard: { id: dashboardId },
  } = useSelector((state) => state.dashboard);
  const totalBadgeExpanded = useSelector((state) =>
    dashboardId in state.settings.totalBadgeExpanded ? state.settings.totalBadgeExpanded[dashboardId] : null,
  );
  const [period, setPeriod] = useState(PERIOD.QUARTER);
  const [loading, surveysTrends, fetchedPeriod] = useSurveyTotalTrends(dashboardId, period);

  useEffect(() => {
    if (loading === FETCH_STATUS.LOADED) {
      setPeriod(fetchedPeriod);
      if (totalBadgeExpanded === null) {
        dispatch(settingsSetTotalBadgeExpanded(dashboardId, true));
      }
    }
  }, [loading, surveysTrends, dashboardId, dispatch, fetchedPeriod, totalBadgeExpanded]);

  function handleToggleCompact() {
    if (totalBadgeExpanded) {
      eventsTracker.track(EVENTS.DASHBOARD_TOTAL_RESPONSES_COLLAPSE);
    } else {
      eventsTracker.track(EVENTS.DASHBOARD_TOTAL_RESPONSES_EXPAND);
    }

    dispatch(settingsSetTotalBadgeExpanded(dashboardId, !totalBadgeExpanded));
  }

  function handlePeriodClick(value) {
    eventsTracker.track(EVENTS.DASHBOARD_TOTAL_RESPONSES_VIEW_CHANGE, {
      'Time Range': capitalize(value),
    });
    setPeriod(value);
  }

  if (!totalBadgeExpanded) {
    return <Badge text='Total responses' IconComponent={CachedIcon} value={numberWithCommas(total)} onExpandClick={handleToggleCompact} />;
  }

  return (
    <SurveyTotalTrendsChartWrapper
      loading={loading}
      onSelectPeriod={handlePeriodClick}
      onSizeToggle={handleToggleCompact}
      period={fetchedPeriod}
      surveysTrends={surveysTrends}
      total={total}
    />
  );
};

export default TotalBadge;
