import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppBar, Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

import palette from '../tools/palette';
import RenameField from './RenameField';
import LogoSvg from '../UI/LogoSvg';
import { sharedStyles } from './styles';
import { AccountLink, ExitLink, InviteLink, PseudoLink } from './Link';

const DashboardTitle = styled.span`
  ${sharedStyles};
  font-size: 24px;
`;

const ToolbarContainer = styled(Toolbar)`
  background-color: ${palette.toolbarBackgroundColor};
  padding: 0 16px;
`;

interface IDashboardToolbar {
  exitText: boolean;
  showAccountLink: boolean;
  showInviteLink: boolean;
  disableInviteLink: boolean;
  title?: string;
  selectedCompany?: string;
  dashboardId?: string;
  onBackClick?: () => void;
  onDashboardRename: (name: string) => void;
}
function DashboardToolbar({
  dashboardId,
  title,
  selectedCompany,
  exitText,
  showAccountLink,
  showInviteLink,
  disableInviteLink,
  onBackClick,
  onDashboardRename,
}: IDashboardToolbar) {
  return (
    <>
      <AppBar position='fixed' elevation={0}>
        <ToolbarContainer variant='dense' disableGutters>
          <Box display='flex' alignItems='center' width='100%'>
            <Box display='flex' flexGrow={1} alignItems='center'>
              <Box>
                <Link href='#' to={selectedCompany && dashboardId ? `/company/${selectedCompany}` : '/'}>
                  <LogoSvg />
                </Link>
              </Box>
              <Box style={{ paddingLeft: '16px' }}>
                <DashboardTitle>{renderTitle()}</DashboardTitle>
              </Box>
            </Box>
            <Box display='flex' flexWrap='nowrap' alignItems='center'>
              {showAccountLink && <AccountLink />}
              {showInviteLink && <InviteLink company={selectedCompany} disabled={disableInviteLink} />}
              {onBackClick && (
                <PseudoLink onClick={onBackClick} style={{ marginRight: '20px' }}>
                  &lt; Back
                </PseudoLink>
              )}
              {exitText && <ExitLink />}
            </Box>
          </Box>
        </ToolbarContainer>
      </AppBar>
      <Toolbar variant='dense' />
    </>
  );

  function renderTitle() {
    if (title === undefined) {
      return null;
    }

    return <RenameField label={title ? title : 'No title'} onSaveData={onDashboardRename} />;
  }
}

export default DashboardToolbar;
