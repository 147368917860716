import { IEventsTrackerService } from './EventsTrackerService';

class HubSpotTracker implements IEventsTrackerService {
  isTrackingEnabled = true;
  _hsq: any;

  constructor() {
    this._hsq = (window as any)._hsq || [];
  }

  identify(uniqId: string, extraInfo?: object) {
    if (!this.isTrackingEnabled) {
      return;
    }

    this._hsq.push(['identify', { id: uniqId, ...extraInfo }]);
  }

  track(event: string, properties = {}) {
    if (!this.isTrackingEnabled) {
      return;
    }

    this._hsq.push(['trackCustomBehavioralEvent', { name: event, properties }]);
  }

  trackImmediately(event: string, properties = {}) {
    this._hsq.push(['trackCustomBehavioralEvent', { name: event, properties }]);
  }

  disableTracking() {
    this.isTrackingEnabled = false;
  }

  enableTracking() {
    this.isTrackingEnabled = true;
  }

  trackPageView() {
    if (!this.isTrackingEnabled) {
      return;
    }

    this._hsq.push(['trackPageView']);
  }
}

export default HubSpotTracker;
