import styled from 'styled-components';
import { Button, Checkbox, Paper } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import palette from '../../../components/tools/palette';

export const SelectorButton = styled(Button)<{ selected?: boolean }>`
  &.MuiButton-root {
    padding: 2px 16px;
    border: 1px solid #5a50a5;
    color: #5a50a5;
    border-radius: var(--border-radius);
    margin-left: 8px;
    ${(props) =>
      props.selected &&
      `
      color: #7D6DF6;
      background-color: #DEDAFC;
    `}
  }
`;

export const SelectorContainer = styled(Paper)`
  padding: 16px;
  width: 350px;
  font-size: 14px;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked,
  &.MuiCheckbox-indeterminate {
    color: #7c6df6;
  }
`;

export const ApplyButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${palette.primaryColorLight};
    color: #fff;
    margin-left: 8px;
  }
`;

// https://mui.com/material-ui/material-icons/?theme=Outlined&query=fi&selected=FileDownloadOutlined
export const FileDownloadOutlined: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z'></path>
  </SvgIcon>
);
