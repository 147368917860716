import React from 'react';
import styled from 'styled-components';
import { Slider, TextField } from '@material-ui/core';
import { Mark } from '@material-ui/core/Slider/Slider';

import { formatNumber } from '../../../components/UI/lib';
import { ThresholdRange } from '../compare';

const CustomSlider = styled(Slider)`
  flex-grow: 1;
  .MuiSlider-markLabel {
    font-size: 12px;
  }
  .MuiSlider-thumb {
    background-color: #7c6df6;
  }
  .MuiSlider-thumb:active,
  .MuiSlider-thumb:hover,
  .Mui-focusVisible,
  &&&.MuiFocusVisible {
    box-shadow: none;
  }
  .MuiSlider-rail {
    background-color: #ddd;
  }
  .MuiSlider-track {
    background-color: #c2bcfa;
  }

  .MuiSlider-valueLabel {
    left: auto;
    transform: scale(1) translateY(10px) !important;

    & > span {
      background-color: #e3e0fc;
      transform: none;
      border-radius: 4px;
      height: 16px;
      padding: 2px 8px;
      width: auto;

      & > span {
        color: #000;
        transform: none;
      }
    }
  }
`;

const SliderControlContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SliderContainer = styled.div`
  flex-grow: 1;
  margin: 20px 30px 0;
`;

const ManualValueTextField = styled(TextField)`
  width: 70px;
  .MuiInputBase-input,
  .MuiInputLabel-root {
    font-size: 12px;
  }
`;

function parseInputToNumber(value: string) {
  return Number(value.replace(/[^0-9]/g, ''));
}

interface IThresholdControl {
  value: ThresholdRange;
  onChange: (value: ThresholdRange) => void;
  showManualValue?: boolean;
  marks?: boolean | Mark[];
  max?: number;
  min?: number;
  step?: number | null;
  valueLabelFormat?: string | ((value: number, index: number) => React.ReactNode);
}

function ThresholdControl({ value, onChange, showManualValue = true, marks, max, min = 0, step = 1, valueLabelFormat }: IThresholdControl) {
  const defaultMarks: Mark[] =
    max !== undefined
      ? [
          { value: 0, label: '0' },
          { value: max, label: formatNumber(max) },
        ]
      : [];
  const minValue = value[0];
  const maxValue = value[1];

  function handleMinChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInputToNumber(event.target.value);
    onChange([value, maxValue]);
  }

  function handleMaxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInputToNumber(event.target.value);
    onChange([minValue, value]);
  }

  function handleSliderChange(event: React.ChangeEvent<object>, value: number | number[]) {
    onChange(value as ThresholdRange);
  }

  return (
    <SliderControlContainer>
      {showManualValue && (
        <ManualValueTextField size='small' label='Min value' value={minValue} onChange={handleMinChange} variant='outlined' />
      )}
      <SliderContainer>
        <CustomSlider
          value={value}
          marks={marks ?? defaultMarks}
          onChange={handleSliderChange.bind(null)}
          valueLabelDisplay='on'
          step={step}
          min={min}
          max={max}
          valueLabelFormat={valueLabelFormat ?? formatNumber}
        />
      </SliderContainer>
      {showManualValue && (
        <ManualValueTextField size='small' label='Max value' value={maxValue} onChange={handleMaxChange} variant='outlined' />
      )}
    </SliderControlContainer>
  );
}

export default ThresholdControl;
