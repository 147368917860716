import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CopyableText = styled.span`
  padding: 0.5em 1em;
  border: 1px solid var(--gray-disabled);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: var(--dark-gray);
`;

const CopyableButton = styled.span<{ copied: boolean }>`
  padding: 0.5em 2em;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: var(--white);
  cursor: pointer;
  ${(props) => `border: 1px solid ${props.copied ? 'var(--gray-disabled)' : 'var(--beehive-purple)'}`};
  ${(props) => `background-color: ${props.copied ? 'var(--gray-disabled)' : 'var(--beehive-purple)'}`};
`;

interface ICopyable {
  copiedText: string;
}

function Copyable({ copiedText }: ICopyable) {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<any>(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(copiedText);
    if (copied) {
      return;
    }

    setCopied(true);
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div>
      <CopyableText>{copiedText}</CopyableText>
      <CopyableButton copied={copied} onClick={handleCopy}>
        {copied ? 'Copied' : 'Copy'}
      </CopyableButton>
    </div>
  );
}

export default Copyable;
