import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import escape from 'lodash/escape';

import { Concept } from '../../../../store/reducers/aiInsights';
import { Label } from '../../../../types/Label';
import { CustomAutocomplete, FilteringTextField } from '../../OpenEndedWidget/Data/ResponsesList/style';
import palette from '../../../tools/palette';
import styled from 'styled-components';

function getTooltipContent(value: string, label: string | null = null) {
  const escapedValue = escape(value);
  if (label !== null) {
    const escapedLabel = escape(label);
    const labelPart = `<span style="color:white;background-color:black;padding:2px;border-radius:2px;margin:2px">${escapedLabel}</span>`;
    if (escapedValue.length > 0) {
      return `${labelPart}: ${escapedValue}`;
    } else {
      return labelPart;
    }
  }
  return escapedValue;
}

interface ICustomInputAdornment {
  disabled: boolean;
  showReset: boolean;
  onClick: () => void;
}
function CustomInputAdornment({ disabled, showReset, onClick }: ICustomInputAdornment) {
  const AdornmentIcon = showReset ? CancelIcon : SearchIcon;
  const color = disabled ? 'var(--gray-disabled)' : undefined;
  return (
    <InputAdornment position='end' style={{ fontSize: '18px', paddingRight: '6px', color }}>
      <AdornmentIcon fontSize='inherit' style={{ cursor: 'pointer' }} onClick={disabled ? () => {} : onClick} />
    </InputAdornment>
  );
}

const LabelChip = styled.span<{ $isParent: boolean }>`
  padding-left: ${({ $isParent }) => ($isParent ? 0 : '20px')};
`;

interface ILabelsSelect {
  inputValue: string;
  selectedLabelItem: Concept | null;
  filterOptions: any;
  isInputButtonDisabled: boolean;
  shouldShowReset: boolean;
  searchString: string;
  options: Label[];
  selectedLabels: Label[];
  title?: string;
  onControlClick: () => void;
  onChange: (_: any, newInputValue: any, reason: any) => void;
  onInputChange: (_: any, newInputValue: any, reason: any) => void;
  onFocus: () => void;
  onBlur: () => void;
}

function LabelsSelect({
  inputValue,
  selectedLabelItem,
  title,
  filterOptions,
  options,
  selectedLabels,
  isInputButtonDisabled,
  shouldShowReset,
  searchString,
  onControlClick,
  onChange,
  onInputChange,
  onFocus,
  onBlur,
}: ILabelsSelect) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function getOptionDisabled(option: any) {
    return !(option as Label).displayTitle.toLowerCase().includes(inputValue.toLowerCase());
  }

  return (
    <CustomAutocomplete
      ChipProps={{
        style: {
          maxWidth: '40%',
          borderRadius: 'var(--double-border-radius)',
          margin: '8px',
          backgroundColor: 'var(--grey-bar)',
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'Rubik',
          height: '25px',
        },
      }}
      disableClearable
      freeSolo
      multiple
      data-tooltip-id='base-tooltip'
      data-tooltip-html={getTooltipContent(inputValue, selectedLabelItem ? title : null)}
      filterOptions={filterOptions}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={(option) => (option as Label).displayTitle}
      inputValue={inputValue}
      ListboxProps={{ style: { fontSize: '12px' } }}
      onChange={onChange}
      onClose={handleClose}
      onInputChange={onInputChange}
      onOpen={handleOpen}
      onFocus={onFocus}
      onBlur={onBlur}
      open={isOpen}
      options={options}
      popupIcon={null}
      renderInput={renderInput}
      renderOption={renderOption}
      size='small'
      value={selectedLabels}
    />
  );

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <FilteringTextField
        {...params}
        fullWidth
        InputProps={{
          ...params.InputProps,
          endAdornment: <CustomInputAdornment disabled={isInputButtonDisabled} onClick={onControlClick} showReset={shouldShowReset} />,
          style: {
            paddingLeft: selectedLabels.length > 0 ? 0 : '10px',
            color: searchString.length > 0 ? palette.primaryColorLight : 'inherit',
          },
        }}
        variant='outlined'
      />
    );
  }

  function renderOption(option: any) {
    const label = option as Label;
    return <LabelChip $isParent={label.parentLabel === null}>{label.displayTitle}</LabelChip>;
  }
}

export default LabelsSelect;
