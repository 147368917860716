import { datadogLogs } from '@datadog/browser-logs';

import { LOG_MESSAGE_LEVEL, LOG_MESSAGE_TYPE } from '../constants';
import { getTestsLinks } from '../components/tools';
import { narrowQueryWithRule } from '../components/FilterForm/lib';
import * as api from '../components/tools/api';
import { CurrentDashboardDataSource, ResponsesByDataSourceId } from '../store/reducers/dashboard/types';
import { SummariesPayload } from '../types/incoming/summary';
import { Filter, Query, Rule } from '../types/Query';

export const recursiveValidation = (
  query: Query | Rule,
  responsesByDataSourceId: ResponsesByDataSourceId,
): {
  valid: boolean;
  missedValues: string[];
  missedDataSourceIds: string[];
} => {
  let valid = true;
  let missedDataSourceIds: string[] = [];
  let missedValues: string[] = [];

  switch (query.type) {
    case 'group':
      for (const rule of (query as Query).rules) {
        const {
          valid: ruleValid,
          missedValues: ruleMissedValues,
          missedDataSourceIds: ruleMissedDataSourceIds,
        } = recursiveValidation(rule as Query, responsesByDataSourceId);
        valid = valid && ruleValid;
        missedValues = [...missedValues, ...ruleMissedValues];
        missedDataSourceIds = [...missedDataSourceIds, ...ruleMissedDataSourceIds];
      }
      break;
    case 'values':
      const availableResponses = responsesByDataSourceId[(query as Rule).dataSourceID];
      if (!availableResponses) {
        missedDataSourceIds.push((query as Rule).dataSourceID);
        valid = false;
      } else {
        const filterMissedValues = (query as Rule).values.filter((value) => !availableResponses.includes(value));
        if (filterMissedValues.length) {
          missedValues.push(...filterMissedValues);
          valid = false;
        }
      }
      break;
    case 'date':
    case 'sentiment':
      break;
    default:
      valid = false;
  }

  return { valid, missedValues, missedDataSourceIds } as const;
};

const cleanFilterQuery = (query: Query | Rule, missedValues: string[], missedDataSourceIds: string[]): Rule | Query | null => {
  switch (query.type) {
    case 'group':
      const rules = [];
      for (const rule of (query as Query).rules) {
        const cleanQuery = cleanFilterQuery(rule, missedValues, missedDataSourceIds);
        if (cleanQuery) {
          rules.push(cleanQuery);
        }
      }
      return { ...query, rules } as Query;
    case 'values':
      if (missedDataSourceIds.includes((query as Rule).dataSourceID)) {
        return null;
      } else {
        const values = (query as Rule).values.filter((value) => !missedValues.includes(value));
        return { ...query, values };
      }
    case 'date':
    case 'sentiment':
    default:
      return query;
  }
};

/**
 * Check if the saved filter contains invalid data sources/values.
 * Removes them and mark saved filter as invalid if it contains invalid data.
 */
export const preprocessSavedFilter = (savedFilter: any, responsesByDataSourceId: ResponsesByDataSourceId) => {
  const { valid, missedValues, missedDataSourceIds } = recursiveValidation(savedFilter.filter, responsesByDataSourceId);

  if (valid) {
    return { ...savedFilter, valid: true };
  } else {
    const filterQuery = cleanFilterQuery(savedFilter.filter, missedValues, missedDataSourceIds);
    return { ...savedFilter, valid, filter: filterQuery };
  }
};

export const reportInvalidSavedFilter = (
  savedFilterData: any,
  responsesByDataSourceId: ResponsesByDataSourceId,
  dashboardId: string,
  dashboardTitle: string,
  dashboardDataSources: CurrentDashboardDataSource[],
  formatLogMessage: (message: string) => string,
) => {
  const { missedValues, missedDataSourceIds } = recursiveValidation(savedFilterData.filter, responsesByDataSourceId);

  const missedDataSources = missedDataSourceIds.map((dataSourceID) => {
    const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceID);
    return dashboardDataSource ? dashboardDataSource.title : dataSourceID;
  });

  const links = getTestsLinks();

  const lines = [
    `[${LOG_MESSAGE_LEVEL.ERROR}]`,
    'Invalid saved filter',
    `Filter: ${savedFilterData.name}`,
    `Filter Id: ${savedFilterData.id}`,
    `Missed data sources: ${missedDataSources.join(', ')}`,
    `Missed values: ${missedValues.join(', ')}`,
  ];
  console.error(lines.join('\n'));

  const message = formatLogMessage('Invalid saved filters');
  datadogLogs.logger.error(message, {
    type: LOG_MESSAGE_TYPE.INVALID_SAVED_FILTER,
    unsafeLink: links.unsafe,
    debugLink: links.debug,
  });
};

export const getSummariesWithQuery = async (dashboardId: string, dataSourceIDs: string[], filters: Filter[], query: Rule | Query) => {
  const params = [];
  for (const filter of filters) {
    params.push({
      dataSourceIDs,
      filter: narrowQueryWithRule(filter.query, query),
    });
  }
  return (await api.fetchSummaries(dashboardId, params)) as SummariesPayload;
};
