import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { useAppParams } from '../../types/Router';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { dashboardUpdateTitle } from '../../store/thunks/dashboard';
import DashboardToolbar from './DashboardToolbar';

interface IDashboardToolbarContainer {
  exitText: boolean;
  showAccountLink: boolean;
  showInviteLink: boolean;
  disableInviteLink: boolean;
  title?: string;
  dashboardId?: string;
  onBackClick?: () => void;
}
function DashboardToolbarContainer({
  dashboardId,
  title,
  exitText,
  showAccountLink,
  showInviteLink,
  disableInviteLink,
  onBackClick,
}: IDashboardToolbarContainer) {
  const dispatch = useAppDispatch();
  const { company: selectedCompany } = useAppParams();

  const handleDashboardRename = (name: string) => {
    if (dashboardId && name && title) {
      eventsTracker.track(EVENTS.RENAME_DASHBOARD, {
        'Old Name': title,
        'New Name': name,
      });
      dispatch(
        dashboardUpdateTitle({
          id: dashboardId,
          title: name,
        }),
      );
    }
  };

  return (
    <DashboardToolbar
      dashboardId={dashboardId}
      exitText={exitText}
      selectedCompany={selectedCompany}
      title={title}
      disableInviteLink={disableInviteLink}
      showInviteLink={showInviteLink}
      showAccountLink={showAccountLink}
      onBackClick={onBackClick}
      onDashboardRename={handleDashboardRename}
    />
  );
}

export default DashboardToolbarContainer;
