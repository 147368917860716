import {
  OVERVIEW_SET_BREAKDOWN_FILTER,
  OVERVIEW_SET_QUESTIONS_BY_ID,
  overviewSetBreakdownFilter,
  overviewSetQuestionsById,
} from '../actions/overview';

const overviewDefaultState: IOverviewReducer = {
  questionsById: {},
  breakdownFilter: null,
};

export type BreakdownFilter = {
  dataSourceID: string;
  values: string[];
};

export interface IOverviewReducer {
  questionsById: object; // TODO: not used, should remove?
  breakdownFilter: null | BreakdownFilter;
}

type overviewReducerActionTypes = ReturnType<typeof overviewSetQuestionsById> & ReturnType<typeof overviewSetBreakdownFilter>;

const overviewReducer = (state = overviewDefaultState, action: overviewReducerActionTypes) => {
  switch (action.type) {
    case OVERVIEW_SET_QUESTIONS_BY_ID:
      return {
        ...state,
        questionsById: action.payload.questionsById,
      };
    case OVERVIEW_SET_BREAKDOWN_FILTER:
      return {
        ...state,
        breakdownFilter: action.payload.filter,
      };
    default:
      return state;
  }
};

export default overviewReducer;
