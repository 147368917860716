import {
  APP_BLOCKER_HIDE,
  APP_BLOCKER_SHOW,
  SET_COLOR_SCHEME_NAME,
  TURN_TRANSLATION_OFF,
  TURN_TRANSLATION_ON,
  TURN_WEIGHTED_METRICS_OFF,
  TURN_WEIGHTED_METRICS_ON,
} from '../actions/actionsCreators';

export interface IAppReducer {
  weightedMetricsUsed: boolean;
  translationUsed: boolean;
  colorSchemeName: string;
  showBlocker: boolean;
}

const defaultAppState: IAppReducer = {
  weightedMetricsUsed: true,
  translationUsed: false,
  colorSchemeName: '',
  showBlocker: false,
};

export const AppReducer = (state = defaultAppState, action: any) => {
  switch (action.type) {
    case SET_COLOR_SCHEME_NAME:
      return {
        ...state,
        colorSchemeName: action.colorSchemeName,
      };
    case TURN_TRANSLATION_ON:
      return {
        ...state,
        translationUsed: true,
      };
    case TURN_TRANSLATION_OFF:
      return {
        ...state,
        translationUsed: false,
      };
    case TURN_WEIGHTED_METRICS_ON:
      return {
        ...state,
        weightedMetricsUsed: true,
      };
    case TURN_WEIGHTED_METRICS_OFF:
      return {
        ...state,
        weightedMetricsUsed: false,
      };
    case APP_BLOCKER_SHOW:
      return {
        ...state,
        showBlocker: true,
      };
    case APP_BLOCKER_HIDE:
      return {
        ...state,
        showBlocker: false,
      };
    default:
      return state;
  }
};
