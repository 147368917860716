import styled from 'styled-components';

import {
  CompactMenuItem,
  CustomSelect,
  Row,
  SortDirectionDownButton,
  SortDirectionUpButton,
  SortingDirections,
  Toggle,
  ToggleItem,
} from '../../../components/style';
import { OutlinedButton } from '../../../../UI/Button';
import { SORT_OPTION_FIELDS, TOPICS_SORT_FIELD_NAME } from '../../../utils/types';
import { SortingQuery } from '../../../../../types/SortingQuery';

const RowStyled = styled(Row)`
  height: 64px;
`;

const CustomSelectStyled = styled(CustomSelect)`
  &.MuiInputBase-root {
    padding: 8px;
  }
`;

const ButtonStyled = styled(OutlinedButton)`
  max-height: 42px;
  height: 100%;
  &.MuiButton-root {
    padding: 9px 8px;
    @media (max-width: 1415px) {
      font-size: 12px;
    }
  }
`;

const ButtonRow = styled.div`
  height: 100%;
  width: 100%;
  max-width: 200px;
  height: 42px;
`;

const SortingRow = styled.div`
  display: flex;
  height: 100%;
  height: 42px;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
`;

interface ITopicsHeader {
  topicsSorting: SortingQuery;
  onSortingChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onSortingDirectionChange: (desc: boolean) => void;
  onTopicCreatingChange: () => void;
}

function TopicsHeader({ topicsSorting, onSortingChange, onSortingDirectionChange, onTopicCreatingChange }: ITopicsHeader) {
  return (
    <RowStyled>
      <ButtonRow>
        <ButtonStyled data-testid='topics-header-add' fullWidth onClick={onTopicCreatingChange}>
          + Add new
        </ButtonStyled>
      </ButtonRow>
      <SortingRow>
        <CustomSelectStyled
          data-testid='topics-header-sorting-select'
          data-tooltip-id='base-tooltip'
          data-tooltip-content={TOPICS_SORT_FIELD_NAME[topicsSorting.sortBy]}
          onChange={onSortingChange}
          value={topicsSorting.sortBy}
          variant='outlined'
          fullWidth
        >
          {SORT_OPTION_FIELDS.map((field) => (
            <CompactMenuItem key={field} value={field}>
              {TOPICS_SORT_FIELD_NAME[field]}{' '}
            </CompactMenuItem>
          ))}
        </CustomSelectStyled>
        <SortingDirections>
          <Toggle>
            <ToggleItem $isSelected={topicsSorting.desc}>
              <SortDirectionDownButton
                data-testid='topics-header-sorting-directon-down'
                $isSelected={topicsSorting.desc}
                onClick={onSortingDirectionChange.bind(null, true)}
              />
            </ToggleItem>
            <ToggleItem $isSelected={!topicsSorting.desc}>
              <SortDirectionUpButton
                data-testid='topics-header-sorting-directon-up'
                $isSelected={!topicsSorting.desc}
                onClick={onSortingDirectionChange.bind(null, false)}
              />
            </ToggleItem>
          </Toggle>
        </SortingDirections>
      </SortingRow>
    </RowStyled>
  );
}

export default TopicsHeader;
