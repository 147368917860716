import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { API_ARG_DATE_FORMAT, DEFAULT_DATE_FORMAT } from '../../../models/TimeRange';

const ControlDatePicker = ({ value, disabled, onChange, testID, label = '' }) => {
  const datePickerValue = value ? parse(value, API_ARG_DATE_FORMAT, new Date()) : null;

  const handleOnChange = (v) => {
    onChange(v && v instanceof Date && !isNaN(v) ? format(v, API_ARG_DATE_FORMAT) : v);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        placeholder='MM / DD / YYYY'
        helperText={null}
        format={DEFAULT_DATE_FORMAT}
        clearable
        disabled={disabled}
        value={datePickerValue}
        onChange={handleOnChange}
        inputVariant='outlined'
        size='small'
        inputProps={{
          'data-testid': testID,
        }}
        label={label}
      />
    </MuiPickersUtilsProvider>
  );
};

export default ControlDatePicker;
