import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import people1x from '../../assets/images/welcome/welcome@1x.png';
import people2x from '../../assets/images/welcome/welcome@2x.png';
import people3x from '../../assets/images/welcome/welcome@3x.png';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg';
import { ReactComponent as EyeCrossedIcon } from '../../assets/icons/eye-crossed-icon.svg';
import { FilledButton } from '../../components/UI/Button';
import PasswordHelper from './components/PasswordHelper';
import { BeehiveLogoStyled, PageContainer } from './styles';
import { checkRules } from './utils/passwordValidator';

export const WelcomeContainer = styled.div`
  position: relative;

  display: flex;
  width: 800px;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;

  margin: 15vh auto 100px;
  background-color: var(--white);

  border-radius: var(--double-border-radius);

  background: var(--white);
  box-shadow:
    0 1px 2px 0 rgba(15, 12, 12, 0.3),
    0 2px 6px 2px rgba(15, 12, 12, 0.15);

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 610px;
  }
  @media screen and (max-width: 1023px) {
    width: 328px;
  }
`;

export const PeopleImg = styled.img`
  width: 355px;
  height: 276px;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 355px;
    height: 276px;
  }
  @media screen and (max-width: 1023px) {
    width: 258px;
    height: 200px;
  }
`;

export const Header = styled.div`
  color: var(--black);

  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`;
export const Info = styled.div`
  color: var(--black);

  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 24px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 32px;
  width: 100%;
  max-width: 463px;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    gap: 16px;
  }
  @media screen and (max-width: 1023px) {
    gap: 16px;
  }
`;

export const FormLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media screen and (min-width: 360px) and (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const FormElement = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Label = styled.label`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 100%;
`;
export const Input = styled.input<{ $isError?: boolean }>`
  display: flex;
  height: 40px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  align-self: stretch;
  border-radius: 8px;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: 1px solid ${({ $isError }) => ($isError ? 'var(--red-error)' : 'var(--medium-gray)')};
`;
export const InputError = styled.span`
  color: var(--red-error);
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  position: absolute;
  bottom: -20px;
`;

export const eyeIconStyles = css`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5px;
  top: 20px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--medium-gray);
  }
`;

export const EyeIconStyled = styled(EyeIcon)`
  ${eyeIconStyles}
`;

export const EyeCrossedIconStyled = styled(EyeCrossedIcon)`
  ${eyeIconStyles}
`;

export const PasswordHelperContainer = styled.div<{ $isDirty: boolean }>`
  display: ${({ $isDirty }) => ($isDirty ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const FilledButtonStyled = styled(FilledButton)`
  margin-top: 40px !important;
  width: 221px !important;
  font-family: Rubik !important;
  font-size: 18px !important;
  padding: 10px 16px !important;

  @media screen and (max-width: 1023px) {
    max-width: 463px;
    width: 100% !important;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: var(--white);
  }
`;

enum FIELDS {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PASSWORD = 'PASSWORD',
  VERIFICATION_PASSWORD = 'VERIFICATION_PASSWORD',
}

interface IWelcomePage {
  firstName: string;
  lastName: string;
  password: string;
  verificationPassword: string;
  isLoading: boolean;
  doPasswordsMatch: boolean;
  isDisabled: boolean;
  passwordCheckInfo: ReturnType<typeof checkRules>;
  onFirstNameChange: (newFirstName: string) => void;
  onLastNameChange: (newLastName: string) => void;
  onPasswordChange: (newPassword: string) => void;
  onVerificationPasswordChange: (newVerificationPassword: string) => void;
  onSubmit: () => void;
}

function WelcomePage({
  firstName,
  lastName,
  password,
  verificationPassword,
  passwordCheckInfo,
  doPasswordsMatch,
  isLoading,
  isDisabled,
  onFirstNameChange,
  onLastNameChange,
  onPasswordChange,
  onVerificationPasswordChange,
  onSubmit,
}: IWelcomePage) {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isVerificationPasswordHidden, setIsVerificationPasswordHidden] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  function handleInput(field: FIELDS, event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    switch (field) {
      case FIELDS.FIRST_NAME:
        onFirstNameChange(value);
        break;
      case FIELDS.LAST_NAME:
        onLastNameChange(value);
        break;
      case FIELDS.PASSWORD:
        if (!isDirty) {
          setIsDirty(true);
        }
        onPasswordChange(value);
        break;
      case FIELDS.VERIFICATION_PASSWORD:
        if (!isDirty) {
          setIsDirty(true);
        }
        onVerificationPasswordChange(value);
        break;
      default:
        console.error('Unsupported field: ', field);
    }
  }

  function handleTogglePasswordVisibility(e: React.MouseEvent) {
    e.stopPropagation();
    setIsPasswordHidden((prevState) => !prevState);
  }

  function handleToggleVerificationPasswordVisibility(e: React.MouseEvent) {
    e.stopPropagation();
    setIsVerificationPasswordHidden((prevState) => !prevState);
  }

  return (
    <PageContainer>
      <WelcomeContainer>
        <BeehiveLogoStyled />
        <PeopleImg srcSet={`${people1x} 1x, ${people2x} 2x, ${people3x} 3x`} />
        <Header>Welcome to Beehive AI</Header>
        <Info>While we set up your account, please complete your profile.</Info>
        <Form>
          <FormLine>
            <FormElement>
              <Label htmlFor='First-Name'>First Name</Label>
              <Input
                id='First-Name'
                required={true}
                value={firstName}
                placeholder='Your first name'
                onChange={handleInput.bind(null, FIELDS.FIRST_NAME)}
              />
            </FormElement>
            <FormElement>
              <Label htmlFor='Last-Name'>Last Name</Label>
              <Input
                id='Last-Name'
                required={true}
                value={lastName}
                placeholder='Your last name'
                onChange={handleInput.bind(null, FIELDS.LAST_NAME)}
              />
            </FormElement>
          </FormLine>
          <FormElement>
            <Label htmlFor='Password'>Password</Label>
            <InputWrapper>
              <Input
                id='Password'
                $isError={!doPasswordsMatch}
                required={true}
                type={isPasswordHidden ? 'password' : 'text'}
                value={password}
                placeholder='Add a password'
                onChange={handleInput.bind(null, FIELDS.PASSWORD)}
              />
              {renderTogglePasswordVisiblityIcon(isPasswordHidden, handleTogglePasswordVisibility)}
            </InputWrapper>
            {!doPasswordsMatch && <InputError>Passwords don’t match</InputError>}
          </FormElement>
          <FormElement>
            <Label htmlFor='Verification_Password'>Confirm Your Password</Label>
            <InputWrapper>
              <Input
                id='Verification_Password'
                $isError={!doPasswordsMatch}
                required={true}
                type={isVerificationPasswordHidden ? 'password' : 'text'}
                value={verificationPassword}
                placeholder='Add password again'
                onChange={handleInput.bind(null, FIELDS.VERIFICATION_PASSWORD)}
              />
              {renderTogglePasswordVisiblityIcon(isVerificationPasswordHidden, handleToggleVerificationPasswordVisibility)}
            </InputWrapper>
            {!doPasswordsMatch && <InputError>Passwords don’t match</InputError>}
          </FormElement>
          <PasswordHelperContainer $isDirty={isDirty}>
            <PasswordHelper passwordCheckInfo={passwordCheckInfo} />
          </PasswordHelperContainer>
        </Form>
        <FilledButtonStyled type='submit' onClick={onSubmit} disabled={isDisabled}>
          {isLoading ? <StyledCircularProgress size='24px' /> : "Let's Get Started"}
        </FilledButtonStyled>
      </WelcomeContainer>
    </PageContainer>
  );

  function renderTogglePasswordVisiblityIcon(isHidden: boolean, clickHandler: (e: React.MouseEvent) => void) {
    let Component = EyeCrossedIconStyled;
    if (isHidden) {
      Component = EyeIconStyled;
    }

    return <Component onClick={clickHandler} />;
  }
}

export default WelcomePage;
