import { useState } from 'react';
import isEqual from 'lodash/isEqual';

import { FILTER_ID_ALL } from './useFilters';
import { Filter } from '../types/Query';

/**
 * Returns an array of selected filter ids, and a function to filter ids
 * On filters change:
 *   - auto-select filters
 *   - remove ids of unselected filters from selectedFilterIds
 * Usage: const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);
 */
function useSelectedFilterIdsToggle(filters: Filter[]) {
  const [prevFilters, setPrevFilters] = useState(filters);
  const appliedFilterIds = filters.map((filter) => filter.id);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>(appliedFilterIds.length > 0 ? appliedFilterIds : [FILTER_ID_ALL]);

  if (!isEqual(filters, prevFilters)) {
    setSelectedFilterIds(appliedFilterIds);
    setPrevFilters(filters);
  } else {
    const filteredIds = filters.length > 0 ? selectedFilterIds.filter((id) => filters.some((filter) => filter.id === id)) : [FILTER_ID_ALL];
    if (filteredIds.length !== selectedFilterIds.length) {
      if (filteredIds.length === 0) {
        setSelectedFilterIds([FILTER_ID_ALL]);
      } else {
        setSelectedFilterIds(filteredIds);
      }
    }
  }

  return [selectedFilterIds, setSelectedFilterIds] as const;
}

export default useSelectedFilterIdsToggle;
