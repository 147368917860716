import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { OPTION_SELECT_OPERATOR, OPTION_SELECT_SOURCE } from '../constants';

const ErrorBox = styled(Box)`
  color: red;
  font-size: 12px;
`;

const RuleValidator = ({ rule }) => {
  let error = '';
  if ('rules' in rule) {
    if (rule.rules.length === 0) {
      error = 'Group cannot be empty';
    }
  } else {
    if (rule.field === OPTION_SELECT_SOURCE.name) {
      error = 'Please select a source';
    } else if (rule.operator === OPTION_SELECT_OPERATOR.name) {
      error = 'Please select an operator';
    } else if (rule.value === null || rule.value.length === 0) {
      error = 'Please select a value';
    }
  }

  if (!error) {
    return null;
  }

  return <ErrorBox>{error}</ErrorBox>;
};

export default RuleValidator;
