import { getLabelsComparator } from '../../Widgets/OpenEndedWidget/Data/lib';

const getTooltip = (item, base) => {
  if (item.original && item.original.length) {
    return `${base} (${item.original.join(', ')})`;
  }
  return base;
};

const prepareOrderedLabels = (dataSource, responseField, valueField) => {
  return dataSource.items.map((item) => ({
    name: item.response,
    tooltip: getTooltip(item, item[responseField]),
    label: item[responseField],
    value: item[valueField].value,
    percentage: item[valueField].percentage,
    parent: null,
  }));
};

const prepareUnorderedLabels = (dataSource, responseField, valueField) => {
  // un-ordered data source -> sort by value, group by parents
  const valueByLabel = new Map();
  const labelByTitle = new Map();

  const children = [];
  for (let item of dataSource.items) {
    valueByLabel.set(item.response, item[valueField].value);
    labelByTitle.set(item.response, { ...item, title: item.response });
    if (item.children) {
      children.push(...item.children);
    }
  }
  const labelsComparator = getLabelsComparator(valueByLabel);
  const sortedPrimaryLabels = [...labelByTitle.values()].filter((l) => !children.includes(l.response)).sort(labelsComparator);

  const list = [];
  for (let item of sortedPrimaryLabels) {
    list.push({
      name: item.response,
      label: item[responseField],
      value: item[valueField].value,
      percentage: item[valueField].percentage,
      tooltip: getTooltip(item, item[responseField]),
      children: item.children,
      parent: null,
    });
    if (item.children) {
      const sortedChildren = [...item.children].sort((a, b) => valueByLabel.get(b) - valueByLabel.get(a));
      for (let child of sortedChildren) {
        const childItem = labelByTitle.get(child);
        list.push({
          name: childItem.response,
          label: childItem[responseField],
          value: childItem[valueField].value,
          percentage: childItem[valueField].percentage,
          parent: item.response,
          tooltip: getTooltip(item, item[responseField]),
        });
      }
    }
  }

  return list;
};

export const prepareLabelsAutocompleteOptions = (
  dataSource,
  dashboardDataSource,
  translationUsed,
  weightedMetricsUsed,
  specialOptions = [],
) => {
  const responseField = translationUsed ? 'response' : 'originalResponse';
  const valueField = weightedMetricsUsed ? 'weighted' : 'base';

  const options = [];
  specialOptions.forEach((specialItem, index) => {
    options.push({
      ...specialItem,
      parent: null,
      isSpecial: true,
      hasDivider: index === specialOptions.length - 1,
    });
  });

  if (dashboardDataSource.ordered) {
    options.push(...prepareOrderedLabels(dataSource, responseField, valueField, translationUsed));
  } else {
    options.push(...prepareUnorderedLabels(dataSource, responseField, valueField, translationUsed));
  }

  return options;
};
