import React from 'react';
import styled from 'styled-components';
import { Chip, CircularProgress, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import { Integration } from '../../../types/connections';
import { tooltipClasses } from '../../../style';
import { OAUTH_REAUTH_SOURCES } from '../constants';

import newIcon from '../../../assets/icons/integration-new-icon.svg';

const IntegrationCard = styled.div<{ $isSelected: boolean }>`
  display: flex;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  ${(props) =>
    props.$isSelected
      ? `
            border: 1px solid var(--beehive-purple);
            background-color: var(--light-purple-20-transparency);
        `
      : `
            border: 1px solid var(--medium-gray);
            background-color: var(--white);
        `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const IntegrationTitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex-grow: 1;
`;

const IntegrationTitleLoaderContainer = styled.div`
  margintop: 8px;
`;

const Icon = styled.img`
  max-width: 64px;
  vertical-align: middle;
`;

const StyledChip = styled(Chip)<{ $hasError: boolean }>`
  margin-top: 8px;
  width: fit-content;
  && {
    ${(props) =>
      props.$hasError
        ? `
                background-color: var(--error-message-background);
                color: var(--error-message);
            `
        : `
                background-color: var(--light-purple-50-transparency);
                color: var(--dark-purple);
            `}
  }
`;

const IconContainer = styled.div`
  height: 50px;
  width: 64px;
  max-width: 64px;
  min-width: 64px;
  display: flex;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const DeleteMenuItem = styled(StyledMenuItem)`
  &.MuiButtonBase-root.Mui-disabled {
    pointer-events: auto;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin-right: 12px;
  }
`;

const NewIconContainer = styled.div`
  position: relative;
`;

const NewIntegrationIcon = styled.img`
  position: absolute;
  top: -32px;
  left: 0;
  z-index: 1;
`;

interface IIntegrationListItem {
  integration: Integration;
  anchorEl: HTMLElement | null;
  isLoading: boolean;
  isSelected: boolean;
  isNew: boolean;
  onSelect: (integration: Integration) => void;
  onEdit: (integration: Integration) => void;
  onMenuOpen: () => void;
  onDelete(): void;
  onMenuClick(event: React.MouseEvent<HTMLElement>): void;
  onCloseMenu(): void;
}

function IntegrationListItem({
  integration,
  anchorEl,
  isLoading,
  isSelected,
  isNew,
  onSelect,
  onMenuClick,
  onCloseMenu,
  onMenuOpen,
  onEdit,
  onDelete,
}: IIntegrationListItem) {
  const { displayName, logoURL, icon, authentication, connections } = integration;

  const isError = !!authentication?.error;
  const isMenuOpen = Boolean(anchorEl);
  const isDeleteDisabled = Boolean(connections.filter((c) => c.authID === authentication?.id).length > 0);
  const isReAuthSupported = OAUTH_REAUTH_SOURCES.includes(integration.source);

  const chipLabel = isError ? 'Authentication Error' : `(${connections.length}) Connections`;
  const menuPaperProps = {
    style: {
      width: 240,
    },
  };

  return (
    <>
      <IntegrationCard data-testid='integration-list-item-card' $isSelected={isSelected} onClick={onSelect.bind(null, integration)}>
        {isNew && (
          <NewIconContainer data-testid='integration-list-item-new-icon'>
            <NewIntegrationIcon src={newIcon} />
          </NewIconContainer>
        )}

        <IconContainer>
          {isLoading ? <StyledCircularProgress size='36px' /> : <Icon src={logoURL || icon} alt={displayName} />}
        </IconContainer>
        <IntegrationTitleColumn>
          <div>{displayName}</div>
          {isLoading ? (
            <IntegrationTitleLoaderContainer>
              <StyledCircularProgress size='18px' />
              Loading...
            </IntegrationTitleLoaderContainer>
          ) : (
            <StyledChip data-testid='integration-list-item-chip' label={chipLabel} $hasError={isError} size='small' />
          )}
        </IntegrationTitleColumn>
        {authentication && (
          <IconButton data-testid='integration-list-item-open-menu' onClick={onMenuClick}>
            <MoreVertIcon />
          </IconButton>
        )}
      </IntegrationCard>
      <Menu id='long-menu' anchorEl={anchorEl} PaperProps={menuPaperProps} open={isMenuOpen} onEntered={onMenuOpen} onClose={onCloseMenu}>
        <StyledMenuItem data-testid='integration-list-item-edit' button onClick={onEdit.bind(null, integration)}>
          {isReAuthSupported ? 'Reauthorize' : 'Edit authentication'}
          <EditIcon />
        </StyledMenuItem>
        {Boolean(authentication) && (
          <Tooltip
            arrow
            classes={tooltipClasses()}
            placement='top'
            title={
              isDeleteDisabled
                ? 'You may not delete the authentication while there are existing connections using this authentication.'
                : ''
            }
          >
            <DeleteMenuItem data-testid='integration-list-item-delete' button disabled={isDeleteDisabled} onClick={onDelete}>
              Delete authentication
              <DeleteIcon />
            </DeleteMenuItem>
          </Tooltip>
        )}
      </Menu>
    </>
  );
}

export default IntegrationListItem;
