import styled from 'styled-components';
import { Dialog, IconButton } from '@material-ui/core';

import { SurveySourceEnum } from '../../../types/connections';

import { StyledCloseIcon } from '../style';
import { OAUTH_REAUTH_SOURCES } from '../constants';
import AddConnectionContainer from './AddConnectionContainer';
import ReAuthQualtricsContainer from './Qualtrics/ReAuthQualtricsContainer';

const Container = styled.div`
  padding: 32px 30px 24px 30px;
  width: 1000px;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

interface IAddConnectionDialog {
  isOpen: boolean;
  isAuthEdit?: boolean;
  source?: SurveySourceEnum;
  onClose: () => void;
}

function AddConnectionDialog({ isOpen, isAuthEdit, source, onClose }: IAddConnectionDialog) {
  if (!source || !isOpen) {
    return null;
  }

  if (OAUTH_REAUTH_SOURCES.includes(source) && isAuthEdit) {
    return (
      <Dialog open={isOpen}>
        <ReAuthQualtricsContainer onClose={onClose} />
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} maxWidth={false}>
      <CloseButtonContainer>
        <IconButton size='small' onClick={onClose}>
          <StyledCloseIcon />
        </IconButton>
      </CloseButtonContainer>
      <Container>
        <AddConnectionContainer isEditingAuth={isAuthEdit ?? false} />
      </Container>
    </Dialog>
  );
}

export default AddConnectionDialog;
