export const TOPICS_SORT_FIELDS = {
  FREQUENCY: 'frequency',
  NAME: 'name',
};

export const SORT_OPTION_FIELDS = [TOPICS_SORT_FIELDS.FREQUENCY, TOPICS_SORT_FIELDS.NAME];

export const TOPICS_SORT_FIELD_NAME = {
  [TOPICS_SORT_FIELDS.FREQUENCY]: 'Sort by Frequency',
  [TOPICS_SORT_FIELDS.NAME]: 'Sort by Name',
};
