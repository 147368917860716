import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const ConfirmationDialog = ({ onCancel, onConfirm, open, title, content, disabled = false }) => {
  return (
    <Dialog maxWidth='xs' open={open} data-testid='confirmation-dialog'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary' disabled={disabled} disableRipple disableTouchRipple>
          Cancel
        </Button>
        <Button onClick={onConfirm} color='primary' data-testid='confirmation-ok' disabled={disabled} disableRipple disableTouchRipple>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
