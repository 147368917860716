import { PERIOD, WIDGET_TYPE } from '../../../../constants';
import { Base, Config } from '../../../../types/Config';
import { TRENDS_DISPLAY_MODE } from '../../../Widgets/TrendsWidget/Settings/TrendsWidgetSettings';

interface IGetBase {
  dashboardID: string;
  title: string;
  type: string;
}
const getBase = ({ dashboardID, title, type }: IGetBase): Base => {
  return {
    dashboardID,
    type,
    title: title,
    originalTitle: title,
    collapsed: false,
    expanded: false,
    maximized: type === WIDGET_TYPE.OPEN_ENDED || type === WIDGET_TYPE.INTERVIEW ? true : false,
    filter: {
      valid: false,
    },
  };
};

interface IPrepareConfig {
  dashboardID: string;
  title: string;
  selectedItems: any[];
  dataSourceID: string;
}
export const prepareTrendsWidgetConfig = ({ dashboardID, dataSourceID, selectedItems, title }: IPrepareConfig): Config => {
  const base = getBase({ dashboardID, title, type: WIDGET_TYPE.TRENDS });
  return {
    base,
    settings: {
      dataSources: [
        {
          id: dataSourceID,
          selectedItems,
        },
      ],
      displayMode: TRENDS_DISPLAY_MODE.PERCENTAGE,
      aggregationRange: PERIOD.MONTH,
      merge: false,
    },
  };
};

export const prepareInterviewWidgetConfig = ({ dashboardID, dataSourceID, selectedItems, title }: IPrepareConfig): Config => {
  const base = getBase({ dashboardID, title, type: WIDGET_TYPE.INTERVIEW });
  return {
    base,
    settings: {
      dataSources: [
        {
          id: dataSourceID,
          selectedItems,
        },
      ],
      detailed: true,
      showFilters: true,
      showResponses: true,
      showStatus: true,
    },
  };
};

export const prepareOpenEndedWidgetConfig = ({ dashboardID, dataSourceID, selectedItems, title }: IPrepareConfig): Config => {
  const base = getBase({ dashboardID, title, type: WIDGET_TYPE.OPEN_ENDED });
  return {
    base,
    settings: {
      dataSources: [
        {
          id: dataSourceID,
          selectedItems,
        },
      ],
      detailed: true,
      showFilters: true,
      showResponses: true,
      showStatus: true,
    },
  };
};

export const prepareBarsWidgetConfig = ({ dashboardID, dataSourceID, selectedItems, title }: IPrepareConfig): Config => {
  const base = getBase({ dashboardID, title, type: WIDGET_TYPE.BARS });
  return {
    base,
    settings: {
      dataSources: [
        {
          id: dataSourceID,
          selectedItems,
        },
      ],
    },
  };
};
