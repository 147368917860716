import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as BookmarkIcon } from '../../../../../../assets/icons/bookmark-icon.svg';
import { LabelsWrapper } from '../../style';

export const StyledLabelsWrapper = styled(LabelsWrapper)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
`;

export const HeaderRightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SentimentResponseContainer = styled(Grid)`
  margin-right: 16px !important;
  width: 116px;
`;

export const BookmarkIconStyled = styled(BookmarkIcon)<{
  $isSelected: boolean;
}>`
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'none')};
  fill: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'none')};

  &:hover {
    background-color: var(--light-gray);
  }
`;
