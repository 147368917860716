import { useAppSelector } from '../../../store/hooks';
import { SurveySourceEnum } from '../../../types/connections';

import IntegrationView from './IntegrationView';

interface IIntegrationViewContainter {
  handleAddConnection: (source: SurveySourceEnum) => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string) => void;
}

function IntegrationViewContainer({ handleAddConnection, handleEnrich }: IIntegrationViewContainter) {
  const integration = useAppSelector((state) => state.connectors).selectedIntegration;

  return <IntegrationView integration={integration} handleAddConnection={handleAddConnection} handleEnrich={handleEnrich} />;
}

export default IntegrationViewContainer;
