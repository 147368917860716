import * as api from '../../components/tools/api';
import { Query } from '../../types/Query';
import {
  dashboardCreateSavedFilterError,
  dashboardCreateSavedFilterStart,
  dashboardCreateSavedFilterSuccess,
  dashboardFetchTotalsError,
  dashboardFetchTotalsStart,
  dashboardFetchTotalsSuccess,
  dashboardUpdateSavedFilterError,
  dashboardUpdateSavedFilterStart,
  dashboardUpdateSavedFilterSuccess,
} from '../actions/dashboardFilter';
import { Action } from '../hooks';

interface IUpdateSavedFilter {
  dashboardId: string;
  savedFilterID: string;
  name: string;
  filterQuery: Query;
  description: string;
}
export const updateSavedFilter =
  ({ dashboardId, savedFilterID, name, filterQuery, description }: IUpdateSavedFilter): Action =>
  async (dispatch) => {
    dispatch(dashboardUpdateSavedFilterStart());
    try {
      const resp = await api.updateSavedFilter(dashboardId, savedFilterID, name, filterQuery, description);
      if (resp.error) {
        throw new Error(resp.error);
      } else {
        dispatch(dashboardUpdateSavedFilterSuccess());
      }
    } catch (e) {
      dispatch(dashboardUpdateSavedFilterError());
    }
  };

interface ICreateSavedFilter {
  dashboardId: string;
  name: string;
  filterQuery: Query;
  description: string;
}
export const createSavedFilter =
  ({ dashboardId, name, filterQuery, description }: ICreateSavedFilter): Action =>
  async (dispatch) => {
    dispatch(dashboardCreateSavedFilterStart());
    try {
      const resp = await api.createSavedFilter(dashboardId, name, filterQuery, description);
      if (resp.error) {
        throw new Error(resp.error);
      } else {
        dispatch(dashboardCreateSavedFilterSuccess());
      }
    } catch (e) {
      dispatch(dashboardCreateSavedFilterError());
    }
  };

interface IFetchTotals {
  dashboardId: string;
  filterQuery: Query;
}
export const fetchTotals =
  ({ dashboardId, filterQuery }: IFetchTotals): Action =>
  async (dispatch) => {
    dispatch(dashboardFetchTotalsStart());
    try {
      const resp = await api.fetchTotals(dashboardId, filterQuery);
      if (resp.error) {
        throw new Error(resp.error);
      } else {
        dispatch(dashboardFetchTotalsSuccess({ result: resp }));
      }
    } catch (e) {
      dispatch(dashboardFetchTotalsError());
    }
  };
