import { useState } from 'react';

import HivesBanner from './HivesBanner';

interface IHivesBannerContainer {
  dashboardsLength: number;
}

function HivesBannerContainer({ dashboardsLength }: IHivesBannerContainer) {
  const MIN_DASHBOARDS_COUNT = 2;
  const [isOpen, setIsOpen] = useState(dashboardsLength < MIN_DASHBOARDS_COUNT);

  return <HivesBanner isOpen={isOpen} setIsOpen={setIsOpen} />;
}

export default HivesBannerContainer;
