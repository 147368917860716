import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';

import { ICompareRow, ICompareSubRow } from '../../compare';
import { formatNumber } from '../../../../components/UI/lib';
import CompareTableRowPValue from './CompareTableRowPValue';
import { StyledCheckbox } from '../UI';

export const CustomTableCell = styled(TableCell)`
  padding: 4px 8px;
`;

const FilterColor = styled('div')`
  height: 75%;
  width: 15px;
  min-width: 15px;
  background-color: ${(props) => props.color};
  margin: 2px 8px 2px 0;
`;

const TwoRowsBlock = styled('div')`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const SegmentBlock = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

const RowCell = styled(CustomTableCell)<{
  $lightGray?: boolean;
  $mediumGray?: boolean;
}>`
  ${({ $lightGray }) => $lightGray && 'background-color: #f5f5f5'}
  ${({ $mediumGray }) => $mediumGray && 'background-color: #eaeaea'}
`;

const SegmentRowCell = styled(RowCell)`
  height: 0;
`;

/**
 * Returns the sub row with the maximum difference percentage
 * Later logic of the function could be changed to select subrow based on other criteria
 * @param row
 */
const getDetailsSubRow = (row: ICompareRow): ICompareSubRow => {
  let maxDifferencePercentage = row.data[0].differencePercentage;
  let result = row.data[0];
  for (const subRow of row.data) {
    if (subRow.differencePercentage > maxDifferencePercentage) {
      maxDifferencePercentage = subRow.differencePercentage;
      result = subRow;
    }
  }
  return result;
};

interface ICompareTableRow {
  checked: boolean;
  row: ICompareRow;
  filtersById: any;
  onClick: (row: ICompareRow) => void;
}

const CompareTableRow: React.FC<ICompareTableRow> = ({ checked, row, filtersById, onClick }) => {
  const detailsSubRow = getDetailsSubRow(row);

  return (
    <>
      <TableRow hover onClick={onClick.bind(null, row)}>
        <RowCell padding='checkbox' $lightGray>
          <StyledCheckbox checked={checked} />
        </RowCell>

        <RowCell align='right'>#{formatNumber(row.index)}</RowCell>

        <RowCell $lightGray>
          <TwoRowsBlock data-tooltip-id='base-tooltip' data-tooltip-content={row.dataSourceTitle}>
            {row.dataSourceTitle}
          </TwoRowsBlock>
        </RowCell>
        <RowCell align='right' $lightGray>
          {formatNumber(row.dataSourceTotal)}
        </RowCell>

        <CustomTableCell>
          <TwoRowsBlock data-tooltip-id='base-tooltip' data-tooltip-content={row.concept}>
            {row.concept}
          </TwoRowsBlock>
        </CustomTableCell>

        <SegmentRowCell $lightGray>
          <SegmentBlock data-tooltip-id='base-tooltip' data-tooltip-content={filtersById[detailsSubRow.filterMaxID].name}>
            <FilterColor color={filtersById[detailsSubRow.filterMaxID].color} />
            <TwoRowsBlock>{filtersById[detailsSubRow.filterMaxID].name}</TwoRowsBlock>
          </SegmentBlock>
        </SegmentRowCell>
        <RowCell align='right' $lightGray>
          {formatNumber(detailsSubRow.filterMaxValue)}
        </RowCell>
        <RowCell align='right' $lightGray>
          ({formatNumber(detailsSubRow.filterMaxPercentage)}%)
        </RowCell>

        <SegmentRowCell $mediumGray>
          <SegmentBlock data-tooltip-id='base-tooltip' data-tooltip-content={filtersById[detailsSubRow.filterMinID].name}>
            <FilterColor color={filtersById[detailsSubRow.filterMinID].color} />
            <TwoRowsBlock>{filtersById[detailsSubRow.filterMinID].name}</TwoRowsBlock>
          </SegmentBlock>
        </SegmentRowCell>
        <RowCell align='right' $mediumGray>
          {formatNumber(detailsSubRow.filterMinValue)}
        </RowCell>
        <RowCell align='right' $mediumGray>
          ({formatNumber(detailsSubRow.filterMinPercentage)}%)
        </RowCell>

        <RowCell align='right'>{formatNumber(detailsSubRow.differenceAbsolute)}</RowCell>
        <RowCell align='right'>({formatNumber(detailsSubRow.differencePercentage)}%)</RowCell>
        <RowCell align='right' $lightGray>
          <CompareTableRowPValue value={detailsSubRow.pValue} />
        </RowCell>
      </TableRow>
    </>
  );
};

export default CompareTableRow;
