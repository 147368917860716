import React from 'react';
import styled from 'styled-components';

import palette from '../../../../components/tools/palette';
import { CHART_FONT_FAMILY } from '../../constants';
import { ChartLabel } from '../../compare';

const ChartLabelsWrapper = styled.div`
  display: flex;
  height: 95px;
  gap: 1px;
  margin: 5px 41px 0;
`;
const Label = styled.div`
  flex: 1;
  text-align: center;
  min-width: 0;
`;

const Concept = styled.span`
  display: inline-block;
  background-color: #fff;
  color: ${palette.darkPurple};
  border: 1px solid ${palette.lightPurpleTransparent50};
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
`;
const DataSource = styled.div`
  text-align: center;
  color: ${palette.black};
  font-family: ${CHART_FONT_FAMILY};
  font-size: 14px;
  margin-top: 4px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 0 10%;
`;

interface ICompareChartLabels {
  chartLabels: ChartLabel[];
}

function CompareChartLabels({ chartLabels }: ICompareChartLabels) {
  return (
    <ChartLabelsWrapper>
      {chartLabels.map((label) => (
        <Label key={`${label.concept} - ${label.dataSource}`}>
          <Concept data-tooltip-id='base-tooltip' data-tooltip-content={label.concept}>
            {label.concept}
          </Concept>
          <DataSource data-tooltip-id='base-tooltip' data-tooltip-content={label.dataSource}>
            {label.dataSource}
          </DataSource>
        </Label>
      ))}
    </ChartLabelsWrapper>
  );
}

export default CompareChartLabels;
