export enum SortField {
  DIFFERENCE_PERCENTAGE = 'differencePercentage',
  DIFFERENCE_ABSOLUTE = 'differenceAbsolute',
  FILTER_MIN_VALUE = 'filterMinValue',
  FILTER_MAX_VALUE = 'filterMaxValue',
  FILTER_MIN_PERCENTAGE = 'filterMinPercentage',
  FILTER_MAX_PERCENTAGE = 'filterMaxPercentage',
  P_VALUE = 'pValue',
  DATA_SOURCE_TOTAL = 'dataSourceTotal',
  DATA_SOURCE_TITLE = 'dataSourceTitle',
  CONCEPT = 'concept',
}

export const SortFieldName: Record<SortField, string> = {
  [SortField.DIFFERENCE_PERCENTAGE]: 'Difference Percentage',
  [SortField.DIFFERENCE_ABSOLUTE]: 'Difference Absolute',
  [SortField.FILTER_MIN_VALUE]: 'Min Segment Total',
  [SortField.FILTER_MAX_VALUE]: 'Max Segment Total',
  [SortField.FILTER_MIN_PERCENTAGE]: 'Min Segment Percentage',
  [SortField.FILTER_MAX_PERCENTAGE]: 'Max Segment Percentage',
  [SortField.P_VALUE]: 'P-value',
  [SortField.DATA_SOURCE_TOTAL]: 'Data Source Answered',
  [SortField.DATA_SOURCE_TITLE]: 'Data Source Name',
  [SortField.CONCEPT]: 'Value',
};

export const SEGMENT_ID_ANY = '__segment_id_any__';

export enum CompareView {
  TABLE = 'Table',
  CHART = 'Chart',
  COMBINED = 'Combined',
}

export const CHART_FONT_FAMILY =
  '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export const PopperModifiers = {
  flip: {
    enabled: true,
  },
  preventOverflow: {
    enabled: true,
    boundariesElement: 'scrollParent',
    padding: 50,
  },
};
