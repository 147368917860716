// @ts-nocheck
import { saveAs } from 'file-saver';

import { FILTER_ID_ALL } from '../../constants';
import { addPeriodToDate, DEFAULT_DATE_FROM, DEFAULT_DATE_TO } from '../../models/TimeRange';
import { LABEL_NOT_APPLICABLE, LABEL_OTHER } from '../Widgets/OpenEndedWidget/Data/lib';
import store from '../../store/store';

export const exportSummaryPlain = (
  overviewData,
  dataSourceIds,
  dataSources,
  dashboardDataSources,
  translationUsed,
  filters,
  selectedFilterIds,
  filename,
) => {
  const columns = ['Question', 'Filter', 'Answer', 'Number', 'Percent'];
  const table = getSummaryPlainTable(
    overviewData,
    dataSourceIds,
    dataSources,
    dashboardDataSources,
    translationUsed,
    filters,
    selectedFilterIds,
  );
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getSummaryPlainTable = (
  overviewData,
  dataSourceIds,
  dataSources,
  dashboardDataSources,
  translationUsed,
  filters,
  selectedFilterIds,
) => {
  const table = [];
  const responseField = translationUsed ? 'response' : 'originalResponse';
  const titleField = translationUsed ? 'title' : 'originalTitle';

  const dataSourcesById = dataSources.reduce((acc, dataSource) => ({ ...acc, [dataSource.dataSourceID]: dataSource }), {});
  const dashboardDataSourcesById = dashboardDataSources.reduce(
    (acc, dashboardDataSource) => ({
      ...acc,
      [dashboardDataSource.dataSourceID]: dashboardDataSource,
    }),
    {},
  );

  // prepare
  for (let index = 0; index < filters.length; index++) {
    const filter = filters[index];
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
      continue;
    }
    const questions = overviewData[index].items;
    for (const dataSourceID of dataSourceIds) {
      const question = questions.find((question) => question.id === dataSourceID);
      const dataSource = dataSourcesById[dataSourceID];
      const itemByResponse = question ? question.items.reduce((acc, item) => ({ ...acc, [item.response]: item }), {}) : {};
      for (const item of dataSource.items) {
        const result = itemByResponse[item.response];
        table.push({
          filterId: filter.id,
          filterName: filter.name,
          dataSourceID: dataSourceID,
          dataSourceTitle: dashboardDataSourcesById[dataSourceID][titleField],
          response: item.response,
          answer: item[responseField],
          number: result ? result.number : 0,
          percentage: result ? result.realPercent : 0,
        });
      }
    }
  }

  // sort
  const dataSourcesPositions = dataSourceIds.reduce((acc, dataSourceID, index) => ({ ...acc, [dataSourceID]: index }), {});
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const dataSourcesItemsPositions = dataSources.reduce((acc, dataSource) => {
    if (!dashboardDataSourcesById[dataSource.dataSourceID].ordered) {
      return acc;
    }
    const itemsPositions = dataSource.items.reduce((positions, item, index) => ({ ...positions, [item.response]: index }), {});
    return { ...acc, [dataSource.dataSourceID]: itemsPositions };
  }, {});
  table.sort((a, b) => {
    if (a.dataSourceID !== b.dataSourceID) {
      return dataSourcesPositions[a.dataSourceID] - dataSourcesPositions[b.dataSourceID];
    }
    if (a.filterId !== b.filterId) {
      return filtersPositions[a.filterId] - filtersPositions[b.filterId];
    }
    if (dataSourcesItemsPositions[a.dataSourceID]) {
      return dataSourcesItemsPositions[a.dataSourceID][b.response] - dataSourcesItemsPositions[a.dataSourceID][a.response];
    }
    return b.number - a.number;
  });

  return table.map((row) => [row.dataSourceTitle, row.filterName, row.answer, row.number, row.percentage]);
};

export const exportSummaryBreakdown = (
  filters,
  selectedFilterIds,
  breakdownData,
  breakdownItems,
  dataSourceIds,
  dataSources,
  dashboardDataSources,
  translationUsed,
  filename,
) => {
  const columns = ['Question', 'Filter', 'Breakdown value', 'Answer', 'Number', 'Percent'];

  const table = getSummaryBreakdownTable(
    filters,
    selectedFilterIds,
    breakdownData,
    breakdownItems,
    dataSourceIds,
    dataSources,
    dashboardDataSources,
    translationUsed,
  );
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getSummaryBreakdownTable = (
  filters,
  selectedFilterIds,
  breakdownData,
  breakdownItems,
  dataSourceIds,
  dataSources,
  dashboardDataSources,
  translationUsed,
) => {
  const table = [];

  const dataSourcesById = dataSources.reduce((acc, dataSource) => ({ ...acc, [dataSource.dataSourceID]: dataSource }), {});
  const dashboardDataSourcesById = dashboardDataSources.reduce(
    (acc, dashboardDataSource) => ({
      ...acc,
      [dashboardDataSource.dataSourceID]: dashboardDataSource,
    }),
    {},
  );

  const responseField = translationUsed ? 'response' : 'originalResponse';
  const titleField = translationUsed ? 'title' : 'originalTitle';

  for (const filter of filters) {
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
      continue;
    }
    const filterData = breakdownData[filter.id];
    for (const breakdownItem of breakdownItems) {
      const bucketData = filterData ? filterData[breakdownItem.response] : undefined;
      for (const dataSourceID of dataSourceIds) {
        const resultDataSource = bucketData ? bucketData[dataSourceID] : undefined;
        const dataSource = dataSourcesById[dataSourceID];
        const dashboardDataSource = dashboardDataSourcesById[dataSourceID];
        for (const item of dataSource.items) {
          const resultItem = resultDataSource
            ? resultDataSource.items.find((resultItem) => resultItem.response === item.response)
            : undefined;
          table.push({
            dataSourceID,
            dataSourceTitle: dashboardDataSource[titleField],
            filterId: filter.id,
            filterName: filter.name,
            breakdownResponse: breakdownItem.response,
            breakdownDisplayResponse: breakdownItem[responseField],
            label: item.response,
            labelTitle: item[responseField],
            number: resultItem ? resultItem.value : 0,
            percent: resultItem ? resultItem.percentage : 0,
          });
        }
      }
    }
  }

  // sort
  const dataSourcesPositions = dataSourceIds.reduce((acc, dataSourceID, index) => ({ ...acc, [dataSourceID]: index }), {});
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const dataSourcesItemsPositions = dataSources.reduce((acc, dataSource) => {
    if (!dashboardDataSourcesById[dataSource.dataSourceID].ordered) {
      return acc;
    }
    const itemsPositions = dataSource.items.reduce((positions, item, index) => ({ ...positions, [item.response]: index }), {});
    return { ...acc, [dataSource.dataSourceID]: itemsPositions };
  }, {});
  const breakdownPositions = breakdownItems.reduce(
    (acc, breakdownItem, index) => ({
      ...acc,
      [breakdownItem.response]: index,
    }),
    {},
  );
  table.sort((a, b) => {
    if (a.dataSourceID !== b.dataSourceID) {
      return dataSourcesPositions[a.dataSourceID] - dataSourcesPositions[b.dataSourceID];
    }
    if (a.filterId !== b.filterId) {
      return filtersPositions[a.filterId] - filtersPositions[b.filterId];
    }
    if (a.breakdownResponse !== b.breakdownResponse) {
      return breakdownPositions[a.breakdownResponse] - breakdownPositions[b.breakdownResponse];
    }
    if (dataSourcesItemsPositions[a.dataSourceID]) {
      return dataSourcesItemsPositions[a.dataSourceID][b.response] - dataSourcesItemsPositions[a.dataSourceID][a.response];
    }
    return b.number - a.number;
  });

  return table.map((row) => [row.dataSourceTitle, row.filterName, row.breakdownDisplayResponse, row.labelTitle, row.number, row.percent]);
};

export const exportTrendsPlain = (
  data,
  filters,
  groupBy,
  selectedItems,
  selectedFilterIds,
  translationUsed,
  weightedMetricsUsed,
  filename,
  isOpenEnded = false,
) => {
  const columns = ['Filter', 'Date', isOpenEnded ? 'Concept' : 'Value', 'Number', 'Percent'];
  const table = getTrendsPlainTable(data, filters, groupBy, selectedItems, selectedFilterIds, translationUsed, weightedMetricsUsed);
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getTrendsPlainTable = (data, filters, groupBy, selectedItems, selectedFilterIds, translationUsed, weightedMetricsUsed) => {
  const table = [];
  const responseField = translationUsed ? 'response' : 'originalResponse';
  const valueField = weightedMetricsUsed ? 'weighted' : 'base';

  const [minDate, maxDate] = extractTrendsTimeRange(data, selectedFilterIds);

  // prepare export data
  for (const filter of filters) {
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
      continue;
    }
    const filterData = data.find((row) => row.filterId === filter.id);
    for (const selectedItem of selectedItems) {
      const itemData = filterData ? filterData.items.find((item) => item.response === selectedItem.response) : undefined;
      let date = minDate;
      while (date <= maxDate) {
        const values = itemData ? itemData.buckets.find((bucket) => bucket.date === date) : undefined; // eslint-disable-line no-loop-func
        table.push({
          filterId: filter.id,
          filterName: filter.name,
          date: date,
          response: selectedItem.response,
          originalResponse: selectedItem.originalResponse,
          number: values ? values[valueField].value : 0,
          percent: values ? values[valueField].percentage : 0,
        });
        date = addPeriodToDate(date, groupBy);
      }
    }
  }

  // sort
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const itemsPositions = selectedItems.reduce((positions, item, index) => ({ ...positions, [item.response]: index }), {});
  table.sort((a, b) => {
    if (a.filterId !== b.filterId) {
      return filtersPositions[a.filterId] - filtersPositions[b.filterId];
    }
    if (a.date !== b.date) {
      return a.date.localeCompare(b.date);
    }
    return itemsPositions[b.response] - itemsPositions[a.response];
  });

  // return
  return table.map((row) => [row.filterName, row.date, row[responseField], row.number, row.percent]);
};

export const exportTrendsBreakdown = (
  data,
  breakdownItems,
  filters,
  groupBy,
  selectedItems,
  selectedFilterIds,
  translationUsed,
  weightedMetricsUsed,
  filename,
  isOpenEnded,
) => {
  const columns = ['Filter', 'Date', 'Breakdown value', isOpenEnded ? 'Concept' : 'Value', 'Number', 'Percent'];
  const table = getTrendsBreakdownTable(
    data,
    breakdownItems,
    filters,
    groupBy,
    selectedItems,
    selectedFilterIds,
    translationUsed,
    weightedMetricsUsed,
  );
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getTrendsBreakdownTable = (
  data,
  breakdownItems,
  filters,
  groupBy,
  selectedItems,
  selectedFilterIds,
  translationUsed,
  weightedMetricsUsed,
) => {
  const table = [];
  const responseField = translationUsed ? 'response' : 'originalResponse';
  const valueField = weightedMetricsUsed ? 'weighted' : 'base';

  const breakdownBucketsByResponse = breakdownItems.reduce(
    (acc, item) => ({
      ...acc,
      [item.response]: translationUsed ? item.response : item.originalResponse,
    }),
    {},
  );

  const [minDate, maxDate] = extractTrendsTimeRange(data, selectedFilterIds);

  // prepare export data
  for (const filter of filters) {
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
      continue;
    }
    for (const breakdownItem of breakdownItems) {
      const filterData = data.find((row) => row.filterId === filter.id && row.bucketName === breakdownItem.response);
      for (const selectedItem of selectedItems) {
        const itemData = filterData ? filterData.items.find((item) => item.response === selectedItem.response) : null;
        let date = minDate;
        while (date <= maxDate) {
          const values = itemData ? itemData.buckets.find((bucket) => bucket.date === date) : null; // eslint-disable-line no-loop-func
          table.push({
            filterId: filter.id,
            filterName: filter.name,
            breakdownResponse: breakdownItem.response,
            date: date,
            response: selectedItem.response,
            originalResponse: selectedItem.originalResponse,
            number: values ? values[valueField].value : 0,
            percent: values ? values[valueField].percentage : 0,
          });
          date = addPeriodToDate(date, groupBy);
        }
      }
    }
  }

  // sort
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const itemsPositions = selectedItems.reduce((positions, item, index) => ({ ...positions, [item.response]: index }), {});
  const breakdownPositions = breakdownItems.reduce(
    (acc, breakdownItem, index) => ({
      ...acc,
      [breakdownItem.response]: index,
    }),
    {},
  );
  table.sort((a, b) => {
    if (a.filterId !== b.filterId) {
      return filtersPositions[a.filterId] - filtersPositions[b.filterId];
    }
    if (a.date !== b.date) {
      return a.date.localeCompare(b.date);
    }
    if (a.breakdownResponse !== b.breakdownResponse) {
      return breakdownPositions[a.breakdownResponse] - breakdownPositions[b.breakdownResponse];
    }
    return itemsPositions[b.response] - itemsPositions[a.response];
  });

  // return
  return table.map((row) => [
    row.filterName,
    row.date,
    breakdownBucketsByResponse[row.breakdownResponse],
    row[responseField],
    row.number,
    row.percent,
  ]);
};

export const exportOpenEndedPlain = (
  overviewDataById,
  dataSource,
  filters,
  selectedFilterIds,
  filtersById,
  filename: string,
  labelToParent: { [key: string]: string } | null = null,
) => {
  const exportColumns = ['Parent concept', 'Child concept', 'Filter', 'Number', 'Percent'];
  return exportBarsPlain(overviewDataById, dataSource, filters, selectedFilterIds, filtersById, filename, labelToParent, exportColumns);
};

export const exportBarsPlain = (
  overviewDataById,
  dataSource,
  filters,
  selectedFilterIds,
  filtersById,
  filename,
  labelToParent = null,
  exportColumns = null,
) => {
  const defaultColumns = ['Answer', 'Filter', 'Number', 'Percent'];
  const columns = exportColumns ? exportColumns : defaultColumns;
  const table = getBarsPlainTable(overviewDataById, dataSource, filters, selectedFilterIds, filtersById, labelToParent);
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getBarsPlainTable = (overviewDataById, dataSource, filters, selectedFilterIds, filtersById, labelToParent) => {
  const table = [];

  for (const filter of filters) {
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
      continue;
    }
    const value = overviewDataById.get(filter.id);
    const model = value ? value.items[0] : undefined;
    for (const item of dataSource.items) {
      const modelItem = model ? model.items.find((modelItem) => modelItem.response === item.response) : undefined;
      if (labelToParent) {
        table.push({
          filterId: filter.id,
          filterName: filter.name,
          parent: labelToParent.hasOwnProperty(item.response) ? labelToParent[item.response] : item.response,
          label: labelToParent.hasOwnProperty(item.response) ? item.displayResponse : '',
          number: modelItem ? modelItem.number : 0,
          percent: modelItem ? modelItem.realPercent : 0,
        });
      } else {
        table.push({
          filterId: filter.id,
          filterName: filter.name,
          response: item.response,
          label: item.displayResponse,
          number: modelItem ? modelItem.number : 0,
          percent: modelItem ? modelItem.realPercent : 0,
        });
      }
    }
  }

  // sort
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const baseFilterId = selectedFilterIds.length === 1 ? selectedFilterIds[0] : FILTER_ID_ALL;
  const baseDataSource = overviewDataById.get(baseFilterId).items[0];
  const responseNumberPairs = [];
  for (const item of baseDataSource.items) {
    responseNumberPairs.push([item.response, item.number]);
  }
  responseNumberPairs.sort((a, b) => b[1] - a[1]);
  const itemPositions = responseNumberPairs.reduce((acc, item, index) => ({ ...acc, [item[0]]: index }), {});

  if (labelToParent) {
    table.sort((a, b) => {
      if (a.parent !== b.parent) {
        if (a.parent === LABEL_NOT_APPLICABLE || b.parent === LABEL_NOT_APPLICABLE) {
          return a.parent === LABEL_NOT_APPLICABLE ? 1 : -1;
        }
        if (a.parent === LABEL_OTHER || b.parent === LABEL_OTHER) {
          return a.parent === LABEL_OTHER ? 1 : -1;
        }
        return (
          (itemPositions.hasOwnProperty(a.parent) ? itemPositions[a.parent] : Number.MAX_SAFE_INTEGER) -
          (itemPositions.hasOwnProperty(b.parent) ? itemPositions[b.parent] : Number.MAX_SAFE_INTEGER)
        );
      } else {
        if (a.label === '' && b.label === '') {
          return filtersPositions[a.filterId] - filtersPositions[b.filterId];
        }
        if (a.label === '') {
          return -1;
        }
        if (b.label === '') {
          return 1;
        }
        return (
          (itemPositions.hasOwnProperty(a.label) ? itemPositions[a.label] : Number.MAX_SAFE_INTEGER) -
          (itemPositions.hasOwnProperty(b.label) ? itemPositions[b.label] : Number.MAX_SAFE_INTEGER)
        );
      }
    });
  } else {
    table.sort((a, b) => {
      if (a.label !== b.label) {
        return (
          (itemPositions.hasOwnProperty(a.response) ? itemPositions[a.response] : Number.MAX_SAFE_INTEGER) -
          (itemPositions.hasOwnProperty(b.response) ? itemPositions[b.response] : Number.MAX_SAFE_INTEGER)
        );
      }
      return filtersPositions[a.filterId] - filtersPositions[b.filterId];
    });
  }

  if (labelToParent) {
    return table.map((row) => [row.parent, row.label, row.filterName, row.number, row.percent]);
  } else {
    return table.map((row) => [row.label, row.filterName, row.number, row.percent]);
  }
};

export const exportBarsBreakdown = (breakdownData, dataSource, breakdownItems, filters, selectedFilterIds, translationUsed, filename) => {
  const columns = ['Answer', 'Breakdown value', 'Filter', 'Number', 'Percent'];
  const table = getBarsBreakdownTable(breakdownData, dataSource, breakdownItems, filters, selectedFilterIds, translationUsed);
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getBarsBreakdownTable = (breakdownData, dataSource, breakdownItems, filters, selectedFilterIds, translationUsed) => {
  const table = [];

  const responseField = translationUsed ? 'response' : 'originalResponse';
  const baseFilterId = selectedFilterIds.length === 1 ? selectedFilterIds[0] : FILTER_ID_ALL;
  const responseToValue = new Map();

  // prepare
  for (const item of dataSource.items) {
    const itemBuckets = breakdownData.get(item.response);
    for (const breakdownItem of breakdownItems) {
      const filtersValues = itemBuckets ? itemBuckets.get(breakdownItem.response) : undefined;
      const baseValue = filtersValues.find((filterValue) => filterValue.filterId === baseFilterId);
      responseToValue.set(
        item.response,
        responseToValue.has(item.response) ? Math.max(responseToValue.get(item.response), baseValue.value) : baseValue.value,
      );

      for (const filter of filters) {
        if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
          continue;
        }
        const filterValue = filtersValues ? filtersValues.find((filterValue) => filterValue.filterId === filter.id) : undefined;
        table.push({
          filterName: filter.name,
          filterId: filter.id,
          bucket: breakdownItem.response,
          bucketTitle: breakdownItem[responseField],
          response: item.response,
          displayResponse: item[responseField],
          number: filterValue.value,
          percent: filterValue.percentage,
        });
      }
    }
  }
  // sort
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const responseValuePairs = [...responseToValue.entries()].sort((a, b) => b[1] - a[1]);
  const responsePositions = responseValuePairs.reduce((acc, item, index) => ({ ...acc, [item[0]]: index }), {});
  const breakdownPositions = breakdownItems.reduce(
    (acc, breakdownItem, index) => ({
      ...acc,
      [breakdownItem.response]: index,
    }),
    {},
  );
  table.sort((a, b) => {
    if (a.response !== b.response) {
      return (
        (responsePositions.hasOwnProperty(a.response) ? responsePositions[a.response] : Number.MAX_SAFE_INTEGER) -
        (responsePositions.hasOwnProperty(b.response) ? responsePositions[b.response] : Number.MAX_SAFE_INTEGER)
      );
    }
    if (a.bucket !== b.bucket) {
      return breakdownPositions[a.bucket] - breakdownPositions[b.bucket];
    }
    return filtersPositions[a.filterId] - filtersPositions[b.filterId];
  });

  return table.map((row) => [row.displayResponse, row.bucketTitle, row.filterName, row.number, row.percent]);
};

export const exportOpenEndedBreakdown = (
  breakdownData,
  filters,
  selectedFilterIds,
  breakdownItems,
  dataSource,
  translationUsed,
  labelToParent: { [key: string]: string },
  filename,
) => {
  const columns = ['Parent concept', 'Breakdown value', 'Child concept', 'Filter', 'Number', 'Percent'];
  const table = getOpenEndedBreakdownTable(
    breakdownData,
    filters,
    selectedFilterIds,
    breakdownItems,
    dataSource,
    translationUsed,
    labelToParent,
  );
  const body = prepareBody(columns, table);
  saveCsv(body, filename);
};

const getOpenEndedBreakdownTable = (
  breakdownData,
  filters,
  selectedFilterIds,
  breakdownItems,
  dataSource,
  translationUsed,
  labelToParent,
) => {
  const table = [];

  const responseField = translationUsed ? 'response' : 'originalResponse';
  const baseFilterId = selectedFilterIds.length === 1 ? selectedFilterIds[0] : FILTER_ID_ALL;
  const responseToValue = new Map();

  for (const filter of filters) {
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(filter.id)) {
      continue;
    }
    const filterData = breakdownData[filter.id];
    for (const breakdownItem of breakdownItems) {
      const bucketData = filterData ? filterData[breakdownItem.response] : undefined;
      const resultDataSource = bucketData ? bucketData[dataSource.dataSourceID] : undefined;
      for (const item of dataSource.items) {
        const resultItem = resultDataSource
          ? resultDataSource.items.find((resultItem) => resultItem.response === item.response)
          : undefined;
        const resultValue = resultItem ? resultItem.value : 0;
        if (filter.id === baseFilterId) {
          responseToValue.set(
            item.response,
            responseToValue.has(item.response) ? Math.max(responseToValue.get(item.response), resultValue) : resultValue,
          );
        }
        table.push({
          filterId: filter.id,
          filterName: filter.name,
          breakdownResponse: breakdownItem.response,
          breakdownDisplayResponse: breakdownItem[responseField],
          parent: labelToParent.hasOwnProperty(item.response) ? labelToParent[item.response] : item[responseField],
          label: labelToParent.hasOwnProperty(item.response) ? item.response : '',
          labelTitle: labelToParent.hasOwnProperty(item.response) ? item[responseField] : '',
          number: resultValue,
          percent: resultItem ? resultItem.percentage : 0,
        });
      }
    }
  }

  // sort
  const filtersPositions = filters.reduce((acc, filter, index) => ({ ...acc, [filter.id]: index }), {});
  const responseValuePairs = [...responseToValue.entries()].sort((a, b) => b[1] - a[1]);
  const responsePositions = responseValuePairs.reduce((acc, item, index) => ({ ...acc, [item[0]]: index }), {});
  const breakdownPositions = breakdownItems.reduce(
    (acc, breakdownItem, index) => ({
      ...acc,
      [breakdownItem.response]: index,
    }),
    {},
  );
  table.sort((a, b) => {
    if (a.parent !== b.parent) {
      if (a.parent === LABEL_NOT_APPLICABLE || b.parent === LABEL_NOT_APPLICABLE) {
        return a.parent === LABEL_NOT_APPLICABLE ? 1 : -1;
      }
      if (a.parent === LABEL_OTHER || b.parent === LABEL_OTHER) {
        return a.parent === LABEL_OTHER ? 1 : -1;
      }
      return (
        (responsePositions.hasOwnProperty(a.parent) ? responsePositions[a.parent] : Number.MAX_SAFE_INTEGER) -
        (responsePositions.hasOwnProperty(b.parent) ? responsePositions[b.parent] : Number.MAX_SAFE_INTEGER)
      );
    } else {
      if (a.breakdownResponse !== b.breakdownResponse) {
        return breakdownPositions[a.breakdownResponse] - breakdownPositions[b.breakdownResponse];
      }
      if (a.label === '' && b.label === '') {
        return filtersPositions[a.filterId] - filtersPositions[b.filterId];
      }
      if (a.label === '') {
        return -1;
      }
      if (b.label === '') {
        return 1;
      }

      if (a.label !== b.label) {
        return (
          (responsePositions.hasOwnProperty(a.label) ? responsePositions[a.label] : Number.MAX_SAFE_INTEGER) -
          (responsePositions.hasOwnProperty(b.label) ? responsePositions[b.label] : Number.MAX_SAFE_INTEGER)
        );
      }
      return filtersPositions[a.filterId] - filtersPositions[b.filterId];
    }
  });

  return table.map((row) => [row.parent, row.breakdownDisplayResponse, row.labelTitle, row.filterName, row.number, row.percent]);
};

/*** Utils ***/
const extractTrendsTimeRange = (data, selectedFilterIds) => {
  let minDate = DEFAULT_DATE_TO;
  let maxDate = DEFAULT_DATE_FROM;
  for (const row of data) {
    if (!(selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL) && !selectedFilterIds.includes(row.filterId)) {
      continue;
    }
    for (const item of row.items) {
      for (const bucket of item.buckets) {
        if (bucket.date > maxDate) {
          maxDate = bucket.date;
        } else if (bucket.date < minDate) {
          minDate = bucket.date;
        }
      }
    }
  }

  return [minDate, maxDate];
};

export const prepareBody = (columns, table) => {
  return [columns, ...table]
    .map((row) =>
      row
        .map((value) => {
          switch (typeof value) {
            case 'string':
              const escapedValue = value.replace(/"/g, '""');
              return escapedValue.length === 0 ? '' : `"${escapedValue}"`;
            default:
              return value;
          }
        })
        .join(','),
    )
    .join('\n');
};

export const saveCsv = (body, filename, addPostfix = true) => {
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), body], {
    type: 'text/csv;charset=utf-8',
  });
  const state = store.getState();
  const postfix = addPostfix && state.app.weightedMetricsUsed ? ' - weighted' : '';
  saveAs(blob, `${filename}${postfix}.csv`);
};

export const savePng = (blob, filename, addPostfix = true) => {
  const state = store.getState();
  const postfix = addPostfix && state.app.weightedMetricsUsed ? ' - weighted' : '';
  saveAs(blob, `${filename}${postfix}.png`);
};

export const getTimestampString = () => new Date().toISOString().split('.').shift().replace('T', ' ').replace(/:/gi, '.');
