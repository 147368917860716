import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';

import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { OutlinedButton } from '../../../UI/Button';
import { dashboardCreateWidgetGroup } from '../../../../store/thunks/dashboard';

const AddGroup = ({ onClose }) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const handleSave = () => {
    eventsTracker.track(EVENTS.ADD_NEW_GROUP);
    dispatch(dashboardCreateWidgetGroup(value.trim()));
    onClose();
  };

  const disabled = value.trim().length === 0;

  return (
    <Grid container direction='column' style={{ height: '100%' }}>
      <Grid item style={{ flex: 1 }} xs={11}>
        <TextField
          value={value}
          onChange={(event) => setValue(event.target.value)}
          size='small'
          variant='outlined'
          fullWidth
          placeholder='Name of group…'
        />
      </Grid>
      <Grid item style={{ marginTop: '10px', textAlign: 'right' }}>
        <OutlinedButton data-testid='save-button' disabled={disabled} onClick={handleSave}>
          Add
        </OutlinedButton>
      </Grid>
    </Grid>
  );
};

export default AddGroup;
