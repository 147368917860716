import React, { useEffect, useRef, useState } from 'react';
import ShowMoreText from 'react-show-more-text';

interface IExpandableText {
  children: React.ReactElement | string;
  linesToShow?: number;
  showMoreLabel?: string;
  showLessLabel?: string;
  truncatedEnding?: string;
  wrapperClassName?: string;
  expandableButtonClassName?: string;
  onToggleExpand?: (isExpanded: boolean) => void;
}

function ExpandableText({
  children,
  linesToShow = 3,
  showMoreLabel = 'More',
  showLessLabel = 'Less',
  truncatedEnding = '… ',
  wrapperClassName,
  expandableButtonClassName,
  onToggleExpand,
}: IExpandableText) {
  const [isExpanded, setIsExpanded] = useState(false);
  const showMoreTextRef = useRef(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (onToggleExpand && showMoreTextRef?.current) {
      // Timeout is required to do virtuallized list to catch the rendered component size
      // We need approximate delay of about 100 ms to have some time to clear the interval if the component was
      // scrolled away from screen, thus it doesn't appear in the DOM.
      timeout = setTimeout(() => onToggleExpand(isExpanded), 100);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showMoreTextRef, isExpanded, onToggleExpand]);

  function handleExpandClick(isExpanded: boolean) {
    setIsExpanded(isExpanded);
    if (onToggleExpand) {
      setTimeout(() => onToggleExpand(isExpanded));
    }
  }

  return (
    <ShowMoreText
      ref={showMoreTextRef}
      lines={linesToShow}
      more={showMoreLabel}
      less={showLessLabel}
      className={wrapperClassName}
      anchorClass={expandableButtonClassName}
      onClick={handleExpandClick}
      expanded={isExpanded}
      truncatedEndingComponent={truncatedEnding}
    >
      {children}
    </ShowMoreText>
  );
}

export default ExpandableText;
