import { useContext, useEffect, useMemo, useState } from 'react';

import { FETCH_STATUS } from '../../constants';
import { getTotalsByDataSourcesAndFiltersIds, SummaryDataContext } from '../../contexts/SummaryDataContext';
import { WidgetTotalsContext } from '../../components/Widgets/WidgetTotalsContext';
import { getSummariesWithQuery } from '../../contexts/lib';

/**
 * It's used to get plain summary data from the prefetched results
 *
 * Accepts:
 * - dashboardId: dashboard id
 * - filters: list of applied filters
 * - visibleDataSourcesIds: list of data source ids
 * - query: optional widget level filter
 * - skipFetch: optional flag to skip fetch on update
 * Returns:
 * - responses: array of fetched results
 * - status: fetch status (see FETCH_STATUS);
 */
const useSummaryPlainFetch = (dashboardId, filters, visibleDataSourcesIds, query = null, skipFetch = false) => {
  const { status: baseStatus, data } = useContext(SummaryDataContext);
  const { setTotals } = useContext(WidgetTotalsContext);
  const [value, setValue] = useState({
    status: FETCH_STATUS.IDLE,
    responses: [],
  });

  const baseResponses = useMemo(() => {
    return data.map((filterData) => filterData.filter((row) => visibleDataSourcesIds.includes(row.dataSourceID)));
  }, [data, visibleDataSourcesIds]);

  useEffect(() => {
    if (!query || skipFetch) {
      setValue({
        status: baseStatus,
        responses: baseResponses,
      });
    }
  }, [baseStatus, baseResponses, query, skipFetch]);

  useEffect(() => {
    if (!query || skipFetch) {
      return;
    }
    let mounted = true;

    const fetchData = async () => {
      setValue({ status: FETCH_STATUS.LOADING, responses: [] });
      const responses = await getSummariesWithQuery(dashboardId, visibleDataSourcesIds, filters, query);
      if (mounted) {
        setValue({
          responses,
          status: FETCH_STATUS.LOADED,
        });
        setTotals(getTotalsByDataSourcesAndFiltersIds(responses, filters));
      }
    };
    fetchData();

    return () => {
      mounted = false;
    };
  }, [filters, visibleDataSourcesIds, dashboardId, query, skipFetch, setTotals]);

  return value;
};

export default useSummaryPlainFetch;
