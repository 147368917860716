import { JsonModel } from './JsonModel';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { TRENDS_DATE_FORMAT, DEFAULT_DATE_FORMAT } from './TimeRange';

const expSymbols = 0;

export class TimelineItemModel extends JsonModel {
  constructor(answer = '', buckets = []) {
    super();

    this.answer = answer || '';

    /**
     * @type {TimelineBucketItemModel[]}
     */
    this.buckets = buckets || [];
  }

  fromJson(_json) {
    super.fromJson(_json);
    this.answer = _json['value'] || '';
    this.buckets = (_json['buckets'] || []).map(TimelineBucketItemModelFactory);
    return this;
  }
}

export class TimelineBucketItemModel extends JsonModel {
  constructor(date = '', percent = 0.0, number = 0) {
    super();

    this.date = date;
    this.realPercent = percent;
    this.percent = parseFloat(percent.toFixed(expSymbols));
    this.number = number;
  }

  fromJson(_json) {
    super.fromJson(_json);
    const pct = parseFloat(_json['size']) || 0;
    this.date = _json['date'] ? format(parse(_json['date'], TRENDS_DATE_FORMAT, new Date()), DEFAULT_DATE_FORMAT) : '';
    this.realPercent = pct;
    this.percent = parseFloat(pct.toFixed(expSymbols));
    this.number = _json['value'] || 0;
    return this;
  }
}

export const TimelineItemModelFactory = (_json) => {
  return new TimelineItemModel().fromJson(_json);
};
export const TimelineBucketItemModelFactory = (_json) => {
  return new TimelineBucketItemModel().fromJson(_json);
};
