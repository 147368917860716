import styled from 'styled-components';
import { Table, TableCell, TableHead } from '@material-ui/core';

import { ReactComponent as CloseIcon } from '../../assets/icons/cross-icon.svg';

import { CONNECTORS_COLUMN, CONNECTORS_COLUMN_STYLE } from './types';
import { OutlinedButton } from '../UI/Button';

export const getColumnStyles = (column: CONNECTORS_COLUMN): CONNECTORS_COLUMN_STYLE => ({
  width: column.width,
  minWidth: column.width,
  maxWidth: column.width,
  textAlign: column.align,
});

export const StyledConnectionCell = styled(TableCell)<{
  $colProps: CONNECTORS_COLUMN_STYLE;
}>`
  && {
    ${({ $colProps: { width, maxWidth, minWidth, textAlign } }) => `
            width: ${width};
            max-width: ${maxWidth};
            min-width: ${minWidth};
            text-align: ${textAlign};
        `}
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    position: sticky;
    top: 0;
    background: white;
    opacity: 1;
    z-index: 1;
  }
`;

export const StyledTable = styled(Table)`
  && {
    overflow: auto;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
`;

export const StyledIntegrationTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const StyledIntegrationAddButton = styled(OutlinedButton)`
  flex: 0 0 auto;
`;
