import styled from 'styled-components';
import { Typography, Tabs, Tab } from '@material-ui/core';

import { Integration, SurveySourceEnum } from '../../types/connections';

import CSVTabContainter from './Tabs/CSV/CSVTabContainter';
import FileUploadDialogContainer from './AddCSV/FileUploadDialogContainer';
import IntegrationsTabContainer from './Tabs/Integrations/IntegrationsTabContainer';
import AddConnectionDialogContainer from './AddConnection/AddConnectionDialogContainer';
import { TabEnum } from './types';
import { TAB_TO_LABEL } from './utils';

const TOP_PADDING = 40;
const BOTTOM_PADDING = 60;

const Container = styled.div`
  padding: ${TOP_PADDING}px 60px ${BOTTOM_PADDING}px 60px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  && {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const StyledTabs = styled(Tabs)``;

const Content = styled.div`
  margin-top: 20px;
  height: 100%;
`;

interface IConnectorsTab {
  selectedTab: TabEnum;
  shouldOpenFileUpload: boolean;
  handleTabChange: (event: React.ChangeEvent<object>, value: TabEnum) => void;
  handleAddConnection: (source: SurveySourceEnum | null) => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string | null) => void;
  handleEditAuthentication: (integration: Integration) => void;
  handleFileAdd: (fileAdded?: boolean) => void;
}

function ConnectorsTab({
  selectedTab,
  shouldOpenFileUpload,
  handleTabChange,
  handleAddConnection,
  handleEnrich,
  handleEditAuthentication,
  handleFileAdd,
}: IConnectorsTab) {
  return (
    <Container>
      <Title variant='h5'>Connect with Beehive AI</Title>
      <StyledTabs value={selectedTab} onChange={handleTabChange} indicatorColor='primary' textColor='primary'>
        <Tab data-testid='connectors-tab-integrations' label={TAB_TO_LABEL[TabEnum.INTEGRATIONS]} value={TabEnum.INTEGRATIONS} />
        <Tab data-testid='connectors-tab-csv' label={TAB_TO_LABEL[TabEnum.CSV]} value={TabEnum.CSV} />
      </StyledTabs>
      <Content data-testid='connectors-tab-container'>
        {selectedTab === TabEnum.INTEGRATIONS && (
          <IntegrationsTabContainer
            handleAddConnection={handleAddConnection}
            handleEnrich={handleEnrich}
            handleEditAuthentication={handleEditAuthentication}
          />
        )}
        {selectedTab === TabEnum.CSV && (
          <CSVTabContainter handleAdd={handleAddConnection.bind(null, SurveySourceEnum.CSV)} handleEnrich={handleEnrich} />
        )}
      </Content>
      <FileUploadDialogContainer isOpen={shouldOpenFileUpload} onClose={handleFileAdd} />
      <AddConnectionDialogContainer />
    </Container>
  );
}

export default ConnectorsTab;
