import styled from 'styled-components';

interface IAvatar {
  name: string;
  color?: string;
}

const AvatarContainer = styled.div<{ $color: string }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.$color};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  text-transform: uppercase;
`;

function Avatar({ name, color = 'var(--avatar-default)' }: IAvatar) {
  return <AvatarContainer $color={color}>{GetInitials(name)}</AvatarContainer>;
}

function GetInitials(name: string) {
  if (name) {
    const names = name.trim().split(' ');
    if (names.length === 0) {
      return '';
    }

    if (names.length === 1) {
      return names[0][0];
    }

    return names[0][0] + names[names.length - 1][0];
  }

  return '';
}

export default Avatar;
