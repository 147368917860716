import cloneDeep from 'lodash/cloneDeep';

import {
  COMPARE_ACTION_TYPES,
  SetDashboardContextAction,
  resetThresholds,
  setDataSourceIds,
  setPage,
  setRowsPerPage,
  setSegmentsMinMax,
  setSelectedRowIds,
  setSelectedSegmentIds,
  setSort,
  setThresholds,
  setView,
} from './actions';
import { CompareView, SEGMENT_ID_ANY, SortField } from '../constants';
import { SegmentsMinMax, SortDirection, Thresholds } from '../compare';
import { DashboardDataSource } from '../../../types';

type CompareAction = SetDashboardContextAction &
  ReturnType<typeof setThresholds> &
  ReturnType<typeof resetThresholds> &
  ReturnType<typeof setSort> &
  ReturnType<typeof setPage> &
  ReturnType<typeof setRowsPerPage> &
  ReturnType<typeof setDataSourceIds> &
  ReturnType<typeof setSelectedSegmentIds> &
  ReturnType<typeof setSegmentsMinMax> &
  ReturnType<typeof setSelectedRowIds> &
  ReturnType<typeof setView>;

export interface ICompareState {
  data: {
    thresholds: Thresholds;
    sort: {
      field: SortField;
      direction: SortDirection;
    };
    dataSourceIDs: string[];
    selectedSegmentIDs: string[];
    dashboardId: string;
    segmentsMinMax: SegmentsMinMax;
    selectedRowIDs: string[];
  };
  table: {
    page: number;
    rowsPerPage: number;
  };
  view: CompareView;
}

const defaultThresholds: Thresholds = {
  dataSourceTotal: undefined,
  differenceAbsolute: undefined,
  differencePercentage: undefined,
  pValue: undefined,
};

const initialState: ICompareState = {
  data: {
    thresholds: defaultThresholds,
    sort: {
      field: SortField.DIFFERENCE_PERCENTAGE,
      direction: 'desc',
    },
    dataSourceIDs: [],
    selectedSegmentIDs: [],
    dashboardId: '',
    segmentsMinMax: {
      min: SEGMENT_ID_ANY,
      max: SEGMENT_ID_ANY,
    },
    selectedRowIDs: [],
  },
  table: {
    page: 0,
    rowsPerPage: 10,
  },
  view: CompareView.COMBINED,
};

const reducer = (state: ICompareState = initialState, action: CompareAction) => {
  switch (action.type) {
    case COMPARE_ACTION_TYPES.SET_DASHBOARD_CONTEXT: {
      return handleSetDashboardContext(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_THRESHOLD: {
      return handleSetThresholds(state, action);
    }
    case COMPARE_ACTION_TYPES.RESET_THRESHOLD: {
      return handleResetThresholds(state);
    }
    case COMPARE_ACTION_TYPES.SET_SORT: {
      return handleSetSort(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_PAGE: {
      return handleSetPage(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_ROWS_PER_PAGE: {
      return handleSetRowsPerPage(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_DATA_SOURCE_IDS: {
      return handleSetDataSourceIDs(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_SELECTED_SEGMENT_IDS: {
      return handleSetSelectedSegmentIDs(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_SEGMENTS_MIN_MAX: {
      return handleSetSegmentsMinMax(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_SELECTED_ROW_IDS: {
      return handleSetSelectedRowIDs(state, action);
    }
    case COMPARE_ACTION_TYPES.SET_VIEW: {
      return handleSetView(state, action);
    }
    default:
      return state;
  }
};

function handleSetDashboardContext(state: ICompareState, action: SetDashboardContextAction): ICompareState {
  const { dashboardId, dataSources } = action.payload;
  if (state.data.dashboardId !== dashboardId) {
    const visibleDataSources: DashboardDataSource[] = dataSources.filter((dataSource: DashboardDataSource) => dataSource.visible);
    const dataSourceIDs = visibleDataSources.map((dataSource) => dataSource.dataSourceID);
    return {
      ...cloneDeep(initialState),
      data: {
        ...cloneDeep(initialState.data),
        dashboardId,
        dataSourceIDs,
      },
    };
  }
  return state;
}

function handleSetThresholds(state: ICompareState, action: ReturnType<typeof setThresholds>): ICompareState {
  const { thresholds } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      thresholds,
    },
  };
}

function handleResetThresholds(state: ICompareState): ICompareState {
  return {
    ...state,
    data: {
      ...state.data,
      thresholds: defaultThresholds,
    },
  };
}

function handleSetSort(state: ICompareState, action: ReturnType<typeof setSort>): ICompareState {
  const { field, direction } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      sort: {
        field,
        direction,
      },
    },
  };
}

function handleSetPage(state: ICompareState, action: ReturnType<typeof setPage>): ICompareState {
  const { page } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      page,
    },
  };
}

function handleSetRowsPerPage(state: ICompareState, action: ReturnType<typeof setRowsPerPage>): ICompareState {
  const { rowsPerPage } = action.payload;
  return {
    ...state,
    table: {
      ...state.table,
      rowsPerPage,
    },
  };
}

function handleSetDataSourceIDs(state: ICompareState, action: ReturnType<typeof setDataSourceIds>): ICompareState {
  const { dataSourceIDs } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      dataSourceIDs,
    },
  };
}

function handleSetSelectedSegmentIDs(state: ICompareState, action: ReturnType<typeof setSelectedSegmentIds>): ICompareState {
  const { selectedSegmentIDs } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      selectedSegmentIDs,
    },
  };
}

function handleSetSegmentsMinMax(state: ICompareState, action: ReturnType<typeof setSegmentsMinMax>): ICompareState {
  const { segmentsMinMax } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      segmentsMinMax,
    },
  };
}

function handleSetSelectedRowIDs(state: ICompareState, action: ReturnType<typeof setSelectedRowIds>): ICompareState {
  const { selectedRowIDs } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      selectedRowIDs,
    },
  };
}

function handleSetView(state: ICompareState, action: ReturnType<typeof setView>): ICompareState {
  const { view } = action.payload;
  return {
    ...state,
    view,
  };
}

export default reducer;
