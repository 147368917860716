import FormControl from '@material-ui/core/FormControl';

import LabelsAutocompleteControl from '../../UI/LabelsAutocompleteControl/LabelsAutocompleteControl';

export const MaterialAutoComplete = ({
  className,
  handleOnChange,
  options,
  value,
  disabled,
  title,
  // Props that should not be in extraProps
  testID: _testID,
  rules: _rules,
  level: _level,
  path: _path,
  context: _context,
  validation: _validation,
  operator: _operator,
  field: _field,
  fieldData: _fieldData,
}) => {
  return (
    <FormControl variant='outlined' className={className} title={title} disabled={disabled} size='small'>
      <LabelsAutocompleteControl
        options={options}
        handleOnChange={handleOnChange}
        value={value}
        testID={_testID}
        style={{ width: '400px' }}
      />
    </FormControl>
  );
};

MaterialAutoComplete.displayName = 'MaterialAutoComplete';
