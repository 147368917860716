import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import palette from '../../tools/palette';
import { numberWithCommas } from '../../tools';

const CircularProgressFront = styled(CircularProgress)`
  position: absolute;
  left: 0;
  .MuiCircularProgress-circle {
    stroke: ${palette.primaryColorLight};
  }
`;

const CircularProgressBack = styled(CircularProgress)`
  .MuiCircularProgress-circle {
    stroke: ${palette.dividerColor};
  }
`;

const CustomersGrid = styled(Grid)`
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
`;

const TotalGrid = styled(Grid)`
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  font-size: 24px;
`;

const PercentageBox = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

const CIRCULAR_PROGRESS_SIZE = 160;

export const SegmentInfoPaper = styled(Paper)`
  padding: 20px;
  width: 200px;
`;

interface IFilterSegmentInfo {
  percentage: number;
  filteredTotal: number;
  isLoading: boolean;
}
const FilterSegmentInfo = ({ filteredTotal, percentage, isLoading }: IFilterSegmentInfo) => {
  return (
    <Grid data-testid='filter-segment-info' container direction='column' style={{ width: `${CIRCULAR_PROGRESS_SIZE}px` }}>
      <CustomersGrid item>Your Customers</CustomersGrid>
      <Grid item>
        <Box position='relative' width={CIRCULAR_PROGRESS_SIZE} height={CIRCULAR_PROGRESS_SIZE}>
          <CircularProgressBack variant='determinate' thickness={3} value={100} size={CIRCULAR_PROGRESS_SIZE} />
          <CircularProgressFront
            variant={isLoading ? 'indeterminate' : 'determinate'}
            thickness={3}
            value={percentage}
            size={CIRCULAR_PROGRESS_SIZE}
          />
          <PercentageBox data-testid='segment-info-percentage'>{percentage}%</PercentageBox>
        </Box>
      </Grid>
      <TotalGrid item data-testid='filtered-total'>
        {isLoading ? 'Loading…' : numberWithCommas(filteredTotal)}
      </TotalGrid>
    </Grid>
  );
};

export default FilterSegmentInfo;
