import { ICompareRow, MarkPointDataByFilterId } from '../compare';
import { CHART_FONT_FAMILY } from '../constants';
import palette from '../../../components/tools/palette';

export function prepareChartData(pageRows: ICompareRow[], orderedFilterIds: string[]) {
  const markPointDataByFilterId: MarkPointDataByFilterId = {};
  const data = [];

  for (let i = 0; i < pageRows.length; i++) {
    const row = pageRows[i];
    const dataRow: Array<number | string> = [row.id];
    const valueByFilterId = new Map();
    for (const subRow of row.data) {
      valueByFilterId.set(subRow.filterMaxID, {
        p: subRow.filterMaxPercentage,
        v: subRow.filterMaxValue,
      });
      valueByFilterId.set(subRow.filterMinID, {
        p: subRow.filterMinPercentage,
        v: subRow.filterMinValue,
      });
    }
    for (const filterId of orderedFilterIds) {
      if (!markPointDataByFilterId[filterId]) {
        markPointDataByFilterId[filterId] = [];
      }
      if (valueByFilterId.has(filterId)) {
        dataRow.push(valueByFilterId.get(filterId).p);
        markPointDataByFilterId[filterId].push({
          name: valueByFilterId.get(filterId).v,
          coord: [i, 106],
        });
      } else {
        dataRow.push(0);
        markPointDataByFilterId[filterId].push({ name: 0, coord: [i, 106] });
      }
    }
    data.push(dataRow);
  }

  return { data, markPointDataByFilterId };
}

export function prepareChartSeries(
  orderedFilterIds: string[],
  filtersById: Record<string, any>,
  markPointDataByFilterId: MarkPointDataByFilterId,
) {
  const series = [];
  for (const filterId of orderedFilterIds) {
    if (!filtersById[filterId]) {
      continue;
    }
    series.push({
      type: 'bar',
      barMaxWidth: 50,
      backgroundStyle: {
        color: palette.lightGray,
      },
      itemStyle: {
        color: filtersById[filterId].color,
      },

      label: {
        formatter: function (value: { componentIndex: number; data: number[] }) {
          return Math.round(value.data[value.componentIndex + 1]) + '%';
        },
        color: '#fff',
        fontFamily: CHART_FONT_FAMILY,
        fontWeight: 'bold',
        position: 'insideBottom',
        show: true,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderRadius: 2,
        padding: [2, 4],
      },
      markPoint: {
        symbolSize: 1,
        symbolOffset: [0, 0],
        silent: true,
        label: {
          show: true,
          formatter: function (point: { name: string }) {
            return point.name;
          },
          color: palette.textGray,
          fontFamily: CHART_FONT_FAMILY,
          fontSize: 14,
        },
        data: markPointDataByFilterId[filterId],
      },
    });
  }
  return series;
}
