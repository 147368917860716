import { v4 } from 'uuid';

import { TRENDS_DISPLAY_MODE } from '../../Widgets/TrendsWidget/Settings/TrendsWidgetSettings';
import { createWidget, createWidgetGroup, updateWidgetGroup } from '../../tools/api';
import { PERIOD, WIDGET_TYPE } from '../../../constants';

export const DEFAULT_GROUP_TITLE = 'Default';

const generateWidgetConfig = (config, dashboardId, dashboardDataSources) => {
  const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === config.dataSourceId);

  let widgetSettings = {
    dataSources: [
      {
        id: config.dataSourceId,
        selectedItems: [],
      },
    ],
  };

  switch (config.type) {
    case WIDGET_TYPE.TRENDS:
      widgetSettings = {
        dataSources: [
          {
            id: config.dataSourceId,
            selectedItems: config.meta.items,
          },
        ],
        displayMode: TRENDS_DISPLAY_MODE.PERCENTAGE,
        aggregationRange: PERIOD.MONTH,
        merge: false,
      };
      break;
    case WIDGET_TYPE.OPEN_ENDED:
      widgetSettings = {
        ...widgetSettings,
        showResponses: true,
        showStatus: true,
        showFilters: true,
        detailed: true,
      };
      break;
    default:
    // do nothing
  }

  return {
    base: {
      dashboardID: dashboardId,
      title: dashboardDataSource.title,
      originalTitle: dashboardDataSource.originalTitle,
      type: config.type,
      collapsed: false,
      expanded: false,
      maximized: false,
    },
    settings: widgetSettings,
  };
};

const addWidgetToGroupConfig = (widgetGroupConfig, widgetID) => {
  let x = 0;
  let y = 0;
  if (widgetGroupConfig.layout.length > 0) {
    const itemsSortedByPosition = [...widgetGroupConfig.layout].sort((a, b) => {
      const dy = b.y - a.y;
      if (dy !== 0) {
        return dy;
      }
      return b.x - a.x;
    });
    const lastItem = itemsSortedByPosition[0];
    x = 0;
    y = lastItem.y + 1;
    // if here is a space next to the last item
    if (lastItem.x === 0 && lastItem.width === 1) {
      x = 1;
      y = lastItem.y;
    }
  }

  return {
    ...widgetGroupConfig,
    layout: [
      ...widgetGroupConfig.layout,
      {
        widgetID,
        x,
        y,
        height: 24,
        width: 1,
        id: v4(),
      },
    ],
  };
};

export const createWidgetsAndGroups = async (configs, dashboardId, dashboardDataSources, widgetGroups, translationUsed) => {
  const widgetConfigs = [];
  const widgetGroupConfigs = [];
  const groupTitleToWidgetIds = new Map();
  for (let config of configs) {
    const widgetConfig = generateWidgetConfig(config, dashboardId, dashboardDataSources);
    const widgetId = await createWidget(dashboardId, widgetConfig);
    widgetConfigs.push({
      ...widgetConfig,
      base: {
        ...widgetConfig.base,
        id: widgetId,
      },
    });
    const trimmedGroupTitle = config.groupTitle.trim();
    const groupTitle = trimmedGroupTitle === DEFAULT_GROUP_TITLE ? '' : trimmedGroupTitle;
    if (!groupTitleToWidgetIds.has(groupTitle)) {
      groupTitleToWidgetIds.set(groupTitle, []);
    }
    groupTitleToWidgetIds.get(groupTitle).push(widgetId);
  }

  const titleField = translationUsed ? 'title' : 'originalTitle';
  let maxOrder = widgetGroups.reduce((order, widgetGroup) => Math.max(order, widgetGroup.order), 0);
  for (let [groupTitle, widgetIds] of groupTitleToWidgetIds.entries()) {
    let widgetGroupConfig = widgetGroups.find((widgetGroup) => widgetGroup[titleField] === groupTitle && !widgetGroup.isOverview);
    let isNew = false;
    if (!widgetGroupConfig) {
      isNew = true;
      widgetGroupConfig = {
        title: groupTitle,
        originalTitle: groupTitle,
        order: ++maxOrder,
        collapsed: false,
        layout: [],
      };
    }
    for (let widgetId of widgetIds) {
      widgetGroupConfig = addWidgetToGroupConfig(widgetGroupConfig, widgetId);
    }

    if (isNew) {
      const widgetGroupId = await createWidgetGroup(dashboardId, widgetGroupConfig);
      widgetGroupConfigs.push({
        ...widgetGroupConfig,
        id: widgetGroupId,
      });
    } else {
      await updateWidgetGroup(dashboardId, widgetGroupConfig);
      widgetGroupConfigs.push(widgetGroupConfig);
    }
  }

  return { widgetConfigs, widgetGroupConfigs };
};
