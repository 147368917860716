import { InputLabel, Select, MenuItem } from '@material-ui/core';

import { IQualtricsSurvey } from '../../../store/actions/qualtrics';

import { StyledFormControl } from './style';

interface IConnectionSurveySelect {
  surveys: IQualtricsSurvey[];
  selectedSurvey: string;
  onSelectSurvey: (survey: string) => void;
}

function ConnectionSurveySelect({ surveys, selectedSurvey, onSelectSurvey }: IConnectionSurveySelect) {
  function handleSelectSurvey(e: React.ChangeEvent<{ value: unknown }>) {
    onSelectSurvey(e.target.value as string);
  }

  return (
    <StyledFormControl>
      <InputLabel id='survey-select-label'>Select Survey</InputLabel>
      <Select data-testid='connection-survey-select' value={selectedSurvey} labelId='survey-select-label' onChange={handleSelectSurvey}>
        {surveys.map((survey) => (
          <MenuItem value={survey.id} key={survey.id}>
            {survey.name}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default ConnectionSurveySelect;
