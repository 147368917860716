import { useCallback, useEffect, useRef, useState } from 'react';

import { FETCH_STATUS } from '../../../constants';
import * as api from '../../../components/tools/api';

const useSegments = (dashboardId) => {
  const mounted = useRef(true);
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({ base: 0, weighted: 0 });

  const loadMore = useCallback(
    async (offset) => {
      setStatus(FETCH_STATUS.LOADING);
      const data = await api.fetchSegments(dashboardId, offset);
      if (!mounted.current) {
        return;
      }

      setData((prevValue) => [...prevValue, ...data.segments]);
      setTotals({ base: data.totalOriginal, weighted: data.totalWeighted });
      setStatus(FETCH_STATUS.LOADED);
    },
    [dashboardId],
  );

  const init = useCallback(() => {
    setData([]);
    setTotals({ base: 0, weighted: 0 });
    loadMore(0);
  }, [loadMore]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return { data, totals, init, loadMore, status };
};

export default useSegments;
