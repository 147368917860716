import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { getSchemeColors } from '../../style/colors';
import StopRounded from '@material-ui/icons/StopRounded';
import FormControl from '@material-ui/core/FormControl';
import { Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ResponseChip } from '../../style';
import TimeLineCharts from './TimeLineCharts';
import useFilters from '../../hooks/useFilters';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { getAppliedFiltersCount } from '../UI/lib';

const ChipsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
const DropdownChip = styled(ResponseChip)`
  &.MuiChip-root {
    margin-right: 4px;
    margin-bottom: 0;
    margin-top: 4px;
  }
`;

const StyledFormControl = styled(FormControl)`
  &.MuiInput-underline:after,
  .MuiInput-underline:after {
    border-bottom: 0;
    display: none;
  }
`;
const StyledSelect = styled(Select)`
  .MuiSelect-root {
    padding: 0 4px 4px 4px;
    font-size: 14px;
    color: #525777;

    width: auto;
    overflow-x: hidden;

    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: var(--border-radius);
  }
`;

const PLOT_LEGEND_ITEMS = 5;

export const PlotLegend = ({ timelineData }) => {
  const [filters] = useFilters();
  const [enabledItems, setEnabledItems] = useState([]);
  const colorSchemeName = useSelector((state) => state.app.colorSchemeName);

  const allAnswers = Array.from(
    new Set(
      [...timelineData].flat().map((d, index) => {
        return d.answer;
      }),
    ),
  ); // Use non-filtered data to get largest segments.

  const colorByLabel = useMemo(() => {
    const colors = getSchemeColors(colorSchemeName, allAnswers.length);
    const result = {};

    for (let answer of allAnswers) {
      result[answer] = colors.pop();
    }
    return result;
  }, [colorSchemeName, allAnswers]);

  useEffect(() => {
    const topAnswers = Object.entries(
      [...timelineData].flat().reduce((acc, { buckets, answer }) => {
        let overallValue = 0;
        buckets.forEach(({ number }) => (overallValue += number));
        acc[answer] = acc[answer] ? acc[answer] + overallValue : overallValue;
        return acc;
      }, {}),
    )
      .sort(([key1, value1], [key2, value2]) => {
        return value2 - value1;
      })
      .slice(0, PLOT_LEGEND_ITEMS)
      .map(([key, value]) => key);
    const selectedItem = topAnswers && topAnswers.length > 0 ? [topAnswers[0]] : [];
    setEnabledItems(selectedItem);
  }, [timelineData]);

  if (timelineData.length < 1) {
    return 'No plot data.';
  }

  const handleChange = (e) => {
    eventsTracker.track(EVENTS.TRENDS_ANSWER_CHANGE, {
      'Answer Count': e.target.value.length,
      'Dashboard Filter Count': getAppliedFiltersCount(filters),
    });
    return setEnabledItems(e.target.value);
  };

  return (
    <Grid style={{ textAlign: 'left' }} container direction='column' spacing={2} justifyContent='flex-start' alignItems='stretch'>
      <Grid item xs={12}>
        <StyledFormControl variant='outlined'>
          <InputLabel id='demo-mutiple-chip-label'>Answers</InputLabel>
          <StyledSelect
            variant='standard'
            // style={{width: "50vw"}}
            labelId='demo-mutiple-chip-label'
            id='demo-mutiple-chip'
            multiple
            value={enabledItems}
            onChange={handleChange}
            input={<Input id='select-multiple-chip' disableUnderline={true} />}
            renderValue={(selected) => (
              <ChipsWrapper>
                {selected.map((value) => (
                  <DropdownChip key={value} label={value} icon={<StopRounded style={{ color: colorByLabel[value] }} />} />
                ))}
              </ChipsWrapper>
            )}
          >
            {allAnswers.map((name) => (
              <MenuItem key={name} value={name}>
                <StopRounded style={{ color: colorByLabel[name] }} /> {name}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        {timelineData && <TimeLineCharts enabledItems={enabledItems} colorByLabel={colorByLabel} timelineData={timelineData} />}
      </Grid>
    </Grid>
  );
};
