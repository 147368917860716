import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import capitalize from 'lodash/capitalize';

import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { WIDGET_TYPE } from '../../constants';
import { dashboardRemoveOverview } from '../../store/thunks/dashboard';

const OverviewMenu = ({ config, onEdit, groupTitle }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    eventsTracker.track(EVENTS.WIDGET_EDIT, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
    handleClose();
    onEdit();
  };

  const handleRemoveClick = () => {
    eventsTracker.track(EVENTS.WIDGET_REMOVE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
    dispatch(dashboardRemoveOverview(config.base.id));
  };

  return (
    <>
      <MoreVertIcon data-testid='widget-menu-button' onClick={handleClick} style={{ cursor: 'pointer' }} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEdit} data-action='edit'>
          <EditIcon fontSize='small' /> Edit
        </MenuItem>
        <MenuItem onClick={() => setShowConfirmation(true)} data-testid='widget-menu-item-remove'>
          <DeleteForeverIcon fontSize='small' /> Remove
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleRemoveClick}
        open={showConfirmation}
        title='Remove Overview'
        content={'Overview will be removed. All settings will be lost.'}
      />
    </>
  );
};

export default OverviewMenu;
