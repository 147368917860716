import React, { useRef, useState } from 'react';
import { MoreVert } from '@material-ui/icons';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import WidgetGroupRenameDialog from './WidgetGroupRenameDialog';
import { dashboardRemoveWidgetGroup, dashboardRenameWidgetGroup, dashboardSwapWidgetGroupsOrder } from '../../store/thunks/dashboard';

const WidgetsGroupMenu = ({ id, title, originalTitle, index }) => {
  const widgetGroups = useSelector((state) => state.dashboard.widgetGroups);
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRename, setShowRename] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMove = (offset) => {
    const groupIdx = widgetGroups.findIndex((group) => group.id === id);
    dispatch(dashboardSwapWidgetGroupsOrder(id, widgetGroups[groupIdx + offset].id));
    handleClose();
  };

  const handleRemoveClick = () => {
    setShowConfirmation(true);
    handleClose();
  };

  const handleRenameClick = () => {
    setShowRename(true);
    handleClose();
  };

  const handleRemoveConfirmation = () => {
    dispatch(dashboardRemoveWidgetGroup(id));
  };

  const handleSaveClick = (newTitle, newOriginalTitle) => {
    dispatch(dashboardRenameWidgetGroup(id, newTitle, newOriginalTitle));
    setShowRename(false);
  };

  const hasOverview = widgetGroups.some((group) => group.isOverview);
  const hasDefault = widgetGroups.some((group) => group.isDefault);
  const isUpDisabled = hasOverview ? index === 1 : index === 0;
  const idDownDisabled = hasDefault ? index === widgetGroups.length - 2 : index === widgetGroups.length - 1;

  return (
    <>
      {<MoreVert ref={anchorRef} onClick={handleOpen} data-testid='widget-group-menu-button' />}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom-end'
        transition
        disablePortal
        data-testid='widget-group-menu'
        style={{ zIndex: 103 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='menu-list-grow' onClick={(e) => e.stopPropagation()}>
                  <MenuItem onClick={() => handleMove(-1)} disabled={isUpDisabled}>
                    Move Up
                  </MenuItem>
                  <MenuItem onClick={() => handleMove(1)} disabled={idDownDisabled}>
                    Move Down
                  </MenuItem>
                  <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
                  <MenuItem onClick={handleRemoveClick} data-testid='remove'>
                    Remove
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ConfirmationDialog
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleRemoveConfirmation}
        open={showConfirmation}
        title='Remove Group'
        content={`Group "${title}" will be removed. All existing widgets will be moved to the default group.`}
      />
      <WidgetGroupRenameDialog
        onCancel={() => setShowRename(false)}
        onSave={handleSaveClick}
        title={title}
        originalTitle={originalTitle}
        open={showRename}
      />
    </>
  );
};

export default WidgetsGroupMenu;
