import React from 'react';
import { ConceptPill } from '../../../components/Labels/ConceptPill';

import { Row, RowLeft } from './style';

interface IAllTopicsRow {
  clickable: boolean;
  disabled: boolean;
  selected: boolean;
  onLabelClick: () => void;
}

const AllTopicsRow = React.forwardRef<HTMLDivElement, IAllTopicsRow>(({ clickable, disabled, selected, onLabelClick }, ref) => {
  const labelText = 'All Topics';
  return (
    <Row data-label={labelText} ref={ref}>
      <RowLeft>
        <ConceptPill
          text={labelText}
          isHierarchy={false}
          isParent={false}
          onClick={onLabelClick}
          isClickable={clickable}
          isDisabled={disabled}
          isSelected={selected}
        />
      </RowLeft>
    </Row>
  );
});

export default AllTopicsRow;
