import React from 'react';
import styled from 'styled-components';
import { ChildLabel } from '../../LabelsList/style';
import { ConceptPill } from '../../../../components/Labels/ConceptPill';

export const ResponseChip = styled(ChildLabel)`
  display: flex;
  margin-left: 0;
  min-height: 24px;
  height: auto;
`;

const getLabelsToShow = (labels, hiddenLabels, labelToParent, selectedItems) => {
  const parentFrequency = [...labelToParent.values()].reduce(
    (acc, parent) => ({ ...acc, [parent]: acc[parent] ? acc[parent] + 1 : 1 }),
    {},
  );
  const parents = Object.entries(parentFrequency)
    .filter(([, count]) => count > 1)
    .map(([name]) => name);
  return labels
    .filter((label) => !hiddenLabels.includes(label) && (!parents.includes(label) || selectedItems.includes(label)))
    .sort((a, b) => {
      const pa = labelToParent.get(a);
      const pb = labelToParent.get(b);
      if (pa !== pb) {
        return pa.localeCompare(pb);
      }
      if (pa === a) {
        return -1;
      }
      if (pb === b) {
        return 1;
      }
      return a.localeCompare(b);
    });
};

const ResponseLabels = ({ labels, hiddenLabels, labelToParent, selectedLabelItem, onLabelClick, selectedItems }) => {
  const labelsToShow = getLabelsToShow(labels, hiddenLabels, labelToParent, selectedItems);
  return labelsToShow.map((label, index) => {
    const parent = labelToParent.get(label);
    const disabled = selectedItems.length > 0 && !selectedItems.includes(label) && !selectedItems.includes(parent);
    const tooltipContent = disabled
      ? 'Disabled by widget setting, to filter by this label edit and add it to selected items'
      : labelToParent.get(label);

    return (
      <ConceptPill
        key={label}
        text={label}
        isHierarchy={false}
        isClickable={true}
        isDisabled={disabled}
        isSelected={selectedLabelItem !== null && selectedLabelItem.title === label}
        tooltipContent={tooltipContent}
        onClick={onLabelClick.bind(null, label)}
      />
    );
  });
};

export default ResponseLabels;
