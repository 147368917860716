import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SidebarItem from "./SidebarItem";
import { useHistory } from 'react-router-dom';

const SidebarItemHives = ({ isSelected, onClick, to }) => {
    const history = useHistory();
    const handleClick = () => {
        onClick();
        history.push(to);
    }

    return <SidebarItem
        icon={<DashboardOutlinedIcon/>}
        selected={isSelected}
        tooltip="Hives"
        onClick={handleClick}
    />
};

export default SidebarItemHives;