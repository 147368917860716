import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { DataSourceRegeneratingStatus } from '../../../../store/reducers/dashboard/types';
import { startRegeneration } from '../../../../store/thunks/dashboard';
import RefreshAI from './RefreshAI';

interface IRefreshAIContainer {
  dataSourceID: string;
  isRegenerating: boolean;
}

function RefreshAIContainer({ dataSourceID, isRegenerating }: IRefreshAIContainer) {
  const state = useAppSelector((state) => state.dashboard.regeneratingStatesByDataSourceID[dataSourceID]);
  const dispatch = useAppDispatch();

  if (isRegenerating) {
    return <RefreshAI isPendingRefresh={false} />;
  }

  const isPendingRefresh = state?.status === DataSourceRegeneratingStatus.PendingRefreshAfterInit;
  if (!isPendingRefresh) {
    return null;
  }

  function handleRefreshClick() {
    dispatch(startRegeneration({ dataSourceIDs: [dataSourceID] }));
  }

  return <RefreshAI isPendingRefresh={true} onRefreshClick={handleRefreshClick} />;
}

export default RefreshAIContainer;
