import styled from 'styled-components';
import ButtonMui from '@material-ui/core/Button';
import IconButtonMui from '@material-ui/core/IconButton';

import palette from '../tools/palette';

export const FilledButton = styled(ButtonMui)`
  &.MuiButton-root {
    background-color: ${palette.beehivePurple};
    color: #fff;
    border-radius: 8px;
    text-transform: none;
    box-shadow: none;

    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    padding: 10px 40px;
  }

  &.MuiButton-root:hover {
    box-shadow:
      0 2px 6px 2px rgba(15, 12, 12, 0.15),
      0 1px 2px 0 rgba(15, 12, 12, 0.3);
    background-color: ${palette.beehivePurple};
  }

  &.Mui-disabled {
    background-color: ${palette.mediumGray};
    color: ${palette.textGray};
  }
`;

export const OutlinedButton = styled(FilledButton)`
  &.MuiButton-root {
    color: ${palette.darkPurple};
    background-color: transparent;
    border: 1px solid ${palette.darkPurple};
    box-sizing: border-box;
    box-shadow: none;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &.MuiButton-root:hover {
    background-color: rgba(130, 104, 255, 0.1);
    box-shadow: none;
  }

  &.Mui-disabled {
    background-color: ${palette.mediumGray};
    border-color: ${palette.mediumGray};
    color: ${palette.textGray};
  }
`;

export const OutlinedButtonNarrow = styled(OutlinedButton)`
  &.MuiButton-root {
    padding: 9px 15px;
  }
`;

export const SavedFilterButton = styled(ButtonMui)<{ selected: boolean }>`
  &.MuiButton-root {
    background-color: ${({ selected }) => (selected ? palette.beehivePurple : palette.lightPurpleTransparent50)};
    border-radius: 6px;
    box-shadow: none;
    color: ${(props) => (props.selected ? '#fff' : '#000')};

    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    text-transform: none;
    padding: 8px 10px;
  }

  &.MuiButtonGroup-groupedHorizontal:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.MuiButtonGroup-groupedHorizontal:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const TextButton = styled(ButtonMui)`
  &.MuiButton-root {
    color: ${palette.black};

    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    text-transform: none;
    padding: 8px 10px;
  }

  &.MuiButton-root:hover {
    background-color: transparent;
    color: ${palette.beehivePurple};
  }
`;

export const IconButton = styled(IconButtonMui)``;
