import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OverviewWidgetContentBreakdown from './OverviewWidgetContent/OverviewWidgetContentBreakdown';
import OverviewWidgetContentPlain from './OverviewWidgetContent/OverviewWidgetContentPlain';
import { RGL_ROW_HEIGHT } from '../../overview/lib';
import useFilters from '../../../hooks/useFilters';
import { dashboardUpdateWidget } from '../../../store/actions/dashboard';
import * as api from '../../tools/api';
import { WidgetTotalsProvider } from '../WidgetTotalsContext';
import { useSavedFilters } from '../../../contexts/SavedFiltersContext';

export const OVERVIEW_WIDGET_DEFAULT_HEIGHT = 56;
export const OVERVIEW_WIDGET_COLLAPSED_HEIGHT = 7;

const OverviewWidget = ({ config, onDimensionsChange }) => {
  const { savedFilters } = useSavedFilters();
  const [filters] = useFilters();
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const dispatch = useDispatch();

  const settingsDataSources = config.settings.dataSources;
  const visibleDataSourcesIds = useMemo(
    () =>
      settingsDataSources
        .filter((dataSource) => dataSource.visible)
        .sort((a, b) => a.order - b.order)
        .map((d) => d.id),
    [settingsDataSources],
  );

  const collapsed = config.base.collapsed;
  useEffect(() => {
    if (collapsed) {
      onDimensionsChange(2, OVERVIEW_WIDGET_COLLAPSED_HEIGHT, false);
    } else {
      onDimensionsChange(2, OVERVIEW_WIDGET_DEFAULT_HEIGHT, false);
    }
  }, [collapsed, onDimensionsChange]);

  useEffect(() => {
    const { _updated, ...updatedConfig } = config;
    if (!_updated) {
      return;
    }
    const updateWidget = async () => {
      await api.updateWidget(updatedConfig);
      dispatch(dashboardUpdateWidget(updatedConfig));
    };
    updateWidget();
  }, [config, dispatch]);

  const query = useMemo(() => {
    if (config.base.filter?.query || config.base.filter?.filterID) {
      if (config.base.filter.query) {
        return config.base.filter.query;
      } else {
        const savedFilter = savedFilters.find((savedFilter) => savedFilter.id === config.base.filter.filterID);
        return savedFilter ? savedFilter.query : null;
      }
    } else {
      return null;
    }
  }, [config, savedFilters]);

  const handleHeightUpdate = useCallback(
    (height) => {
      const h = Math.ceil(height / RGL_ROW_HEIGHT);
      onDimensionsChange(2, h, false);
    },
    [onDimensionsChange],
  );

  if (breakdownFilter) {
    return (
      <WidgetTotalsProvider widgetDataSources={config.settings.dataSources}>
        <OverviewWidgetContentBreakdown
          config={config}
          onDimensionsChange={onDimensionsChange}
          visibleDataSourcesIds={visibleDataSourcesIds}
          handleHeightUpdate={handleHeightUpdate}
          filters={filters}
          query={query}
        />
      </WidgetTotalsProvider>
    );
  } else {
    return (
      <WidgetTotalsProvider widgetDataSources={config.settings.dataSources}>
        <OverviewWidgetContentPlain
          config={config}
          onDimensionsChange={onDimensionsChange}
          visibleDataSourcesIds={visibleDataSourcesIds}
          handleHeightUpdate={handleHeightUpdate}
          filters={filters}
          query={query}
        />
      </WidgetTotalsProvider>
    );
  }
};

export default OverviewWidget;
