import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const LabelWrapper = styled('div')`
  overflow: hidden;
  height: 1.25em;
  width: 95%;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const MCWContentWrapper = styled(Grid)`
  max-height: 100%;
`;

export const MCWBarsContainer = styled(Grid)`
  &.MuiGrid-root {
    padding: 16px 0;
  }
`;
