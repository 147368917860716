import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { CurrentDashboardInfo } from '../../../store/reducers/dashboard/types';
import { Query } from '../../../types/Query';
import { createDescriptionFrom } from '../lib';
import SegmentDescription from './SegmentDescription';

export const ELEMENT_TYPE = {
  DATA_SOURCE_VALUE: 'DATA_SOURCE_VALUE',
  TEXT: 'TEXT',
};

interface ISegmentDescriptionContainer {
  filterQuery: Query;
}
export type DescriptionElement = {
  type: string;
  label?: string;
  dataSourceID?: string;
  text?: string;
};
function SegmentDescriptionContainer({ filterQuery }: ISegmentDescriptionContainer) {
  const { dataSources: dashboardDataSources } = useAppSelector((state) => state.dashboard.dashboard as CurrentDashboardInfo);
  const translationUsed = useAppSelector((state) => state.app.translationUsed);
  const [segments, setSegments] = useState<DescriptionElement[]>([]);

  const dataSourceTitleByID = useMemo(() => {
    const field = translationUsed ? 'title' : 'originalTitle';
    return dashboardDataSources.reduce(
      (acc, dashboardDataSource) => ({
        ...acc,
        [dashboardDataSource.dataSourceID]: dashboardDataSource[field],
      }),
      {} as { [key: string]: string },
    );
  }, [dashboardDataSources, translationUsed]);

  useEffect(() => {
    setSegments(createDescriptionFrom(filterQuery));
  }, [filterQuery]);

  if (segments.length === 0) {
    return null;
  }

  return <SegmentDescription segments={segments} dataSourceTitleByID={dataSourceTitleByID} />;
}

export default SegmentDescriptionContainer;
