import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ExpandIcon } from '../../../../../../assets/icons/expand-icon.svg';
import { ReactComponent as BookmarkIcon } from '../../../../../../assets/icons/bookmark-icon.svg';
import { Concept } from '../../../../../../store/reducers/aiInsights';
import { ResponsesSorting } from '../../../../../../store/reducers/openEndedWidget';
import { Label } from '../../../../../../types/Label';
import { RESPONSES_SORT_FIELD_NAME, SORT_OPTION_FIELDS, SORTING_ORDER } from './ResponsesListFilterContainer';
import LabelsSelectContainer from '../../../../components/LabelsSelect/LabelsSelectContainer';
import { CompactMenuItem, CustomSelect } from '../style';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  border-radius: var(--double-border-radius);
  background-color: var(--light-gray);
  font-size: 14px;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const RowItem = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SotringRowItem = styled(RowItem)`
  flex: 1;
  gap: 8px;
`;

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
`;

const ToggleItem = styled.div<{ $isSelected: boolean }>`
  height: 40px;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--white)' : 'none')};

  :nth-child(1) {
    border-top-left-radius: var(--double-border-radius);
    border-bottom-left-radius: var(--double-border-radius);
  }

  :nth-last-child(1) {
    border-top-right-radius: var(--double-border-radius);
    border-bottom-right-radius: var(--double-border-radius);
  }

  :nth-child(even) {
    border-left: 1px solid var(--medium-gray);
  }
`;

const ToggleItemBig = styled(ToggleItem)`
  padding: 6px 12px;
`;

const SortingDirections = styled.div`
  flex-grow: 0;
`;

const BookmarkedContainer = styled.div`
  flex-grow: 1;
`;

const SortDirectionDownButton = styled(ExpandIcon)<{ $isSelected: boolean }>`
  width: 24px;
  height: 24px;
  fill: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'var(--gray-disabled)')};
`;

const SortDirectionUpButton = styled(SortDirectionDownButton)`
  transform: rotateX(180deg);
`;

const BookmarkedToggle = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 4px;
  color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'var(--text-primary)')};
`;

const BookmarkIconStyled = styled(BookmarkIcon)<{ $isSelected: boolean }>`
  color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'none')};
  fill: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'none')};
`;

const HideableText = styled.span`
  @media only screen and (max-width: 1800px) {
    display: none;
  }
`;

interface IResponsesListFilter {
  widgetName: string;
  isBookmarkedOnly: boolean;
  labels: Label[];
  searchString: string;
  selectedLabelItem: Concept | null;
  sorting: ResponsesSorting;
  onBookmarkClick: () => void;
  onFieldChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  onOrderChange: (order: string) => void;
  onSearchStringChange: (searchString: string) => void;
  onLabelSelect: (label: string) => void;
}
function ResponsesListFilter({
  widgetName,
  isBookmarkedOnly,
  labels,
  searchString,
  selectedLabelItem,
  sorting,
  onBookmarkClick,
  onFieldChange,
  onOrderChange,
  onSearchStringChange,
  onLabelSelect,
}: IResponsesListFilter) {
  return (
    <Container>
      <Row>
        <SotringRowItem>
          {renderSortingOptions()}
          <SortingDirections>
            <Toggle>
              <ToggleItem $isSelected={sorting.desc}>
                <SortDirectionDownButton $isSelected={sorting.desc} onClick={onOrderChange.bind(null, SORTING_ORDER.DOWN)} />
              </ToggleItem>
              <ToggleItem $isSelected={!sorting.desc}>
                <SortDirectionUpButton $isSelected={!sorting.desc} onClick={onOrderChange.bind(null, SORTING_ORDER.UP)} />
              </ToggleItem>
            </Toggle>
          </SortingDirections>
        </SotringRowItem>
        <RowItem>
          <BookmarkedContainer>{renderBookmarked()}</BookmarkedContainer>
        </RowItem>
      </Row>
      <Row>{renderFilter()}</Row>
    </Container>
  );

  function renderBookmarked() {
    return (
      <Toggle>
        <ToggleItemBig $isSelected={!isBookmarkedOnly}>
          <BookmarkedToggle $isSelected={!isBookmarkedOnly} onClick={onBookmarkClick}>
            Show All
          </BookmarkedToggle>
        </ToggleItemBig>
        <ToggleItemBig $isSelected={isBookmarkedOnly}>
          <BookmarkedToggle $isSelected={isBookmarkedOnly} onClick={onBookmarkClick}>
            <BookmarkIconStyled $isSelected={isBookmarkedOnly} />
            <HideableText> Bookmarked</HideableText>
          </BookmarkedToggle>
        </ToggleItemBig>
      </Toggle>
    );
  }

  function renderSortingOptions() {
    return (
      <CustomSelect
        data-tooltip-id='base-tooltip'
        data-tooltip-content={RESPONSES_SORT_FIELD_NAME[sorting.sortBy]}
        onChange={onFieldChange}
        value={sorting.sortBy}
        fullWidth
        variant='outlined'
      >
        {SORT_OPTION_FIELDS.map((field) => (
          <CompactMenuItem key={field} value={field}>
            {RESPONSES_SORT_FIELD_NAME[field]}{' '}
          </CompactMenuItem>
        ))}
      </CustomSelect>
    );
  }

  function renderFilter() {
    return (
      <LabelsSelectContainer
        widgetName={widgetName}
        selectedLabelItem={selectedLabelItem}
        searchString={searchString}
        labels={labels}
        onSearchStringChange={onSearchStringChange}
        onLabelSelect={onLabelSelect}
      />
    );
  }
}

export default ResponsesListFilter;
