import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { initializeBrands } from '../../../../store/thunks/brands';

const useBrands = (onUpdateError: (err: string) => void) => {
  const { brands, initialized: isBrandsInitialized, error: isBrandsError } = useAppSelector((state) => state.brands);
  const dispatch = useAppDispatch();
  const [selectedBrand, setSelectedBrand] = useState('');

  useEffect(() => {
    if (isBrandsError) {
      onUpdateError('Failed to load brands.');
      return;
    }

    if (!isBrandsInitialized) {
      dispatch(initializeBrands());
    }
  }, [isBrandsInitialized, isBrandsError, dispatch, onUpdateError]);

  useEffect(() => {
    if (brands.length > 0) {
      setSelectedBrand(brands[0].id);
    }
  }, [brands]);

  return { brands, selectedBrand, setSelectedBrand };
};

export default useBrands;
