import { useState } from 'react';
import { useSelector } from 'react-redux';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { FILTER_ID_ALL, FILTER_ID_CURRENT } from '../../../constants';
import useFilters from '../../../hooks/useFilters';
import SidebarItem from './SidebarItem';

const ALERT_DELAY = 2000;

const SidebarItemShare = () => {
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const [filters] = useFilters();
  const [icon, setIcon] = useState(<ShareOutlinedIcon />);

  const linkText = window.location.href;

  const handleClick = () => {
    eventsTracker.track(EVENTS.DASHBOARD_RESULTS_SHARE, {
      'Filter Count': filters.filter((filter) => ![FILTER_ID_ALL, FILTER_ID_CURRENT].includes(filter.id)).length,
      'Weighted Metrics': weightedMetricsUsed,
      Breakdown: !!breakdownFilter,
    });
    navigator.clipboard
      .writeText(linkText)
      .then(
        () => {
          setIcon(
            <DoneIcon
              htmlColor='green'
              data-tooltip-hidden={false}
              data-tooltip-id='base-tooltip'
              data-tooltip-content='Share link copied into clipboard!'
              data-tooltip-delay-hide={ALERT_DELAY}
            />,
          );
        },
        () => {
          setIcon(
            <ErrorOutlineIcon
              htmlColor='red'
              data-tooltip-hidden={false}
              data-tooltip-id='base-tooltip'
              data-tooltip-content='Failed to copy link.'
              data-tooltip-delay-hide={ALERT_DELAY}
            />,
          );
        },
      )
      .finally(() => {
        setTimeout(() => {
          setIcon(<ShareOutlinedIcon />);
        }, ALERT_DELAY);
      });
  };

  return (
    <>
      <SidebarItem icon={icon} onClick={handleClick} tooltip='Share Dashboard' />
    </>
  );
};

export default SidebarItemShare;
