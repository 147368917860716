import { ChangeEventHandler } from 'react';
import { FilledButton } from '../../../UI/Button';
import Help from '../Help';

import {
  StyledCircularProgress,
  AuthContent,
  AuthFormContainer,
  AuthFormContent,
  AuthSubtitle,
  AuthTextField,
  Container,
  FooterActions,
} from '../style';

interface IAuthQualtrics {
  datacenterID: string;
  clientID: string;
  secretID: string;
  datacenterError: string;
  clientError: string;
  secretError: string;
  isSubmitDisabled: boolean;
  isSubmitting: boolean;
  onChangeDatacenterID: ChangeEventHandler<HTMLInputElement>;
  onChangeClientID: ChangeEventHandler<HTMLInputElement>;
  onChangeSecretID: ChangeEventHandler<HTMLInputElement>;
  onBlurDatacenterID: () => void;
  onBlurClientID: () => void;
  onBlurSecretID: () => void;
  onSubmit: () => void;
  onSendHelpMessage: () => void;
}

function AuthQualtrics({
  datacenterID,
  clientID,
  secretID,
  datacenterError,
  clientError,
  secretError,
  isSubmitDisabled,
  isSubmitting,
  onChangeDatacenterID,
  onChangeClientID,
  onBlurSecretID,
  onBlurDatacenterID,
  onBlurClientID,
  onChangeSecretID,
  onSubmit,
  onSendHelpMessage,
}: IAuthQualtrics) {
  return (
    <>
      <Container>
        <AuthSubtitle data-testid='auth-qualtrics-title'>
          Authenticate <strong>Qualtrics</strong>
        </AuthSubtitle>
        <AuthContent>
          <AuthFormContainer>
            <AuthFormContent data-testid='auth-qualtrics-text-fields-container'>
              <AuthTextField
                data-testid='auth-qualtrics-datacenter'
                label='Datacenter ID'
                placeholder='Enter your datacenter ID here...'
                helperText={datacenterError || 'Datacenter ID for your Qualtrics account.'}
                value={datacenterID}
                error={!!datacenterError}
                onChange={onChangeDatacenterID}
                onBlur={onBlurDatacenterID}
              />
              <AuthTextField
                data-testid='auth-qualtrics-client-id'
                label='Client ID'
                placeholder='Enter your client ID here...'
                helperText={clientError || 'Client ID for your Qualtrics account.'}
                value={clientID}
                error={!!clientError}
                onChange={onChangeClientID}
                onBlur={onBlurClientID}
              />
              <AuthTextField
                data-testid='auth-qualtrics-secret-id'
                label='Client Secret'
                placeholder='Enter your Client Secret here...'
                helperText={secretError || 'Client Secret for your Qualtrics account.'}
                value={secretID}
                error={!!secretError}
                onChange={onChangeSecretID}
                onBlur={onBlurSecretID}
              />
            </AuthFormContent>
          </AuthFormContainer>
          <Help onSendHelpMessage={onSendHelpMessage} testID='auth-qualtrics-help' />
        </AuthContent>
      </Container>
      <FooterActions>
        {isSubmitting && <StyledCircularProgress data-testid='auth-qualtrics-progress' size='36px' />}
        <FilledButton data-testid='auth-qualtrics-submit' disabled={isSubmitDisabled} onClick={onSubmit}>
          Continue
        </FilledButton>
      </FooterActions>
    </>
  );
}

export default AuthQualtrics;
