import React, { useEffect, useMemo, useRef, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import OverviewWidgetSettings from '../Widgets/OverviewWidget/OverviewWidgetSettings/OverviewWidgetSettings';
import { dashboardUpdateWidget } from '../../store/actions/dashboard';
import OverviewMenu from './OverviewMenu';
import { FILTER_ID_ALL } from '../../constants';
import WeightIndicator from '../UI/WeightIndicator';
import WidgetExportMenu from '../WidgetMenu/WidgetExportMenu';
import { exportSummaryBreakdown, exportSummaryPlain, getTimestampString, savePng } from '../tools/export';
import OverviewChart from './OverviewChart';
import * as api from '../tools/api';
import WidgetFilterMenu from '../Widgets/WidgetFilterMenu';
import useSelectedFilterIdsToggle from '../../hooks/useSelectedFilterIdsToggle';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import SegmentsRowContainer from '../Widgets/components/SegmentsRow/SegmentsRowContainer';
import { calculateChartHeight, generateColorMap, generateOverviewRows } from './lib';
import { OverviewWrapper } from './style';

const DEFAULT_HEIGHT = 560;
const NO_EXPAND_HEIGHT = 400;

export const Overview = ({ overviewData, breakdownData, breakdownFilter, filters, onHeightUpdate, config }) => {
  const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);
  const isCollapsed = config.base.collapsed;
  const expandOverview = config.base.expanded;
  const { colorSchemeName, translationUsed } = useSelector((state) => state.app);
  const [showSettings, setShowSettings] = useState(false);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const { title: dashboardTitle, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
  const dispatch = useDispatch();
  const overviewRef = useRef();
  const refContent = useRef();
  const refFiltersCount = useRef(0);
  const groupTitle = 'Overview';

  refFiltersCount.current = selectedFilterIds.length;

  const dataSourcesSettings = config.settings.dataSources;
  const dataSourcesSettingsById = useMemo(() => {
    return dataSourcesSettings.reduce(
      (acc, dataSourceSettings) => ({
        ...acc,
        [dataSourceSettings.id]: dataSourceSettings,
      }),
      {},
    );
  }, [dataSourcesSettings]);

  const { tracesData } = useMemo(() => {
    let tracesDataTmp = [];
    let tracesData = [];

    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];
      const data = overviewData[index];
      if (
        data &&
        ((selectedFilterIds.includes(FILTER_ID_ALL) && filters.length === 1) ||
          selectedFilterIds.includes(filter.id) ||
          (breakdownFilter && filter.id === FILTER_ID_ALL))
      ) {
        tracesDataTmp.push(data);
      }
    }

    tracesDataTmp.forEach((trace) => {
      const items = [...trace.items]
        .filter((ds) => dataSourcesSettingsById[ds.id].visible)
        .sort((a, b) => dataSourcesSettingsById[a.id].order - dataSourcesSettingsById[b.id].order);
      tracesData.push({
        ...trace,
        items,
      });
    });

    return { tracesData };
  }, [overviewData, selectedFilterIds, filters, dataSourcesSettingsById, breakdownFilter]);

  const colorsMap = useMemo(() => generateColorMap(tracesData, colorSchemeName), [tracesData, colorSchemeName]);

  const ToggleIcon = isCollapsed ? KeyboardArrowRightIcon : KeyboardArrowDownIcon;

  useEffect(() => {
    if (!overviewRef.current) {
      return;
    }
    const observer = new ResizeObserver(([entry]) => {
      onHeightUpdate(entry.target.scrollHeight);
    });
    observer.observe(overviewRef.current);

    return () => {
      observer.disconnect();
    };
  }, [overviewRef, onHeightUpdate]);

  const updateAndSaveWidgetConfig = (newConfig) => {
    dispatch(dashboardUpdateWidget(newConfig));
    api.updateWidget(newConfig);
  };

  const handleCollapseToggle = () => {
    eventsTracker.track(EVENTS.WIDGET_TOGGLE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      Visible: !config.base.collapsed,
    });
    const newConfig = {
      ...config,
      base: { ...config.base, collapsed: !config.base.collapsed },
    };
    if (config.base.collapsed) {
      onHeightUpdate(DEFAULT_HEIGHT);
      // TODO: updated with less hacky ordering of events
      setTimeout(() => updateAndSaveWidgetConfig(newConfig), 0);
      setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    } else {
      updateAndSaveWidgetConfig(newConfig);
    }
  };

  const handleExpandToggle = () => {
    const newConfig = {
      ...config,
      base: { ...config.base, expanded: !config.base.expanded },
    };
    updateAndSaveWidgetConfig(newConfig);
  };

  const handleCsvClick = () => {
    const timestampString = getTimestampString();
    const dataSourceIds = dataSourcesSettings
      .filter((settings) => settings.visible)
      .sort((a, b) => a.order - b.order)
      .map((settings) => settings.id);

    if (breakdownFilter) {
      const breakdownDataSource = dataSources.find((dataSource) => dataSource.dataSourceID === breakdownFilter.dataSourceID);
      const breakdownItems = breakdownFilter.values.map((value) => breakdownDataSource.items.find((item) => item.response === value));
      const breakdownTitle = dashboardDataSources.find((dds) => dds.dataSourceID === breakdownFilter.dataSourceID)[
        translationUsed ? 'title' : 'originalTitle'
      ];
      const filename = `${dashboardTitle} - Overview (breakdown by ${breakdownTitle}) - ${timestampString}`;
      exportSummaryBreakdown(
        filters,
        selectedFilterIds,
        breakdownData,
        breakdownItems,
        dataSourceIds,
        dataSources,
        dashboardDataSources,
        translationUsed,
        filename,
      );
    } else {
      const filename = `${dashboardTitle} - Overview - ${timestampString}`;
      exportSummaryPlain(
        overviewData,
        dataSourceIds,
        dataSources,
        dashboardDataSources,
        translationUsed,
        filters,
        selectedFilterIds,
        filename,
      );
    }
  };

  const handlePngClick = async () => {
    const canvas = await html2canvas(refContent.current);
    const image = canvas.toDataURL('image/png', 1.0);
    savePng(image, `${dashboardTitle} - Overview`);
  };

  const handleFilterApply = (filter) => {
    const updatedConfig = { ...config, base: { ...config.base, filter } };
    dispatch(dashboardUpdateWidget(updatedConfig));
    api.updateWidget(updatedConfig);
  };

  const sortedBuckets = useMemo(() => {
    if (!breakdownFilter) {
      return [];
    }
    const datasource = dataSources.find((ds) => ds.dataSourceID === breakdownFilter.dataSourceID);
    const datasourceAnswers = datasource.items.map((item) => item.response);
    return [...breakdownFilter.values].sort((a, b) => datasourceAnswers.indexOf(a) - datasourceAnswers.indexOf(b));
  }, [breakdownFilter, dataSources]);

  const sortedDataSources = useMemo(() => {
    return config.settings.dataSources
      .filter((dataSource) => dataSource.visible)
      .sort((dataSourceA, dataSourceB) => dataSourceA.order - dataSourceB.order)
      .map((dataSource) => {
        const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSource.id);
        return {
          dataSourceID: dataSource.id,
          title: dashboardDataSource[translationUsed ? 'title' : 'originalTitle'],
        };
      });
  }, [config, dashboardDataSources, translationUsed]);

  const filtersToShow = useMemo(() => {
    const showAll = selectedFilterIds.includes(FILTER_ID_ALL) && filters.length === 1;
    return filters.filter((filter) => showAll || selectedFilterIds.includes(filter.id));
  }, [selectedFilterIds, filters]);

  const rows = useMemo(() => {
    return generateOverviewRows(tracesData, breakdownData, sortedDataSources, filtersToShow, sortedBuckets, colorsMap);
  }, [tracesData, breakdownData, sortedDataSources, filtersToShow, sortedBuckets, colorsMap]);

  const height = calculateChartHeight(sortedDataSources.length, filtersToShow.length, sortedBuckets.length);

  return (
    <div ref={overviewRef} data-testid='widget' data-widget-title='Overview'>
      {showSettings && <OverviewWidgetSettings config={config} onClose={() => setShowSettings(false)} groupTitle={groupTitle} />}
      {!showSettings && (
        <OverviewWrapper $expand={expandOverview ? height : -1} $nonExpandHeigth={NO_EXPAND_HEIGHT}>
          <Box display='flex' flexDirection='row' alignItems='center' width='100%'>
            <Box>
              <ToggleIcon
                data-testid='widget-toggle'
                onClick={handleCollapseToggle}
                style={{ fontSize: 30, marginTop: 3, cursor: 'pointer' }}
              />
            </Box>
            <Box flexGrow={1}>
              <h4 style={{ margin: '5px 0' }}>Overview</h4>
            </Box>
            {!isCollapsed && (
              <Box style={{ marginRight: '5px', marginTop: '-5px' }}>
                <WidgetFilterMenu config={config} onFilterApply={handleFilterApply} groupTitle={groupTitle} />
              </Box>
            )}
            <Box>
              <WeightIndicator />
            </Box>
            {!config.base.collapsed && (
              <Box>
                <WidgetExportMenu
                  onCsvClick={handleCsvClick}
                  onPngClick={handlePngClick}
                  groupTitle={groupTitle}
                  config={config}
                  refFiltersCount={refFiltersCount}
                />
              </Box>
            )}
            <Box>
              <OverviewMenu config={config} onEdit={() => setShowSettings(true)} groupTitle={groupTitle} />
            </Box>
          </Box>
          <Box ref={refContent}>
            <Box style={isCollapsed ? { display: 'none' } : { marginTop: '1em', marginBottom: '0.5em' }}>
              <SegmentsRowContainer onChange={setSelectedFilterIds} value={selectedFilterIds} filters={filters} widgetTitle='Overview' />
            </Box>
            <Box className='scroll-wrapper' style={isCollapsed ? { display: 'none' } : { padding: '0', marginTop: '16px' }}>
              <OverviewChart
                height={height}
                rows={rows}
                sortedBuckets={sortedBuckets}
                sortedDataSources={sortedDataSources}
                filtersToShow={filtersToShow}
              />
            </Box>
          </Box>
          {height > NO_EXPAND_HEIGHT && (
            <div style={isCollapsed ? { display: 'none' } : {}} className='show-more' onClick={handleExpandToggle}>
              {expandOverview ? (
                <div className='label'>
                  Show less <ExpandLessIcon />
                </div>
              ) : (
                <div className='label'>
                  Show more <ExpandMoreIcon />
                </div>
              )}
            </div>
          )}
        </OverviewWrapper>
      )}
    </div>
  );
};
