import isEqual from 'lodash/isEqual';
import { EMPTY_FILTER_QUERY } from '../../../components/FilterForm/lib';
import { ResponseModelFactory } from '../../../models/Response';
import {
  assignTopicToResponseFailure,
  assignTopicToResponseStart,
  assignTopicToResponseSuccess,
  changeParticipantForResponseFailure,
  changeParticipantForResponseStart,
  changeParticipantForResponseSuccess,
  changeSearchString,
  createParticipantFailure,
  createParticipantStart,
  createParticipantSuccess,
  initialize,
  INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_FAILURE,
  INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_START,
  INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_SUCCESS,
  INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_FAILURE,
  INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_START,
  INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_SUCCESS,
  INTERVIEW_WIDGET_CREATE_PARTICIPANT_FAILURE,
  INTERVIEW_WIDGET_CREATE_PARTICIPANT_START,
  INTERVIEW_WIDGET_CREATE_PARTICIPANT_SUCCESS,
  INTERVIEW_WIDGET_LOAD_ANSWERS_FAILURE,
  INTERVIEW_WIDGET_LOAD_ANSWERS_START,
  INTERVIEW_WIDGET_LOAD_ANSWERS_SUCCESS,
  INTERVIEW_WIDGET_LOAD_PARTICIPANTS_FAILURE,
  INTERVIEW_WIDGET_LOAD_PARTICIPANTS_START,
  INTERVIEW_WIDGET_LOAD_PARTICIPANTS_SUCCESS,
  INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_FAILURE,
  INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_START,
  INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_SUCCESS,
  INTERVIEW_WIDGET_SEARCH_STRING_CHANGE,
  INTERVIEW_WIDGET_SELECT_CONCEPT,
  INTERVIEW_WIDGET_UPDATE_PARTICIPANT_FAILURE,
  INTERVIEW_WIDGET_UPDATE_PARTICIPANT_START,
  INTERVIEW_WIDGET_UPDATE_PARTICIPANT_SUCCESS,
  INTERVIEW_WIDGET_WIDGET_INITIALIZE,
  loadAnswersFailure,
  loadAnswersStart,
  loadAnswersSuccess,
  loadParticipantsFailure,
  loadParticipantsStart,
  loadParticipantsSuccess,
  removeTopicFromResponseFailure,
  removeTopicFromResponseStart,
  removeTopicFromResponseSuccess,
  selectConcept,
  updateParticipantFailure,
  updateParticipantStart,
  updateParticipantSuccess,
} from '../../actions/interviewWidget';

import { ConversationState, Participant, ParticipantsLoadingState, ResponseManagementState, WidgetConfig } from './types';

export interface IInterviewWidgetsReducer {
  conversationsStatesByWidgetID: { [key: string]: ConversationState };
  configsByWidgetID: { [key: string]: WidgetConfig };
  participantsByDataSourceID: { [key: string]: Participant[] };
  participantsStatesByDataSourceID: { [key: string]: ParticipantsLoadingState };
  conversationsByWidgetID: {
    [key: string]: ReturnType<typeof ResponseModelFactory>[];
  };
  responseManagementStatesByWidgetID: {
    [key: string]: { [key: string]: ResponseManagementState };
  };
}

type actionTypes = ReturnType<typeof initialize> &
  ReturnType<typeof loadAnswersStart> &
  ReturnType<typeof loadAnswersFailure> &
  ReturnType<typeof loadAnswersSuccess> &
  ReturnType<typeof selectConcept> &
  ReturnType<typeof changeSearchString> &
  ReturnType<typeof loadParticipantsStart> &
  ReturnType<typeof loadParticipantsFailure> &
  ReturnType<typeof loadParticipantsSuccess> &
  ReturnType<typeof createParticipantStart> &
  ReturnType<typeof createParticipantFailure> &
  ReturnType<typeof createParticipantSuccess> &
  ReturnType<typeof updateParticipantStart> &
  ReturnType<typeof updateParticipantFailure> &
  ReturnType<typeof updateParticipantSuccess> &
  ReturnType<typeof assignTopicToResponseStart> &
  ReturnType<typeof assignTopicToResponseFailure> &
  ReturnType<typeof assignTopicToResponseSuccess> &
  ReturnType<typeof removeTopicFromResponseStart> &
  ReturnType<typeof removeTopicFromResponseFailure> &
  ReturnType<typeof removeTopicFromResponseSuccess> &
  ReturnType<typeof changeParticipantForResponseStart> &
  ReturnType<typeof changeParticipantForResponseFailure> &
  ReturnType<typeof changeParticipantForResponseSuccess>;

const initialState: IInterviewWidgetsReducer = {
  conversationsStatesByWidgetID: {},
  configsByWidgetID: {},
  participantsByDataSourceID: {},
  participantsStatesByDataSourceID: {},
  conversationsByWidgetID: {},
  responseManagementStatesByWidgetID: {},
};

const interviewWidgetsReducer = (state = initialState, action: actionTypes) => {
  switch (action.type) {
    case INTERVIEW_WIDGET_SELECT_CONCEPT:
      return handleSelectConcept(state, action);
    case INTERVIEW_WIDGET_WIDGET_INITIALIZE:
      return handleInitialize(state, action);
    case INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_START:
      return handleAssignTopicFromResponseStart(state, action);
    case INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_FAILURE:
      return handleAssignTopicFromResponseFailure(state, action);
    case INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_SUCCESS:
      return handleAssignTopicFromResponseSuccess(state, action);
    case INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_START:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_FAILURE:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_SUCCESS:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_CREATE_PARTICIPANT_START:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_CREATE_PARTICIPANT_FAILURE:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_CREATE_PARTICIPANT_SUCCESS:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_SEARCH_STRING_CHANGE:
      return handleSearchStringChange(state, action);
    case INTERVIEW_WIDGET_LOAD_ANSWERS_START:
      return handleLoadAnswersStart(state, action);
    case INTERVIEW_WIDGET_LOAD_ANSWERS_FAILURE:
      return handleLoadAnswersFailure(state, action);
    case INTERVIEW_WIDGET_LOAD_ANSWERS_SUCCESS:
      return handleLoadAnswersSuccess(state, action);
    case INTERVIEW_WIDGET_LOAD_PARTICIPANTS_START:
      return handleLoadParticipantsStart(state, action);
    case INTERVIEW_WIDGET_LOAD_PARTICIPANTS_FAILURE:
      return handleLoadParticipantsFailure(state, action);
    case INTERVIEW_WIDGET_LOAD_PARTICIPANTS_SUCCESS:
      return handleLoadParticipantsSuccess(state, action);
    case INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_START:
      return handleRemoveTopicFromResponseStart(state, action);
    case INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_FAILURE:
      return handleRemoveTopicFromResponseFailure(state, action);
    case INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_SUCCESS:
      return handleRemoveTopicFromResponseSuccess(state, action);
    case INTERVIEW_WIDGET_UPDATE_PARTICIPANT_START:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_UPDATE_PARTICIPANT_FAILURE:
      // TODO:
      return state;
    case INTERVIEW_WIDGET_UPDATE_PARTICIPANT_SUCCESS:
      // TODO:
      return state;
    default:
      return state;
  }
};

function handleInitialize(state: IInterviewWidgetsReducer, action: ReturnType<typeof initialize>): IInterviewWidgetsReducer {
  return {
    ...state,
    configsByWidgetID: {
      ...state.configsByWidgetID,
      [action.payload.widgetID]: {
        dataSourceID: action.payload.dataSourceID,
        filter: EMPTY_FILTER_QUERY,
        selectedConcept: null,
      },
    },
    conversationsStatesByWidgetID: {
      ...state.conversationsStatesByWidgetID,
      [action.payload.widgetID]: {
        offset: 0,
        isLoading: false,
        isError: false,
        responsesTotal: 1,
        searchString: '',
      },
    },
  };
}

function handleLoadParticipantsStart(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof loadParticipantsStart>,
): IInterviewWidgetsReducer {
  return {
    ...state,
    participantsByDataSourceID: {
      ...state.participantsByDataSourceID,
      [action.payload.dataSourceID]: [],
    },
    participantsStatesByDataSourceID: {
      ...state.participantsStatesByDataSourceID,
      [action.payload.dataSourceID]: {
        isLoading: true,
        isError: false,
      },
    },
  };
}

function handleLoadParticipantsFailure(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof loadParticipantsFailure>,
): IInterviewWidgetsReducer {
  return {
    ...state,
    participantsStatesByDataSourceID: {
      ...state.participantsStatesByDataSourceID,
      [action.payload.dataSourceID]: {
        isLoading: false,
        isError: true,
      },
    },
  };
}

function handleLoadParticipantsSuccess(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof loadParticipantsSuccess>,
): IInterviewWidgetsReducer {
  return {
    ...state,
    participantsByDataSourceID: {
      ...state.participantsByDataSourceID,
      [action.payload.dataSourceID]: action.payload.participants,
    },
    participantsStatesByDataSourceID: {
      ...state.participantsStatesByDataSourceID,
      [action.payload.dataSourceID]: {
        isLoading: false,
        isError: false,
      },
    },
  };
}

function handleSearchStringChange(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof changeSearchString>,
): IInterviewWidgetsReducer {
  const { widgetID, searchString } = action.payload;
  const conversationState = state.conversationsStatesByWidgetID[widgetID];
  return {
    ...state,
    conversationsStatesByWidgetID: {
      ...state.conversationsStatesByWidgetID,
      [widgetID]: {
        ...conversationState,
        searchString,
      },
    },
  };
}

function handleLoadAnswersStart(state: IInterviewWidgetsReducer, action: ReturnType<typeof loadAnswersStart>): IInterviewWidgetsReducer {
  const { widgetID, offset, filterQuery } = action.payload;
  const responsesState = state.conversationsStatesByWidgetID[widgetID];
  const configByWidgetID = state.configsByWidgetID[widgetID];

  const shouldCleanResponses = (responsesState.offset > 0 && offset === 0) || !isEqual(configByWidgetID.filter, filterQuery);

  return {
    ...state,
    conversationsStatesByWidgetID: {
      ...state.conversationsStatesByWidgetID,
      [widgetID]: {
        ...state.conversationsStatesByWidgetID[action.payload.widgetID],
        isLoading: true,
        isError: false,
        offset: offset,
      },
    },
    configsByWidgetID: {
      ...state.configsByWidgetID,
      [widgetID]: {
        ...state.configsByWidgetID[widgetID],
        filter: filterQuery,
      },
    },
    conversationsByWidgetID: {
      ...state.conversationsByWidgetID,
      [widgetID]: shouldCleanResponses ? [] : state.conversationsByWidgetID[widgetID],
    },
  };
}

function handleLoadAnswersFailure(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof loadAnswersFailure>,
): IInterviewWidgetsReducer {
  return {
    ...state,
    conversationsStatesByWidgetID: {
      ...state.conversationsStatesByWidgetID,
      [action.payload.widgetID]: {
        ...state.conversationsStatesByWidgetID[action.payload.widgetID],
        isLoading: false,
        isError: true,
      },
    },
  };
}

function handleLoadAnswersSuccess(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof loadAnswersSuccess>,
): IInterviewWidgetsReducer {
  const { widgetID, totalResponses } = action.payload;
  const responses = state.conversationsByWidgetID[widgetID] || [];
  const newResponses = action.payload.responses.map(ResponseModelFactory);

  return {
    ...state,
    conversationsByWidgetID: {
      ...state.conversationsByWidgetID,
      [widgetID]: [...responses, ...newResponses],
    },
    conversationsStatesByWidgetID: {
      ...state.conversationsStatesByWidgetID,
      [widgetID]: {
        ...state.conversationsStatesByWidgetID[widgetID],
        isLoading: false,
        isError: false,
        responsesTotal: totalResponses,
        offset: state.conversationsStatesByWidgetID[widgetID].offset + newResponses.length,
      },
    },
  };
}

function handleSelectConcept(state: IInterviewWidgetsReducer, action: ReturnType<typeof selectConcept>): IInterviewWidgetsReducer {
  const { widgetID, title } = action.payload;
  if (title) {
    const currentConcept = state.configsByWidgetID[action.payload.widgetID].selectedConcept;
    return {
      ...state,
      configsByWidgetID: {
        ...state.configsByWidgetID,
        [widgetID]: {
          ...state.configsByWidgetID[widgetID],
          selectedConcept: currentConcept !== null && currentConcept.title === title ? null : { title: title, isEnabled: true },
        },
      },
    };
  }
  return {
    ...state,
    configsByWidgetID: {
      ...state.configsByWidgetID,
      [widgetID]: {
        ...state.configsByWidgetID[widgetID],
        selectedConcept: null,
      },
    },
  };
}

function handleAssignTopicFromResponseStart(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof assignTopicToResponseStart>,
): IInterviewWidgetsReducer {
  const { widgetID, responseID } = action.payload;
  return {
    ...state,
    responseManagementStatesByWidgetID: {
      ...state.responseManagementStatesByWidgetID,
      [widgetID]: {
        ...state.responseManagementStatesByWidgetID[widgetID],
        [responseID]: {
          isLoading: true,
          isError: false,
        },
      },
    },
  };
}

function handleAssignTopicFromResponseSuccess(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof assignTopicToResponseSuccess>,
): IInterviewWidgetsReducer {
  const { widgetID, responseID, label } = action.payload;
  const responses = state.conversationsByWidgetID[widgetID];
  const responseIndex = responses.findIndex((r) => r.id === responseID);
  if (responseIndex === -1) {
    console.error(`handleAssignTopicFromResponseSuccess: response ${responseID} not found`);
    return state;
  }

  const response = responses[responseIndex];
  response.addLabels([label]);
  const responsesCopy = responses.toSpliced(responseIndex, 1, response);

  return {
    ...state,
    conversationsByWidgetID: {
      ...state.conversationsByWidgetID,
      [widgetID]: responsesCopy,
    },
    responseManagementStatesByWidgetID: {
      ...state.responseManagementStatesByWidgetID,
      [widgetID]: {
        ...state.responseManagementStatesByWidgetID[widgetID],
        [responseID]: {
          isLoading: false,
          isError: true,
        },
      },
    },
  };
}

function handleAssignTopicFromResponseFailure(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof assignTopicToResponseSuccess>,
): IInterviewWidgetsReducer {
  const { widgetID, responseID } = action.payload;
  return {
    ...state,
    responseManagementStatesByWidgetID: {
      ...state.responseManagementStatesByWidgetID,
      [widgetID]: {
        ...state.responseManagementStatesByWidgetID[widgetID],
        [responseID]: {
          isLoading: false,
          isError: true,
        },
      },
    },
  };
}

function handleRemoveTopicFromResponseStart(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof removeTopicFromResponseStart>,
): IInterviewWidgetsReducer {
  const { widgetID, responseID } = action.payload;
  return {
    ...state,
    responseManagementStatesByWidgetID: {
      ...state.responseManagementStatesByWidgetID,
      [widgetID]: {
        ...state.responseManagementStatesByWidgetID[widgetID],
        [responseID]: {
          isLoading: true,
          isError: false,
        },
      },
    },
  };
}

function handleRemoveTopicFromResponseSuccess(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof removeTopicFromResponseSuccess>,
): IInterviewWidgetsReducer {
  const { widgetID, responseID, label } = action.payload;

  const responses = state.conversationsByWidgetID[widgetID];
  const responseIndex = responses.findIndex((r) => r.id === responseID);
  if (responseIndex === -1) {
    console.error(`handleAssignTopicFromResponseSuccess: response ${responseID} not found`);
    return state;
  }

  const response = responses[responseIndex];
  response.removeLabel(label);
  const responsesCopy = responses.toSpliced(responseIndex, 1, response);

  return {
    ...state,
    conversationsByWidgetID: {
      ...state.conversationsByWidgetID,
      [widgetID]: responsesCopy,
    },
    responseManagementStatesByWidgetID: {
      ...state.responseManagementStatesByWidgetID,
      [widgetID]: {
        ...state.responseManagementStatesByWidgetID[widgetID],
        [responseID]: {
          isLoading: false,
          isError: false,
        },
      },
    },
  };
}

function handleRemoveTopicFromResponseFailure(
  state: IInterviewWidgetsReducer,
  action: ReturnType<typeof removeTopicFromResponseFailure>,
): IInterviewWidgetsReducer {
  const { widgetID, responseID } = action.payload;
  return {
    ...state,
    responseManagementStatesByWidgetID: {
      ...state.responseManagementStatesByWidgetID,
      [widgetID]: {
        ...state.responseManagementStatesByWidgetID[widgetID],
        [responseID]: {
          isLoading: false,
          isError: true,
        },
      },
    },
  };
}

export default interviewWidgetsReducer;
