import React from 'react';

import { FilledButton } from '../../../UI/Button';

import { Container, FooterActions, FormGroup, StyledCircularProgress } from '../style';
import ConnectionScheduleGroup from '../ConnectionScheduleGroup';
import ConnectionLanguageSelect from '../ConnectionLanguageSelect';
import ConnectionBrandSelect from '../ConnectionBrandSelect';
import ConnectionSurveySelect from '../ConnectionSurveySelect';

interface IQualtricsConnection
  extends React.ComponentProps<typeof ConnectionScheduleGroup>,
    React.ComponentProps<typeof ConnectionLanguageSelect>,
    React.ComponentProps<typeof ConnectionBrandSelect>,
    React.ComponentProps<typeof ConnectionSurveySelect> {
  isSubmitDisabled: boolean;
  isSubmitting: boolean;
  onSubmitConnection: () => void;
}

function QualtricsConnection(props: IQualtricsConnection) {
  const { isSubmitting, isSubmitDisabled, onSubmitConnection } = props;

  return (
    <>
      <Container data-testid='qualtrics-connection-container'>
        <FormGroup>
          <ConnectionSurveySelect {...props} />
          <ConnectionLanguageSelect {...props} />
          <ConnectionBrandSelect {...props} />
        </FormGroup>
        <ConnectionScheduleGroup {...props} />
      </Container>
      <FooterActions>
        {isSubmitting && <StyledCircularProgress data-testid='qualtrics-connection-progress' size='36px' />}
        <FilledButton data-testid='qualtrics-connection-submit' onClick={onSubmitConnection} disabled={isSubmitDisabled}>
          Finish
        </FilledButton>
      </FooterActions>
    </>
  );
}

export default QualtricsConnection;
