import { ChangeEvent } from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

import { StyledFormControl } from './style';

interface IConnectionLanguageSelect {
  languages: { key: string; value: string }[];
  selectedLanguage: string;
  onSelectLanguage: (language: string) => void;
}

function ConnectionLanguageSelect({ languages, selectedLanguage, onSelectLanguage }: IConnectionLanguageSelect) {
  function handleSelectLanguage(e: ChangeEvent<{ value: unknown }>) {
    onSelectLanguage(e.target.value as string);
  }

  return (
    <StyledFormControl>
      <InputLabel id='language-select-label'>Select Language</InputLabel>
      <Select
        data-testid='connection-language-select'
        value={selectedLanguage}
        labelId='language-select-label'
        onChange={handleSelectLanguage}
      >
        {languages.map((lang) => (
          <MenuItem value={lang.value} key={lang.value}>
            {lang.key}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default ConnectionLanguageSelect;
