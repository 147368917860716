import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import palette from '../tools/palette';

export const AnswerButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 100%;
    max-height: 28px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .MuiButton-label {
    display: inline-block;
    max-height: 28px;
    line-height: 28px;
    font-size: 12px;
    text-transform: none;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
`;

export const OverviewWrapper = styled('div')`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 0;
  }

  div.scroll-wrapper {
    margin: 0 10px;
    padding: 16px 0;
    overflow: hidden;
    max-height: ${(props) => (props.$expand < 0 ? (props.$nonExpandHeigth || 400) + 'px' : props.$expand + 'px')};
  }

  div.show-more {
    padding: 16px;
    cursor: pointer;
    font-size: 12px;

    * {
      color: ${palette.linkColorLight};
    }

    div.label {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const GridWithCenterVerticalAlign = styled(Grid)`
  display: flex;
  align-items: center;
`;
