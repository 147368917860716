import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useFilters from '../../../hooks/useFilters';
import useSummaryBreakdownFetch from '../../../hooks/fetch/useSummaryBreakdownFetch';
import useSummaryBreakdownProcessing from '../../../hooks/processing/useSummaryBreakdownProcessing';

const withSummaryBreakdownLoader = (BaseComponent) => {
  return (props) => {
    const { config, mapBasedBreakdown, query } = props;
    const [filters] = useFilters();
    const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
    const { id: dashboardId, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
    const skipFetching = config.base.collapsed;

    const dataSourceId = config.settings.dataSources[0].id;
    const dashboardDataSource = dashboardDataSources.find((dds) => dds.dataSourceID === dataSourceId);
    const visibleDataSourcesIds = useMemo(
      () => (dashboardDataSource && !dashboardDataSource.isEmpty ? [dataSourceId] : []),
      [dashboardDataSource, dataSourceId],
    );
    const { responses, status } = useSummaryBreakdownFetch(
      dashboardId,
      filters,
      breakdownFilter,
      visibleDataSourcesIds,
      skipFetching,
      query,
    );
    const breakdownData = useSummaryBreakdownProcessing(
      responses,
      filters,
      dataSourceId,
      visibleDataSourcesIds,
      breakdownFilter,
      mapBasedBreakdown,
    );

    return <BaseComponent loading={status} breakdownData={breakdownData} {...props} />;
  };
};

export default withSummaryBreakdownLoader;
