import { calculatePValue } from './statistics';
import { FILTER_ID_ALL } from '../../../constants';
import { ICompareRow, ICompareSubRow } from '../compare';

export const prepareData = (
  data: any,
  filters: any[],
  field: string,
  totalField: string,
): {
  rows: ICompareRow[];
  maxDataSourceTotal: number;
  maxConceptTotal: number;
  maxAbsoluteDifference: number;
} => {
  const rows = [];

  let maxDataSourceTotal = 0;
  let maxConceptTotal = 0;
  let maxAbsoluteDifference = 0;
  const itemsMap = new Map();
  const totalsMap = new Map();
  for (let filterIndex = 0; filterIndex < filters.length; filterIndex++) {
    const filter = filters[filterIndex];
    const dataSourcesData = data[filterIndex];
    itemsMap.set(filter.id, new Map());
    totalsMap.set(filter.id, new Map());

    for (const dataSource of dataSourcesData) {
      itemsMap.get(filter.id).set(dataSource.dataSourceID, new Map());
      totalsMap.get(filter.id).set(dataSource.dataSourceID, dataSource[totalField]);
      maxDataSourceTotal = Math.max(maxDataSourceTotal, dataSource[totalField]);
      for (const item of dataSource.items) {
        maxConceptTotal = Math.max(maxConceptTotal, item[field].value);
        itemsMap.get(filter.id).get(dataSource.dataSourceID).set(item.response, {
          data: item[field],
          response: item.response,
          originalResponse: item.originalResponse,
        });
      }
    }
  }

  const dataSourceIDs = [...itemsMap.get(FILTER_ID_ALL).keys()];
  const filterIds = [...itemsMap.keys()];

  for (const dataSourceID of dataSourceIDs) {
    const labels = [...itemsMap.get(FILTER_ID_ALL).get(dataSourceID).keys()];
    for (const label of labels) {
      const row: ICompareRow = {
        id: `${label}-${dataSourceID}`,
        concept: label,
        dataSourceID,
        data: [],
        dataSourceTotal: totalsMap.get(FILTER_ID_ALL).get(dataSourceID),
        dataSourceTitle: '',
        conceptTotal: itemsMap.get(FILTER_ID_ALL).get(dataSourceID).get(label).data.value,
        response: itemsMap.get(FILTER_ID_ALL).get(dataSourceID).get(label).response,
        originalResponse: itemsMap.get(FILTER_ID_ALL).get(dataSourceID).get(label).originalResponse,
        maxDifferencePercentage: 0,
      };
      const subRows: ICompareSubRow[] = [];
      let maxDifferencePercentage = 0;
      for (let filter1Index = 0; filter1Index < filterIds.length - 1; filter1Index++) {
        for (let filter2Index = filter1Index + 1; filter2Index < filterIds.length; filter2Index++) {
          const filter1ID = filterIds[filter1Index];
          const filter2ID = filterIds[filter2Index];

          let filterMinPercentage = 0;
          let filterMaxPercentage = 0;
          let filterMinValue = 0;
          let filterMaxValue = 0;
          let filterMaxID = filter1ID;
          let filterMinID = filter2ID;

          if (
            itemsMap.get(filter1ID).has(dataSourceID) &&
            itemsMap.get(filter1ID).get(dataSourceID).has(label) &&
            itemsMap.get(filter2ID).has(dataSourceID) &&
            itemsMap.get(filter2ID).get(dataSourceID).has(label)
          ) {
            const data1 = itemsMap.get(filter1ID).get(dataSourceID).get(label).data;
            const data2 = itemsMap.get(filter2ID).get(dataSourceID).get(label).data;
            filterMaxPercentage = data1.percentage;
            filterMaxValue = data1.value;
            filterMinPercentage = data2.percentage;
            filterMinValue = data2.value;
            if (data2.percentage > data1.percentage) {
              filterMaxID = filter2ID;
              filterMinID = filter1ID;
              filterMaxPercentage = data2.percentage;
              filterMaxValue = data2.value;
              filterMinPercentage = data1.percentage;
              filterMinValue = data1.value;
            }
          }

          if (
            itemsMap.get(filter1ID).has(dataSourceID) &&
            itemsMap.get(filter1ID).get(dataSourceID).has(label) &&
            itemsMap.get(filter2ID).has(dataSourceID) &&
            !itemsMap.get(filter2ID).get(dataSourceID).has(label)
          ) {
            const data1 = itemsMap.get(filter1ID).get(dataSourceID).get(label).data;
            filterMinPercentage = 0;
            filterMaxPercentage = data1.percentage;
            filterMinValue = 0;
            filterMaxValue = data1.value;
          }

          if (
            itemsMap.get(filter2ID).has(dataSourceID) &&
            itemsMap.get(filter2ID).get(dataSourceID).has(label) &&
            itemsMap.get(filter1ID).has(dataSourceID) &&
            !itemsMap.get(filter1ID).get(dataSourceID).has(label)
          ) {
            const data2 = itemsMap.get(filter2ID).get(dataSourceID).get(label).data;
            filterMinPercentage = 0;
            filterMaxPercentage = data2.percentage;
            filterMinValue = 0;
            filterMaxValue = data2.value;
            filterMaxID = filter2ID;
            filterMinID = filter1ID;
          }

          if (!(filterMinValue === 0 && filterMaxValue === 0)) {
            maxAbsoluteDifference = Math.max(maxAbsoluteDifference, filterMaxValue - filterMinValue);
            maxDifferencePercentage = Math.max(maxDifferencePercentage, Math.abs(filterMaxPercentage - filterMinPercentage));
            subRows.push({
              differencePercentage: Math.abs(filterMaxPercentage - filterMinPercentage),
              differenceAbsolute: Math.abs(filterMaxValue - filterMinValue),
              filterMaxID,
              filterMinID,
              filterMinPercentage,
              filterMaxPercentage,
              filterMinValue,
              filterMaxValue,
              pValue: calculatePValue(
                filterMinPercentage,
                filterMaxPercentage,
                totalsMap.get(filterMaxID).get(dataSourceID),
                totalsMap.get(filterMinID).get(dataSourceID),
              ),
            });
          }
        }
      }
      if (subRows.length > 0) {
        row.data = subRows;
        row.maxDifferencePercentage = maxDifferencePercentage;
        rows.push(row);
      }
    }
  }

  return { rows, maxDataSourceTotal, maxConceptTotal, maxAbsoluteDifference };
};

export const toggleFilter = (filters: any[], filterIds: string[], filter: any) => {
  if (filterIds.includes(filter.id)) {
    return filterIds.filter((id) => id !== filter.id);
  }

  return [...filterIds, filter.id];
};
