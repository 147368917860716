import { format } from 'date-fns/esm';
import { SOURCES, SOURCES_LIST } from '../../../components/ConnectorsTab/constants';
import {
  AuthProvider,
  AuthSourceEnum,
  BaseConnection,
  BaseProviderAuthentication,
  BaseSurvey,
  ConnectionStatusEnum,
  Integration,
  OperationTypeEnum,
  ProviderAuthentication,
  Survey,
  SurveyGroup,
  SurveySourceEnum,
} from '../../../types/connections';
import { DATE_FORMAT_DISPLAY } from '../../../constants';
import { datadogLogs } from '@datadog/browser-logs';
import { authSourceToSurveySource, getConnectionTypeString, surveySourceToAuthSource } from '../../../components/ConnectorsTab/utils';
import { ScheduleEnum } from '../../../components/ConnectorsTab/types';

export const UnsupportedConnectorsSources = [SurveySourceEnum.SURVEYMONKEY, SurveySourceEnum.TYPEFORM];

const emptySurveyGroup: { source: SurveySourceEnum | null } & Omit<SurveyGroup, 'source'> = {
  source: null,
  responsesTotal: 0,
  lastUpdated: 0,
  dashboardsConnectedTo: 0,
  surveys: [],
};

const createEmptySurveyGroups = () => {
  return SOURCES_LIST.reduce(
    (surveyGroups, source) => {
      surveyGroups[source] = { ...emptySurveyGroup, source, surveys: [] };
      return surveyGroups;
    },
    {} as Record<SurveySourceEnum, SurveyGroup>,
  );
};

export const groupSurveysBySource = (surveys: BaseSurvey[] = []) => {
  const emptySurveyGroups = createEmptySurveyGroups();

  const surveyGroups = surveys.reduce((surveyGroups, survey) => {
    const source = survey.source.toLowerCase() as SurveySourceEnum;
    const enrichmentSource = survey.enrichmentSources?.[0] ?? null;
    if (enrichmentSource) {
      enrichmentSource.updatedAtString = format(new Date(enrichmentSource.updatedAt * 1000), DATE_FORMAT_DISPLAY);
    }
    const enrichedSurvey = {
      ...survey,
      source,
      updatedAtString: format(new Date(survey.updatedAt * 1000), DATE_FORMAT_DISPLAY),
      dashboardsConnectedTo: survey.dashboards?.length || 0,
      enrichmentSource,
    };
    if (!surveyGroups[source]) {
      // log surveys with unrecognized sources in datadog
      datadogLogs.logger.error('encountered a survey with an unrecognized survey source type on the connections page', {
        id: survey.id,
        source: survey.source,
        title: survey.title,
      });
      return surveyGroups;
    }
    surveyGroups[source].surveys.push(enrichedSurvey);
    surveyGroups[source].responsesTotal += enrichedSurvey.responsesTotal;
    if (!surveyGroups[source].lastUpdated || surveyGroups[source].lastUpdated < enrichedSurvey.updatedAt) {
      surveyGroups[source].lastUpdated = enrichedSurvey.updatedAt;
      surveyGroups[source].lastUpdatedString = enrichedSurvey.updatedAtString;
    }
    return surveyGroups;
  }, emptySurveyGroups);

  (Object.keys(surveyGroups) as SurveySourceEnum[]).forEach((source) => {
    // take the size of the unique set of dashboards connected to all surveys in the group
    surveyGroups[source].dashboardsConnectedTo = new Set(
      // compile a non-unique list of dashboard ids from all surveys in the group
      surveyGroups[source].surveys
        .reduce((dashboardsAggregate: string[], survey) => dashboardsAggregate.concat(survey.dashboards?.map(({ id }) => id) ?? []), [])
        .filter((dashboard) => !!dashboard),
    ).size;
  });

  return surveyGroups;
};

export const assembleIntegrations = (
  authProviders: AuthProvider[] = [],
  auths: BaseProviderAuthentication[] = [],
  surveyGroups: Record<SurveySourceEnum, SurveyGroup> = createEmptySurveyGroups(),
  allConnections: BaseConnection[] = [],
): Integration[] => {
  const authProviderMap = authProviders.reduce(
    (
      {
        nameMap,
        idMap,
      }: {
        nameMap: Partial<Record<AuthSourceEnum, AuthProvider>>;
        idMap: Record<string, AuthProvider>;
      },
      authProvider,
    ) => {
      nameMap[authProvider.name] = authProvider;
      idMap[authProvider.id] = authProvider;
      return { nameMap, idMap };
    },
    { idMap: {}, nameMap: {} },
  );

  const authMap = auths.reduce((map: Partial<Record<SurveySourceEnum, ProviderAuthentication>>, auth) => {
    const surveySource = authSourceToSurveySource(auth.systemType);
    if (!surveySource || !auth.hasAccess) {
      return map;
    }
    map[surveySource] = {
      ...auth,
      authProvider: authProviderMap.idMap[auth.providerID],
    };
    return map;
  }, {});

  const integrations: Integration[] = SOURCES_LIST.filter((source) => source !== SurveySourceEnum.CSV).map((source) => {
    const authSource = surveySourceToAuthSource(source);
    const authProvider = authSource ? authProviderMap.nameMap[authSource] : null;
    const auth = authMap[source] ?? undefined;
    const allAuthsForSource = auths.filter(({ systemType }) => systemType === authSource).map(({ id }) => id);
    const integration: Integration = {
      connections: [],
      displayName: authProvider?.displayName ?? SOURCES[source]?.title ?? '',
      logoURL: authProvider?.logoURL ?? '',
      source,
      name: source,
      icon: SOURCES[source]?.icon ?? '',
      authentication: auth,
      authProviderID: authProvider?.id ?? '',
    };
    let connections: BaseConnection[] = [];
    if (source === SurveySourceEnum.SURVEYMONKEY) {
      connections = (surveyGroups ?? {})[SurveySourceEnum.SURVEYMONKEY]?.surveys?.map(surveyToConnection) ?? [];
    } else if (source === SurveySourceEnum.TYPEFORM) {
      connections = (surveyGroups ?? {})[SurveySourceEnum.TYPEFORM]?.surveys?.map(surveyToConnection) ?? [];
    } else {
      connections = allConnections.filter(({ authID }) => allAuthsForSource.includes(authID));
    }
    return {
      ...integration,
      connections: connections.map((connection) => ({
        ...connection,
        nextRunString: connection.nextRun ? format(new Date(connection.nextRun * 1000), DATE_FORMAT_DISPLAY) : '',
        lastRunString: connection.lastRun
          ? format(new Date(connection.lastRun * 1000), DATE_FORMAT_DISPLAY + ' hh:mm aaa')
          : 'Process scheduled to run',
        actionTypeString: getConnectionTypeString(connection.actionType),
      })),
    };
  });

  return integrations;
};

const surveyToConnection = (survey: Partial<Survey>): BaseConnection => ({
  id: survey.id ?? '',
  authID: '',
  name: survey.title ?? '',
  actionType: OperationTypeEnum.IMPORT,
  nextRun: 0,
  lastRun: survey.updatedAt ?? 0,
  status: ConnectionStatusEnum.CONNECTED,
  interval: ScheduleEnum.ONE_TIME,
});
