import { IQualtricsSurvey, QUALTRICS_RESET_SURVEYS, QUALTRICS_UPDATE_SURVEYS, resetSurveys, updateSurveys } from '../actions/qualtrics';

export interface IQualtricsReducer {
  surveys: IQualtricsSurvey[];
  initialized: boolean;
  error?: boolean;
}

const initialState: IQualtricsReducer = {
  surveys: [],
  initialized: false,
};

type QualtricsActionTypes = ReturnType<typeof updateSurveys>;

export const qualtricsReducer = (state = initialState, action: QualtricsActionTypes) => {
  switch (action.type) {
    case QUALTRICS_UPDATE_SURVEYS:
      return handleUpdateSurveys(state, action);
    case QUALTRICS_RESET_SURVEYS:
      return handleResetSurveys(state, action);
    default:
      return state;
  }
};

const handleUpdateSurveys = (state: IQualtricsReducer, action: ReturnType<typeof updateSurveys>): IQualtricsReducer => {
  const { surveys, error } = action.payload;
  return {
    ...state,
    surveys: surveys || [],
    error,
    initialized: !error,
  };
};

const handleResetSurveys = (state: IQualtricsReducer, action: ReturnType<typeof resetSurveys>): IQualtricsReducer => {
  return {
    ...state,
    surveys: [],
    error: false,
    initialized: false,
  };
};
