import React from 'react';
import { ReactComponent as SendIcon } from '../../../../../../../assets/icons/send-icon.svg';
import styled from 'styled-components';

const ChatInputContainer = styled.div`
  position: relative;
  border-radius: 10px;
  height: 48px;
  padding: 5px 20px 5px 12px;
  border: 1px solid var(--medium-gray);
  background-color: var(--white);
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  padding-right: 10px;
  font-family: 'Rubik';
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: var(--dark-gray);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Rubik';
  }
`;

const SendIconStyled = styled(SendIcon)<{ $isEnabled: boolean }>`
  position: absolute;
  right: 9px;
  fill: ${({ $isEnabled }) => ($isEnabled ? 'var(--beehive-purple)' : 'var(--dark-gray)')};
  stroke: ${({ $isEnabled }) => ($isEnabled ? 'var(--beehive-purple)' : 'var(--dark-gray)')};
  top: calc(50%);
  transform: translateY(-50%);
  cursor: ${({ $isEnabled }) => ($isEnabled ? 'pointer' : 'not-allowed')};
`;

interface IChatInput {
  prompt: string;
  isSendAvailable: boolean;
  errorMessage?: string;
  onPromptChange: (newPrompt: string) => void;
  onSendClick: () => void;
}

function ChatInput({ prompt, isSendAvailable, errorMessage, onPromptChange, onSendClick }: IChatInput) {
  function handlePromptChange(e: React.ChangeEvent<HTMLInputElement>) {
    onPromptChange(e.target.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === 'Enter') {
      onSendClick();
    }
  }

  return (
    <ChatInputContainer>
      <Input
        disabled={!isSendAvailable}
        title={!isSendAvailable ? errorMessage : prompt}
        placeholder='Ask any question...'
        value={prompt}
        onChange={handlePromptChange}
        onKeyDown={handleKeyDown}
      />
      <SendIconStyled title={errorMessage} $isEnabled={isSendAvailable} onClick={onSendClick} />
    </ChatInputContainer>
  );
}

export default ChatInput;
