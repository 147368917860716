import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeConnectionsModal } from '../../../store/actions/connectors';

import AddConnectionDialog from './AddConnectionDialog';

function AddConnectionDialogContainer() {
  const { open: isOpen, isAuthEdit } = useAppSelector((state) => state.connectors).addConnection;
  const source = useAppSelector((state) => state.connectors).selectedIntegration?.source;
  const dispatch = useAppDispatch();

  function onClose() {
    dispatch(closeConnectionsModal());
  }

  return <AddConnectionDialog isOpen={isOpen} isAuthEdit={isAuthEdit} source={source} onClose={onClose} />;
}

export default AddConnectionDialogContainer;
