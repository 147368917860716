import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

import { GROUP_OTHER_TITLE } from '../../../constants';
import { DashboardDataSource } from '../../../types';
import { useAppSelector } from '../../../store/hooks';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { debounce } from 'lodash';

const GroupsWrapper = styled.div`
  border: 1px solid var(--medium-gray);
  border-radius: 4px;
  margin: 10px 0;
  max-height: 40vh;
  overflow-y: auto;
  padding: 10px 20px;
`;

const CustomFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    align-items: flex-start;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiCheckbox-root {
    padding: 0 9px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked,
  .MuiCheckbox-indeterminate {
    color: var(--beehive-purple);
  }
`;

const GroupControlLabel = styled(CustomFormControlLabel)`
  .MuiFormControlLabel-label {
    color: var(--grey);
    font-weight: bold;
  }
`;

const DataSourceControlLabel = styled(CustomFormControlLabel)`
  &.MuiFormControlLabel-root {
    padding-left: 30px;
  }
`;

interface IPreparedDataSource {
  id: string;
  title: string;
}

interface IPreparedGroup {
  id: string;
  title: string;
  dataSources: IPreparedDataSource[];
}

interface IDataSourcesSelectorListProps {
  visibleDataSources: DashboardDataSource[];
  searchString: string;
  localDataSourceIDs: string[];
  titleField: 'title' | 'originalTitle';
  onChange: (dataSourceIDs: string[]) => void;
}

function DataSourcesSelectorListContainer({
  visibleDataSources,
  searchString,
  localDataSourceIDs,
  titleField,
  onChange,
}: IDataSourcesSelectorListProps) {
  const groups = useAppSelector((state) => state.dashboard.dashboard?.groups ?? []);
  const dashboardTitle = useAppSelector((state) => state.dashboard.dashboard?.title ?? '');
  const searchTrackingProperties = useRef({});

  const preparedGroups = useMemo<IPreparedGroup[]>(() => {
    return [...groups]
      .concat({
        id: '',
        order: Number.MAX_SAFE_INTEGER,
        title: GROUP_OTHER_TITLE,
        originalTitle: GROUP_OTHER_TITLE,
      })
      .sort((a, b) => a.order - b.order)
      .map((group) => {
        const groupDataSources = visibleDataSources
          .filter((dataSource) => dataSource.groupID === group.id)
          .sort((a, b) => a.order - b.order)
          .map((dataSource) => ({
            id: dataSource.dataSourceID,
            title: dataSource[titleField],
          }));

        return {
          id: group.id,
          title: group[titleField],
          dataSources: groupDataSources,
        };
      })
      .filter((group) => group.dataSources.length > 0);
  }, [groups, visibleDataSources, titleField]);

  const filteredGroups = useMemo<IPreparedGroup[]>(() => {
    const res = preparedGroups.reduce((groups, group) => {
      const isEntireGroupIncluded = group.title.toLowerCase().includes(searchString.toLowerCase());
      if (isEntireGroupIncluded) {
        return groups.concat(group);
      }
      const dataSources = group.dataSources.filter((dataSource) => dataSource.title.toLowerCase().includes(searchString.toLowerCase()));
      if (dataSources.length > 0) {
        return groups.concat({
          ...group,
          dataSources,
        });
      }
      return groups;
    }, [] as IPreparedGroup[]);

    return res;
  }, [preparedGroups, searchString]);

  const lazySearchTrack = useCallback(
    debounce(() => {
      eventsTracker.track(EVENTS.SELECT_DATA_SOURCES_SEARCH, searchTrackingProperties.current);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (searchString.length > 0) {
      updateSearchTrackingProperties();
      lazySearchTrack();
    }
  }, [filteredGroups]);

  const updateSearchTrackingProperties = () => {
    searchTrackingProperties.current = {
      'Dashboard name': dashboardTitle,
      'Applied search text': searchString,
      'Returned Group names': filteredGroups.map((group) => group.title),
      'Returned Data Sources': filteredGroups.reduce(
        (names: string[], group) => names.concat(group.dataSources.map((dataSource) => dataSource.title)),
        [],
      ),
    };
  };

  function isGroupSelected(groupId: string) {
    return visibleDataSources
      .filter((dataSource) => dataSource.groupID === groupId)
      .every((dataSource) => localDataSourceIDs.includes(dataSource.dataSourceID));
  }

  function isGroupIndeterminate(groupId: string) {
    const groupDataSources = visibleDataSources.filter((dataSource) => dataSource.groupID === groupId);
    const selectedGroupDataSources = groupDataSources.filter((dataSource) => localDataSourceIDs.includes(dataSource.dataSourceID));
    return selectedGroupDataSources.length > 0 && selectedGroupDataSources.length !== groupDataSources.length;
  }

  function handleGroupClick(event: React.ChangeEvent<HTMLInputElement>) {
    const groupId = event.target.name;
    const isSelected = isGroupSelected(groupId);

    const group = filteredGroups.find(({ id }) => id === groupId);
    if (!group) {
      return;
    }
    const groupDataSourcesIds = group.dataSources.map((dataSource) => dataSource.id);

    let newDataSourceIDs: string[];
    if (isSelected) {
      newDataSourceIDs = localDataSourceIDs.filter((id) => !groupDataSourcesIds.includes(id));
    } else {
      newDataSourceIDs = [...localDataSourceIDs];
      for (const dataSourceId of groupDataSourcesIds) {
        if (!newDataSourceIDs.includes(dataSourceId)) {
          newDataSourceIDs.push(dataSourceId);
        }
      }
    }
    onChange(newDataSourceIDs);
  }

  function handleDataSourceClick(event: React.ChangeEvent<HTMLInputElement>) {
    const clickedId = event.target.name;
    const newDataSourceIDs = localDataSourceIDs.filter((id) => id !== clickedId);
    if (newDataSourceIDs.length === localDataSourceIDs.length) {
      newDataSourceIDs.push(clickedId);
    }
    onChange(newDataSourceIDs);
  }

  function dataSourcesList(dataSources: IPreparedDataSource[]) {
    return dataSources.map((dataSource) => {
      return (
        <DataSourceControlLabel
          data-testid={`data-source-control-label-${dataSource.id}`}
          key={dataSource.id}
          control={
            <Checkbox
              checked={localDataSourceIDs.includes(dataSource.id)}
              onChange={handleDataSourceClick}
              name={dataSource.id}
              size='small'
            />
          }
          label={dataSource.title}
        />
      );
    });
  }

  const groupsList = filteredGroups.map((group) => (
    <div key={group.id}>
      <GroupControlLabel
        data-testid={`group-control-label-${group.id}`}
        control={
          <Checkbox
            checked={isGroupSelected(group.id)}
            onChange={handleGroupClick}
            name={group.id}
            size='small'
            indeterminate={isGroupIndeterminate(group.id)}
          />
        }
        label={group.title}
      />
      {dataSourcesList(group.dataSources)}
    </div>
  ));

  return <GroupsWrapper>{groupsList.length > 0 ? groupsList : <>Data source(s) not found</>}</GroupsWrapper>;
}

export default DataSourcesSelectorListContainer;
