import { useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { OPTION_SELECT_SOURCE } from '../constants';
import palette from '../../tools/palette';

const Total = styled('span')`
  color: ${palette.linkColorLight};
`;

export const ControlFieldSelector = ({ value, options, className, handleOnChange, disabled, testID }) => {
  const handleChange = (event, value) => {
    handleOnChange(value.name);
  };

  const extendedOptions = useMemo(
    () =>
      options.map((options) => {
        if (options.id === OPTION_SELECT_SOURCE.name) {
          return OPTION_SELECT_SOURCE;
        }
        return options;
      }),
    [options],
  );

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        data-tooltip-id='base-tooltip'
        data-tooltip-content={params.inputProps.value}
        label='Source'
        variant='outlined'
      />
    );
  };

  const selectedOption = extendedOptions.find((option) => option.name === value);

  const getOptionDisabled = (option) => {
    return option.name === OPTION_SELECT_SOURCE.name;
  };

  const renderOption = (option) => {
    const total = 'total' in option ? <Total>({option.total})</Total> : '';
    return (
      <Box component='span'>
        {option.label} {total}
      </Box>
    );
  };

  return (
    <Autocomplete
      disableClearable
      size='small'
      value={selectedOption}
      options={extendedOptions}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={getOptionDisabled}
      style={{ width: 300 }}
      renderInput={renderInput}
      renderOption={renderOption}
      onChange={handleChange}
      disabled={disabled}
      className={className}
      data-testid={testID}
      data-value={value}
    />
  );
};

ControlFieldSelector.displayName = 'ControlFieldSelector';
