import React from 'react';

import { StyledSelect } from './styles';

import { UploadDropdownOutput } from './UploadDropdownOutput';

interface IUploadDropDownSelect {
  selectedDashboards: string[];
  open: boolean;
  bubbleColor: string;
  bubbleTextColor: string;
  bubbleTextWeight: string;
  bubbleTextHover: string;
  anchorOriginVertical: number | 'top' | 'center' | 'bottom';
  transformOriginVertical: number | 'top' | 'center' | 'bottom';
  onBubbleDeleteClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCheckboxChange: (value: string) => void;
  onOpen: () => void;
  onClose: () => void;
  UploadDropdownContent: () => JSX.Element;
}

function UploadDropdownSelect({
  selectedDashboards,
  open,
  bubbleColor,
  bubbleTextColor,
  bubbleTextWeight,
  bubbleTextHover,
  anchorOriginVertical,
  transformOriginVertical,
  onBubbleDeleteClick,
  onCheckboxChange,
  onOpen,
  onClose,
  UploadDropdownContent,
}: IUploadDropDownSelect) {
  return (
    <StyledSelect
      data-testid='file-upload-dropdown'
      labelId='demo-multiple-checkbox-label'
      id='demo-multiple-checkbox'
      multiple
      value={selectedDashboards}
      name='dashboard'
      displayEmpty
      disableUnderline
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      MenuProps={{
        anchorOrigin: {
          vertical: anchorOriginVertical,
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: transformOriginVertical,
          horizontal: 'center',
        },
        getContentAnchorEl: null,
      }}
      renderValue={function () {
        return (
          <UploadDropdownOutput
            selectedDashboards={selectedDashboards}
            bubbleColor={bubbleColor}
            bubbleTextColor={bubbleTextColor}
            bubbleTextWeight={bubbleTextWeight}
            bubbleTextHover={bubbleTextHover}
            handleBubbleDeleteClick={onBubbleDeleteClick}
            handleCheckboxChange={onCheckboxChange}
          />
        );
      }}
    >
      {UploadDropdownContent()}
    </StyledSelect>
  );
}

export default UploadDropdownSelect;
