import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const RowLeft = styled.div`
  display: flex;
  gap: 8px;
  flex-shrink: 0;
  min-width: 50%;
`;

export const RowRight = styled.div`
  display: flex;
  flex-shrink: 0;
  min-width: 50%;
`;
