import distinctColors from 'distinct-colors';
import palette from '../tools/palette';
import { FILTER_ID_ALL } from '../../constants';

export const NUMBER_OF_DISTINCT_COLORS = 30;

const generateChartColors = () => {
  return distinctColors({
    count: NUMBER_OF_DISTINCT_COLORS,
    hueMin: 0,
    hueMax: 360,
    chromaMin: 20,
    chromaMax: 100,
    lightMin: 15,
    lightMax: 80,
  }).map((c) => c.hex());
};

export const chartColors = generateChartColors();

export const TOOLBOX_MOVING_AVERAGE_BASE = {
  show: true,
  title: 'Moving Average',
  icon: 'path://M19.71 9.71 22 12V6h-6l2.29 2.29-4.17 4.17c-.39.39-1.02.39-1.41 0l-1.17-1.17c-1.17-1.17-3.07-1.17-4.24 0L2 16.59 3.41 18l5.29-5.29c.39-.39 1.02-.39 1.41 0l1.17 1.17c1.17 1.17 3.07 1.17 4.24 0l4.19-4.17z',
  iconStyle: {
    color: palette.primaryColorLightHover,
    borderColor: palette.primaryColorLightHover,
  },
};

export const getAppliedFiltersCount = (filters) => {
  return filters.filter((f) => f.id !== FILTER_ID_ALL).length;
};

export const emulateDocumentMouseUp = () => {
  const event = new MouseEvent('mouseup', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  document.dispatchEvent(event);
};

export const formatNumber = (number) => {
  return number.toLocaleString('en-US', { maximumFractionDigits: 2 });
};
