import React from 'react';

import FileUploadDropdown from './FileUploadDropdown';

const fileSourceOptions = ['Typeform', 'SurveyMonkey', 'Qualtrics', 'UserTesting', 'Medallia', 'Dovetail', 'Google Meet', 'Zoom', 'Other'];

function useFilteredDashboards(
  dashboardSearch: string,
  dashboards: string[],
  selectedDashboards: string[],
  newSelectedDashboards: string[],
) {
  const [filteredDashboards, setFilteredDashboards] = React.useState<string[]>([]);
  const newSelectedDashboardsSplit = newSelectedDashboards.map((dashboard) => {
    const [dashboardSplit] = dashboard.split(':');
    return dashboardSplit;
  });

  React.useEffect(() => {
    setFilteredDashboards(
      [...dashboards, ...newSelectedDashboardsSplit].filter((dashboard) => dashboard.toLowerCase().includes(dashboardSearch.toLowerCase())),
    );
  }, [dashboardSearch, dashboards, selectedDashboards]);

  return filteredDashboards;
}

interface IFileUploadDropdownContainer {
  dashboards: string[];
  selectedDashboards: string[];
  newSelectedDashboards: string[];
  fileSource: string;
  handleSelectedDashboardsSet: (dashboards: string[]) => void;
  onNewSelectedDashboardsSet: (newDashboards: string[]) => void;
  onFileSourceChange: (source: string) => void;
}

function FileUploadDropdownContainer({
  dashboards,
  selectedDashboards,
  newSelectedDashboards,
  fileSource,
  handleSelectedDashboardsSet,
  onNewSelectedDashboardsSet,
  onFileSourceChange,
}: IFileUploadDropdownContainer) {
  const [dashboardSearch, onDashboardSearchChange] = React.useState('');
  const filteredDashboards = useFilteredDashboards(dashboardSearch, dashboards, selectedDashboards, newSelectedDashboards);
  const [isOpen, onIsOpen] = React.useState(false);

  const handleCheckboxChange = (dashboard: string) => {
    if (selectedDashboards.includes(dashboard)) {
      handleSelectedDashboardsSet(selectedDashboards.filter((d) => d !== dashboard));
    } else {
      handleSelectedDashboardsSet([...selectedDashboards, dashboard]);
    }
  };

  function onClose() {
    onIsOpen(false);
    onDashboardSearchChange('');
  }

  function onOpen() {
    onIsOpen(true);
  }

  return (
    <FileUploadDropdown
      selectedDashboards={selectedDashboards}
      fileSource={fileSource}
      dashboards={dashboards}
      filteredDashboards={filteredDashboards}
      newSelectedDashboards={newSelectedDashboards}
      fileSourceOptions={fileSourceOptions}
      isOpen={isOpen}
      onOpen={onOpen}
      handleClose={onClose}
      onSelectedDashboardsSet={handleSelectedDashboardsSet}
      onNewSelectedDashboardsSet={onNewSelectedDashboardsSet}
      handleFileSourceChange={onFileSourceChange}
      handleDashboardSearchChange={onDashboardSearchChange}
      handleCheckboxChange={handleCheckboxChange}
    />
  );
}

export default FileUploadDropdownContainer;
