import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

const OverlayPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  min-height: 350px;
  padding: 15px;
  overflow: auto;
  border-radius: 8px;
  ${(props) => props.fixed && 'width: 1200px;'}
`;

export default OverlayPaper;
