import { JsonModel } from './JsonModel';
import { EMPTY_QUERY } from '../components/FilterForm/lib';

export const mockGroup = (group = null) => {
  if (!!group || group === '') {
    return group;
  }

  const mockGroups = ['', 'General', 'Stuff', 'Items'];

  const M = 10;
  const N = Math.random() * M;
  const D = M / mockGroups.length;

  for (let i = 0; i < mockGroups.length; i++) {
    if (D * i > N) {
      return mockGroups[i];
    }
  }

  return mockGroups[0];
};

export class FilterResponseModel extends JsonModel {
  constructor(response, originalResponse, displayResponse, pct, value, original = [], children) {
    super();

    this.response = response;
    this.originalResponse = originalResponse;
    this.displayResponse = displayResponse;
    this.pct = pct;
    this.value = value;
    this.original = original;
    this.children = children;
  }
  fromJson(_json) {
    super.fromJson(_json);
    const pct = parseFloat(_json['pct']) || 0;
    this.response = _json['response'];
    this.originalResponse = _json['originalResponse'];
    this.displayResponse = 'displayResponse' in _json ? _json['displayResponse'] : _json['response'];
    this.pct = parseFloat(pct.toFixed(0));
    this.value = _json['value']; //absolute number
    this.original = _json['original'] || [];
    this.children = _json['children'];
    return this;
  }
}
export class FilterToApplyModel extends JsonModel {
  constructor(id = '', responses = null, excluded = false, isSentiment = false) {
    super();

    this.excluded = excluded;
    this.id = id;
    this.responses = responses || [];
    this.isSentiment = isSentiment;
  }

  /**
   * @param {string} response
   * @returns {FilterToApplyModel}
   */
  addResponse(response) {
    if (!this.responses.includes(response)) {
      this.responses.push(response);
    }
    return this;
  }
  removeResponse(response) {
    if (this.responses.includes(response)) {
      this.responses.splice(this.responses.indexOf(response), 1);
    }
    return this;
  }
  fromJson(_json) {
    super.fromJson(_json);
    this.id = _json['dataSourceID']; //FilterModel.id
    this.responses = _json['values'] || []; //List of FilterResponseModel.response
    this.isSentiment = _json['isSentiment'];
    return this;
  }
  toJsonString() {
    return JSON.stringify({ dataSourceID: this.id, values: this.responses });
  }
  clone() {
    return new FilterToApplyModel(this.id, [...this.responses]);
  }
}
export class FilterModel extends JsonModel {
  constructor(
    id = '',
    title = '',
    originalTitle = '',
    displayTitle = '',
    responsesData = [],
    usersNum = 0,
    type,
    analyzed,
    status,
    sentimentSummary = {},
    originalUsersNum = 0,
    originalAnalyzed = 0,
    order,
    visible,
  ) {
    super();

    this.group = 'General';
    this.id = id;
    this.title = title;
    this.originalTitle = originalTitle;
    this.displayTitle = displayTitle;
    this.usersNum = usersNum;
    this.responsesData = responsesData;
    this.type = type;
    this.analyzed = analyzed;
    this.status = status;
    this.sentimentSummary = sentimentSummary;
    this.originalUsersNum = originalUsersNum;
    this.originalAnalyzed = originalAnalyzed;
    this.order = order;
    this.visible = visible;
  }
  fromJson(_json) {
    super.fromJson(_json);
    this.id = _json['id'];
    // this.group = _json['group'] || this.group;
    this.group = mockGroup(_json['group']);
    this.responsesData = _json['items'].map(FilterResponseModelFactory) || []; //responses
    this.title = _json['title'];
    this.originalTitle = _json['originalTitle'];
    this.displayTitle = 'displayTitle' in _json ? _json['displayTitle'] : _json['title'];
    this.usersNum = _json['usersNum'];
    this.type = _json['type'];
    this.analyzed = _json['analyzed'];
    this.status = _json['status'];
    this.sentimentSummary = _json['sentimentSummary'] || {};
    this.originalUsersNum = _json['originalUsersNum'];
    this.originalAnalyzed = _json['originalAnalyzed'];
    this.order = _json['order'];
    this.visible = _json['visible'];
    return this;
  }
  toJsonString() {
    return JSON.stringify({
      id: this.id,
      title: this.title,
      usersNum: this.usersNum,
      items: this.responsesData.map((i) => i.toJson()),
    });
  }
}
export class SavedFilterModel extends JsonModel {
  constructor(id = '', dashboardID = '', name = '', userID = '', query = EMPTY_QUERY, valid = true, description = '') {
    super();

    this.id = id;
    this.dashboardID = dashboardID;
    this.userID = userID;
    this.name = name;
    this.description = description;
    this.query = query;
    this.valid = valid;
  }
  fromJson(_json) {
    super.fromJson(_json);
    this.dashboardID = _json['dashboardID'];
    this.id = _json['id'];
    this.name = _json['name'];
    this.description = _json['description'] ?? '';
    this.userID = _json['userID'];
    this.query = _json['filter'];
    this.valid = _json['valid'];
    return this;
  }

  toJsonString() {
    return JSON.stringify({
      id: this.id,
      dashboardID: this.dashboardID,
      name: this.name,
      description: this.description ?? '',
      userID: this.userID,
      filter: this.query,
    });
  }
}

export const FilterToApplyModelFactory = (_json) => {
  return new FilterToApplyModel().fromJson(_json);
};
export const SavedFilterModelFactory = (_json) => {
  return new SavedFilterModel().fromJson(_json);
};
export const FilterModelFactory = (_json) => {
  return new FilterModel().fromJson(_json);
};
export const FilterResponseModelFactory = (_json) => {
  return new FilterResponseModel().fromJson(_json);
};
