import React from 'react';
import styled from 'styled-components';
import { checkRules } from '../utils/passwordValidator';

const Container = styled.div``;
const Header = styled.div`
  color: var(--black);

  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: bolder;
  line-height: 120%;
`;
const Rules = styled.ul`
  list-style: none;
  padding-left: 25px;

  &:first-child {
    margin-top: 8px;
  }
`;
const Rule = styled.li<{ $isValid: boolean; $isGreyedOut?: boolean }>`
  font-family: Rubik;
  color: ${({ $isValid, $isGreyedOut }) =>
    $isValid ? 'var(--sentiment-green)' : $isGreyedOut ? 'var(--gray-disabled)' : 'var(--sentiment-gray)'};
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &::before {
    font-family: Rubik;
    position: absolute;
    ${({ $isValid }) => ($isValid ? "content: '✓'" : "content: '•'")};
    font-size: 16px;
    text-align: right;
    left: -24px;
    width: 10px;
    height: 10px;
    border-radius: 0;
  }
`;

interface IPasswordHelper {
  passwordCheckInfo: ReturnType<typeof checkRules>;
}
function PasswordHelper({ passwordCheckInfo }: IPasswordHelper) {
  const qualifiedRulesAmount = Object.values(passwordCheckInfo.qualifiedRules).reduce((acc, cur) => {
    if (cur) {
      acc += 1;
    }

    return acc;
  }, 0);

  return (
    <Container>
      <Header>Your password should contain:</Header>
      <Rules>
        <Rule $isValid={passwordCheckInfo.isLengthValid}>At least 8 characters</Rule>
        <Rule $isValid={qualifiedRulesAmount > 2}>At least 3 of the following:</Rule>
        <Rules>
          <Rule
            $isGreyedOut={qualifiedRulesAmount > 2 && !passwordCheckInfo.qualifiedRules.isLowerCasePresent}
            $isValid={passwordCheckInfo.qualifiedRules.isLowerCasePresent}
          >
            Lower case letters (a-z)
          </Rule>
          <Rule
            $isGreyedOut={qualifiedRulesAmount > 2 && !passwordCheckInfo.qualifiedRules.isUpperCasePresent}
            $isValid={passwordCheckInfo.qualifiedRules.isUpperCasePresent}
          >
            Upper case letters (A-Z)
          </Rule>
          <Rule
            $isGreyedOut={qualifiedRulesAmount > 2 && !passwordCheckInfo.qualifiedRules.areNumbersPresent}
            $isValid={passwordCheckInfo.qualifiedRules.areNumbersPresent}
          >
            Numbers (0-9)
          </Rule>
          <Rule
            $isGreyedOut={qualifiedRulesAmount > 2 && !passwordCheckInfo.qualifiedRules.areSpecialCharactersPresent}
            $isValid={passwordCheckInfo.qualifiedRules.areSpecialCharactersPresent}
          >
            Special characters (e.g. !@#$%^&*)
          </Rule>
        </Rules>
        <Rule $isValid={passwordCheckInfo.isLessThanTwoEqualSymbolsInRow}>No more than 2 identical characters in a row</Rule>
      </Rules>
    </Container>
  );
}

export default PasswordHelper;
