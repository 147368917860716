import LoadingLabel from '../LoadingLabel/LoadingLabel';

const LoadingOverlay = () => (
  <div
    style={{
      position: 'absolute',
      zIndex: 2,
      backgroundColor: '#fff',
      top: 4,
      left: 4,
      right: 4,
      bottom: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: '0.9',
    }}
  >
    <LoadingLabel loading={true} />
  </div>
);

export default LoadingOverlay;
