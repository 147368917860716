export const LANGUAGES_UPDATE_LANGUAGES = 'LANGUAGES_UPDATE_LANGUAGES';

interface IUpdateLanguages {
  languages?: Record<string, string>;
  error?: boolean;
}
export const updateLanguages = ({ languages, error }: IUpdateLanguages) => ({
  type: LANGUAGES_UPDATE_LANGUAGES,
  payload: {
    languages,
    error,
  },
});
