import TrendsWidgetChartWrapper from '../TrendsWidgetChartWrapper';
import useTrendsPlainFetch from '../../../../hooks/fetch/useTrendsPlainFetch';
import { FETCH_STATUS } from '../../../../constants';

const TrendsWidgetContentPlain = ({
  config,
  selectedItems,
  filters,
  dashboardId,
  dataSourceId,
  aggregationRange,
  refCsvExport,
  refContent,
  refFiltersCount,
  query,
}) => {
  const skipFetching = config.base.collapsed;
  const { responses, status } = useTrendsPlainFetch(
    dashboardId,
    filters,
    dataSourceId,
    selectedItems,
    aggregationRange,
    skipFetching,
    query,
  );
  return (
    <TrendsWidgetChartWrapper
      config={config}
      loading={status !== FETCH_STATUS.LOADED}
      plainResponses={responses}
      filters={filters}
      refCsvExport={refCsvExport}
      refContent={refContent}
      refFiltersCount={refFiltersCount}
    />
  );
};

export default TrendsWidgetContentPlain;
