// @ts-nocheck
export const FILTER_RESET = 'FILTER_RESET';
export const FILTER_SET_QUERY = 'FILTER_SET_QUERY';
export const DASHBOARD_UPDATE_SAVED_FILTER_START = 'DASHBOARD_UPDATE_SAVED_FILTER_START';
export const DASHBOARD_UPDATE_SAVED_FILTER_SUCCESS = 'DASHBOARD_UPDATE_SAVED_FILTER_SUCCESS';
export const DASHBOARD_UPDATE_SAVED_FILTER_ERROR = 'DASHBOARD_UPDATE_SAVED_FILTER_ERROR';
export const DASHBOARD_CREATE_SAVED_FILTER_START = 'DASHBOARD_CREATE_SAVED_FILTER_START';
export const DASHBOARD_CREATE_SAVED_FILTER_SUCCESS = 'DASHBOARD_CREATE_SAVED_FILTER_SUCCESS';
export const DASHBOARD_CREATE_SAVED_FILTER_ERROR = 'DASHBOARD_CREATE_SAVED_FILTER_ERROR';
export const DASHBOARD_FETCH_TOTALS_START = 'DASHBOARD_FETCH_TOTALS_START';
export const DASHBOARD_FETCH_TOTALS_SUCCESS = 'DASHBOARD_FETCH_TOTALS_SUCCESS';
export const DASHBOARD_FETCH_TOTALS_ERROR = 'DASHBOARD_FETCH_TOTALS_ERROR';

export const dashboardUpdateSavedFilterStart = () => ({
  type: DASHBOARD_UPDATE_SAVED_FILTER_START,
});

export const dashboardUpdateSavedFilterSuccess = () => ({
  type: DASHBOARD_UPDATE_SAVED_FILTER_SUCCESS,
});

export const dashboardUpdateSavedFilterError = () => ({
  type: DASHBOARD_UPDATE_SAVED_FILTER_ERROR,
});
export const dashboardCreateSavedFilterStart = () => ({
  type: DASHBOARD_CREATE_SAVED_FILTER_START,
});

export const dashboardCreateSavedFilterSuccess = () => ({
  type: DASHBOARD_CREATE_SAVED_FILTER_SUCCESS,
});

export const dashboardCreateSavedFilterError = () => ({
  type: DASHBOARD_CREATE_SAVED_FILTER_ERROR,
});

export const dashboardFetchTotalsStart = () => ({
  type: DASHBOARD_FETCH_TOTALS_START,
});

export const dashboardFetchTotalsSuccess = ({ result }) => ({
  type: DASHBOARD_FETCH_TOTALS_SUCCESS,
  payload: {
    result,
  },
});

export const dashboardFetchTotalsError = () => ({
  type: DASHBOARD_FETCH_TOTALS_ERROR,
});

export const filterReset = () => ({
  type: FILTER_RESET,
});

export const filterSetQuery = (query) => ({
  type: FILTER_SET_QUERY,
  payload: {
    query,
  },
});
