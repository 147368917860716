import add from 'date-fns/add';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { FILTER_ID_AVERAGE, FILTER_NAME_AVERAGE, FILTER_ID_ALL, PERIOD } from '../../../constants';

export const VALUE_FIELD = {
  VALUE: 'value',
  PERCENTAGE: 'percentage',
};

export const DATE_FORMAT = 'yyyy/MM/dd';
export const TOOLTIP_DATE_FORMAT = 'MM / dd / yyyy';

export const prepareTrends = (
  responses,
  breakdownFilter,
  colorByFilterId,
  groupBy,
  selectedButtonIds,
  translationUsed,
  weightedMetricsUsed,
  average,
) => {
  const labelField = translationUsed ? 'response' : 'originalResponse';
  const valueType = weightedMetricsUsed ? 'weighted' : 'base';
  // get min max values
  let min = '3000/12/31';
  let max = '1000/01/01';
  for (let response of responses) {
    if (!selectedButtonIds.includes(response.filterId) && !selectedButtonIds.includes(FILTER_ID_ALL)) {
      continue;
    }
    for (let row of response.items) {
      for (let date of row.buckets) {
        min = date.date < min ? date.date : min;
        max = date.date > max ? date.date : max;
      }
    }
  }
  // create dates list
  const fixedDate = new Date();
  const dates = [];
  let currentDate = min;
  while (currentDate <= max) {
    dates.push(currentDate);
    const parsed = parse(currentDate, DATE_FORMAT, fixedDate);
    const nextDate = groupBy === PERIOD.QUARTER ? add(parsed, { months: 3 }) : add(parsed, { [`${groupBy}s`]: 1 });
    currentDate = format(nextDate, DATE_FORMAT);
  }

  let trends = [];
  const filterIds = new Set();
  const averageData = new Map();

  for (let { filterName, filterId, bucketName, items } of responses) {
    if (!selectedButtonIds.includes(filterId) || !colorByFilterId[filterId]) {
      continue;
    }
    if (!filterIds.has(filterId)) {
      filterIds.add(filterId);
    }

    const bucket = breakdownFilter ? bucketName : null;

    if (!averageData.has(bucket)) {
      averageData.set(bucket, new Map());
    }

    for (let row of items) {
      const name = row[labelField];
      const data = [];
      for (let date of dates) {
        const item = row.buckets.find((item) => item.date === date);
        data.push({
          date: parse(date, DATE_FORMAT, new Date()),
          value: item ? item[valueType][VALUE_FIELD.VALUE] : 0,
          percentage: item ? item[valueType][VALUE_FIELD.PERCENTAGE] : 0,
        });
      }

      trends.push({
        data,
        bucket,
        filterId,
        filterName,
        name,
        color: colorByFilterId[filterId],
      });

      if (!averageData.get(bucket).has(name)) {
        averageData.get(bucket).set(name, []);
      }
    }
  }

  if (average) {
    trends = [];
    for (let [bucket, bucketData] of averageData.entries()) {
      for (let [name, labelData] of bucketData.entries()) {
        const data = [];
        for (let i = 0; i < dates.length; i++) {
          let value = 0;
          let responsesCount = 0;
          for (let item of labelData) {
            value += item[i].value;
            responsesCount += item[i].percentage === 0 ? 0 : (item[i].value / item[i].percentage) * 100;
          }

          data.push({
            date: parse(dates[i], DATE_FORMAT, new Date()),
            value: Math.round(value / filterIds.size),
            percentage: responsesCount === 0 ? 0 : (value / responsesCount) * 100,
          });
        }
        trends.push({
          data,
          name,
          bucket,
          filterId: FILTER_ID_AVERAGE,
          color: colorByFilterId[FILTER_ID_ALL],
          filterName: FILTER_NAME_AVERAGE,
        });
      }
    }
  }

  return trends;
};
