import { useEffect, useState } from 'react';

import { FETCH_STATUS } from '../../constants';
import * as api from '../../components/tools/api';
import { narrowQueryWithRule } from '../../components/FilterForm/lib';

/**
 * It's used to fetch breakdown trends data.
 *
 * Accepts:
 * - dashboardId: dashboard id
 * - filters: list of applied filters
 * - dataSourceId: list of data source ids
 * - selectedItems: list of labels to limit result
 * - aggregationRange: group by range
 * - breakdownFilter: applied breakdown filter
 *
 * Returns:
 * - responses: array of processed fetched results
 * - status: fetch status (see FETCH_STATUS);
 */
const useTrendsBreakdownFetch = (dashboardId, filters, dataSourceID, selectedItems, aggregationRange, breakdownFilter, skip, query) => {
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (skip) {
      return;
    }

    let mounted = true;

    const fetchData = async () => {
      setStatus(FETCH_STATUS.LOADING);

      const params = filters.map((filter) => ({
        dataSourceID,
        select: selectedItems,
        groupBy: aggregationRange,
        filter: query ? narrowQueryWithRule(filter.query, query) : filter.query,
      }));

      const trends = await api.fetchTrendsBreakdown(dashboardId, breakdownFilter, params);
      if (mounted) {
        const data = [];
        for (let index = 0; index < trends.length; index++) {
          const buckets = trends[index];
          const filter = filters[index];
          data.push({
            filter,
            buckets,
          });
        }
        setResponses(data);
        setStatus(FETCH_STATUS.LOADED);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [dashboardId, filters, dataSourceID, selectedItems, aggregationRange, breakdownFilter, skip, query]);

  return { responses, status };
};

export default useTrendsBreakdownFetch;
