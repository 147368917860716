import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import Badge from '../tools/badge';
import CachedIcon from '@material-ui/icons/Cached';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useSelector } from 'react-redux';
import CommentIcon from '@material-ui/icons/Comment';
import { numberWithCommas } from '../tools';
import { DEFAULT_DATE_FORMAT } from '../../models/TimeRange';
import { BadgesAreaContainer } from '../dashboard/style';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import useFilters from '../../hooks/useFilters';
import * as api from '../tools/api';
import TotalBadge from './TotalBadge';

const BADGE_PERCENT_DECIMAL_ITEMS = 0;
const API_LAST_UPDATED_DATE_FORMAT = 'yyyy-MM-dd HH:mm';

/**
 *
 * @param {Object} d Date
 * @returns {*}
 */
const formatDate = (d) => {
  return format(d, DEFAULT_DATE_FORMAT);
};

const getTotalLabel = (total, filteredTotal) => {
  return `${numberWithCommas(filteredTotal)} (${total === 0 ? 0 : ((filteredTotal / total) * 100).toFixed(BADGE_PERCENT_DECIMAL_ITEMS)}%)`;
};

const InfoBadgesArea = ({ showFilters = false }) => {
  const [filters] = useFilters();
  const {
    id: dsId,
    latestUpdatedResponse: dsLastUpdated,
    total: dsTotal,
    intervalTotal,
  } = useSelector((state) => state.dashboard.dashboard);
  const totalBadgeExpanded = useSelector((state) =>
    dsId in state.settings.totalBadgeExpanded ? state.settings.totalBadgeExpanded[dsId] : null,
  );
  const { weightedMetricsUsed } = useSelector((state) => state.app);
  const [totalsRaw, setTotalsRaw] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      const queries = filters
        .slice(1) // exclude `All` filter because it has own badge
        .map((filter) => filter.query);
      const multipleTotals = await api.fetchMultipleTotals(dsId, queries);
      if (mounted) {
        const result = multipleTotals.map((totals, i) => {
          const filter = filters[i + 1];
          return {
            ...totals,
            name: filter.name,
          };
        });
        setTotalsRaw(result);
      }
    };

    if (showFilters) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [filters, showFilters, dsId]);

  const filteredTotals = useMemo(() => {
    const valueFieldName = weightedMetricsUsed ? 'weighted' : 'total';
    return totalsRaw.map((totalRaw) => [totalRaw.name, totalRaw[valueFieldName]]);
  }, [totalsRaw, weightedMetricsUsed]);

  const lastUpdated = dsLastUpdated ? parse(dsLastUpdated, API_LAST_UPDATED_DATE_FORMAT, new Date()) : new Date();

  return (
    <BadgesAreaContainer container direction='row'>
      <Grid item className='badges-area' xs={12}>
        <TotalBadge total={dsTotal} />
        <Box display={'flex'} flex={1} flexDirection={totalBadgeExpanded ? 'column' : 'row'} flexGrow={0} style={{ float: 'left' }}>
          <Badge text='Last updated' IconComponent={CachedIcon} value={formatDate(lastUpdated)} />
          <Badge text='Added in the last 7 days' IconComponent={ControlPointIcon} value={numberWithCommas(intervalTotal || 0)} />
        </Box>
        {showFilters &&
          filteredTotals.map(([name, total]) => (
            <Badge
              id={`total-${name}`}
              key={`${name}-${total}`}
              text={name}
              IconComponent={CommentIcon}
              value={getTotalLabel(dsTotal, total)}
              showWeightIndicator
            />
          ))}
      </Grid>
    </BadgesAreaContainer>
  );
};

export default InfoBadgesArea;
