import { useMemo } from 'react';

import { useSavedFilters } from '../contexts/SavedFiltersContext';
import { EMPTY_FILTER_QUERY } from '../components/FilterForm/lib';
import { useAppSelector } from '../store/hooks';
import { Filter } from '../types/Query';

export const FILTER_ID_ALL = '___all___filter___id___';
export const FILTER_ID_CURRENT = '___current___filter___id___';

/**
 * Returns an array of used filters, and an object of the same filters organised by id
 *
 * It updates returning values when saved filters selection or current filter are changed
 * Usage: const [filters, filtersById] = useFilters();
 *
 * Each filter contains:
 *  id - saved filter id or internal filters ids
 *  name - name to display
 *  color - assigned color
 *  conditions - list of conditions
 *  timeRange - date range condition
 */
const useFilters = () => {
  const { selectedSavedFiltersArray, savedFiltersById, colorByFilterId } = useSavedFilters();
  const query = useAppSelector((state) => state.filter.query);

  return useMemo(() => {
    const filters: Filter[] = [];
    // all
    filters.push({
      id: FILTER_ID_ALL,
      name: 'All Responses',
      color: colorByFilterId[FILTER_ID_ALL],
      query: EMPTY_FILTER_QUERY,
    });
    // current
    if (query) {
      filters.push({
        query,
        id: FILTER_ID_CURRENT,
        name: 'Current',
        color: colorByFilterId[FILTER_ID_CURRENT],
      });
    }
    // applied saved
    if (selectedSavedFiltersArray.length > 0) {
      for (const selectedSavedFilterId of selectedSavedFiltersArray) {
        if (savedFiltersById[selectedSavedFilterId]) {
          const { id, name, query } = savedFiltersById[selectedSavedFilterId];
          filters.push({
            id,
            name,
            query,
            color: colorByFilterId[selectedSavedFilterId],
          });
        }
      }
    }
    const filtersById = filters.reduce((acc, filter) => ({ ...acc, [filter.id]: filter }), {} as { [key: string]: Filter });
    return [filters, filtersById] as const;
  }, [colorByFilterId, query, selectedSavedFiltersArray, savedFiltersById]);
};

export default useFilters;
