export function formatMilliseconds(milliseconds: number, padStart: boolean) {
  let ms = milliseconds;
  if (ms < 0) {
    return (ms = 0);
  }

  function pad(num: number) {
    return `${num}`.padStart(2, '0');
  }
  const asSeconds = ms / 1000;

  let hours = 0;
  let minutes = Math.floor(asSeconds / 60);
  const seconds = Math.floor(asSeconds % 60);

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes %= 60;
  }

  return `${padStart ? pad(hours) : hours}:${pad(minutes)}:${pad(seconds)}`;
}

export function isElementTruncated(el: HTMLDivElement | HTMLSpanElement | null) {
  if (!el) return false;

  return el.offsetWidth < el.scrollWidth;
}
