import Grid from '@material-ui/core/Grid';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import styled from 'styled-components';

import { ReactComponent as MoreIcon } from '../../../../assets/icons/more-icon.svg';

export const AnswerContainer = styled(Grid)`
  &.MuiGrid-item,
  &.MuiGrid-root {
    padding-bottom: 16px;
  }
`;

export const LabelsWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 12px;
`;
export const ResponseAction = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-bottom: 6px;
  color: var(--dark-gray);

  span {
    cursor: pointer;
  }
`;

export const DateWrapper = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  color: var(--black);
`;

export const Width100Grid = styled(Grid)`
  width: 100%;
  text-align: left;
`;

export const Width100 = styled.div`
  width: 100%;
  text-align: left;
`;

export const ResponseWrapper = styled(Width100)`
  padding-bottom: 12px;
`;

export const ResponseLoadingOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScrollToTopIcon = styled(DoubleArrowIcon)`
  background-color: var(--medium-gray);
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  opacity: 0.4;
  padding: 2px;
  transform: rotate(270deg);

  &:active,
  &:focus {
    outline: none;
  }
`;

export const ResponsesWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0;
  overflow: hidden;
`;

export const ItemContainer = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  padding: 24px 24px 24px 16px;
  border-radius: 8px;
  overflow: hidden;

  &:nth-child(odd) {
    background: var(--light-gray);
  }
`;

export const ItemTitleContainer = styled.div`
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 24px;
  color: var(--text-primary);
`;

export const InsightsTitleContainer = styled(ItemTitleContainer)`
  margin-bottom: 0;
`;

export const InsightsHeader = styled(ItemTitleContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ItemSecondaryTitle = styled.span`
  display: inline-flex;
  color: var(--dark-gray);
  font-size: 20px;
  max-width: 200px;
`;

export const FilteringButtonsAndContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MoreIconStyled = styled(MoreIcon)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  fill: var(--black);
  border-radius: 50%;

  &:hover {
    fill: var(--dark-purple);
    background-color: var(--light-gray);
  }
`;
