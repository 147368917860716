import React, { useEffect, useState } from 'react';
import ChatInput from './ChatInput';
import { Concept } from '../../../../../../../store/reducers/aiInsights';

interface IChatInputContainer {
  selectedConcept?: Concept;
  onSendClick: (prompt: string) => void;
}

function ChatInputContainer({ selectedConcept, onSendClick }: IChatInputContainer) {
  const [selectedConceptPrefix, setSelectedConceptPrefix] = useState('');
  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    if (selectedConcept?.isEnabled) {
      setPrompt(prompt.replace(selectedConceptPrefix, '').trim());
      setSelectedConceptPrefix(`${selectedConcept.title}: `);
    } else {
      setSelectedConceptPrefix('');
    }
  }, [selectedConcept]);

  function handlePromptChange(newPrompt: string) {
    if (newPrompt.trim() !== selectedConceptPrefix.trim()) {
      setPrompt(newPrompt.replace(selectedConceptPrefix, ''));
    } else {
      setPrompt('');
    }
  }

  function handleSendClick() {
    const clearText = prompt.trim();
    if (clearText !== '' && (!selectedConcept || selectedConcept.isEnabled)) {
      onSendClick(prompt.replace(selectedConceptPrefix, '').trim());
    }
  }

  return (
    <ChatInput
      prompt={`${selectedConceptPrefix}${prompt}`}
      isSendAvailable={selectedConcept ? selectedConcept.isEnabled : true}
      errorMessage={selectedConcept ? selectedConcept.disabledText : undefined}
      onPromptChange={handlePromptChange}
      onSendClick={handleSendClick}
    />
  );
}

export default ChatInputContainer;
