import { FILTER_ID_ALL } from '../../../../constants';
import { BreakdownData } from '../../../../types/BreakdownData';
import { WidgetDataSource, WidgetDataSourceConcept } from '../../../../types/DataSource';
import { BreakdownLabel, Filters, Label } from '../../../../types/Label';
import { Filter } from '../../../../types/Query';
import { ResponseListItem } from '../../../overview/lib';

export const LABEL_NOT_APPLICABLE = 'n/a';
export const LABEL_OTHER = 'Other';

export const DATA_VIEW = {
  HIGH_LEVEL: false,
  DETAILED: true,
};

export const getLabelsComparator = (valuesByLabelTitle: Map<string, number>) => {
  return (a: { title: string; children: string[] | null }, b: { title: string; children: string[] | null }) => {
    if (a.title === LABEL_NOT_APPLICABLE || b.title === LABEL_NOT_APPLICABLE) {
      return a.title === LABEL_NOT_APPLICABLE ? 1 : -1;
    }
    if (
      (a.title === LABEL_OTHER && a.children && a.children.length > 0) ||
      (b.title === LABEL_OTHER && b.children && b.children.length > 0)
    ) {
      return a.title === LABEL_OTHER ? 1 : -1;
    }
    return (valuesByLabelTitle.get(b.title) || 0) - (valuesByLabelTitle.get(a.title) || 0);
  };
};

export const prepareLabelsHierarchy = (
  dataSource: WidgetDataSource,
  showChildren: boolean,
  valuesByLabelTitle: Map<string, number>,
  selectedItems: string[],
): Label[] => {
  type UnpreparedLabel = {
    title: string;
    displayTitle: string;
    hasChild: boolean;
    children: string[] | null;
    parentLabel: string | null;
    filters?: Filters;
  };
  const answers = dataSource.items;
  const labelsByTitle = new Map<string, UnpreparedLabel>();
  const parentLabels: WidgetDataSourceConcept[] = [];
  const allowedLabels = [...selectedItems];

  for (const answer of answers) {
    labelsByTitle.set(answer.originalResponse, {
      title: answer.originalResponse,
      displayTitle: answer.displayResponse,
      hasChild: false,
      children: answer.children,
      parentLabel: null,
    } as UnpreparedLabel);

    if (answer.children) {
      parentLabels.push(answer);
    }
  }
  for (const parentLabel of parentLabels) {
    const selectedParent = selectedItems.length > 0 && selectedItems.includes(parentLabel.originalResponse);
    const parent = labelsByTitle.get(parentLabel.originalResponse);
    if (parent) {
      parent.hasChild = showChildren;
      if (parent.children) {
        const actualChildren: any[] = [];

        parent.children
          .filter((childLabel: any) => selectedItems.length === 0 || selectedParent || selectedItems.includes(childLabel))
          .forEach((childLabel: any) => {
            const child = labelsByTitle.get(childLabel);
            if (child) {
              child.parentLabel = parent.title;
              actualChildren.push(childLabel);
            }
          });

        if (parent.children.length !== actualChildren.length) {
          parent.children = actualChildren;
        }

        if (parent.children.length === 0) {
          parent.hasChild = false;
        } else if (selectedItems.length > 0) {
          if (selectedParent) {
            allowedLabels.push(...(parent.children as any[]));
          } else {
            allowedLabels.push(parent.title);
          }
        }
      }
    }
  }

  const labelsHierarchy: Label[] = [];
  const labelsComparator = getLabelsComparator(valuesByLabelTitle);
  const sortedPrimaryLabels = [...labelsByTitle.values()]
    .filter((l) => l.parentLabel === null && (allowedLabels.length === 0 || allowedLabels.includes(l.title)))
    .sort(labelsComparator);

  for (const primaryLabel of sortedPrimaryLabels) {
    const preparedLabel = {
      ...primaryLabel,
      children:
        showChildren && primaryLabel.children
          ? [...primaryLabel.children]
              .sort((a, b) => (valuesByLabelTitle.get(b as any) || 0) - (valuesByLabelTitle.get(a as any) || 0))
              .map((a) => labelsByTitle.get(a as any))
          : null,
    } as Label;
    labelsHierarchy.push(preparedLabel);
  }
  return labelsHierarchy;
};

export function prepareLabelsList(
  overviewDataById: Map<string, ResponseListItem>,
  dataSource: WidgetDataSource,
  filtersById: { [key: string]: Filter },
  selectedButtonId: string[],
  showChildren: boolean,
  selectedItems: string[],
) {
  const labelsByTitle = new Map<string, Label>();
  const labelsList: Label[] = [];

  let selectedFilterIds = selectedButtonId.filter((id) => filtersById[id]);
  if (selectedFilterIds.length === 0) {
    selectedFilterIds = [FILTER_ID_ALL];
  }

  const filterId = selectedFilterIds.length > 1 ? FILTER_ID_ALL : selectedFilterIds[0];

  const overviewData = overviewDataById.get(filterId);
  const baseDataSource = overviewData && overviewData.items.length > 0 ? (overviewData.items[0] as Partial<WidgetDataSource>) : dataSource;
  const percentageByLabel = new Map<string, number>();
  if (selectedFilterIds.length === 1 && baseDataSource.items) {
    for (const item of baseDataSource.items) {
      percentageByLabel.set(item.originalResponse, item.realPercent as number);
    }
  } else {
    for (const selectedFilterId of selectedFilterIds) {
      const ds = overviewDataById.get(selectedFilterId)?.items[0] as Partial<WidgetDataSource>;
      if (ds?.items) {
        for (const item of ds.items) {
          percentageByLabel.set(
            item.originalResponse,
            Math.max(item.realPercent as number, percentageByLabel.get(item.originalResponse) || 0),
          );
        }
      } else {
        console.info(`DataSource with ID ${dataSource.dataSourceID} does not have any items, that are required for widget`);
      }
    }
  }
  const labelsHierarchy = prepareLabelsHierarchy(baseDataSource as WidgetDataSource, showChildren, percentageByLabel, selectedItems);
  for (const primaryLabel of labelsHierarchy) {
    const { children, ...labelData } = primaryLabel;
    const label = {
      ...labelData,
      key: labelData.title,
      parentKey: null,
      children: children ? children.map((child) => child.displayTitle) : children,
    } as Label;
    labelsByTitle.set(label.title, label);
    labelsList.push(label);
    if (children) {
      for (const childData of children) {
        const childLabel = {
          ...childData,
          key: childData.title,
          parentKey: label.title,
        } as Label;
        labelsByTitle.set(childLabel.title, childLabel);
        labelsList.push(childLabel);
      }
    }
  }

  overviewDataById.forEach((overviewAPIResponse, filterId) => {
    if (overviewAPIResponse && (selectedFilterIds.includes(filterId) || selectedFilterIds.includes(FILTER_ID_ALL))) {
      const model = overviewAPIResponse.items[0];
      for (const key of labelsByTitle.keys()) {
        const value = labelsByTitle.get(key)!;
        const answerModel = model && model.answers.find((answer) => answer.response === key);
        const filterValue = {
          value: 0,
          pct: 0,
        };
        if (answerModel) {
          filterValue.pct = answerModel.realPercent;
          filterValue.value = answerModel.number;
        }
        if (!value?.filters) {
          value!.filters = {};
        }
        value.filters[filterId] = {
          realPercent: filterValue.pct,
          number: filterValue.value,
          sentiment: 'unknown',
          sentimentScore: 0.0,
        };
      }
    }
  });

  return labelsList;
}

const getKey = (label: string, breakdownValue: string) => {
  return `${label}-${breakdownValue}`;
};

export const prepareBreakdownLabels = (
  breakdownData: BreakdownData,
  dataSource: WidgetDataSource,
  filtersById: { [key: string]: Filter },
  selectedFilterIds: string[],
  showChildren: boolean,
  selectedItems: string[],
) => {
  const filterValuesByKey = new Map();
  const breakdownKeys = new Set<string>();
  let selectedButtonId = selectedFilterIds.filter((id) => filtersById[id]);
  if (selectedButtonId.length === 0) {
    selectedButtonId = [FILTER_ID_ALL];
  }

  const percentageByLabel = new Map();
  if (selectedButtonId.length === 1 && selectedButtonId[0] === FILTER_ID_ALL) {
    for (const item of dataSource.items) {
      percentageByLabel.set(item.originalResponse, item.realPercent);
    }
  } else {
    for (const [filterId, buckets] of Object.entries(breakdownData)) {
      if (!selectedButtonId.includes(filterId)) {
        continue;
      }
      for (const dataSources of Object.values(buckets)) {
        const ds = Object.values(dataSources)[0];
        for (const item of ds.items) {
          percentageByLabel.set(
            item.originalResponse,
            Math.max(item.percentage as number, percentageByLabel.get(item.originalResponse) || 0),
          );
        }
      }
    }
  }
  const labelsHierarchy = prepareLabelsHierarchy(dataSource, showChildren, percentageByLabel, selectedItems);

  Object.entries(breakdownData).forEach(([filterId, breakdown]) => {
    if (filtersById[filterId] && (selectedButtonId.includes(filterId) || selectedButtonId.includes(FILTER_ID_ALL))) {
      Object.entries(breakdown).forEach(([breakdownKey, dataSources]) => {
        Object.entries(dataSources).forEach(([, dataSource]) => {
          breakdownKeys.add(breakdownKey);
          for (const item of dataSource.items) {
            if (selectedItems.length > 0 && !selectedItems.includes(item.response)) {
              continue;
            }
            const key = getKey(item.displayResponse, breakdownKey);
            const values = filterValuesByKey.has(key) ? filterValuesByKey.get(key) : {};
            filterValuesByKey.set(key, {
              ...values,
              [filterId]: {
                realPercent: item.percentage,
                number: item.value,
              },
            });
          }
        });
      });
    }
  });

  const breakdownLabels: BreakdownLabel[] = [];
  const sortFilterId = selectedFilterIds[0];
  labelsHierarchy.forEach((label) => {
    let top = true;
    breakdownKeys.forEach((key) => {
      const parentKey = getKey(label.title, key);
      breakdownLabels.push({
        ...label,
        top,
        key: parentKey,
        breakdownValue: key as string,
        filters: filterValuesByKey.get(parentKey),
      } as BreakdownLabel);
      top = false;
      if (label.children) {
        const childrenLabels: BreakdownLabel[] = [];
        label.children.forEach((child) => {
          const childKey = getKey((child as any).title, key);
          childrenLabels.push({
            ...(child as any),
            top,
            parentKey,
            key: childKey,
            breakdownValue: key,
            filters: filterValuesByKey.get(childKey),
          } as BreakdownLabel);
          top = false;
        });
        if (!dataSource.ordered) {
          childrenLabels.sort((a, b) => b.filters[sortFilterId].number - a.filters[sortFilterId].number);
        }
        breakdownLabels.push(...childrenLabels);
      }
    });
  });

  return breakdownLabels;
};

export const getChildrenLabels = (dataSource: WidgetDataSource) => {
  const childrenLabels = [];
  for (const item of dataSource.items) {
    if (item.children && item.children.length) {
      childrenLabels.push(...item.children);
    }
  }

  return childrenLabels;
};

export const getLabelToParent = (dataSource: WidgetDataSource) => {
  const labelToParent = new Map();
  for (const item of dataSource.items) {
    if (item.children) {
      for (const child of item.children) {
        labelToParent.set(child, item.response);
      }
    }
  }
  for (const item of dataSource.items) {
    if (!labelToParent.has(item.response)) {
      labelToParent.set(item.response, item.response);
    }
  }
  return labelToParent;
};
