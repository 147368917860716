import { Action } from '../hooks';
import { getRegenerationStatus } from '../../components/tools/api';
import {
  dashboardGetRegenerationStatusFailure,
  dashboardGetRegenerationStatusStart,
  dashboardGetRegenerationStatusSuccess,
} from '../actions/dashboard';

const dataSourceIdToAbortSignal: Map<string, AbortController> = new Map();

interface ICheckDataSources {
  dashboardID: string;
  dataSourceIDs: string[];
}
export const checkDataSources =
  ({ dashboardID, dataSourceIDs }: ICheckDataSources): Action =>
  async (dispatch, getState) => {
    const stringifiedDSs = dataSourceIDs.join(',');

    if (dataSourceIdToAbortSignal.has(stringifiedDSs)) {
      console.log(`Request for DataSources: ${stringifiedDSs} is still in progress, skipping current request`);
      return;
    }

    dispatch(dashboardGetRegenerationStatusStart({ dataSourceIDs }));

    try {
      const abortController = new AbortController();
      dataSourceIdToAbortSignal.set(stringifiedDSs, abortController);
      const resp = await getRegenerationStatus(dashboardID, dataSourceIDs, abortController.signal);

      if (resp.error) {
        throw new Error(resp.error);
      } else {
        const toCamelCase =
          resp?.map((r: any) => ({
            dataSourceID: r.data_source_id,
            dashboardID: r.dashboard_id,
            status: r.status,
          })) || [];
        dispatch(dashboardGetRegenerationStatusSuccess({ items: toCamelCase }));
        dataSourceIdToAbortSignal.delete(stringifiedDSs);
      }
    } catch (e: any) {
      dataSourceIdToAbortSignal.delete(stringifiedDSs);
      if (e.name !== 'AbortError') {
        dispatch(dashboardGetRegenerationStatusFailure({ dataSourceIDs }));
      } else {
        console.error(`Unexpected error happened for ${stringifiedDSs} query to get regeneration statuses`);
      }
    }
  };

export const abortCheckDataSources = () => {
  for (const abortController of dataSourceIdToAbortSignal.values()) {
    abortController.abort();
  }
};
