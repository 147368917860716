import React, { useEffect, useMemo } from 'react';
import { EVENTS, eventsTracker } from '../../../../../services/EventTrackerService';
import {
  clearAllInsights,
  GeneratedInsight,
  showFeedbackModal,
  showKeyQuotesModal,
  updateSelectedConceptsFilteredInWidgetSettings,
} from '../../../../../store/actions/aiInsights';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Concept, IAIInsightsReducer, Insight, InsightType } from '../../../../../store/reducers/aiInsights';
import { WidgetDataSource } from '../../../../../types/DataSource';
import AIInsights from './AIInsights';
import { ThumbStatus } from './Components/GeneratedInsights/GeneratedInsightsContainer';

interface IAIInsightsContainer {
  widgetID: string;
  dataSource: WidgetDataSource;
  conceptsFilteredInWidgetSettings: string[];
  selectedConcept?: Concept;
  onFiltersClearClick: () => void;
  onGenerateInsightsClick: (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => void;
}
function AIInsightsContainer({
  widgetID,
  dataSource,
  selectedConcept,
  conceptsFilteredInWidgetSettings,
  onGenerateInsightsClick,
}: IAIInsightsContainer) {
  const insightsByWidgetID = useAppSelector((state) => state.aiInsights as IAIInsightsReducer).insightsByWidgetID;
  const dispatch = useAppDispatch();

  const [isInitialized, setInitialized] = React.useState(false);

  const insights = useMemo<undefined | Insight[]>(() => {
    return insightsByWidgetID.find((insightsByWidgetID) => insightsByWidgetID.widgetID === widgetID)?.insights;
  }, [insightsByWidgetID, widgetID]);

  useEffect(() => {
    if (insights?.length || isInitialized) {
      return;
    }
    setInitialized(true);
    onGenerateInsightsClick();
  }, [insights]);

  useEffect(() => {
    dispatch(
      updateSelectedConceptsFilteredInWidgetSettings({
        widgetID,
        concepts: conceptsFilteredInWidgetSettings,
      }),
    );
  }, [conceptsFilteredInWidgetSettings]);

  function handleClearAllInsights() {
    if (!insights) {
      return;
    }

    const latestInsight = insights[insights.length - 1];
    const triggerName = latestInsight.concept
      ? latestInsight.customPrompt
        ? latestInsight.customPrompt
        : latestInsight.concept.title
      : 'Generate';

    eventsTracker.track(EVENTS.GEN_AI_CLEAR_ALL, {
      'Widget ID': widgetID,
      'Data Source ID': dataSource.dataSourceID,
      'Last Generated insight trigger name': triggerName,
    });
    dispatch(clearAllInsights({ widgetID }));
  }

  function handleSendCustomUsersPromptClick(customPrompt: string) {
    // dispatch(generateInsights({
    //     dashboardID,
    //     widgetID,
    //     dataSourceID: dataSource.dataSourceID,
    //     selectedConcept,
    //     customPrompt,
    // }))
  }

  function handleLearnMoreClick() {
    eventsTracker.track(EVENTS.GEN_AI_LEARN_MORE, {
      'Widget ID': widgetID,
      'Data Source ID': dataSource.dataSourceID,
    });
  }

  function handleCopyClick(insightID: string) {
    if (!insights) {
      return;
    }

    const insight = insights.find((insight) => insight.id === insightID);
    if (insight) {
      const triggerName = selectedConcept
        ? insight.customPrompt
          ? `${selectedConcept}: ${insight.customPrompt}`
          : selectedConcept.title
        : insight.customPrompt
          ? insight.customPrompt
          : 'Generate';

      eventsTracker.track(EVENTS.GEN_AI_COPY_GENERATED_INSIGHTS, {
        'Widget ID': widgetID,
        'Data Source ID': dataSource.dataSourceID,
        'Generated insight trigger name': triggerName,
        'Generated Content': insight.generatedInsights?.join(' ; '),
        'Generated Related Concepts': insight.relatedConcepts?.map((rc) => rc.title).join(' , '),
      });
    }
  }

  function handleThumbsClick(insightID: string, thumbStatus: ThumbStatus) {
    if (!insights) {
      return;
    }

    const insight = insights.find((insight) => insight.id === insightID);
    if (insight) {
      const eventName = thumbStatus === ThumbStatus.UP ? EVENTS.GEN_AI_LIKE_GENERATED_INSIGHTS : EVENTS.GEN_AI_DISLIKE_GENERATED_INSIGHTS;
      const triggerName = insight.concept ? (insight.customPrompt ? insight.customPrompt : insight.concept.title) : 'Generate';
      eventsTracker.track(eventName, {
        'Widget ID': widgetID,
        'Data Source ID': dataSource.dataSourceID,
        'Generated insight trigger name': triggerName,
        'Generated Content': insight.generatedInsights?.join(' ; '),
        'Generated Related Concepts': insight.relatedConcepts?.map((rc) => rc.title).join(' , '),
      });
      dispatch(
        showFeedbackModal({
          widgetID,
          thumbStatus,
          insightID: insight.id,
          dataSourceID: dataSource.dataSourceID,
        }),
      );
    }
  }

  function handleQuoteClick(insightID: string, insight: GeneratedInsight) {
    dispatch(
      showKeyQuotesModal({
        widgetID,
        insightID,
        dataSourceID: dataSource.dataSourceID,
        keyTakeaway: insight.insight,
        keyQuotes: insight.keyQuotes,
      }),
    );
  }

  function handleGenerateInsightsClick(newSelectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) {
    if (selectedConcept?.title === newSelectedConcept?.title && !isTryAgain) {
      return;
    }
    onGenerateInsightsClick(newSelectedConcept, isTryAgain, insightID);
  }

  if (!insights) {
    return null;
  }

  return (
    <AIInsights
      selectedConcept={selectedConcept}
      insights={insights}
      onSendCustomUserPromptClick={handleSendCustomUsersPromptClick}
      onGenerateClick={handleGenerateInsightsClick}
      onClearAllInsights={handleClearAllInsights}
      onLearnMoreClick={handleLearnMoreClick}
      onCopyClick={handleCopyClick}
      onThumbsClick={handleThumbsClick}
      onQuoteClick={handleQuoteClick}
    />
  );
}

export default AIInsightsContainer;
