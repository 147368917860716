import { JsonModel } from './JsonModel';

const expSymbols = 0;

export class OverviewItemModel extends JsonModel {
  static TYPE_UNKNOWN = 'unknown';
  static TYPE_OPEN_ENDED = 'OpenEnded';
  static TYPE_OTHER = 'Other';
  static TYPE_MULTI_CHOICE = 'MultiChoice';

  static availableTypes = [OverviewItemModel.TYPE_OPEN_ENDED, OverviewItemModel.TYPE_OTHER, OverviewItemModel.TYPE_MULTI_CHOICE];

  constructor(id = '', question = '', answers = [], peopleCount = 0, isOpenEnded = false, isOrdered = false, title, originalTitle) {
    super();

    this.id = id;
    this.question = question;
    this.answers = answers;
    this.peopleCount = peopleCount;
    this.type = OverviewItemModel.TYPE_UNKNOWN;
    this.ordered = isOrdered;
    this.title = title;
    this.originalTitle = originalTitle;
  }

  setType(jsonType) {
    if (OverviewItemModel.availableTypes.includes(jsonType)) {
      this.type = jsonType;
    } else {
      this.type = OverviewItemModel.TYPE_UNKNOWN;
    }

    return this;
  }

  fromJson(_json) {
    super.fromJson(_json);
    this.id = _json['id'];
    this.question = _json['title'];
    this.peopleCount = _json['usersNum'];
    this.answers = (_json['items'] || []).map((answer) => OverviewAnswerModelFactory(answer));
    this.setType(_json['type']);
    this.ordered = _json['ordered'];
    this.title = _json['title'];
    this.originalTitle = _json['originalTitle'];

    return this;
  }
}

export class OverviewAnswerModel extends JsonModel {
  constructor(
    response = '',
    originalResponse = '',
    displayResponse = '',
    number = 0,
    percent = 0.0,
    children = [],
    sentiment = 'unknown',
    sentimentPercentage = 0.0,
  ) {
    super();

    this.response = response;
    this.originalResponse = originalResponse;
    this.displayResponse = displayResponse;
    this.number = number;
    this.percent = parseFloat(percent.toFixed(expSymbols));
    this.realPercent = percent;
    this.label = '';
    this.children = children;
    this.sentiment = sentiment;
    this.sentimentPercentage = sentimentPercentage;
  }

  setLabel(label = '') {
    this.label = label;
  }

  getLabel(prefix = '', delimiter = '<br>') {
    return this.label ? this.label : `${prefix ? prefix + delimiter : ''}${this.displayResponse}: ${this.percent}% / ${this.number}`;
  }

  getTextLabel(prefix = '', delimiter = '<br>') {
    return this.label ? this.label : `${prefix ? prefix + delimiter : ''}${this.displayResponse}`;
  }

  getPercentLabel() {
    return `${this.number} (${this.percent}%)`;
  }

  fromJson(_json) {
    super.fromJson(_json);
    const pct = parseFloat(_json['pct']) || 0;
    this.response = _json['response'];
    this.originalResponse = _json['originalResponse'];
    this.displayResponse = 'displayResponse' in _json ? _json['displayResponse'] : _json['response'];
    this.number = _json['value'];
    this.realPercent = pct;
    this.percent = parseFloat(pct.toFixed(expSymbols));
    this.children = _json['children'];
    this.sentiment = _json['sentiment'];
    this.sentimentPercentage = _json['sentimentPercentage'];
    return this;
  }
}

export const OverviewItemModelFactory = (_json) => {
  return new OverviewItemModel().fromJson(_json);
};
export const OverviewAnswerModelFactory = (_json) => {
  return new OverviewAnswerModel().fromJson(_json);
};
