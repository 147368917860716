import CONFIG from '../../config';
import { SESSION_ID } from '../../constants';

export interface IEventsTrackerService {
  identify: (uniqId: string, extraInfo?: object) => void;
  track: (event: string, props: object) => void;
  trackImmediately: (event: string, properties: object) => void;
  disableTracking: () => void;
  enableTracking: () => void;
  trackPageView?: () => void;
}

class EventsTrackerService {
  trackers = {} as {
    [key: string]: IEventsTrackerService;
  };
  context = {} as {
    [key: string]: any;
  };

  addToContext(value = {}) {
    this.context = { ...this.context, ...value };
  }

  removeFromContext(keys: string[] = []) {
    for (const key of keys) {
      delete this.context[key];
    }
  }

  clearContext() {
    this.context = {};
  }

  enrichProps(props: any) {
    const base = {
      Page: window.location.pathname,
      Timestamp: new Date().toISOString().slice(0, -5),
      Version: CONFIG.APP.VERSION,
      session_id: localStorage.getItem(SESSION_ID),
    };

    return { ...base, ...this.context, ...props };
  }

  registerTracker(trackerName: string, trackerInstance: IEventsTrackerService) {
    this.trackers[trackerName] = trackerInstance;
  }

  unregisterTracker(trackerName: string) {
    delete this.trackers[trackerName];
  }

  identify(uniqId: string, extraInfo?: object) {
    for (const trackerName in this.trackers) {
      this.trackers[trackerName].identify(uniqId, extraInfo);
    }
  }

  track(event: string, props = {}) {
    const enrichedProps = this.enrichProps(props);
    for (const trackerName in this.trackers) {
      this.trackers[trackerName].track(event, enrichedProps);
    }
  }

  trackPageView() {
    for (const trackerName in this.trackers) {
      const tracker = this.trackers[trackerName];
      if (tracker.trackPageView) {
        tracker.trackPageView();
      }
    }
  }

  // track event in case of page unloading, tracker shouldn't batch requests or wait for a response
  trackImmediately(event: string, props = {}) {
    const enrichedProps = this.enrichProps(props);

    for (const trackerName in this.trackers) {
      this.trackers[trackerName].trackImmediately(event, enrichedProps);
    }
  }

  disableTracking() {
    for (const trackerName in this.trackers) {
      this.trackers[trackerName].disableTracking();
    }
  }

  enableTracking() {
    for (const trackerName in this.trackers) {
      this.trackers[trackerName].enableTracking();
    }
  }
}

export default EventsTrackerService;
