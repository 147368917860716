import { JsonModel } from './JsonModel';

export const SENTIMENT = Object.freeze({
  NEUTRAL: 'neutral',
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  UNKNOWN: 'unknown',
});

export const SENTIMENT_TITLE = Object.freeze({
  [SENTIMENT.NEUTRAL]: 'Neutral',
  [SENTIMENT.POSITIVE]: 'Positive',
  [SENTIMENT.NEGATIVE]: 'Negative',
  [SENTIMENT.UNKNOWN]: 'Unknown',
});

export class ResponseModel extends JsonModel {
  constructor(
    id,
    date,
    answerText,
    originalAnswerText,
    displayAnswerText,
    labels = [],
    answerCount = 0,
    sentiment = SENTIMENT.UNKNOWN,
    sentimentScore = 0.0,
    bookmarked = false,
    customerID = undefined,
    start = 0,
  ) {
    super();
    this.id = id;
    this.date = date;
    this.answerText = answerText;
    this.originalAnswerText = originalAnswerText;
    this.displayAnswerText = displayAnswerText;
    this.labels = labels;
    this.additionalLabels = [];
    this.answerCount = answerCount;
    this.labelsCount = labels.length || 0;
    this.sentimentScore = sentimentScore;
    this.sentiment = sentiment;
    this.bookmarked = bookmarked;
    this.customerID = customerID;
    this.start = start;
  }
  fromJson(_json) {
    super.fromJson(_json);
    this.id = _json['id'];
    this.date = _json['date'];
    this.answerText = _json['value'];
    this.originalAnswerText = _json['originalValue'];
    this.displayAnswerText = 'displayAnswerText' in _json ? _json['displayAnswerText'] : _json['value'];
    this.labels = _json['labels'] || [];
    this.answerCount = _json['size'] || 0;
    this.labelsCount = this.labels.length || 0;
    this.sentiment = _json['sentiment'] || SENTIMENT.NEUTRAL;
    this.sentimentScore = _json['sentimentScore'] || 0.0;
    this.bookmarked = _json['bookmarked'];
    this.customerID = _json['customerID'] || undefined;
    this.start = _json['start'] || 0;
    return this;
  }
  toJsonString() {
    return JSON.stringify({
      id: this.id,
      date: this.date,
      value: this.answerText,
      originalValue: this.originalAnswerText,
      labels: this.labels,
      size: this.answerCount,
      sentiment: this.sentiment,
      sentimentScore: this.sentimentScore,
      bookmarked: this.bookmarked,
      customerID: this.customerID,
      start: this.start,
    });
  }
  addLabels(_labels = [], labelsProp = 'labels') {
    let labels = _labels;
    if (typeof _labels === 'string' || _labels instanceof String) {
      labels = [_labels];
    }

    this[labelsProp] = Array.from(new Set([...this[labelsProp], ...labels]));
    this.labelsCount = this.labels.length || 0;
    return this;
  }
  removeLabel(_label) {
    this.labels = this.labels.filter((label) => label !== _label);
    this.labelsCount = this.labels.length || 0;
    return this;
  }
  addAdditionalLabels(_labels = []) {
    return this.addLabels(_labels, 'additionalLabels');
  }
  setDisplayAnswerText(translationUsed = true) {
    this.displayAnswerText = translationUsed ? this.answerText : this.originalAnswerText;
  }
}

export const ResponseModelFactory = (_json) => {
  return new ResponseModel().fromJson(_json);
};
