import { useContext, useEffect } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';

import SidebarItemWithOverlay from './SidebarItemWithOverlay';
import { useSavedFilters } from '../../../contexts/SavedFiltersContext';
import OverlayPaper from './OverlayPaper';
import { eventsTracker, EVENTS } from '../../../services/EventTrackerService';
import { SavedFilters } from '../../SavedFilters/SavedFilters';
import { SidebarDashboardContext } from '../SidebarDashboardContext';
import { itemColors } from './style';

const Count = styled('span')`
  display: flex;
  font-family: 'Rubik';
  position: absolute;
  background-color: ${itemColors.selectedBackground};
  font-size: 10px;
  font-weight: 700;
  color: ${itemColors.selectedColor};
  min-width: 17px;
  border-radius: 9px;
  text-align: center;
  line-height: 17px;
  border: 1.5px solid white;
  top: 6px;
  left: 45px;
  box-sizing: border-box;
  height: 17px;
  align-items: center;
  justify-content: center;
`;

const InvalidMark = styled.div`
  background-color: ${itemColors.error};
  border-radius: 50%;
  height: 6px;
  left: 45px;
  position: absolute;
  top: 9px;
  width: 6px;
`;

// https://mui.com/material-ui/material-icons/?query=funnel&theme=Outlined&selected=FilterAltOutlined
const FilterAltOutlined = (props) => (
  <SvgIcon {...props}>
    <path d='M7 6h10l-5.01 6.3L7 6zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z'></path>
  </SvgIcon>
);

const SidebarItemSavedFilters = ({ index }) => {
  const { selectedSavedFiltersArray, savedFilters } = useSavedFilters();
  const { setActiveItemIndex, setSavedFilter, createFilterIndex, setSavedFiltersIndex } = useContext(SidebarDashboardContext);

  useEffect(() => {
    setSavedFiltersIndex(index);
  }, [index, setSavedFiltersIndex]);

  const count = selectedSavedFiltersArray.length;
  const hasInvalidatedFilters = savedFilters.some((filter) => !filter.valid);
  const showInvalidMark = hasInvalidatedFilters && count === 0;

  const handleEdit = (filter) => {
    setSavedFilter(filter);
    setActiveItemIndex(createFilterIndex);
  };

  const handleCreateNewClick = () => {
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_CREATE_NEW);
    setActiveItemIndex(createFilterIndex);
  };

  const handleOpen = () => {
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_VIEW);
  };

  const handleClose = () => {
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_CLOSE, {
      'Filter Count': count,
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <SidebarItemWithOverlay
        icon={<FilterAltOutlined />}
        index={index}
        selected={count > 0}
        tooltip='Saved Segments'
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <OverlayPaper elevation={4} fixed='true'>
          <SavedFilters onEditFilterClick={handleEdit} onCreateNewClick={handleCreateNewClick} />
        </OverlayPaper>
      </SidebarItemWithOverlay>
      {count > 0 && <Count>{count}</Count>}
      {showInvalidMark && <InvalidMark />}
    </div>
  );
};

export default SidebarItemSavedFilters;
