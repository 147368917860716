import * as React from 'react';

import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

import { CloseButtonContainer, CloseIcon, Container, DialogTitle } from './styles';

import { FilledButton } from '../../UI/Button';
import uploadSuccess from '../../../assets/images/upload-success.png';

const StyledFilledButton = styled(FilledButton)`
  width: 15%;
  align-self: flex-end;
  margin-top: 10px;
`;

const StyledCloseButtonContainer = styled(CloseButtonContainer)`
  top: 32px;
  right: 32px;
`;

const StyledContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  text-align: left;
`;

const StyledDialogTitle = styled(DialogTitle)`
  align-self: flex-start;
`;

const DialogSubtext = styled.div`
  font-size: 16px;
  margin-top: 20px;
  align-self: flex-start;
  margin-bottom: 8px;
`;

interface ISuccessfulUploadDialog {
  isSuccessfulUploadDialogOpen: boolean;
  fileName: string;
  handleIsSuccessfulUploadDialogOpenChange: (isOpen: boolean) => void;
}

function SuccessfulUploadDialog({
  isSuccessfulUploadDialogOpen,
  fileName,
  handleIsSuccessfulUploadDialogOpenChange,
}: ISuccessfulUploadDialog) {
  return (
    <div>
      <Dialog
        data-testid='successful-upload-dialog'
        open={isSuccessfulUploadDialogOpen}
        onClose={handleIsSuccessfulUploadDialogOpenChange.bind(null, false)}
        fullWidth={true}
        maxWidth={'md'}
      >
        <StyledContainer>
          <StyledCloseButtonContainer
            data-testid='successful-upload-dialog-close'
            onClick={handleIsSuccessfulUploadDialogOpenChange.bind(null, false)}
          >
            <CloseIcon />
          </StyledCloseButtonContainer>
          <StyledDialogTitle>Processing Uploaded File</StyledDialogTitle>
          <img data-testid='successful-upload-dialog-image' src={uploadSuccess} alt='Sucessful File Upload'></img>
          <DialogSubtext>
            Your program,
            <b data-testid='successful-upload-dialog-fileName'> {fileName}</b>, is being configured. This process includes both training
            your bespoke AI models and dashboard configuration. We will notify you when the data is available.
          </DialogSubtext>
          <StyledFilledButton
            data-testid='successful-upload-dialog-done'
            onClick={handleIsSuccessfulUploadDialogOpenChange.bind(null, false)}
          >
            {' '}
            OK{' '}
          </StyledFilledButton>
        </StyledContainer>
      </Dialog>
    </div>
  );
}

export default SuccessfulUploadDialog;
