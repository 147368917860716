import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import { Container, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';

import DashboardToolbar from '../../components/DashboardToolbar/DashboardToolbarContainer';
import { setSelectedCompany } from '../../contexts/api-routes';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { escapeQuery } from '../../components/tools';
import { EVENT_PROPERTY_COMPANY_NAME } from '../../services/EventTrackerService/events';

const Companies = () => {
  const { user, companies } = useSelector((state) => state.profile);
  const [value, setValue] = useState('');
  const history = useHistory();

  useEffect(() => {
    eventsTracker.clearContext();
  }, []);

  if (companies.length === 1) {
    setSelectedCompany(companies[0].name);
    return <Redirect to={`/company/${companies[0].name}`} />;
  }

  const escapedValue = escapeQuery(value);
  const regex = new RegExp(escapedValue, 'i');
  const filteredList = companies.filter((company) => {
    if (value.length === 0) {
      return true;
    }

    return regex.test(company.name) || regex.test(company.displayName);
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    if (value.length > 0) {
      eventsTracker.track(EVENTS.SEARCH_COMPANY);
    }
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company.name);
    eventsTracker.addToContext({ [EVENT_PROPERTY_COMPANY_NAME]: company.name });
    eventsTracker.track(EVENTS.SELECT_COMPANY);
    history.push(`/company/${company.name}`);
  };

  return (
    <>
      <DashboardToolbar exitText showAccountLink={false} showInviteLink={false} disableInviteLink={false} />
      <Container maxWidth='sm' style={{ marginTop: '10vh' }}>
        <Typography variant='h4' gutterBottom>
          Welcome back 👋
        </Typography>
        <Grid container direction='row' alignItems='center' justifyContent='space-between' style={{ marginBottom: '5px' }}>
          <Grid item>Companies for {user && user.fullname}</Grid>
          <Grid item>
            <TextField
              data-testid='company-name'
              style={{ backgroundColor: 'white' }}
              placeholder='Search...'
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              variant='outlined'
              size='small'
            />
          </Grid>
        </Grid>
        <Paper elevation={1}>
          <List component='nav' data-testid='companies-list'>
            {filteredList.length > 0 &&
              filteredList.map((company, index) => (
                <ListItem
                  button
                  data-company={company.name}
                  key={company.name}
                  divider={filteredList.length - 1 > index}
                  onClick={() => handleCompanyClick(company)}
                >
                  <ListItemText primary={company.displayName} />
                </ListItem>
              ))}
            {companies.length === 0 && (
              <ListItem>
                <ListItemText data-testid='message-no-companies' primary="You don't have access to any company" />
              </ListItem>
            )}
            {companies.length > 0 && filteredList.length === 0 && (
              <ListItem>
                <ListItemText data-testid='message-not-found' primary='Company not found' />
              </ListItem>
            )}
          </List>
        </Paper>
      </Container>
    </>
  );
};

export default Companies;
