import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Box, Button, ClickAwayListener, Fade, Popper, TextField } from '@material-ui/core';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';

import { DATA_SOURCE_TYPE, FADE_TIMEOUT } from '../../../constants';
import { ApplyButton, SelectorButton, SelectorContainer } from './UI';
import { DashboardDataSource } from '../../../types';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import DataSourcesSelectorListContainer from './DataSourcesSelectorListContainer';
import { PopperModifiers } from '../constants';

const Counter = styled.span`
  color: #a9a9a9;
`;

const DataSourceTypeButton = styled(Button)`
  &.MuiButton-root {
    font-size: 12px;
    margin-right: 8px;
    padding: 2px 8px;
  }
`;

const SearchTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-top: 10px;
  }
`;

enum DataSourceType {
  ALL,
  CLOSED_ENDED,
  OPEN_ENDED,
  INTERVIEW,
}

interface IDataSourcesSelectorProps {
  dataSourceIDs: string[];
  visibleDataSources: DashboardDataSource[];
  defaultSelectedDataSourceIDs: string[];
  onChange: (dataSourceIDs: string[]) => void;
}

function DataSourcesSelectorContainer({
  dataSourceIDs,
  visibleDataSources,
  defaultSelectedDataSourceIDs,
  onChange,
}: IDataSourcesSelectorProps) {
  const translationUsed = useSelector((state: any) => state.app.translationUsed);
  const titleField = translationUsed ? 'title' : 'originalTitle';
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [localDataSourceIDs, setLocalDataSourceIDs] = React.useState<string[]>(dataSourceIDs);
  const [searchString, setSearchString] = React.useState<string>('');

  const selected = !isEqual(dataSourceIDs, defaultSelectedDataSourceIDs);
  const disabled = isEqual(dataSourceIDs, localDataSourceIDs);

  useEffect(() => {
    setLocalDataSourceIDs(dataSourceIDs);
  }, [dataSourceIDs]);

  function closeDataSourcesSelector() {
    setLocalDataSourceIDs(dataSourceIDs);
    setOpen(false);
  }

  function handleDataSourceTypeClick(type: DataSourceType, event: React.SyntheticEvent<HTMLButtonElement>) {
    const typeText = capitalize((event.target as HTMLButtonElement).innerText);
    eventsTracker.track(EVENTS.SELECT_DATA_SOURCES_TYPE_SELECT, {
      'Data source Type': typeText,
    });
    const ids = visibleDataSources
      .filter(
        (dataSource) =>
          (type === DataSourceType.ALL && localDataSourceIDs.length !== visibleDataSources.length) ||
          (type === DataSourceType.OPEN_ENDED && dataSource.type === DATA_SOURCE_TYPE.OPEN_ENDED) ||
          (type === DataSourceType.INTERVIEW && dataSource.type === DATA_SOURCE_TYPE.INTERVIEW) ||
          (type === DataSourceType.CLOSED_ENDED && dataSource.type !== DATA_SOURCE_TYPE.OPEN_ENDED),
      )
      .map((dataSource) => dataSource.dataSourceID);
    setLocalDataSourceIDs(ids);
  }

  function handleClick(event: React.SyntheticEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    if (open) {
      closeDataSourcesSelector();
    } else {
      setSearchString('');
      eventsTracker.track(EVENTS.SELECT_DATA_SOURCES_SELECT);
      setOpen(true);
    }
  }

  function handleResetClick() {
    eventsTracker.track(EVENTS.SELECT_DATA_SOURCES_RESET, {
      'Filtered data sources count': dataSourceIDs.length,
    });
    onChange(defaultSelectedDataSourceIDs);
    setOpen(false);
  }

  function handleApplyClick() {
    const dataSourceTitleById = visibleDataSources.reduce<Record<string, string>>(
      (acc, dataSource) => ({
        ...acc,
        [dataSource.dataSourceID]: dataSource[titleField],
      }),
      {},
    );
    const included = localDataSourceIDs.map((id) => dataSourceTitleById[id]);
    const excluded = Object.keys(dataSourceTitleById)
      .filter((id) => !localDataSourceIDs.includes(id))
      .map((id) => dataSourceTitleById[id]);
    eventsTracker.track(EVENTS.SELECT_DATA_SOURCES_APPLY, {
      'Data sources included': included,
      'Data sources excluded': excluded,
    });
    onChange(localDataSourceIDs);
    setOpen(false);
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchString(event.target.value);
  }

  function handleDataSourcesChange(dataSourceIDs: string[]) {
    setLocalDataSourceIDs(dataSourceIDs);
  }

  return (
    <>
      <SelectorButton data-testid='data-sources-selector-selector-button' size='small' onClick={handleClick} selected={selected}>
        Select data source(s)
      </SelectorButton>
      <Popper open={open} anchorEl={anchorEl} placement='bottom-end' transition modifiers={PopperModifiers}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closeDataSourcesSelector}>
            <Fade {...TransitionProps} timeout={FADE_TIMEOUT}>
              <SelectorContainer>
                <Box>
                  <Box display='flex' justifyContent='space-between'>
                    <strong>Select data source(s)</strong>
                    <Counter>
                      {localDataSourceIDs.length}/{visibleDataSources.length} selected
                    </Counter>
                  </Box>

                  <Box marginTop='10px'>
                    <DataSourceTypeButton
                      size='small'
                      variant='outlined'
                      onClick={handleDataSourceTypeClick.bind(null, DataSourceType.ALL)}
                    >
                      All
                    </DataSourceTypeButton>
                    <DataSourceTypeButton
                      size='small'
                      variant='outlined'
                      onClick={handleDataSourceTypeClick.bind(null, DataSourceType.CLOSED_ENDED)}
                    >
                      Closed-ended
                    </DataSourceTypeButton>
                    <DataSourceTypeButton
                      size='small'
                      variant='outlined'
                      onClick={handleDataSourceTypeClick.bind(null, DataSourceType.OPEN_ENDED)}
                    >
                      Open-ended
                    </DataSourceTypeButton>
                  </Box>

                  <SearchTextField
                    data-testid='data-sources-selector-search-field'
                    value={searchString}
                    onChange={handleSearchChange}
                    label='Search'
                    variant='outlined'
                    size='small'
                    fullWidth={true}
                  />

                  <DataSourcesSelectorListContainer
                    visibleDataSources={visibleDataSources}
                    searchString={searchString}
                    localDataSourceIDs={localDataSourceIDs}
                    titleField={titleField}
                    onChange={handleDataSourcesChange}
                  />
                </Box>
                <Box display='flex' flexDirection='row' justifyContent='flex-end'>
                  {selected && <Button onClick={handleResetClick}>Reset</Button>}
                  <ApplyButton variant='contained' onClick={handleApplyClick} disabled={disabled}>
                    Apply
                  </ApplyButton>
                </Box>
              </SelectorContainer>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

export default DataSourcesSelectorContainer;
