import { ComponentProps } from 'react';
import styled from 'styled-components';

import ResponsesInfiniteLoader from '../../../components/Responses/ResponsesInfiniteLoader';
import { IRowRenderer } from '../../../components/Responses/types';
import { DEFAULT_PAGE_SIZE } from '../../../../../store/thunks/interviewWidget';
import LoadingLabel from '../../../../LoadingLabel/LoadingLabel';
import { ResponseLoadingOverlay } from '../../../components/Responses/style';
import NoResponses from '../../../components/Responses/NoResponses';
import { Label } from '../../../../../types/Label';
import ManageTopicTagsModal from '../../../components/Responses/ManageTopicTags';

import ConversationRow from './ConversationRow';
import { IManageMenuState } from './types';

const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 4px;
  position: relative;
`;

const ResponsesContainer = styled.div`
  padding: 4px 0;
  height: calc(100% - 28px);
`;

interface IConversationList {
  labels: Label[];
  rowLabels: string[];
  rowCount: number;
  manageMenuState: IManageMenuState | null;
  isLoading: boolean;
  isManagementLoading: boolean;
  onGetRowProps: (index: number, renderProps: IRowRenderer) => ComponentProps<typeof ConversationRow>;
  onIsRowLoaded: (index: { index: number }) => boolean;
  onLoadMoreResponses: ({ startIndex }: { startIndex: number }) => Promise<any>;
  onManageMenuClose: () => void;
  onRemoveResponseLabel: (label: string) => void;
  onAddResponseLabel: (label: string) => void;
}

function ConversationList({
  labels,
  rowLabels,
  rowCount,
  manageMenuState,
  isLoading,
  isManagementLoading,
  onGetRowProps,
  onIsRowLoaded,
  onLoadMoreResponses,
  onManageMenuClose,
  onAddResponseLabel,
  onRemoveResponseLabel,
}: IConversationList) {
  const labelsOptions = labels.map((label) => label.title);
  return (
    <ListContainer>
      <ResponsesContainer>
        <ResponsesInfiniteLoader
          pageSize={DEFAULT_PAGE_SIZE}
          threshold={10}
          rowCount={rowCount}
          getRowProps={onGetRowProps}
          rowRenderer={ConversationRow}
          isRowLoaded={onIsRowLoaded}
          loadMoreRows={onLoadMoreResponses}
          onScroll={onManageMenuClose}
        />
        {isLoading && (
          <ResponseLoadingOverlay>
            <LoadingLabel loading={true} />
          </ResponseLoadingOverlay>
        )}
        {rowCount === 0 && <NoResponses />}
      </ResponsesContainer>
      <ManageTopicTagsModal
        labels={labelsOptions}
        rowLabels={rowLabels}
        anchorEl={manageMenuState?.anchorEl}
        isLoading={isManagementLoading}
        onCloseClick={onManageMenuClose}
        onAddLabelClick={onAddResponseLabel}
        onRemoveLabelClick={onRemoveResponseLabel}
        isOpen={!!manageMenuState?.anchorEl}
      />
    </ListContainer>
  );
}

export default ConversationList;
