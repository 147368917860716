import React, { useEffect, useState } from 'react';
import LabelsSelect from './LabelsSelect';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { Label } from '../../../../types/Label';
import { Concept } from '../../../../store/reducers/aiInsights';

const AUTOCOMPLETE_REASON = {
  CREATE_OPTION: 'create-option',
  SELECT_OPTION: 'select-option',
  REMOVE_OPTION: 'remove-option',
  RESET: 'reset',
};

interface ILabelsSelectContainer {
  widgetName: string;
  selectedLabelItem: Concept | null;
  labels: Label[];
  searchString: string;
  onSearchStringChange: (searchString: string) => void;
  onLabelSelect: (label: string) => void;
}
function LabelsSelectContainer({
  widgetName,
  selectedLabelItem,
  labels,
  searchString,
  onSearchStringChange,
  onLabelSelect,
}: ILabelsSelectContainer) {
  const [selectedLabels, setSelectedLabels] = useState<Label[]>([]);
  const [inputValue, setInputValue] = useState(searchString);
  const [prevInputValue, setPrevInputValue] = useState(inputValue);

  const trimmedInputValue = inputValue?.trim();
  const isChanged = trimmedInputValue !== searchString;
  const isInputButtonDisabled = inputValue.length === 0 && !isChanged;
  const shouldShowReset = !isChanged && searchString.length > 0;

  useEffect(() => {
    if (selectedLabelItem === null) {
      setSelectedLabels([]);
    } else {
      const label = labels.find((label) => label.displayTitle === selectedLabelItem.title);
      setSelectedLabels(label ? [label] : []);
    }
  }, [labels, selectedLabelItem]);

  const doSearch = (searchValue: string) => {
    onSearchStringChange(searchValue);
  };

  const filterOption = (options: any, state: any) => {
    const searchString = state.inputValue.toLowerCase();
    return options.filter(
      (option: { children: string[] }) =>
        state.getOptionLabel(option).toLowerCase().includes(searchString) || // label itself
        (option.children && option.children.some((child) => child.toLowerCase().includes(searchString))), // parent of matched label
    );
  };

  function handleChange(_: any, newValue: any, reason: any) {
    switch (reason) {
      case AUTOCOMPLETE_REASON.CREATE_OPTION: // user hit Enter and input value is not one of options
        const newInputValue = newValue[newValue.length - 1];
        setInputValue(newInputValue);
        doSearch(newInputValue.trim());
        eventsTracker.track(EVENTS.WIDGET_RESPONSES_SEARCH, {
          'Widget Name': widgetName,
          'Search Text': newInputValue.trim(),
          'Label Selected': !!selectedLabelItem,
        });
        break;
      case AUTOCOMPLETE_REASON.SELECT_OPTION: // user selected option from the dropdown
        if (newValue.length === 0) {
          //todo: check that works
          onLabelSelect(selectedLabels[0].title);
        } else {
          onLabelSelect(newValue[newValue.length - 1].displayTitle);
        }
        setInputValue('');
        setSelectedLabels(newValue);
        break;
      case AUTOCOMPLETE_REASON.REMOVE_OPTION: // removed select label by clicking x or backspace
        onLabelSelect(selectedLabels[0].displayTitle);
        break;
      default:
    }
  }

  function handleInputChange(_: any, newInputValue: any, reason: any) {
    if (reason !== AUTOCOMPLETE_REASON.RESET) {
      setInputValue(newInputValue);
    }
  }

  function handleControlClick() {
    if (shouldShowReset) {
      setInputValue('');
      onSearchStringChange('');
      if (selectedLabels.length > 0) {
        onLabelSelect(selectedLabels[0].displayTitle);
      }
    } else {
      doSearch(inputValue.trim());
    }
  }

  function handleFocus() {
    setInputValue(prevInputValue);
  }

  function handleBlur() {
    setPrevInputValue(inputValue);
    setInputValue(searchString);
  }

  return (
    <LabelsSelect
      inputValue={inputValue}
      selectedLabelItem={selectedLabelItem}
      title={selectedLabelItem?.title}
      filterOptions={filterOption}
      isInputButtonDisabled={isInputButtonDisabled}
      searchString={searchString}
      shouldShowReset={shouldShowReset}
      options={labels}
      selectedLabels={selectedLabels}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onControlClick={handleControlClick}
    />
  );
}

export default LabelsSelectContainer;
