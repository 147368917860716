import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import { Overview } from '../../../overview';
import useSummaryPlainFetch from '../../../../hooks/fetch/useSummaryPlainFetch';
import { FETCH_STATUS } from '../../../../constants';
import useSummaryPlainProcessing from '../../../../hooks/processing/useSummaryPlainProcessing';

const OverviewWidgetContentPlain = ({ config, filters, visibleDataSourcesIds, handleHeightUpdate, query }) => {
  const { id: dashboardId, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
  const skipFetching = config.base.collapsed;

  const dataSourceToFetchIds = useMemo(() => {
    return visibleDataSourcesIds.filter((id) => dashboardDataSources.some((dds) => dds.dataSourceID === id && !dds.isEmpty));
  }, [visibleDataSourcesIds, dashboardDataSources]);
  const { responses, status } = useSummaryPlainFetch(dashboardId, filters, dataSourceToFetchIds, query, skipFetching);
  const overviewData = useSummaryPlainProcessing(responses, visibleDataSourcesIds);

  if ((status !== FETCH_STATUS.LOADED && !skipFetching) || overviewData.length !== filters.length) {
    return <LoadingLabel textContent='Loading overview responses...' loading={true} style={{ padding: '2em' }} />;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Overview overviewData={overviewData} filters={filters} config={config} onHeightUpdate={handleHeightUpdate} />
    </div>
  );
};

export default OverviewWidgetContentPlain;
