import { SegmentModelFactory } from '../models/Segment';

export const prepareSegments = (data, weightedMetricsUsed, translationUsed) => {
  const titleField = translationUsed ? 'title' : 'originalTitle';
  const nameField = translationUsed ? 'name' : 'originalName';
  return data
    .filter((item) => item.weighted === !!weightedMetricsUsed)
    .map((item) =>
      SegmentModelFactory({
        ...item,
        traits: item.traits.map((trait) => ({
          ...trait,
          displayTitle: trait[titleField],
          values: trait.values.map((value) => ({
            ...value,
            displayName: value[nameField],
          })),
        })),
        properties: item.properties.map((property) => ({
          ...property,
          displayTitle: property[titleField],
          values: property.values.map((value) => ({
            ...value,
            displayName: value[nameField],
          })),
        })),
      }),
    );
};
