import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ExpandIcon } from '../../assets/icons/expand-icon.svg';
import { ReactComponent as SurveyIcon } from '../../assets/icons/survey-icon.svg';
import { ReactComponent as MicrophoneIcon } from '../../assets/icons/microphone-icon.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users-icon.svg';
import { ReactComponent as AnnouncementIcon } from '../../assets/icons/announcement-icon.svg';
import BannerImageOne from '../../assets/images/banner-image-one.png';
import BannerImageTwo from '../../assets/images/banner-image-two.png';
import BannerImageThree from '../../assets/images/banner-image-three.png';
import BannerImageOneLarge from '../../assets/images/banner-image-one-large.png';
import BannerImageTwoLarge from '../../assets/images/banner-image-two-large.png';
import BannerImageThreeLarge from '../../assets/images/banner-image-three-large.png';

const StyledExpandIcon = styled(ExpandIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const InvertedExpandIcon = styled(StyledExpandIcon)`
  transform: rotate(180deg);
`;

const Background = styled.div`
  background-color: var(--white);
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const DropdownHeaderText = styled.div`
  font-size: 20px;
  color: var(--black);
`;

const DropdownHeader = styled.div`
  display: flex;
  gap: 8px;
`;

const DropdownContentContainer = styled.div<{ isOpen?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition:
    max-height 0.5s ease-in-out,
    margin-top 0.5s ease-in-out;
`;

const DropdownContentItem = styled.div`
  height: 167px;
  display: flex;
  flex-direction: row;
  border-radius: var(--double-border-radius);
  background-color: var(--light-gray);
  overflow: hidden;
  position: relative;
  gap: 0px;

  @media (max-width: 1280px) {
    flex-direction: column;
    height: 250px;
  }
`;

const DropdownContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 8px 16px 0;
`;

const DropdownContentHeaderText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 12px;
`;

const DropdownContentSubtext = styled.div`
  font-size: 14px;
  color: var(--dark-gray);
  margin-left: 12px;
`;

const ItemNumber = styled.div`
  height: 32px;
  width: 32px;
  position: absolute;
  background-color: var(--beehive-purple);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  top: 8px;
  left: 8px;
  user-select: none;
`;

const StyledImg = styled.img<{ src: string; srcLarge: string }>`
  margin-right: 8px;

  @media (max-width: 1280px) {
    margin-right: 0;
    content: url(${({ srcLarge }) => srcLarge});
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

const IconListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 8px;
`;

const IconListItem = styled.li`
  display: flex;
  gap: 8px;
`;

interface IHivesBanner {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function HivesBanner({ isOpen, setIsOpen }: IHivesBanner) {
  return (
    <Background>
      <DropdownHeader>
        {renderExpandIcon()}
        <DropdownHeaderText>Start discovering new insights by uploading data</DropdownHeaderText>
      </DropdownHeader>
      <DropdownContentContainer isOpen={isOpen}>
        {renderDropdownItem(1, 'Upload Data', '', BannerImageOne, BannerImageOneLarge, renderDropdownList())}
        {renderDropdownItem(
          2,
          'Beehive AI Analyzes',
          'Beehive AI does the manual work for you. It saves you time and frees you to focus on higher impact activities.',
          BannerImageTwo,
          BannerImageTwoLarge,
        )}
        {renderDropdownItem(
          3,
          'Discover Insights',
          'Discover deeper insights about your customer from the data you already have.',
          BannerImageThree,
          BannerImageThreeLarge,
        )}
      </DropdownContentContainer>
    </Background>
  );

  function renderExpandIcon() {
    if (isOpen) {
      return <InvertedExpandIcon onClick={setIsOpen.bind(null, false)} />;
    } else {
      return <StyledExpandIcon onClick={setIsOpen.bind(null, true)} />;
    }
  }

  function renderDropdownList() {
    return (
      <IconListContainer>
        {renderDropdownListItem(<SurveyIcon />, 'Surveys')}
        {renderDropdownListItem(<MicrophoneIcon />, 'Interview transcripts')}
        {renderDropdownListItem(<UsersIcon />, 'Focus groups')}
        {renderDropdownListItem(<AnnouncementIcon />, <div>Reviews and more</div>)}
      </IconListContainer>
    );
  }

  function renderDropdownListItem(Icon: React.ReactNode, children: React.ReactNode) {
    return (
      <IconListItem>
        <IconContainer>{Icon}</IconContainer>
        {children}
      </IconListItem>
    );
  }

  function renderDropdownItem(
    DropdownNumber: number,
    DropdownHeader: string,
    DropdownSubtext: string,
    Image: string,
    ImageLarge: string,
    children?: React.ReactNode,
  ) {
    return (
      <DropdownContentItem>
        <ItemNumber>{DropdownNumber}</ItemNumber>
        <StyledImg src={Image} srcLarge={ImageLarge} />
        <DropdownContentText>
          <DropdownContentHeaderText>{DropdownHeader}</DropdownContentHeaderText>
          <DropdownContentSubtext>
            {DropdownSubtext}
            {children}
          </DropdownContentSubtext>
        </DropdownContentText>
      </DropdownContentItem>
    );
  }
}

export default HivesBanner;
