import React, { useCallback, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { FILTER_ID_ALL } from '../../../../constants';
import { useAppSelector } from '../../../../store/hooks';
import { getAppliedFiltersCount } from '../../../UI/lib';
import { Filter } from '../../../../types/Query';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import SegmentsRow from './SegmentsRow';
import { TotalsByDataSourcesAndFiltersIDS } from '../../OpenEndedWidget/Data/OpenWidgetData';

const DEBOUNCE_PERIOD = 500;

const useFilterToggle = (initialValue: string[], onChange: (ids: string[]) => void) => {
  const [currentFiltersIds, setCurrentFiltersIds] = useState(initialValue);
  const prevValue = useRef(initialValue);

  if (!isEqual(prevValue.current, initialValue)) {
    prevValue.current = initialValue;
    setCurrentFiltersIds(initialValue);
  }

  const callOnChange = useCallback(
    debounce((filterIds) => onChange(filterIds), DEBOUNCE_PERIOD),
    [onChange],
  );

  const toggleId = useCallback(
    (id: string) => {
      let updatedIds = [...currentFiltersIds];
      const isChangingNotAllowed = currentFiltersIds.length === 1 && currentFiltersIds[0] === FILTER_ID_ALL && id === FILTER_ID_ALL;
      if (isChangingNotAllowed) {
        return;
      }

      if (currentFiltersIds.includes(id)) {
        updatedIds = updatedIds.filter((fid) => fid !== id);
        if (updatedIds.length === 0) {
          updatedIds = [FILTER_ID_ALL];
        }
      } else {
        updatedIds.push(id);
      }

      setCurrentFiltersIds(updatedIds);
      callOnChange(updatedIds);
    },
    [currentFiltersIds, callOnChange],
  );

  return [currentFiltersIds, toggleId] as const;
};

/**
 * Presents list of globally applied filters in widgets.
 * Allows to select some of them for the narrowing results set.
 */
interface ISegmentsRowContainer {
  value: string[];
  filters: Filter[];
  totalsPerFilter: TotalsByDataSourcesAndFiltersIDS;
  widgetTitle: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: (ids: string[]) => void;
}

function SegmentsRowContainer({
  onChange,
  disabled = false,
  value,
  filters,
  totalsPerFilter,
  loading = false,
  widgetTitle,
}: ISegmentsRowContainer) {
  const weightedMetricsUsed = useAppSelector((state) => state.app.weightedMetricsUsed);
  const [selectedFilterIds, toggleFilterId] = useFilterToggle(value, onChange);

  if (getAppliedFiltersCount(filters) === 0) {
    return null;
  }

  const handleSegmentClick = (filter: Filter) => {
    eventsTracker.track(EVENTS.WIDGET_DASHBOARD_FILTERS_TOGGLE, {
      'Widget Name': widgetTitle,
      'Filter Name': filter.name,
      'Filter Count': selectedFilterIds.length,
    });
    toggleFilterId(filter.id);
  };

  return (
    <SegmentsRow
      selectedFilterIds={selectedFilterIds}
      filters={filters}
      totalsPerFilter={totalsPerFilter}
      disabled={disabled}
      loading={loading}
      weightedMetricsUsed={weightedMetricsUsed}
      onSegmentClick={handleSegmentClick}
    />
  );
}

export default SegmentsRowContainer;
