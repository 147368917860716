import styled from 'styled-components';

import { Integration, SurveySourceEnum } from '../../../types/connections';

import DeleteAuthModalContainer from './DeleteAuthModalContainer';
import IntegrationListItemContainer from './IntegrationListItemContainer';
import { useCallback } from 'react';

const Container = styled.div`
  flex: 0.15 0 300px;
  border-radius: 16px;
  background-color: #fff;
  margin-right: 20px;
  padding: 24px 24px;
`;

const Title = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 30px;
`;

interface IIntegrationsList {
  integrations: Integration[];
  selectedIntegration?: SurveySourceEnum;
  isConnectorsLoading: boolean;
  isSurveysLoading: boolean;
  handleSelect: (integration: Integration) => void;
  handleEdit: (integration: Integration) => void;
}

function IntegrationsList({
  isConnectorsLoading,
  isSurveysLoading,
  integrations,
  selectedIntegration,
  handleSelect,
  handleEdit,
}: IIntegrationsList) {
  const isLoading = useCallback(
    (integration: Integration) => {
      return integration.source === SurveySourceEnum.SURVEYMONKEY || integration.source === SurveySourceEnum.TYPEFORM
        ? isSurveysLoading
        : isConnectorsLoading;
    },
    [isConnectorsLoading, isSurveysLoading],
  );

  return (
    <Container data-testid='integrations-list-container'>
      <Title>Apps & Integrations</Title>
      {integrations.map((integration) => (
        <IntegrationListItemContainer
          key={integration.name}
          integration={integration}
          isSelected={selectedIntegration === integration.source}
          isLoading={isLoading(integration)}
          onSelect={handleSelect}
          onEdit={handleEdit}
        />
      ))}
      <DeleteAuthModalContainer />
    </Container>
  );
}

export default IntegrationsList;
