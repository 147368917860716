import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { AssignmentOutlined } from '@material-ui/icons';
import FullRecordDialogContent from './FullRecordDialogContent';
import palette from '../tools/palette';

const useStyles = makeStyles({
  popupPaper: {
    backgroundColor: palette.linkColorLight3,
  },
});

const FullRecordDialog = ({ answerId, open, onClose }) => {
  const classes = useStyles();
  if (answerId === null) {
    return null;
  }
  return (
    <Dialog open={open} onClose={onClose} scroll='paper' maxWidth='md' classes={{ paper: classes.popupPaper }} disableRestoreFocus>
      <DialogTitle id='scroll-dialog-title'>
        <AssignmentOutlined style={{ verticalAlign: 'text-bottom' }} /> Full record
      </DialogTitle>
      <DialogContent>
        <FullRecordDialogContent answerId={answerId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FullRecordDialog;
