import styled from 'styled-components';

export const WidgetStatusPanelContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
  gap: 24px;
`;

export const Title = styled.span`
  color: var(--dark-gray);
  font-size: 14px;
  font-weight: 400;
  flex-shrink: 1;
  flex-grow: 0;
`;

export const Counter = styled(Title)`
  font-size: 24px;
  color: var(--black);
`;

export const BorderedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-right: 16px;
  width: 100%;
  padding: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
`;
