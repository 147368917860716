import React, { useCallback, useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import { SENTIMENT } from '../../../../../../models/Response';
import { ExtraConcept } from '../../../../../../store/actions/openEndedWidget';
import { useAppSelector } from '../../../../../../store/hooks';
import ExpandableText from '../../../../../ExpandableText/ExpandableText';
import { DateWrapper, MoreIconStyled, ResponseAction, ResponseWrapper, Width100Grid } from '../../style';
import styles from '../Response.module.css';

import ResponseLabels, { ResponseChip } from './ResponseLabels';
import ResponseSentiment from './ResponseSentiment';
import { BookmarkIconStyled, HeaderContainer, HeaderRightPart, SentimentResponseContainer, StyledLabelsWrapper } from './styles';

// BDTC-3736: Remove "Show more data" from OE widget responses
const IS_SHOW_MORE_LABELS_FEATURE_ENABLED = false;

interface IResponseItem {
  bookmarked: boolean;
  measure: any;
  registerChild: any;
  style: any;
  measurerKey: any;
  id: any;
  date: any;
  displayAnswerText: any;
  labels: any;
  additionalLabels: ExtraConcept[];
  selectedLabelItem: any;
  onLabelClick: any;
  fetchAdditionalLabels: any;
  loading: any;
  sentiment: any;
  sentimentScore: any;
  hasSentiment: any;
  hiddenLabels: any[];
  isVisible: any;
  widgetConfig: any;
  dashboardDataSourcesById: any;
  labelToParent: any;
  selectedItems: any;
  onBookmarkClick: any;
  onOpenResponseMenuClick: (responseEl: any) => void;
}

function ResponseItem({
  bookmarked,
  measure,
  registerChild,
  style,
  measurerKey,
  id,
  date,
  displayAnswerText,
  labels,
  additionalLabels,
  selectedLabelItem,
  onLabelClick,
  fetchAdditionalLabels,
  loading,
  sentiment,
  sentimentScore,
  hasSentiment,
  hiddenLabels,
  isVisible,
  dashboardDataSourcesById,
  labelToParent,
  selectedItems,
  onBookmarkClick,
  onOpenResponseMenuClick,
}: IResponseItem) {
  const translationUsed = useAppSelector((state) => state.app.translationUsed);

  const [showAllLabels, setShowAllLabels] = useState(false);

  const moreIconRef = useRef<any | null>(null);

  const showDataLabel = showAllLabels ? 'Show less data' : 'Show more data';
  const titleField = translationUsed ? 'title' : 'originalTitle';
  const showSentiment = hasSentiment && sentiment !== SENTIMENT.UNKNOWN;

  useEffect(() => {
    if (id || isVisible) {
      measure();
    }
  }, [showAllLabels, id, measure, isVisible]);

  function showAllLabelsHandler() {
    setShowAllLabels(!showAllLabels);
    fetchAdditionalLabels();
  }

  function handleBookmarkClick() {
    onBookmarkClick(id, bookmarked);
  }

  const handleToggleAnswerExpand = useCallback(() => {
    if (id) {
      measure();
    }
  }, [measure, id]);

  return (
    <ResponseWrapper ref={registerChild} style={style} key={measurerKey} data-cy='response'>
      <HeaderContainer>
        {renderDateWrapper()}
        <HeaderRightPart>
          {renderSentiment()}
          {renderBookmarkIcon()}
          {renderMoreMenuIcon()}
        </HeaderRightPart>
      </HeaderContainer>
      <Width100Grid>
        <ExpandableText expandableButtonClassName={styles.toggleExpandAnswer} onToggleExpand={handleToggleAnswerExpand}>
          {displayAnswerText}
        </ExpandableText>
      </Width100Grid>
      <StyledLabelsWrapper>
        {renderResponseLabels()}
        {renderOptionalAllLabels()}
      </StyledLabelsWrapper>
      {IS_SHOW_MORE_LABELS_FEATURE_ENABLED && (
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <ResponseAction onClick={showAllLabelsHandler}>
              <span>{showDataLabel}</span>
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress size={8} />
                </>
              )}
            </ResponseAction>
          </Grid>
        </Grid>
      )}
      <Divider />
    </ResponseWrapper>
  );

  function renderOptionalAllLabels() {
    if (!showAllLabels) {
      return null;
    }

    return additionalLabels
      .filter((label) => !hiddenLabels.includes(label))
      .map((label, index) => (
        <ResponseChip
          $isSelected={selectedLabelItem !== null && selectedLabelItem.label === label}
          $isDisabled={false}
          key={index}
          data-tooltip-id='base-tooltip'
          data-tooltip-content={
            dashboardDataSourcesById.hasOwnProperty(label.DataSourceID) ? dashboardDataSourcesById[label.DataSourceID][titleField] : null
          }
        >
          {label.Heading}
        </ResponseChip>
      ));
  }

  function renderDateWrapper() {
    return date ? <DateWrapper className='DateWrapper'>{date}</DateWrapper> : <Skeleton width='20%' />;
  }

  function renderBookmarkIcon() {
    if (!date) {
      return <Skeleton />;
    }

    return <BookmarkIconStyled $isSelected={bookmarked} onClick={handleBookmarkClick} />;
  }

  function renderMoreMenuIcon() {
    return <MoreIconStyled ref={moreIconRef} onClick={(event) => onOpenResponseMenuClick(moreIconRef)} />;
  }

  function renderSentiment() {
    if (!showSentiment) {
      return <Skeleton width='30%' />;
    }
    return (
      <SentimentResponseContainer item>
        <ResponseSentiment sentiment={sentiment} score={sentimentScore} />
      </SentimentResponseContainer>
    );
  }

  function renderResponseLabels() {
    if (!labels?.length) {
      return <Skeleton width='50%' />;
    }
    return (
      <ResponseLabels
        labels={labels}
        hiddenLabels={hiddenLabels}
        labelToParent={labelToParent}
        selectedLabelItem={selectedLabelItem}
        selectedItems={selectedItems}
        onLabelClick={onLabelClick}
      />
    );
  }
}

export default ResponseItem;
