import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Label } from '../../../../../types/Label';
import { Filter } from '../../../../../types/Query';

import { ITopicManaging, ITopicRenameDelete } from './types';
import TopicRow from './TopicRow';
import AllTopicsRow from './AllTopicsRow';
import TopicBurgerMenu from './Components/TopicBurgerMenu';
import TopicCreateEdit from './TopicCreateEdit';

const LabelsListContainer = styled.div`
  background-color: transparent;
  overflow-y: auto;
  overflow-x: auto;
  flex-wrap: nowrap;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface ITopicsList extends ITopicManaging, ITopicRenameDelete {
  labels: Label[];
  filtersById: {
    [p: string]: Filter;
  };
  selectedFilterIds: string[];
  clickable: boolean;
  disabled: boolean;
  selectedLabelItem: string | null;
  isTopicDeleteConfirmationOpen: boolean;
  average?: boolean;
  onLabelClick: (label: string | null) => void;
}

function TopicsList({
  labels,
  filtersById,
  selectedFilterIds,
  clickable,
  disabled,
  selectedLabelItem,
  topic,
  isTopicManagingLoading,
  isTopicDeleteConfirmationOpen,
  average,
  onLabelClick,
  onTopicRenameClick,
  onTopicRenameCancel,
  onTopicRename,
  onTopicDeleteClick,
}: ITopicsList) {
  const [moreMenuParams, setMoreMenuParams] = useState<null | {
    anchorEl: any;
    label: string;
  }>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const allTopicsRef = useRef<HTMLDivElement>(null);

  const refs: { [key: string]: any } = useMemo(
    () => labels.reduce((acc, label) => ({ ...acc, [label.title]: React.createRef() }), {}),
    [labels],
  );

  const showTopicEdit = !isTopicDeleteConfirmationOpen && topic;

  useEffect(() => {
    let scrollRef;
    if (selectedLabelItem === null) {
      scrollRef = allTopicsRef;
    } else {
      if (selectedLabelItem in refs) {
        scrollRef = refs[selectedLabelItem];
      }
    }

    if (!scrollRef?.current || !listRef?.current) {
      return;
    }
    listRef.current.scroll({
      top: scrollRef.current.offsetTop - listRef.current.offsetTop,
      behavior: 'smooth',
    });
  }, [selectedLabelItem, refs]);

  function handleMenuReset() {
    setMoreMenuParams(null);
  }

  function handleMenuClick(ref: any, label: string) {
    setMoreMenuParams({ anchorEl: ref, label });
  }

  function handleRenameClick() {
    if (moreMenuParams) {
      onTopicRenameClick(moreMenuParams.label);
    }
    setMoreMenuParams(null);
  }

  function handleDeleteClick() {
    if (moreMenuParams) {
      onTopicDeleteClick(moreMenuParams.label);
    }
    setMoreMenuParams(null);
  }

  return (
    <LabelsListContainer className='labels-list' ref={listRef} onScroll={handleMenuReset}>
      <AllTopicsRow
        ref={allTopicsRef}
        clickable={clickable}
        disabled={disabled}
        selected={selectedLabelItem === null}
        onLabelClick={onLabelClick.bind(null, null)}
      />
      {labels.map((label) => {
        if (showTopicEdit && label.title && topic === label.title) {
          return (
            <TopicCreateEdit
              size='small'
              key={label.key}
              topic={topic}
              isLoading={isTopicManagingLoading}
              onSave={onTopicRename}
              onClose={onTopicRenameCancel}
            />
          );
        }
        return (
          <TopicRow
            ref={refs[label.title]}
            key={label.key}
            label={label}
            filtersById={filtersById}
            selectedFilterIds={selectedFilterIds}
            clickable={clickable}
            disabled={disabled}
            selected={selectedLabelItem === label.title}
            average={average}
            onLabelClick={onLabelClick.bind(null, label.title)}
            onMenuClick={handleMenuClick}
          />
        );
      })}
      <TopicBurgerMenu
        anchorEl={moreMenuParams?.anchorEl}
        isOpen={!!moreMenuParams?.anchorEl}
        onCloseClick={handleMenuReset}
        onRenameClick={handleRenameClick}
        onDeleteClick={handleDeleteClick}
      />
    </LabelsListContainer>
  );
}

export default TopicsList;
