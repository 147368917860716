import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { initializeLanguages } from '../../../../store/thunks/languages';

type SKeyValue = { key: string; value: string };

const useLanguages = (onUpdateError: (err: string) => void) => {
  const {
    languages: languagesMap,
    initialized: isLanguagesInitialized,
    error: isLanguagesError,
  } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();
  const [languages, setLanguages] = useState<SKeyValue[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    if (!isLanguagesInitialized) {
      dispatch(initializeLanguages());
      return;
    }
    if (isLanguagesError) {
      onUpdateError('Failed to load languages.');
    }
  }, [dispatch, onUpdateError, isLanguagesInitialized, isLanguagesError]);

  useEffect(() => {
    if (!isEmpty(languagesMap)) {
      const languagesData = Object.entries(languagesMap).map(([key, value]) => ({ key, value: value as string }));
      setLanguages(languagesData);
      setSelectedLanguage('en');
    }
  }, [languagesMap]);

  return { languages, selectedLanguage, setSelectedLanguage };
};

export default useLanguages;
