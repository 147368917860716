import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';

const Container = styled.div`
  color: var(--text-primary);
  width: 100%;
  font-family:
    'Rubik',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
`;

const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const BlocksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0;
`;

const MiddleBlock = styled.div`
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const Block = styled.div`
  display: flex;
  background-color: var(--white);
  padding: 16px;
  border-radius: 8px;
  width: 40%;
  font-size: 13px;
`;

const BlockHeader = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

const IconWrapper = styled.div`
  background-color: var(--light-gray);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-right: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled.a`
  color: var(--link);
`;

function CSVUploadHelp() {
  function handleHelpPageClick() {
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_HELP_PAGE_SELECT);
  }

  function handleCSVTemplateClick() {
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_CSV_TEMPLATE_SELECT);
  }

  return (
    <Container>
      <Title>Prepare your file</Title>
      <BlocksContainer>
        <Block>
          <IconWrapper>
            <DocumentIcon />
          </IconWrapper>
          <div>
            <BlockHeader>Follow our Guided Tutorial</BlockHeader>
            Follow the instructions outlined in our{' '}
            <StyledLink
              href='https://help.beehive.ai/en/articles/8441322-how-to-clean-csv-file-formatting'
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleHelpPageClick}
            >
              help page
            </StyledLink>{' '}
            to ensure a smooth upload experience.
          </div>
        </Block>
        <MiddleBlock>Or</MiddleBlock>
        <Block>
          <IconWrapper>
            <DocumentIcon />
          </IconWrapper>
          <div>
            <BlockHeader>Download the CSV Template</BlockHeader>
            Use{' '}
            <StyledLink
              href='https://storage.googleapis.com/assets.beehive.ai/data/BeehiveAICSVUploadTemplate.csv'
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleCSVTemplateClick}
            >
              this CSV Template
            </StyledLink>{' '}
            as a reference for a properly organized CSV file.
          </div>
        </Block>
      </BlocksContainer>
    </Container>
  );
}

export default CSVUploadHelp;
