import React from 'react';
import StatusPanel from './StatusPanel';
import { useAppSelector } from '../../../../store/hooks';

export type SentimentSummary = {
  positive: number;
  neutral: number;
  negative: number;
};

interface IStatusPanelContainer {
  answeredCount: number;
  analyzedCount: number;
  weightedTotal: number;
  sentimentSummary: SentimentSummary;
  hasSentiment: boolean;
}

function StatusPanelContainer({ answeredCount, analyzedCount, sentimentSummary, hasSentiment, weightedTotal }: IStatusPanelContainer) {
  const weightedMetricsUsed = useAppSelector((state) => state.app.weightedMetricsUsed);
  const percentage = answeredCount === 0 ? 0 : analyzedCount / answeredCount;
  const pct = isFinite(percentage) ? Math.round(percentage * 100) : 0;

  return (
    <StatusPanel
      answeredCount={answeredCount}
      sentimentSummary={sentimentSummary}
      total={answeredCount}
      hasSentiment={hasSentiment}
      weightedMetricsUsed={weightedMetricsUsed}
      weightedTotal={weightedTotal}
      percentage={pct}
    />
  );
}

export default StatusPanelContainer;
