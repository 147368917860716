import React from 'react';
import { numberWithCommas } from '../../../tools';
import SentimentStatus from './Components/Sentiment';
import WidgetElement from './Components/WidgetElement';
import { SentimentSummary } from './StatusPanelContainer';
import { Counter, OpenEndedWidgetStatusPanelContainer } from './Components/style';

interface IOpenEndedWidgetStatusPanel {
  answeredCount: number;
  sentimentSummary: SentimentSummary;
  total: number;
  hasSentiment: boolean;
  weightedTotal: number;
  weightedMetricsUsed: boolean;
  percentage: number;
}

function OpenEndedWidgetStatusPanel({
  answeredCount,
  sentimentSummary,
  total,
  hasSentiment,
  weightedTotal,
  weightedMetricsUsed,
  percentage,
}: IOpenEndedWidgetStatusPanel) {
  const shouldRenderSentiment = hasSentiment && sentimentSummary;
  return (
    <OpenEndedWidgetStatusPanelContainer data-testid='status-panel'>
      <WidgetElement title='Answered' renderChild={renderAnswered} />

      {weightedMetricsUsed && (
        <WidgetElement title='Weighted Total' isWeighted={true} dataTestId='weighted-container' renderChild={renderWeighted} />
      )}

      <WidgetElement isWeighted={weightedMetricsUsed} title='Coverage' renderChild={renderAnalysedAnswers} />

      {shouldRenderSentiment && (
        <WidgetElement title='Sentiment' dataTestId='sentiment-statuses-container' renderChild={renderSentimentAnalysis} />
      )}
    </OpenEndedWidgetStatusPanelContainer>
  );

  function renderAnswered() {
    return <Counter data-testid='answered'>{numberWithCommas(answeredCount)}</Counter>;
  }

  function renderWeighted() {
    return <Counter data-testid='weighted-total'>{numberWithCommas(weightedTotal)}</Counter>;
  }

  function renderAnalysedAnswers() {
    return <Counter data-testid='coverage'>{percentage}%</Counter>;
  }

  function renderSentimentAnalysis() {
    return <SentimentStatus sentimentSummary={sentimentSummary} total={total} />;
  }
}

export default OpenEndedWidgetStatusPanel;
