import { MutableRefObject, Ref, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { debounce, isEqual } from 'lodash';

import {
  CurrentDashboardDataSource,
  CurrentDashboardInfo,
  DashboardSentimentSummariesSentiment,
  DataSourceRegeneratingStatus,
} from '../../../../store/reducers/dashboard/types';
import { Config } from '../../../../types/Config';
import { Query } from '../../../../types/Query';
import { ResponseListItem, useDataBySelectedSavedFilterId } from '../../../overview/lib';
import { FETCH_STATUS_TYPE, FILTER_ID_ALL, FILTER_ID_CURRENT } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useSavedFilters } from '../../../../contexts/SavedFiltersContext';
import useSelectedFilterIdsToggle from '../../../../hooks/useSelectedFilterIdsToggle';
import { WidgetTotalsContext } from '../../WidgetTotalsContext';
import { getAppliedFiltersCount } from '../../../UI/lib';
import { initialize, selectConcept } from '../../../../store/actions/interviewWidget';
import { generateInsightsFGRequest, initializeInsightsForWidget } from '../../../../store/actions/aiInsights';
import { stopGeneratingInsights } from '../../../../store/thunks/interviewAIInsights';
import { WidgetDataSource } from '../../../../types/DataSource';
import { DATA_VIEW } from '../../utils/lib';
import {
  EMPTY_FILTER_QUERY,
  createContainsValuesRule,
  createGroup,
  createInCustomersValuesRule,
  createInValuesRule,
  narrowQueryWithRule,
} from '../../../FilterForm/lib';
import { COMBINATOR } from '../../../FilterForm/constants';
import { InsightsFilter } from '../../../../store/thunks/aiInsights';

import { useDataFilters, useLabels } from './dataHooks';
import InterviewWidgetData from './InterviewWidgetData';
import { loadResponses } from '../../../../store/thunks/interviewWidget';
import { SortingQuery } from '../../../../types/SortingQuery';
import { TOPICS_SORT_FIELDS } from '../../utils/types';
import { Concept } from '../../../../store/reducers/aiInsights';
import { generateInsightsRequest } from '../../../../store/actions/aiInsights';
import { dashboardWidgetReloadResponsesSuccess } from '../../../../store/actions/dashboard';

export type CurrentDashboardDataSourceInfo = {
  sentimentSummary: DashboardSentimentSummariesSentiment;
  usersNum: number;
} & CurrentDashboardDataSource;

interface IInterviewWidgetDataContainer {
  currentDashboardDataSourceInfo: CurrentDashboardDataSourceInfo;
  overviewAPIOutput: ResponseListItem[];
  config: Config;
  onExpand: () => void;
  onFeedbackClick: (title: { title: string }) => void;
  refCsvExport: Ref<() => void>;
  onResetAllFilters: () => void;
  refFiltersCount: MutableRefObject<number>;
  selectedItems: string[];
  query: Query;
  loading: FETCH_STATUS_TYPE;
  isPreview: boolean;
  refContent?: MutableRefObject<HTMLDivElement> | null;
  onLabelSelectionTrack: (label: string, isParent: boolean, count: number) => void;
}

function InterviewWidgetDataContainer({
  currentDashboardDataSourceInfo,
  overviewAPIOutput,
  config,
  onExpand,
  onFeedbackClick,
  // TODO do we need?
  refCsvExport,
  refContent,
  onResetAllFilters,
  refFiltersCount,
  selectedItems,
  query: currentWidgetFilter,
  loading,
  onLabelSelectionTrack,
  isPreview,
}: IInterviewWidgetDataContainer) {
  const widgetID = config.base.id as string;
  const dispatch = useAppDispatch();
  const { filters, filtersById } = useDataFilters(isPreview);
  const { savedFiltersById } = useSavedFilters();
  const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);
  const overviewDataById = useDataBySelectedSavedFilterId(overviewAPIOutput, filters);
  const { loading: totalsLoading, totalsByDataSourcesAndFiltersIds } = useContext(WidgetTotalsContext);
  const { hasSentiment, dataSources: dashboardDataSources } = useAppSelector((state) => state.dashboard.dashboard) as CurrentDashboardInfo;
  const { translationUsed, weightedMetricsUsed } = useAppSelector((state) => state.app);

  const dataSourceID = currentDashboardDataSourceInfo.dataSourceID;

  const currentDashboardFilter = useAppSelector((state) => state.filter.query);
  const dataSources = useAppSelector((state) => state.dashboard.dataSources);
  const regenerationState = useAppSelector((state) => state.dashboard.regeneratingStatesByDataSourceID[dataSourceID]);
  const shouldResponsesBeReloaded = useAppSelector((state) => state.dashboard.shouldBeReloadedByWidgetID[widgetID]);

  const widgetConfig = useAppSelector((state) => state.interviewWidgets.configsByWidgetID[widgetID]);
  const responsesState = useAppSelector((state) => state.interviewWidgets.conversationsStatesByWidgetID[widgetID]);

  const [areInitialInsightsBeingGenerated, setAreInitialInsightsBeingGenerated] = useState(true);
  const [prevInsightsQuery, setPrevInsightsQuery] = useState({});

  const [topicsSorting, setTopicsSorting] = useState<SortingQuery>({
    sortBy: TOPICS_SORT_FIELDS.FREQUENCY,
    desc: false,
  });
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);

  const generateInsightsDebounce = useCallback(
    debounce((params) => {
      dispatch(generateInsightsFGRequest(params));
    }, 1000),
    [],
  );

  const loadResponsesDebounce = useCallback(
    debounce((params) => {
      dispatch(loadResponses(params));
    }, 1000),
    [],
  );

  refFiltersCount.current = selectedFilterIds.length;

  const hasResponses = responsesState?.responsesTotal > 0 || currentDashboardDataSourceInfo.usersNum > 0;
  const showResponses = config.settings.showResponses as boolean;
  const average = config.settings.merge && getAppliedFiltersCount(filters) > 0;

  useEffect(() => {
    dispatch(
      initialize({
        widgetID,
        dataSourceID,
      }),
    );

    // TODO initialize FG insights
    dispatch(
      initializeInsightsForWidget({
        widgetID,
        selectedConcept: undefined,
        dataSource,
        filteredConcepts: selectedItems,
      }),
    );

    return () => {
      dispatch(stopGeneratingInsights({ widgetID }));
      loadResponsesDebounce.cancel();
      generateInsightsDebounce.cancel();
    };
  }, []);

  const dataSource = useMemo(() => {
    const dataSource = dataSources.find((ds) => ds.dataSourceID === dataSourceID);
    const dataSourceInfo = dashboardDataSources.find((ds) => ds.dataSourceID === dataSourceID);
    const valueType = weightedMetricsUsed ? 'weighted' : 'base';
    const items = dataSourceInfo?.isEmpty
      ? []
      : dataSource?.items
          .map((item) => ({
            ...item,
            displayResponse: translationUsed ? item.response : item.originalResponse,
          }))
          .sort((a, b) => (dataSourceInfo?.ordered ? 1 : b[valueType].value - a[valueType].value));

    return {
      ...dataSourceInfo,
      ...dataSource,
      items,
    } as WidgetDataSource;
  }, [currentDashboardDataSourceInfo, dataSources, dashboardDataSources, weightedMetricsUsed, translationUsed]);

  // TOPICS
  const { labels } = useLabels(overviewDataById, dataSource, filtersById, selectedFilterIds, selectedItems, topicsSorting);
  //
  const responsesFilterQuery = useMemo(() => {
    const queries = [];
    for (const filterId of selectedFilterIds) {
      switch (filterId) {
        case FILTER_ID_ALL:
          queries.push(EMPTY_FILTER_QUERY);
          break;
        case FILTER_ID_CURRENT:
          queries.push(currentDashboardFilter);
          break;
        default: // saved filters
          queries.push(savedFiltersById[filterId].query);
      }
    }

    let resultingQuery = queries.length === 1 ? queries[0] : createGroup(COMBINATOR.OR, queries);
    if (responsesState && responsesState.searchString.length > 0) {
      const rule = createContainsValuesRule(dataSourceID, responsesState.searchString);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    }

    if (widgetConfig && widgetConfig.selectedConcept !== null) {
      // add filtration by labels
      const rule = createInValuesRule(dataSourceID, [widgetConfig.selectedConcept.title]);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    } else if (selectedItems.length > 0) {
      const rule = createInValuesRule(dataSourceID, [...selectedItems]);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    }

    if (selectedCustomerIds && selectedCustomerIds.length > 0) {
      const rule = createInCustomersValuesRule(dataSourceID, [...selectedCustomerIds]);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    }

    if (currentWidgetFilter) {
      resultingQuery = narrowQueryWithRule(resultingQuery, currentWidgetFilter);
    }
    return resultingQuery;
  }, [
    selectedFilterIds,
    currentDashboardFilter,
    currentWidgetFilter,
    dataSourceID,
    selectedItems,
    responsesState?.searchString,
    savedFiltersById,
    widgetConfig?.selectedConcept,
    selectedCustomerIds,
  ]);

  const insightsFilterQuery = useMemo(() => {
    const result: InsightsFilter[] = [];

    const queries = [];
    for (const filterId of selectedFilterIds) {
      switch (filterId) {
        case FILTER_ID_ALL:
          queries.push({
            query: EMPTY_FILTER_QUERY,
            segmentName: 'All',
          });
          break;
        case FILTER_ID_CURRENT:
          queries.push({
            query: currentDashboardFilter,
            segmentName: 'Current',
          });
          break;
        default: // saved filters
          queries.push({
            query: savedFiltersById[filterId].query,
            segmentName: savedFiltersById[filterId].name,
          });
      }
    }
    for (const { query, segmentName } of queries) {
      let resultingQuery = query;
      if (responsesState && responsesState.searchString.length > 0) {
        const rule = createContainsValuesRule(dataSourceID, responsesState.searchString);
        resultingQuery = narrowQueryWithRule(resultingQuery, rule);
      }

      if (widgetConfig && widgetConfig.selectedConcept !== null) {
        // add filtration by labels
        const rule = createInValuesRule(dataSourceID, [widgetConfig.selectedConcept.title]);
        resultingQuery = narrowQueryWithRule(resultingQuery, rule);
      } else if (selectedItems.length > 0) {
        const rule = createInValuesRule(dataSourceID, [...selectedItems]);
        resultingQuery = narrowQueryWithRule(resultingQuery, rule);
      }

      if (currentWidgetFilter) {
        resultingQuery = narrowQueryWithRule(resultingQuery, currentWidgetFilter);
      }
      result.push({
        segmentName,
        query: resultingQuery,
      });
    }

    return result;
  }, [
    selectedFilterIds,
    currentDashboardFilter,
    currentWidgetFilter,
    dataSourceID,
    selectedItems,
    responsesState?.searchString,
    savedFiltersById,
    widgetConfig?.selectedConcept,
  ]);

  const appliedSegmentsNames = useMemo(() => {
    return selectedFilterIds.map((filterID) => filters.find((filter) => filterID === filter.id)?.name || '') ?? [];
  }, [selectedFilterIds, filters]);

  // LOAD RESPONSES WITH FILTER
  useEffect(() => {
    loadResponsesDebounce({
      widgetID,
      filterQuery: responsesFilterQuery,
      offset: 0,
    });
  }, [responsesFilterQuery]);

  // RELOAD RESPONSES
  useEffect(() => {
    if (!shouldResponsesBeReloaded) {
      return;
    }

    loadResponsesDebounce({
      widgetID,
      filterQuery: responsesFilterQuery,
      offset: 0,
    });
    dispatch(dashboardWidgetReloadResponsesSuccess({ widgetID }));
  }, [shouldResponsesBeReloaded]);

  // LOAD INSIGHTS WITH FILTER
  useEffect(() => {
    if (!areInitialInsightsBeingGenerated && !isEqual(prevInsightsQuery, insightsFilterQuery)) {
      setPrevInsightsQuery(insightsFilterQuery);
      generateInsightsDebounce({
        widgetID,
        dashboardID: config.base.dashboardID,
        dataSourceID: dataSource.dataSourceID,
        insightsQuery: insightsFilterQuery,
        responsesQuery: responsesFilterQuery,
        selectedConcept: widgetConfig?.selectedConcept ?? undefined,
        availableFilters: filters.map((f) => f.name),
        isTryAgain: false,
        segmentsNames: appliedSegmentsNames,
      });
    }
  }, [insightsFilterQuery]);

  // HANDLE OPERATIONS
  const handleLabelClick = useCallback(
    (title: string | null) => {
      if (title) {
        const item = dataSource?.items?.find((item) => item.response === title);
        if (item) {
          onLabelSelectionTrack(title, !!item?.children, item[weightedMetricsUsed ? 'weighted' : 'base'].value);
        }
      }
      dispatch(
        selectConcept({
          title,
          widgetID,
        }),
      );
    },
    [dataSource, onLabelSelectionTrack, weightedMetricsUsed],
  );

  const handleFilterButtonChange = useCallback(
    (ids: SetStateAction<string[]>) => {
      (setSelectedFilterIds as React.Dispatch<React.SetStateAction<string[]>>)(ids);
    },
    [setSelectedFilterIds],
  );

  const handleGenerateInsightsClick = (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => {
    if (selectedConcept?.title && !isTryAgain) {
      dispatch(
        selectConcept({
          title: selectedConcept.title,
          widgetID,
        }),
      );
    } else {
      setAreInitialInsightsBeingGenerated(false);
      generateInsightsDebounce({
        dashboardID: config.base.dashboardID,
        widgetID,
        dataSourceID: dataSource.dataSourceID,
        selectedConcept,
        isTryAgain,
        insightsQuery: insightsFilterQuery,
        availableFilters: filters.map((f) => f.name),
        responsesQuery: responsesFilterQuery,
        segmentsNames: appliedSegmentsNames,
        insightID,
      });
    }
  };

  function handleResponsesLoad() {
    if (responsesState.isLoading) {
      return;
    }

    loadResponsesDebounce({
      widgetID,
      filterQuery: responsesFilterQuery,
      offset: responsesState?.offset ?? 0,
    });
  }

  function handleTopicsSortingChange(sorting: SortingQuery) {
    setTopicsSorting(sorting);
  }

  if (!widgetConfig?.dataSourceID) {
    return null;
  }

  // TODO: do we need this?
  // (refCsvExport as MutableRefObject<Function>).current = () => {
  //   const titleField = translationUsed ? 'title' : 'originalTitle';
  //   const title = dataSource[titleField];
  //   const timestampString = getTimestampString();
  //   const filename = `${title} - ${timestampString}`;
  //   const labelToParent = dataSource?.items?.filter(item => item.children)
  //     .reduce((labelToParent, item) => {
  //       const relations = item.children?.reduce((acc, child) => ({
  //         ...acc,
  //         [child]: item.displayResponse,
  //       }), {});
  //       return { ...labelToParent, ...relations };
  //     }, {} as {
  //       [key: string]: string
  //     });
  //   if (isBreakdownView) {
  //     const breakdownDataSource = dataSources.find(dataSource => dataSource.dataSourceID === breakdownFilter?.dataSourceID);
  //     const breakdownItems = breakdownFilter?.values.map(value => breakdownDataSource?.items.find(item => item.response === value));
  //     let breakdownData = dashboardDataSources?.find(dashboardDataSource => dashboardDataSource.dataSourceID === breakdownFilter?.dataSourceID);
  //     let breakdownTitle = '';
  //     if (breakdownData) {
  //       breakdownTitle = breakdownData[titleField];
  //     }
  //     const filename = `${title} (breakdown by ${breakdownTitle}) - ${timestampString}`;
  //     exportOpenEndedBreakdown(breakdownData, filters, selectedFilterIds, breakdownItems, dataSource, translationUsed, labelToParent, filename);
  //   } else {
  //     exportOpenEndedPlain(overviewDataById, dataSource, filters, selectedFilterIds, filtersById, filename, labelToParent);
  //   }
  // };

  return (
    <InterviewWidgetData
      analyzedAnswers={currentDashboardDataSourceInfo.analyzed}
      areFiltersEmpty={currentDashboardDataSourceInfo.isEmpty}
      average={average}
      config={config}
      dataSource={dataSource}
      dataSourceID={dataSourceID}
      widgetID={widgetID}
      filters={filters}
      filtersById={filtersById}
      hasResponses={hasResponses}
      hasSentiment={hasSentiment}
      isPreview={isPreview}
      isRegenerating={regenerationState?.status === DataSourceRegeneratingStatus.PendingRegenerating}
      labels={labels}
      loading={loading}
      responsesCount={responsesState?.responsesTotal}
      responsesLoading={responsesState?.isLoading}
      selectedFilterIds={selectedFilterIds}
      selectedItems={selectedItems}
      selectedLabelItem={widgetConfig?.selectedConcept}
      sentimentSummary={currentDashboardDataSourceInfo.sentimentSummary}
      showResponses={showResponses}
      totalAnswers={currentDashboardDataSourceInfo.total}
      totalsByDataSourcesAndFiltersIds={totalsByDataSourcesAndFiltersIds}
      totalsLoading={totalsLoading}
      totalWeightedAnswers={dataSource.weightedTotal}
      duration={dataSource.duration ?? 0}
      topicsSorting={topicsSorting}
      onExpand={onExpand}
      onFeedbackClick={onFeedbackClick}
      onFilterButtonChange={handleFilterButtonChange}
      onLabelClick={handleLabelClick}
      onLoadMoreResponses={handleResponsesLoad}
      onRefContent={refContent}
      onWidgetFiltersClearClick={onResetAllFilters}
      onGenerateInsightsClick={handleGenerateInsightsClick}
      onTopicsSortingChange={handleTopicsSortingChange}
      onCustomersFilterChange={setSelectedCustomerIds}
    />
  );
}

export default InterviewWidgetDataContainer;
