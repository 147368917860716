import React, { SyntheticEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross-icon.svg';

const Content = styled.div`
  display: flex;
  width: 710px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 26px;
  flex-shrink: 0;
`;

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  color: var(--dark-gray);
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const CrossIconStyled = styled(CrossIcon)`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const OpinionText = styled.textarea`
  resize: none;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--medium-gray);
  padding: var(--border-radius) var(--double-border-radius);
`;

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const PlainButton = styled.button`
  background: none;
  height: 32px;
  padding: 0 16px;
  cursor: pointer;

  border: none;
  color: var(--black);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;

const PrimaryButton = styled.button`
  border-radius: var(--double-border-radius);
  background: var(--beehive-purple);
  height: 32px;
  padding: 0 16px;
  cursor: pointer;

  border: none;
  color: var(--white);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;

interface IGenerationFeedback {
  isOpen: boolean;
  placeholder: string;
  feedback: string;
  onCancel: () => void;
  onSubmit: () => void;
  onOpinionTextChange: (feedback: string) => void;
}
export default function GenerationFeedback({
  feedback,
  isOpen,
  placeholder,
  onCancel,
  onSubmit,
  onOpinionTextChange,
}: IGenerationFeedback) {
  function handleOpinionTextChange(event: SyntheticEvent<HTMLTextAreaElement>) {
    onOpinionTextChange((event.target as HTMLTextAreaElement).value);
  }

  return (
    <Dialog maxWidth={false} open={isOpen} onClose={onCancel}>
      <Content>
        <TitleBlock>
          <Title>Provide Additional Feedback</Title>
          <CrossIconStyled onClick={onCancel} />
        </TitleBlock>
        <OpinionText value={feedback} onChange={handleOpinionTextChange} rows={5} placeholder={placeholder} />
        <ActionBlock>
          <PlainButton onClick={onCancel}>Cancel</PlainButton>
          <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
        </ActionBlock>
      </Content>
    </Dialog>
  );
}
