import React from 'react';
import styled from 'styled-components';
import palette from '../../tools/palette';
import { DescriptionElement, ELEMENT_TYPE } from './SegmentDescriptionContainer';

const Text = styled('span')`
  font-size: 12px;
`;

const Label = styled(Text)`
  color: ${palette.primaryColorLight};
`;

interface ISegmentDescription {
  segments: DescriptionElement[];
  dataSourceTitleByID: { [p: string]: string };
}
function SegmentDescription({ segments, dataSourceTitleByID }: ISegmentDescription) {
  return (
    <div data-testid='segment-description'>
      <Text>Of your customers who </Text>
      {segments.map((segment, index) => {
        if (segment.type === ELEMENT_TYPE.TEXT) {
          return (
            <Text key={index} data-testid={`description-element-${index}`}>
              {segment.text}
            </Text>
          );
        } else {
          return (
            <Label
              key={index}
              data-testid={`description-element-${index}`}
              data-tooltip-id='base-tooltip'
              data-tooltip-content={dataSourceTitleByID[segment.dataSourceID as string]}
            >
              {segment.label}
            </Label>
          );
        }
      })}
    </div>
  );
}

export default SegmentDescription;
