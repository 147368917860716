export enum SEVERITY {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface NotificationType {
  id: string;
  severity: SEVERITY;
  title: string;
  duration?: number;
  persistent?: boolean;
  subtitle?: string;
}
