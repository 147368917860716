import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import SidebarItemWithOverlay from './SidebarItemWithOverlay';
import OverlayPaper from './OverlayPaper';
import SidebarItemAddToDashboardOverlay from './SidebarItemAddToDashboardOverlay/SidebarItemAddToDashboardOverlay';
import { eventsTracker, EVENTS } from '../../../services/EventTrackerService';

const SidebarItemAddToDashboard = ({ index }) => {
  const handleOpen = () => {
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_VIEW);
  };

  return (
    <SidebarItemWithOverlay icon={<AddOutlinedIcon />} index={index} tooltip='Add to Dashboard' onOpen={handleOpen}>
      <OverlayPaper elevation={4}>
        <SidebarItemAddToDashboardOverlay />
      </OverlayPaper>
    </SidebarItemWithOverlay>
  );
};

export default SidebarItemAddToDashboard;
