import debounce from 'lodash/debounce';

class HighlightService {
  ids = [];

  highlight() {
    this.ids.forEach((id, index, list) => {
      const element = window.document.getElementById(id);
      if (element) {
        element.classList.add('glowHighlight');
        if (index === list.length - 1) {
          element.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      }
    });

    this.ids = [];
  }

  debouncedHighlight = debounce(this.highlight, 500);

  add(id) {
    this.ids.push(id);
    this.debouncedHighlight();
  }
}

export const highlightService = new HighlightService();
