import SvgIcon from '@material-ui/core/SvgIcon';

import SidebarItem from './SidebarItem';

const ControlPanelIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M17.25 3a2.25 2.25 0 1 0 0 4.499 2.25 2.25 0 0 0 0-4.499Zm-3.675 1.5a3.75 3.75 0 0 1 7.35 0H24V6h-3.075a3.75 3.75 0 0 1-7.35 0H0V4.5h13.575Zm-6.825 6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM3.075 12a3.75 3.75 0 0 1 7.35 0H24v1.5H10.425a3.75 3.75 0 0 1-7.35 0H0V12h3.075Zm14.175 6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.675 1.5a3.75 3.75 0 0 1 7.35 0H24V21h-3.075a3.75 3.75 0 0 1-7.35 0H0v-1.5h13.575Z' />
  </SvgIcon>
);

const SidebarItemControlPanel = ({ onClick }) => {
  return <SidebarItem icon={<ControlPanelIcon />} tooltip='Dashboard Status' onClick={onClick} />;
};

export default SidebarItemControlPanel;
