import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

import { createWidgetsAndGroups, DEFAULT_GROUP_TITLE } from '../../../navigation-panel/CreateWidgetsDialog/lib';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { WIDGET_TYPE } from '../../../../constants';
import { dashboardSetWidgets, setWidgetGroups } from '../../../../store/actions/dashboard';
import CreateWidgetsDialogRow from './CreateWidgetsDialogRow';
import { hideBlocker, showBlocker } from '../../../../store/actions/actionsCreators';
import palette from '../../../tools/palette';
import { OutlinedButton } from '../../../UI/Button';

const DEFAULT_SETTINGS = {
  dataSourceId: '',
  type: '',
  groupTitle: '',
  meta: { items: [] },
};

const DialogRow = styled(Box)`
  margin-bottom: 10px;
`;

const AddMultipleWidgets = ({ onClose }) => {
  const dispatch = useDispatch();
  const [adding, setAdding] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [settings, setSettings] = useState([DEFAULT_SETTINGS]);
  const { translationUsed } = useSelector((state) => state.app);
  const mounted = useRef(true);
  const widgets = useSelector((state) => state.dashboard.widgets);
  const widgetGroups = useSelector((state) => state.dashboard.widgetGroups);
  const { id: dashboardId, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);

  const defaultGroupList = [
    DEFAULT_GROUP_TITLE,
    ...widgetGroups
      .filter((widgetGroup) => !(widgetGroup.isDefault || widgetGroup.isOverview))
      .sort((a, b) => a.order - b.order)
      .map((group) => group[translationUsed ? 'title' : 'originalTitle']),
  ];
  const [groupList, setGroupList] = useState(defaultGroupList);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const updateGroupList = (newSettings) => {
    const newGroupList = [...defaultGroupList];
    for (let item of newSettings) {
      if (typeof item.groupTitle === 'string' && item.groupTitle.length > 0 && !newGroupList.includes(item.groupTitle)) {
        newGroupList.push(item.groupTitle);
      }
    }
    setGroupList(newGroupList);
  };

  const updateDisabledState = (newSettings) => {
    let state = false;
    for (let item of newSettings) {
      if (!item.dataSourceId || !item.type || typeof item.groupTitle !== 'string' || item.groupTitle.length === 0) {
        state = true;
        break;
      }
    }
    setDisabled(state);
  };

  const handleChange = (index, data) => {
    const newSettings = settings.map((item, i) => (i === index ? data : item));
    updateGroupList(newSettings);
    updateDisabledState(newSettings);
    setSettings(newSettings);
  };

  const handleAdd = () => {
    const newSettings = [...settings, DEFAULT_SETTINGS];
    updateDisabledState(newSettings);
    setSettings(newSettings);
  };

  const handleDelete = (index) => {
    const newSettings = [...settings];
    newSettings.splice(index, 1);
    updateGroupList(newSettings);
    updateDisabledState(newSettings);
    setSettings(newSettings);
  };

  const handleSave = async () => {
    eventsTracker.track(EVENTS.ADD_MULTIPLE_WIDGETS_SAVE, {
      'Widget Count': settings.length,
      'Widget Types': settings.map((config) => (config.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.type) : 'Open-ended')),
      'Group Names': settings.map((config) => config.groupTitle.trim()),
    });
    setAdding(true);
    dispatch(showBlocker());
    const { widgetConfigs, widgetGroupConfigs } = await createWidgetsAndGroups(
      settings,
      dashboardId,
      dashboardDataSources,
      widgetGroups,
      translationUsed,
    );

    // update widget
    const updatedWidgets = widgets.concat(widgetConfigs);
    dispatch(dashboardSetWidgets(updatedWidgets));

    // update widget groups
    const updatedWidgetGroups = widgetGroups.map((widgetGroup) => {
      const updatedWidgetGroup = widgetGroupConfigs.find((widgetGroupConfig) => widgetGroupConfig.id === widgetGroup.id);
      return updatedWidgetGroup ? updatedWidgetGroup : widgetGroup;
    });
    updatedWidgetGroups.push(
      ...widgetGroupConfigs.filter((widgetGroupConfig) => !widgetGroups.some((widgetGroup) => widgetGroup.id === widgetGroupConfig.id)),
    );
    setAdding(false);
    dispatch(setWidgetGroups({ widgetGroups: updatedWidgetGroups }));
    dispatch(hideBlocker());
    onClose();
  };

  const addButtonIcon = adding ? <CircularProgress size={16} style={{ color: palette.primaryColorLight }} /> : null;

  return (
    <Grid container direction='column' style={{ height: '100%' }}>
      <Grid item style={{ flex: 1 }} xs={11}>
        {settings.map((row, index) => {
          return (
            <DialogRow key={index} data-testid='create-widgets-row' data-row={index}>
              <CreateWidgetsDialogRow
                data={row}
                groupList={groupList}
                onChange={(data) => handleChange(index, data)}
                onDelete={() => handleDelete(index)}
                enableDelete={settings.length > 1}
              />
            </DialogRow>
          );
        })}
        <OutlinedButton startIcon={<AddIcon />} onClick={handleAdd} style={{ textTransform: 'none' }} data-testid='add-button'>
          Add More
        </OutlinedButton>
      </Grid>
      <Grid item style={{ marginTop: '10px', textAlign: 'right' }}>
        <OutlinedButton data-testid='save-button' disabled={disabled} startIcon={addButtonIcon} onClick={handleSave}>
          {adding ? 'Adding' : 'Add'}
        </OutlinedButton>
      </Grid>
    </Grid>
  );
};

export default AddMultipleWidgets;
