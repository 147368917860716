import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { NotificationsContext } from '../Notification/NotificationsContext';
import { SEVERITY } from '../Notification/types';
import { NOTIFICATIONS } from '../Notification/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Integration, SurveySourceEnum } from '../../types/connections';
import { fetchConnectors, fetchSurveys, resetConnectors } from '../../store/thunks/connectors';
import { openConnectionsModal } from '../../store/actions/connectors';

import { SOURCES } from './constants';
import { TAB_TO_LABEL } from './utils';
import { TabEnum } from './types';
import ConnectorsTab from './ConnectorsTab';

function ConnectorsTabContainer() {
  const { showNewMessages } = useIntercom();
  const dispatch = useAppDispatch();
  const { connectorsLoadingError: isConnectorsLoadingError, surveysLoadingError: isSurveysLoadingError } = useAppSelector(
    (state) => state.connectors,
  );
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const [selectedTab, setSelectedTab] = useState(TabEnum.INTEGRATIONS);
  const [shouldOpenFileUpload, setShouldOpenFileUpload] = useState(false);

  useEffect(() => {
    dispatch(resetConnectors());
    dispatch(fetchConnectors());
    dispatch(fetchSurveys());
    return () => {
      dispatch(resetConnectors());
    };
  }, []);

  function handleAddConnection(source: SurveySourceEnum | null) {
    if (!source) {
      return;
    }

    eventsTracker.track(EVENTS.CONNECTIONS_ADD_CONNECTION_SELECT, {
      'App/Integration name': source,
    });

    if (source === SurveySourceEnum.CSV) {
      setShouldOpenFileUpload(true);
      return;
    }
    if (source === SurveySourceEnum.SURVEYMONKEY || source === SurveySourceEnum.TYPEFORM) {
      const message = SOURCES[source]?.intercomMessage ?? '';
      showNewMessages(message);
      return;
    }
    dispatch(openConnectionsModal({ source }));
  }

  function handleEnrich(source: SurveySourceEnum, title: string, existingEnrichment?: string | null) {
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_ENRICH, {
      'Survey title': title,
      'Existing enrichment name': existingEnrichment,
      'Data source name': source,
    });
    showNewMessages(SOURCES[source]?.intercomEnrichmentMessage(title, existingEnrichment));
  }

  function handleFileAdd(isFileAdded = false) {
    setShouldOpenFileUpload(false);
    if (isFileAdded) {
      dispatch(fetchSurveys());
    }
  }

  function handleEditAuthentication(integration: Integration) {
    dispatch(openConnectionsModal({ source: integration.source, isAuthEdit: true }));
  }

  useEffect(() => {
    addNotification({
      id: NOTIFICATIONS.ConnectorsUploadMoreData,
      title: 'Upload more data',
      subtitle: 'To connect and/or upload more data, click the “+Add” button, or reach out to a Beehive AI Representative!',
      severity: SEVERITY.INFO,
      persistent: true,
    });
    return () => {
      removeNotification(NOTIFICATIONS.ConnectorsUploadMoreData);
    };
  }, []);

  useEffect(() => {
    if (!isConnectorsLoadingError) {
      removeNotification(NOTIFICATIONS.ConnectorsLoadingError);
      return;
    }
    addNotification({
      id: NOTIFICATIONS.ConnectorsLoadingError,
      title: 'An error occurred while loading your connections.',
      severity: SEVERITY.ERROR,
      persistent: true,
    });
    return () => {
      removeNotification(NOTIFICATIONS.ConnectorsLoadingError);
    };
  }, [isConnectorsLoadingError]);

  useEffect(() => {
    if (!isSurveysLoadingError) {
      removeNotification(NOTIFICATIONS.ConnectorsSurveysLoadingError);
      return;
    }
    addNotification({
      id: NOTIFICATIONS.ConnectorsSurveysLoadingError,
      title: 'An error occurred while loading your csv surveys.',
      severity: SEVERITY.ERROR,
      persistent: true,
    });
    return () => {
      removeNotification(NOTIFICATIONS.ConnectorsSurveysLoadingError);
    };
  }, [isSurveysLoadingError]);

  function handleTabChange(_: any, value: React.SetStateAction<TabEnum>) {
    eventsTracker.track(EVENTS.CONNECTIONS_APP_TAB_SELECT, {
      Origin: TAB_TO_LABEL[selectedTab],
      Destination: TAB_TO_LABEL[value as TabEnum],
    });
    setSelectedTab(value);
  }

  return (
    <ConnectorsTab
      shouldOpenFileUpload={shouldOpenFileUpload}
      handleAddConnection={handleAddConnection}
      handleEditAuthentication={handleEditAuthentication}
      handleEnrich={handleEnrich}
      handleFileAdd={handleFileAdd}
      handleTabChange={handleTabChange}
      selectedTab={selectedTab}
    />
  );
}

export default ConnectorsTabContainer;
