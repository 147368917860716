import { useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/cross-transparent-icon.svg';
import { ReactComponent as CheckboxUncheckedIcon } from '../../../../assets/icons/checkbox-unchecked-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../../assets/icons/checkbox-checked-icon.svg';

import { CompactMenuItem, CustomSelect } from '../style';

import { ParticipantsFilterOptions } from './types';

const CustomSelectStyled = styled(CustomSelect)`
  &.MuiInputBase-root {
    padding: 8px;
  }
`;

const ChipsContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const Chip = styled.div<{ $alone?: boolean }>`
  ${(props) =>
    props.$alone === true
      ? `  
  max-width: 100%;
  & span {
    max-width: 100%;
  }`
      : `max-width: calc(100% - 40px);`}
  background-color: var(--grey-bar);
  color: var(--text-primary);
  border-radius: var(--double-border-radius);
  font-size: 14px;
  font-weight: 500;
  padding: 6px;
  display: flex;
  gap: 4px;
  align-items: center;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MoreChip = styled(Chip)`
  background-color: var(--purple-notification);
  border-radius: 12px;
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
`;

const CloseIconStyled = styled(CloseIcon)`
  min-width: 14px;
  min-height: 14px;
  fill: var(--grey-bar);
  background-color: var(--gray-disabled);
  padding: 2px;
  border-radius: 50%;
`;

const CompactMenuItemStyled = styled(CompactMenuItem)`
  display: flex;
  gap: 12px;
  align-items: center;
  &.MuiMenuItem-root {
    padding: 6px;
    font-size: 14px;
  }
`;

const CompactMenuItemAll = styled(CompactMenuItemStyled)`
  display: flex;
  gap: 12px;
  align-items: center;
  &.MuiMenuItem-root {
    padding: 6px 12px;
    font-size: 14px;
    color: var(--dark-gray);
  }
`;

const ValueMenuItemAll = styled.div`
  padding: 6px;
  font-size: 14px;
  color: var(--dark-gray);
`;

interface IParticipantsSelect {
  participants: ParticipantsFilterOptions[];
  onChange: (ids: string[]) => void;
}

const ALL_PARTICIPANTS_NAME = 'All participants (Default)';
const ALL_PARTICIPANTS_ID = 'all';

function ParticipantsSelect({ participants, onChange }: IParticipantsSelect) {
  const [value, setValue] = useState<string[]>([ALL_PARTICIPANTS_ID]);

  const options = [{ id: ALL_PARTICIPANTS_ID, name: ALL_PARTICIPANTS_NAME }, ...participants];

  const handleChipClick = (id: string) => {
    const newids = value.filter((x) => x !== id);
    // Default always should be if no participants selected
    if (newids.length === 0) {
      newids.push(ALL_PARTICIPANTS_ID);
    }
    setValue(newids);

    // send empty instead ALL_PARTICIPANTS_ID
    onChange(newids.filter((x) => x != ALL_PARTICIPANTS_ID));
  };

  const handleChangeClick = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    let tValue = (e.target.value as string[]) ?? [];

    // If ALL_PARTICIPANTS_ID clicked reset state to default
    if (tValue.length > 1 && tValue[tValue.length - 1] === ALL_PARTICIPANTS_ID) {
      setValue([ALL_PARTICIPANTS_ID]);
      onChange([]);
      return;
    }

    // Default always should be if no participants selected
    if (tValue.length === 0) {
      tValue.push(ALL_PARTICIPANTS_ID);
    }
    // Remove ALL if other participants selected
    if (tValue.includes(ALL_PARTICIPANTS_ID) && tValue.length > 1) {
      tValue = tValue.filter((x) => x !== ALL_PARTICIPANTS_ID);
    }
    setValue(tValue);
    // send empty instead ALL_PARTICIPANTS_ID
    onChange(tValue.filter((x) => x != ALL_PARTICIPANTS_ID));
  };

  return (
    <CustomSelectStyled
      onChange={handleChangeClick}
      value={value}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      variant='outlined'
      multiple
      renderValue={(selected) => {
        const tSelected = (selected as string[]) ?? [];
        const option = options.find((x) => x.id === tSelected[0]);
        if (!option) return null;

        if (option.id === ALL_PARTICIPANTS_ID) {
          return (
            <ChipsContainer>
              <ValueMenuItemAll>{ALL_PARTICIPANTS_NAME}</ValueMenuItemAll>
            </ChipsContainer>
          );
        }

        return (
          <ChipsContainer>
            <ChipWithRemove id={option.id} name={option.name} alone={tSelected.length === 1} onChipClick={handleChipClick} />
            {tSelected.length > 1 && (
              <>
                <span>...</span>
                <MoreChip>+{tSelected.length - 1}</MoreChip>
              </>
            )}
          </ChipsContainer>
        );
      }}
      fullWidth
    >
      <CompactMenuItemAll value={ALL_PARTICIPANTS_ID}>{ALL_PARTICIPANTS_NAME}</CompactMenuItemAll>
      {participants.map((x) => {
        return (
          <CompactMenuItemStyled key={x.id} value={x.id}>
            {value.includes(x.id) ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
            {x.name}
          </CompactMenuItemStyled>
        );
      })}
    </CustomSelectStyled>
  );
}

interface IChipWithRemove extends ParticipantsFilterOptions {
  alone?: boolean;
  onChipClick?: (id: string) => void;
}

function ChipWithRemove({ id, name, alone = false, onChipClick }: IChipWithRemove) {
  function handleOnMouseDown(e: React.MouseEvent) {
    e.stopPropagation();
  }

  return (
    <Chip $alone={alone} data-tooltip-id='base-tooltip' data-tooltip-content={name}>
      <span>{name}</span>
      <CloseIconStyled onMouseDown={handleOnMouseDown} onClick={onChipClick?.bind(null, id)} />
    </Chip>
  );
}

export default ParticipantsSelect;
