import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { startRegeneration } from '../../../../store/thunks/dashboard';
import { DataSourceRegeneratingStatus } from '../../../../store/reducers/dashboard/types';

import RefreshAI from './RefreshAI';

const pendingRefreshStatuses = [DataSourceRegeneratingStatus.PendingRefresh, DataSourceRegeneratingStatus.PendingRefreshAfterInit];

function RefreshAIContainer() {
  const { regeneratingStatesByDataSourceID } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const dsIds: string[] = [];
  for (const [ds, state] of Object.entries(regeneratingStatesByDataSourceID)) {
    if (state.status === DataSourceRegeneratingStatus.PendingRefreshAfterInit) {
      return null;
    }

    if (state.status != null && pendingRefreshStatuses.includes(state.status)) {
      dsIds.push(ds);
    }
  }

  if (dsIds.length === 0) {
    return null;
  }

  function handleRefreshClick() {
    dispatch(startRegeneration({ dataSourceIDs: dsIds }));
  }

  return <RefreshAI onRefreshClick={handleRefreshClick} />;
}

export default RefreshAIContainer;
