import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@material-ui/lab';
import { Button, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { v4 } from 'uuid';

import { createOverview, createWidget, timeout } from './lib';
import { dashboardSetWidgets, setWidgetGroups } from '../../store/actions/dashboard';
import { DASHBOARD_TEMPLATE_ID, DEFAULT_WIDGET_HEIGHT } from '../../constants';
import * as api from '../tools/api';

const DashboardGenerationDialog = ({ open, onClose, templateId }) => {
  const dispatch = useDispatch();
  const {
    widgetGroups,
    dashboard: { id: dashboardId, groups, dataSources: dashboardDataSources },
  } = useSelector((state) => state.dashboard);
  const [activeStep, setActiveStep] = useState(0);
  const [widgetsCount, setWidgetsCount] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [createdWidgets, setCreatedWidgets] = useState([]);
  const [createdWidgetGroups, setCreatedWidgetGroups] = useState([]);
  const dashboardVisibleDataSources = dashboardDataSources.filter((dashboardDataSource) => dashboardDataSource.visible);
  const totalWidgets = dashboardVisibleDataSources.length;
  const totalGroups = groups.length + 1;
  const prevOpen = useRef(false);

  const groupIdToIds = new Map();
  const ungroupedIds = [];

  const generate = async (templateId) => {
    const widgetsConfigs = [];
    const widgetGroupsConfigs = [];

    switch (templateId) {
      case DASHBOARD_TEMPLATE_ID.BASIC:
        // create overview
        const [overviewWidget, overviewWidgetGroup] = await createOverview(dashboardId, dashboardVisibleDataSources);
        setActiveStep((step) => step + 1);
        widgetsConfigs.push(overviewWidget);
        widgetGroupsConfigs.push(overviewWidgetGroup);

        // create widgets
        const sortedDataSources = [...dashboardVisibleDataSources].sort((a, b) => a - b);
        for (let dataSource of sortedDataSources) {
          const widgetConfig = await createWidget(dashboardId, dataSource);
          if (dataSource.groupID) {
            if (groupIdToIds.has(dataSource.groupID)) {
              groupIdToIds.set(dataSource.groupID, [...groupIdToIds.get(dataSource.groupID), widgetConfig.base.id]);
            } else {
              groupIdToIds.set(dataSource.groupID, [widgetConfig.base.id]);
            }
          } else {
            ungroupedIds.push(widgetConfig.base.id);
          }
          widgetsConfigs.push(widgetConfig);
          setWidgetsCount((count) => count + 1);
        }
        setActiveStep((step) => step + 1);

        // create groups
        const sortedGroups = [...groups].sort((a, b) => a.order - b.order);
        for (let group of sortedGroups) {
          const wids = groupIdToIds.has(group.id) ? groupIdToIds.get(group.id) : [];
          const layout = wids.map((wid, index) => ({
            widgetID: wid,
            id: v4(),
            x: index % 2,
            y: Math.ceil(index / 2) * 24,
            width: 1,
            height: DEFAULT_WIDGET_HEIGHT,
          }));
          const groupConfig = {
            layout,
            title: group.title,
            originalTitle: group.originalTitle,
            order: group.order,
            collapsed: true,
          };
          groupConfig.id = await api.createWidgetGroup(dashboardId, groupConfig);
          widgetGroupsConfigs.push(groupConfig);
          setGroupsCount((count) => count + 1);
        }
        // create default group
        const layout = ungroupedIds.map((wid, index) => ({
          widgetID: wid,
          id: v4(),
          x: index % 2,
          y: Math.ceil(index / 2) * 24,
          width: 1,
          height: DEFAULT_WIDGET_HEIGHT,
        }));
        if (widgetGroups.length === 0) {
          const groupConfig = {
            layout,
            title: '',
            originalTitle: '',
            order: groups.length + 1,
            collapsed: true,
          };
          groupConfig.id = await api.createWidgetGroup(dashboardId, groupConfig);
          widgetGroupsConfigs.push(groupConfig);
        } else {
          const groupConfig = {
            ...widgetGroups[0],
            layout,
          };
          await api.updateWidgetGroup(dashboardId, groupConfig);
          widgetGroupsConfigs.push(groupConfig);
        }
        setGroupsCount((count) => count + 1);
        setActiveStep((step) => step + 1);
        break;
      default:
        console.error('Unknown dashboard template ID');
    }
    // update all
    await timeout(1000); // it's just a delay for the better look
    setActiveStep((step) => step + 1);
    setCreatedWidgets(widgetsConfigs);
    setCreatedWidgetGroups(widgetGroupsConfigs);
  };

  // start dashboard generation when displaying the modal
  if (prevOpen.current !== open) {
    if (open) {
      generate(templateId);
    }
    prevOpen.current = open;
  }

  const handleCloseClick = () => {
    dispatch(dashboardSetWidgets(createdWidgets));
    dispatch(setWidgetGroups({ widgetGroups: createdWidgetGroups }));
    onClose();
  };

  return (
    <Dialog maxWidth='sm' fullWidth={true} open={open}>
      <DialogTitle>Dashboard Initialization</DialogTitle>
      <DialogContent dividers>
        <Alert severity='warning'>Please do not close the window until the initialization is complete.</Alert>
        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step key={0}>
            <StepLabel>Create Overview</StepLabel>
            <StepContent>Overview is creating...</StepContent>
          </Step>
          <Step key={1}>
            <StepLabel>Create widgets</StepLabel>
            <StepContent>
              Widgets created: {widgetsCount}/{totalWidgets}
            </StepContent>
          </Step>
          <Step key={2}>
            <StepLabel>Create widget groups</StepLabel>
            <StepContent>
              Groups created: {groupsCount}/{totalGroups}
            </StepContent>
          </Step>
          <Step key={4} expanded={activeStep > 3}>
            <StepLabel>Finish</StepLabel>
            <StepContent>Congratulations! Dashboard has been initialized.</StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} disabled={activeStep < 4} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DashboardGenerationDialog;
