import { useContext, useRef } from 'react';
import { AutoSizer, CellMeasurer, InfiniteLoader, InfiniteLoaderProps, List } from 'react-virtualized';

import { ResponsesCellsStateContext } from './ResponsesCellsStateContext';
import { IRowRenderer } from './types';

type InfiniteLoaderInheritedKeys = 'isRowLoaded' | 'loadMoreRows' | 'threshold' | 'rowCount';
interface IResponsesInfiniteLoader<TRowProps extends IRowRenderer> extends Pick<InfiniteLoaderProps, InfiniteLoaderInheritedKeys> {
  pageSize: number;
  getRowProps: (index: number, renderProps: IRowRenderer) => TRowProps;
  rowRenderer: (props: TRowProps) => React.ReactNode | JSX.Element;
  onScroll?: (params: { scrollTop: number }) => void;
}

function ResponsesInfiniteLoader<TRowProps extends IRowRenderer>({
  pageSize,
  rowCount = 0,
  threshold = 10,
  rowRenderer: RowRenderer,
  isRowLoaded,
  loadMoreRows,
  getRowProps,
  onScroll,
}: IResponsesInfiniteLoader<TRowProps>) {
  const { cellCache, previousWidthRef, clearCache } = useContext(ResponsesCellsStateContext);
  const infLoaderRef = useRef<InfiniteLoader | null>(null);
  const listRef = useRef<List | null>(null);

  function handleWidthChange(width: number) {
    clearCache();
    previousWidthRef.current = width;
  }

  return (
    <InfiniteLoader
      ref={infLoaderRef}
      minimumBatchSize={pageSize}
      threshold={threshold}
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ height, width }) => {
            if (previousWidthRef.current !== width) {
              handleWidthChange(width);
            }
            return (
              <List
                overscanRowCount={3}
                ref={(ref) => {
                  listRef.current = ref;
                  registerChild(ref);
                }}
                height={height}
                width={width}
                deferredMeasurementCache={cellCache}
                rowCount={rowCount}
                rowHeight={cellCache.rowHeight}
                rowRenderer={({ index, key, style, parent, isVisible }) => (
                  <CellMeasurer cache={cellCache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                    {({ measure, registerChild }) => {
                      // That way to support hooks in RowRenderer
                      const props = getRowProps(index, {
                        style,
                        isVisible,
                        measurerKey: key,
                        measure,
                        registerChild,
                      });
                      return <RowRenderer {...props} />;
                    }}
                  </CellMeasurer>
                )}
                onRowsRendered={onRowsRendered}
                onScroll={onScroll}
              />
            );
          }}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}

export default ResponsesInfiniteLoader;
