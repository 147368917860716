import React from 'react';
import styled from 'styled-components';

import { ReactComponent as NotificationBell } from '../../../../assets/icons/notification-icon.svg';

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
`;

const Container = styled.div`
  position: absolute;
  right: 10px;
  top: 60px;
  width: 630px;
  height: 90px;
  display: flex;
  gap: 12px;
  padding: 16px;
  border-radius: var(--double-border-radius);
  background: var(--purple-notification);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2.5;
  gap: 8px;

  font-family: Rubik;
  font-size: 14px;
  line-height: 120%;
  color: var(--dark-purple);
`;

const NotificationBellStyled = styled(NotificationBell)`
  width: 20px;
  height: 20px;
  fill: var(--beehive-purple);
`;

const Header = styled.div`
  font-weight: bold;
  font-family: Rubik;
`;

const Body = styled.div`
  font-family: Rubik;
`;

const ButtonContainer = styled.div`
  font-family: Rubik;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Button = styled.button`
  display: flex;
  height: 32px;
  padding: 0px 16px;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--white);
  border-radius: var(--double-border-radius);
  background: var(--beehive-purple);
  font-family: Rubik;
  outline: none;
  border: none;
  cursor: pointer;
`;

interface IRefreshAI {
  onRefreshClick: () => void;
}
function RefreshAI({ onRefreshClick }: IRefreshAI) {
  return (
    <OuterContainer>
      <Container>
        <div>
          <NotificationBellStyled />
        </div>
        <ContentContainer>
          <Header>Refresh AI Analysis</Header>
          <Body>You have made an edit. Now, refresh the AI analysis to keep the model current.</Body>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={onRefreshClick}>Refresh AI Analysis</Button>
        </ButtonContainer>
      </Container>
    </OuterContainer>
  );
}

export default RefreshAI;
