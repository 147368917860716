import { useSelector } from 'react-redux';
import BalanceIcon from './BalanceIcon';

const WeightIndicator = ({ size, disableTooltip }) => {
  const { weightedMetricsUsed } = useSelector((state) => state.app);
  const hasWeights = useSelector((state) => state.dashboard.dashboard.hasWeights);

  if (!hasWeights || !weightedMetricsUsed) {
    return null;
  }

  return (
    <BalanceIcon
      data-tooltip-id='base-tooltip'
      data-tooltip-content={disableTooltip ? null : 'Weighted metrics applied'}
      style={size ? { fontSize: size, height: size, widgth: size } : {}}
    />
  );
};

export default WeightIndicator;
