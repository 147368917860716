import { WIDGET_HEIGHT, WIDGET_TYPE } from '../../constants';

export const getHeight = (collapsed, expanded, widgetType) => {
  if (collapsed) {
    return WIDGET_HEIGHT.COLLAPSED;
  }
  switch (widgetType) {
    case WIDGET_TYPE.OPEN_ENDED:
      return expanded ? WIDGET_HEIGHT.EXPANDED : WIDGET_HEIGHT.DEFAULT_OPEN_ENDED;
    case WIDGET_TYPE.INTERVIEW:
      return expanded ? WIDGET_HEIGHT.EXPANDED : WIDGET_HEIGHT.DEFAULT_INTERVIEW;
    default:
      return expanded ? WIDGET_HEIGHT.EXPANDED : WIDGET_HEIGHT.DEFAULT;
  }
};

export const getWidth = (maximized) => {
  return maximized ? 2 : 1;
};
