import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import {
  DeltaPlotWrapper,
  SegmentAnswerBar,
  SegmentAnswerBarContainer,
  SegmentAnswerBarBackgroundGrid,
  SegmentWrapper,
  SegmentItem,
  SegmentHeader,
} from './style';
import DeltaPlot from './DeltaPlot';
import { useSavedFilters } from '../../../contexts/SavedFiltersContext';
import { filterSetQuery } from '../../../store/actions/dashboardFilter';
import { ShareButtonStyle } from '../../../components/dashboard/style';
import { AnswerContainer } from '../../../components/Widgets/OpenEndedWidget/Data/style';
import { TextOverflowEllipsis, TotalNumberContainer } from '../../../components/UI';
import { PERCENT_DECIMAL_NUM } from '../../../constants';
import { segmentColors } from '../lib/colors';
import { eventsTracker, EVENTS } from '../../../services/EventTrackerService';

export const getColor = (index) => {
  let _index = index % segmentColors.length;
  const color = segmentColors[_index];
  return color ? color.hsl().string() : null;
};

const Segment = ({ segment, routeProps, responsesURL }) => {
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);
  const { resetSelectedSavedFilters } = useSavedFilters();
  const { total: peopleNumber } = useSelector((state) => state.dashboard.dashboard);
  const dispatch = useDispatch();
  const title = `${segment.title} - ${segment.total}`;

  const applySegmentFilter = (segment) => {
    eventsTracker.track(EVENTS.SEGMENTS_RESULTS, {
      'Segment Name': title,
      'Weighted Metrics': weightedMetricsUsed,
    });
    resetSelectedSavedFilters();
    dispatch(filterSetQuery(segment.makeQuery()));
    routeProps.history.push(responsesURL);
  };

  return (
    <SegmentWrapper>
      <SegmentItem>
        <Grid container direction='row' justifyContent='space-between' alignItems='baseline'>
          <Grid item>
            <SegmentHeader>
              {title} ({parseInt((segment.total / peopleNumber) * 100)}%)
            </SegmentHeader>
          </Grid>
          <Grid item>
            <ShareButtonStyle onClick={applySegmentFilter.bind(null, segment)} data-testid='segment-to-filter'>
              Results
            </ShareButtonStyle>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
          <Grid item xs={4} style={{ paddingRight: '16px', borderRight: '1px solid #ccc' }}>
            <Grid container direction='column' style={{ paddingTop: '16px' }}>
              {segment.properties.map(
                /** @type SegmentItemModel */ (sProperty) => {
                  return (
                    <Grid key={sProperty.displayTitle} item xs={12}>
                      <div
                        style={{
                          paddingBottom: '8px',
                          fontSize: '12px',
                          fontWeight: 600,
                          color: '#505d6f',
                        }}
                      >
                        {sProperty.displayTitle}
                      </div>
                      {sProperty.values.map((/** @type SegmentItemValueModel */ value, index) => {
                        return (
                          <AnswerContainer item key={value.displayName} style={{ paddingBottom: 0 }}>
                            <Grid container direction='row' alignItems='center'>
                              <Grid item xs={4} style={{ paddingBottom: '16px' }}>
                                <Grid
                                  container
                                  wrap='nowrap'
                                  style={{
                                    fontSize: '12px',
                                    color: '#505d6f',
                                  }}
                                >
                                  <Grid item zeroMinWidth>
                                    <TextOverflowEllipsis data-tooltip-id='base-tooltip' data-tooltip-content={value.displayName}>
                                      {value.displayName}
                                    </TextOverflowEllipsis>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      marginLeft: '5px',
                                      marginRight: '5px',
                                    }}
                                  >
                                    ({value.percent.toFixed(PERCENT_DECIMAL_NUM)}
                                    %)
                                  </Grid>
                                </Grid>
                              </Grid>
                              <SegmentAnswerBarBackgroundGrid item xs={8}>
                                <Grid container direction='column'>
                                  <div style={{ position: 'relative' }}>
                                    <TotalNumberContainer>{value.size}</TotalNumberContainer>
                                    <SegmentAnswerBarContainer>
                                      <SegmentAnswerBar style={{ width: `${value.percent}%` }} color={getColor(index)}>
                                        &nbsp;
                                      </SegmentAnswerBar>
                                    </SegmentAnswerBarContainer>
                                  </div>
                                </Grid>
                              </SegmentAnswerBarBackgroundGrid>
                            </Grid>
                          </AnswerContainer>
                        );
                      })}
                      <Grid
                        container
                        justifyContent='flex-end'
                        style={{
                          fontSize: '10px',
                          color: '#979797',
                          marginBottom: '10px',
                          marginTop: '5px',
                        }}
                      >
                        <Grid item xs={8}>
                          <Grid container justifyContent='space-between'>
                            <Grid item style={{ marginLeft: '-2px' }}>
                              0
                            </Grid>
                            <Grid item style={{ marginRight: '-12px' }}>
                              100%
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Grid>

          {/* display: grid required. https://codepen.io/balazs_sziklai/pen/mOwoLg */}
          <Grid item xs={8} style={{ paddingLeft: '16px' }}>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              {segment.traits.map(
                /** @type SegmentItemModel */ (sProperty) => {
                  return (
                    <DeltaPlotWrapper key={sProperty.displayTitle} item style={{ marginBottom: '16px' }}>
                      <div
                        style={{
                          paddingBottom: '10px',
                          fontSize: '12px',
                          fontWeight: 600,
                          color: '#505d6f',
                        }}
                      >
                        {sProperty.displayTitle}
                      </div>
                      <DeltaPlot traitValues={sProperty.values} />
                    </DeltaPlotWrapper>
                  );
                },
              )}
            </Grid>
          </Grid>
        </Grid>
      </SegmentItem>
    </SegmentWrapper>
  );
};

export default Segment;
