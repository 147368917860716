import {
  DASHBOARDS_INITIALIZE_FAIL,
  DASHBOARDS_INITIALIZE_SUCCESS,
  DASHBOARDS_RESET,
  DASHBOARDS_SET_VIEWS,
  DASHBOARDS_SET_DATE_RANGE,
  DASHBOARDS_SET_DASHBOARDS,
  DASHBOARDS_SET_STATUS,
  DASHBOARDS_SET_ABORT_CONTROLLER,
  setDashboardsStatus,
  dashboardsInitializeSuccess,
  dashboardsInitializeFail,
  dashboardsSetViews,
  dashboardsSetDashboards,
  dashboardsSetDateRange,
  dashboardsReset,
  dashboardsSetAbortController,
} from '../actions/dashboards';
import { DASHBOARD_UPDATE_TITLE, updateDashboardTitle } from '../actions/dashboard';
import { FETCH_STATUS } from '../../constants';
export type IDashboardsReducer = {
  initialized: boolean;
  error?: any;
  dateRange?: any;
  dashboards: Dashboard[];
  views: View[];
  status: string;
  abortController: AbortController;
};
export type Dashboard = {
  id: string;
  title: string;
  createdAt: string;
  logo: string;
  brandName: string;
  lastUpdated: string;
  total: number;
  intervalTotal: number;
  filteredTotal: number;
  hasWeights: boolean;
  hasSentiment: boolean;
  dataSources?: any;
  groups?: any;
  widgetGroups?: any;
  widgets?: any;
  countries: string[];
  underMaintenance: boolean;
  latestUpdatedResponse: string;
  status: string;
  surveys?: any;
};
export type View = {
  id?: any;
  title: string;
  userID?: string;
  dashboardIDs: any[];
};
type dashboardActionTypes = ReturnType<typeof setDashboardsStatus> &
  ReturnType<typeof dashboardsInitializeSuccess> &
  ReturnType<typeof dashboardsInitializeFail> &
  ReturnType<typeof dashboardsSetViews> &
  ReturnType<typeof dashboardsSetDashboards> &
  ReturnType<typeof dashboardsSetDateRange> &
  ReturnType<typeof dashboardsReset> &
  ReturnType<typeof updateDashboardTitle> &
  ReturnType<typeof dashboardsSetAbortController>;

const initialState: IDashboardsReducer = {
  initialized: false,
  error: null,
  dateRange: null,
  dashboards: [],
  views: [],
  status: FETCH_STATUS.IDLE,
  abortController: new AbortController(),
};

const dashboardsReducer = (state = initialState, action: dashboardActionTypes) => {
  switch (action.type) {
    case DASHBOARDS_INITIALIZE_SUCCESS:
      return {
        ...state,
        error: null,
        initialized: true,
        dashboards: action.payload.dashboards,
        views: action.payload.views,
      };
    case DASHBOARDS_INITIALIZE_FAIL:
      return {
        ...initialState,
        error: action.payload.error,
      };
    case DASHBOARDS_RESET: {
      return {
        ...initialState,
      };
    }
    case DASHBOARDS_SET_DASHBOARDS:
      return {
        ...state,
        dashboards: action.payload.dashboards,
      };
    case DASHBOARDS_SET_VIEWS:
      return {
        ...state,
        views: action.payload.views,
      };
    case DASHBOARDS_SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    case DASHBOARD_UPDATE_TITLE:
      const dashboards = state.dashboards.map((dashboard) => {
        if (dashboard.id !== action.payload.id) {
          return dashboard;
        }
        return { ...dashboard, title: action.payload.title };
      });
      return {
        ...state,
        dashboards,
      };
    case DASHBOARDS_SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    case DASHBOARDS_SET_ABORT_CONTROLLER:
      return {
        ...state,
        abortController: action.payload.abortController,
      };
    default:
      return state;
  }
};

export default dashboardsReducer;
