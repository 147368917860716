import styled from 'styled-components';

const OverlayContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OverlayBackdrop = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OverlayBackdropPurple = styled(OverlayBackdrop)`
  background-color: var(--beehive-purple);
  opacity: 0.1;
`;

const OverlayBackdropWhite = styled(OverlayBackdrop)`
  background-color: var(--white);
  opacity: 0.5;
`;

const PapperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Papper = styled.div`
  position: relative;
  z-index: 100;
  background-color: var(--white);
  border-radius: var(--double-border-radius);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--black);
  padding: 32px;
  padding-bottom: 44px;
  height: 260px;
  width: 660px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Header = styled.div`
  font-size: 24px;
  font-weight: 400;
`;

const Line = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

const FixedLine = styled(Line)`
  max-width: 300px;
`;

const Button = styled.button`
  display: flex;
  height: 40px;
  padding: 0px 24px;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--white);
  border-radius: var(--double-border-radius);
  background: var(--beehive-purple);
  font-family: Rubik;
  outline: none;
  border: none;
  cursor: pointer;
`;

interface IRefreshAI {
  isPendingRefresh: boolean;
  onRefreshClick?: () => void;
}

function RefreshAI({ isPendingRefresh, onRefreshClick }: IRefreshAI) {
  return (
    <OverlayContainer>
      <OverlayBackdropPurple />
      <OverlayBackdropWhite />
      <PapperContainer>
        <Papper>{isPendingRefresh ? renderNeedRefresh() : renderRefreshing()}</Papper>
      </PapperContainer>
    </OverlayContainer>
  );

  function renderRefreshing() {
    return (
      <Container>
        <Header>AI model is updating itself</Header>
        <BodyContainer>
          <Line>
            Your AI model is re-training itself based on your edits. This will take a few minutes. The widget will automatically refresh.
          </Line>
          <Line>You can work in other widgets, dashboards, or workspaces while this one refreshes.</Line>
        </BodyContainer>
      </Container>
    );
  }

  function renderNeedRefresh() {
    return (
      <Container>
        <Header>Refresh AI analysis</Header>
        <FixedLine>You have made an edit. Now, refresh the AI analysis to keep the model current.</FixedLine>
        <Button onClick={onRefreshClick}>Refresh AI Analysis</Button>
      </Container>
    );
  }
}

export default RefreshAI;
