import { useSelector } from 'react-redux';

import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import { Overview } from '../../../overview';
import useSummaryBreakdownFetch from '../../../../hooks/fetch/useSummaryBreakdownFetch';
import { FETCH_STATUS } from '../../../../constants';
import { useMemo } from 'react';
import useSummaryPlainProcessing from '../../../../hooks/processing/useSummaryPlainProcessing';

const OverviewWidgetContentBreakdown = ({ config, filters, visibleDataSourcesIds, handleHeightUpdate, query }) => {
  const { weightedMetricsUsed, translationUsed } = useSelector((state) => state.app);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const { id: dashboardId, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
  const skipFetching = config.base.collapsed;

  const dataSourceToFetchIds = useMemo(() => {
    return visibleDataSourcesIds.filter((id) => dashboardDataSources.some((dds) => dds.dataSourceID === id && !dds.isEmpty));
  }, [visibleDataSourcesIds, dashboardDataSources]);

  const { responses, status } = useSummaryBreakdownFetch(dashboardId, filters, breakdownFilter, dataSourceToFetchIds, skipFetching, query);

  // @TODO: remove temporary overviewData workaround after updating Overview component
  const overviewDataRaw = useMemo(() => {
    const baseData = visibleDataSourcesIds.map((dsId) => {
      const responses = dataSources.find((ds) => ds.dataSourceID === dsId);
      return { ...responses, dataSourceID: dsId };
    });
    return responses.map((_) => baseData);
  }, [visibleDataSourcesIds, dataSources, responses]);
  const overviewData = useSummaryPlainProcessing(overviewDataRaw, visibleDataSourcesIds);

  if (status !== FETCH_STATUS.LOADED && !skipFetching) {
    return <LoadingLabel textContent='Loading overview responses...' loading={true} style={{ padding: '2em' }} />;
  }

  const valueType = weightedMetricsUsed ? 'weighted' : 'base';
  const breakdownData = {};

  responses.forEach((response, responseId) => {
    if (filters[responseId]) {
      const filterId = filters[responseId].id;
      response.forEach((bucket) => {
        const bucketName = bucket.value;
        const bucketDataSources = bucket.items;
        bucketDataSources.forEach((dataSource) => {
          if (!breakdownData[filterId]) {
            breakdownData[filterId] = {};
          }
          if (!breakdownData[filterId][bucketName]) {
            breakdownData[filterId][bucketName] = {};
          }
          const dataSourceInfo = dashboardDataSources.find(
            (dashboardDataSource) => dashboardDataSource.dataSourceID === dataSource.dataSourceID,
          );
          const items = dataSource.items
            .map((item) => ({
              ...item,
              ...item[valueType],
              displayResponse: translationUsed ? item.response : item.originalResponse,
            }))
            .sort((a, b) => (dataSourceInfo.ordered ? 1 : a[valueType].value - b[valueType].value));
          breakdownData[filterId][bucketName][dataSource.dataSourceID] = {
            ...dataSource,
            items,
          };
        });
      });
    }
  });

  return (
    <div style={{ position: 'relative' }}>
      <Overview
        overviewData={overviewData}
        filters={filters}
        breakdownData={breakdownData}
        breakdownFilter={breakdownFilter}
        breakdownReady={true}
        config={config}
        onHeightUpdate={handleHeightUpdate}
      />
    </div>
  );
};

export default OverviewWidgetContentBreakdown;
