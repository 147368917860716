import { useContext, useEffect, useState } from 'react';

import { NotificationsContext } from '../../../Notification/NotificationsContext';
import { SEVERITY } from '../../../Notification/types';
import { NOTIFICATIONS } from '../../../Notification/constants';
import { OperationTypeEnum, ProviderAuthentication, SurveySourceEnum } from '../../../../types/connections';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { createConnection } from '../../../../store/thunks/connectors';
import { clearLastAddedConnection, closeConnectionsModal } from '../../../../store/actions/connectors';
import { ScheduleEnum } from '../../types';

import QualtricsConnection from './QualtricsConnection';
import useBrands from '../hooks/useBrands';
import useLanguages from '../hooks/useLanguages';
import useQualtricsSurveys from '../hooks/useQualtricsSurveys';

interface IQualtricsConnectionContainer {
  authentication: ProviderAuthentication;
  onUpdateError: (err: string) => void;
}

function QualtricsConnectionContainer({ authentication, onUpdateError }: IQualtricsConnectionContainer) {
  const dispatch = useAppDispatch();
  const { addNotification } = useContext(NotificationsContext);
  const { lastAddedConnection, submittingConnection: isSubmittingConnection } = useAppSelector((state) => state.connectors).addConnection;
  const { brands, selectedBrand, setSelectedBrand } = useBrands(onUpdateError);
  const { languages, selectedLanguage, setSelectedLanguage } = useLanguages(onUpdateError);
  const { surveys, selectedSurvey, setSelectedSurvey } = useQualtricsSurveys(onUpdateError, authentication);

  const [scheduleStartDate, setScheduleStartDate] = useState('');
  const [updateInterval, setUpdateInterval] = useState(ScheduleEnum.ONE_TIME);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setIsSubmitDisabled(!scheduleStartDate || !selectedSurvey);
  }, [scheduleStartDate, selectedSurvey]);

  useEffect(() => {
    if (lastAddedConnection?.source !== SurveySourceEnum.QUALTRICS) {
      return;
    }
    dispatch(clearLastAddedConnection());
    if (lastAddedConnection.success) {
      addNotification({
        id: NOTIFICATIONS.ConnectorsImportSuccess,
        title: `Import connection successfully created.`,
        severity: SEVERITY.SUCCESS,
      });
      dispatch(closeConnectionsModal());
      return;
    }

    onUpdateError('Failed to create import connection.');
  }, [addNotification, dispatch, lastAddedConnection, onUpdateError]);

  function handleSelectBrand(brand: string) {
    setSelectedBrand(brand);
  }

  function handleSelectLanguage(language: string) {
    setSelectedLanguage(language);
  }

  function handleSelectSurvey(survey: string) {
    setSelectedSurvey(survey);
  }

  function handleChangeUpdateInterval(interval: ScheduleEnum) {
    setUpdateInterval(interval);
  }

  function handleConnectionSubmit() {
    if (!selectedSurvey) {
      return;
    }

    const startDate = new Date(scheduleStartDate);
    startDate.setHours(24);
    const startTime = startDate.getTime() / 1000;

    const survey = surveys.find((x) => x.id === selectedSurvey);
    if (!survey) {
      return;
    }

    dispatch(
      createConnection({
        operation: OperationTypeEnum.IMPORT,
        source: SurveySourceEnum.QUALTRICS,
        startDate,
        config: {
          surveyName: survey.name,
          surveyID: survey.id,
          brandID: selectedBrand,
          startTime,
          updateInterval,
          language: selectedLanguage,
          authID: authentication.id,
        },
      }),
    );
  }

  return (
    <QualtricsConnection
      operationType={OperationTypeEnum.IMPORT}
      brands={brands}
      languages={languages}
      surveys={surveys}
      selectedBrand={selectedBrand}
      selectedLanguage={selectedLanguage}
      selectedSurvey={selectedSurvey}
      scheduleStartDate={scheduleStartDate}
      updateInterval={updateInterval}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmittingConnection ?? false}
      onSelectBrand={handleSelectBrand}
      onChangeUpdateInterval={handleChangeUpdateInterval}
      onSelectLanguage={handleSelectLanguage}
      onSelectSurvey={handleSelectSurvey}
      onChangeScheduleStartDate={setScheduleStartDate}
      onSubmitConnection={handleConnectionSubmit}
    />
  );
}

export default QualtricsConnectionContainer;
