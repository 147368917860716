import styled, { keyframes } from 'styled-components';
import { ReactComponent as ProgressActivityIcon } from '../../../../../../assets/icons/progress-activity-icon.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/icons/error-icon.svg';
import { ReactComponent as BeehiveLogo } from '../../../../../../assets/icons/beehive-icon.svg';
import { ReactComponent as NotificationIcon } from '../../../../../../assets/icons/notification-icon.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const AIInsightContainer = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  border-radius: var(--double-border-radius);
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  gap: 25px;
`;

export const HeaderContainerLeftPart = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const ProgressIndicator = styled(ProgressActivityIcon)`
  height: 18px;
  width: 18px;
  fill: var(--black);
  animation-name: ${spin};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

export const StyledBeehiveLogo = styled(BeehiveLogo)`
  margin-top: 3px;
  width: 12px;
  height: 12px;
  min-width: 12px;
`;

export const FailureIcon = styled(ErrorIcon)`
  margin-top: 3px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  fill: var(--sentiment-orange);
`;

export const NotificationIconStyled = styled(NotificationIcon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  fill: var(--beehive-purple);
`;

export const NotEnoughTextContainer = styled(AIInsightContainer)`
  flex-direction: row;
  gap: 16px;
  background: var(--beehive-purple-transparent-10);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
