import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import Notification from './Notification';
import { NotificationType } from './types';

export type NotificationsContextType = {
  addNotification: (n: NotificationType) => void;
  removeNotification: (id: string) => void;
};

export const NotificationsContext = createContext<NotificationsContextType>({
  addNotification: () => {},
  removeNotification: () => {},
});

export const NotificationsProvider = ({ children }: React.PropsWithChildren<object>) => {
  const notificationsRef = useRef<NotificationType[]>([]);
  const [lastNotification, setLastNotification] = useState<NotificationType | null>(null);

  const updateLastNotification = useCallback(() => {
    setLastNotification(notificationsRef.current[notificationsRef.current.length - 1] || null);
  }, []);

  const removeNotification = useCallback(
    (id: string) => {
      notificationsRef.current = notificationsRef.current.filter((n) => n.id !== id);
      updateLastNotification();
    },
    [updateLastNotification],
  );

  const addNotification = useCallback(
    (n: NotificationType) => {
      removeNotification(n.id);
      notificationsRef.current.push(n);
      updateLastNotification();
      if (!n.persistent) {
        setTimeout(() => {
          removeNotification(n.id);
        }, n.duration || 5000);
      }
    },
    [removeNotification, updateLastNotification],
  );

  const value = useMemo(() => {
    return {
      addNotification,
      removeNotification,
    };
  }, [addNotification, removeNotification]);

  return (
    <NotificationsContext.Provider value={value}>
      {lastNotification && (
        <Notification
          title={lastNotification.title}
          subtitle={lastNotification.subtitle}
          severity={lastNotification.severity}
          onClose={() => removeNotification(lastNotification.id)}
        />
      )}
      {children}
    </NotificationsContext.Provider>
  );
};
