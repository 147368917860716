import React from 'react';

import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { FilledButton } from '../../UI/Button';
import { CloseButtonContainer, CloseIcon, Container, DialogTitle, FullWidth, TextField } from './styles';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-icon.svg';

import UploadDropdownSelectContainer from './UploadDropdownSelectContainer';
import UploadDropdownElements from './UploadDropdownElements';

const AddIcon = styled(CrossIcon)`
  rotate: 45deg;
  stroke-width: 0;
  width: 24px;
  height: 24px;
`;

const StyledFilledButton = styled(FilledButton)`
  width: 20%;
  align-self: flex-end;
  margin-top: 10px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 38px;
  right: 28px;
`;

const SearchEmptyIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 15px;
  &:hover {
    background-color: initial;
  }
`;

const TextFieldContainer = styled(FullWidth)`
  position: relative;
`;

const DashboardButton = styled.button`
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  border: 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  &:hover {
    background-color: var(--light-gray);
  }
  > * {
    margin-right: 12px;
  }
`;

const DropdownContainer = styled.div`
  margin: 12px;
`;

const FieldSubtitle = styled.div`
  color: var(--grey);
  font-size: 20px;
  margin-bottom: 20px;
`;

const FieldHeader = styled.div`
  margin-bottom: 16px;
`;

interface IFileUploadNewDashboard {
  dashboardName: string;
  selectedHives: string[];
  filteredHives: string[];
  isOpen: boolean;
  isDropdownOpen: boolean;
  handleDashboardCreation: () => void;
  handleCheckboxChange: (hive: string) => void;
  handleHiveSearchChange: (hive: string) => void;
  handleSetOpen: (isOpen: boolean) => void;
  handleDashboardNameReset: () => void;
  HandleUpdatingDashboardName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSetDropdownOpen: () => void;
  onSetDropdownClose: () => void;
}

function FileUploadNewDashboard({
  dashboardName,
  selectedHives,
  filteredHives,
  isOpen,
  isDropdownOpen,
  handleDashboardCreation,
  handleCheckboxChange,
  handleHiveSearchChange,
  handleSetOpen,
  handleDashboardNameReset,
  HandleUpdatingDashboardName,
  onSetDropdownOpen,
  onSetDropdownClose,
}: IFileUploadNewDashboard) {
  return (
    <div>
      <DashboardButton data-testid='hives-dashboard-open' onClick={handleSetOpen.bind(null, true)}>
        {' '}
        <AddIcon /> Create New Dashboard
      </DashboardButton>
      <Dialog open={isOpen} onClose={handleSetOpen.bind(null, false)} fullWidth={true} maxWidth={'md'}>
        <Container>
          <CloseButtonContainer onClick={handleSetOpen.bind(null, false)}>
            <CloseIcon data-testid='hives-dashboard-close' />
          </CloseButtonContainer>
          <DialogTitle>Create New Dashboard</DialogTitle>
          <FieldSubtitle> Name your Dashboard and place it into a Hive. </FieldSubtitle>
          <FieldHeader>Dashboard Name</FieldHeader>
          {renderNewDashboardTextField()}
          <FullWidth>
            <FieldHeader>Add to Hives (optional)</FieldHeader>
            {renderHivesDropdown()}
          </FullWidth>
          <StyledFilledButton
            disabled={dashboardName.length === 0}
            onClick={handleDashboardCreation}
            data-testid='hives-new-dashboard-submit'
          >
            Next
          </StyledFilledButton>
        </Container>
      </Dialog>
    </div>
  );

  function renderHivesDropdown() {
    return (
      <UploadDropdownSelectContainer
        selectedDashboards={selectedHives}
        open={isDropdownOpen}
        bubbleColor='grey'
        anchorOriginVertical='bottom'
        transformOriginVertical='top'
        onCheckboxChange={handleCheckboxChange}
        onOpen={onSetDropdownOpen}
        onClose={onSetDropdownClose}
        UploadDropdownContent={renderHivesDropdownContent}
      />
    );
  }

  function renderNewDashboardTextField() {
    return (
      <TextFieldContainer>
        <TextField
          data-testid='hives-new-dashboard-name'
          value={dashboardName}
          name='dashboardName'
          onChange={HandleUpdatingDashboardName}
        />
        {dashboardName && <SearchEmptyIcon onClick={handleDashboardNameReset} />}
      </TextFieldContainer>
    );
  }

  function renderHivesDropdownContent() {
    const handleHiveSearchUpdating = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleHiveSearchChange(e.currentTarget.value);
    };
    return (
      <DropdownContainer>
        <TextField
          data-testid='file-new-dashboard-hive-search'
          name='hiveSearch'
          placeholder='Search Hives'
          onChange={handleHiveSearchUpdating}
        />
        <StyledSearchIcon />
        {renderHivesDropdownElements()}
      </DropdownContainer>
    );
  }

  function renderHivesDropdownElements() {
    return (
      <UploadDropdownElements
        elements={filteredHives}
        selectedElements={selectedHives}
        paddingHorizontal='0'
        handleCheckboxChange={handleCheckboxChange}
      />
    );
  }
}

export default FileUploadNewDashboard;
