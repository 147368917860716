import React, { useEffect, useState } from 'react';
import { TimeLine } from '../timeline';
import { TimelineItemModelFactory } from '../../models/Timeline';
import LoadingLabel from '../LoadingLabel/LoadingLabel';
import { useSelector } from 'react-redux';
import useFilters from '../../hooks/useFilters';
import * as api from '../tools/api';
import { PERIOD } from '../../constants';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { getAppliedFiltersCount } from '../UI/lib';

export const MotivationsWrapper = () => {
  const [filters] = useFilters();

  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const { id: dashboardId, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
  const questionsById = dashboardDataSources.reduce((acc, dataSource) => {
    return { ...acc, [dataSource.dataSourceID]: dataSource.title };
  }, {});

  const [mainQuestionId, setMainQuestionId] = useState(dashboardDataSources[0].dataSourceID);

  const [timelineData, setTimelineData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dataList, setDataList] = useState();
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);

  useEffect(() => {
    if (!mainQuestionId) {
      return;
    }

    let mounted = true;

    const fetchData = async () => {
      setLoading(true);

      const select = dataSources.find((dataSource) => dataSource.dataSourceID === mainQuestionId).items.map((item) => item.response);
      const params = filters.map((filter) => ({
        select,
        filter: filter.query,
        dataSourceID: mainQuestionId,
        groupBy: PERIOD.WEEK,
      }));

      const trends = await api.fetchTrends(dashboardId, params);
      if (mounted) {
        setDataList(trends);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [mainQuestionId, filters, dataSources, dashboardId]);

  useEffect(() => {
    if (dataList) {
      const valueType = weightedMetricsUsed ? 'weighted' : 'base';
      const list = dataList.map((response) => {
        return response.map((row) => ({
          value: row.response,
          buckets: row.buckets.map((bucket) => ({
            date: bucket.date,
            value: bucket[valueType].value,
            size: bucket[valueType].percentage,
          })),
        }));
      });
      const timelineDataList = list.map((timelineDataset) => timelineDataset.map(TimelineItemModelFactory));
      setTimelineData(timelineDataList);
    }
  }, [dataList, weightedMetricsUsed]);

  const handlePrimaryQuestionChange = (id) => {
    eventsTracker.track(EVENTS.TRENDS_QUESTION_CHANGE, {
      'Dashboard Filter Count': getAppliedFiltersCount(filters),
    });
    setMainQuestionId(id);
  };

  if (!mainQuestionId) {
    return (
      <div style={{ padding: '15vh' }}>
        <LoadingLabel loading={true} />
      </div>
    );
  }

  return (
    <TimeLine
      timelineData={timelineData || []}
      loading={loading}
      lastUpdated={0}
      isFetching={false}
      questions={questionsById}
      mainQuestionId={mainQuestionId}
      handlePrimaryQuestionChange={handlePrimaryQuestionChange}
    />
  );
};
