import React from 'react';
import styled from 'styled-components';

import screenshot from '../../../../assets/images/azure-sas-screenshot.png';

const Instructions = styled.div`
  width: 800px;
  margin-bottom: 60px;
  ol {
    padding-left: 24px;
  }
  img {
    margin-top: 24px;
  }
`;

function AzureSASInstructions() {
  return (
    <Instructions data-testid='azure-sas-instructions'>
      <div>Generate a Shared Access Signature (SAS) Token</div>
      <ol>
        <li>Go to the Azure Portal (Microsoft Azure).</li>
        <li>Navigate to your Azure Storage Account.</li>
        <li>Create or navigate to a container dedicated to sharing data with Beehive AI. (Must be a folder. Cannot be a specific file.)</li>
        <li>In the left-hand menu, under "Settings," select "Shared access signature" or “Shared access tokens“.</li>
        <li>
          <div>Configure the SAS token settings. You can specify the permissions, start time, end time, and more.</div>
          <ul style={{ listStyleType: 'disc' }}>
            <li>
              <div>Key type: You can choose one of the following options…</div>
              <ul>
                <li>Account key (no upper bound on key expiry, less secure)</li>
                <li>User delegation key (7 day expiry but more secure)</li>
              </ul>
            </li>
            <li>Permissions: read, add, write, create, list</li>
            <li>Start time and Expiry time: Set the duration for which the SAS token will be valid.</li>
            <li>Select HTTPS only if it is not already selected</li>
          </ul>
        </li>
        <li>Click the "Generate SAS and connection string" or “Generate SAS token and URL“ button.</li>
        <li>
          Copy the generated SAS token. <strong>Make sure to keep this token secure, as it provides access to your Azure resources.</strong>
        </li>
      </ol>
      <img src={screenshot} alt='Screenshot of Azure SAS token configuration' width='800px' />
    </Instructions>
  );
}

export default AzureSASInstructions;
