import {
  INTERVIEW_AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET,
  INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_START,
  INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_SUCCESS,
  INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_FAILURE,
  INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_CANCEL,
  INTERVIEW_AI_INSIGHTS_CLEAR_ALL,
  INTERVIEW_AI_INSIGHTS_QUEUE_CONCEPT,
  INTERVIEW_AI_INSIGHTS_SHOW_FEEDBACK_MODAL,
  INTERVIEW_AI_INSIGHTS_HIDE_FEEDBACK_MODAL,
  INTERVIEW_AI_INSIGHTS_FEEDBACK_SUBMIT_START,
  INTERVIEW_AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS,
  INTERVIEW_AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR,
  clearAll,
  feedbackSubmitError,
  feedbackSubmitStart,
  feedbackSubmitSuccess,
  generateRequestCancel,
  generateRequestFailure,
  generateRequestStart,
  generateRequestSuccess,
  hideFeedbackModal,
  initializeInsightsForWidget,
  showFeedbackModal,
} from '../actions/interviewAIInsights';
import { InsightsFilter } from '../thunks/aiInsights';
import { FeedbackModal, GenerationStatus, InsightType } from './aiInsights';

export type InterviewInsight = {
  id: string;
  timestamp: string;
  type: InsightType;
  filter: InsightsFilter[];
  totalResponses?: number;
  segmentsNames?: string[];
  isDisabled?: boolean;
  concept?: string; // TODO
  generatedInsights?: string[]; // TODO
  generationStatuses?: GenerationStatus[];
  numberOfResponsesUsedForGeneration?: string;
  generationModel?: string;
  generationModelLocation?: string;
  generationTime?: string;
  tokensTotal?: string;
  modelParameters?: {
    maxOutputTokens: string;
    temperature: string;
    topK: string;
    topP: string;
  };
};

export interface IInterviewAIInsightsReducer {
  insightsByWidgetID: { [key: string]: InterviewInsight[] };
  feedbackModal?: FeedbackModal;
}

type actionTypes = ReturnType<typeof initializeInsightsForWidget> &
  ReturnType<typeof generateRequestStart> &
  ReturnType<typeof generateRequestFailure> &
  ReturnType<typeof generateRequestSuccess> &
  ReturnType<typeof generateRequestCancel> &
  ReturnType<typeof clearAll> &
  ReturnType<typeof showFeedbackModal> &
  ReturnType<typeof hideFeedbackModal> &
  ReturnType<typeof feedbackSubmitStart> &
  ReturnType<typeof feedbackSubmitError> &
  ReturnType<typeof feedbackSubmitSuccess>;

const initialState: IInterviewAIInsightsReducer = {
  insightsByWidgetID: {},
};

const interviewAIInsightsReducer = (state: IInterviewAIInsightsReducer = initialState, action: actionTypes) => {
  switch (action.type) {
    case INTERVIEW_AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_START:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_SUCCESS:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_FAILURE:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_GENERATE_REQUEST_CANCEL:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_CLEAR_ALL:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_QUEUE_CONCEPT:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_SHOW_FEEDBACK_MODAL:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_HIDE_FEEDBACK_MODAL:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_FEEDBACK_SUBMIT_START:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS:
      // TODO
      return state;
    case INTERVIEW_AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR:
      // TODO
      return state;
    default:
      return state;
  }
};

export default interviewAIInsightsReducer;
