import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { initializeAzureFiles } from '../../../../store/thunks/azure';
import { resetFiles } from '../../../../store/actions/azure';
import { ProviderAuthentication } from '../../../../types/connections';

const useAzureFiles = (onUpdateError: (err: string) => void, authentication: ProviderAuthentication) => {
  const dispatch = useAppDispatch();
  const { files, initialized: isFilesInitialized, error: isFilesError } = useAppSelector((state) => state.azure);
  const [selectedFile, setSelectedFile] = useState('');

  useEffect(() => {
    if (isFilesError) {
      onUpdateError('Failed to load files.');
      return;
    }

    if (!isFilesInitialized) {
      dispatch(initializeAzureFiles({ authID: authentication.id }));
      return;
    }

    if (isFilesInitialized) {
      return () => {
        dispatch(resetFiles());
      };
    }
  }, [isFilesInitialized, isFilesError, dispatch, onUpdateError, authentication.id]);

  return { files, selectedFile, setSelectedFile };
};

export default useAzureFiles;
