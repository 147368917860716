import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Paper } from '@material-ui/core';
import styled from 'styled-components';

import useFilters from '../../../hooks/useFilters';
import { SummaryDataContext } from '../../../contexts/SummaryDataContext';
import { FETCH_STATUS, FILTER_ID_ALL } from '../../../constants';
import LoadingLabel from '../../../components/LoadingLabel/LoadingLabel';
import CompareTable from './Table/CompareTable';
import { ICompareRow, SegmentsMinMax, Thresholds } from '../compare';
import { prepareData, toggleFilter } from '../lib/comp';
import { EVENT_PROPERTY_DASHBOARD_NAME, EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import CompareFilterButtons from './CompareFilterButtons';
import palette from '../../../components/tools/palette';
import { CompareView, SEGMENT_ID_ANY, SortField, SortFieldName } from '../constants';
import { expandAggregatedRows, filterRows, getUniqIds, sortRows, translateRows } from '../lib/compareTable';
import ThresholdsSelector from './ThresholdsSelector';
import DataSourcesSelectorContainer from './DataSourcesSelectorContainer';
import { DashboardDataSource } from '../../../types';
import TableExportContainer from './TableExportContainer';
import {
  setDashboardContext,
  resetThresholds,
  setDataSourceIds,
  setSegmentsMinMax,
  setSelectedRowIds,
  setSelectedSegmentIds,
  setSort,
  setThresholds,
  setView,
} from '../store/actions';
import { ICompareState } from '../store/reducer';
import ChartTableToggle from './ChartTableToggle';
import CompareChartContainer from './Chart/CompareChartContainer';
import { SelectorButton } from './UI';

const ViewSelectedButton = styled(SelectorButton)`
  &.MuiButton-root {
    margin-left: 0;
  }
`;

const EmptyFiltersWrapper = styled(Paper)`
  &.MuiPaper-root {
    margin: 20px;
    padding: 10vh 20px;
    text-align: center;
  }
`;

const FiltersWrapper = styled(Paper)`
  &.MuiPaper-root {
    border: 1px solid rgba(224, 224, 224, 1);
    margin: 0 20px;
    padding-top: 16px;
    padding-left: 16px;
  }
`;
const CompareSegmentsButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    background-color: ${palette.primaryColorLight};
    color: #fff;
    margin-top: 16px;
  }
`;

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

enum CompareSteps {
  COMPARISON = 'comparison',
  AGGREGATION = 'aggregation',
  TRANSLATION = 'translation',
  FILTERING = 'filtering',
  SORTING = 'sorting',
}

const CompareContent: React.FC<{ onSelectSegmentsClick: () => void }> = ({ onSelectSegmentsClick }) => {
  const dashboardTitle = useSelector((state: any) => state.dashboard.dashboard.title);
  const dashboardDataSources: DashboardDataSource[] = useSelector((state: any) => state.dashboard.dashboard.dataSources);
  const [filters, filtersById] = useFilters() as [any[], any];
  const { status, data } = useContext(SummaryDataContext);
  const { weightedMetricsUsed, translationUsed } = useSelector((state: any) => state.app);
  const [viewSelected, setViewSelected] = useState(false);
  const steps = useRef<CompareSteps[]>([]);
  const dispatch = useDispatch();

  const { thresholds, sort, dataSourceIDs, selectedSegmentIDs, segmentsMinMax, selectedRowIDs } = useSelector(
    (state: any) => (state.compare as ICompareState).data,
  );
  const view = useSelector((state: any) => (state.compare as ICompareState).view);

  const visibleDataSources = useMemo(() => dashboardDataSources.filter((dataSource) => dataSource.visible), [dashboardDataSources]);
  const defaultSelectedDataSourceIDs = visibleDataSources.map((dataSource) => dataSource.dataSourceID);
  const showAggregation = false; // will be configurable later

  const field = weightedMetricsUsed ? 'weighted' : 'base';
  const totalField = weightedMetricsUsed ? 'weightedTotal' : 'total';

  const startTime = performance.now();
  steps.current = [];

  useEffect(() => {
    dispatch(setDashboardContext());
  }, [dispatch]);

  useEffect(() => {
    const filterIds: string[] = filters.map((filter) => filter.id).filter((id) => id !== FILTER_ID_ALL);
    dispatch(setSelectedSegmentIds(filterIds.length === 1 ? filterIds.concat(FILTER_ID_ALL) : filterIds));
  }, [filters, dispatch]);

  useEffect(() => {
    const newMin =
      segmentsMinMax.min === SEGMENT_ID_ANY || selectedSegmentIDs.includes(segmentsMinMax.min) ? segmentsMinMax.min : SEGMENT_ID_ANY;
    const newMax =
      segmentsMinMax.max === SEGMENT_ID_ANY || selectedSegmentIDs.includes(segmentsMinMax.max) ? segmentsMinMax.max : SEGMENT_ID_ANY;
    if (newMin !== segmentsMinMax.min || newMax !== segmentsMinMax.max) {
      dispatch(
        setSegmentsMinMax({
          min: newMin,
          max: newMax,
        }),
      );
    }
  }, [selectedSegmentIDs, segmentsMinMax, dispatch]);

  const baseData = useMemo(() => {
    if (status !== FETCH_STATUS.LOADED) {
      return [];
    }
    return data;
  }, [status, data]);

  const count = useMemo(() => {
    if (baseData.length === 0) {
      return 0;
    }
    return baseData[0].reduce((acc: number, dataSource: any) => acc + dataSource.items.length, 0);
  }, [baseData]);

  const { compareRows, maxDataSourceTotal, maxAbsoluteDifference } = useMemo<{
    compareRows: ICompareRow[];
    maxDataSourceTotal: number;
    maxAbsoluteDifference: number;
  }>(() => {
    if (baseData.length === 0 || baseData.length !== filters.length) {
      return {
        compareRows: [],
        maxDataSourceTotal: 0,
        maxAbsoluteDifference: 0,
      };
    }
    steps.current.push(CompareSteps.COMPARISON);
    const { rows: compareRows, maxDataSourceTotal, maxAbsoluteDifference } = prepareData(baseData, filters, field, totalField);
    return { compareRows, maxDataSourceTotal, maxAbsoluteDifference };
  }, [baseData, filters, field, totalField, steps]);

  const preparedRows = useMemo(() => {
    if (showAggregation) {
      return compareRows;
    }
    steps.current.push(CompareSteps.AGGREGATION);
    return expandAggregatedRows(compareRows);
  }, [showAggregation, compareRows, steps]);

  const filteredRows = useMemo(() => {
    steps.current.push(CompareSteps.FILTERING);
    const filteredNewRows = filterRows(preparedRows, selectedSegmentIDs, thresholds, dataSourceIDs, segmentsMinMax);
    return filteredNewRows.map((row, index) => ({ index: index + 1, ...row }));
  }, [preparedRows, selectedSegmentIDs, dataSourceIDs, thresholds, segmentsMinMax, steps]);

  const translatedRows = useMemo(() => {
    steps.current.push(CompareSteps.TRANSLATION);
    return translateRows(filteredRows, dashboardDataSources, translationUsed);
  }, [filteredRows, dashboardDataSources, translationUsed, steps]);

  const rowsToShow = useMemo(() => {
    if (!viewSelected) {
      return translatedRows;
    }
    return translatedRows.filter((row) => selectedRowIDs.includes(`${row.concept}-${row.dataSourceID}`));
  }, [translatedRows, selectedRowIDs, viewSelected]);

  const rows = useMemo(() => {
    steps.current.push(CompareSteps.SORTING);
    return sortRows(rowsToShow, sort.field, sort.direction);
  }, [rowsToShow, sort, steps]);

  useEffect(() => {
    dispatch(setSelectedRowIds(getUniqIds(translatedRows)));
  }, [dispatch, translatedRows]);

  function handleSortClick(field: SortField) {
    let newSortBy: SortField = sort.field;
    let newDirection;
    if (sort.field === field) {
      if (sort.direction === SortOrder.ASC) {
        newSortBy = SortField.DIFFERENCE_PERCENTAGE;
        newDirection = SortOrder.DESC;
      } else {
        newDirection = SortOrder.ASC;
      }
    } else {
      newSortBy = field;
      newDirection = SortOrder.DESC;
    }

    eventsTracker.track(EVENTS.COMPARED_TABLE_SORT, {
      'Sort Column': SortFieldName[newSortBy],
      'Sort Order': newDirection === SortOrder.ASC ? 'Ascending' : 'Descending',
    });

    dispatch(setSort(newSortBy, newDirection));
  }

  function handleFilterClick(filter: any) {
    const newSelectedSegmentIDs = toggleFilter(filters, selectedSegmentIDs, filter);
    if (filter.id === FILTER_ID_ALL) {
      eventsTracker.track(EVENTS.COMPARED_ALL_SELECT);
    } else {
      const selectedFilterNames = newSelectedSegmentIDs.map((id) => {
        return filters.find((filter) => filter.id === id).name;
      });
      eventsTracker.track(EVENTS.COMPARED_SEGMENT_SELECT, {
        'Selected Segment Names': selectedFilterNames,
      });
    }
    dispatch(setSelectedSegmentIds(newSelectedSegmentIDs));
  }

  function handleDataSourceIDsChange(value: string[]) {
    dispatch(setDataSourceIds(value));
  }

  function handleThresholdsChange(value: Thresholds) {
    dispatch(setThresholds(value));
  }

  function handleThresholdsReset() {
    dispatch(resetThresholds());
  }

  function handleSegmentsMinMaxChange(value: SegmentsMinMax) {
    dispatch(setSegmentsMinMax(value));
  }

  function handleSelectedRowIDsChange(value: string[]) {
    dispatch(setSelectedRowIds(value));
  }

  function handleViewChange(value: CompareView) {
    eventsTracker.track(EVENTS.COMPARE_SEGMENTS_CHANGE_PAGE_VIEW, {
      'View Selected': value,
    });
    dispatch(setView(value));
  }

  function handleViewSelectedClick() {
    eventsTracker.track(EVENTS.VIEW_SELECTED_BUTTON_SELECT);
    setViewSelected(!viewSelected);
  }

  if (status !== FETCH_STATUS.LOADED) {
    return <LoadingLabel loading={true} />;
  }

  const finishTime = performance.now();
  if (filters.length > 1 && rows.length > 0 && steps.current.length > 0) {
    eventsTracker.track(EVENTS.COMPARE_PERFORMANCE, {
      [EVENT_PROPERTY_DASHBOARD_NAME]: dashboardTitle,
      Time: finishTime - startTime,
      Count: count,
      Filters: filters.length,
      Steps: steps.current,
    });
  }

  function renderTable() {
    return (
      <Box padding='20px'>
        <Box display='flex' marginBottom='8px'>
          <ViewSelectedButton size='small' selected={viewSelected} onClick={handleViewSelectedClick}>
            View selected
          </ViewSelectedButton>
          <Box flexGrow={1} />
          <DataSourcesSelectorContainer
            visibleDataSources={visibleDataSources}
            defaultSelectedDataSourceIDs={defaultSelectedDataSourceIDs}
            dataSourceIDs={dataSourceIDs}
            onChange={handleDataSourceIDsChange}
          />
          <ThresholdsSelector
            thresholds={thresholds}
            maxAbsoluteDifference={maxAbsoluteDifference}
            maxDataSourceTotal={maxDataSourceTotal}
            onChange={handleThresholdsChange}
            onReset={handleThresholdsReset}
          />
          <TableExportContainer rows={rows} />
        </Box>
        <CompareTable
          rows={rows}
          filtersById={filtersById}
          sortBy={sort.field}
          direction={sort.direction}
          filters={filters}
          selectedFilterIds={selectedSegmentIDs}
          segmentsMinMax={segmentsMinMax}
          selectedRowIDs={selectedRowIDs}
          onSortClick={handleSortClick}
          onSelectedRowIDsChange={handleSelectedRowIDsChange}
          onSegmentsMinMaxChange={handleSegmentsMinMaxChange}
        />
      </Box>
    );
  }

  return (
    <>
      {filters.length < 2 && (
        <EmptyFiltersWrapper>
          Compare segments to see where the biggest differences in your responses reside.
          <br />
          <CompareSegmentsButton variant='contained' onClick={onSelectSegmentsClick}>
            Compare Segment(s)
          </CompareSegmentsButton>
        </EmptyFiltersWrapper>
      )}
      {filters.length > 1 && (
        <>
          <FiltersWrapper elevation={0}>
            <CompareFilterButtons onFilterClick={handleFilterClick} selectedFilterIds={selectedSegmentIDs} filters={filters} />
          </FiltersWrapper>
          {view !== CompareView.TABLE && (
            <Box padding='20px'>
              <CompareChartContainer
                rows={compareRows}
                selectedRowIds={selectedRowIDs}
                dashboardDataSources={dashboardDataSources}
                filtersById={filtersById}
                selectedFilterIds={selectedSegmentIDs}
                filters={filters}
                expanded={view === CompareView.CHART}
              />
            </Box>
          )}

          <ChartTableToggle view={view} onChange={handleViewChange} />

          {view !== CompareView.CHART && renderTable()}
        </>
      )}
    </>
  );
};

export default CompareContent;
