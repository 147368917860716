import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { StyledCloseIcon as CloseIcon } from '../style';

const StyledCloseIcon = styled(CloseIcon)`
  && {
    color: var(--text-primary);
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  margin-right: 64px;

  > div {
    border-radius: 8px;
    width: 400px;
    max-width: 400px;
    overflow: hidden;
    z-index: 10;
    background: white;
  }
`;

const ErrorBox = styled.div`
  background-color: var(--sentiment-red-50-transparency);
  display: flex;
  padding: 8px 16px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  && {
    padding: 3px;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--text-primary);
  margin-left: 8px;
  margin-right: 12px;
  flex: 1;
  justify-content: center;
  height: max-content;
  min-height: 26px;
`;

const StyledIconButton = styled(IconButton)`
  && {
    height: max-content;
  }
`;

interface IErrorNotification {
  error: string;
  onClose: () => void;
}
function ErrorNotification({ error, onClose }: IErrorNotification) {
  if (!error) return null;
  return (
    <ErrorContainer>
      <div>
        <ErrorBox>
          <StyledErrorIcon htmlColor='var(--text-primary)' fontSize='small' />
          <ErrorMessage>
            <div>{error}</div>
          </ErrorMessage>
          <StyledIconButton size='small' onClick={onClose}>
            <StyledCloseIcon />
          </StyledIconButton>
        </ErrorBox>
      </div>
    </ErrorContainer>
  );
}

export default ErrorNotification;
