import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener, Popper } from '@material-ui/core';

import SidebarItem from './SidebarItem';
import { SidebarDashboardContext } from '../SidebarDashboardContext';

const Arrow = styled('span')`
  position: absolute;
  box-sizing: border-box;
  background: #fff;

  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: -9px;
    width: 15px;
    height: 15px;
    transform-origin: 0 0;
    background-color: white;
    transform: rotate(45deg);
    box-shadow:
      0 2px 1px -1px rgba(0, 0, 0, 0.05),
      -2px 2px 1px -1px rgba(0, 0, 0, 0.05);
  }
`;

const SidebarItemWithOverlay = ({ children, icon, tooltip, selected, onOpen = null, onClose = null, index, testId }) => {
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const justClickedAway = useRef(false);
  const { activeItemIndex, setActiveItemIndex } = useContext(SidebarDashboardContext);
  const ref = useRef(null);
  const prevActiveItemIndex = useRef(null);

  if (prevActiveItemIndex.current !== activeItemIndex) {
    setAnchorEl(activeItemIndex === index ? ref.current : null);
    prevActiveItemIndex.current = activeItemIndex;
  }

  const handleClick = () => {
    if (!justClickedAway.current) {
      // prevent reopening after clicking on the button
      setActiveItemIndex(index);
      if (!!onOpen) {
        onOpen();
      }
    }
  };

  const handleClose = () => {
    setActiveItemIndex(null);
    justClickedAway.current = true;
    setTimeout(() => (justClickedAway.current = false), 10);
    if (!!onClose) {
      onClose();
    }
  };

  const open = !!anchorEl;

  return (
    <>
      <Popper
        anchorEl={anchorEl}
        disablePortal={false}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
          arrow: {
            enabled: { open },
            element: arrowRef,
          },
          offset: {
            offset: `-${5 + 68 * index}px, 25px`,
          },
        }}
        open={open}
        placement={'right-start'}
        style={{ zIndex: 1 }}
      >
        <Arrow ref={setArrowRef} />
        <ClickAwayListener onClickAway={handleClose} mouseEvent='onMouseUp'>
          {children}
        </ClickAwayListener>
      </Popper>
      <SidebarItem icon={icon} selected={selected} tooltip={tooltip} onClick={handleClick} ref={ref} testId={testId} />
    </>
  );
};

export default SidebarItemWithOverlay;
