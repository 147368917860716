import { useRef, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const interIcon = <IndeterminateCheckBoxIcon fontSize='small' />;

const OptionContent = styled(Box)`
  margin-right: 16px;
  margin-left: 16px;
`;

const OptionContentSpecial = styled(OptionContent)`
  padding: 13px 10px;
`;

const OptionContentLabel = styled(OptionContent).attrs((props) => ({
  paddingLeft: props.parent ? '25px' : '0',
}))`
  padding-top: 6px;
  padding-bottom: 6px;
`;

const AutocompletePopper = styled(Popper)`
  .MuiAutocomplete-option {
    flex-wrap: wrap;
    padding: 0;
  }
`;

const InvalidChip = styled(Chip)`
  background-color: lightpink;
  color: red;
`;

const renderTags = (tagValue, getTagProps) => {
  return tagValue.map((option, index) => {
    const props = getTagProps({ index });
    const key = props.key;
    delete props.key;
    if (option.deleted) {
      return (
        <InvalidChip
          key={key}
          data-tooltip-id='base-tooltip'
          data-tooltip-content='This concept has been renamed or deleted.'
          label={option.label}
          size='small'
          {...props}
        />
      );
    }
    return (
      <Chip key={key} data-tooltip-id='base-tooltip' data-tooltip-content={option.label} label={option.label} size='small' {...props} />
    );
  });
};

const LabelsAutocompleteControl = ({ options, value, handleOnChange, testID, ...props }) => {
  const enabled = useRef(null);
  const selected = value
    ? value.map((name) => {
        const item = options.find((option) => option.name === name);
        if (item) {
          return item;
        }

        return {
          name,
          label: name,
          key: name,
          deleted: true,
        };
      })
    : [];

  const [expanded, setExpanded] = useState([]);

  const nameToItem = options.reduce((acc, item) => ({ ...acc, [item.name]: item }), {});

  const getOptionSelected = (option, value) => {
    return option.name === value.name;
  };

  const handleChange = (event, options, reason, details) => {
    let selectedOptions;
    switch (reason) {
      case 'select-option':
        const selectedOption = details.option;
        if (selectedOption.parent === null) {
          // remove children of the selected option from the selection
          selectedOptions = options.filter((option) => option.parent === null || selectedOption.name !== option.parent);
        } else {
          const selectedParent = options.find((option) => selectedOption.parent === option.name);
          if (selectedParent) {
            // remove parent and selected option from the list
            selectedOptions = options.filter((option) => option.name !== selectedOption.name && option.name !== selectedParent.name);
            // find other children of the parent and add them to the list
            const children = selectedParent.children.filter((child) => child !== selectedOption.name);
            const childrenOptions = children.map((child) => nameToItem[child]);
            selectedOptions = [...selectedOptions, ...childrenOptions];
          } else {
            selectedOptions = options;
            const parent = nameToItem[selectedOption.parent];
            // all children of the parent are selected
            if (parent.children.every((child) => options.some((option) => option.name === child))) {
              // remove children and add parent
              selectedOptions = selectedOptions.filter((option) => !parent.children.includes(option.name));
              selectedOptions = [...selectedOptions, parent];
            }
          }
        }
        break;
      case 'remove-option':
        selectedOptions = options;
        break;
      default:
        selectedOptions = [];
    }
    handleOnChange(selectedOptions.map((option) => option.name));
  };

  const checkOption = (option) => {
    return selected.some((c) => c.name === option.name || c.name === option.parent);
  };

  const getOptionDisabled = (option) => {
    return enabled.current && !enabled.current.includes(option.name);
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === 'input' && value.length > 0) {
      enabled.current = options.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())).map((item) => item.name);
    } else {
      enabled.current = null;
    }
  };

  const renderOption = (option) => {
    if (option.isSpecial) {
      return (
        <>
          <OptionContentSpecial data-tooltip-id='base-tooltip' data-tooltip-content={option.tooltip}>
            <span>{option.label} </span>
          </OptionContentSpecial>
          {option.hasDivider && <Divider style={{ width: '100%' }} />}
        </>
      );
    }

    const isExpanded = expanded.includes(option.name);
    const click = (event) => {
      if (isExpanded) {
        setExpanded(expanded.filter((i) => i !== option.name));
      } else {
        setExpanded(expanded.concat(option.name));
      }
      event.stopPropagation();
    };
    const checked = checkOption(option);
    const indeterminate = !checked && option.children && option.children.some((child) => selected.some((v) => v.name === child));
    const ArrowIcon = isExpanded ? KeyboardArrowDown : KeyboardArrowRightIcon;
    return (
      <OptionContentLabel parent={option.parent} data-tooltip-id='base-tooltip' data-tooltip-content={option.tooltip}>
        <Checkbox
          key={option.name}
          icon={icon}
          checkedIcon={checkedIcon}
          indeterminateIcon={interIcon}
          indeterminate={indeterminate}
          checked={checked}
        />
        {option.parent === null && option.children?.length > 0 && (
          <IconButton size='small' onClick={click}>
            <ArrowIcon />
          </IconButton>
        )}
        <span>{option.label} </span>({option.percentage.toFixed(0)}%)
      </OptionContentLabel>
    );
  };

  const filterOptions = (options, state) => {
    const value = state.inputValue.toLowerCase();

    if (value.length === 0) {
      return options.filter((option) => {
        return option.parent === null || expanded.includes(option.parent);
      });
    }

    return options.filter((option) => {
      return enabled.current.includes(option.name) || (option.children && option.children.some((child) => enabled.current.includes(child)));
    });
  };

  return (
    <Autocomplete
      multiple
      size='small'
      options={options}
      disableCloseOnSelect
      onChange={handleChange}
      value={selected}
      getOptionLabel={(option) => (option.isSpecial ? option.label : `${option.label} (${option.percentage.toFixed(0)}%)`)}
      renderOption={renderOption}
      filterOptions={filterOptions}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      onInputChange={handleInputChange}
      data-testid={testID}
      PopperComponent={AutocompletePopper}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      {...props}
    />
  );
};

export default LabelsAutocompleteControl;
