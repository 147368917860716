import React from 'react';
import styled from 'styled-components';

import TabTutorialBlock from './TabTutorialBlock';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';

const ExternalLink = styled.a`
  color: inherit;
  font-weight: bold;
  margin-left: 5px;
`;

interface ITabTutorialBlockProps {
  onClose: () => void;
}

function TabTutorialBlockCompare({ onClose }: ITabTutorialBlockProps) {
  function handleLinkClick() {
    eventsTracker.track(EVENTS.WATCH_TUTORIAL_SELECT);
  }

  function handleCloseClick() {
    eventsTracker.track(EVENTS.TUTORIAL_BANNER_CLOSE);
    onClose();
  }

  return (
    <TabTutorialBlock onClose={handleCloseClick}>
      Need help
      <ExternalLink
        href='https://help.beehive.ai/en/articles/8417808-compare-segments'
        target='_blank'
        rel='noopener noreferrer'
        onClick={handleLinkClick}
      >
        Watch tutorial.
      </ExternalLink>
    </TabTutorialBlock>
  );
}

export default TabTutorialBlockCompare;
