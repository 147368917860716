import React, { useContext, useEffect, useState } from 'react';

import { NotificationsContext } from '../../Notification/NotificationsContext';
import { SEVERITY } from '../../Notification/types';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NOTIFICATIONS } from '../../Notification/constants';
import { resetCSVUploadStatus, uploadCSV } from '../../../store/thunks/connectors';
import { CsvUploadErrorEnum } from '../../../types/connections';

import { dashboardsInitialize } from '../../../store/thunks/dashboards';

import FileUploadDialog from './FileUploadDialog';

interface IFileUploadDialogContainer {
  isOpen: boolean;
  onClose: (fileAdded?: boolean) => void;
}

function useDashboards() {
  const dispatch = useAppDispatch();

  // Dispatch the dashboardsInitialize action to fetch the dashboards
  const dashboards = useAppSelector((state) => state.dashboards.dashboards);
  React.useEffect(() => {
    dispatch(dashboardsInitialize());
  }, []);
  // Map to just their titles
  return dashboards.map((dashboard) => dashboard.title);
}

function FileUploadDialogContainer({ isOpen, onClose }: IFileUploadDialogContainer) {
  const dispatch = useAppDispatch();
  const { addCSV } = useAppSelector((state) => state.connectors);
  const { addNotification, removeNotification } = useContext(NotificationsContext);

  const [lastFile, setLastFile] = useState<File | null>(null);
  const [selectedDashboards, onSelectedDashboardsSet] = useState<string[]>([]);
  const [newSelectedDashboards, onNewSelectedDashboardsSet] = useState<string[]>([]);
  const [fileSource, onFileSourceChange] = React.useState('');
  const [isSuccessfulUploadDialogOpen, onIsSuccessfulUploadDialogOpenChange] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const dashboards = useDashboards();

  const isSubmitDisabled = !lastFile || !selectedDashboards.length || !fileSource.trim().length;

  useEffect(() => {
    if (addCSV.uploadingSuccess) {
      removeNotification(NOTIFICATIONS.ConnectorsFileUploadError);
      onIsSuccessfulUploadDialogOpenChange(true);
      handleClose();
      return;
    }
    if (addCSV.uploadingError) {
      switch (addCSV.uploadingError) {
        case CsvUploadErrorEnum.DUBLICATE_NAME:
          addNotification({
            id: NOTIFICATIONS.ConnectorsFileUploadError,
            title: 'A file with this name already exists.',
            severity: SEVERITY.ERROR,
            persistent: true,
          });
          handleClose();
          break;
        case CsvUploadErrorEnum.UNKNOWN_ERROR:
          addNotification({
            id: NOTIFICATIONS.ConnectorsFileUploadError,
            title: 'Something went wrong with the upload. Please try again.',
            severity: SEVERITY.ERROR,
            persistent: true,
          });
          handleClose();
          break;
        default:
          break;
      }
    }
  }, [addCSV]);

  function handleClose() {
    dispatch(resetCSVUploadStatus());
    // Reset all values after successful upload
    onSelectedDashboardsSet([]);
    onNewSelectedDashboardsSet([]);
    onFileSourceChange('');
    onClose(true);
  }

  function filterDashboards() {
    const filteredDashboards = selectedDashboards.filter((selectedDashboard) => dashboards.includes(selectedDashboard));
    return filteredDashboards;
  }

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_ADD_SELECT);
    const buffer = await lastFile.arrayBuffer();
    setFileName(lastFile.name);
    const filteredDashboards = filterDashboards();
    dispatch(
      uploadCSV({
        buffer,
        fileName: lastFile.name,
        dashboards: filteredDashboards.join(', '),
        newDashboards: newSelectedDashboards.join(', '),
        source: fileSource,
      }),
    );
  }

  function handleSelectFile(e: any) {
    eventsTracker.track(EVENTS.FILE_UPLOAD_SELECT_FILE_CLICK);
  }

  useEffect(() => {
    return () => {
      removeNotification(NOTIFICATIONS.ConnectorsFileUploadError);
      dispatch(resetCSVUploadStatus());
    };
  }, []);

  return (
    <FileUploadDialog
      dashboards={dashboards}
      selectedDashboards={selectedDashboards}
      newSelectedDashboards={newSelectedDashboards}
      fileSource={fileSource}
      isOpen={isOpen}
      isSubmitDisabled={isSubmitDisabled}
      isUploading={addCSV.submittingUpload ?? false}
      isSuccessfulUploadDialogOpen={isSuccessfulUploadDialogOpen}
      fileName={fileName}
      handleSelectFile={handleSelectFile}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      setFile={setLastFile}
      onSelectedDashboardsSet={onSelectedDashboardsSet}
      onNewSelectedDashboardsSet={onNewSelectedDashboardsSet}
      onFileSourceChange={onFileSourceChange}
      onIsSuccessfulUploadDialogOpenChange={onIsSuccessfulUploadDialogOpenChange}
    />
  );
}

export default FileUploadDialogContainer;
