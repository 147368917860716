import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import html2canvas from 'html2canvas';
import capitalize from 'lodash/capitalize';

import { WidgetContainer, WidgetTotalBlock } from '../UI';
import WidgetHeader from './WidgetHeader';
import { savePng } from '../tools/export';
import { getHeight, getWidth } from './lib';
import { RGL_MARGIN_VALUE, RGL_ROW_HEIGHT } from '../overview/lib';
import { dashboardResetWidgetError, dashboardUpdateWidget } from '../../store/actions/dashboard';
import { WIDGET_HEIGHT, WIDGET_TYPE, FIXED_SIZE_WIDGETS } from '../../constants';
import WidgetTotal from './WidgetTotal';
import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import * as api from '../tools/api';
import { WidgetTotalsProvider } from './WidgetTotalsContext';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { useHistory } from 'react-router-dom';
import { SAVED_FILTERS_URL_ARG_NAME } from '../SavedFilters/SavedFilters';

const BaseWidget = ({ config, onDimensionsChange, SettingsComponent, MenuComponent, ContentComponent, groupTitle }) => {
  const history = useHistory();
  const { savedFilters, setSelectedSavedFilters } = useSavedFilters();
  const dataSourceId = config.settings.dataSources[0].id;
  const dashboardDataSource = useSelector((state) =>
    state.dashboard.dashboard.dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceId),
  );
  const dispatch = useDispatch();
  const { translationUsed } = useSelector((state) => state.app);
  const title = translationUsed ? config.base.title : config.base.originalTitle;
  const [showSettings, setShowSettings] = useState(false);
  const refContent = useRef();
  const refCsvExport = useRef();
  const refSettings = useRef();
  const refHeader = useRef();
  const refFiltersCount = useRef(0);

  const width = getWidth(config.base.maximized);
  const height = getHeight(config.base.collapsed, config.base.expanded, config.base.type);

  const isMaximizable =
    !FIXED_SIZE_WIDGETS.includes(config.base.type) &&
    !FIXED_SIZE_WIDGETS.map((x) => x.toLowerCase()).includes(dashboardDataSource?.type?.toLowerCase());

  const setQuery = useCallback(
    (name, value) => {
      const params = new URLSearchParams();
      if (name && value) {
        params.append(name, value);
      }
      history.push({ search: params.toString() });
    },
    [history],
  );

  const handleWidgetStateChange = (expanded, collapsed, maximized) => {
    const updatedConfig = {
      ...config,
      base: { ...config.base, expanded, collapsed, maximized },
    };
    dispatch(dashboardUpdateWidget(updatedConfig));
    api.updateWidget(updatedConfig);
  };

  const handleShowSettingsClick = () => {
    onDimensionsChange(width, WIDGET_HEIGHT.DEFAULT);
    setShowSettings(true);
  };

  const handleHideSettingsClick = () => {
    onDimensionsChange(width, height);
    setShowSettings(false);
  };

  const handleCollapseClick = () => {
    eventsTracker.track(EVENTS.WIDGET_TOGGLE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      Visible: !config.base.collapsed,
    });
    handleWidgetStateChange(config.base.expanded, !config.base.collapsed, config.base.maximized);
    onDimensionsChange(width, getHeight(!config.base.collapsed, config.base.expanded, config.base.type), true);
  };

  const handleMaximizeClick = () => {
    eventsTracker.track(EVENTS.WIDGET_MAX_MIN, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      Maximized: !config.base.maximized,
    });
    handleWidgetStateChange(config.base.expanded, config.base.collapsed, !config.base.maximized);
    onDimensionsChange(getWidth(!config.base.maximized), height, true);
  };

  const handleExpand = () => {
    handleWidgetStateChange(!config.base.expanded, config.base.collapsed, config.base.maximized);
    onDimensionsChange(width, getHeight(config.base.collapsed, !config.base.expanded, config.base.type), true);
  };

  const handleUpdateAutoSizeHeight = useCallback(
    (calculatedHeight) => {
      onDimensionsChange(width, calculatedHeight);
    },
    [onDimensionsChange, width],
  );

  const resetWidgetHeight = (type) => {
    const lType = type?.toLowerCase();
    let height = WIDGET_HEIGHT.DEFAULT;
    switch (lType) {
      case WIDGET_TYPE.OPEN_ENDED.toLowerCase():
        height = WIDGET_HEIGHT.DEFAULT_OPEN_ENDED;
        break;
      case WIDGET_TYPE.INTERVIEW.toLowerCase():
        height = WIDGET_HEIGHT.DEFAULT_INTERVIEW;
        break;
      default:
        height = WIDGET_HEIGHT.DEFAULT;
    }
    onDimensionsChange(width, height);
  };

  const handleCsvClick = () => {
    refCsvExport.current();
  };

  const handlePngClick = async () => {
    const canvas = await html2canvas(refContent.current);
    const image = canvas.toDataURL('image/png', 1.0);
    savePng(image, title);
  };

  const handleFilterApply = (filter) => {
    const updatedConfig = { ...config, base: { ...config.base, filter } };
    dispatch(dashboardUpdateWidget(updatedConfig));
    api.updateWidget(updatedConfig);
  };

  const handleResetAllFilters = () => {
    handleFilterApply({ filterID: null, query: null });
    const filterIds = [];
    setQuery(SAVED_FILTERS_URL_ARG_NAME, filterIds.join(','));
    setSelectedSavedFilters(filterIds);
  };

  const refSettingsCurrent = refSettings.current;
  useEffect(() => {
    if (!refSettingsCurrent || !showSettings) {
      return;
    }
    const observer = new ResizeObserver(([entry]) => {
      const customHeight = Math.ceil((entry.target.clientHeight + RGL_MARGIN_VALUE) / (RGL_ROW_HEIGHT + RGL_MARGIN_VALUE));
      onDimensionsChange(width, customHeight);
    });
    observer.observe(refSettingsCurrent);

    return () => {
      observer.disconnect();
    };
  }, [refSettingsCurrent, showSettings, width, onDimensionsChange]);

  const query = useMemo(() => {
    if (config.base.filter?.query || config.base.filter?.filterID) {
      if (config.base.filter.query) {
        if (!config.base.filter.valid) {
          return null;
        }
        return config.base.filter.query;
      } else {
        const savedFilter = savedFilters.find((savedFilter) => savedFilter.id === config.base.filter.filterID);
        return savedFilter && savedFilter.valid ? savedFilter.query : null;
      }
    } else {
      return null;
    }
  }, [config, savedFilters]);

  const unfixable = !config.validation.valid && config.validation.errors.missedDataSourceIds; // user cannot fix incorrect data source

  return (
    <div style={{ height: '100%' }} data-testid='widget' data-widget-title={config.base.title} data-widget-type={config.base.type}>
      <WidgetTotalsProvider
        query={query}
        widgetDataSources={config.settings.dataSources}
        forceFetch={config.base.type === WIDGET_TYPE.TRENDS}
      >
        {showSettings && (
          <SettingsComponent config={config} onClose={handleHideSettingsClick} componentRef={refSettings} groupTitle={groupTitle} />
        )}
        <WidgetContainer
          $withBottomPadding={FIXED_SIZE_WIDGETS.includes(config.base.type)}
          container
          direction='column'
          style={{ height: '100%', display: showSettings ? 'none' : null }}
        >
          <WidgetHeader
            config={config}
            refHeader={refHeader}
            onCollapseClick={handleCollapseClick}
            onMaximizeClick={handleMaximizeClick}
            onCsvClick={handleCsvClick}
            onPngClick={handlePngClick}
            onErrorClick={handleShowSettingsClick}
            onFilterApply={handleFilterApply}
            refFiltersCount={refFiltersCount}
            groupTitle={groupTitle}
            isMaximizable={isMaximizable}
            unfixable={unfixable}
            menuComponent={
              <MenuComponent
                config={config}
                onEdit={handleShowSettingsClick}
                onWidgetTypeChange={resetWidgetHeight}
                groupTitle={groupTitle}
                unfixable={unfixable}
              />
            }
          />
          <Grid
            item
            style={{
              flex: '1',
              height: '0',
              display: config.base.collapsed ? 'none' : 'inherit',
              width: '100%',
            }}
          >
            <Grid container direction='column'>
              <ContentComponent
                config={config}
                showSettings={showSettings}
                updateAutoSizeHeight={handleUpdateAutoSizeHeight}
                groupTitle={groupTitle}
                refCsvExport={refCsvExport}
                refContent={refContent}
                refFiltersCount={refFiltersCount}
                refHeader={refHeader}
                query={query}
                onResetAllFilters={handleResetAllFilters}
                onExpand={handleExpand}
              />
            </Grid>
          </Grid>
        </WidgetContainer>
        {!(showSettings || config.base.collapsed) && (
          <WidgetTotalBlock>
            <WidgetTotal config={config} />
          </WidgetTotalBlock>
        )}
      </WidgetTotalsProvider>
    </div>
  );
};

export default BaseWidget;
