import React from 'react';
import { useSelector } from 'react-redux';

import FileUploadNewDashboard from './FileUploadNewDashboard';

// Fetch the list of hives from the API with this function
import { selectHiveNames } from '../../../pages/Programs/HiveSelector';

function useFilteredHives(hives: string[], hiveSearch: string) {
  const [filteredHives, setFilteredHives] = React.useState(hives);

  React.useEffect(() => {
    setFilteredHives(hives.filter((hive) => hive.toLowerCase().includes(hiveSearch.toLowerCase())));
  }, [hiveSearch, hives]);

  return filteredHives;
}

interface IFileUploadNewDashboardContainer {
  selectedDashboards: string[];
  dashboards: string[];
  newSelectedDashboards: string[];
  handleSelectedDashboardsSet: (dashboards: string[]) => void;
  handleNewSelectedDashboardsSet: (dashboards: string[]) => void;
}

function FileUploadNewDashboardContainer({
  dashboards,
  newSelectedDashboards,
  selectedDashboards,
  handleSelectedDashboardsSet,
  handleNewSelectedDashboardsSet,
}: IFileUploadNewDashboardContainer) {
  const hives: string[] = useSelector(selectHiveNames);
  const [selectedHives, setSelectedHives] = React.useState<string[]>([]);
  const [dashboardName, handleDashboardNameChange] = React.useState('');
  const [hiveSearch, onHiveSearchChange] = React.useState('');
  const filteredHives = useFilteredHives(hives, hiveSearch);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [open, handleSetOpen] = React.useState(false);

  function onSetDropdownOpen() {
    setDropdownOpen(true);
  }

  function onSetDropdownClose() {
    setDropdownOpen(false);
  }

  const handleCheckboxChange = (hive: string) => {
    if (selectedHives.includes(hive)) {
      setSelectedHives(selectedHives.filter((d) => d !== hive));
    } else {
      setSelectedHives([...selectedHives, hive]);
    }
  };

  const handleDashboardNameReset = () => {
    handleDashboardNameChange('');
  };

  const HandleUpdatingDashboardName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDashboardNameChange(e.currentTarget.value);
  };

  const handleUpdatingDashboard = (dashboard: string) => {
    handleSelectedDashboardsSet([dashboard, ...selectedDashboards]);
    handleNewSelectedDashboardsSet([...newSelectedDashboards, `${dashboard}: [${selectedHives.join(', ')}]`]);
    dashboards.push(dashboard);
    setSelectedHives([]);
    handleDashboardNameChange('');
    handleSetOpen(false);
  };

  const isDashboardNameUnique = (name: string) => {
    const splitNewSelectedDashboards = newSelectedDashboards.map((dashboard) => dashboard.split(':')[0]);
    return !dashboards.includes(name) && !splitNewSelectedDashboards.includes(name);
  };

  const handleDashboardCreation = () => {
    if (isDashboardNameUnique(dashboardName)) {
      handleUpdatingDashboard(dashboardName);
    } else {
      alert('Dashboard name already exists. Please choose a different name.');
    }
  };

  return (
    <FileUploadNewDashboard
      dashboardName={dashboardName}
      selectedHives={selectedHives}
      filteredHives={filteredHives}
      isOpen={open}
      isDropdownOpen={dropdownOpen}
      handleDashboardCreation={handleDashboardCreation}
      handleCheckboxChange={handleCheckboxChange}
      handleHiveSearchChange={onHiveSearchChange}
      handleSetOpen={handleSetOpen}
      handleDashboardNameReset={handleDashboardNameReset}
      HandleUpdatingDashboardName={HandleUpdatingDashboardName}
      onSetDropdownOpen={onSetDropdownOpen}
      onSetDropdownClose={onSetDropdownClose}
    />
  );
}

export default FileUploadNewDashboardContainer;
