import { v4 } from 'uuid';

import {
  AuthProvider,
  AuthSourceEnum,
  BaseConnection,
  BaseProviderAuthentication,
  BaseSurvey,
  ConnectionUpdate,
  CsvUploadErrorEnum,
  Integration,
  OperationTypeEnum,
  ProviderAuthentication,
  Survey,
  SurveySourceEnum,
  SurveyStatus,
} from '../../../types/connections';
import {
  closeDeleteAuthModal,
  closeDeleteConnectionModal,
  removeAuth,
  removeConnection,
  updateConnectors,
  updateConnectorsLoading,
  updateConnection as updateConnectionAction,
  setAuth,
  closeConnectionsModal,
  updateLastDeletedAuth,
  updateLastDeletedConnection,
  updateConnectionUpdateResult,
  updateSubmittingDeleteAuth,
  updateSubmittingDeleteConnection,
  updateLastEditedAuth,
  updateLastAddedConnection,
  updateSubmittingAuth,
  updateSubmittingAddConnection,
  updateSubmittingConnectionUpdate,
  updateSurveysLoading,
  updateSubmittingUpload,
  updateUploadResult,
  resetUploadResult,
} from '../../actions/connectors';
import { AppDispatch } from '../../hooks';
import * as api from '../../../components/tools/api';
import store from '../../store';
import { IConnectorsReducer } from '../../reducers/connectors';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { ScheduleEnum, ScheduleToReadable } from '../../../components/ConnectorsTab/types';

import { UnsupportedConnectorsSources, assembleIntegrations, groupSurveysBySource } from './utils';

export const fetchSurveys = () => async (dispatch: AppDispatch) => {
  const startTime = performance.now();
  dispatch(updateSurveysLoading({ loading: true }));
  const [surveysRes, processingCSVNames]: [BaseSurvey[] | undefined, string[] | undefined] = await Promise.all([
    api.fetchSurveys(),
    api.fetchProcessingCSVs(),
  ]);
  if (!surveysRes || !processingCSVNames) {
    dispatch(updateSurveysLoading({ loading: false, error: true }));
    const endTime = performance.now();
    eventsTracker.track(EVENTS.CONNECTIONS_FETCH_CONNECTORS, {
      'Processing Time': endTime - startTime,
      'Is Success': false,
    });
    return;
  }
  const processingCSVs: Partial<Survey>[] = (processingCSVNames ?? []).map((csv: string) => ({
    title: csv,
    id: csv + v4(),
    source: SurveySourceEnum.CSV,
    status: SurveyStatus.PROCESSING,
  }));
  const surveyGroups = groupSurveysBySource(surveysRes);
  surveyGroups.csv.surveys = surveyGroups.csv.surveys.concat(processingCSVs);

  const newIntegrations = assembleIntegrations([], [], surveyGroups, []).filter(({ source }) =>
    UnsupportedConnectorsSources.includes(source),
  );
  dispatch(
    updateConnectors({
      csvSurveys: surveyGroups.csv.surveys,
      integrations: newIntegrations,
    }),
  );
  dispatch(updateSurveysLoading({ loading: false }));
};

export const fetchConnectors = () => async (dispatch: AppDispatch) => {
  const startTime = performance.now();
  dispatch(updateConnectorsLoading({ loading: true }));
  const [connectionsRes, authProviderRes, authRes]: [
    BaseConnection[] | undefined,
    AuthProvider[] | undefined,
    BaseProviderAuthentication[] | undefined,
  ] = await Promise.all([api.fetchConnections(), api.fetchAuthProviders(), api.fetchProviderAuths()]);
  if (!connectionsRes || !authRes || !authProviderRes) {
    dispatch(updateConnectorsLoading({ loading: false, error: true }));
    return;
  }
  const surveyGroups = groupSurveysBySource();

  const newIntegrations = assembleIntegrations(authProviderRes, authRes, surveyGroups, connectionsRes).filter(
    ({ source }) => !UnsupportedConnectorsSources.includes(source),
  );

  dispatch(updateConnectors({ integrations: newIntegrations }));
  dispatch(updateConnectorsLoading({ loading: false }));
  const endTime = performance.now();
  eventsTracker.track(EVENTS.CONNECTIONS_FETCH_CONNECTORS, {
    'Processing Time': endTime - startTime,
    'Is Success': true,
  });
};

export const resetConnectors = () => (dispatch: AppDispatch) => {
  dispatch(updateConnectors({ integrations: assembleIntegrations(), csvSurveys: [] }));
};

interface ICreateConnection {
  operation: OperationTypeEnum;
  source: SurveySourceEnum;
  config: Record<string, any>;
  startDate: Date;
}
export const createConnection =
  ({ operation, source, config, startDate }: ICreateConnection) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();

    dispatch(updateSubmittingAddConnection({ submitting: true }));
    const res = await api.createConnection(operation, source, config);
    dispatch(updateSubmittingAddConnection({ submitting: false }));
    if (res) {
      dispatch(fetchConnectors());
      dispatch(updateLastAddedConnection({ success: true, source, type: operation }));
    } else {
      dispatch(updateLastAddedConnection({ success: false, source, type: operation }));
    }
    const endTime = performance.now();
    eventsTracker.track(EVENTS.CONNECTIONS_CONNECTION_ESTABLISHED, {
      'Processing Time': endTime - startTime,
      'App/Integration name': source,
      Operation: operation,
      'Is Success': !!res,
      'File/Dashboard Selected': config.fileName || config.dashboardName,
      'Selected Language': config.language,
      'Selected Brand': config.brandID,
      'Selected Export Type': config.exportType,
      'Schedule Operation': startDate.toDateString(),
      'Update Interval': ScheduleToReadable[config.updateInterval as ScheduleEnum],
    });
  };

interface ICreateQualtricsAuth {
  auth: {
    datacenterId: string;
    clientId: string;
    secretId: string;
    state: string;
    scopes: string;
    redirectUri: string;
  };
  integration: Integration;
  authProviderID: string;
}

export const createQualtricsAuth =
  ({ auth, integration, authProviderID }: ICreateQualtricsAuth) =>
  async (dispatch: AppDispatch) => {
    dispatch(updateSubmittingAuth({ submitting: true }));
    if (integration.authentication) {
      if (integration.authentication.active) {
        return null;
      }
      const res = await api.deleteProviderAuth(integration.authentication.id);
      if (res) {
        dispatch(removeAuth({ id: integration.authentication.id }));
      }
    }

    const res = await api.authenticateProvider(AuthSourceEnum.QUALTRICS, {
      datacenterId: auth.datacenterId,
      clientId: auth.clientId,
      secretId: auth.secretId,
      state: auth.state,
      scopes: auth.scopes,
      redirectUri: auth.redirectUri,
      providerID: authProviderID,
    });

    if (res) {
      return res as ProviderAuthentication;
    } else {
      dispatch(updateSubmittingAuth({ submitting: false }));
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: AuthSourceEnum.QUALTRICS,
        }),
      );
      return null;
    }
  };

interface ISubmitQualtricsAuth {
  auth: ProviderAuthentication;
}

export const submitQualtricsAuth =
  ({ auth }: ISubmitQualtricsAuth) =>
  async (dispatch: AppDispatch) => {
    const newAuth = { ...auth, active: true };
    dispatch(setAuth({ auth: newAuth, source: SurveySourceEnum.QUALTRICS }));
    dispatch(updateLastEditedAuth({ success: true, source: AuthSourceEnum.QUALTRICS }));
    eventsTracker.track(EVENTS.CONNECTIONS_AUTHENTICATION_SUCCESSFUL, {
      'App/Integration name': AuthSourceEnum.QUALTRICS,
      'Processing Time': 0,
    });

    dispatch(updateSubmittingAuth({ submitting: false }));
  };

interface ISubmitAzureAuth {
  auth: {
    accountName: string;
    containerName: string;
    sasToken: string;
  };
  authProviderID?: string;
}
export const submitAzureAuth =
  ({ auth, authProviderID }: ISubmitAzureAuth) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingAuth({ submitting: true }));
    const res = await api.authenticateProvider(AuthSourceEnum.AZURE_SAS, {
      accountName: auth.accountName,
      container: auth.containerName,
      token: auth.sasToken,
      providerID: authProviderID,
    });
    dispatch(updateSubmittingAuth({ submitting: false }));
    if (res) {
      dispatch(setAuth({ auth: res, source: SurveySourceEnum.AZURE }));
      const endTime = performance.now();
      dispatch(
        updateLastEditedAuth({
          success: true,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
      eventsTracker.track(EVENTS.CONNECTIONS_AUTHENTICATION_SUCCESSFUL, {
        'App/Integration name': AuthSourceEnum.AZURE_SAS,
        'Account Name': auth.accountName,
        'Processing Time': endTime - startTime,
      });
    } else {
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
    }
  };

interface IUpdateAzureAuth {
  auth: {
    accountName: string;
    containerName: string;
    sasToken: string;
  };
  id: string;
}
export const updateAzureAuth =
  ({ auth, id }: IUpdateAzureAuth) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingAuth({ submitting: true }));
    const res = await api.updateProviderAuth(id, AuthSourceEnum.AZURE_SAS, {
      accountName: auth.accountName,
      container: auth.containerName,
      token: auth.sasToken,
    });
    dispatch(updateSubmittingAuth({ submitting: false }));
    if (res) {
      dispatch(setAuth({ auth: res, source: SurveySourceEnum.AZURE }));
      const endTime = performance.now();
      dispatch(
        updateLastEditedAuth({
          success: true,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
      dispatch(fetchConnectors());
      dispatch(closeConnectionsModal());
      eventsTracker.track(EVENTS.CONNECTIONS_AUTHENTICATION_SUCCESSFUL, {
        'App/Integration name': AuthSourceEnum.AZURE_SAS,
        'Account Name': auth.accountName,
        'Processing Time': endTime - startTime,
      });
    } else {
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
    }
  };

export const deleteAuthById =
  ({ id }: { id: string }) =>
  async (dispatch: AppDispatch) => {
    const res = await api.deleteProviderAuth(id);
    if (res) {
      dispatch(removeAuth({ id }));
    }
    dispatch(updateSubmittingAuth({ submitting: false }));
  };

export const deleteAuth = () => async (dispatch: AppDispatch, getState: typeof store.getState) => {
  const { id } = (getState().connectors as IConnectorsReducer).deleteAuth;
  if (!id) {
    return;
  }
  const startTime = performance.now();
  dispatch(updateSubmittingDeleteAuth({ submitting: true }));
  const res = await api.deleteProviderAuth(id);
  dispatch(updateSubmittingDeleteAuth({ submitting: false }));
  if (res) {
    dispatch(removeAuth({ id }));
    dispatch(updateLastDeletedAuth({ success: true }));
  } else {
    dispatch(updateLastDeletedAuth({ success: false }));
  }
  dispatch(closeDeleteAuthModal());
  const endTime = performance.now();
  eventsTracker.track(EVENTS.CONNECTIONS_DELETE_AUTHENTICATION, {
    'Provider ID': id,
    'Is Success': !!res,
    'Processing Time': endTime - startTime,
  });
};

export const deleteConnection = () => async (dispatch: AppDispatch, getState: typeof store.getState) => {
  const { id, source } = (getState().connectors as IConnectorsReducer).deleteConnection;
  if (!id || !source) {
    return;
  }
  const startTime = performance.now();
  dispatch(updateSubmittingDeleteConnection({ submitting: true }));
  const res = await api.deleteConnection(id);
  dispatch(updateSubmittingDeleteConnection({ submitting: false }));
  if (res) {
    dispatch(removeConnection({ id, source }));
    dispatch(updateLastDeletedConnection({ success: true }));
  } else {
    dispatch(updateLastDeletedConnection({ success: false }));
  }
  dispatch(closeDeleteConnectionModal());
  const endTime = performance.now();
  eventsTracker.track(EVENTS.CONNECTIONS_DELETE_CONNECTION_FLOW, {
    'Connection ID': id,
    'Is Success': !!res,
    'App/Integration name': source,
    'Processing Time': endTime - startTime,
  });
};

interface IUpdateConnection {
  update: ConnectionUpdate;
}
export const updateConnection =
  ({ update }: IUpdateConnection) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingConnectionUpdate({ id: update.id, submitting: true }));
    const res = await api.updateConnection({
      ...update,
      interval: update.interval ? update.interval.toString() : undefined,
    });
    dispatch(updateSubmittingConnectionUpdate({ id: update.id, submitting: false }));
    if (res) {
      dispatch(updateConnectionAction({ connectionUpdate: update }));
      dispatch(updateConnectionUpdateResult({ id: update.id, success: true }));
    } else {
      dispatch(updateConnectionUpdateResult({ id: update.id, success: false }));
    }

    const endTime = performance.now();
    eventsTracker.track(EVENTS.CONNECTIONS_UPDATE_CONNECTION, {
      'Connection ID': update.id,
      'Is Success': !!res,
      'Processing Time': endTime - startTime,
    });
  };

interface IUploadCSV {
  buffer: ArrayBuffer;
  fileName: string;
  dashboards: string;
  newDashboards: string;
  source: string;
}
export const uploadCSV =
  ({ buffer, fileName, dashboards, newDashboards, source }: IUploadCSV) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingUpload({ submitting: true }));
    const res = await api.uploadCSV(buffer, {
      fileName: fileName,
      dashboards: dashboards,
      newDashboards: newDashboards,
      source,
    });
    dispatch(updateSubmittingUpload({ submitting: false }));
    // handle errors
    if (res.status !== 201) {
      if (res.status === 409) {
        dispatch(
          updateUploadResult({
            success: false,
            error: CsvUploadErrorEnum.DUBLICATE_NAME,
          }),
        );
      } else {
        dispatch(
          updateUploadResult({
            success: false,
            error: CsvUploadErrorEnum.UNKNOWN_ERROR,
          }),
        );
      }
      return;
    }
    // handle success
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_SELECT_A_FILE, {
      'File Name': fileName,
      Dashboards: dashboards,
      'New Dashboards': newDashboards,
      Survey: source,
    });
    const endTime = performance.now();
    dispatch(updateUploadResult({ success: true }));
    eventsTracker.track(EVENTS.FILE_UPLOAD_SUCCESS, {
      'Processing Time': endTime - startTime,
    });
  };

export const resetCSVUploadStatus = () => (dispatch: AppDispatch) => {
  dispatch(resetUploadResult());
};
