import React, { useState } from 'react';

import { useAppDispatch } from '../../../store/hooks';
import { openDeleteAuthModal } from '../../../store/actions/connectors';
import { Integration } from '../../../types/connections';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';

import { SURVEY_SOURCE_TO_IS_NEW } from '../constants';
import IntegrationListItem from './IntegrationListItem';

interface IIntegrationListItemContainer {
  integration: Integration;
  isSelected: boolean;
  isLoading: boolean;
  onSelect: (integration: Integration) => void;
  onEdit: (integration: Integration) => void;
}

function IntegrationListItemContainer({ integration, isSelected, isLoading, onSelect, onEdit }: IIntegrationListItemContainer) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleMenuClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }

  function selectedEdit() {
    setAnchorEl(null);
    onEdit(integration);
    eventsTracker.track(EVENTS.CONNECTIONS_KEBAB_EDIT_AUTHENTICATION, {
      'App/Integration name': integration.source,
    });
  }

  function onDelete() {
    setAnchorEl(null);
    dispatch(openDeleteAuthModal({ id: integration.authentication?.id ?? '' }));
    eventsTracker.track(EVENTS.CONNECTIONS_KEBAB_DELETE_AUTHENTICATION, {
      'App/Integration name': integration.source,
    });
  }

  function handleMenuOpen() {
    eventsTracker.track(EVENTS.CONNECTIONS_KEBAB_SELECT, {
      'App/Integration name': integration.source,
    });
  }

  return (
    <IntegrationListItem
      integration={integration}
      anchorEl={anchorEl}
      isSelected={isSelected}
      isLoading={isLoading}
      isNew={SURVEY_SOURCE_TO_IS_NEW[integration.source]}
      onMenuClick={handleMenuClick}
      onCloseMenu={setAnchorEl.bind(null, null)}
      onEdit={selectedEdit}
      onDelete={onDelete}
      onSelect={onSelect}
      onMenuOpen={handleMenuOpen}
    />
  );
}

export default IntegrationListItemContainer;
