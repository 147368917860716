import styled from 'styled-components';
import { ReactComponent as ReloadIcon } from '../../../../../../../assets/icons/progress-activity-icon.svg';

export const ActionsContainer = styled.div`
  margin-left: 20px;
  margin-top: 25px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const StyledReloadIcon = styled(ReloadIcon)`
  height: 18px;
  width: 18px;
  fill: var(--dark-purple);
  cursor: pointer;
`;
