import React from 'react';
import { SENTIMENT_TITLE } from '../../../../../models/Response';
import styled from 'styled-components';

const SentimentStatusContainer = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 80px;
  height: 16px;
  border-radius: var(--double-border-radius);
  overflow: hidden;
`;

interface IStatusPart {
  left: number;
  percent: number;
  type: 'positive' | 'negative' | 'neutral' | 'unknown';
}

const StatusPart = styled.div<IStatusPart>`
  position: absolute;
  left: ${({ left }) => left}%;
  top: 0;
  width: ${({ percent }) => percent}%;
  height: 100%;
  background-color: ${({ type }) => {
    switch (type) {
      case 'positive':
        return 'var(--sentiment-green)';
      case 'negative':
        return 'var(--sentiment-orange)';
      case 'neutral':
        return 'var(--sentiment-yellow)';
      case 'unknown':
        return 'var(--sentiment-gray)';
    }
  }};
`;

function SentimentStatus({ sentimentSummary, total }: any) {
  const positiveScore = (sentimentSummary.positive / total) * 100;
  const neutralScore = (sentimentSummary.neutral / total) * 100;
  const negativeScore = (sentimentSummary.negative / total) * 100;
  const unknownCount = total - sentimentSummary.positive - sentimentSummary.neutral - sentimentSummary.negative;
  const unknownScore = (unknownCount / total) * 100;

  return (
    <SentimentStatusContainer data-testid='sentiment-statuses'>
      <StatusPart
        data-testid='positive'
        title={`${SENTIMENT_TITLE.positive}: ${Math.round(positiveScore)}%`}
        left={0}
        type='positive'
        percent={positiveScore}
      />
      <StatusPart
        data-testid='neutral'
        title={`${SENTIMENT_TITLE.neutral}: ${Math.round(neutralScore)}%`}
        left={positiveScore}
        type='neutral'
        percent={neutralScore}
      />
      <StatusPart
        data-testid='negative'
        title={`${SENTIMENT_TITLE.negative}: ${Math.round(negativeScore)}%`}
        left={positiveScore + neutralScore}
        type='negative'
        percent={negativeScore}
      />
      <StatusPart
        data-testid='unknown'
        title={`${SENTIMENT_TITLE.unknown}: ${Math.round(unknownScore)}%`}
        left={positiveScore + neutralScore + negativeScore}
        type='unknown'
        percent={unknownScore}
      />
    </SentimentStatusContainer>
  );
}

export default SentimentStatus;
