import styled from 'styled-components';
import palette from '../../../../components/tools/palette';

const PValueThreshold = 0.05;

const PValueWrapper = styled.div<{ $grayed: boolean }>`
  ${({ $grayed }) => ($grayed ? `color: ${palette.textGray}` : '')}
`;

interface ICompareTableRowPValue {
  value: number;
}

function CompareTableRowPValue({ value }: ICompareTableRowPValue) {
  return <PValueWrapper $grayed={value > PValueThreshold}>{value}</PValueWrapper>;
}

export default CompareTableRowPValue;
