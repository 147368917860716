/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import QuestionsDropdownTitle from './QuestionsDropdownTitle';
import { PlotLegend } from './PlotLegend';

const TimelineContainer = styled(Grid)`
  padding: 16px;
`;

export class TimeLine extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    // Init state args
    this.state = {};
  }

  render() {
    let { loading, timelineData, mainQuestionId, lastUpdated, isFetching, handlePrimaryQuestionChange, questions } = this.props;

    return (
      <TimelineContainer container direction='column' justifyContent='space-between'>
        <Grid item xs={12}>
          <QuestionsDropdownTitle
            mainQuestionId={mainQuestionId}
            isFetching={isFetching}
            lastUpdated={lastUpdated}
            handlePrimaryQuestionChange={handlePrimaryQuestionChange}
            questions={questions}
            loading={loading}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <PlotLegend timelineData={timelineData} />
        </Grid>
      </TimelineContainer>
    );
  }
}

TimeLine.propTypes = {
  questions: PropTypes.object.isRequired,
  timelineData: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
};

export default TimeLine;
