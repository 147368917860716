import { Dispatch } from 'redux';

import { SegmentsMinMax, SortDirection, Thresholds } from '../compare';
import { DashboardDataSource } from '../../../types';
import { CompareView, SortField } from '../constants';

export enum COMPARE_ACTION_TYPES {
  SET_DASHBOARD_CONTEXT = 'COMPARE/SET_DASHBOARD_CONTEXT',
  SET_THRESHOLD = 'COMPARE/SET_THRESHOLD',
  RESET_THRESHOLD = 'COMPARE/RESET_THRESHOLD',
  SET_SORT = 'COMPARE/SET_SORT',
  SET_PAGE = 'COMPARE/SET_PAGE',
  SET_ROWS_PER_PAGE = 'COMPARE/SET_ROWS_PER_PAGE',
  SET_DATA_SOURCE_IDS = 'COMPARE/SET_DATA_SOURCE_IDS',
  SET_SELECTED_SEGMENT_IDS = 'COMPARE/SET_SELECTED_SEGMENT_IDS',
  SET_SEGMENTS_MIN_MAX = 'COMPARE/SET_SEGMENTS_MIN_MAX',
  SET_SELECTED_ROW_IDS = 'COMPARE/SET_SELECTED_ROW_IDS',
  SET_VIEW = 'COMPARE/SET_VIEW',
}

export type SetDashboardContextAction = {
  type: COMPARE_ACTION_TYPES;
  payload: {
    dashboardId: string;
    dataSources: DashboardDataSource[];
  };
};
export const setDashboardContext = () => {
  return (dispatch: Dispatch<SetDashboardContextAction>, getState: any): void => {
    const state = getState();
    const { id: dashboardId, dataSources } = state.dashboard.dashboard;
    dispatch({
      type: COMPARE_ACTION_TYPES.SET_DASHBOARD_CONTEXT,
      payload: { dashboardId, dataSources },
    });
  };
};

export const setThresholds = (thresholds: Thresholds) => ({
  type: COMPARE_ACTION_TYPES.SET_THRESHOLD,
  payload: { thresholds },
});

export const resetThresholds = () => ({
  type: COMPARE_ACTION_TYPES.RESET_THRESHOLD,
});

export const setSort = (field: SortField, direction: SortDirection) => ({
  type: COMPARE_ACTION_TYPES.SET_SORT,
  payload: { field, direction },
});

export const setPage = (page: number) => ({
  type: COMPARE_ACTION_TYPES.SET_PAGE,
  payload: { page },
});

export const setRowsPerPage = (rowsPerPage: number) => ({
  type: COMPARE_ACTION_TYPES.SET_ROWS_PER_PAGE,
  payload: { rowsPerPage },
});

export const setDataSourceIds = (dataSourceIDs: string[]) => ({
  type: COMPARE_ACTION_TYPES.SET_DATA_SOURCE_IDS,
  payload: { dataSourceIDs },
});

export const setSelectedSegmentIds = (selectedSegmentIDs: string[]) => ({
  type: COMPARE_ACTION_TYPES.SET_SELECTED_SEGMENT_IDS,
  payload: { selectedSegmentIDs },
});

export const setSegmentsMinMax = (segmentsMinMax: SegmentsMinMax) => ({
  type: COMPARE_ACTION_TYPES.SET_SEGMENTS_MIN_MAX,
  payload: { segmentsMinMax },
});

export const setSelectedRowIds = (selectedRowIDs: string[]) => ({
  type: COMPARE_ACTION_TYPES.SET_SELECTED_ROW_IDS,
  payload: { selectedRowIDs },
});

export const setView = (view: CompareView) => ({
  type: COMPARE_ACTION_TYPES.SET_VIEW,
  payload: { view },
});
