import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { ThumbStatus } from '../../components/Widgets/OpenEndedWidget/Data/AIInsights/Components/GeneratedInsights/GeneratedInsightsContainer';
import {
  AI_INSIGHTS_CLEAR_ALL,
  AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR,
  AI_INSIGHTS_FEEDBACK_SUBMIT_START,
  AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS,
  AI_INSIGHTS_GENERATE_REQUEST_FAILURE,
  AI_INSIGHTS_GENERATE_REQUEST_START,
  AI_INSIGHTS_GENERATE_REQUEST_SUCCESS,
  AI_INSIGHTS_HIDE_FEEDBACK_MODAL,
  AI_INSIGHTS_HIDE_QUOTES_MODAL,
  AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET,
  AI_INSIGHTS_RESET,
  AI_INSIGHTS_SHOW_FEEDBACK_MODAL,
  AI_INSIGHTS_SHOW_QUOTES_MODAL,
  AI_INSIGHTS_UPDATE_FILTERED_CONCEPTS_FOR_WIDGET,
  AI_INSIGHTS_UPDATE_SELECTED_CONCEPT_FOR_WIDGET,
  clearAllInsights,
  feedbackSubmitError,
  feedbackSubmitStart,
  feedbackSubmitSuccess,
  GeneratedInsight,
  generateInsightsRequestCancel,
  generateInsightsRequestFailure,
  generateInsightsRequestStart,
  generateInsightsRequestSuccess,
  hideFeedbackModal,
  hideKeyQuotesModal,
  initializeInsightsForWidget,
  resetAIInsights,
  showFeedbackModal,
  showKeyQuotesModal,
  updateSelectedConceptForWidget,
  updateSelectedConceptsFilteredInWidgetSettings,
} from '../actions/aiInsights';
import { WidgetDataSourceConcept } from '../../types/DataSource';
import { InsightsFilter } from '../thunks/aiInsights';

export const DISABLED_BY_WIDGET_SETTING = 'Disabled by widget settings.';

export type Concept = {
  id?: number;
  title: string;
  isEnabled: boolean;
  parentConceptTitle?: string;
  disabledText?: string;
  numberOfAnswers?: number;
};

export enum InsightType {
  INTRO_PROMPT = 'INTRO_PROMPT',
  SELECTED_CONCEPT = 'SELECTED_CONCEPT',
  GENERATING = 'GENERATING',
  AI_GENERATED = 'AI_GENERATED',
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
}

export type Insight = {
  id: string;
  timestamp: string;
  type: InsightType;
  filter: InsightsFilter[];
  availableFilters: string[];
  totalResponses?: number;
  segmentsNames?: string[];
  customPrompt?: string;
  isDisabled?: boolean;
  concept?: Concept;
  generatedInsights?: GeneratedInsight[];
  relatedConcepts?: Concept[];
  generationStatuses?: GenerationStatus[];
  numberOfResponsesUsedForGeneration?: string;
  generationModel?: string;
  generationModelLocation?: string;
  generationTime?: string;
  tokensTotal?: string;
  modelParameters?: {
    maxOutputTokens: string;
    temperature: string;
    topK: string;
    topP: string;
  };
};

export type InsightsByWidgetID = {
  widgetID: string;
  insights: Insight[];
};

export enum GenerationStatus {
  GENERATING = 'GENERATING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type FeedbackModal = {
  isVisible: boolean;
  widgetID: string;
  thumbStatus: ThumbStatus;
  relatedInsightID: string;
  dataSourceID: string;
  isLoading: boolean;
  isError: boolean;
};

export type KeyQuote = {
  createdAt: string;
  quote: string;
  quoteID: string;
};

export type KeyQuotesModal = {
  isVisible: boolean;
  widgetID: string;
  relatedInsightID: string;
  relatedKeyTakeaway: string;
  keyQuotes: KeyQuote[];
};

export interface IAIInsightsReducer {
  insightsByWidgetID: InsightsByWidgetID[];
  filteredConceptsByWidgetID: {
    widgetID: string;
    concepts: Set<string>;
  }[];
  conceptsByParentAndChildPerWidgetID: {
    widgetID: string;
    childConcepts: WidgetDataSourceConcept[];
    parentConcepts: WidgetDataSourceConcept[];
  }[];
  feedbackModal?: FeedbackModal;
  keyQuotesModal?: KeyQuotesModal;
}

const initialState: IAIInsightsReducer = {
  insightsByWidgetID: [],
  filteredConceptsByWidgetID: [],
  conceptsByParentAndChildPerWidgetID: [],
};

type aiInsightsActionTypes = ReturnType<typeof resetAIInsights> &
  ReturnType<typeof initializeInsightsForWidget> &
  ReturnType<typeof updateSelectedConceptForWidget> &
  ReturnType<typeof generateInsightsRequestStart> &
  ReturnType<typeof generateInsightsRequestSuccess> &
  ReturnType<typeof generateInsightsRequestFailure> &
  ReturnType<typeof generateInsightsRequestCancel> &
  ReturnType<typeof clearAllInsights> &
  ReturnType<typeof updateSelectedConceptsFilteredInWidgetSettings> &
  ReturnType<typeof showFeedbackModal> &
  ReturnType<typeof hideFeedbackModal> &
  ReturnType<typeof feedbackSubmitStart> &
  ReturnType<typeof feedbackSubmitSuccess> &
  ReturnType<typeof feedbackSubmitError> &
  ReturnType<typeof showKeyQuotesModal> &
  ReturnType<typeof hideKeyQuotesModal>;

const aiInsightsReducer = (state: IAIInsightsReducer = initialState, action: aiInsightsActionTypes) => {
  switch (action.type) {
    case AI_INSIGHTS_RESET:
      return handleResetAIInsights();
    case AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET:
      return handleInitializeInsightsForWidget(state, action);
    case AI_INSIGHTS_UPDATE_FILTERED_CONCEPTS_FOR_WIDGET:
      return handleUpdateFilteredConceptsForWidget(state, action);
    case AI_INSIGHTS_UPDATE_SELECTED_CONCEPT_FOR_WIDGET:
      return handleUpdateSelectedConceptForWidget(state, action);
    case AI_INSIGHTS_GENERATE_REQUEST_START:
      return handleGenerateRequestStart(state, action);
    case AI_INSIGHTS_GENERATE_REQUEST_SUCCESS:
      return handleGenerateRequestSuccess(state, action);
    case AI_INSIGHTS_GENERATE_REQUEST_FAILURE:
      return handleGenerateRequestFailure(state, action);
    case AI_INSIGHTS_CLEAR_ALL:
      return handleClearAllInsights(state, action);
    case AI_INSIGHTS_SHOW_FEEDBACK_MODAL:
      return handleShowFeedbackModal(state, action);
    case AI_INSIGHTS_HIDE_FEEDBACK_MODAL:
      return handleHideFeedbackModal(state);
    case AI_INSIGHTS_FEEDBACK_SUBMIT_START:
      return handleFeedbackSubmitStart(state);
    case AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS:
      return handleFeedbackSubmitSuccess(state);
    case AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR:
      return handleFeedbackSubmitError(state);
    case AI_INSIGHTS_SHOW_QUOTES_MODAL:
      return handleKeyQuotesModalShow(state, action);
    case AI_INSIGHTS_HIDE_QUOTES_MODAL:
      return handleKeyQuotesModalHide(state);
    default:
      return state;
  }
};

function handleResetAIInsights(): IAIInsightsReducer {
  return initialState;
}

function handleInitializeInsightsForWidget(
  state: IAIInsightsReducer,
  action: ReturnType<typeof initializeInsightsForWidget>,
): IAIInsightsReducer {
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const filteredConcepts = new Set(action.payload.filteredConcepts);
  const { parentConcepts, childConcepts } = action.payload.dataSource.items.reduce(
    (result, concept) => {
      if (concept.children !== null) {
        result.parentConcepts.push(concept);
      } else {
        result.childConcepts.push(concept);
      }
      return result;
    },
    {
      parentConcepts: [] as WidgetDataSourceConcept[],
      childConcepts: [] as WidgetDataSourceConcept[],
    },
  );

  clonedInsightsByWidgetID.push({
    widgetID: action.payload.widgetID,
    insights: [],
  });

  return {
    ...state,
    insightsByWidgetID: clonedInsightsByWidgetID,
    filteredConceptsByWidgetID: [
      ...state.filteredConceptsByWidgetID,
      {
        widgetID: action.payload.widgetID,
        concepts: filteredConcepts,
      },
    ],
    conceptsByParentAndChildPerWidgetID: [
      ...state.conceptsByParentAndChildPerWidgetID,
      {
        widgetID: action.payload.widgetID,
        parentConcepts,
        childConcepts,
      },
    ],
  };
}

function handleUpdateSelectedConceptForWidget(
  state: IAIInsightsReducer,
  action: ReturnType<typeof updateSelectedConceptForWidget>,
): IAIInsightsReducer {
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const insightsForWidget = clonedInsightsByWidgetID.find((insights) => insights.widgetID === action.payload.widgetID);

  if (insightsForWidget) {
    const latestInsight = insightsForWidget.insights[insightsForWidget.insights.length - 1];
    let concept;

    if (action.payload.selectedConcept) {
      const { parentConcepts, childConcepts } = state.conceptsByParentAndChildPerWidgetID.find(
        (concepts) => concepts.widgetID === action.payload.widgetID,
      )!;
      const filteredConcepts = state.filteredConceptsByWidgetID.find((concepts) => concepts.widgetID === action.payload.widgetID);
      concept = assignPossibleParentConcept([action.payload.selectedConcept], parentConcepts);
      concept = appendNecessaryInfoToConcepts(concept, childConcepts);
      concept = markNotEnabledConcepts(concept, filteredConcepts?.concepts);
      concept = concept.pop();
    }

    if (latestInsight.type === InsightType.SELECTED_CONCEPT) {
      if (concept) {
        latestInsight.concept = concept;
      } else {
        insightsForWidget.insights.pop();
      }
    } else if (concept) {
      insightsForWidget.insights.push({
        id: v4(),
        timestamp: new Date().toISOString(),
        type: InsightType.SELECTED_CONCEPT,
        concept: concept,
      } as Insight);
    }
  }

  return {
    ...state,
    insightsByWidgetID: clonedInsightsByWidgetID,
  };
}

function handleUpdateFilteredConceptsForWidget(
  state: IAIInsightsReducer,
  action: ReturnType<typeof updateSelectedConceptsFilteredInWidgetSettings>,
): IAIInsightsReducer {
  const filteredConcepts = new Set(action.payload.concepts);
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const insightsForWidget = clonedInsightsByWidgetID.find((insights) => insights.widgetID === action.payload.widgetID);

  if (insightsForWidget) {
    const latestInsight = insightsForWidget.insights[insightsForWidget.insights.length - 1];
    if (latestInsight?.relatedConcepts?.length) {
      latestInsight.relatedConcepts = markNotEnabledConcepts(latestInsight.relatedConcepts, filteredConcepts);
    }
  }

  const clonedFilteredConceptsByWidgetID = cloneDeep(state.filteredConceptsByWidgetID);
  const filteredConceptsForWidget = clonedFilteredConceptsByWidgetID.find((concepts) => concepts.widgetID === action.payload.widgetID);
  if (filteredConcepts.size) {
    if (filteredConceptsForWidget) {
      filteredConceptsForWidget.concepts = filteredConcepts;
    } else {
      clonedFilteredConceptsByWidgetID.push({
        widgetID: action.payload.widgetID,
        concepts: filteredConcepts,
      });
    }
  } else {
    if (filteredConceptsForWidget) {
      const indexForRemoval = clonedFilteredConceptsByWidgetID.indexOf(filteredConceptsForWidget);
      clonedFilteredConceptsByWidgetID.splice(indexForRemoval, 1);
    }
  }

  return {
    ...state,
    filteredConceptsByWidgetID: clonedFilteredConceptsByWidgetID,
    insightsByWidgetID: clonedInsightsByWidgetID,
  };
}

function handleGenerateRequestStart(
  state: IAIInsightsReducer,
  action: ReturnType<typeof generateInsightsRequestStart>,
): IAIInsightsReducer {
  const { availableFilters, isTryAgain, widgetID, id, timestamp, selectedConcept, customPrompt, segmentsNames, filter } = action.payload;
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const insightsForWidget = clonedInsightsByWidgetID.find((insights) => insights.widgetID === widgetID);

  if (isTryAgain) {
    // Sometimes users might have selected concept after failure/stopped generation
    // In order not to handle multiple generation scenarios we remove the in-middle generation status
    const previousGenerationStatus = insightsForWidget?.insights.find(
      ({ type, id: insightID, generationStatuses }) =>
        type === InsightType.GENERATING && insightID === id && generationStatuses?.includes(GenerationStatus.FAILURE),
    );
    if (previousGenerationStatus && insightsForWidget) {
      const indexOfPreviousGenerationStatus = insightsForWidget.insights.indexOf(previousGenerationStatus);
      insightsForWidget.insights.splice(indexOfPreviousGenerationStatus!, 1);
    }
  }

  if (insightsForWidget) {
    insightsForWidget.insights.push({
      id,
      timestamp: timestamp,
      type: InsightType.GENERATING,
      concept: selectedConcept,
      customPrompt: customPrompt,
      generationStatuses: [GenerationStatus.GENERATING],
      segmentsNames: segmentsNames,
      filter,
      availableFilters,
    });
  }

  return {
    ...state,
    insightsByWidgetID: clonedInsightsByWidgetID,
  };
}

function handleGenerateRequestSuccess(
  state: IAIInsightsReducer,
  action: ReturnType<typeof generateInsightsRequestSuccess>,
): IAIInsightsReducer {
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const insightsForWidget = clonedInsightsByWidgetID.find((insights) => insights.widgetID === action.payload.widgetID);

  if (insightsForWidget) {
    const insightForUpdate = insightsForWidget.insights.find(({ id }) => id === action.payload.id);
    if (insightForUpdate) {
      let { relatedConcepts = [] } = action.payload;
      const { parentConcepts, childConcepts } = state.conceptsByParentAndChildPerWidgetID.find(
        ({ widgetID }) => widgetID === action.payload.widgetID,
      )!;
      const filteredConcepts = state.filteredConceptsByWidgetID.find(({ widgetID }) => widgetID === action.payload.widgetID);
      relatedConcepts = assignPossibleParentConcept(relatedConcepts, parentConcepts);
      relatedConcepts = appendNecessaryInfoToConcepts(relatedConcepts, childConcepts);
      relatedConcepts = markNotEnabledConcepts(relatedConcepts, filteredConcepts?.concepts);

      insightForUpdate.type = InsightType.AI_GENERATED;
      insightForUpdate.generatedInsights = action.payload.generatedInsights!;
      insightForUpdate.relatedConcepts = relatedConcepts;
      insightForUpdate.numberOfResponsesUsedForGeneration = action.payload.numberOfResponsesUsedForGeneration;
      insightForUpdate.tokensTotal = action.payload.tokensTotal;
      insightForUpdate.generationModel = action.payload.generationModel;
      insightForUpdate.generationModelLocation = action.payload.generationModelLocation;
      insightForUpdate.generationTime = action.payload.generationTime;
      insightForUpdate.totalResponses = action.payload.totalResponses;
      insightForUpdate.modelParameters = { ...action.payload.modelParameters };
    } else {
      console.error(`Could not find the insight with ID: ${action.payload.id}`);
    }
  }

  return {
    ...state,
    insightsByWidgetID: clonedInsightsByWidgetID,
  };
}

function handleGenerateRequestFailure(
  state: IAIInsightsReducer,
  action: ReturnType<typeof generateInsightsRequestFailure>,
): IAIInsightsReducer {
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const insightsForWidget = clonedInsightsByWidgetID.find((insights) => insights.widgetID === action.payload.widgetID);

  if (insightsForWidget) {
    const generatingInsight = insightsForWidget.insights.find((i) => i.id === action.payload.id);
    if (generatingInsight) {
      if (action.payload.totalResponses === 0) {
        generatingInsight.type = InsightType.NOT_ENOUGH_DATA;
        generatingInsight.generationStatuses = [];
      } else {
        generatingInsight.generationStatuses?.push(GenerationStatus.FAILURE);
      }
      generatingInsight.totalResponses = action.payload.totalResponses;
    } else {
      console.error(`Insights were not found for widgetID: ${action.payload.widgetID} and insightID: ${action.payload.id}`);
    }
  } else {
    console.error(`Widget ID was not found for widgetID: ${action.payload.widgetID} and insightID: ${action.payload.id}`);
  }

  return {
    ...state,
    insightsByWidgetID: clonedInsightsByWidgetID,
  };
}

function handleClearAllInsights(state: IAIInsightsReducer, action: ReturnType<typeof clearAllInsights>): IAIInsightsReducer {
  const clonedInsightsByWidgetID = cloneDeep(state.insightsByWidgetID);
  const insightsForWidget = clonedInsightsByWidgetID.find((insights) => insights.widgetID === action.payload.widgetID);

  if (insightsForWidget) {
    insightsForWidget.insights = insightsForWidget.insights.reduceRight((result, insight, index) => {
      const recentSelectedConceptPrompt = index === insightsForWidget.insights.length - 1 && insight.type === InsightType.SELECTED_CONCEPT;

      if (index === 0 || recentSelectedConceptPrompt) {
        insight.isDisabled = false;
        result.unshift(insight);
      }

      return result;
    }, [] as Insight[]);
  }

  return {
    ...state,
    insightsByWidgetID: clonedInsightsByWidgetID,
  };
}

function handleShowFeedbackModal(state: IAIInsightsReducer, action: ReturnType<typeof showFeedbackModal>): IAIInsightsReducer {
  return {
    ...state,
    feedbackModal: {
      isVisible: true,
      widgetID: action.payload.widgetID,
      thumbStatus: action.payload.thumbStatus,
      relatedInsightID: action.payload.insightID,
      dataSourceID: action.payload.dataSourceID,
      isLoading: false,
      isError: false,
    },
  };
}

function handleHideFeedbackModal(state: IAIInsightsReducer): IAIInsightsReducer {
  return {
    ...state,
    feedbackModal: {
      ...Object.assign({}, state.feedbackModal),
      isVisible: false,
      isLoading: false,
      isError: false,
    },
  };
}

function handleKeyQuotesModalShow(state: IAIInsightsReducer, action: ReturnType<typeof showKeyQuotesModal>): IAIInsightsReducer {
  return {
    ...state,
    keyQuotesModal: {
      isVisible: true,
      widgetID: action.payload.widgetID,
      relatedKeyTakeaway: action.payload.keyTakeaway,
      relatedInsightID: action.payload.insightID,
      keyQuotes: action.payload.keyQuotes,
    },
  };
}

function handleKeyQuotesModalHide(state: IAIInsightsReducer): IAIInsightsReducer {
  return {
    ...state,
    keyQuotesModal: {
      ...Object.assign({}, state.keyQuotesModal),
      isVisible: false,
      keyQuotes: [],
      relatedKeyTakeaway: '',
    },
  };
}

function handleFeedbackSubmitStart(state: IAIInsightsReducer): IAIInsightsReducer {
  return {
    ...state,
    feedbackModal: {
      ...Object.assign({}, state.feedbackModal),
      isLoading: true,
      isError: false,
    },
  };
}

function handleFeedbackSubmitSuccess(state: IAIInsightsReducer): IAIInsightsReducer {
  return {
    ...state,
    feedbackModal: {
      ...Object.assign({}, state.feedbackModal),
      isLoading: false,
      isError: false,
    },
  };
}

function handleFeedbackSubmitError(state: IAIInsightsReducer): IAIInsightsReducer {
  return {
    ...state,
    feedbackModal: {
      ...Object.assign({}, state.feedbackModal),
      isLoading: false,
      isError: true,
    },
  };
}

export function pickRelatedConcepts(childConcepts: WidgetDataSourceConcept[]): Concept[] {
  const excludedConcepts = new Set(['n/a', 'not applicable']);
  const relatedConcepts: Concept[] = [];
  let i = 0;
  while (relatedConcepts.length < 3 && i < childConcepts.length) {
    if (childConcepts[i] && !excludedConcepts.has(childConcepts[i].response)) {
      const concept: Concept = {
        title: childConcepts[i].response,
        isEnabled: true,
        numberOfAnswers: childConcepts[i].base.value,
      };
      relatedConcepts.push(concept);
    }
    i++;
  }
  return relatedConcepts;
}

export function assignPossibleParentConcept(selectedConcepts: Concept[], parentConcepts: WidgetDataSourceConcept[]): Concept[] {
  if (!selectedConcepts.length || !parentConcepts.length) {
    return selectedConcepts;
  }

  const result: Concept[] = [];
  for (const selectedConcept of selectedConcepts) {
    for (const parentConcept of parentConcepts) {
      if (selectedConcept.title === parentConcept.response) {
        result.push({
          ...selectedConcept,
          numberOfAnswers: parentConcept.base.value,
        });
        break;
      } else {
        for (const childConcept of parentConcept.children!) {
          if (selectedConcept.title === childConcept) {
            selectedConcept.parentConceptTitle = parentConcept.response;
            result.push({ ...selectedConcept });
            break;
          }
        }
      }
    }
  }

  return result.length ? result : selectedConcepts;
}

export function appendNecessaryInfoToConcepts(concepts: Concept[], childConcepts: WidgetDataSourceConcept[]): Concept[] {
  const result: Concept[] = [];

  for (const concept of concepts) {
    const mappedConcept = childConcepts.find((childConcept) => childConcept.response === concept.title);
    if (mappedConcept) {
      result.push({
        ...concept,
        numberOfAnswers: mappedConcept.base.value,
      });
    }
  }

  return result.length ? result : concepts;
}

export function markNotEnabledConcepts(selectedConcepts: Concept[], filteredConcepts?: Set<string>): Concept[] {
  const result: Concept[] = [];

  if (!filteredConcepts || filteredConcepts.size === 0) {
    for (const selectedConcept of selectedConcepts) {
      const concept = {
        ...selectedConcept,
        isEnabled: true,
      };
      delete concept.disabledText;

      result.push(concept);
    }

    return result;
  }

  for (const selectedConcept of selectedConcepts) {
    const isFilteredOut =
      !filteredConcepts.has(selectedConcept.title) &&
      selectedConcept.parentConceptTitle &&
      !filteredConcepts.has(selectedConcept.parentConceptTitle);
    if (isFilteredOut) {
      result.push({
        ...selectedConcept,
        isEnabled: false,
        disabledText: DISABLED_BY_WIDGET_SETTING,
      });
    } else {
      const concept = {
        ...selectedConcept,
        isEnabled: true,
      };
      delete concept.disabledText;

      result.push(concept);
    }
  }

  return result;
}

export default aiInsightsReducer;
