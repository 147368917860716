import React from 'react';

import styled from 'styled-components';
import { Bubble, BubbleText, BubbleClosedCross, BubbleClosedCircle } from './styles';

const SelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 8px 16px;
`;

const DropdownPlaceholderText = styled.div`
  color: var(--grey);
`;

interface IUploadDropdownOutput {
  selectedDashboards: string[];
  bubbleColor: string;
  bubbleTextColor: string;
  bubbleTextWeight: string;
  bubbleTextHover: string;
  handleBubbleDeleteClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleCheckboxChange: (value: string) => void;
}

export function UploadDropdownOutput({
  selectedDashboards,
  bubbleColor,
  bubbleTextColor,
  bubbleTextWeight,
  bubbleTextHover,
  handleBubbleDeleteClick,
  handleCheckboxChange,
}: IUploadDropdownOutput) {
  return (
    <SelectContainer>
      {selectedDashboards.length === 0 ? (
        <DropdownPlaceholderText>Specify the Dashboard(s) to which you'd like to upload this data</DropdownPlaceholderText>
      ) : (
        selectedDashboards.map((value: string) => (
          <Bubble key={value} bubbleColor={bubbleColor}>
            <BubbleText bubbleTextWeight={bubbleTextWeight} bubbleTextColor={bubbleTextColor}>
              {value}
            </BubbleText>
            <BubbleClosedCircle
              bubbleColor={bubbleColor}
              bubbleTextHover={bubbleTextHover}
              data-testid='bubble-delete'
              onMouseDown={handleBubbleDeleteClick}
              onClick={handleCheckboxChange.bind(null, value)}
            >
              <BubbleClosedCross />
            </BubbleClosedCircle>
          </Bubble>
        ))
      )}
    </SelectContainer>
  );
}
