import { createStore, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
import isEqual from 'lodash/isEqual';
import interviewGenerateWatcher from './middleware/interviewGenerateWatcher';
import rootReducer, { IApplicationState } from './reducers';
import { DEFAULT_COLOR_SCHEME_NAME } from '../style/colors';
import { defaultSettingsState, ISettingsReducer } from './reducers/settingsReducer';
import { IAppReducer } from './reducers/app';
import insightsLoader from './middleware/insightsLoader';
import interviewInsightsLoader from './middleware/interviewInsightsLoader';

const LOCAL_STORAGE_KEY = {
  APP: 'app',
  SETTINGS: 'settings',
};

const getSavedState = () => {
  const appStateJson = localStorage.getItem(LOCAL_STORAGE_KEY.APP);
  const appState = appStateJson ? JSON.parse(appStateJson) : {};

  const settingsJson = localStorage.getItem(LOCAL_STORAGE_KEY.SETTINGS);
  const storedSettings = settingsJson ? JSON.parse(settingsJson) : null;
  const settings: ISettingsReducer = settingsJson ? storedSettings : defaultSettingsState;

  if (!settings.groupsState) {
    settings.groupsState = [];
  }

  // MIGRATE FROM PREVIOUS SETTINGS, WHERE ONLY OPENED STATE WAS STORED
  if (storedSettings) {
    if (storedSettings.openedGroups) {
      for (const groupID of storedSettings.openedGroups) {
        settings.groupsState.push({ groupID, isCollapsed: false });
      }
      settings.openedGroups = [];
      localStorage.setItem(LOCAL_STORAGE_KEY.SETTINGS, JSON.stringify(settings));
    }
  }

  if (!settings.totalBadgeExpanded) {
    settings.totalBadgeExpanded = {};
  }

  const weightedMetricsUsed = !appState.hasOwnProperty('weightedMetricsUsed') || appState.weightedMetricsUsed;
  const translationUsed = appState.translationUsed;
  const colorSchemeName = appState.colorSchemeName ? appState.colorSchemeName : DEFAULT_COLOR_SCHEME_NAME;

  return {
    settings,
    app: {
      weightedMetricsUsed,
      translationUsed,
      colorSchemeName,
    } as IAppReducer,
  };
};

export const storeCreator = (preloadedState: any = {}) =>
  createStore<IApplicationState, Action<any>, unknown, unknown>(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware, insightsLoader, interviewInsightsLoader, interviewGenerateWatcher)),
  );

/**
 * Configure Redux Store
 */
function configureStore() {
  const savedState = getSavedState();

  const store = storeCreator(savedState);

  let currentAppState: Partial<IAppReducer> | null = null;
  let currentSettings: Partial<ISettingsReducer> | null = null;
  const handleStoreStateChange = () => {
    const previousAppState = currentAppState;
    const previousSettings = currentSettings;
    const state = store.getState();
    currentAppState = state.app;
    currentSettings = state.settings;
    if (!isEqual(currentAppState, previousAppState)) {
      localStorage.setItem(LOCAL_STORAGE_KEY.APP, JSON.stringify(currentAppState));
    }
    if (!isEqual(currentSettings, previousSettings)) {
      localStorage.setItem(LOCAL_STORAGE_KEY.SETTINGS, JSON.stringify(currentSettings));
    }
  };

  store.subscribe(handleStoreStateChange);

  return store;
}

const store = configureStore();

export default store;
