import React from 'react';
import { MotivationsWrapper } from '../../wrappers/MotivationsWrapper';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const TrendsContainer = styled(Paper)`
  & > div {
    background-color: #fff;
  }

  &.MuiPaper-root {
    padding: 4px;
    margin: 0px 16px;
    margin-bottom: 16px;
    border-radius: var(--border-radius);
    box-shadow: none;
  }
`;

const TrendsTab = () => {
  return (
    <TrendsContainer>
      <MotivationsWrapper />
    </TrendsContainer>
  );
};

export default TrendsTab;
