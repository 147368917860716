import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { FILTER_ID_ALL } from '../../../constants';

const AverageFilterBox = styled(Box).attrs((props) => ({
  style: {
    backgroundColor: props.color,
  },
}))`
  height: 8px;
  width: 8px;
  margin-right: 4px;
  &:nth-child(odd) {
    margin-bottom: 4px;
  }
`;

const AverageFilterBoxes = ({ selectedFilterIds, filtersById }) => {
  const filterIds =
    selectedFilterIds.length === 1 && selectedFilterIds[0] === FILTER_ID_ALL
      ? Object.keys(filtersById).filter((filterId) => filterId !== FILTER_ID_ALL)
      : selectedFilterIds;

  return (
    <Box display='flex' flexDirection='column' flexWrap='wrap' height={20} width={Math.ceil(filterIds.length / 2) * 12}>
      {filterIds.map((filterId) => (
        <AverageFilterBox
          key={filterId}
          data-tooltip-id='base-tooltip'
          data-tooltip-content={filtersById[filterId].name}
          color={filtersById[filterId].color}
        />
      ))}
    </Box>
  );
};

export default AverageFilterBoxes;
