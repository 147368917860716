import styled from 'styled-components';

import { Select } from '@material-ui/core';

import { ReactComponent as closeIcon } from '../../../assets/icons/cross-icon.svg';

export const Bubble = styled.div<{ bubbleColor: string }>`
  border-radius: var(--double-border-radius);
  background-color: var(--${(props) => props.bubbleColor}-transparent-10);
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 0 4px;
  }
`;

export const BubbleClosedCircle = styled.div<{
  bubbleColor: string;
  bubbleTextHover: string;
}>`
  background-color: var(--${(props) => props.bubbleColor});
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--${(props) => props.bubbleTextHover});
  }
`;

export const BubbleClosedCross = styled(closeIcon)`
  color: var(--white);
  width: 14px;
  height: 14px;
`;

export const BubbleText = styled.div<{
  bubbleTextColor: string;
  bubbleTextWeight: string;
}>`
  font-size: 16px;
  font-weight: ${(props) => props.bubbleTextWeight};
  color: var(--${(props) => props.bubbleTextColor});
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 16px;
  padding: 16px;

  > * {
    margin-bottom: 16px;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const CloseIcon = styled(closeIcon)`
  cursor: pointer;
  color: var(--black);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--grey-transparent-10);
  }
`;

export const DialogTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
`;

export const DropdownElement = styled.div<{ paddingHorizontal: string }>`
  display: flex;
  align-items: center;
  padding: 12px ${(props) => props.paddingHorizontal};
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
`;

export const TextField = styled.input`
  padding: 16px 10px;
  font-size: 16px;
  margin-bottom: 16px;
  width: 100%;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
  outline-color: var(--beehive-purple);
`;
