import React, { useEffect, useState } from 'react';

import { DropzoneOptions, useDropzone } from 'react-dropzone';

interface IDropzoneContent {
  file: File | null;
  handleSelectFile: (e: any) => void;
}

interface IDropzone {
  rootContainer: (props: React.PropsWithChildren<object>) => React.ReactElement;
  options?: DropzoneOptions & { className?: string };
  children?: (props: IDropzoneContent) => React.ReactElement;
  setFile: (file: File | null) => void;
  handleSelectFile?: (e: any) => void;
}

function Dropzone({ rootContainer: RootContainer, handleSelectFile, setFile, children, options = {} }: IDropzone) {
  const { className, ...restOptions } = options;
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone(restOptions);
  const [lastFile, setLastFile] = useState<File | null>(null);

  useEffect(() => {
    const lastFile = acceptedFiles.length ? acceptedFiles[acceptedFiles.length - 1] : null;
    setLastFile(lastFile);
    setFile(lastFile);
  }, [acceptedFiles, setFile]);

  return (
    <RootContainer {...getRootProps({ className: className ?? '' })}>
      <input {...getInputProps()} />
      {children &&
        children({
          file: lastFile,
          handleSelectFile: (e: any) => {
            open();
            handleSelectFile && handleSelectFile(e);
          },
        })}
    </RootContainer>
  );
}

export default Dropzone;
