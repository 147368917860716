import React from 'react';
import { hideKeyQuotesModal } from '../../../../store/actions/aiInsights';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import KeyQuotes from './KeyQuotes';

function KeyQuotesContainer() {
  const { keyQuotesModal } = useAppSelector((state) => state.aiInsights);
  const dispatch = useAppDispatch();

  if (!keyQuotesModal) {
    return null;
  }

  function handleClose() {
    dispatch(hideKeyQuotesModal());
  }

  return (
    <KeyQuotes
      keyTakeaway={keyQuotesModal.relatedKeyTakeaway}
      keyQuotes={keyQuotesModal.keyQuotes}
      isOpen={keyQuotesModal.isVisible}
      onClose={handleClose}
    />
  );
}

export default KeyQuotesContainer;
