import { ChangeEventHandler } from 'react';

import { FilledButton } from '../../../UI/Button';

import {
  StyledCircularProgress,
  AuthContent,
  AuthFormContainer,
  AuthFormContent,
  AuthSubtitle,
  Container,
  FooterActions,
  AuthTextField,
} from '../style';
import Help from '../Help';

interface IAuthSAS {
  accountError: string;
  containerError: string;
  tokenError: string;
  accountName: string;
  containerName: string;
  sasToken: string;
  isEditMode: boolean;
  isSubmitting: boolean;
  isSubmitDisabled: boolean;
  onSubmit: () => void;
  onSendHelpMessage: () => void;
  onBlurAccountName: () => void;
  onBlurContainerName: () => void;
  onBlurSasToken: () => void;
  onChangeAccountName: ChangeEventHandler<HTMLInputElement>;
  onChangeContainerName: ChangeEventHandler<HTMLInputElement>;
  onChangeSasToken: ChangeEventHandler<HTMLInputElement>;
}

function AuthSAS({
  accountError,
  containerError,
  tokenError,
  accountName,
  containerName,
  sasToken,
  isEditMode,
  isSubmitDisabled,
  isSubmitting,
  onSubmit,
  onSendHelpMessage,
  onBlurAccountName,
  onBlurContainerName,
  onBlurSasToken,
  onChangeAccountName,
  onChangeContainerName,
  onChangeSasToken,
}: IAuthSAS) {
  return (
    <>
      <Container>
        {isEditMode ? (
          <AuthSubtitle data-testid='auth-sas-title'>
            Edit Authentication: <strong>Azure SAS</strong>
          </AuthSubtitle>
        ) : (
          <AuthSubtitle data-testid='auth-sas-title'>
            Authenticate <strong>Azure SAS</strong>
          </AuthSubtitle>
        )}
        <AuthContent>
          <AuthFormContainer>
            <AuthFormContent data-testid='auth-sas-text-fields-container'>
              <AuthTextField
                data-testid='auth-sas-account-name'
                label='Azure Storage account name'
                placeholder='Enter your account name here...'
                helperText={accountError || 'Spelling and case sensitive'}
                value={accountName}
                error={!!accountError}
                onBlur={onBlurAccountName}
                onChange={onChangeAccountName}
              />
              <AuthTextField
                data-testid='auth-sas-container-name'
                label='Container Name'
                placeholder='Enter your container name here...'
                helperText={containerError || 'Spelling and case sensitive'}
                value={containerName}
                error={!!containerError}
                onBlur={onBlurContainerName}
                onChange={onChangeContainerName}
              />
              <AuthTextField
                data-testid='auth-sas-sas-token'
                label='Azure SAS (Shared Access Signature) Token'
                placeholder='Paste your Azure SAS token here...'
                helperText={tokenError || 'Spelling and case sensitive'}
                value={sasToken}
                error={!!tokenError}
                onBlur={onBlurSasToken}
                onChange={onChangeSasToken}
              />
            </AuthFormContent>
          </AuthFormContainer>
          <Help onSendHelpMessage={onSendHelpMessage} testID='auth-sas-help' />
        </AuthContent>
      </Container>
      <FooterActions>
        {isSubmitting && <StyledCircularProgress data-testid='auth-sas-progress' size='36px' />}
        <FilledButton data-testid='auth-sas-submit' onClick={onSubmit} disabled={isSubmitDisabled}>
          {isEditMode ? 'Finish' : 'Continue'}
        </FilledButton>
      </FooterActions>
    </>
  );
}

export default AuthSAS;
