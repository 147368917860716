import { Dashboard, View } from '../reducers/dashboards';

export const DASHBOARDS_INITIALIZE_SUCCESS = 'DASHBOARDS_FETCH_SUCCESS';
export const DASHBOARDS_INITIALIZE_FAIL = 'DASHBOARDS_FETCH_FAIL';
export const DASHBOARDS_RESET = 'DASHBOARDS_RESET';
export const DASHBOARDS_SET_VIEWS = 'DASHBOARDS_SET_VIEWS';
export const DASHBOARDS_SET_DASHBOARDS = 'DASHBOARDS_SET_DASHBOARDS';
export const DASHBOARDS_SET_DATE_RANGE = 'DASHBOARDS_SET_DATE_RANGE';
export const DASHBOARDS_SET_STATUS = 'DASHBOARDS_SET_STATUS';
export const DASHBOARDS_SET_ABORT_CONTROLLER = 'DASHBOARDS_SET_ABORT_CONTROLLER';

interface IDashboardsSetStatus {
  status: string;
}
export const setDashboardsStatus = ({ status }: IDashboardsSetStatus) => ({
  type: DASHBOARDS_SET_STATUS,
  payload: {
    status,
  },
});

interface IDashboardsInitializeSuccess {
  views: View[];
  dashboards: Dashboard[];
}
export const dashboardsInitializeSuccess = ({ views, dashboards }: IDashboardsInitializeSuccess) => ({
  type: DASHBOARDS_INITIALIZE_SUCCESS,
  payload: {
    views,
    dashboards,
  },
});

interface IDashboardsInitializeFail {
  error: string;
}
export const dashboardsInitializeFail = ({ error }: IDashboardsInitializeFail) => ({
  type: DASHBOARDS_INITIALIZE_FAIL,
  payload: {
    error,
  },
});

interface IDashboardsSetViews {
  views: View[];
}
export const dashboardsSetViews = ({ views }: IDashboardsSetViews) => ({
  type: DASHBOARDS_SET_VIEWS,
  payload: {
    views,
  },
});

interface IDashboardsSetDashboards {
  dashboards: Dashboard[];
}
export const dashboardsSetDashboards = ({ dashboards }: IDashboardsSetDashboards) => ({
  type: DASHBOARDS_SET_DASHBOARDS,
  payload: {
    dashboards,
  },
});

interface IDashboardsSetDateRange {
  dateRange: any;
}
export const dashboardsSetDateRange = ({ dateRange }: IDashboardsSetDateRange) => ({
  type: DASHBOARDS_SET_DATE_RANGE,
  payload: {
    dateRange,
  },
});

export const dashboardsReset = () => ({
  type: DASHBOARDS_RESET,
});

interface IDashboardsSetAbortController {
  abortController: AbortController;
}
export const dashboardsSetAbortController = ({ abortController }: IDashboardsSetAbortController) => ({
  type: DASHBOARDS_SET_ABORT_CONTROLLER,
  payload: {
    abortController,
  },
});
