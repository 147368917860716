import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, makeStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import WidgetsGroupMenu from './WidgetsGroupMenu';
import WidgetsGroupLayout from './WidgetsGroupLayout';
import { settingsToggleWidgetGroupState } from '../../store/actions/settings';
import { DEFAULT_GROUP_TITLE } from '../navigation-panel/CreateWidgetsDialog/lib';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { dashboardWidgetGroupUpdate } from '../../store/thunks/dashboard';

const useClasses = makeStyles(() => ({
  root: {
    borderRadius: 'var(--border-radius)',
    padding: '16px',
    marginBottom: '16px',
    backgroundColor: 'white',
  },
  arrow: {
    cursor: 'pointer',
    fontSize: 30,
    float: 'left',
  },
  simpleContainer: {
    borderRadius: 'var(--border-radius)',
    marginBottom: '16px',
    backgroundColor: 'white',
  },
}));

function WidgetsGroup({ data, index }) {
  const dispatch = useDispatch();
  const classes = useClasses();
  const { isDefault, isOverview, collapsed: isCollapsed, originalTitle, title } = data;
  const storedGroupState = useSelector((state) => state.settings.groupsState);
  const { translationUsed } = useSelector((state) => state.app);

  const storedWidgetInfo = useMemo(() => {
    return storedGroupState.find((gs) => gs.groupID === data.id);
  }, [storedGroupState]);
  const [isOpened, setIsOpened] = useState(() => {
    return storedWidgetInfo ? !storedWidgetInfo.isCollapsed : isDefault || isOverview || !isCollapsed;
  });

  const displayTitle = translationUsed || originalTitle.length === 0 ? title : originalTitle;
  const ArrowIcon = isOpened ? KeyboardArrowDownIcon : KeyboardArrowRightIcon;

  const groupTitle = title.length === 0 ? DEFAULT_GROUP_TITLE : title;

  useEffect(() => {
    if (storedWidgetInfo) {
      setIsOpened(!storedWidgetInfo.isCollapsed);
    }
  }, [storedWidgetInfo]);

  function handleLayoutChange(layout, save) {
    dispatch(dashboardWidgetGroupUpdate({ ...data, layout }, save));
  }

  function handleToggleCollapse() {
    eventsTracker.track(EVENTS.GROUP_TOGGLE, {
      'Group Name': groupTitle,
      Visible: !isOpened,
    });
    dispatch(settingsToggleWidgetGroupState(data.id, !isOpened));
    if (!isOpened) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    }
  }

  return isOverview ? (
    <div className={classes.simpleContainer}>
      <WidgetsGroupLayout
        groupId={data.id}
        layout={data.layout}
        onLayoutChange={handleLayoutChange}
        withoutMargin={true}
        groupTitle={title}
      />
    </div>
  ) : (
    <Grid container className={classes.root} data-widget-group={data.id} data-testid='widget-group' data-title={data.title}>
      {!isDefault && (
        <Grid item xs={12}>
          <Grid container direction='row' wrap='nowrap'>
            <Grid item xs='auto'>
              <ArrowIcon onClick={handleToggleCollapse} data-control='group-collapse' className={classes.arrow} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Box
                component='h4'
                data-tooltip-id='base-tooltip'
                data-tooltip-content={displayTitle}
                style={{ marginTop: '5px', marginBottom: isOpened ? '' : '5px' }}
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                overflow='hidden'
              >
                {displayTitle}
              </Box>
            </Grid>
            <Grid item xs='auto'>
              <WidgetsGroupMenu id={data.id} title={title} originalTitle={originalTitle} index={index} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {isOpened && (
        <Grid item style={{ width: '100%' }} data-content='layout'>
          <WidgetsGroupLayout groupId={data.id} layout={data.layout} onLayoutChange={handleLayoutChange} groupTitle={groupTitle} />
        </Grid>
      )}
    </Grid>
  );
}

export default WidgetsGroup;
