import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand-icon.svg';
import TextField from '../../UI/TextField';

export const CustomSelect = styled(Select)`
  &.MuiInputBase-root {
    background-color: var(--white);
    font-size: 14px;
    min-width: 160px;
    height: 100%;
    border-radius: var(--double-border-radius);
  }

  &.MuiSelect-root {
    background-color: var(--white);
  }

  .MuiSelect-select {
    padding: 3.5px 24px 3.5px 6px;
    &:focus {
      background-color: var(--white);
    }
  }

  fieldset {
    border-color: var(--medium-gray);
  }
`;
export const CompactMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 6px;
    font-size: 12px;
  }
`;

export const CustomAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    border-radius: var(--double-border-radius);
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    padding: 0;
    width: 100%;

    & .MuiTextField-root {
      height: 100%;
      border-radius: var(--double-border-radius);

      & .MuiInputBase-root {
        height: 100%;
      }
    }
  }

  &&& .MuiAutocomplete-paper {
    color: red;
    font-size: 12px;
  }
`;

export const FilteringTextField = styled(TextField)`
  background-color: var(--white);

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input {
    font-size: 12px;
    padding: 6px 0 6px 2px;
    box-sizing: border-box;
    height: 100%;
  }

  &&& .MuiInputBase-root {
    flex-wrap: nowrap;
    padding: 0;
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 6px;
  }

  fieldset {
    border-color: var(--medium-gray);
    border-radius: var(--double-border-radius);
  }
`;

export const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
`;

export const ToggleItem = styled.div<{ $isSelected: boolean }>`
  height: 40px;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--white)' : 'none')};

  :nth-child(1) {
    border-top-left-radius: var(--double-border-radius);
    border-bottom-left-radius: var(--double-border-radius);
  }

  :nth-last-child(1) {
    border-top-right-radius: var(--double-border-radius);
    border-bottom-right-radius: var(--double-border-radius);
  }

  :nth-child(even) {
    border-left: 1px solid var(--medium-gray);
  }
`;

export const ToggleItemBig = styled(ToggleItem)`
  padding: 6px 12px;
`;

export const SortingDirections = styled.div`
  flex-grow: 0;
`;

export const SortDirectionDownButton = styled(ExpandIcon)<{
  $isSelected: boolean;
}>`
  width: 24px;
  height: 24px;
  fill: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'var(--gray-disabled)')};
`;

export const SortDirectionUpButton = styled(SortDirectionDownButton)`
  transform: rotateX(180deg);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const RowItem = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BurgerMenuContainer = styled.div`
  border-radius: var(--double-border-radius);
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow:
    0px 1px 2px 0px rgba(15, 12, 12, 0.3),
    0px 2px 6px 2px rgba(15, 12, 12, 0.15);
`;

export const BurgerButtonItem = styled.div`
  display: flex;
  height: 43px;
  padding: 12px 16px;
  align-items: center;
  align-content: center;
  gap: 1em;
  cursor: pointer;
  &:hover {
    background-color: var(--light-gray);
  }
`;
