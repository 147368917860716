import React from 'react';

import UploadDropdownSelect from './UploadDropdownSelect';

interface IUploadDropDownSelectContainer {
  selectedDashboards: string[];
  open: boolean;
  bubbleColor: string;
  anchorOriginVertical: number | 'top' | 'center' | 'bottom';
  transformOriginVertical: number | 'top' | 'center' | 'bottom';
  onCheckboxChange: (value: string) => void;
  onOpen: () => void;
  onClose: () => void;
  UploadDropdownContent: () => JSX.Element;
}

function UploadDropdownSelectContainer({
  selectedDashboards,
  open,
  bubbleColor,
  anchorOriginVertical,
  transformOriginVertical,
  onCheckboxChange,
  onOpen,
  onClose,
  UploadDropdownContent,
}: IUploadDropDownSelectContainer) {
  const onBubbleDeleteClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const setBubbleColors = (color: string) => {
    let bubbleTextColor = 'black';
    let bubbleTextWeight = '400';
    let bubbleTextHover = 'dark-gray';

    if (color === 'beehive-purple') {
      bubbleTextColor = 'dark-purple';
      bubbleTextWeight = '400';
      bubbleTextHover = 'dark-purple';
    } else if (color === 'grey') {
      bubbleTextWeight = '700';
    }

    return { bubbleTextColor, bubbleTextWeight, bubbleTextHover };
  };

  const { bubbleTextColor, bubbleTextWeight, bubbleTextHover } = setBubbleColors(bubbleColor);

  return (
    <UploadDropdownSelect
      selectedDashboards={selectedDashboards}
      open={open}
      bubbleColor={bubbleColor}
      bubbleTextColor={bubbleTextColor}
      bubbleTextWeight={bubbleTextWeight}
      bubbleTextHover={bubbleTextHover}
      anchorOriginVertical={anchorOriginVertical}
      transformOriginVertical={transformOriginVertical}
      onCheckboxChange={onCheckboxChange}
      onOpen={onOpen}
      onClose={onClose}
      onBubbleDeleteClick={onBubbleDeleteClick}
      UploadDropdownContent={UploadDropdownContent}
    />
  );
}

export default UploadDropdownSelectContainer;
