import React, { useRef } from 'react';
import styled from 'styled-components';

import { Label } from '../../../../../types/Label';
import { LABEL_NOT_APPLICABLE } from '../../../utils/lib';
import { ConceptPill, TopicEditButton } from '../../../components/Labels/ConceptPill';
import PercentageBars from '../../../components/Labels/PercentageBars';
import { Filter } from '../../../../../types/Query';

import { Row, RowLeft, RowRight } from './style';

const RowStyled = styled(Row)`
  & .topics-menu-button {
    display: none;
  }
  &:hover .topics-menu-button {
    display: block;
    position: absolute;
    top: 2px;
    right: -24px;
  }
`;

const AnswerItemContainer = styled.div`
  position: relative;
`;

interface ITopicRow {
  label: Label;
  filtersById: {
    [p: string]: Filter;
  };
  selectedFilterIds: string[];
  clickable: boolean;
  disabled: boolean;
  selected: boolean;
  average?: boolean;
  onLabelClick: () => void;
  onMenuClick: (ref: any, label: string) => void;
}

const TopicRow = React.forwardRef<HTMLDivElement, ITopicRow>(
  ({ label, filtersById, selectedFilterIds, clickable, disabled, selected, average, onLabelClick, onMenuClick }, ref) => {
    const moreIconRef = useRef(null);
    const labelText = label.title === LABEL_NOT_APPLICABLE ? 'Not Applicable' : label.displayTitle;
    const showManaging = label.title !== LABEL_NOT_APPLICABLE;
    return (
      <RowStyled data-testid='topic-row-container' data-label={labelText} ref={ref}>
        <RowLeft>
          <AnswerItemContainer>
            <ConceptPill
              text={labelText}
              isHierarchy={false}
              isParent={false}
              isClickable={clickable}
              isDisabled={disabled}
              isSelected={selected}
              onClick={onLabelClick}
            />
            {renderEditButton()}
          </AnswerItemContainer>
        </RowLeft>
        <RowRight>
          <PercentageBars
            dataByFilterId={label.filters}
            selectedFilterIds={selectedFilterIds}
            filtersById={filtersById}
            average={average}
            showSentiment
          />
        </RowRight>
      </RowStyled>
    );

    function renderEditButton() {
      if (!showManaging) {
        return null;
      }

      return (
        <TopicEditButton
          data-testid='topic-row-more'
          className='topics-menu-button'
          ref={moreIconRef}
          onClick={onMenuClick.bind(null, moreIconRef, label.title)}
        />
      );
    }
  },
);

export default TopicRow;
