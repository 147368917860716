import { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import format from 'date-fns/format';

import { TOOLTIP_DATE_FORMAT } from '../lib';
import Chart from '../../../UI/Chart';
import { AVERAGE_POSTFIX, PERIOD } from '../../../../constants';
import { getAverageName, prepareData, VIEW } from './lib';
import styled from 'styled-components';

const TRENDS_MOVING_AVERAGE_WINDOW_SIZE = {
  [PERIOD.DAY]: 7,
  [PERIOD.WEEK]: 4,
  [PERIOD.MONTH]: 3,
  [PERIOD.QUARTER]: 0,
};

// StackedLineChart
const ViewBothIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M2 19.99l7.5-7.51 4 4 7.09-7.97L22 9.92l-8.5 9.56-4-4-6 6.01-1.5-1.5zm1.5-4.5l6-6.01 4 4L22 3.92l-1.41-1.41-7.09 7.97-4-4L2 13.99l1.5 1.5z' />
  </SvgIcon>
);

// TrendingUp
const ViewAverageIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z' />
  </SvgIcon>
);

// ShowChart
const ViewOriginalIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z' />
  </SvgIcon>
);

const VIEW_SETTINGS = {
  [VIEW.ORIGINAL]: {
    icon: ViewOriginalIcon,
    tooltip: 'Values',
  },
  [VIEW.AVERAGE]: {
    icon: ViewAverageIcon,
    tooltip: 'Moving averages',
  },
  [VIEW.BOTH]: {
    icon: ViewBothIcon,
    tooltip: 'Values / Moving averages',
  },
};

const ShowToggleButton = styled('div')`
  display: inline-block;
  position: absolute;
  right: 150px;
  top: 10px;
`;

const TrendsWidgetChart = ({
  trends,
  period,
  isPercentage = false,
  showFilters = false,
  showBreakdown = false,
  handleToggleView = null,
}) => {
  const selectedRef = useRef({});
  const [view, setView] = useState(VIEW.BOTH);

  useEffect(() => {
    if (period === PERIOD.QUARTER) {
      setView(VIEW.ORIGINAL);
    }
  }, [period]);

  const { source, transformers, series, legendData } = prepareData(
    trends,
    isPercentage,
    view,
    TRENDS_MOVING_AVERAGE_WINDOW_SIZE[period],
    showFilters,
    showBreakdown,
  );

  const legend = {
    type: 'scroll',
    top: 0,
    right: 0,
    padding: [5, 5, 40, 5],
    data: legendData,
    orient: 'vertical',
    formatter: (name) => {
      const [groupName, , itemName] = name.split('---');
      return itemName === undefined ? groupName : itemName;
    },
    borderWidth: 0,
    selected: selectedRef.current,
    textStyle: {
      width: 100,
      backgroundColor: 'var(--white)', // @HACK!: width isn't applied when there is no backgroundColor
      overflow: 'breakAll',
    },
  };

  const yAxis = {
    type: 'value',
  };
  if (isPercentage) {
    const trendsMax = trends.reduce((max, trend) => {
      const values = trend.data.map((item) => item.percentage);
      const trendMax = Math.max(...values);
      return Math.max(max, trendMax);
    }, 0);
    yAxis.max = Math.round(trendsMax) + 10;
  }

  const option = {
    animation: false,
    dataset: [
      {
        dimensions: ['date', 'number', 'value', 'percentage', 'name', 'filterName', 'bucket', 'id'],
        source,
      },
      ...transformers,
    ],
    tooltip: {
      show: true,
      formatter: (params) => {
        const data = params.dimensionNames.reduce((acc, dimensionName, index) => {
          return { ...acc, [dimensionName]: params.value[index] };
        }, {});

        const lines = [];
        if (showFilters) {
          lines.push(`<strong>${data.filterName}</strong>`);
        }
        if (params.seriesName.includes(AVERAGE_POSTFIX)) {
          lines.push(`${TRENDS_MOVING_AVERAGE_WINDOW_SIZE[period]}-${period}s moving average`);
        }
        if (data.bucket !== null) {
          lines.push(`<strong>${data.bucket}</strong>`);
        }
        lines.push(format(data.date, TOOLTIP_DATE_FORMAT));
        lines.push(`${data.name}: ${data.value} (${data.percentage.toFixed(2)}%)`);
        return lines.join('<br/>');
      },
    },
    grid: {
      top: 10,
      left: 60,
      right: 150,
      bottom: 60,
    },
    xAxis: {
      type: 'time',
      splitLine: {
        show: true,
      },
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis,
    series,
    legend,
  };

  function handleClick() {
    let newView;
    switch (view) {
      case VIEW.BOTH: {
        newView = VIEW.ORIGINAL;
        break;
      }
      case VIEW.ORIGINAL: {
        newView = VIEW.AVERAGE;
        break;
      }
      default:
        newView = VIEW.BOTH;
    }
    setView(newView);
    handleToggleView((toggleTooltip = VIEW_SETTINGS[newView].tooltip));
  }

  const TrendsEventHandlers = {
    legendselectchanged: (params, chart) => {
      const [groupName, color, itemName] = params.name.split('---');
      const type = params.selected[params.name] ? 'legendSelect' : 'legendUnSelect';
      selectedRef.current = { ...selectedRef.current, ...params.selected };
      if (itemName === undefined) {
        const prefix = `${groupName}---${color}---`;
        Object.keys(params.selected)
          .filter((name) => name.startsWith(prefix))
          .forEach((name) => {
            chart.dispatchAction({ type, name });
            chart.dispatchAction({ type, name: getAverageName(name) });
            selectedRef.current[name] = params.selected[params.name];
            selectedRef.current[getAverageName(name)] = params.selected[params.name];
          });
      } else {
        chart.dispatchAction({ type, name: getAverageName(params.name) });
        selectedRef.current[getAverageName(params.name)] = params.selected[params.name];
      }
      return false;
    },
  };

  const averageDisabled = period === PERIOD.QUARTER;

  let ToggleIcon = ViewOriginalIcon;
  let toggleTooltip = 'Select another time interval to see average';
  if (!averageDisabled) {
    ToggleIcon = VIEW_SETTINGS[view].icon;
    toggleTooltip = VIEW_SETTINGS[view].tooltip;
  }

  return (
    <Box height='100%' position='relative'>
      <Chart option={option} eventHandlers={TrendsEventHandlers} style={{ height: '100%' }} />
      <ShowToggleButton data-tooltip-id='base-tooltip' data-tooltip-content={toggleTooltip}>
        <IconButton onClick={handleClick} disableRipple color='primary' disabled={averageDisabled}>
          <ToggleIcon fontSize='small' />
        </IconButton>
      </ShowToggleButton>
    </Box>
  );
};

export default TrendsWidgetChart;
