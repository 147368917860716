import styled from 'styled-components';

import { Sentiment } from '../../../types/DataSource';
import { SENTIMENT, SENTIMENT_TITLE } from '../../../models/Response';

const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 8px;
`;

const SentimentLine = styled.div<{
  $color: string;
  $width: string;
}>`
  background-color: ${({ $color }) => $color};
  width: ${({ $width }) => $width};
  height: 8px;
  border-radius: 8px;
`;

interface ILabelSentiment {
  sentiment: Sentiment;
  score: number;
  count?: number;
}
function InlineSentiment({ sentiment, score, count }: ILabelSentiment) {
  let backgroundColor;
  let title;
  switch (sentiment) {
    case Sentiment.Negative:
      backgroundColor = 'var(--sentiment-orange)';
      title = SENTIMENT_TITLE[SENTIMENT.NEGATIVE];
      break;
    case Sentiment.Positive:
      backgroundColor = 'var(--sentiment-green)';
      title = SENTIMENT_TITLE[SENTIMENT.POSITIVE];
      break;
    case Sentiment.Neutral:
      backgroundColor = 'var(--sentiment-yellow)';
      title = SENTIMENT_TITLE[SENTIMENT.NEUTRAL];
      break;
    default:
      backgroundColor = 'var(--sentiment-gray)';
      title = SENTIMENT_TITLE[SENTIMENT.UNKNOWN];
  }
  const pct = Math.abs(score) * 100;
  const width = pct + '%';
  const sentimentPct = Math.round(pct);
  const sentimentCount = count ? `(${count})` : '';
  title = `${title}: ${sentimentPct}% ${sentimentCount}`;

  return (
    <Container data-testid='inline-sentiment-container' data-tooltip-id='base-tooltip' data-tooltip-content={title}>
      <SentimentLine data-testid='inline-sentiment-line' $color={backgroundColor} $width={width} />
    </Container>
  );
}

export default InlineSentiment;
