import { JsonModel } from '../../../models/JsonModel';
import { createInValuesRule, EMPTY_FILTER_QUERY } from '../../../components/FilterForm/lib';

export class SegmentModel extends JsonModel {
  constructor(title = '', total = 0, properties = [], traits = [], weighted = false) {
    super();

    this.title = title;
    this.total = total;
    this.weighted = weighted;
    /**
     * @type {SegmentItemModel[]}
     */
    this.properties = properties;
    this.traits = traits;
  }

  fromJson(_json) {
    super.fromJson(_json);

    this.total = parseInt(_json.total, 10) || 0;
    this.title = _json.title || '';
    this.properties = _json.properties?.map(SegmentItemModelFactory) || [];
    this.traits = _json.traits?.map(SegmentItemModelFactory) || [];
    this.weighted = _json.weighted;
    return this;
  }

  makeQuery() {
    const rules = [];
    for (let property of this.properties) {
      for (let value of property.values) {
        const rule = createInValuesRule(property.id, [value.name]);
        rules.push(rule);
      }
    }
    return { ...EMPTY_FILTER_QUERY, rules };
  }
}

export class SegmentItemModel extends JsonModel {
  constructor(id = '', title = '', values = [], displayTitle = '') {
    super();

    this.id = id;
    this.title = title;
    this.displayTitle = displayTitle;

    /**
     *
     * @type {SegmentItemValueModel[]}
     */
    this.values = values;
  }

  fromJson(_json) {
    super.fromJson(_json);

    this.id = _json.id;
    this.title = _json.title || '';
    this.displayTitle = _json.displayTitle || '';
    this.values = _json.values?.map(SegmentItemValueModelFactory) || [];
    return this;
  }
}

export class SegmentItemValueModel extends JsonModel {
  constructor(name, size, percent, delta, displayName) {
    super();

    this.name = name;
    this.displayName = displayName;
    this.size = size;
    this.percent = percent;
    this.delta = delta;
  }

  fromJson(_json) {
    super.fromJson(_json);

    this.name = _json['name'];
    this.displayName = _json['displayName'];
    this.size = parseInt(_json['size']) || 0;
    this.percent = parseFloat(_json['pct']) || 0;
    this.delta = parseFloat(_json['diff']) || 0;
    return this;
  }

  toJsonString() {
    return JSON.stringify({
      name: this.name,
      size: this.size,
      pct: this.percent,
      diff: this.delta,
    });
  }
}

export const SegmentModelFactory = (_json) => {
  return new SegmentModel().fromJson(_json);
};
export const SegmentItemModelFactory = (_json) => {
  return new SegmentItemModel().fromJson(_json);
};
export const SegmentItemValueModelFactory = (_json) => {
  return new SegmentItemValueModel().fromJson(_json);
};
