import { useDispatch, useSelector } from 'react-redux';

import SidebarItem from './SidebarItem';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { toggleWeightedMetricsUsage } from '../../../store/actions/actionsCreators';
import SvgIcon from '@material-ui/core/SvgIcon';

// https://mui.com/material-ui/material-icons/?query=bala&selected=Balance
const BalanceIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M13 7.83c.85-.3 1.53-.98 1.83-1.83H18l-3 7c0 1.66 1.57 3 3.5 3s3.5-1.34 3.5-3l-3-7h2V4h-6.17c-.41-1.17-1.52-2-2.83-2s-2.42.83-2.83 2H3v2h2l-3 7c0 1.66 1.57 3 3.5 3S9 14.66 9 13L6 6h3.17c.3.85.98 1.53 1.83 1.83V19H2v2h20v-2h-9V7.83zM20.37 13h-3.74l1.87-4.36L20.37 13zm-13 0H3.63L5.5 8.64 7.37 13zM12 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z' />
  </SvgIcon>
);

const SidebarItemWeightedMetrics = () => {
  const weightedMetricsUsed = useSelector((state) => state.app.weightedMetricsUsed);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const dispatch = useDispatch();

  const handleClick = () => {
    const weighted = !weightedMetricsUsed;
    eventsTracker.track(EVENTS.WEIGHTED_METRICS, {
      'Weighted Metrics State': weighted,
      'Breakdown Applied': !!breakdownFilter,
    });
    dispatch(toggleWeightedMetricsUsage(weighted));
  };

  return <SidebarItem icon={<BalanceIcon />} onClick={handleClick} selected={weightedMetricsUsed} tooltip='Weighted Metrics' />;
};

export default SidebarItemWeightedMetrics;
