import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDownOutlined } from '@material-ui/icons';
import styled from 'styled-components';

import { SEGMENT_ID_ANY } from '../../constants';
import { SegmentID } from '../../compare';

const SegmentNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SegmentColor = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-color: ${(props) => props.color};
`;

const SegmentNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: inherit;

  svg {
    cursor: pointer;
  }
`;

const SegmentName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ISegmentProps {
  filter: any;
}
const Segment: React.FC<ISegmentProps> = ({ filter }) => {
  return (
    <SegmentNameContainer>
      <SegmentColor color={filter.color} /> {filter.name}
    </SegmentNameContainer>
  );
};

interface ICompareTableSegmentSelectProps {
  filters: any[];
  selectedFilterIds: SegmentID[];
  segmentID: SegmentID;
  disabledSegmentID: SegmentID;
  onSelect: (id: SegmentID) => void;
}

const CompareTableSegmentSelect: React.FC<ICompareTableSegmentSelectProps> = ({
  filters,
  selectedFilterIds,
  segmentID,
  disabledSegmentID,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const availableSegments = filters.filter(
    (filter) => selectedFilterIds.includes(filter.id) && (disabledSegmentID === SEGMENT_ID_ANY || disabledSegmentID !== filter.id),
  );
  const title = segmentID === SEGMENT_ID_ANY ? 'Segment' : filters.find((filter) => filter.id === segmentID)?.name;

  function handleClick(event: { currentTarget: Element }) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function itemClickHandler(id: SegmentID) {
    onSelect(id);
    handleClose();
  }

  return (
    <>
      <SegmentNameWrapper>
        <SegmentName>{title}</SegmentName>
        <ArrowDropDownOutlined fontSize='small' onClick={handleClick} />
      </SegmentNameWrapper>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem value={SEGMENT_ID_ANY} onClick={itemClickHandler.bind(null, SEGMENT_ID_ANY)}>
          Any
        </MenuItem>
        {availableSegments.map((filter) => (
          <MenuItem key={filter.id} value={filter.id} onClick={itemClickHandler.bind(null, filter.id)}>
            <Segment filter={filter} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CompareTableSegmentSelect;
