import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

import CompareTableRow, { CustomTableCell } from './CompareTableRow';
import CompareTablePagination from './CompareTablePagination';
import { ICompareRow, SegmentID, SegmentsMinMax, SortDirection } from '../../compare';
import { SEGMENT_ID_ANY, SortField } from '../../constants';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { setPage, setRowsPerPage } from '../../store/actions';
import CompareTableSegmentSelect from './CompareTableSegmentSelect';
import { StyledCheckbox } from '../UI';
import { getUniqIds } from '../../lib/compareTable';
import HeaderTitleWithTooltip from './HeaderTitleWithTooltip';
import { ICompareState } from '../../store/reducer';

const StyledTable = styled(Table)`
  table-layout: fixed;
`;

const TableCellHeader = styled(CustomTableCell)<{ $gray?: boolean }>`
  border-bottom-color: #cacaca;
  ${({ $gray }) => $gray && 'background-color: #e0e0e0'}
`;

const TableSubHeaderRow = styled(TableRow)`
  border-bottom: 2px solid #c9c9c9;
`;

const TableCellMainHeader = styled(TableCellHeader)`
  font-weight: bold;
`;

interface ICompareTableProps {
  rows: ICompareRow[];
  filtersById: any;
  sortBy: SortField;
  direction: SortDirection;
  segmentsMinMax: SegmentsMinMax;
  filters: any[];
  selectedFilterIds: string[];
  selectedRowIDs: string[];
  onSegmentsMinMaxChange: (segmentsMinMax: SegmentsMinMax) => void;
  onSortClick: (field: SortField) => void;
  onSelectedRowIDsChange: (ids: string[]) => void;
}

export const CompareTable: React.FC<ICompareTableProps> = ({
  rows,
  filtersById,
  sortBy,
  direction,
  segmentsMinMax,
  filters,
  selectedFilterIds,
  selectedRowIDs,
  onSortClick,
  onSegmentsMinMaxChange,
  onSelectedRowIDsChange,
}) => {
  const { page, rowsPerPage } = useSelector((state: any) => state.compare as ICompareState).table;
  const prevRowsLengthRef = useRef(rows.length);
  const dispatch = useDispatch();

  if (prevRowsLengthRef.current !== rows.length) {
    dispatch(setPage(0));
    prevRowsLengthRef.current = rows.length;
  }

  function handleChangePage(event: any, newPage: number) {
    eventsTracker.track(EVENTS.COMPARED_TABLE_PAGINATE, {
      'Pagination Direction': page > newPage ? 'Left' : 'Right',
    });
    dispatch(setPage(newPage));
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    eventsTracker.track(EVENTS.COMPARED_ROWS_PER_PAGE_SELECT, {
      'Rows Per Page Number': newRowsPerPage,
    });
    dispatch(setRowsPerPage(newRowsPerPage));
    dispatch(setPage(0));
  }

  function handleSegmentSelect(field: keyof SegmentsMinMax, id: SegmentID) {
    eventsTracker.track(EVENTS.SEGMENT_DROPDOWN_FILTER_APPLIED, {
      'Left/Right column': field === 'max' ? 'Left' : 'Right',
      'Segment Selected': id === SEGMENT_ID_ANY ? 'Any' : filtersById[id].name,
    });
    onSegmentsMinMaxChange({ ...segmentsMinMax, [field]: id });
  }

  const ids: string[] = useMemo(() => getUniqIds(rows), [rows]);

  function updateSelectedIds(ids: string[]) {
    onSelectedRowIDsChange(ids);
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      updateSelectedIds(ids);
      return;
    }
    updateSelectedIds([]);
  }

  const handleClick = (row: ICompareRow) => {
    const id = `${row.concept}-${row.dataSourceID}`;
    const selectedIndex = selectedRowIDs.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRowIDs, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRowIDs.slice(1));
    } else if (selectedIndex === selectedRowIDs.length - 1) {
      newSelected = newSelected.concat(selectedRowIDs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedRowIDs.slice(0, selectedIndex), selectedRowIDs.slice(selectedIndex + 1));
    }

    updateSelectedIds(newSelected);
  };

  const count = rows.length;

  const numSelected = selectedRowIDs.length;
  const rowCount = ids.length;

  return (
    <>
      <TableContainer component={Paper}>
        <StyledTable size='small'>
          <TableHead>
            <TableRow>
              <TableCellMainHeader padding='checkbox' $gray>
                <StyledCheckbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCellMainHeader>
              <TableCellMainHeader align='right' style={{ width: '55px' }}>
                #
              </TableCellMainHeader>
              <TableCellMainHeader colSpan={2} $gray>
                <HeaderTitleWithTooltip
                  title='Data source'
                  tooltip='Data source refers to a specific question or item within a data collection.'
                />
              </TableCellMainHeader>
              <TableCellMainHeader>
                <TableSortLabel
                  active={sortBy === SortField.CONCEPT}
                  direction={sortBy === SortField.CONCEPT ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.CONCEPT)}
                >
                  <HeaderTitleWithTooltip
                    title='Value'
                    tooltip='Value is either the AI-classified concept or closed-ended answer associated with a specific data source.'
                  />
                </TableSortLabel>
              </TableCellMainHeader>
              <TableCellMainHeader colSpan={6} $gray>
                <HeaderTitleWithTooltip
                  title='Compared Segments'
                  tooltip='Compared Segments refers to the different filtered subsets of data that have been selected for comparison.'
                />
              </TableCellMainHeader>
              <TableCellMainHeader colSpan={2}>
                <HeaderTitleWithTooltip
                  title='Difference'
                  tooltip='Difference refers to both the absolute and percent differences between the two Segments being compared.'
                />
              </TableCellMainHeader>
              <TableCellMainHeader style={{ width: '80px' }} $gray>
                <TableSortLabel
                  active={sortBy === SortField.P_VALUE}
                  direction={sortBy === SortField.P_VALUE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.P_VALUE)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <HeaderTitleWithTooltip
                    title='P-value'
                    tooltip='P-value is used as a measure of statistical significance. ≤ 0.05 is a common threshold set in research.'
                  />
                </TableSortLabel>
              </TableCellMainHeader>
            </TableRow>
            <TableSubHeaderRow>
              <TableCellHeader $gray />
              <TableCellHeader />
              <TableCellHeader $gray>
                <TableSortLabel
                  active={sortBy === SortField.DATA_SOURCE_TITLE}
                  direction={sortBy === SortField.DATA_SOURCE_TITLE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.DATA_SOURCE_TITLE)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Name
                </TableSortLabel>
              </TableCellHeader>
              <TableCellHeader align='right' style={{ width: '90px' }} $gray>
                <TableSortLabel
                  active={sortBy === SortField.DATA_SOURCE_TOTAL}
                  direction={sortBy === SortField.DATA_SOURCE_TOTAL ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.DATA_SOURCE_TOTAL)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Answered
                </TableSortLabel>
              </TableCellHeader>
              <TableCellHeader />
              <TableCellHeader style={{ width: '180px' }} $gray>
                <CompareTableSegmentSelect
                  filters={filters}
                  segmentID={segmentsMinMax.max}
                  disabledSegmentID={segmentsMinMax.min}
                  selectedFilterIds={selectedFilterIds}
                  onSelect={handleSegmentSelect.bind(null, 'max')}
                />
              </TableCellHeader>
              <TableCellHeader align='right' style={{ width: '60px' }} $gray>
                <TableSortLabel
                  active={sortBy === SortField.FILTER_MAX_VALUE}
                  direction={sortBy === SortField.FILTER_MAX_VALUE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.FILTER_MAX_VALUE)}
                >
                  Total
                </TableSortLabel>
              </TableCellHeader>
              <TableCellHeader align='right' style={{ width: '60px' }} $gray>
                <TableSortLabel
                  active={sortBy === SortField.FILTER_MAX_PERCENTAGE}
                  direction={sortBy === SortField.FILTER_MAX_PERCENTAGE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.FILTER_MAX_PERCENTAGE)}
                >
                  (%)
                </TableSortLabel>
              </TableCellHeader>

              <TableCellHeader style={{ width: '180px' }} $gray>
                <CompareTableSegmentSelect
                  filters={filters}
                  segmentID={segmentsMinMax.min}
                  disabledSegmentID={segmentsMinMax.max}
                  selectedFilterIds={selectedFilterIds}
                  onSelect={handleSegmentSelect.bind(null, 'min')}
                />
              </TableCellHeader>
              <TableCellHeader align='right' style={{ width: '60px' }} $gray>
                <TableSortLabel
                  active={sortBy === SortField.FILTER_MIN_VALUE}
                  direction={sortBy === SortField.FILTER_MIN_VALUE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.FILTER_MIN_VALUE)}
                >
                  Total
                </TableSortLabel>
              </TableCellHeader>
              <TableCellHeader align='right' style={{ width: '60px' }} $gray>
                <TableSortLabel
                  active={sortBy === SortField.FILTER_MIN_PERCENTAGE}
                  direction={sortBy === SortField.FILTER_MIN_PERCENTAGE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.FILTER_MIN_PERCENTAGE)}
                >
                  (%)
                </TableSortLabel>
              </TableCellHeader>

              <TableCellHeader align='right' style={{ width: '90px' }}>
                <TableSortLabel
                  active={sortBy === SortField.DIFFERENCE_ABSOLUTE}
                  direction={sortBy === SortField.DIFFERENCE_ABSOLUTE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.DIFFERENCE_ABSOLUTE)}
                >
                  Absolute
                </TableSortLabel>
              </TableCellHeader>
              <TableCellHeader align='right' style={{ width: '60px' }}>
                <TableSortLabel
                  active={sortBy === SortField.DIFFERENCE_PERCENTAGE}
                  direction={sortBy === SortField.DIFFERENCE_PERCENTAGE ? direction : 'desc'}
                  onClick={() => onSortClick(SortField.DIFFERENCE_PERCENTAGE)}
                >
                  (%)
                </TableSortLabel>
              </TableCellHeader>
              <TableCellHeader $gray />
            </TableSubHeaderRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <CompareTableRow
                    checked={selectedRowIDs.includes(`${row.concept}-${row.dataSourceID}`)}
                    filtersById={filtersById}
                    key={row.id}
                    row={row}
                    onClick={handleClick}
                  />
                ))
            ) : (
              <TableRow hover>
                <TableCell size='medium' align='center' colSpan={14}>
                  No data to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <CompareTablePagination
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default CompareTable;
