import Grid from '@material-ui/core/Grid';

import { TextOverflowEllipsis } from '../../UI';
import { FILTER_ID_ALL } from '../../../constants';
import FiltersBars from './FiltersBars';

const BreakdownFiltersBars = ({ breakdownData, label, selectedFilterIds, filtersById, average }) => {
  if (!breakdownData || !breakdownData.has(label)) {
    return null;
  }

  const dataByLabelByBucket = new Map();
  for (let [label, labelData] of breakdownData.entries()) {
    dataByLabelByBucket.set(label, new Map());
    for (let [bucket, bucketData] of labelData.entries()) {
      const dataByFilterId = bucketData.reduce((acc, data) => {
        if (filtersById[data.filterId] && (selectedFilterIds.includes(data.filterId) || selectedFilterIds.includes(FILTER_ID_ALL))) {
          return {
            ...acc,
            [data.filterId]: {
              number: data.value,
              realPercent: data.percentage,
            },
          };
        }
        return acc;
      }, {});
      dataByLabelByBucket.get(label).set(bucket, dataByFilterId);
    }
  }

  return (
    <Grid container direction='column'>
      {[...breakdownData.get(label).keys()].map((bucket) => {
        return (
          <Grid item key={bucket} xs={12} style={{ marginBottom: '10px' }}>
            <Grid container direction='row' alignItems='center'>
              <Grid item xs={4} zeroMinWidth style={{ fontSize: '0.8em' }}>
                <TextOverflowEllipsis data-tooltip-id='base-tooltip' data-tooltip-content={bucket}>
                  {bucket}
                </TextOverflowEllipsis>
              </Grid>
              <Grid item xs={8}>
                <FiltersBars
                  dataByFilterId={dataByLabelByBucket.get(label).get(bucket)}
                  average={average}
                  filtersById={filtersById}
                  selectedFilterIds={selectedFilterIds}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BreakdownFiltersBars;
