import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import palette from '../components/tools/palette';
import { makeStyles } from '@material-ui/core';

export const ResponseChip = styled(Chip)`
  &.MuiChip-root {
    cursor: pointer;
    background-color: ${palette.responseChipColor};
    border-radius: 3px;
    margin-right: 4px;
    margin-top: 4px;
    font-weight: ${(props) => (props.selected ? 'bolder' : 'normal')};
    position: relative;
  }
`;

export const LabelsHierarchySeparator = styled('span')`
  &::before {
    content: '•';
    font-size: 24px;
    vertical-align: bottom;
    color: #9f90ff;
    line-height: 24px;
    padding: 0 6px 0 2px;
  }
`;

export const tooltipClasses = makeStyles((theme) => ({
  tooltip: {
    fontSize: 12,
    backgroundColor: '#000',
  },
  arrow: {
    color: '#000',
  },
}));
