import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
// TODO do not use Grid
import { Grid } from '@material-ui/core';

import { Config } from '../../../../types/Config';
import StatusPanelContainer from '../StatusPanel/StatusPanelContainer';
import { ReactComponent as SparkleIcon } from '../../../../assets/icons/sparkle-icon.svg';
import AIInsightsContainer from './AIInsights/AIInsightsContainer';
import SegmentsRowContainer from '../../components/SegmentsRow/SegmentsRowContainer';
import { WidgetDataSource } from '../../../../types/DataSource';
import { Filter } from '../../../../types/Query';
import { Label } from '../../../../types/Label';
import { FETCH_STATUS, FETCH_STATUS_TYPE } from '../../../../constants';
import { DashboardSentimentSummariesSentiment } from '../../../../store/reducers/dashboard/types';
import { TotalsByDataSourcesAndFiltersIDS } from '../../OpenEndedWidget/Data/OpenWidgetData';
import LoadingOverlay from '../../../UI/LoadingOverlay';
import { SortingQuery } from '../../../../types/SortingQuery';
import { ResponsesCellsStateContextProvider } from '../../components/Responses/ResponsesCellsStateContext';

import {
  ContainerTitle,
  FilteringButtonsAndContentContainer,
  InsightsHeader,
  InsightsTitleContainer,
  ItemContainer,
  ItemTitleContainer,
  WidgetContainer,
} from './style';
import { Concept } from '../../../../store/reducers/aiInsights';
import { ItemSecondaryTitle } from '../../OpenEndedWidget/Data/style';
import { TextOverflowEllipsis } from '../../../UI';
import RefreshAIContainer from '../../components/RefreshAI/RefreshAIContainer';

import TopicsContainer from './Topics/TopicsContainer';
import ConversationContainer from './Conversation/ConversationContainer';

const SparkleIconStyled = styled(SparkleIcon)`
  width: 13px;
  height: 14px;
`;

interface IInterviewWidgetData {
  // TODO:
  analyzedAnswers: number;
  areFiltersEmpty: boolean;
  config: Config;
  dataSource: WidgetDataSource;
  dataSourceID: string;
  widgetID: string;
  filters: Filter[];
  filtersById: { [p: string]: Filter };
  hasResponses: boolean;
  hasSentiment: boolean;
  isPreview: boolean;
  isRegenerating: boolean;
  labels: Label[];
  loading: FETCH_STATUS_TYPE;
  responsesCount: number;
  responsesLoading: boolean;
  selectedFilterIds: string[];
  selectedItems: string[];
  selectedLabelItem: Concept | null;
  sentimentSummary: DashboardSentimentSummariesSentiment;
  showResponses: boolean;
  totalAnswers: number;
  totalsByDataSourcesAndFiltersIds: Record<string, TotalsByDataSourcesAndFiltersIDS>;
  totalsLoading: boolean;
  totalWeightedAnswers: number;
  duration: number;
  topicsSorting: SortingQuery;
  average?: boolean;
  onExpand: () => void;
  onFeedbackClick: (title: { title: string }) => void;
  onFilterButtonChange: (ids: string[]) => void;
  onLabelClick: (label: string | null) => void;
  onRefContent?: MutableRefObject<HTMLDivElement> | null;
  onWidgetFiltersClearClick: () => void;
  onLoadMoreResponses: () => void;
  onGenerateInsightsClick: (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => void;
  onTopicsSortingChange: (sorting: SortingQuery) => void;
  onCustomersFilterChange: (ids: string[]) => void;
}

function InterviewWidgetData({
  // TODO:
  analyzedAnswers,
  areFiltersEmpty,
  average,
  config,
  dataSource,
  dataSourceID,
  widgetID,
  filters,
  filtersById,
  hasResponses,
  hasSentiment,
  isPreview,
  isRegenerating,
  labels,
  loading,
  onExpand,
  responsesCount,
  responsesLoading,
  selectedFilterIds,
  selectedItems,
  selectedLabelItem,
  sentimentSummary,
  showResponses,
  totalAnswers,
  totalsByDataSourcesAndFiltersIds,
  totalsLoading,
  totalWeightedAnswers,
  onRefContent,
  duration,
  topicsSorting,
  onFeedbackClick,
  onFilterButtonChange,
  onLabelClick,
  onWidgetFiltersClearClick,
  onLoadMoreResponses,
  onGenerateInsightsClick,
  onTopicsSortingChange,
  onCustomersFilterChange,
}: IInterviewWidgetData) {
  if (!hasResponses) {
    const text = "This widget doesn't have any responses yet. We'll message you when someone responds.";
    return (
      <>
        <Grid item style={{ flex: 1, display: 'inherit' }}>
          <Grid container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
            <Grid item style={{ color: '#8b92a6', padding: '0 30px 20px', textAlign: 'center' }}>
              {text}
            </Grid>
          </Grid>
        </Grid>
        {renderLoading()}
      </>
    );
  }

  return (
    <>
      <Grid item style={!config.settings.showStatus ? { display: 'none' } : { marginBottom: '16px' }}>
        <StatusPanelContainer
          dataSourceID={dataSourceID}
          topicsCount={labels.length}
          duration={duration}
          total={totalAnswers}
          hasSentiment={hasSentiment}
          sentimentSummary={sentimentSummary}
        />
      </Grid>
      <Grid item style={{ display: 'flex', flex: '1' }} ref={onRefContent}>
        <FilteringButtonsAndContentContainer>
          {renderFilteringButtons()}
          <WidgetContainer>
            {renderTopics()}
            {renderConversation()}
            {renderInsights()}
          </WidgetContainer>
        </FilteringButtonsAndContentContainer>
      </Grid>
      {renderLoading()}
      <RefreshAIContainer dataSourceID={dataSourceID} isRegenerating={isRegenerating} />
    </>
  );

  function renderLoading() {
    if (loading !== FETCH_STATUS.LOADING) {
      return null;
    }

    return <LoadingOverlay />;
  }

  function renderFilteringButtons() {
    if (isPreview || !config.settings.showFilters) {
      return null;
    }
    return (
      <Grid item style={{ width: '100%' }}>
        <SegmentsRowContainer
          onChange={onFilterButtonChange}
          value={selectedFilterIds}
          filters={filters}
          disabled={responsesLoading && !!config.settings.showResponses}
          totalsPerFilter={totalsByDataSourcesAndFiltersIds[dataSourceID]}
          loading={totalsLoading}
          widgetTitle={config.base.title}
        />
      </Grid>
    );
  }

  function renderTopics() {
    if (areFiltersEmpty) {
      return null;
    }

    return (
      <ItemContainer>
        <ContainerTitle>
          <ItemTitleContainer>Topics</ItemTitleContainer>
        </ContainerTitle>

        <TopicsContainer
          dataSourceID={dataSourceID}
          widgetID={widgetID}
          labels={labels}
          filtersById={filtersById}
          selectedFilterIds={selectedFilterIds}
          clickable={showResponses}
          disabled={responsesLoading}
          selectedLabelItem={selectedLabelItem?.title || null}
          topicsSorting={topicsSorting}
          average={average}
          onLabelClick={onLabelClick}
          onTopicsSortingChange={onTopicsSortingChange}
        />
      </ItemContainer>
    );
  }

  function renderConversation() {
    return (
      <ItemContainer>
        <ContainerTitle>{renderTitle()}</ContainerTitle>
        <ResponsesCellsStateContextProvider>
          <ConversationContainer
            dataSourceID={dataSourceID}
            widgetID={widgetID}
            widgetName={config.base.title}
            selectedLabelItem={selectedLabelItem}
            labels={labels}
            isRegenerating={isRegenerating}
            onLabelClick={onLabelClick}
            onLoadMoreResponses={onLoadMoreResponses}
            onCustomersFilterChange={onCustomersFilterChange}
          />
        </ResponsesCellsStateContextProvider>
      </ItemContainer>
    );

    function renderTitle() {
      let conceptPart: JSX.Element | null = null;
      if (selectedLabelItem) {
        conceptPart = (
          <ItemSecondaryTitle title={selectedLabelItem.title}>
            {' '}
            / <TextOverflowEllipsis>{selectedLabelItem.title}</TextOverflowEllipsis>
          </ItemSecondaryTitle>
        );
      }

      return <ItemTitleContainer>Conversation{conceptPart}</ItemTitleContainer>;
    }
  }

  function renderInsights() {
    let selectedConcept;
    if (selectedLabelItem?.title) {
      selectedConcept = {
        title: selectedLabelItem.title,
        isEnabled: true,
      };
    } else {
      selectedConcept = undefined;
    }

    return (
      <ItemContainer>
        <InsightsHeader>
          <InsightsTitleContainer>
            AI Insights <SparkleIconStyled />
          </InsightsTitleContainer>
        </InsightsHeader>
        <AIInsightsContainer
          widgetID={config.base.id ?? 'preview'}
          dataSource={dataSource}
          selectedConcept={selectedConcept}
          conceptsFilteredInWidgetSettings={selectedItems}
          onFiltersClearClick={onWidgetFiltersClearClick}
          onGenerateInsightsClick={onGenerateInsightsClick}
        />
      </ItemContainer>
    );
  }
}

export default InterviewWidgetData;
