import styled from 'styled-components';
import { ClickAwayListener, Popper } from '@material-ui/core';

import { BurgerButtonItem, BurgerMenuContainer } from '../../../../components/style';

import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/delete-icon.svg';

const EditIconStyled = styled(EditIcon)`
  width: 16px;
  height: 16px;
`;

const DeleteIconStyled = styled(DeleteIcon)`
  width: 16px;
  height: 16px;
`;

const PopperStyled = styled(Popper)`
  z-index: 103;
`;

interface ITopicBurgerMenu {
  isOpen: boolean;
  anchorEl: any;
  onCloseClick: () => void;
  onRenameClick: () => void;
  onDeleteClick: () => void;
}

function TopicBurgerMenu({ isOpen, anchorEl, onCloseClick, onDeleteClick, onRenameClick }: ITopicBurgerMenu) {
  return (
    <PopperStyled open={isOpen} anchorEl={anchorEl?.current} placement='right-start' transition disablePortal>
      <ClickAwayListener onClickAway={onCloseClick}>
        <BurgerMenuContainer>
          <BurgerButtonItem data-testid='topic-menu-rename' onClick={onRenameClick}>
            <EditIconStyled /> Rename
          </BurgerButtonItem>
          <BurgerButtonItem data-testid='topic-menu-delete' onClick={onDeleteClick}>
            <DeleteIconStyled />
            Delete
          </BurgerButtonItem>
        </BurgerMenuContainer>
      </ClickAwayListener>
    </PopperStyled>
  );
}

export default TopicBurgerMenu;
