import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { ChangeEvent } from 'react';

import { OperationTypeEnum } from '../../../types/connections';

import { FormGroup, StyledFormControl } from './style';

interface IConnectionOperationGroup {
  operationType: OperationTypeEnum;
  onChangeOperationType: (operationType: OperationTypeEnum) => void;
}

function ConnectionOperationGroup({ operationType, onChangeOperationType }: IConnectionOperationGroup) {
  function handleChangeOperationType(e: ChangeEvent<{ value: unknown }>) {
    onChangeOperationType(e.target.value as OperationTypeEnum);
  }

  return (
    <FormGroup>
      <StyledFormControl>
        <InputLabel id='operation-select-label'>Operation</InputLabel>
        <Select
          data-testid='connection-operation-type-select'
          value={operationType}
          labelId='operation-select-label'
          onChange={handleChangeOperationType}
        >
          <MenuItem value={OperationTypeEnum.IMPORT}>Import</MenuItem>
          <MenuItem value={OperationTypeEnum.EXPORT}>Export</MenuItem>
        </Select>
      </StyledFormControl>
    </FormGroup>
  );
}

export default ConnectionOperationGroup;
