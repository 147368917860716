import { Box } from '@material-ui/core';

const HiveEmptyRow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      style={{
        border: '1px solid #e3e3f5',
        backgroundColor: '#f1f2f8',
        borderRadius: '8px',
        height: '88px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      Edit hive to select dashboards
    </Box>
  );
};

export default HiveEmptyRow;
