import { BreakdownFilter } from '../reducers/overviewReducer';

export const OVERVIEW_SET_QUESTIONS_BY_ID = 'OVERVIEW_SET_QUESTIONS_BY_ID';
export const OVERVIEW_SET_BREAKDOWN_FILTER = 'OVERVIEW_SET_BREAKDOWN_FILTER';

// TODO: not used, should remove?
export const overviewSetQuestionsById = (questionsById: any) => ({
  type: OVERVIEW_SET_QUESTIONS_BY_ID,
  payload: {
    questionsById,
  },
});

export const overviewSetBreakdownFilter = (filter: BreakdownFilter | null) => ({
  type: OVERVIEW_SET_BREAKDOWN_FILTER,
  payload: {
    filter,
  },
});
