import { v4 } from 'uuid';

import { DATA_SOURCE_TYPE, WIDGET_TYPE } from '../../constants';
import * as api from '../tools/api';

export const createOverview = async (dashboardId, dashboardDataSources) => {
  // get proper data source ids in the correct order
  const dataSourceIds = dashboardDataSources
    .filter((dds) => dds.type === DATA_SOURCE_TYPE.OTHER && dds.visible)
    .sort((a, b) => a.order - b.order)
    .map((dds) => dds.dataSourceID);
  // create dataSources structure for the settings
  const dataSources = dataSourceIds.map((dataSourceId, index) => ({
    id: dataSourceId,
    order: index,
    visible: true,
  }));
  // prepare widget config
  const widgetConfig = {
    base: {
      dashboardID: dashboardId,
      title: 'Overview',
      originalTitle: 'Overview',
      type: WIDGET_TYPE.OVERVIEW,
      collapsed: false,
      expanded: false,
      maximized: false,
    },
    settings: {
      dataSources,
    },
  };
  // create widget
  const widgetID = await api.createWidget(dashboardId, widgetConfig);
  widgetConfig.base.id = widgetID;

  // prepare overview Widget group
  const id = v4();
  const widgetsGroupConfig = {
    title: '',
    originalTitle: '',
    order: 0,
    collapsed: false,
    layout: [
      {
        widgetID,
        id,
        x: 0,
        y: 0,
        width: 2,
        height: 2,
      },
    ],
  };
  // create overview widget group
  widgetsGroupConfig.id = await api.createWidgetGroup(dashboardId, widgetsGroupConfig);

  return [widgetConfig, widgetsGroupConfig];
};

export const getInterviewConfig = (dataSource, dashboardId) => {
  return {
    base: {
      title: dataSource.title,
      originalTitle: dataSource.originalTitle,
      type: WIDGET_TYPE.INTERVIEW,
      dashboardID: dashboardId,
      collapsed: false,
      expanded: false,
      maximized: true,
    },
    settings: {
      dataSources: [
        {
          id: dataSource.dataSourceID,
          selectedItems: [],
        },
      ],
      showResponses: true,
      showStatus: true,
      showFilters: true,
      detailed: true,
    },
  };
};

export const getOpenEndedConfig = (dataSource, dashboardId) => {
  return {
    base: {
      title: dataSource.title,
      originalTitle: dataSource.originalTitle,
      type: WIDGET_TYPE.OPEN_ENDED,
      dashboardID: dashboardId,
      collapsed: false,
      expanded: false,
      maximized: true,
    },
    settings: {
      dataSources: [
        {
          id: dataSource.dataSourceID,
          selectedItems: [],
        },
      ],
      showResponses: true,
      showStatus: true,
      showFilters: true,
      detailed: true,
    },
  };
};

export const getBarsConfig = (dataSource, dashboardId) => {
  return {
    base: {
      title: dataSource.title,
      originalTitle: dataSource.originalTitle,
      type: WIDGET_TYPE.BARS,
      dashboardID: dashboardId,
      collapsed: false,
      expanded: false,
      maximized: false,
    },
    settings: {
      dataSources: [
        {
          id: dataSource.dataSourceID,
          selectedItems: [],
        },
      ],
    },
  };
};

export const generateWidgetConfig = (dataSource, dashboardId) => {
  if (dataSource.type === DATA_SOURCE_TYPE.OPEN_ENDED) {
    return getOpenEndedConfig(dataSource, dashboardId);
  }
  if (dataSource.type === DATA_SOURCE_TYPE.INTERVIEW) {
    return getInterviewConfig(dataSource, dashboardId);
  }
  return getBarsConfig(dataSource, dashboardId);
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const createWidget = async (dashboardId, dataSource) => {
  const config = generateWidgetConfig(dataSource, dashboardId);
  const widgetID = await api.createWidget(dashboardId, config);

  return {
    ...config,
    base: {
      ...config.base,
      id: widgetID,
    },
  };
};
