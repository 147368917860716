import { Grid } from '@material-ui/core';

import SegmentItem from '../../../components/Widgets/components/SegmentsRow/SegmentItem';

const CompareFilterButtons: React.FC<{
  filters: any[];
  selectedFilterIds: string[];
  onFilterClick: (filter: any) => void;
}> = ({ filters, selectedFilterIds, onFilterClick }) => {
  const buttons = filters.map((filter) => {
    return (
      <Grid item xs='auto' style={{ marginRight: '16px', marginBottom: '1em' }} key={filter.id}>
        <SegmentItem
          selected={selectedFilterIds.includes(filter.id)}
          text={filter.name}
          color={filter.color}
          onClick={onFilterClick.bind(null, filter)}
        />
      </Grid>
    );
  });

  return (
    <Grid container direction='row' data-testid='widget-filter-buttons'>
      {buttons}
    </Grid>
  );
};

export default CompareFilterButtons;
