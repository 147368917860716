import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { RGL_MARGIN_VALUE, RGL_ROW_HEIGHT, useDataBySelectedSavedFilterId } from '../lib';
import LabelsList from './LabelsList';
import useFilters from '../../../hooks/useFilters';
import { ExpandContainer } from '../../UI';
import { FETCH_STATUS, FILTER_ID_ALL, WIDGET_HEIGHT } from '../../../constants';
import SegmentsRowContainer from '../../Widgets/components/SegmentsRow/SegmentsRowContainer';
import { exportBarsBreakdown, exportBarsPlain, getTimestampString } from '../../tools/export';
import { WidgetTotalsContext } from '../../Widgets/WidgetTotalsContext';
import useSelectedFilterIdsToggle from '../../../hooks/useSelectedFilterIdsToggle';
import LoadingOverlay from '../../UI/LoadingOverlay';
import { getAppliedFiltersCount } from '../../UI/lib';

const BAR_HEIGHT = 22;
const BAR_BOTTOM_PADDING = 16;
const BREAKDOWN_BOTTOM_PADDING = 10;
const LIST_HORIZONTAL_PADDING = 32;

const calculateWidgetHeight = (height) => {
  return height * (RGL_ROW_HEIGHT + RGL_MARGIN_VALUE) - RGL_MARGIN_VALUE;
};

const MultiChoiceWidget = ({
  filterModel,
  overviewAPIOutput,
  breakdownData,
  breakdownLoading,
  config,
  updateAutoSizeHeight,
  refHeader,
  onExpand,
  showSettings,
  refCsvExport,
  refFiltersCount,
  breakdownFilter,
  refContent,
  loading,
  preview = false,
}) => {
  const { loading: totalsLoading, totalsByDataSourcesAndFiltersIds } = useContext(WidgetTotalsContext);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const [filters, filtersById] = useFilters();
  const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);
  const overviewDataById = useDataBySelectedSavedFilterId(overviewAPIOutput, filters);

  const [showExpand, setShowExpand] = useState(false);
  const { translationUsed, weightedMetricsUsed } = useSelector((state) => state.app);
  const dataSource = useMemo(() => {
    const dataSource = dataSources.find((ds) => ds.dataSourceID === filterModel.dataSourceID);
    const dataSourceInfo = dashboardDataSources.find((ds) => ds.dataSourceID === filterModel.dataSourceID);
    const valueType = weightedMetricsUsed ? 'weighted' : 'base';
    const items = dataSource.items.map((item) => ({
      ...item,
      ...item[valueType],
      displayResponse: translationUsed ? item.response : item.originalResponse,
    }));
    return { ...dataSource, ...dataSourceInfo, items };
  }, [filterModel, dataSources, dashboardDataSources, weightedMetricsUsed, translationUsed]);

  const refButtons = useRef();
  const refExpand = useRef();
  const refWidgetHeight = useRef(WIDGET_HEIGHT.DEFAULT);

  refFiltersCount.current = selectedFilterIds.length;

  const isExpanded = config.base.expanded;
  const isCollapsed = config.base.collapsed;
  const average = config.settings.merge && getAppliedFiltersCount(filters) > 0;
  const selectedItems = config.settings.dataSources[0].selectedItems;

  const labelsCount = selectedItems.length > 0 ? selectedItems.length : dataSource.items.length;
  const filtersCount = filters.length;
  useEffect(() => {
    if (showSettings || isCollapsed) {
      if (isCollapsed && !showSettings) {
        refWidgetHeight.current = WIDGET_HEIGHT.COLLAPSED;
        updateAutoSizeHeight(WIDGET_HEIGHT.COLLAPSED);
      }
      return;
    }

    // calculate list height
    const breakdownCount = breakdownFilter ? breakdownFilter.values.length : 1;
    const calculatedFiltersCount = average ? 1 : filters.length === 1 ? filtersCount : selectedFilterIds.length;
    const listHeight =
      calculatedFiltersCount * labelsCount * BAR_HEIGHT * breakdownCount +
      labelsCount * BAR_BOTTOM_PADDING +
      (breakdownCount > 1 ? BREAKDOWN_BOTTOM_PADDING * labelsCount * breakdownCount : 0) +
      LIST_HORIZONTAL_PADDING;

    // calculate max list height (widget height - controls height)
    const baseHeight = isExpanded ? WIDGET_HEIGHT.EXPANDED : WIDGET_HEIGHT.DEFAULT;
    const offset =
      (refButtons.current ? refButtons.current.scrollHeight : 0) +
      (refHeader.current ? refHeader.current.scrollHeight : 0) +
      (isExpanded && refExpand.current ? refExpand.current.scrollHeight : 0) +
      16; // 16 - handler height
    const maxListHeight = calculateWidgetHeight(baseHeight) - offset;

    let show = isExpanded;

    if (listHeight < maxListHeight) {
      const customHeight = Math.ceil((offset + listHeight + RGL_MARGIN_VALUE) / (RGL_ROW_HEIGHT + RGL_MARGIN_VALUE));
      refWidgetHeight.current = customHeight;
      updateAutoSizeHeight(customHeight);
    } else {
      refWidgetHeight.current = baseHeight;
      updateAutoSizeHeight(baseHeight);
      show = show || listHeight > maxListHeight;
    }
    setShowExpand(show);
  }, [
    isExpanded,
    isCollapsed,
    refButtons,
    refExpand,
    refHeader,
    labelsCount,
    filtersCount,
    average,
    showSettings,
    breakdownFilter,
    selectedFilterIds,
    updateAutoSizeHeight,
  ]);

  refCsvExport.current = () => {
    const titleField = translationUsed ? 'title' : 'originalTitle';
    const title = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === filterModel.dataSourceID)[
      titleField
    ];
    const timestampString = getTimestampString();
    if (breakdownData) {
      const breakdownDataSource = dataSources.find((dataSource) => dataSource.dataSourceID === breakdownFilter.dataSourceID);
      const breakdownItems = breakdownFilter.values.map((value) => breakdownDataSource.items.find((item) => item.response === value));
      const breakdownTitle = dashboardDataSources.find(
        (dashboardDataSource) => dashboardDataSource.dataSourceID === breakdownFilter.dataSourceID,
      )[titleField];
      const filename = `${title} (breakdown by ${breakdownTitle}) - ${timestampString}`;
      exportBarsBreakdown(breakdownData, dataSource, breakdownItems, filters, selectedFilterIds, translationUsed, filename);
    } else {
      const filename = `${title} - ${timestampString}`;
      exportBarsPlain(overviewDataById, dataSource, filters, selectedFilterIds, filtersById, filename);
    }
  };

  return (
    <>
      <Grid
        item
        style={{
          height: preview ? 'inherit' : '0',
          flex: '1',
          display: isCollapsed ? 'none' : 'flex',
        }}
        ref={refContent}
      >
        <Grid container direction='column'>
          {!preview && (
            <Grid item ref={refButtons}>
              <SegmentsRowContainer
                onChange={setSelectedFilterIds}
                value={selectedFilterIds}
                filters={filters}
                totalsPerFilter={totalsByDataSourcesAndFiltersIds[filterModel.dataSourceID]}
                loading={totalsLoading}
                widgetTitle={config.base.title}
              />
            </Grid>
          )}
          <Grid
            item
            style={{
              width: '100%',
              height: '0',
              flex: '1',
              paddingTop: '16px',
              paddingBottom: '16px',
            }}
          >
            {breakdownLoading ? (
              <>Loading...</>
            ) : (
              <LabelsList
                dataSource={dataSource}
                overviewDataById={overviewDataById}
                selectedFilterIds={selectedFilterIds}
                filtersById={filtersById}
                breakdownData={breakdownData}
                selectedItems={selectedItems}
                average={average}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!preview && !isCollapsed && showExpand && (
        <Grid item ref={refExpand}>
          <ExpandContainer onClick={onExpand} container direction='row' justifyContent='center' alignItems='center' alignContent='center'>
            {!isExpanded && (
              <>
                <Grid item>Show more</Grid>
                <Grid item style={{ fontSize: '33px' }}>
                  <ExpandMoreIcon />
                </Grid>
              </>
            )}
            {isExpanded && (
              <>
                <Grid item>Show less</Grid>
                <Grid item style={{ fontSize: '33px' }}>
                  <ExpandLessIcon />
                </Grid>
              </>
            )}
          </ExpandContainer>
        </Grid>
      )}
      {loading === FETCH_STATUS.LOADING && <LoadingOverlay />}
    </>
  );
};

export default MultiChoiceWidget;
