import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/cross-icon.svg';
import { FilledButton, IconButton } from '../../../../UI/Button';
import TextField from '../../../../UI/TextField';

import { Row } from './style';

const TextFieldStyled = styled(TextField)<{ size: 'small' | 'medium' }>`
  .MuiOutlinedInput-input {
    padding: 0 16px;
    height: ${(props) => (props.size === 'small' ? '24px' : '40px')};
  }
`;

const CloseIconStyled = styled(CloseIcon)<{ size: 'small' | 'medium' }>`
  width: ${(props) => (props.size === 'small' ? '12px' : '20px')};
  height: ${(props) => (props.size === 'small' ? '12px' : '20px')};
`;

const IconButtonStyled = styled(IconButton)<{ size: 'small' | 'medium' }>`
  height: ${(props) => (props.size === 'small' ? '24px' : '48px')};
  width: ${(props) => (props.size === 'small' ? '24px' : '48px')};
  &.MuiIconButton-root {
    background-color: var(--medium-gray);
    padding: 0;
  }
`;

const AdornmentIconButtonStyled = styled(IconButtonStyled)<{
  size: 'small' | 'medium';
}>`
  height: ${(props) => (props.size === 'small' ? '16px' : '24px')};
  width: ${(props) => (props.size === 'small' ? '16px' : '24px')};
`;

const RowStyled = styled(Row)`
  gap: 1em;
  align-items: center;
  height: 64px;
`;

const ButtonStyled = styled(FilledButton)<{ size: 'small' | 'medium' }>`
  height: ${(props) => (props.size === 'small' ? '24px' : '40px')};
  & * {
    font-size: ${(props) => (props.size === 'small' ? '14px' : '16px')};
  }
`;

interface IAdornment {
  onClick: () => void;
  size: 'small' | 'medium';
}
function Adornment({ size, onClick }: IAdornment) {
  return (
    <AdornmentIconButtonStyled data-testid='topic-create-edit-clear' size={size} onClick={onClick}>
      <CloseIconStyled size='small' />
    </AdornmentIconButtonStyled>
  );
}

interface ITopicCreateEdit {
  topic: string;
  isLoading: boolean;
  size?: 'small' | 'medium';
  onClose: () => void;
  onSave: (topic: string) => void;
  onClear?: () => void;
}

function TopicCreateEdit({ topic, isLoading, size = 'medium', onClose, onSave, onClear }: ITopicCreateEdit) {
  const [value, setValue] = useState(topic);

  function handleTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.currentTarget.value);
  }

  function handleOnClear() {
    setValue('');
    if (onClear) {
      onClear();
    }
  }

  return (
    <RowStyled data-testid='topic-create-edit-container'>
      <IconButtonStyled data-testid='topic-create-edit-close' onClick={onClose} disabled={isLoading} size={size}>
        <CloseIconStyled size={size} />
      </IconButtonStyled>
      <TextFieldStyled
        data-testid='topic-create-edit-text-field'
        fullWidth
        variant='outlined'
        InputProps={{
          endAdornment: <Adornment onClick={handleOnClear} size={size} />,
        }}
        value={value}
        disabled={isLoading}
        size={size}
        onChange={handleTextChange}
      />
      <ButtonStyled
        data-testid='topic-create-edit-save'
        onClick={onSave.bind(null, value.trim())}
        disabled={isLoading || !value.trim()}
        size={size}
      >
        Save
      </ButtonStyled>
    </RowStyled>
  );
}

export default TopicCreateEdit;
