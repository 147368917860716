import { forwardRef } from 'react';
import { Box, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { itemColors } from './style';

const SidebarIconButton = styled(IconButton)`
  box-shadow: 0 0 4px 1px #e9e9e9;
  &.MuiIconButton-root {
    background-color: ${(props) => (props.selected ? itemColors.selectedBackground : itemColors.background)};
    color: ${(props) => (props.selected ? itemColors.selectedColor : itemColors.color)};
    .MuiSvgIcon-root {
      height: 24px;
      width: 24px;
    }
  }

  &.MuiIconButton-root:hover {
    background-color: ${(props) => (props.selected ? itemColors.selectedHover : itemColors.hover)};
  }
`;

const SidebarItem = forwardRef(({ icon, tooltip, selected, onClick, testId }, ref) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='68px'>
      <SidebarIconButton
        ref={ref}
        selected={selected}
        data-tooltip-id='base-tooltip'
        data-tooltip-content={tooltip}
        data-testid={testId}
        onClick={onClick}
      >
        {icon}
      </SidebarIconButton>
    </Box>
  );
});

export default SidebarItem;
