import { API_BASE_URL } from '../constants';

let selectedCompany;
let csrfToken = localStorage.getItem('csrfToken');

export function setCsrfToken(token) {
  csrfToken = token;
  localStorage.setItem('csrfToken', token);
}

export function getCsrfToken() {
  return csrfToken;
}

export const setSelectedCompany = (value) => (selectedCompany = value);
export const getSelectedCompany = () => selectedCompany;

export const defaultFetchSettings = {
  method: 'GET',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
};

export let getFetchSettings = (method = 'GET', data = null) => {
  let settings = {
    ...defaultFetchSettings,
    headers: { ...defaultFetchSettings.headers, 'X-CSRF-Token': csrfToken },
    method: method,
  };
  if (data) {
    Object.defineProperty(settings, 'body', {
      enumerable: true,
      configurable: false,
      value: JSON.stringify(data),
    });
  }
  return settings;
};

export const getLabelsUrl = (dashboardId, answerId) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/extend-labels/?answerID=${answerId}`;
export const getCreateFilterUrl = (dashboardId, filterName, description) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filter/?name=${encodeURIComponent(filterName)}&description=${encodeURIComponent(description)}`;
export const getSavedFiltersUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filters`;
export const getSavedFilterUrl = (dashboardId, filterId) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filter/${filterId}`;
export const getUpdateFilterUrl = (dashboardId, filterId, filterName, description) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filter/${filterId}?name=${encodeURIComponent(filterName)}&description=${encodeURIComponent(description)}`;
export const getSegmentsUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/segments`;
export const getExportUrl = (dashboardId, isTranslated) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/export/?isTranslated=${isTranslated}`;
export const getTotalUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/total`;
export const getFullRecord = (dashboardId, answerId) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/full-record/?answerID=${answerId}`;
export const getWidgetGroupUrl = (dashboardId, groupId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget-group/${groupId}`;
export const getWidgetUrl = (dashboardId, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}`;
export const putAIInsightsRequestUrl = (dashboardId, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}/ai-insights`;
export const putInterviewInsightsRequestUrl = (dashboardId, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}/interview-insights`;
export const postAIInsightsFeedbackUrl = (dashboardId, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}/ai-insights/feedback`;
export const getDashboardsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/dashboards`;
export const getDashboardViewsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard-views`;
export const getDashboardViewUrl = (viewId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard-view/${viewId ? viewId : ''}`;
export const getDashboardUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}`;
export const getSummaryUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/summary`;
export const getSentimentSummaryUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/sentiment`;
export const getBreakdownUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/breakdown`;
export const getTotalTrendsUrl = (dashboardId, groupBy) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/total-trends/${groupBy}`;
export const getBookmarkResponseUrl = (dashboardId, answerId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/bookmarked-response/${answerId}`;
export const getProfileUrl = () => `${API_BASE_URL}/api/profile`;

export const postAuthUrl = `${API_BASE_URL}/api/post-auth`;

export const getTrendsUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/trends`;
export const getTrendsBreakDownUrl = (dashboardId) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/trends-breakdown`;
export const getResponsesUrl = (dashboardId) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/responses`;
export const getSurveysUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/surveys`;
export const getUploadCSVUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/upload-csv`;
export const getProcessingCSVsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/processing-csvs`;
export const getConnectionsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/connections`;
export const getConnectionUrl = (connectionId) => `${API_BASE_URL}/api/company/${selectedCompany}/connections/${connectionId}`;
export const getAzureFilesUrl = (authID) => `${API_BASE_URL}/api/company/${selectedCompany}/azure-files?authID=${authID}`;
export const getQualtricsSurveysUrl = (authID) => `${API_BASE_URL}/api/company/${selectedCompany}/qualtrics-surveys?authID=${authID}`;
export const getProviderAuthsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/provider-auth`;
export const getProviderAuthUrl = (authID) => `${API_BASE_URL}/api/company/${selectedCompany}/provider-auth/${authID}`;
export const getBrandsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/brands`;
export const getLanguagesUrl = () => `${API_BASE_URL}/api/languages`;
export const getAuthProvidersUrl = () => `${API_BASE_URL}/api/auth-provider`;
export const getCustomersUrl = (dashboardID, datasourceID) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${datasourceID}/customers`;
export const getLabelsManagementUrl = (dashboardID, datasourceID, label) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${datasourceID}/labels${label ? `/${label}` : ''}`;
export const getRegenerationStatusUrl = (dashboardID, dataSourcesIDsQuery) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/regenerate?${dataSourcesIDsQuery}`;
export const putRegeneratingUrl = (dashboardID, dataSourceID) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${dataSourceID}/regenerate`;
export const getReposnonseLabelsManagementUrl = (dashboardID, datasourceID, responseID) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${datasourceID}/answer/${responseID}/labels`;
export const getSignupWorkspaceUrl = () => `${API_BASE_URL}/api/signup/workspace`;
