import styled from 'styled-components';
import { Button, Grid, Tabs } from '@material-ui/core';
import palette from '../tools/palette';

export const DashboardContainer = styled('div')`
  padding: 0px 16px;
  margin-bottom: 16px;
  box-shadow: none;
`;
export const ResponesesContainer = styled(Grid)`
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
`;
export const OverviewContainer = styled(Grid)`
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;
`;
export const CommonWidgetContainer = styled('div')`
  background-color: #fff;
  border: 1px solid ${palette.dividerColor};
  border-radius: 8px;
  margin-bottom: 16px;
  height: 100%;

  &.MuiGrid-item {
    margin-bottom: 16px;
  }
`;
const TOOLBAR_HEIGHT = '60px';

export const StyledResultsTabs = styled(Tabs)`
  background-color: transparent;
  min-height: ${TOOLBAR_HEIGHT};
  height: ${TOOLBAR_HEIGHT};
  margin: 0px 16px 16px 16px;

  a {
    min-height: ${TOOLBAR_HEIGHT};
    height: ${TOOLBAR_HEIGHT};
  }

  .MuiTabs-flexContainer {
    min-height: ${TOOLBAR_HEIGHT};
    height: ${TOOLBAR_HEIGHT};
  }

  .MuiTab-root {
    text-transform: none;
    color: ${palette.linkColorLight};

    &.Mui-selected {
      color: ${palette.linkColorDark};
    }
  }

  .MuiTabs-indicator {
    background-color: ${palette.primaryColorLight};
    height: 5px;
  }
`;

export const BadgesAreaContainer = styled(Grid)`
  padding: 16px 16px 0px 16px;
`;

export const ExportButtonStyle = styled(Button)`
  &.MuiButton-root,
  &.MuiButton-root:hover {
    background-color: ${palette.primaryColorDark};
    color: #fff;
    text-transform: none;
    padding-right: 14px;
    padding-left: 14px;
  }

  &.MuiButton-root:hover {
    background-color: ${palette.primaryColorDarkHover};
  }

  .MuiCircularProgress-colorPrimary {
    color: #fff;
  }
`;

export const ShareButtonStyle = styled(ExportButtonStyle)`
  &.MuiButton-root {
    background-color: ${palette.primaryColorLight};
  }

  &.MuiButton-root:hover {
    background-color: ${palette.primaryColorLightHover};
  }
`;
