import styled from 'styled-components';

import { Label } from '../../../../../types/Label';
import { Filter } from '../../../../../types/Query';
import ConfirmationDialog from '../../../../ConfirmationDialog/ConfirmationDialog';
import { SortingQuery } from '../../../../../types/SortingQuery';

import { ITopicCreating, ITopicManaging, ITopicRenameDelete } from './types';
import TopicsHeader from './TopicsHeader';
import TopicCreateEdit from './TopicCreateEdit';
import TopicsList from './TopicsList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 28px - 16px);
  gap: 24px;
`;

interface ITopics extends ITopicManaging, ITopicCreating, ITopicRenameDelete {
  labels: Label[];
  filtersById: {
    [p: string]: Filter;
  };
  selectedFilterIds: string[];
  clickable: boolean;
  disabled: boolean;
  selectedLabelItem: string | null;
  topicsSorting: SortingQuery;
  isTopicDeleteConfirmationOpen: boolean;
  average?: boolean;
  onLabelClick: (label: string | null) => void;
  onSortingChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onSortingDirectionChange: (desc: boolean) => void;
  onTopicDeleteCancel: () => void;
  onTopicDelete: () => void;
}

function Topics({
  labels,
  filtersById,
  selectedFilterIds,
  clickable,
  disabled,
  selectedLabelItem,
  topicsSorting,
  isCreatingTopic,
  isTopicManagingLoading,
  topic,
  isTopicDeleteConfirmationOpen,
  average,
  onLabelClick,
  onSortingChange,
  onSortingDirectionChange,
  onTopicCreatingModeChange,
  onTopicCreate,
  onTopicRenameClick,
  onTopicRenameCancel,
  onTopicRename,
  onTopicDeleteClick,
  onTopicDeleteCancel,
  onTopicDelete,
}: ITopics) {
  return (
    <Container>
      {!isCreatingTopic ? (
        <TopicsHeader
          topicsSorting={topicsSorting}
          onSortingChange={onSortingChange}
          onSortingDirectionChange={onSortingDirectionChange}
          onTopicCreatingChange={onTopicCreatingModeChange.bind(null, false)}
        />
      ) : (
        <TopicCreateEdit
          topic={topic}
          isLoading={isTopicManagingLoading}
          onClose={onTopicCreatingModeChange.bind(null, true)}
          onSave={onTopicCreate}
        />
      )}
      <TopicsList
        labels={labels}
        filtersById={filtersById}
        selectedFilterIds={selectedFilterIds}
        clickable={clickable}
        disabled={disabled}
        selectedLabelItem={selectedLabelItem}
        topic={topic}
        isTopicManagingLoading={isTopicManagingLoading}
        isTopicDeleteConfirmationOpen={isTopicDeleteConfirmationOpen}
        average={average}
        onLabelClick={onLabelClick}
        onTopicRenameClick={onTopicRenameClick}
        onTopicRenameCancel={onTopicRenameCancel}
        onTopicRename={onTopicRename}
        onTopicDeleteClick={onTopicDeleteClick}
      />
      <ConfirmationDialog
        title={'You are deleting a topic...'}
        content={
          'Deleting this Topic will remove it from all responses currently categorized with this Topic. This action is permanent. Would you like to continue?'
        }
        open={isTopicDeleteConfirmationOpen}
        disabled={isTopicManagingLoading}
        onCancel={onTopicDeleteCancel}
        onConfirm={onTopicDelete}
      />
    </Container>
  );
}

export default Topics;
