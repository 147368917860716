import React from 'react';

import { FilledButton } from '../../../UI/Button';
import { OperationTypeEnum } from '../../../../types/connections';

import { Container, FooterActions, FormGroup, StyledCircularProgress } from '../style';
import ConnectionScheduleGroup from '../ConnectionScheduleGroup';
import ConnectionLanguageSelect from '../ConnectionLanguageSelect';
import ConnectionBrandSelect from '../ConnectionBrandSelect';
import ConnectionExportTypeSelect from '../ConnectionExportTypeSelect';
import ConnectionDashboardSelect from '../ConnectionDashboardSelect';
import ConnectionOperationGroup from '../ConnectionOperationGroup';
import ConnectionAzureFileSelect from '../ConnectionAzureFileSelect';

interface IConnectAzure
  extends React.ComponentProps<typeof ConnectionOperationGroup>,
    React.ComponentProps<typeof ConnectionScheduleGroup>,
    React.ComponentProps<typeof ConnectionLanguageSelect>,
    React.ComponentProps<typeof ConnectionBrandSelect>,
    React.ComponentProps<typeof ConnectionAzureFileSelect>,
    React.ComponentProps<typeof ConnectionDashboardSelect>,
    React.ComponentProps<typeof ConnectionExportTypeSelect> {
  operationType: OperationTypeEnum;
  isSubmitDisabled: boolean;
  isSubmitting: boolean;
  onSubmitConnection: () => void;
}
function ConnectAzure(props: IConnectAzure) {
  const { operationType, isSubmitDisabled, isSubmitting, onSubmitConnection } = props;

  return (
    <>
      <Container data-testid='connect-azure-container'>
        <ConnectionOperationGroup {...props} />
        <FormGroup>
          {operationType === OperationTypeEnum.IMPORT ? (
            <>
              <ConnectionAzureFileSelect {...props} />
              <ConnectionLanguageSelect {...props} />
              <ConnectionBrandSelect {...props} />
            </>
          ) : (
            <>
              <ConnectionDashboardSelect {...props} />
              <ConnectionExportTypeSelect {...props} />
            </>
          )}
        </FormGroup>
        <ConnectionScheduleGroup {...props} />
      </Container>
      <FooterActions>
        {isSubmitting && <StyledCircularProgress data-testid='connect-azure-progress' size='36px' />}
        <FilledButton data-testid='connect-azure-submit' onClick={onSubmitConnection} disabled={isSubmitDisabled}>
          Finish
        </FilledButton>
      </FooterActions>
    </>
  );
}

export default ConnectAzure;
