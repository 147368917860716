import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Connection, ConnectionStatusEnum, ConnectionUpdate, SurveySourceEnum } from '../../../types/connections';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearConnectionUpdate, openDeleteConnectionModal } from '../../../store/actions/connectors';
import { updateConnection as updateConnectionThunk } from '../../../store/thunks/connectors';
import { NotificationsContext } from '../../Notification/NotificationsContext';
import { SEVERITY } from '../../Notification/types';
import { NOTIFICATIONS } from '../../Notification/constants';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';

import { ScheduleEnum, ScheduleToReadable } from '../types';
import ConnectionRow from './ConnectionRow';
import { SURVEY_SOURCE_TO_CONNECTION_ERROR_MESSAGE } from '../constants';

interface IConnectionRowContainer {
  connection: Connection;
  source: SurveySourceEnum;
  // editing is enabled for the entire integration -> render the edit/delete cells to align with the rest of the table
  isIntegrationEditEnabled: boolean;
  // editing is enabled for this connection -> render edit/delete controls
  isEditEnabled: boolean;
}

function ConnectionRowContainer({ connection, isIntegrationEditEnabled, isEditEnabled, source }: IConnectionRowContainer) {
  const { id } = connection;
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const { connectionUpdates } = useAppSelector((state) => state.connectors);
  const dispatch = useAppDispatch();
  const [interval, setInterval] = useState(connection.interval);
  const [status, setStatus] = useState(connection.status);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isEditingConnection, setIsEditingConnection] = useState(false);

  const notificationID = `${NOTIFICATIONS.ConnectorsConnectionUpdateError}-${id}`;

  const isUpdatingConnection = connectionUpdates[id]?.submitting;
  const isReadonly = !isEditEnabled || status === ConnectionStatusEnum.ERROR || status === ConnectionStatusEnum.RUNNING;
  const tooltipConnectionError = SURVEY_SOURCE_TO_CONNECTION_ERROR_MESSAGE[source];

  function handleChangeUpdateInterval(event: React.ChangeEvent<{ value: unknown }>) {
    const interval = event.target.value as number;
    setInterval(interval);
    setShouldUpdate(true);
    eventsTracker.track(EVENTS.CONNECTIONS_EDIT_CONNECTION_INLINE, {
      'App/Integration name': source,
      'Changed field': 'Interval',
      'Edit Connection Select': false,
      'Connection Status Selection': status,
      'Run Schedule Selection': ScheduleToReadable[interval as ScheduleEnum],
      'Save Edit inline': false,
    });
  }

  function handleChangeStatus(event: React.ChangeEvent<{ value: unknown }>) {
    const newStatus = event.target.value as ConnectionStatusEnum;
    setStatus(newStatus);
    setShouldUpdate(true);
    eventsTracker.track(EVENTS.CONNECTIONS_EDIT_CONNECTION_INLINE, {
      'App/Integration name': source,
      'Changed field': 'Status',
      'Edit Connection Select': false,
      'Connection Status Selection': status,
      'Run Schedule Selection': ScheduleToReadable[interval as ScheduleEnum],
      'Save Edit inline': false,
    });
  }

  function beginInlineEdit() {
    if (isReadonly) {
      return;
    }

    setIsEditingConnection(true);
  }

  const updateConnection = useCallback(() => {
    if (!shouldUpdate) {
      return;
    }
    setShouldUpdate(false);
    const connectionUpdate: ConnectionUpdate = {
      id,
      source,
      interval,
      status,
    };

    dispatch(updateConnectionThunk({ update: connectionUpdate }));
  }, [shouldUpdate, dispatch, id, source, interval, status]);

  useEffect(() => {
    updateConnection();
  }, [status, interval, updateConnection]);

  useEffect(() => {
    if (!connectionUpdates[id]) {
      return;
    }
    const { success } = connectionUpdates[id];
    if (success === undefined) {
      return;
    }

    if (success) {
      removeNotification(notificationID);
    } else {
      addNotification({
        id: notificationID,
        title: 'Failed to update connection.',
        severity: SEVERITY.ERROR,
        persistent: true,
      });
    }
    dispatch(clearConnectionUpdate({ id }));
  }, [addNotification, connectionUpdates, dispatch, id, notificationID, removeNotification]);

  function toggleEditingConnection() {
    if (isEditingConnection) {
      updateConnection();
    }
    setIsEditingConnection(!isEditingConnection);
    eventsTracker.track(EVENTS.CONNECTIONS_EDIT_CONNECTION_INLINE, {
      'App/Integration name': source,
      'Edit Connection Select': !isEditingConnection,
      'Connection Status Selection': status,
      'Run Schedule Selection': ScheduleToReadable[interval as ScheduleEnum],
      'Save Edit inline': isEditingConnection,
    });
  }

  function handleDeleteConnection() {
    eventsTracker.track(EVENTS.CONNECTIONS_DELETE_CONNECTION, {
      'App/Integration name': source,
      'Connection name': connection.name,
    });
    dispatch(openDeleteConnectionModal({ id, source }));
  }

  return (
    <ConnectionRow
      connection={connection}
      status={status}
      interval={interval}
      isIntegrationEditEnabled={isIntegrationEditEnabled}
      isEditEnabled={isEditEnabled}
      isReadonly={isReadonly}
      isUpdating={isUpdatingConnection ?? false}
      isEditingConnection={isEditingConnection}
      connectionErrorMessage={tooltipConnectionError}
      onBeginInlineEdit={beginInlineEdit}
      onToggleEditingConnection={toggleEditingConnection}
      onChangeInterval={handleChangeUpdateInterval}
      onChangeStatus={handleChangeStatus}
      onDeleteConnection={handleDeleteConnection}
    />
  );
}

export default ConnectionRowContainer;
