import styled from 'styled-components';

import emptyResult from '../../../../assets/images/empty-result.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Message = styled.div`
  text-align: center;
`;

const ImageContainer = styled.div`
  text-align: center;
  padding-top: 20px;
`;

const Image = styled.img`
  width: 130px;
`;

function NoResponses() {
  return (
    <Container>
      <Message>
        There are no responses that match <br /> your filter(s) or search criteria.
      </Message>
      <ImageContainer>
        <Image src={emptyResult} alt='No responses' />
      </ImageContainer>
    </Container>
  );
}

export default NoResponses;
