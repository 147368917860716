import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FilledButton } from '../../components/UI/Button';
import { UserProfile } from '../../store/reducers/profile';

import logo from '../../assets/images/logo.png';
import { ReactComponent as CheckboxUncheckedIcon } from '../../assets/icons/checkbox-unchecked-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../assets/icons/checkbox-checked-icon.svg';
import { ReactComponent as AuthIcon } from '../../assets/icons/auth-icon.svg';
import { ReactComponent as PersonIcon } from '../../assets/icons/person-icon.svg';

import { FIELDS } from './ProfileAccountDetailsContainer';

const Container = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  width: 800px;
  margin: 0 auto;
  box-shadow:
    0px 2px 6px 2px rgba(15, 12, 12, 0.15),
    0px 1px 2px 0px rgba(15, 12, 12, 0.3);
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 300px;
  margin: 0 auto;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CustomAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding: 0 4px !important;
  }
`;

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;

const FieldName = styled.div`
  font-weight: 400;
  line-height: 16px;
  line-height: 19px;
  color: var(--text-primary);
`;

const GroupName = styled(FieldName)`
  font-weight: 500;
  color: var(--text-primary);
`;

const FormHeader = styled.div`
  color: var(--text-primary);
  font-size: 32px;
  font-weight: 500;
  text-align: center;
`;

const CheckBoxContainer = styled.div``;

const OTPContainer = styled.div`
  border-top: 1px solid var(--divider-color);
  border-bottom: 1px solid var(--divider-color);
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const OTPContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const GroupIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const OTPIcon = styled(AuthIcon)`
  width: 24px;
  height: 24px;
`;

const AccountIcon = styled(PersonIcon)`
  width: 24px;
  height: 24px;
`;

const Logo = styled.img`
  width: 73px;
  height: 75px;
`;

interface IProfileAccountDetails {
  profile: UserProfile;
  isLoading: boolean;
  disabled: boolean;
  errors: Record<FIELDS, boolean>;
  countries: string[];
  onChangeProfile: (profile: UserProfile) => void;
  onSubmit: () => void;
}

function ProfileAccountDetails({ profile, isLoading, disabled, errors, countries, onChangeProfile, onSubmit }: IProfileAccountDetails) {
  function hanldeInput(field: FIELDS, event: React.ChangeEvent<any>, value?: any) {
    switch (field) {
      case FIELDS.FIRST_NAME:
        onChangeProfile({ ...profile, firstName: event.target.value });
        break;
      case FIELDS.LAST_NAME:
        onChangeProfile({ ...profile, lastName: event.target.value });
        break;
      case FIELDS.JOB:
        onChangeProfile({ ...profile, position: event.target.value });
        break;
      case FIELDS.COUNTRY:
        onChangeProfile({ ...profile, country: value as string });
        break;
      case FIELDS.OTP:
        onChangeProfile({ ...profile, requiredOTP: !profile.requiredOTP });
        break;

      default:
        break;
    }
  }

  return (
    <Container>
      <HeaderContainer>
        <Logo alt='Logo' src={logo} />
        <FormHeader>Your Beehive AI Profile</FormHeader>
      </HeaderContainer>
      <ContentContainer>
        <GroupIconContainer>
          <AccountIcon />
          <GroupName>Account Information</GroupName>
        </GroupIconContainer>
        <FieldContainer>
          <FieldName>First Name</FieldName>
          <CustomTextField
            fullWidth
            error={errors[FIELDS.FIRST_NAME]}
            onChange={hanldeInput.bind(null, FIELDS.FIRST_NAME)}
            variant='outlined'
            value={profile.firstName}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldName>Last Name</FieldName>
          <CustomTextField
            fullWidth
            error={errors[FIELDS.LAST_NAME]}
            onChange={hanldeInput.bind(null, FIELDS.LAST_NAME)}
            variant='outlined'
            value={profile.lastName}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldName>Role</FieldName>
          <CustomTextField
            fullWidth
            error={errors[FIELDS.JOB]}
            onChange={hanldeInput.bind(null, FIELDS.JOB)}
            variant='outlined'
            value={profile.position}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldName>Country</FieldName>
          <CustomAutoComplete
            fullWidth
            onChange={hanldeInput.bind(null, FIELDS.COUNTRY)}
            options={countries}
            renderInput={(params) => <CustomTextField error={errors[FIELDS.COUNTRY]} {...params} variant='outlined' />}
            value={profile.country}
          />
        </FieldContainer>
        <OTPContainer>
          <GroupIconContainer>
            <OTPIcon />
            <GroupName>Security Preference</GroupName>
          </GroupIconContainer>
          <OTPContentContainer>
            <CheckBoxContainer onClick={hanldeInput.bind(null, FIELDS.OTP)}>
              {profile.requiredOTP ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
            </CheckBoxContainer>
            <FieldName>Include one-time password using Google Authenticator or similar</FieldName>
          </OTPContentContainer>
        </OTPContainer>
        <ButtonContainer>
          <FilledButton disabled={disabled} onClick={onSubmit} fullWidth>
            {isLoading ? 'Saving...' : 'Save'}
          </FilledButton>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
}

export default ProfileAccountDetails;
