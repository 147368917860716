export const QUALTRICS_UPDATE_SURVEYS = 'QUALDRICS_UPDATE_SURVEYS';
export const QUALTRICS_RESET_SURVEYS = 'QUALDRICS_RESET_SURVEYS';

export interface IQualtricsSurvey {
  id: string;
  name: string;
}

interface IUpdateSurveys {
  surveys?: IQualtricsSurvey[];
  error?: boolean;
}
export const updateSurveys = ({ surveys, error }: IUpdateSurveys) => ({
  type: QUALTRICS_UPDATE_SURVEYS,
  payload: {
    surveys,
    error,
  },
});

export const resetSurveys = () => ({
  type: QUALTRICS_RESET_SURVEYS,
});
