import difference from 'lodash/difference';

import { DATA_SOURCE_TYPE, FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE, WIDGET_TYPE } from '../constants';
import { recursiveValidation } from '../contexts/lib';

export const preprocessOverviewWidgetConfig = (withValidation, config, dataSources, dashboardDataSources) => {
  if (!withValidation) {
    return config;
  }

  const settingsDataSources = config.settings.dataSources;
  const overviewDataSourceIds = dashboardDataSources
    .filter((dashboardDataSource) => {
      const dataSource = dataSources.find((dataSource) => dataSource.dataSourceID === dashboardDataSource.dataSourceID);
      if (!dashboardDataSource.visible || dashboardDataSource.type !== DATA_SOURCE_TYPE.OTHER || !dataSource) {
        return false;
      }
      const sum = dataSource.items.reduce(
        (acc, item) => {
          return {
            base: acc.base + item.base.percentage,
            weighted: acc.weighted + item.weighted.percentage,
          };
        },
        { base: 0, weighted: 0 },
      );
      return (
        sum.base.toFixed(FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE) <= 100 &&
        sum.weighted.toFixed(FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE) <= 100
      );
    })
    .map((dashboardDataSource) => dashboardDataSource.dataSourceID);
  const settingsDataSourcesIds = settingsDataSources.map((dataSource) => dataSource.id);
  const newIds = difference(overviewDataSourceIds, settingsDataSourcesIds);
  const removedIds = difference(settingsDataSourcesIds, overviewDataSourceIds);

  if (newIds.length === 0 && removedIds.length === 0) {
    return config;
  }

  const updatedSettingsDataSources = settingsDataSources.filter((dataSource) => !removedIds.includes(dataSource.id));
  if (newIds.length > 0) {
    const orders = updatedSettingsDataSources.map((dataSource) => dataSource.order);
    let order = Math.max(...orders);
    for (let newId of newIds) {
      order++;
      updatedSettingsDataSources.push({
        order,
        id: newId,
        visible: true,
      });
    }
  }
  return {
    ...config,
    settings: { ...config.settings, dataSources: updatedSettingsDataSources },
    _updated: true,
  };
};

export const addValidationToWidget = (withValidation, config, dataSourcesItemsById) => {
  const validation = {
    valid: true,
    errors: null,
  };

  if (config.base.type === WIDGET_TYPE.OVERVIEW) {
    // Overview has own widget structure, and it couldn't be fixed/broken by user
    return { ...config, validation };
  }

  if (!withValidation) {
    let filter = config.base.filter ? { ...config.base.filter, valid: true } : {};
    let isMaximized = config.base.maximized;
    // if (config.base.type === WIDGET_TYPE.OPEN_ENDED) {
    //     isMaximized = true;
    // }
    return {
      ...config,
      base: {
        ...config.base,
        maximized: isMaximized,
        filter,
      },
      validation,
    };
  }

  const missedLabelsByDataSourceId = {};
  const missedDataSourceIds = [];
  for (let dataSource of config.settings.dataSources) {
    const items = dataSourcesItemsById[dataSource.id];
    if (items) {
      const missed = [];
      for (let selectedItem of dataSource.selectedItems) {
        if (!items.includes(selectedItem)) {
          missed.push(selectedItem);
        }
      }
      if (missed.length > 0) {
        missedLabelsByDataSourceId[dataSource.id] = missed;
      }
    } else {
      missedDataSourceIds.push(dataSource.id);
    }
  }

  if (missedDataSourceIds.length > 0 || Object.keys(missedLabelsByDataSourceId).length > 0) {
    validation.valid = false;
    validation.errors = {};
    if (missedDataSourceIds.length > 0) {
      validation.errors['missedDataSourceIds'] = missedDataSourceIds;
    }
    if (Object.keys(missedLabelsByDataSourceId).length > 0) {
      validation.errors['missedLabelsByDataSourceId'] = missedLabelsByDataSourceId;
    }
  }

  let filter = config.base.filter ? { ...config.base.filter, valid: true } : {};
  if (!!config.base.filter?.query) {
    const { valid, missedValues, missedDataSourceIds } = recursiveValidation(config.base.filter.query, dataSourcesItemsById);
    if (!valid) {
      filter = {
        ...filter,
        valid: false,
        missedValues,
        missedDataSourceIds,
      };
    }
  }

  return {
    ...config,
    base: {
      ...config.base,
      filter,
    },
    validation,
  };
};

const widgetGroupsComparator = (a, b) => {
  // overview on the top
  if (b.isOverview) {
    return 1;
  }
  if (a.isOverview) {
    return -1;
  }
  // default at the bottom
  if (b.isDefault) {
    return -1;
  }
  if (a.isDefault) {
    return 1;
  }
  return a.order - b.order;
};

// Sort widget groups based on the group order and group type
export const sortWidgetGroups = (widgetGroups) => {
  return [...widgetGroups].sort(widgetGroupsComparator);
};
