import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import TextField from '../../../UI/TextField';

export const Width100 = styled.div`
  width: 100%;
  text-align: left;
`;

export const ResponseWrapper = styled(Width100)`
  padding-bottom: 12px;
`;

export const ResponseLoadingOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LeftHeaderWrapper = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  color: var(--black);
`;

export const SentimentResponseContainer = styled.div`
  margin-right: 16px;
  width: 44px;
`;

export const TimestampContainer = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--light-purple);
`;

export const LabelsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const CustomAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    border-radius: var(--double-border-radius);
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    padding: 0;
    width: 100%;

    & .MuiTextField-root {
      height: 100%;
      border-radius: var(--double-border-radius);

      & .MuiInputBase-root {
        height: 100%;
      }
    }
  }

  &&& .MuiAutocomplete-paper {
    color: red;
    font-size: 12px;
  }
`;

export const FilteringTextField = styled(TextField)`
  background-color: var(--white);

  &&& .MuiInputBase-root {
    flex-wrap: nowrap;
    padding: 0 16px;
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 6px;
  }

  fieldset {
    border-color: var(--medium-gray);
    border-radius: var(--double-border-radius);
  }
`;
