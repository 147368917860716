import React from 'react';
import styled from 'styled-components';
import palette from './palette';
import InfoIcon from '@material-ui/icons/Info';
import LoadingLabel from '../LoadingLabel/LoadingLabel';
import WeightIndicator from '../UI/WeightIndicator';
import { Icon } from '@material-ui/core';

const BadgeContainer = styled('div')`
  float: left;
  display: flex;
  width: 260px;
  height: 103px;
  border-radius: 8px;
  background-color: #fff;
  color: ${palette.linkColorLight1};
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 0.8em;

  .text {
    padding: 0 20px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiSvgIcon-root {
      margin-right: 8px;
      font-size: 14px;
      height: 14px;
    }
  }

  .value {
    display: flex;
    color: ${palette.primaryColorDark};
    font-size: 24px;
    letter-spacing: -1px;

    .additional {
      color: rgba(50, 55, 91, 0.73);
    }
  }
`;

const Badge = ({ id, text, value, additionalValue, IconComponent, isLoading, showWeightIndicator, onExpandClick }) => {
  const badgeValue = isLoading ? (
    <LoadingLabel loading={true} showText={false} />
  ) : (
    <>
      {value}
      {additionalValue ? <span className='additional'>&nbsp;{additionalValue}</span> : null}
    </>
  );

  return (
    <BadgeContainer id={id}>
      {onExpandClick && (
        <Icon
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Expand'
          style={{
            fontSize: '10px',
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
          }}
          onClick={onExpandClick}
        >
          open_in_full
        </Icon>
      )}
      {!onExpandClick && showWeightIndicator && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <WeightIndicator size='1rem' />
        </div>
      )}
      <div className='text'>
        {IconComponent ? <IconComponent /> : <InfoIcon />}
        {text || 'Badge'}
      </div>
      <div className='value'>{badgeValue}</div>
    </BadgeContainer>
  );
};

export default Badge;
