import { css } from 'styled-components';
import palette from '../tools/palette';

export const sharedStyles = css`
  color: ${palette.textPrimary};
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
