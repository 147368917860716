import { useState } from 'react';
import { useSelector } from 'react-redux';
import SvgIcon from '@material-ui/core/SvgIcon';

import useFilters from '../../../hooks/useFilters';
import { EMPTY_FILTER_QUERY } from '../../FilterForm/lib';
import { FILTER_ID_ALL, FILTER_ID_CURRENT } from '../../../constants';
import * as api from '../../tools/api';
import { getTimestampString, saveCsv } from '../../tools/export';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import CircularProgress from '@material-ui/core/CircularProgress';
import SidebarItem from './SidebarItem';

// https://mui.com/material-ui/material-icons/?query=download&theme=Outlined&selected=FileDownloadOutlined
const FileDownloadOutlined = (props) => (
  <SvgIcon {...props}>
    <path d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z'></path>
  </SvgIcon>
);

const SidebarItemExport = () => {
  const { id, title: dashboardTitle } = useSelector((state) => state.dashboard.dashboard);
  const { translationUsed, weightedMetricsUsed } = useSelector((state) => state.app);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const { query } = useSelector((state) => state.filter);
  const [filters] = useFilters();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    let exportPayload = EMPTY_FILTER_QUERY;

    const savedFilters = filters.filter((filter) => ![FILTER_ID_CURRENT, FILTER_ID_ALL].includes(filter.id));

    if (savedFilters.length > 0) {
      exportPayload = savedFilters.map((filter) => ({
        ...filter,
        filter: filter.query,
      }));
    }

    if (query) {
      exportPayload = query;
    }

    const startTime = new Date().getTime();
    const data = await api.fetchDashboardExport(id, translationUsed, exportPayload);
    const timestampString = getTimestampString();
    const filename = `${dashboardTitle} ${timestampString}`;
    saveCsv(data, filename, false);
    setLoading(false);
    const endTime = new Date().getTime();
    eventsTracker.track(EVENTS.DASHBOARD_RESULTS_EXPORT, {
      'Filter Count': filters.filter((filter) => filter.id !== FILTER_ID_ALL).length,
      'Weighted Metrics': weightedMetricsUsed,
      Breakdown: !!breakdownFilter,
      'Load Time': endTime - startTime,
    });
  };

  return (
    <SidebarItem
      icon={loading ? <CircularProgress color='inherit' size={24} /> : <FileDownloadOutlined />}
      onClick={loading ? () => {} : handleClick}
      tooltip='Export Dashboard'
    />
  );
};

export default SidebarItemExport;
