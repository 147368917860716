import ReactGA from 'react-ga4';
import CONFIG from '../../config';
import { IEventsTrackerService } from './EventsTrackerService';

class GoogleTracker implements IEventsTrackerService {
  isTrackingEnabled = true;

  constructor() {
    ReactGA.initialize(CONFIG.GOOGLE_ANALYTICS.TRACKING_ID, {
      testMode: CONFIG.APP.ENV === 'develop',
    });
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
    });
  }

  identify(uniqId: string) {
    if (!this.isTrackingEnabled) {
      return;
    }

    ReactGA.set({
      userId: uniqId,
    });
  }

  track(event: string, properties = {}) {
    if (!this.isTrackingEnabled) {
      return;
    }

    ReactGA.event(event, properties);
  }

  trackImmediately(event: string, properties = {}) {
    ReactGA.event(event, {
      ...properties,
      transport: 'beacon',
    });
  }

  disableTracking() {
    this.isTrackingEnabled = false;
  }

  enableTracking() {
    this.isTrackingEnabled = true;
  }
}

export default GoogleTracker;
