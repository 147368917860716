import React from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';

import { StyledIntegrationAddButton, StyledIntegrationTitle } from '../../style';

const NoDataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const NoDataContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

interface INoDataView {
  title: string;
  handleAdd: () => void;
  instructionsView?: () => React.JSX.Element;
}

function NoDataView({ title, handleAdd, instructionsView: InstructionsView }: INoDataView) {
  return (
    <>
      <NoDataHeader>
        <StyledIntegrationTitle>{title}</StyledIntegrationTitle>
        <StyledIntegrationAddButton data-testid='no-data-view-addconnection' startIcon={<AddIcon />} onClick={handleAdd}>
          Add Connection
        </StyledIntegrationAddButton>
      </NoDataHeader>
      <NoDataContent>{InstructionsView && <InstructionsView />}</NoDataContent>
    </>
  );
}

export default NoDataView;
