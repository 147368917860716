import { transformQuery } from 'react-querybuilder';
import format from 'date-fns/format';
import type { RuleGroupTypeAny } from 'react-querybuilder/dist/types/types/index.noReact';
import { RuleGroupType } from 'react-querybuilder/dist/types/types/ruleGroups';

import { API_ARG_DATE_FORMAT } from '../../models/TimeRange';
import { Query, Rule } from '../../types/Query';
import { COMBINATOR, OPERATOR_NAME, OPERATOR_NAME_LABEL, RULE_TYPE } from './constants';
import { DATE_FORMAT_API, DATE_FORMAT_DISPLAY, FILTER_TYPE } from '../../constants';
import { parse } from 'date-fns';
import { DescriptionElement, ELEMENT_TYPE } from './SegmentDescription/SegmentDescriptionContainer';

export const EMPTY_QUERY = Object.freeze({
  rules: [],
  combinator: COMBINATOR.AND,
});

export const transformQueryToFilter = (query: RuleGroupType) => {
  const valuesOperators = [OPERATOR_NAME.IN, OPERATOR_NAME.NOT_IN];
  const sentimentOperators = [OPERATOR_NAME.SENTIMENT];

  return transformQuery(query, {
    ruleProcessor: (rule) => {
      const { field, operator, value } = rule;
      if (valuesOperators.includes(operator)) {
        return {
          operator,
          values: value,
          dataSourceID: field,
          type: RULE_TYPE.VALUES,
        };
      } else if (sentimentOperators.includes(operator)) {
        return {
          operator,
          values: value,
          dataSourceID: field,
          type: RULE_TYPE.SENTIMENT,
        };
      } else {
        if (operator === OPERATOR_NAME.DATE_RANGE && !!value) {
          return {
            rules: [
              {
                type: RULE_TYPE.DATE,
                operator: OPERATOR_NAME.GREATER_THAN_OR_EQUAL_TO,
                value: format(value.startDate, API_ARG_DATE_FORMAT),
              },
              {
                type: RULE_TYPE.DATE,
                operator: OPERATOR_NAME.LESS_THAN_OR_EQUAL_TO,
                value: format(value.endDate, API_ARG_DATE_FORMAT),
              },
            ],
            combinator: COMBINATOR.AND,
            type: RULE_TYPE.GROUP,
          };
        }
        return { operator, value, type: RULE_TYPE.DATE };
      }
    },
    ruleGroupProcessor: (ruleGroup: RuleGroupTypeAny) => {
      const { rules, combinator } = ruleGroup as RuleGroupType;
      return { rules, combinator, type: RULE_TYPE.GROUP };
    },
  });
};

export const EMPTY_FILTER_QUERY = transformQueryToFilter(EMPTY_QUERY);

export const transformFilterToQuery = (filter: Query) => {
  const extractDateRangeRule = (query: Query | Rule, isRoot = false): RuleGroupTypeAny => {
    if (query.type === RULE_TYPE.GROUP) {
      const { rules, combinator } = query as Query;
      if (
        !isRoot &&
        combinator === COMBINATOR.AND &&
        rules.length === 2 &&
        rules[0].type === RULE_TYPE.DATE &&
        rules[1].type === RULE_TYPE.DATE &&
        (rules[0] as Rule).operator === OPERATOR_NAME.GREATER_THAN_OR_EQUAL_TO &&
        (rules[1] as Rule).operator === OPERATOR_NAME.LESS_THAN_OR_EQUAL_TO
      ) {
        return {
          rules: [],
          field: 'date',
          operator: OPERATOR_NAME.DATE_RANGE,
          value: {
            startDate: new Date((rules[0] as Rule).value),
            endDate: new Date((rules[1] as Rule).value),
          },
        } as RuleGroupTypeAny;
      } else {
        return {
          ...query,
          rules: (query as Query).rules.map((rule) => extractDateRangeRule(rule as Query)),
        } as RuleGroupType;
      }
    } else {
      return query as RuleGroupTypeAny;
    }
  };

  const processedFilter = extractDateRangeRule(filter, true);

  return transformQuery(processedFilter, {
    ruleProcessor: (rule) => {
      const { dataSourceID, operator, value, values, type } = rule as any;
      if (dataSourceID) {
        if (type === FILTER_TYPE.SENTIMENT) {
          return {
            operator: OPERATOR_NAME.SENTIMENT,
            value: values,
            field: dataSourceID,
          };
        }
        return { operator, value: values, field: dataSourceID };
      } else {
        return { operator, value, field: 'date' };
      }
    },
  });
};

export const isQueryValid = (rule: Query | Rule) => {
  switch (rule.type) {
    case RULE_TYPE.GROUP:
      if ((rule as Query).rules.length === 0) {
        return false;
      }
      for (const groupRule of (rule as Query).rules) {
        if (!isQueryValid(groupRule)) {
          return false;
        }
      }
      return true;
    case RULE_TYPE.DATE:
      return !!(rule as Rule).operator && !!(rule as Rule).value && !((rule as Rule).value instanceof Date);
    case RULE_TYPE.VALUES:
    case RULE_TYPE.SENTIMENT:
      return !!(rule as Rule).operator && !!(rule as Rule).values && (rule as Rule).values.length > 0;
    default:
      return false;
  }
};

export const narrowQueryWithRule = (query: Query, rule: Rule | Query): Query => {
  const rules = query === EMPTY_FILTER_QUERY ? [rule as Query] : [query, rule as Query];
  return {
    rules,
    combinator: COMBINATOR.AND,
    type: RULE_TYPE.GROUP,
  };
};

/**
 * Removes invalid rules and groups from a query
 * @param query
 * @param depth
 * @returns {{combinator, rules: *[], type: string}|null|*}
 */
export const removeInvalidRulesAndGroups = (query: Query, depth = 0): Query | null => {
  if (query.type === RULE_TYPE.GROUP) {
    const { rules, combinator } = query;
    const newRules = [];
    for (const rule of rules) {
      const newRule = removeInvalidRulesAndGroups(rule as Query, depth + 1);
      if (newRule) {
        newRules.push(newRule);
      }
    }
    if (newRules.length === 0 && depth > 0) {
      return null;
    } else {
      return { rules: newRules, combinator, type: RULE_TYPE.GROUP };
    }
  } else {
    if (isQueryValid(query)) {
      return query;
    } else {
      return null;
    }
  }
};

export const createInValuesRule = (dataSourceID: string, values: string[]): Rule => {
  return {
    dataSourceID,
    values,
    operator: OPERATOR_NAME.IN,
    type: RULE_TYPE.VALUES,
  };
};

export const createInCustomersValuesRule = (dataSourceID: string, values: string[]): Rule => {
  return {
    dataSourceID,
    values,
    operator: OPERATOR_NAME.IN,
    type: RULE_TYPE.CUSTOMERS,
  };
};

export const createContainsValuesRule = (dataSourceID: string, value: string): Rule => {
  return {
    dataSourceID,
    values: [value],
    operator: OPERATOR_NAME.CONTAINS,
    type: RULE_TYPE.VALUES,
  };
};

export const createDateRule = (operator: string, value: Date): Partial<Rule> => {
  return {
    operator,
    value,
    type: RULE_TYPE.DATE,
  };
};

export const createGroup = (combinator: string, rules: Query[] | Rule[]): Query => {
  return {
    combinator,
    rules,
    type: RULE_TYPE.GROUP,
  };
};

export const countProperties = (query: Query) => {
  let count = 0;
  if (query.type === RULE_TYPE.GROUP) {
    for (const rule of query.rules) {
      count += countProperties(rule as Query);
    }
  } else {
    count++;
  }
  return count;
};

/**
 * Counts the number of each combinator in the query
 * @param query the query to count the combinators
 * @param counter an object with the combinator as key and the number of times it appears in the query as value
 * @returns {*} an object with the combinator as key and the number of times it appears in the query as value
 */
export const countCombinators = (query: Query, counter: { [key: string]: number }) => {
  if (query.combinator) {
    let localCounter = { ...counter };
    localCounter[query.combinator] = localCounter[query.combinator] ? localCounter[query.combinator] + 1 : 1;
    for (const rule of query.rules) {
      localCounter = countCombinators(rule as Query, localCounter);
    }
    return localCounter;
  } else {
    return counter;
  }
};

/**
 * Finds the combinator that changed between the two queries
 * @param current
 * @param prev
 * @returns {string|null} the combinator that changed or null if no combinator changed
 */
export const findChangedCombinator = (current: Query, prev: Query) => {
  const prevCombinatorsCount = countCombinators(prev, {});
  const currentCombinatorsCount = countCombinators(current, {});

  const prevGroupsCount = Object.values(prevCombinatorsCount).reduce((acc, v) => acc + v, 0);
  const currentGroupsCount = Object.values(currentCombinatorsCount).reduce((acc, v) => acc + v, 0);

  if (prevGroupsCount !== currentGroupsCount) {
    // new group added
    return null;
  }

  for (const key of Object.keys(currentCombinatorsCount)) {
    if (currentCombinatorsCount[key] > (prevCombinatorsCount[key] || 0)) {
      return key;
    }
  }

  return null;
};

export function createDescriptionFrom(query: Query | Rule): DescriptionElement[] {
  switch (query.type) {
    case RULE_TYPE.GROUP:
      if ((query as Query).rules.length === 0) {
        return [];
      }
      const ruleDescriptions = (query as Query).rules.map((rule) => createDescriptionFrom(rule as Query));
      if (ruleDescriptions.length === 1) {
        return ruleDescriptions[0];
      } else {
        return ruleDescriptions
          ?.flatMap((e) => [
            ...e,
            {
              type: ELEMENT_TYPE.TEXT,
              text: (query as Query).combinator === COMBINATOR.AND ? ', AND ' : ', OR ',
            },
          ])
          .slice(0, -1);
      }
    case RULE_TYPE.VALUES:
    case RULE_TYPE.SENTIMENT:
      if ((query as Rule).values.length === 0) {
        return [];
      }
      const elements = (query as Rule).values.map((value) => ({
        type: ELEMENT_TYPE.DATA_SOURCE_VALUE,
        label: value,
        dataSourceID: (query as Rule).dataSourceID,
      }));

      const text =
        (query as Rule).operator === OPERATOR_NAME.IN || (query as Rule).operator === OPERATOR_NAME.SENTIMENT ? 'are ' : 'are not ';

      return [
        {
          type: ELEMENT_TYPE.TEXT,
          text,
        },
        ...elements
          .flatMap((e) => [
            e,
            {
              type: ELEMENT_TYPE.TEXT,
              text: (query as Rule).operator === OPERATOR_NAME.NOT_IN ? ' nor ' : ' or ',
            },
          ])
          .slice(0, -1),
      ];
    case RULE_TYPE.DATE:
      const date = format(parse((query as Rule).value, DATE_FORMAT_API, new Date()), DATE_FORMAT_DISPLAY);
      return [
        {
          type: ELEMENT_TYPE.TEXT,
          text: `are ${OPERATOR_NAME_LABEL[(query as Rule).operator].toLowerCase()} ${date}`,
        },
      ];
    default:
      return [];
  }
}
