import FileIcon from '../../assets/images/file-icon.png';
import SurveyMonkeyIcon from '../../assets/images/surveymonkey.png';
import TypeformIcon from '../../assets/images/typeform.png';
import { SurveySourceEnum } from '../../types/connections';

import { CONNECTORS_COLUMN } from './types';
import AzureSASInstructions from './Integrations/NoData/AzureSASInstructions';
import QualtricsInstructions from './Integrations/NoData/QualtricsInstructionsContainer';

export const CONNECTORS_COLUMNS: Record<string, CONNECTORS_COLUMN> = {
  ICON: {
    width: '64px',
    align: 'center',
  },
  DATA_SOURCE: {
    width: '35%',
    align: 'left',
  },
  RESPONSES: {
    width: '10%',
    align: 'right',
  },
  LAST_UPDATED: {
    width: '15%',
    align: 'left',
  },
  DASHBOARDS_CONNECTED_TO: {
    width: '15%',
    align: 'right',
  },
  ENRICHMENT_DATA: {
    width: '15%',
    align: 'center',
  },
  CONNECTION_STATUS: {
    width: '15%',
    align: 'center',
  },
  EXPAND: {
    width: '30px',
    align: 'center',
  },
};

// This is needed until we remove our dependence on intercom
export const SOURCES: Partial<
  Record<
    SurveySourceEnum,
    {
      title: string;
      description: string;
      icon: string;
      intercomMessage: string;
      intercomEnrichmentMessage: (title: string, existingEnrichment?: string | null) => string;
    }
  >
> = {
  [SurveySourceEnum.CSV]: {
    title: 'CSV Upload(s)',
    description: 'Upload a CSV file and augment the dashboard that is connected to that file.',
    icon: FileIcon,
    intercomMessage: 'I would like to upload a new CSV file to this company...',
    intercomEnrichmentMessage: (title, existingEnrichment) =>
      existingEnrichment
        ? `I would like to overwrite enrichment file “${existingEnrichment}” and upload new enrichment data to the CSV file “${title}”... |`
        : `I would like to upload Enrichment data to the CSV file “${title}”...`,
  },
  [SurveySourceEnum.TYPEFORM]: {
    title: 'Typeform',
    description: 'Connect Typeform surveys and augment the dashboard that is connected to that survey data.',
    icon: TypeformIcon,
    intercomMessage: 'I would like to connect Typeform data to this company... |',
    intercomEnrichmentMessage: (title, existingEnrichment) =>
      existingEnrichment
        ? `I would like to overwrite enrichment file “${existingEnrichment}” and upload new enrichment data to the Typeform survey “${title}”... |`
        : `I would like to upload Enrichment data to the Typeform survey “${title}”...`,
  },
  [SurveySourceEnum.SURVEYMONKEY]: {
    title: 'SurveyMonkey',
    description: 'Connect SurveyMonkey surveys and augment the dashboard that is connected to that survey data.',
    icon: SurveyMonkeyIcon,
    intercomMessage: 'I would like to connect SurveyMonkey data to this company...',
    intercomEnrichmentMessage: (title, existingEnrichment) =>
      existingEnrichment
        ? `I would like to overwrite enrichment file “${existingEnrichment}” and upload new enrichment data to the SurveyMonkey survey “${title}”... |`
        : `I would like to upload Enrichment data to the SurveyMonkey survey “${title}”...`,
  },
};

export const SOURCES_LIST: SurveySourceEnum[] = [
  SurveySourceEnum.AZURE,
  SurveySourceEnum.QUALTRICS,
  SurveySourceEnum.CSV,
  SurveySourceEnum.SURVEYMONKEY,
  SurveySourceEnum.TYPEFORM,
];

export const SURVEY_SOURCE_TO_INTEGRATION_INSTRUCTIONS: Record<SurveySourceEnum, (() => React.JSX.Element) | undefined> = {
  [SurveySourceEnum.AZURE]: AzureSASInstructions,
  [SurveySourceEnum.QUALTRICS]: QualtricsInstructions,
  [SurveySourceEnum.SURVEYMONKEY]: undefined,
  [SurveySourceEnum.TYPEFORM]: undefined,
  [SurveySourceEnum.CSV]: undefined,
};

export const EDITABLE_SURVEY_SOURCES: SurveySourceEnum[] = [SurveySourceEnum.AZURE, SurveySourceEnum.QUALTRICS];

export const OAUTH_REAUTH_SOURCES: SurveySourceEnum[] = [SurveySourceEnum.QUALTRICS];

export const SURVEY_SOURCE_TO_IS_NEW: Record<SurveySourceEnum, boolean> = {
  [SurveySourceEnum.AZURE]: false,
  [SurveySourceEnum.QUALTRICS]: true,
  [SurveySourceEnum.SURVEYMONKEY]: false,
  [SurveySourceEnum.TYPEFORM]: false,
  [SurveySourceEnum.CSV]: false,
};

export const SURVEY_SOURCE_TO_SCOPES: Record<SurveySourceEnum, string[]> = {
  [SurveySourceEnum.AZURE]: [],
  [SurveySourceEnum.QUALTRICS]: ['read:survey_responses', 'read:surveys'],
  [SurveySourceEnum.SURVEYMONKEY]: [],
  [SurveySourceEnum.TYPEFORM]: [],
  [SurveySourceEnum.CSV]: [],
};

export const SURVEY_SOURCE_TO_CONNECTION_ERROR_MESSAGE: Record<SurveySourceEnum, string> = {
  [SurveySourceEnum.AZURE]: 'There was an error connecting to Azure, please update your Azure SAS configuration.',
  [SurveySourceEnum.QUALTRICS]: 'There was an error connecting to Qualtrics, please Re-authorize your Qualtrics.',
  [SurveySourceEnum.SURVEYMONKEY]: '',
  [SurveySourceEnum.TYPEFORM]: '',
  [SurveySourceEnum.CSV]: '',
};
