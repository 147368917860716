import { Period, PeriodsToggle, TotalBadgeContainer, TrendsLoadingLabel } from './style';
import { FETCH_STATUS, PERIOD } from '../../../constants';
import SurveyTotalTrendsChart from './SurveyTotalTrendsChart';

const SurveyTotalTrendsChartWrapper = ({ total, surveysTrends, period, loading, onSelectPeriod, onSizeToggle = null }) => {
  return (
    <TotalBadgeContainer data-testid='total-badge'>
      <SurveyTotalTrendsChart surveyTrends={surveysTrends} total={total} loading={loading === FETCH_STATUS.LOADING} period={period} />
      <TrendsLoadingLabel loading={loading === FETCH_STATUS.LOADING} />
      <PeriodsToggle>
        <Period
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Day'
          data-testid='toggle-day'
          onClick={() => onSelectPeriod(PERIOD.DAY)}
          $selected={period === PERIOD.DAY}
        >
          D
        </Period>
        <Period
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Week'
          data-testid='toggle-week'
          onClick={() => onSelectPeriod(PERIOD.WEEK)}
          $selected={period === PERIOD.WEEK}
        >
          W
        </Period>
        <Period
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Month'
          data-testid='toggle-month'
          onClick={() => onSelectPeriod(PERIOD.MONTH)}
          $selected={period === PERIOD.MONTH}
        >
          M
        </Period>
        <Period
          data-tooltip-id='base-tooltip'
          data-tooltip-content='Quarter'
          data-testid='toggle-quarter'
          onClick={() => onSelectPeriod(PERIOD.QUARTER)}
          $selected={period === PERIOD.QUARTER}
        >
          Q
        </Period>
        {!!onSizeToggle && (
          <Period onClick={onSizeToggle} data-tooltip-id='base-tooltip' data-tooltip-content='Minimize'>
            _
          </Period>
        )}
      </PeriodsToggle>
    </TotalBadgeContainer>
  );
};

export default SurveyTotalTrendsChartWrapper;
