import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

export const ControlRemoveActionButton = ({
  className,
  handleOnClick,
  label,
  title,
  disabled,
  disabledTranslation,
  // Props that should not be in extraProps
  testID: _testID,
  rules: _rules,
  level: _level,
  path: _path,
  context: _context,
  validation: _validation,
  ...extraProps
}) => (
  <IconButton
    className={className}
    title={disabledTranslation && disabled ? disabledTranslation.title : title}
    size='small'
    disabled={disabled && !disabledTranslation}
    onClick={(e) => handleOnClick(e)}
    {...extraProps}
  >
    <CancelIcon />
  </IconButton>
);

ControlRemoveActionButton.displayName = 'ControlRemoveActionButton';
