import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/cross-transparent-icon.svg';

export const ConceptPillContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 180px;
`;

interface IBaseLabel {
  $isDisabled: boolean;
  $isSelected: boolean;
  $isHierarchy?: boolean;
}

export const BaseLabel = styled.div<IBaseLabel>`
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: var(--dark-purple);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  height: 24px;
  border-radius: var(--double-border-radius);
  cursor: pointer;
  overflow: hidden;

  @media only screen and (max-width: 1500px) {
    max-width: 64px;
  }
`;

export const ParentLabel = styled(BaseLabel)`
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple-transparent-10)' : 'var(--white)')};
  color: var(--dark-purple);
  border: 1px solid var(--dark-purple);
  font-weight: ${({ $isSelected }) => ($isSelected ? 'bold' : '400')};

  &:hover {
    background-color: var(--light-purple-50-transparency);
    color: var(--dark-purple);
  }
`;

export const ChildLabel = styled(BaseLabel)`
  margin-left: ${({ $isHierarchy }) => ($isHierarchy ? '32px' : '0')};
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'var(--beehive-purple-transparent-10)')};
  color: ${({ $isSelected }) => ($isSelected ? 'var(--white)' : 'var(--dark-purple)')};
  border: none;

  &:hover {
    background-color: var(--light-purple-50-transparency);
    color: var(--dark-purple);
  }
`;

export const Label = styled(BaseLabel)`
  background-color: var(--beehive-purple-transparent-10);
  color: ${({ $isSelected }) => ($isSelected ? 'var(--dark-purple)' : 'var(--dark-purple)')};
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'var(--dark-purple)' : 'none')};
  font-weight: ${({ $isSelected }) => ($isSelected ? 'bold' : '400')};
`;

export const Roundedlabel = styled(Label)`
  border-radius: 12px;
  width: 24px;
  height: 24px;
  padding: 0 25%;
  &:hover {
    background-color: var(--light-purple-50-transparency);
    color: var(--dark-purple);
  }
  & svg {
    min-width: 12px;
    min-height: 12px;
  }
`;

export const EditTopicIconContainer = styled.div`
  width: 18px;
  height: 18px;
  padding: 0 3px;
  cursor: pointer;
  background-color: var(--transparent);
  border-radius: 50%;
  &:hover {
    background-color: var(--grey-bar);
  }
`;

export const Container = styled.div`
  min-width: 250px;
  width: 100%;
  height: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
  font-family: Rubik;
  flex-shrink: 0;

  @media only screen and (max-width: 1800px) {
    max-width: 220px;
    min-width: 160px;
  }
`;

export const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
`;

export const Percent = styled.div`
  width: 80px;
  text-align: right;
  margin-right: 4px;
  font-size: 14px;
  line-height: 24px;

  @media only screen and (max-width: 1800px) {
    width: 50px;
  }
`;

export const PercentageBar = styled.div<{
  $percent: number;
  $color: string;
}>`
  height: 24px;
  border-radius: var(--double-border-radius);
  width: ${({ $percent }) => `${$percent}`}%;
  background-color: ${({ $color }) => `${$color}`};
`;

export const Number = styled.span`
  background-color: var(--grey-bar);
  border-radius: var(--border-radius);
  font-size: 14px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  padding: 1px 2px;
`;

export const BarContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

export const SentimentContainer = styled.div`
  padding-left: 0.5em;
  min-width: 44px;
`;

export const Bar = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  background-color: var(--grey-bar);
  border-radius: var(--double-border-radius);
  border: 1px solid var(--grey-bar);
`;

export const AverageMarker = styled.div<{ $avgPct: number }>`
  position: absolute;
  top: 0;
  height: 100%;
  border-left: 2px dashed var(--white);
  left: ${({ $avgPct }) => `${$avgPct}`}%;
`;

export const LabelCloseIcon = styled(CloseIcon)`
  max-width: 16px;
  max-height: 16px;
  min-width: 16px;
  min-height: 16px;
  cursor: pointer;
  margin-left: 8px;
  background-color: var(--beehive-purple);
  border-radius: 8px;
  padding: 2px;
`;
