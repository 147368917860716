import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import { CancelButton, CustomDialogActions, DeleteButton } from './styles';

const HiveDeleteConfirmationDialog = ({ onCancel, onConfirm, open, title }) => {
  return (
    <Dialog maxWidth='xs' open={open}>
      <DialogTitle>Delete {title}</DialogTitle>
      <DialogContent>Are you sure you want to delete this hive?</DialogContent>
      <CustomDialogActions style={{ justifyContent: 'right' }}>
        <CancelButton onClick={onCancel} color='primary'>
          Cancel
        </CancelButton>
        <DeleteButton variant='outlined' onClick={onConfirm} startIcon={<DeleteForeverOutlinedIcon />}>
          Delete
        </DeleteButton>
      </CustomDialogActions>
    </Dialog>
  );
};

export default HiveDeleteConfirmationDialog;
