import { createContext, useMemo, useState } from 'react';

export const SidebarDashboardContext = createContext(null);

export const SidebarDashboardProvider = ({ children }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [savedFilter, setSavedFilter] = useState(null);
  const [createFilterIndex, setCreateFilterIndex] = useState(null);
  const [savedFiltersIndex, setSavedFiltersIndex] = useState(null);

  const value = useMemo(
    () => ({
      activeItemIndex,
      setActiveItemIndex,
      savedFilter,
      setSavedFilter,
      createFilterIndex,
      setCreateFilterIndex,
      savedFiltersIndex,
      setSavedFiltersIndex,
    }),
    [activeItemIndex, savedFilter, createFilterIndex, savedFiltersIndex],
  );

  return <SidebarDashboardContext.Provider value={value}>{children}</SidebarDashboardContext.Provider>;
};
