import styled from 'styled-components';
import { Popper } from '@material-ui/core';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/cross-icon.svg';
import { AnswerRemovableItem } from '../Labels/ConceptPill';
import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import ClickAwayListener from '../../../UI/ClickAwayListener';

import LabelsAutocomplete from './LabelsAutocomplete';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 280px;
  min-width: 280px;
  padding: 8px;
  border-radius: var(--double-border-radius);
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow:
    0px 1px 2px 0px rgba(15, 12, 12, 0.3),
    0px 2px 6px 2px rgba(15, 12, 12, 0.15);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const SelecLabelContainer = styled.div``;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  & > div {
    max-width: 100%;
  }
`;

const PopperStyled = styled(Popper)`
  z-index: 103;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

interface IManageTopicTagsModal {
  isOpen: boolean;
  anchorEl: any;
  labels: string[];
  rowLabels: string[];
  isLoading?: boolean;
  onCloseClick: () => void;
  onRemoveLabelClick: (label: string) => void;
  onAddLabelClick: (label: string) => void;
}

function ManageTopicTagsModal({
  anchorEl,
  isOpen,
  labels,
  rowLabels,
  isLoading = false,
  onCloseClick,
  onRemoveLabelClick,
  onAddLabelClick,
}: IManageTopicTagsModal) {
  const cleanLabels = labels.filter((label) => !rowLabels.includes(label));

  return (
    <PopperStyled anchorEl={anchorEl?.current} open={isOpen} placement='right-start'>
      <ClickAwayListener onClickAway={onCloseClick}>
        <Container>
          <HeaderContainer>
            <Title>Manage Topic Tags</Title>
            <StyledCloseIcon onClick={onCloseClick} />
          </HeaderContainer>
          {isLoading ? (
            <LoadingContainer>
              <LoadingLabel loading={true} />
            </LoadingContainer>
          ) : (
            <>
              <SelecLabelContainer>
                <LabelsAutocomplete labels={cleanLabels} onAddLabelClick={onAddLabelClick} />
              </SelecLabelContainer>
              <LabelsContainer>
                {rowLabels.map((label) => {
                  return <AnswerRemovableItem key={label} disabled={false} text={label} onClick={onRemoveLabelClick.bind(null, label)} />;
                })}
              </LabelsContainer>
            </>
          )}
        </Container>
      </ClickAwayListener>
    </PopperStyled>
  );
}

export default ManageTopicTagsModal;
