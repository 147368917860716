import { ClickAwayListener, Popper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
  border-radius: var(--double-border-radius);
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow:
    0px 1px 2px 0px rgba(15, 12, 12, 0.3),
    0px 2px 6px 2px rgba(15, 12, 12, 0.15);
`;
const ButtonItem = styled.div`
  display: flex;
  height: 43px;
  padding: 12px 16px;
  align-items: center;
  align-content: center;
  cursor: pointer;
  &:hover {
    background-color: var(--light-gray);
  }
`;

interface IAnswerMoreMenu {
  isOpen: boolean;
  anchorEl: any;
  responseID?: string;
  onCloseClick: () => void;
  onShowFullRecordClick: (responseID: string) => void;
  onSendFeedbackClick: (responseID: string) => void;
}

function AnswerMoreMenu({ isOpen, anchorEl, responseID, onCloseClick, onShowFullRecordClick, onSendFeedbackClick }: IAnswerMoreMenu) {
  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl?.current}
      placement='bottom-end'
      transition
      disablePortal
      data-testid='widget-group-menu'
      style={{ zIndex: 103 }}
    >
      <ClickAwayListener onClickAway={onCloseClick}>
        <MenuContainer>
          <ButtonItem onClick={onShowFullRecordClick.bind(null, responseID!)}>Show full record</ButtonItem>
          <ButtonItem onClick={onSendFeedbackClick.bind(null, responseID!)}>Send feedback</ButtonItem>
        </MenuContainer>
      </ClickAwayListener>
    </Popper>
  );
}

export default AnswerMoreMenu;
