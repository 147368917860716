import styled from 'styled-components';

export const Segments = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Segment = styled.div`
  margin-right: 16px;
  margin-bottom: 1em;
`;

export const SegmentColor = styled.div<{ $color: string }>`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: ${({ $color }) => $color};
`;

export const SegmentItemContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 24px;
  gap: 8px;
  cursor: pointer;
  font-weight: bold;
  font-family: Rubik;
  border-radius: var(--double-border-radius);
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'none' : 'var(--dark-gray)')};
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple-transparent-10)' : 'var(--white)')};
`;

export const SegmentNumber = styled.div``;
