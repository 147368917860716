import { ResponsesSorting } from '../reducers/openEndedWidget';
import { Query } from '../../types/Query';

export const OPEN_ENDED_WIDGET_INITIALIZE = 'OPEN_ENDED_WIDGET_INITIALIZE';
export const OPEN_ENDED_WIDGET_SELECT_CONCEPT = 'OPEN_ENDED_WIDGET_SELECT_CONCEPT';

export const OPEN_ENDED_WIDGET_LOAD_ANSWERS_START = 'OPEN_ENDED_WIDGET_LOAD_ANSWERS_START';
export const OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE = 'OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE';
export const OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS = 'OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS';

export const OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_START = 'OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_START';
export const OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_FAILURE = 'OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_FAILURE';
export const OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_SUCCESS = 'OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_SUCCESS';
export const OPEN_ENDED_WIDGET_INVALIDATE_EXTRA_CONCEPTS_LOADING_STATE = 'OPEN_ENDED_WIDGET_INVALIDATE_EXTRA_CONCEPTS_LOADING_STATE';

export const OPEN_ENDED_WIDGET_SORTING_CHANGE = 'OPEN_ENDED_WIDGET_SORTING_CHANGE';
export const OPEN_ENDED_WIDGET_TOGGLE_BOOKMARKED_ONLY = 'OPEN_ENDED_WIDGET_TOGGLE_BOOKMARKED_ONLY';
export const OPEN_ENDED_WIDGET_SEARCH_STRING_CHANGE = 'OPEN_ENDED_WIDGET_SEARCH_STRING_CHANGE';

export const OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_START = 'OPEN_ENDED_WIDGET_BOOKMARK_RESPONSE_START';
export const OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_FAILURE = 'OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_FAILURE';
export const OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_SUCCESS = 'OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_SUCCESS';
export const OPEN_ENDED_WIDGET_INVALIDATE_BOOKMARKS_LOADING_STATE = 'OPEN_ENDED_WIDGET_INVALIDATE_BOOKMARKS_LOADING_STATE';

interface IInitialize {
  dataSourceID: string;
  widgetID: string;
}
export const initialize = ({ dataSourceID, widgetID }: IInitialize) => ({
  type: OPEN_ENDED_WIDGET_INITIALIZE,
  payload: {
    dataSourceID,
    widgetID,
  },
});

interface ILoadAnswersStart {
  widgetID: string;
  offset: number;
  sorting: ResponsesSorting;
  isBookmarkOnly: boolean;
  filterQuery: Query;
}
export const loadAnswersStart = ({ widgetID, offset, sorting, filterQuery, isBookmarkOnly }: ILoadAnswersStart) => ({
  type: OPEN_ENDED_WIDGET_LOAD_ANSWERS_START,
  payload: {
    widgetID,
    offset,
    sorting,
    filterQuery,
    isBookmarkOnly,
  },
});

interface ILoadAnswersFailure {
  widgetID: string;
}
export const loadAnswersFailure = ({ widgetID }: ILoadAnswersFailure) => ({
  type: OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE,
  payload: {
    widgetID,
  },
});

interface ILoadAnswersSuccess {
  widgetID: string;
  totalResponses: number;
  responses: Response[];
}
export const loadAnswersSuccess = ({ widgetID, totalResponses, responses }: ILoadAnswersSuccess) => ({
  type: OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS,
  payload: {
    widgetID,
    totalResponses,
    responses,
  },
});

interface ISelectConcept {
  title: string;
  widgetID: string;
}
export const selectConcept = ({ title, widgetID }: ISelectConcept) => ({
  type: OPEN_ENDED_WIDGET_SELECT_CONCEPT,
  payload: {
    title,
    widgetID,
  },
});

interface ILoadExtraConceptsStart {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const loadExtraConceptsStart = ({ widgetID, responseID, rowIndex }: ILoadExtraConceptsStart) => ({
  type: OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_START,
  payload: {
    widgetID,
    responseID,
    rowIndex,
  },
});

interface ILoadExtraConceptsFailure {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const loadExtraConceptsFailure = ({ widgetID, responseID, rowIndex }: ILoadExtraConceptsFailure) => ({
  type: OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_FAILURE,
  payload: {
    widgetID,
    responseID,
    rowIndex,
  },
});

export type ExtraConcept = {
  Heading: string;
  DataSourceID: string;
};
interface ILoadExtraConceptsSuccess {
  widgetID: string;
  responseID: string;
  rowIndex: number;
  extraConcepts: ExtraConcept[];
}
export const loadExtraConceptsSuccess = ({ widgetID, responseID, rowIndex, extraConcepts }: ILoadExtraConceptsSuccess) => ({
  type: OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_SUCCESS,
  payload: {
    widgetID,
    responseID,
    rowIndex,
    extraConcepts,
  },
});

interface IInvalidateExtraConceptLoadingState {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const invalidateExtraConceptLoadingState = ({ widgetID, responseID, rowIndex }: IInvalidateExtraConceptLoadingState) => ({
  type: OPEN_ENDED_WIDGET_INVALIDATE_EXTRA_CONCEPTS_LOADING_STATE,
  payload: {
    widgetID,
    responseID,
    rowIndex,
  },
});

interface IChangeSorting {
  widgetID: string;
  sorting: ResponsesSorting;
}
export const changeSorting = ({ widgetID, sorting }: IChangeSorting) => ({
  type: OPEN_ENDED_WIDGET_SORTING_CHANGE,
  payload: {
    widgetID,
    sorting,
  },
});

interface IToggleBookmarkOnly {
  widgetID: string;
  isBookmarkOnly: boolean;
}
export const toggleBookmarkedOnly = ({ widgetID, isBookmarkOnly }: IToggleBookmarkOnly) => ({
  type: OPEN_ENDED_WIDGET_TOGGLE_BOOKMARKED_ONLY,
  payload: {
    widgetID,
    isBookmarkOnly,
  },
});

interface IChangeSearchString {
  widgetID: string;
  searchString: string;
}
export const changeSearchString = ({ widgetID, searchString }: IChangeSearchString) => ({
  type: OPEN_ENDED_WIDGET_SEARCH_STRING_CHANGE,
  payload: {
    widgetID,
    searchString,
  },
});

interface IToggleBookmarkResponseStart {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const toggleBookmarkResponseStart = ({ widgetID, responseID, rowIndex }: IToggleBookmarkResponseStart) => ({
  type: OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_START,
  payload: {
    widgetID,
    responseID,
    rowIndex,
  },
});
interface IToggleBookmarkResponseFailure {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const toggleBookmarkResponseFailure = ({ widgetID, responseID, rowIndex }: IToggleBookmarkResponseFailure) => ({
  type: OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_FAILURE,
  payload: {
    widgetID,
    responseID,
    rowIndex,
  },
});

interface IToggleBookmarkResponseSuccess {
  widgetID: string;
  responseID: string;
  rowIndex: number;
  isBookmarked: boolean;
}
export const toggleBookmarkResponseSuccess = ({ widgetID, responseID, rowIndex, isBookmarked }: IToggleBookmarkResponseSuccess) => ({
  type: OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_SUCCESS,
  payload: {
    widgetID,
    responseID,
    rowIndex,
    isBookmarked,
  },
});

interface IInvalidateBookmarksLoadingState {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const invalidateBookmarksLoadingState = ({ widgetID, responseID, rowIndex }: IInvalidateBookmarksLoadingState) => ({
  type: OPEN_ENDED_WIDGET_INVALIDATE_BOOKMARKS_LOADING_STATE,
  payload: {
    widgetID,
    responseID,
    rowIndex,
  },
});
