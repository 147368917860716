import { useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { API_BASE_URL } from '../../constants';

const useQualtricsRedirect = (datacenterID: string, clientID: string, scopes: string[]) => {
  const { company } = useAppSelector((state) => state.profile);

  const response_type = 'code';

  const redirectUri = useCallback(() => {
    if (!company) {
      return '';
    }

    return `${API_BASE_URL}/oauth/${company.name}/qualtrics`;
  }, [company]);

  const qualtricsRedirect = useCallback(
    (state: string) => {
      if (!company) {
        return;
      }

      return `https://${datacenterID}.qualtrics.com/oauth2/auth?client_id=${clientID}&response_type=${response_type}&state=${state}&scope=${scopes.join(' ')}&redirect_uri=${redirectUri()}`;

      // uncomment for local development
      //return `http://localhost:5173/?datacenter_id=${datacenterID}&client_id=${clientID}&response_type=${response_type}&state=${state}&scope=${scopes.join(" ")}&redirect_uri=${redirectUri()}`
    },
    [datacenterID, clientID, company, scopes, redirectUri],
  );

  return {
    redirect: qualtricsRedirect,
    redirectUri: redirectUri,
  };
};

export default useQualtricsRedirect;
