import { Brand } from '../../types/brands';

export const BRANDS_UPDATE_BRANDS = 'BRANDS_UPDATE_BRANDS';

interface IUpdateBrands {
  brands?: Brand[];
  error?: boolean;
}
export const updateBrands = ({ brands, error }: IUpdateBrands) => ({
  type: BRANDS_UPDATE_BRANDS,
  payload: {
    brands,
    error,
  },
});
