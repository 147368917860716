import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import MultiChoiceWidget from '../../overview/multi-choice-widget';
import withSummaryPlainLoader from '../hocLoaders/withSummaryPlainLoader';
import withSummaryBreakdownLoader from '../hocLoaders/withSummaryBreakdownLoader';
import WidgetErrorContent from '../WidgetErrorContent';
import { WidgetMessageContent } from '../../UI';

const ComponentPlain = withSummaryPlainLoader(MultiChoiceWidget);
const ComponentBreakdown = withSummaryBreakdownLoader(MultiChoiceWidget);

const BarsWidgetContent = ({
  config,
  onExpand,
  updateAutoSizeHeight,
  refHeader,
  showSettings,
  refCsvExport,
  refContent,
  refFiltersCount,
  query,
  preview = false,
}) => {
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const dataSourceId = config.settings.dataSources[0].id;
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);

  const filterModel = useMemo(() => {
    return {
      dataSourceID: dataSourceId,
    };
  }, [dataSourceId]);

  const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceId);

  if (!dashboardDataSource) {
    return <WidgetErrorContent config={config} />;
  }

  if (dashboardDataSource.isEmpty) {
    return <WidgetMessageContent>This widget doesn't have any responses yet.</WidgetMessageContent>;
  }

  if (breakdownFilter && !preview) {
    return (
      <ComponentBreakdown
        config={config}
        filterModel={filterModel}
        onExpand={onExpand}
        mapBasedBreakdown={true}
        updateAutoSizeHeight={updateAutoSizeHeight}
        breakdownFilter={breakdownFilter}
        refHeader={refHeader}
        showSettings={showSettings}
        refCsvExport={refCsvExport}
        refContent={refContent}
        refFiltersCount={refFiltersCount}
        query={query}
      />
    );
  } else {
    return (
      <ComponentPlain
        config={config}
        filterModel={filterModel}
        onExpand={onExpand}
        updateAutoSizeHeight={updateAutoSizeHeight}
        refHeader={refHeader}
        showSettings={showSettings}
        refCsvExport={refCsvExport}
        refContent={refContent}
        refFiltersCount={refFiltersCount}
        query={query}
        preview={preview}
      />
    );
  }
};

export default BarsWidgetContent;
