import React from 'react';
import styled from 'styled-components';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import palette from '../../components/tools/palette';
import FlexSpacer from '../../components/UI/FlexSpacer';

const TabTutorialBlockWrapper = styled.div`
  background-color: ${palette.lightPurpleTransparent50};
  color: ${palette.darkPurple};
  font-family: Rubik;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 300px;
`;

const NotificationIcon = styled(NotificationsOutlinedIcon)`
  margin-right: 10px;
`;

const CloseIcon = styled(CloseOutlinedIcon)`
  margin-left: 10px;
  cursor: pointer;
`;

interface ITabTutorialBlockProps {
  children: React.ReactNode;
  onClose: () => void;
}

function TabTutorialBlock({ children, onClose }: ITabTutorialBlockProps) {
  return (
    <TabTutorialBlockWrapper>
      <NotificationIcon fontSize='inherit' />
      {children}
      <FlexSpacer />
      <CloseIcon fontSize='inherit' onClick={onClose} />
    </TabTutorialBlockWrapper>
  );
}

export default TabTutorialBlock;
