import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardUpdateWidget } from '../../../../store/actions/dashboard';
import { Grid, TextField } from '@material-ui/core';
import { SaveButton } from '../../../navigation-panel/style';
import StyledDivider from '../../../Divider/Divider';
import { CancelButton, DataViewButton } from '../../../UI';
import WidgetLabelsAutocompleteControl from '../../../UI/LabelsAutocompleteControl/WidgetLabelsAutocompleteControl';
import { PERIOD, WIDGET_TYPE } from '../../../../constants';
import * as api from '../../../tools/api';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import capitalize from 'lodash/capitalize';

export const TRENDS_DISPLAY_MODE = {
  PERCENTAGE: 'percentages',
  VALUE: 'numbers',
};

const TrendsWidgetSettings = ({ config, onClose, componentRef, groupTitle }) => {
  const { translationUsed } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(config.settings);
  const initialTitle = translationUsed ? config.base.title : config.base.originalTitle;
  const [title, setTitle] = useState(initialTitle);
  const [titleError, setTitleError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedItems, setSelectedItems] = useState(config.settings.dataSources[0].selectedItems);

  const dataSourceId = config.settings.dataSources[0].id;

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
    setTitleError(value.length === 0);
  };

  const updateSettings = (param, value) => {
    setSettings({
      ...settings,
      [param]: value,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    let updatedConfig = {
      ...config,
      settings: {
        ...settings,
        dataSources: [
          {
            id: config.settings.dataSources[0].id,
            selectedItems,
          },
        ],
      },
    };
    const trimmedTitle = title.trim();
    if (trimmedTitle !== initialTitle) {
      updatedConfig = {
        ...updatedConfig,
        base: {
          ...updatedConfig.base,
          [translationUsed ? 'title' : 'originalTitle']: trimmedTitle,
        },
      };
    }
    eventsTracker.track(EVENTS.WIDGET_EDIT_SAVE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      'Numbers Display': settings.displayMode === TRENDS_DISPLAY_MODE.PERCENTAGE ? 'Percentage' : 'Numbers',
      'Time Interval': capitalize(settings.aggregationRange),
      'Old Item Count': config.settings.dataSources[0].selectedItems.length,
      'New Item Count': selectedItems.length,
    });
    await api.updateWidget(updatedConfig);
    dispatch(dashboardUpdateWidget(updatedConfig));
    setSaving(false);
    onClose();
  };

  const handleCancelClick = () => {
    eventsTracker.track(EVENTS.WIDGET_EDIT_CANCEL, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
    onClose();
  };

  return (
    <Grid container direction='column' ref={componentRef}>
      <Grid item style={{ padding: '16px' }}>
        <TextField
          error={titleError}
          size='small'
          variant='outlined'
          value={title}
          style={{ width: '100%' }}
          onChange={handleTitleChange}
        />
      </Grid>
      <Grid item>
        <StyledDivider />
      </Grid>
      <Grid item style={{ padding: '16px 16px 0 16px' }}>
        <strong style={{ color: '#2F3151' }}>Display Numbers</strong>
        <Grid container direction='row' style={{ margin: '16px' }}>
          <Grid item style={{ marginRight: '16px' }}>
            <DataViewButton
              size='small'
              className={settings.displayMode === TRENDS_DISPLAY_MODE.PERCENTAGE ? 'active' : ''}
              disableElevation
              variant='contained'
              onClick={() => updateSettings('displayMode', TRENDS_DISPLAY_MODE.PERCENTAGE)}
            >
              Percentage
            </DataViewButton>
          </Grid>
          <Grid item>
            <DataViewButton
              size='small'
              className={settings.displayMode === TRENDS_DISPLAY_MODE.VALUE ? 'active' : ''}
              disableElevation
              variant='contained'
              onClick={() => updateSettings('displayMode', TRENDS_DISPLAY_MODE.VALUE)}
            >
              Numbers
            </DataViewButton>
          </Grid>
        </Grid>
        <strong style={{ color: '#2F3151' }}>Time Intervals</strong>
        <Grid container direction='row' style={{ margin: '16px' }}>
          <Grid item style={{ marginRight: '16px' }}>
            <DataViewButton
              size='small'
              className={settings.aggregationRange === PERIOD.DAY ? 'active' : ''}
              disableElevation
              variant='contained'
              onClick={() => updateSettings('aggregationRange', PERIOD.DAY)}
            >
              Days
            </DataViewButton>
          </Grid>
          <Grid item style={{ marginRight: '16px' }}>
            <DataViewButton
              size='small'
              className={settings.aggregationRange === PERIOD.WEEK ? 'active' : ''}
              disableElevation
              variant='contained'
              onClick={() => updateSettings('aggregationRange', PERIOD.WEEK)}
            >
              Weeks
            </DataViewButton>
          </Grid>
          <Grid item style={{ marginRight: '16px' }}>
            <DataViewButton
              size='small'
              className={settings.aggregationRange === PERIOD.MONTH ? 'active' : ''}
              disableElevation
              variant='contained'
              onClick={() => updateSettings('aggregationRange', PERIOD.MONTH)}
            >
              Months
            </DataViewButton>
          </Grid>
          <Grid item>
            <DataViewButton
              size='small'
              className={settings.aggregationRange === PERIOD.QUARTER ? 'active' : ''}
              disableElevation
              variant='contained'
              onClick={() => updateSettings('aggregationRange', PERIOD.QUARTER)}
            >
              Quarters
            </DataViewButton>
          </Grid>
        </Grid>
        <strong style={{ color: '#2F3151' }}>Selected Items</strong>
        <div style={{ marginTop: '16px' }}>
          <WidgetLabelsAutocompleteControl
            dataSourceID={dataSourceId}
            value={selectedItems}
            onChange={(items) => setSelectedItems(items)}
          />
        </div>
      </Grid>
      <Grid item style={{ padding: '16px' }}>
        <Grid container direction='row' justifyContent='flex-end'>
          <Grid item style={{ marginRight: '16px' }}>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          </Grid>
          <Grid item>
            <SaveButton variant='outlined' onClick={handleSave} disabled={saving}>
              Save
            </SaveButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrendsWidgetSettings;
