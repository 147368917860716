export type WidgetDataSource = {
  id: string;
  dataSourceID: string;
  title: string;
  originalTitle: string;
  type: string;
  groupID: string;
  total: number;
  analyzed: number;
  status: string;
  order: number;
  ordered: boolean;
  visible: boolean;
  weightedTotal: number;
  items: WidgetDataSourceConcept[];
  duration?: number;
};

export type WidgetDataSourceConcept = {
  response: string;
  originalResponse: string;
  base: Base;
  weighted: Base;
  original: string;
  sentiment: Sentiment;
  children: string[] | null;
  displayResponse: string;
  percentage?: number;
  value?: number;
  realPercent?: number;
};

export type Base = {
  value: number;
  percentage: number;
};

export enum Sentiment {
  Negative = 'negative',
  Neutral = 'neutral',
  Positive = 'positive',
  Unknown = 'unknown',
}

export enum RegenerationStatus {
  Generated = 'generated',
  Generating = 'generating',
  Refreshing = 'refreshing',
}
