import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Label } from '../../../../../types/Label';

import Conversation from './Conversation';
import { Concept } from '../../../../../store/reducers/aiInsights';
import { changeSearchString } from '../../../../../store/actions/interviewWidget';

interface IConversationContainer {
  dataSourceID: string;
  widgetName: string;
  widgetID: string;
  labels: Label[];
  selectedLabelItem: Concept | null;
  isRegenerating: boolean;
  onLoadMoreResponses: (startIndex: number) => void;
  onLabelClick: (label: string) => void;
  onCustomersFilterChange: (ids: string[]) => void;
}
function ConversationContainer({
  dataSourceID,
  widgetID,
  widgetName,
  labels,
  selectedLabelItem,
  isRegenerating,
  onLoadMoreResponses,
  onLabelClick,
  onCustomersFilterChange,
}: IConversationContainer) {
  const dispatch = useAppDispatch();
  const responsesState = useAppSelector((state) => state.interviewWidgets.conversationsStatesByWidgetID[widgetID]);
  const responses = useAppSelector((state) => state.interviewWidgets.conversationsByWidgetID[widgetID]);
  const participants = useAppSelector((state) => state.interviewWidgets.participantsByDataSourceID[dataSourceID]);

  // TODO: Temporary fix for unknown participants that shouldn't be displayed
  const filteredParticipants = participants.filter((x) => x.name && x.name.toLowerCase() != 'unknown');

  function handleSearchStringChange(searchString: string) {
    dispatch(
      changeSearchString({
        searchString,
        widgetID,
      }),
    );
  }

  return (
    <Conversation
      widgetName={widgetName}
      widgetID={widgetID}
      responses={responses}
      responsesState={responsesState}
      labels={labels}
      participants={filteredParticipants}
      selectedLabelItem={selectedLabelItem}
      isRegenerating={isRegenerating}
      onSearchStringChange={handleSearchStringChange}
      onLoadMoreResponses={onLoadMoreResponses}
      onLabelClick={onLabelClick}
      onCustomersFilterChange={onCustomersFilterChange}
    />
  );
}

export default ConversationContainer;
