import styled from 'styled-components';

import { Integration, SurveySourceEnum } from '../../../../types/connections';

import IntegrationViewContainer from '../../Integrations/IntegrationViewContainer';
import IntegrationsListContainer from '../../Integrations/IntegrationsListContainer';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

interface IIntegrationsTab {
  isSurveysLoading: boolean;
  isConnectorsLoading: boolean;
  handleSelect: (integration: Integration) => void;
  handleEditAuthentication: (integration: Integration) => void;
  handleAddConnection: (source: SurveySourceEnum) => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string) => void;
}

function IntegrationsTab({
  isSurveysLoading,
  isConnectorsLoading,
  handleSelect,
  handleEditAuthentication,
  handleAddConnection,
  handleEnrich,
}: IIntegrationsTab) {
  return (
    <Container>
      <IntegrationsListContainer
        isSurveysLoading={isSurveysLoading}
        isConnectorsLoading={isConnectorsLoading}
        handleSelect={handleSelect}
        handleEdit={handleEditAuthentication}
      />
      <IntegrationViewContainer handleAddConnection={handleAddConnection} handleEnrich={handleEnrich} />
    </Container>
  );
}

export default IntegrationsTab;
