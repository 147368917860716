import Compare from '../../features/compare';
import { useContext } from 'react';
import { SidebarDashboardContext } from '../Sidebar';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';

const CompareTab = () => {
  const { setActiveItemIndex, savedFiltersIndex } = useContext<any>(SidebarDashboardContext);

  function handleSelectSegmentsClick() {
    eventsTracker.track(EVENTS.COMPARE_SEGMENTS_SELECT);
    setActiveItemIndex(savedFiltersIndex);
  }

  return <Compare onSelectSegmentsClick={handleSelectSegmentsClick} />;
};

export default CompareTab;
