import { Brand } from '../../types/brands';
import { BRANDS_UPDATE_BRANDS, updateBrands } from '../actions/brands';

export interface IBrandsReducer {
  brands: Brand[];
  initialized: boolean;
  error?: boolean;
}

const initialState: IBrandsReducer = {
  brands: [],
  initialized: false,
};

type BrandsActionTypes = ReturnType<typeof updateBrands>;

export const brandsReducer = (state = initialState, action: BrandsActionTypes) => {
  switch (action.type) {
    case BRANDS_UPDATE_BRANDS:
      return handleUpdateBrands(state, action);
    default:
      return state;
  }
};

const handleUpdateBrands = (state: IBrandsReducer, action: ReturnType<typeof updateBrands>): IBrandsReducer => {
  const { brands, error } = action.payload;
  return {
    ...state,
    brands: brands || [],
    error,
    initialized: !error,
  };
};
