import { useState, useEffect } from 'react';

import { resetSurveys } from '../../../../store/actions/qualtrics';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { initializeQualtricsSurveys } from '../../../../store/thunks/qualtrics';
import { ProviderAuthentication } from '../../../../types/connections';

const useQualtricsSurveys = (onUpdateError: (err: string) => void, authentication: ProviderAuthentication) => {
  const dispatch = useAppDispatch();
  const { surveys, initialized: isSurveysInitialized, error: isSurveysError } = useAppSelector((state) => state.qualtrics);
  const [selectedSurvey, setSelectedSurvey] = useState('');

  useEffect(() => {
    if (isSurveysError) {
      onUpdateError('Failed to load qualtrics surveys.');
      return;
    }

    if (!isSurveysInitialized) {
      dispatch(initializeQualtricsSurveys({ authID: authentication.id }));
      return;
    }
  }, [isSurveysInitialized, isSurveysError, dispatch, onUpdateError, authentication.id]);

  useEffect(() => {
    return () => {
      dispatch(resetSurveys());
    };
  }, [dispatch]);

  return { surveys, selectedSurvey, setSelectedSurvey };
};

export default useQualtricsSurveys;
