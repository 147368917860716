import add from 'date-fns/add';
import format from 'date-fns/format';

import { DATE_FORMAT_DISPLAY, PERIOD } from '../../../constants';
import { numberWithCommas } from '../../tools';
import Chart from '../Chart';

const SurveyTotalTrendsChart = ({ surveyTrends, total, loading, period }) => {
  const series = [];
  const source = [];
  const transformers = [];
  for (let trend of surveyTrends) {
    for (let entry of trend.values) {
      source.push([entry.date, entry.value, trend.surveyID]);
    }
    const data = trend.values.map((value) => ({
      value: [value.date, value.value],
    }));
    series.push({
      data,
      name: trend.surveyTitle,
      type: 'bar',
      symbolSize: 1,
      lineStyle: {
        width: 0.5,
      },
    });
  }

  const option = {
    dataset: [
      {
        dimensions: ['date', 'value', 'surveyID'],
        source,
      },
      ...transformers,
    ],
    animation: false,
    tooltip: {
      show: true,
      confine: true,
      extraCssText: 'width:auto; white-space:pre-wrap;',
      formatter: (params) => {
        const seriesName = params.seriesName;
        const date = params.value[0];
        let dateValue = format(date, DATE_FORMAT_DISPLAY);
        if (period !== PERIOD.DAY) {
          const endDate = period === PERIOD.QUARTER ? add(date, { months: 3, days: -1 }) : add(date, { [`${period}s`]: 1, days: -1 });
          dateValue = `${dateValue} - ${format(endDate, DATE_FORMAT_DISPLAY)}`;
        }
        const responsesCount = Math.round(params.value[1]);
        const value = numberWithCommas(responsesCount) + ' Response' + (responsesCount !== 1 ? 's' : '');
        return `${seriesName}<br/>${dateValue}<br/>${value}`;
      },
    },
    grid: {
      top: 20,
      bottom: 30,
      left: 60,
      right: 20,
    },
    xAxis: {
      type: 'time',
      show: !loading,
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
    },
    graphic: [
      {
        type: 'text',
        left: 'center',
        top: 80,
        style: {
          fill: '#bbb',
          text: numberWithCommas(total),
          font: 'bold 48px Rubik',
        },
        silent: true,
        z: 100,
      },
    ],
    series,
  };

  return <Chart option={option} style={{ height: '100%' }} />;
};

export default SurveyTotalTrendsChart;
