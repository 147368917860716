import React from 'react';
import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const InfoIcon = styled(InfoOutlinedIcon)`
  &.MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

interface IHeaderTitleWithTooltip {
  title: string;
  tooltip: string;
}

function HeaderTitleWithTooltip({ title, tooltip }: IHeaderTitleWithTooltip) {
  return (
    <TitleWithIcon>
      <InfoIcon data-tooltip-id='base-tooltip' data-tooltip-content={tooltip} />
      {title}
    </TitleWithIcon>
  );
}

export default HeaderTitleWithTooltip;
