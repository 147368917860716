import React from 'react';

import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import { numberWithCommas } from '../../../tools';
import { SegmentColor, SegmentItemContainer, SegmentNumber } from './styles';

interface ISegmentItem {
  text: string;
  selected: boolean;
  color: string;
  total?: number | null;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

function SegmentItem({ text, selected, color, disabled = false, total = null, loading = false, onClick }: ISegmentItem) {
  function handleSegmentClick() {
    if (disabled) {
      return;
    }
    onClick();
  }
  return (
    <SegmentItemContainer
      data-testid='segment-item'
      $isSelected={selected}
      title={text}
      onClick={handleSegmentClick}
      data-selected={selected}
      data-filter={text}
    >
      <SegmentColor $color={color} />
      {text}
      {renderTotalValue()}
    </SegmentItemContainer>
  );

  function renderTotalValue() {
    if (!total && !loading) {
      return null;
    }
    if (total === null) {
      return <LoadingLabel loading={true} showText={false} iconSize={10} />;
    }

    return <SegmentNumber data-testid='segment-item-total'>({numberWithCommas(total)})</SegmentNumber>;
  }
}

export default SegmentItem;
