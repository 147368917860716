import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { CellMeasurerCache, List } from 'react-virtualized';

import { ResponseModel } from '../../../../../models/Response';
import {
  changeSearchString,
  invalidateBookmarksLoadingState,
  invalidateExtraConceptLoadingState,
} from '../../../../../store/actions/openEndedWidget';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Concept } from '../../../../../store/reducers/aiInsights';
import { CurrentDashboardInfo } from '../../../../../store/reducers/dashboard/types';
import { ResponsesSorting } from '../../../../../store/reducers/openEndedWidget';
import { loadAdditionalLabels, toggleResponseBookmark } from '../../../../../store/thunks/openEndedWidget';
import { Config } from '../../../../../types/Config';
import { Label } from '../../../../../types/Label';

import ResponsesList from './ResponsesList';

interface IResponsesListContainer {
  selectedConcept: Concept | null;
  hiddenConcepts: string[];
  config: Config;
  conceptToParent: Map<string, string>;
  selectedConcepts: string[];
  labels: Label[];
  onLabelClick: (title: string) => void;
  onLoadMoreResponses: (startIndex: number) => void;
  onSortingChange: (sorting: ResponsesSorting) => void;
  onBookmarkedToggle: () => void;
  onFeedbackClick: (title: string, widgetTitle: string, displayAnswerText?: string) => void;
}

function ResponsesListContainer({
  selectedConcept,
  hiddenConcepts,
  config,
  conceptToParent,
  selectedConcepts,
  labels,
  onLabelClick,
  onLoadMoreResponses,
  onSortingChange,
  onBookmarkedToggle,
  onFeedbackClick,
}: IResponsesListContainer) {
  const widgetID = config.base.id as string;
  const dispatch = useAppDispatch();
  const {
    title,
    hasSentiment,
    dataSources: dashboardDataSources,
  } = useAppSelector((state) => state.dashboard.dashboard) as CurrentDashboardInfo;
  const translationUsed = useAppSelector((state) => state.app.translationUsed);
  const widgetConfig = useAppSelector((state) => state.openEndedWidgets.configsByWidgetID[widgetID]);
  const responsesConfig = useAppSelector((state) => state.openEndedWidgets.responsesStatesByWidgetID[widgetID]);
  const responses = useAppSelector((state) => state.openEndedWidgets.responsesByWidgetID[widgetID]);
  const extraLabelsState = useAppSelector((state) => state.openEndedWidgets.extraLabelsByWidgetID[widgetID]);
  const bookmarkedResponsesState = useAppSelector((state) => state.openEndedWidgets.bookmarkedResponsesByWidgetID[widgetID]);

  const listRef = useRef<List | null>(null);
  const [shouldReset, setShouldReset] = useState(false);

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 100,
        minHeight: 70,
        fixedWidth: true,
      }),
    [],
  );

  const dashboardDataSourcesById = useMemo(() => {
    return dashboardDataSources.reduce(
      (acc, dataSource) => ({
        ...acc,
        [dataSource.dataSourceID]: dataSource,
      }),
      {},
    );
  }, [dashboardDataSources]);

  useEffect(() => {
    const updatedResponses = extraLabelsState?.filter((labelsState) => labelsState.isLoading === false && labelsState.isError === false);
    if (updatedResponses?.length) {
      updatedResponses.forEach((resp) => {
        cache.clear(resp.rowIndex, 0);
        dispatch(
          invalidateExtraConceptLoadingState({
            responseID: resp.responseID,
            widgetID,
            rowIndex: resp.rowIndex,
          }),
        );
      });
      handleGridUpdate();
    }
  }, [extraLabelsState, cache]);

  useEffect(() => {
    const updatedResponses = bookmarkedResponsesState?.filter(
      (labelsState) => labelsState.isLoading === false && labelsState.isError === false,
    );
    if (updatedResponses?.length) {
      updatedResponses.forEach((resp) => {
        cache.clear(resp.rowIndex, 0);
        dispatch(
          invalidateBookmarksLoadingState({
            responseID: resp.responseID,
            widgetID,
            rowIndex: resp.rowIndex,
          }),
        );
      });
      handleGridUpdate();
    }
  }, [bookmarkedResponsesState, cache]);

  const handleIsRowLoaded = ({ index }: { index: number }) => {
    return responses && responses[index] !== undefined;
  };

  useEffect(() => {
    cache.clearAll();
  }, [translationUsed, cache]);

  function handleGridUpdate() {
    listRef.current?.forceUpdateGrid();
  }

  async function handleResponseBookmarkClick(response: ResponseModel, rowIndex: number) {
    dispatch(
      toggleResponseBookmark({
        widgetID,
        responseID: response.id,
        rowIndex,
        isBookmarked: response.bookmarked,
      }),
    );
    cache.clearAll();
    handleGridUpdate();
  }

  function handleSortingChange(sorting: ResponsesSorting) {
    onSortingChange(sorting);
  }

  function handleBookmarkClick() {
    onBookmarkedToggle();
  }

  function handleSearchStringChange(searchString: string) {
    dispatch(
      changeSearchString({
        searchString,
        widgetID,
      }),
    );
  }

  function handleWidthChange() {
    cache.clearAll();
  }

  function handleResponseForRowGet(rowIndex: number) {
    if (!responses) {
      return null;
    }
    return responses[rowIndex];
  }

  function handleResetFinish() {
    setShouldReset(false);
  }

  function handleLoadMoreRows({ startIndex }: { startIndex: number }) {
    onLoadMoreResponses(startIndex);
  }

  function handleExtraLabelsLoad(response: ResponseModel, rowIndex: number) {
    // Since we don't delete additional labels we need this check so that we don't double load the same labels
    if (response.additionalLabels.length === 0) {
      dispatch(
        loadAdditionalLabels({
          widgetID,
          responseID: response.id,
          rowIndex,
        }),
      );
    }
  }

  function handleFeedbackClick(responseID: string) {
    const titleField = translationUsed ? 'title' : 'originalTitle';
    const widgetTitle = config.base[titleField];
    const responseData = responses.find((response) => response.id === responseID);

    onFeedbackClick(title, responseData?.displayAnswerText, widgetTitle);
  }

  if (!widgetConfig || !responsesConfig) {
    return null;
  }

  return (
    <ResponsesList
      cache={cache}
      config={config}
      dashboardDataSourcesById={dashboardDataSourcesById}
      hasSentiment={hasSentiment}
      hiddenLabels={hiddenConcepts}
      isBookmarkedOnly={responsesConfig.isBookmarkOnly}
      isTranslationUsed={translationUsed}
      labels={labels}
      labelToParent={conceptToParent}
      listRef={listRef}
      rowCount={responsesConfig.responsesTotal}
      searchString={responsesConfig.searchString}
      selectedItems={selectedConcepts}
      selectedLabelItem={selectedConcept}
      shouldReset={shouldReset}
      isLoading={responsesConfig.isLoading}
      sorting={responsesConfig.sorting}
      onAdditionalLabelsLoad={handleExtraLabelsLoad}
      onBookmarkClick={handleBookmarkClick}
      onIsRowLoaded={handleIsRowLoaded}
      onLabelClick={onLabelClick}
      onResponseBookmarkClick={handleResponseBookmarkClick}
      onResponseForRowGet={handleResponseForRowGet}
      onLoadMoreRows={handleLoadMoreRows}
      onResetFinish={handleResetFinish}
      onSearchStringChange={handleSearchStringChange}
      onSortingChange={handleSortingChange}
      onWidthChange={handleWidthChange}
      onFeedbackClick={handleFeedbackClick}
    />
  );
}

export default ResponsesListContainer;
