import { RootState } from '../../store/hooks';

export const selectHiveNames = (state: RootState) => {
  const { dashboards, views } = state.dashboards;
  const dashboardsById = dashboards.reduce((acc: any, dashboard: any) => ({ ...acc, [dashboard.id]: dashboard }), {});
  const ids = new Set(Object.keys(dashboardsById));
  const hives = [];
  for (const view of views) {
    const viewDashboards = [];
    if (view.dashboardIDs) {
      for (const dashboardID of view.dashboardIDs) {
        if (dashboardsById[dashboardID]) {
          viewDashboards.push(dashboardsById[dashboardID]);
          ids.delete(dashboardID);
        }
      }
      hives.push({
        ...view,
        dashboards: viewDashboards,
      });
    } else {
      hives.push({
        ...view,
        dashboards: [],
      });
    }
  }
  return hives.filter((hive: any) => hive.title !== 'All').map((hive: any) => hive.title);
};
