import { FETCH_STATUS } from '../../constants';
import * as api from '../../components/tools/api';
import {
  dashboardsInitializeFail,
  dashboardsInitializeSuccess,
  dashboardsSetAbortController,
  dashboardsSetDashboards,
  dashboardsSetDateRange,
  dashboardsSetViews,
  setDashboardsStatus,
} from '../actions/dashboards';
import { View, Dashboard } from '../reducers/dashboards';
import { Action } from '../hooks';

export const dashboardsInitialize =
  (dateRange = null): Action =>
  async (dispatch) => {
    try {
      dispatch(setDashboardsStatus({ status: FETCH_STATUS.LOADING }));
      const [dashboards, views] = await Promise.all([api.fetchDashboards(dateRange), api.fetchDashboardViews()]);
      // add synthetic view to manage "All" view and unattached dashboard
      views.unshift({
        id: null,
        title: 'All',
        dashboardIDs: [],
      });

      // Check each dashboard's lastUpdated and set it to createdAt if lastUpdated is "0"
      const updatedDashboards = dashboards.map((dashboard: Dashboard) => {
        const lastUpdatedDate = new Date(dashboard.lastUpdated);
        const createdAtDate = new Date(dashboard.createdAt);
        const latestUpdatedResponseDate = new Date(dashboard.latestUpdatedResponse);

        return {
          ...dashboard,
          lastUpdated: lastUpdatedDate >= createdAtDate ? dashboard.lastUpdated : dashboard.createdAt,
          latestUpdatedResponse: latestUpdatedResponseDate >= createdAtDate ? dashboard.latestUpdatedResponse : dashboard.createdAt,
        };
      });

      dispatch(
        dashboardsInitializeSuccess({
          dashboards: updatedDashboards,
          views,
        }),
      );
      dispatch(setDashboardsStatus({ status: FETCH_STATUS.LOADED }));
    } catch {
      dispatch(setDashboardsStatus({ status: FETCH_STATUS.ERROR }));
      dispatch(
        dashboardsInitializeFail({
          error: 'Cannot get dashboards information',
        }),
      );
    }
  };

export const deleteDashboardsView =
  (viewToDelete: View): Action =>
  async (dispatch, getState) => {
    await api.deleteDashboardView(viewToDelete.id);
    const state = getState();
    const views = state.dashboards.views.filter((view) => view.id !== viewToDelete.id);
    dispatch(dashboardsSetViews({ views }));
  };

export const createDashboardsView =
  (view: View, userID: string): Action =>
  async (dispatch, getState) => {
    const id = await api.createDashboardsView(view);
    const state = getState();
    const views = [...state.dashboards.views];
    views.splice(1, 0, { ...view, id, userID });
    dispatch(dashboardsSetViews({ views }));
  };

export const updateDashboardsView =
  (updatedView: View): Action =>
  async (dispatch, getState) => {
    await api.updateDashboardsView(updatedView);
    const state = getState();
    const views = state.dashboards.views;
    const updatedViews = views.map((view) => (view.id === updatedView.id ? updatedView : view));
    dispatch(dashboardsSetViews({ views: updatedViews }));
  };

export const setDashboardsDateRange =
  (dateRange: any): Action =>
  async (dispatch, getState) => {
    const { abortController } = getState().dashboards;
    abortController.abort();
    dispatch(dashboardsSetDateRange({ dateRange }));
    const newAbortController = new AbortController();
    dispatch(dashboardsSetAbortController({ abortController: newAbortController }));
    dispatch(setDashboardsStatus({ status: FETCH_STATUS.LOADING }));
    const dashboards = await api.fetchDashboards(dateRange, {
      abortController: newAbortController,
    });
    if (!newAbortController.signal.aborted) {
      dispatch(dashboardsSetDashboards({ dashboards }));
    }
    dispatch(setDashboardsStatus({ status: FETCH_STATUS.LOADED }));
  };
