import React from 'react';
import { NotEnoughTextContainer, NotificationIconStyled } from './styles';
import { Concept } from '../../../../../../store/reducers/aiInsights';

interface INotEnoughData {
  availableFilters: string[];
  selectedConcept?: Concept;
  segmentsNames?: string[];
}
function NotEnoughData({ availableFilters, selectedConcept, segmentsNames }: INotEnoughData) {
  return (
    <NotEnoughTextContainer>
      <NotificationIconStyled />
      <div>
        Not enough data to generate AI insights{renderContextInfo()}. To see more AI insights, try selecting a different concept, changing a
        segment or removing some of the filters.
      </div>
    </NotEnoughTextContainer>
  );

  function renderContextInfo() {
    if (!segmentsNames?.length) {
      return null;
    }
    const concept = selectedConcept?.title ? (
      <>
        selected concept: <i>"{selectedConcept.title}"</i>
      </>
    ) : undefined;

    return (
      <>
        {' '}
        for {concept && concept}
        {concept && !!segmentsNames?.length && withOptionalSegments() && ' and segment(s): '}
        {withOptionalSegments() &&
          segmentsNames?.map((sn, idx) => (
            <i key={`${sn}-${idx}`}>
              "{sn}"{idx !== segmentsNames.length - 1 ? ', ' : ' '}
            </i>
          ))}
      </>
    );

    function withOptionalSegments() {
      return availableFilters.length > 1 || (availableFilters.length === 1 && !concept);
    }
  }
}

export default NotEnoughData;
