import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import palette from '../../components/tools/palette';
import DialogActions from '@material-ui/core/DialogActions';
import { FilledButton } from '../../components/UI/Button';

export const CustomDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    border-top: 1px solid #979797;
    margin-top: 20px;
    padding: 15px;
    justify-content: space-between;
  }
`;

export const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    text-transform: capitalize;
    border-color: ${palette.primaryColorLight};
    font-weight: 100;
    .MuiButton-label {
      color: ${palette.primaryColorLight};
    }
  }

  &.MuiButtonBase-root:hover {
    border-color: ${palette.primaryColorLightHover};
    background-color: white;
    .MuiButton-label {
      color: ${palette.primaryColorLightHover};
    }
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    text-transform: capitalize;
    font-weight: 100;
    margin-right: 16px;
  }
`;

export const DeleteButton = styled(SubmitButton)`
  &.MuiButtonBase-root, &.MuiButtonBase-root:hover {
    border-color: #e3e3f5;

    .MuiButton-label {
      color: red;
    }
  }

  &.MuiButtonBase-root:hover {
    border-color: red;
  }

  &.Mui-disabled {
    .MuiButton-label {
      color: ${palette.linkColorLight};
    }
  }
}
`;

export const CreateNewButton = styled(FilledButton)`
  &.MuiButtonBase-root {
    margin-left: 40px;
  }
`;
