import add from 'date-fns/add';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

export const DEFAULT_DATE_FROM = '1111-01-01';
export const DEFAULT_DATE_TO = '3333-01-01';
export const DEFAULT_DATE_FORMAT = 'MM / dd / yyyy';
export const API_ARG_DATE_FORMAT = 'yyyy-MM-dd';
export const TRENDS_DATE_FORMAT = 'yyyy/MM/dd';

export const addPeriodToDate = (date, period) => {
  const parsedDate = parse(date, TRENDS_DATE_FORMAT, new Date());
  const nextDate = add(parsedDate, { [`${period}s`]: 1 });
  return format(nextDate, TRENDS_DATE_FORMAT);
};
