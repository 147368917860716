import React from 'react';
import styled from 'styled-components';
import { Concept } from '../../../../../../store/reducers/aiInsights';

const PromptContainer = styled.div`
  font-weight: bold;
  min-height: 18px;
`;

const OptionalConcept = styled.i`
  color: var(--black);
  font-weight: normal;
`;

interface IInsightPrompt {
  availableFilters: string[];
  selectedConcept?: Concept;
  customPrompt?: string;
  segmentsNames?: string[];
}
function InsightPrompt({ availableFilters, customPrompt, selectedConcept, segmentsNames }: IInsightPrompt) {
  if (availableFilters.length > 1 || (availableFilters.length === 1 && !selectedConcept)) {
    return (
      <PromptContainer data-testid='prompt'>
        The key takeaways regarding {renderSegments()}
        {renderOptionalSelectedConcept(true)}:
      </PromptContainer>
    );
  }

  return <PromptContainer data-testid='prompt'>The key takeaways {renderOptionalSelectedConcept()}from the data are:</PromptContainer>;

  function renderOptionalSelectedConcept(withSegment?: boolean) {
    if (!selectedConcept) {
      return null;
    }

    return (
      <b>
        {withSegment ? ' and the concept ' : 'regarding'} <OptionalConcept>"{selectedConcept.title}"</OptionalConcept>{' '}
      </b>
    );
  }

  function renderSegments() {
    if (availableFilters.length === 1 && selectedConcept) {
      return null;
    }

    return segmentsNames!.map((segment, i) => (
      <OptionalConcept key={`${i}-${segment}`}>
        "{segment}"{i < segmentsNames!.length - 1 ? ', ' : ''}
      </OptionalConcept>
    ));
  }
}

export default InsightPrompt;
