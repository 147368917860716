import { useRef } from 'react';
import noop from 'lodash/noop';

import BarsWidgetContent from '../../../Widgets/BarsWidget/BarsWidgetContent';
import SummaryDataProvider from '../../../../contexts/SummaryDataContext';
import { WidgetTotalsProvider } from '../../../Widgets/WidgetTotalsContext';

const WidgetPreviewBars = ({ config }) => {
  const mockRef = useRef(null);

  return (
    <SummaryDataProvider>
      <WidgetTotalsProvider query={null} widgetDataSources={config.settings.dataSources} forceFetch={false}>
        <BarsWidgetContent
          config={config}
          preview={true}
          onExpand={noop}
          updateAutoSizeHeight={noop}
          showSettings={false}
          refHeader={mockRef}
          refCsvExport={mockRef}
          refContent={mockRef}
          refFiltersCount={mockRef}
        />
      </WidgetTotalsProvider>
    </SummaryDataProvider>
  );
};

export default WidgetPreviewBars;
