import AverageFilterBoxes from './AverageFilterBoxes';
import { FILTER_ID_AVERAGE, FILTER_NAME_AVERAGE, FILTER_ID_ALL, PERCENT_DECIMAL_NUM } from '../../../constants';
import palette from '../../tools/palette';
import { numberWithCommas } from '../../tools';

const Percent = ({ children }) => (
  <div
    data-testid='label-percentage'
    style={{
      width: '80px',
      textAlign: 'right',
      marginRight: '4px',
      fontSize: '0.8em',
      lineHeight: '20px',
    }}
  >
    {children}
  </div>
);

const PercentageBar = ({ percent, color }) => (
  <div
    style={{
      height: '20px',
      borderRadius: 'var(--border-radius)',
      width: `${percent}%`,
      backgroundColor: color,
    }}
  />
);

const Number = ({ value, filterName }) => (
  <span
    data-cy='label-count'
    data-tooltip-id='base-tooltip'
    data-tooltip-content={`${filterName}: ${numberWithCommas(value)}`}
    style={{
      backgroundColor: '#e9eaf0',
      borderRadius: 'var(--border-radius)',
      fontSize: '12px',
      marginTop: '3px',
      opacity: '0.8',
      padding: '0 2px',
      position: 'absolute',
      right: '4px',
      top: '0',
    }}
  >
    {numberWithCommas(value)}
  </span>
);

const getAverage = (dataByFilterId) => {
  const [value, totalResponses, filtersCount] = Object.keys(dataByFilterId).reduce(
    ([value, totalResponses, filtersCount], filterId) => {
      if (filterId === FILTER_ID_ALL) {
        return [value, totalResponses, filtersCount];
      }
      const data = dataByFilterId[filterId];
      const responsesCount = data.realPercent === 0 ? 0 : (data.number / data.realPercent) * 100;
      return [value + data.number, totalResponses + responsesCount, filtersCount + 1];
    },
    [0, 0, 0],
  );

  return {
    [FILTER_ID_AVERAGE]: {
      number: filtersCount === 0 ? 0 : Math.round(value / filtersCount),
      realPercent: totalResponses === 0 ? 0 : (value / totalResponses) * 100,
    },
  };
};

const FiltersBars = ({ dataByFilterId, average, filtersById, selectedFilterIds }) => {
  if (!dataByFilterId) {
    return null;
  }
  const averageData = getAverage(dataByFilterId);
  const dataById = average ? averageData : dataByFilterId;
  const { number: avgNum, realPercent: avgPct } = averageData[FILTER_ID_AVERAGE];
  const showAverageMarker = Object.keys(dataById).filter((id) => id !== FILTER_ID_ALL).length > 1;
  const extendedFiltersById = {
    ...filtersById,
    [FILTER_ID_AVERAGE]: {
      name: FILTER_NAME_AVERAGE,
      color: filtersById[FILTER_ID_ALL].color,
    },
  };

  return (
    <div>
      {Object.entries(dataById).map(([filterId, data]) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }} key={filterId}>
            <Percent>
              {data.realPercent.toFixed(PERCENT_DECIMAL_NUM)}%{average ? <span>(Avg)</span> : null}
            </Percent>
            {average && (
              <div>
                <AverageFilterBoxes filtersById={filtersById} selectedFilterIds={selectedFilterIds} />
              </div>
            )}
            <div style={{ flexGrow: '1' }}>
              <div
                data-tooltip-id='base-tooltip'
                data-tooltip-content={extendedFiltersById[filterId].name}
                style={{
                  position: 'relative',
                  backgroundColor: palette.linkColorLight4,
                  borderRadius: 'var(--border-radius)',
                  border: `1px solid ${palette.linkColorLight3}`,
                }}
              >
                {showAverageMarker && filterId !== FILTER_ID_ALL && (
                  <div
                    data-tooltip-id='base-tooltip'
                    data-tooltip-content={`Average value: ${avgNum} (${avgPct.toFixed(PERCENT_DECIMAL_NUM)}%)`}
                    style={{
                      position: 'absolute',
                      top: '0',
                      height: '100%',
                      borderLeft: '2px dashed white',
                      left: `${avgPct}%`,
                    }}
                  />
                )}
                <PercentageBar color={extendedFiltersById[filterId].color} percent={data.realPercent} />
                <Number value={data.number} filterName={extendedFiltersById[filterId].name} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FiltersBars;
