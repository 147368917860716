import { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FilterNameInput } from '../DashboardToolbar/RenameField';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { FilledButton, OutlinedButton, OutlinedButtonNarrow } from '../UI/Button';

const FilterFormButtons = ({ filterName, filterDescription, saving, onSave, onApply, onReset, disabled, resetDisabled, isFilterEdit }) => {
  const [showDialog, setShowDialog] = useState(filterName.length > 0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isNameCorrect, setIsNameCorrect] = useState(false);
  const [isSaveRequested, setIsSaveRequested] = useState(false);

  useEffect(() => {
    setName(filterName);
  }, [filterName]);
  useEffect(() => {
    setDescription(filterDescription);
  }, [filterDescription]);

  useEffect(() => {
    setIsNameCorrect(name.trim().length > 0);
  }, [name]);

  useEffect(() => {
    if (!saving && isSaveRequested) {
      setShowDialog(false);
      setIsSaveRequested(false);
    }
  }, [saving, showDialog, isSaveRequested]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSaveClick = () => {
    setIsSaveRequested(true);
    onSave(name, description);
  };

  const toggleSaveDialog = () => {
    if (showDialog) {
      if (isFilterEdit) {
        eventsTracker.track(EVENTS.SAVED_SEGMENTS_EDIT_CANCEL);
      } else {
        eventsTracker.track(EVENTS.FILTER_SAVE_CANCEL);
      }
    } else {
      eventsTracker.track(EVENTS.FILTER_SAVE);
    }
    setShowDialog(!showDialog);
  };

  const saveButtonIcon = saving ? <CircularProgress size={16} style={{ color: '#fff' }} /> : null;

  return (
    <Grid container direction='row' alignItems='center' spacing={1} style={{ height: '48px' }}>
      {!showDialog && (
        <Grid item>
          <OutlinedButton disabled={disabled} startIcon={<SaveOutlinedIcon />} onClick={toggleSaveDialog}>
            Save
          </OutlinedButton>
        </Grid>
      )}
      {showDialog ? (
        <>
          <Grid item>
            <FilterNameInput
              onChange={handleNameChange}
              size='small'
              value={name}
              variant='outlined'
              id='filter-name-input'
              label='Filter name'
            />
          </Grid>
          <Grid item xs>
            <FilterNameInput
              onChange={handleDescriptionChange}
              size='small'
              fullWidth
              value={description}
              variant='outlined'
              label='Description'
            />
          </Grid>
          <Grid item>
            <FilledButton
              startIcon={saveButtonIcon}
              disabled={disabled || !isNameCorrect || saving}
              data-action='save-filter'
              onClick={handleSaveClick}
            >
              Save
            </FilledButton>
          </Grid>
          <Grid item>
            <OutlinedButton variant='outlined' disabled={saving} onClick={toggleSaveDialog}>
              Cancel
            </OutlinedButton>
          </Grid>
        </>
      ) : (
        <Grid item xs />
      )}
      <Grid item style={{ width: '50px' }} />
      <Grid item style={{ width: '205px' }}>
        <Box display='flex' justifyContent='space-between'>
          <OutlinedButtonNarrow onClick={onReset} disabled={resetDisabled}>
            Reset
          </OutlinedButtonNarrow>
          <OutlinedButtonNarrow data-action='apply' onClick={onApply} disabled={disabled}>
            Apply
          </OutlinedButtonNarrow>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterFormButtons;
