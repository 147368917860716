import { useSelector } from 'react-redux';
import SvgIcon from '@material-ui/core/SvgIcon';

import SidebarItemWithOverlay from './SidebarItemWithOverlay';
import OverlayPaper from './OverlayPaper';
import SidebarItemBreakdownOverlay from './SidebarItemBreakdownOverlay';

const BreakdownIcon = (props) => (
  <SvgIcon {...props}>
    <rect x='20' width='4' height='2' />
    <rect x='20' width='4' height='2' y='4' />
    <rect x='20' width='4' height='2' y='22' />
    <rect x='20' width='4' height='2' y='18' />
    <rect width='4' height='2' y='5' x='3' />
    <rect width='4' height='2' y='9' x='3' />
    <rect width='4' height='2' y='13' x='3' />
    <rect width='4' height='2' y='17' x='3' />
    <ellipse cx='18' cy='5' rx='1' ry='1' />
    <ellipse cx='18' cy='1' rx='1' ry='1' />
    <ellipse cx='18' cy='19' rx='1' ry='1' />
    <ellipse cx='1' cy='10' rx='1' ry='1' />
    <ellipse cx='1' cy='14' rx='1' ry='1' />
    <ellipse cx='1' cy='18' rx='1' ry='1' />
    <polygon points='9 11 13 11 13 8 18 12 13 16 13 13 9 13' />
    <ellipse cx='18' cy='23' rx='1' ry='1' />
    <ellipse cx='1' cy='6' rx='1' ry='1' />
  </SvgIcon>
);

const SidebarItemBreakdown = ({ index }) => {
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);

  return (
    <SidebarItemWithOverlay
      icon={<BreakdownIcon />}
      index={index}
      selected={!!breakdownFilter}
      tooltip='Breakdown Data'
      testId='sidebar-breakdown'
    >
      <OverlayPaper elevation={4} fixed='true' data-testid='overlay-breakdown'>
        <SidebarItemBreakdownOverlay />
      </OverlayPaper>
    </SidebarItemWithOverlay>
  );
};

export default SidebarItemBreakdown;
