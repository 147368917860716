import React, { MutableRefObject, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TotalCustomersForQuery } from '../../../store/reducers/dashboardFilter';
import { fetchTotals } from '../../../store/thunks/dashboardFilters';
import FilterSegmentInfo from './FilterSegmentInfo';
import { Query } from '../../../types/Query';
import { CurrentDashboardInfo } from '../../../store/reducers/dashboard/types';

interface IFilterSegmentInfoContainer {
  percentageRef: MutableRefObject<number | null>;
  filterQuery: Query;
}
function FilterSegmentInfoContainer({ percentageRef, filterQuery }: IFilterSegmentInfoContainer) {
  const dispatch = useAppDispatch();
  const { weightedMetricsUsed } = useAppSelector((state) => state.app);
  const { id: dashboardId, total } = useAppSelector((state) => state.dashboard.dashboard as CurrentDashboardInfo);
  const { isLoading, result } = useAppSelector((state) => state.filter.totalCustomers as TotalCustomersForQuery);
  const [filteredTotal, setFilteredTotal] = useState<number>(0);

  useEffect(() => {
    if (percentageRef) {
      percentageRef.current = null;
    }
    dispatch(fetchTotals({ dashboardId, filterQuery }));
  }, [filterQuery, dashboardId]);

  useEffect(() => {
    if (!result) {
      return;
    }
    const currentTotal = result[weightedMetricsUsed ? 'weighted' : 'total'] || 0;
    setFilteredTotal(currentTotal);
    const percentage = Math.round((currentTotal / total) * 100);
    if (percentageRef) {
      percentageRef.current = percentage;
    }
  }, [result, weightedMetricsUsed]);

  return <FilterSegmentInfo isLoading={isLoading} percentage={percentageRef?.current || 0} filteredTotal={filteredTotal || 0} />;
}

export default FilterSegmentInfoContainer;
