import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dashboardsInitialize } from '../../../../store/thunks/dashboards';

const useDashboards = (onUpdateError: (err: string) => void) => {
  const dispatch = useAppDispatch();
  const { dashboards, initialized: isDashboardsInitialized, error: isDashboardsError } = useAppSelector((state) => state.dashboards);
  const [selectedDashboard, setSelectedDashboard] = useState('');

  useEffect(() => {
    if (isDashboardsError) {
      onUpdateError('Failed to load dashboards.');
      return;
    }

    if (!isDashboardsInitialized) {
      dispatch(dashboardsInitialize());
    }
  }, [isDashboardsInitialized, isDashboardsError, dispatch, onUpdateError]);

  return { dashboards, selectedDashboard, setSelectedDashboard };
};

export default useDashboards;
