import styled from 'styled-components';

export const MAX_STEPS_CONTAINER_WIDTH = '60vw';

export const GroupsContainer = styled.div`
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const GroupContainer = styled.div``;

export const GroupTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 24px;
`;

export const StepsContainer = styled.div<{ width: number }>`
  padding: 24px;
  ${(props) => `width: max(${MAX_STEPS_CONTAINER_WIDTH}, ${props.width}px);`}
`;

export const StepDescription = styled.span<{ withPadding?: boolean }>`
  color: var(--dark-gray);
  ${(props) => props.withPadding && `padding-left: 0.5em;`}
`;

export const StepBold = styled.span`
  font-weight: 600;
`;

export const StepLink = styled.a`
  color: var(--dark-gray);
  text-decoration: underline;
  cursor: pointer;
`;
