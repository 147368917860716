import { ThumbStatus } from '../../components/Widgets/OpenEndedWidget/Data/AIInsights/Components/GeneratedInsights/GeneratedInsightsContainer';
import { WidgetDataSource } from '../../types/DataSource';
import { Concept, InsightType, KeyQuote } from '../reducers/aiInsights';
import { Query } from '../../types/Query';
import { InsightsFilter } from '../thunks/aiInsights';

export const AI_INSIGHTS_HIDE_BANNER = 'AI_INSIGHTS_HIDE';
export const AI_INSIGHTS_RESET = 'AI_INSIGHTS_RESET';
export const AI_INSIGHTS_SHOW = 'AI_INSIGHTS_SHOW';
export const AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET = 'AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET';
export const AI_INSIGHTS_UPDATE_FILTERED_CONCEPTS_FOR_WIDGET = 'AI_INSIGHTS_UPDATE_FILTERED_CONCEPTS_FOR_WIDGET';
export const AI_INSIGHTS_UPDATE_SELECTED_CONCEPT_FOR_WIDGET = 'AI_INSIGHTS_UPDATE_SELECTED_CONCEPT_FOR_WIDGET';
export const AI_INSIGHTS_GENERATE = 'AI_INSIGHTS_GENERATE';
export const AI_INSIGHTS_GENERATE_INTERVIEW = 'AI_INSIGHTS_GENERATE_INTERVIEW';
export const AI_INSIGHTS_GENERATE_REQUEST_START = 'AI_INSIGHTS_GENERATE_REQUEST_START';
export const AI_INSIGHTS_GENERATE_REQUEST_SUCCESS = 'AI_INSIGHTS_GENERATE_REQUEST_SUCCESS';
export const AI_INSIGHTS_GENERATE_REQUEST_FAILURE = 'AI_INSIGHTS_GENERATE_REQUEST_FAILURE';
export const AI_INSIGHTS_GENERATE_REQUEST_CANCEL = 'AI_INSIGHTS_GENERATE_REQUEST_CANCEL';
export const AI_INSIGHTS_CLEAR_ALL = 'AI_INSIGHTS_CLEAR_ALL';
export const AI_INSIGHTS_QUEUE_CONCEPT = 'AI_INSIGHTS_QUEUE_CONCEPT';
export const AI_INSIGHTS_SHOW_FEEDBACK_MODAL = 'AI_INSIGHTS_SHOW_FEEDBACK_MODAL';
export const AI_INSIGHTS_HIDE_FEEDBACK_MODAL = 'AI_INSIGHTS_HIDE_FEEDBACK_MODAL';
export const AI_INSIGHTS_SHOW_QUOTES_MODAL = 'AI_INSIGHTS_SHOW_QUOTES_MODAL';
export const AI_INSIGHTS_HIDE_QUOTES_MODAL = 'AI_INSIGHTS_HIDE_QUOTES_MODAL';
export const AI_INSIGHTS_FEEDBACK_SUBMIT_START = 'AI_INSIGHTS_FEEDBACK_SUBMIT_START';
export const AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS = 'AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS';
export const AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR = 'AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR';

export const resetAIInsights = () => ({
  type: AI_INSIGHTS_RESET,
});

interface IInitializeInsightsForWidget {
  widgetID: string;
  dataSource: WidgetDataSource;
  filteredConcepts: string[];
  selectedConcept?: Concept;
}

export const initializeInsightsForWidget = ({ widgetID, dataSource, filteredConcepts, selectedConcept }: IInitializeInsightsForWidget) => ({
  type: AI_INSIGHTS_INITIALIZE_INSIGHTS_FOR_WIDGET,
  payload: {
    type: InsightType.INTRO_PROMPT,
    widgetID,
    dataSource,
    filteredConcepts,
    selectedConcept,
  },
});

interface IUpdateSelectedConceptsFilteredInWidgetSettings {
  widgetID: string;
  concepts: string[];
}

export const updateSelectedConceptsFilteredInWidgetSettings = ({
  widgetID,
  concepts,
}: IUpdateSelectedConceptsFilteredInWidgetSettings) => ({
  type: AI_INSIGHTS_UPDATE_FILTERED_CONCEPTS_FOR_WIDGET,
  payload: {
    widgetID,
    concepts,
  },
});

interface IUpdateSelectedConceptForWidget {
  widgetID: string;
  selectedConcept?: Concept;
}

export const updateSelectedConceptForWidget = ({ widgetID, selectedConcept }: IUpdateSelectedConceptForWidget) => ({
  type: AI_INSIGHTS_UPDATE_SELECTED_CONCEPT_FOR_WIDGET,
  payload: {
    type: InsightType.SELECTED_CONCEPT,
    widgetID,
    selectedConcept,
  },
});

interface IGenerateInsights {
  dataSourceID: string;
  dashboardID: string;
  widgetID: string;
  insightsQuery: InsightsFilter[];
  availableFilters: string[];
  responsesQuery: Query;
  segmentsNames: string[];
  insightID?: string;
  customPrompt?: string;
  selectedConcept?: Concept;
  isTryAgain?: boolean;
}
export const generateInsightsRequest = ({
  availableFilters,
  insightID,
  widgetID,
  customPrompt,
  selectedConcept,
  insightsQuery,
  responsesQuery,
  segmentsNames,
  isTryAgain,
  dashboardID,
  dataSourceID,
}: IGenerateInsights) => ({
  type: AI_INSIGHTS_GENERATE,
  payload: {
    availableFilters,
    insightID,
    dashboardID,
    dataSourceID,
    widgetID,
    customPrompt,
    selectedConcept,
    insightsQuery,
    responsesQuery,
    segmentsNames,
    isTryAgain,
  },
});
export const generateInsightsFGRequest = ({
  availableFilters,
  insightID,
  widgetID,
  customPrompt,
  selectedConcept,
  insightsQuery,
  responsesQuery,
  segmentsNames,
  isTryAgain,
  dashboardID,
  dataSourceID,
}: IGenerateInsights) => ({
  type: AI_INSIGHTS_GENERATE_INTERVIEW,
  payload: {
    availableFilters,
    insightID,
    dashboardID,
    dataSourceID,
    widgetID,
    customPrompt,
    selectedConcept,
    insightsQuery,
    responsesQuery,
    segmentsNames,
    isTryAgain,
  },
});

interface IGenerateInsightsRequestStart {
  id: string;
  timestamp: string;
  widgetID: string;
  filter: InsightsFilter[];
  segmentsNames: string[];
  availableFilters: string[];
  customPrompt?: string;
  selectedConcept?: Concept;
  isTryAgain?: boolean;
}
export const generateInsightsRequestStart = ({
  availableFilters,
  id,
  widgetID,
  customPrompt,
  selectedConcept,
  timestamp,
  filter,
  segmentsNames,
  isTryAgain,
}: IGenerateInsightsRequestStart) => ({
  type: AI_INSIGHTS_GENERATE_REQUEST_START,
  payload: {
    availableFilters,
    id,
    timestamp,
    widgetID,
    customPrompt,
    selectedConcept,
    filter,
    segmentsNames,
    isTryAgain,
  },
});

export type GeneratedInsight = {
  insight: string;
  keyQuotes: KeyQuote[];
};

interface IGenerateInsightsRequestSuccess {
  id: string;
  widgetID: string;
  timestamp: string;
  numberOfResponsesUsedForGeneration: string;
  generationModel: string;
  tokensTotal: string;
  generationModelLocation: string;
  generationTime: string;
  totalResponses: number;
  modelParameters: {
    maxOutputTokens: string;
    temperature: string;
    topK: string;
    topP: string;
  };
  concept?: Concept;
  customPrompt?: string;
  generatedInsights?: GeneratedInsight[];
  relatedConcepts?: Concept[];
  isTryAgain?: boolean;
}
export const generateInsightsRequestSuccess = (data: IGenerateInsightsRequestSuccess) => ({
  type: AI_INSIGHTS_GENERATE_REQUEST_SUCCESS,
  payload: {
    ...data,
  },
});

interface IGenerateInsightsRequestFailure {
  id: string;
  widgetID: string;
  timestamp: string;
  totalResponses: number;
}
export const generateInsightsRequestFailure = ({ id, widgetID, timestamp, totalResponses }: IGenerateInsightsRequestFailure) => ({
  type: AI_INSIGHTS_GENERATE_REQUEST_FAILURE,
  payload: {
    id,
    widgetID,
    timestamp,
    totalResponses,
  },
});

interface IGenerateInsightsRequestCancel {
  widgetID: string;
  timestamp: string;
}
export const generateInsightsRequestCancel = ({ widgetID, timestamp }: IGenerateInsightsRequestCancel) => ({
  type: AI_INSIGHTS_GENERATE_REQUEST_CANCEL,
  payload: {
    widgetID,
    timestamp,
  },
});

interface IClearAllInsightsForWidget {
  widgetID: string;
}
export const clearAllInsights = ({ widgetID }: IClearAllInsightsForWidget) => ({
  type: AI_INSIGHTS_CLEAR_ALL,
  payload: {
    widgetID,
  },
});

interface IQueueConcept {
  widgetID: string;
  selectedConcept?: Concept;
}
export const queueConcept = ({ widgetID, selectedConcept }: IQueueConcept) => ({
  type: AI_INSIGHTS_QUEUE_CONCEPT,
  payload: {
    widgetID,
    selectedConcept,
  },
});

interface IShowFeedbackModal {
  widgetID: string;
  dataSourceID: string;
  insightID: string;
  thumbStatus: ThumbStatus;
}
export const showFeedbackModal = ({ widgetID, insightID, thumbStatus, dataSourceID }: IShowFeedbackModal) => ({
  type: AI_INSIGHTS_SHOW_FEEDBACK_MODAL,
  payload: {
    thumbStatus,
    insightID,
    widgetID,
    dataSourceID,
  },
});

export const hideFeedbackModal = () => ({
  type: AI_INSIGHTS_HIDE_FEEDBACK_MODAL,
});

interface IShowKeyQuotesModal {
  widgetID: string;
  insightID: string;
  dataSourceID: string;
  keyTakeaway: string;
  keyQuotes: KeyQuote[];
}
export const showKeyQuotesModal = ({ widgetID, insightID, dataSourceID, keyTakeaway, keyQuotes }: IShowKeyQuotesModal) => ({
  type: AI_INSIGHTS_SHOW_QUOTES_MODAL,
  payload: {
    widgetID,
    insightID,
    dataSourceID,
    keyTakeaway,
    keyQuotes,
  },
});

export const hideKeyQuotesModal = () => ({
  type: AI_INSIGHTS_HIDE_QUOTES_MODAL,
});

export const feedbackSubmitStart = () => ({
  type: AI_INSIGHTS_FEEDBACK_SUBMIT_START,
});
export const feedbackSubmitSuccess = () => ({
  type: AI_INSIGHTS_FEEDBACK_SUBMIT_SUCCESS,
});
export const feedbackSubmitError = () => ({
  type: AI_INSIGHTS_FEEDBACK_SUBMIT_ERROR,
});
