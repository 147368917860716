import { useSelector } from 'react-redux';

import { getTestsLinks } from '../components/tools';
import { useCallback } from 'react';

const useFormatLogMessage = () => {
  const company = useSelector((state) => state.profile.company);
  const dashboardTitle = useSelector((state) => state.dashboard.dashboard.title);

  return useCallback(
    (message) => {
      const links = getTestsLinks();
      return `[${message}][${company.displayName}][${dashboardTitle}][${links.unsafe}][${links.debug}]`;
    },
    [company, dashboardTitle],
  );
};

export default useFormatLogMessage;
