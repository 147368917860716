import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SidebarItemWithOverlay from './SidebarItemWithOverlay';
import { FilterList } from '@material-ui/icons';
import FilterForm from '../../FilterForm/FilterFormContainer';
import OverlayPaper from './OverlayPaper';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { SidebarDashboardContext } from '../SidebarDashboardContext';

const SidebarItemCreateFilter = ({ index }) => {
  const { query } = useSelector((state) => state.filter);
  const { savedFilter, setSavedFilter, setCreateFilterIndex, setActiveItemIndex, savedFiltersIndex } = useContext(SidebarDashboardContext);

  useEffect(() => {
    setCreateFilterIndex(index);
  }, [index, setCreateFilterIndex]);

  function handleOpen() {
    eventsTracker.track(EVENTS.FILTERS_EXPAND);
    setSavedFilter(null);
  }

  function handleClose() {
    eventsTracker.track(EVENTS.FILTER_CLOSE);
  }

  function handleFilterSave() {
    setActiveItemIndex(savedFiltersIndex);
  }

  return (
    <SidebarItemWithOverlay
      icon={<FilterList />}
      index={index}
      selected={!!query}
      tooltip='Filter'
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <OverlayPaper elevation={4}>
        <FilterForm savedFilter={savedFilter} onFilterSave={handleFilterSave} />
      </OverlayPaper>
    </SidebarItemWithOverlay>
  );
};

export default SidebarItemCreateFilter;
