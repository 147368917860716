import { FormControlLabel, Switch } from '@material-ui/core';
import styled from 'styled-components';

export const SettingsSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary {
    &.Mui-checked {
      color: #0f7451;
      + .MuiSwitch-track {
        background-color: #89c3b2;
      }
    }
  }
`;

const SettingsSwitchFormControl = ({ title, checked, onChange }) => {
  return <FormControlLabel control={<SettingsSwitch checked={checked} onChange={onChange} />} style={{ color: '#88899E' }} label={title} />;
};

export default SettingsSwitchFormControl;
