import SummaryDataProvider from '../../../contexts/SummaryDataContext';
import CompareContent from './CompareContent';

const Compare: React.FC<{ onSelectSegmentsClick: () => void }> = ({ onSelectSegmentsClick }) => (
  <SummaryDataProvider>
    <CompareContent onSelectSegmentsClick={onSelectSegmentsClick} />
  </SummaryDataProvider>
);

export default Compare;
