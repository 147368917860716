import { fetchBrands } from '../../components/tools/api';
import { updateBrands } from '../actions/brands';
import { Action } from '../hooks';

export const initializeBrands = (): Action => async (dispatch) => {
  const res = await fetchBrands();
  if (res) {
    dispatch(updateBrands({ brands: res }));
  } else {
    dispatch(updateBrands({ error: true }));
  }
};
