import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

const AppTooltip = styled(Tooltip)`
  font-size: 12px;
  max-width: 400px;
  opacity: 0.9;
  padding: 8px;
  z-index: 9999 !important;
`;

export default AppTooltip;
