export type CurrentDashboardDataSource = {
  id: string;
  dataSourceID: string;
  title: string;
  originalTitle: string;
  type: string;
  groupID: string;
  total: number;
  analyzed: number;
  status: string;
  order: number;
  ordered: boolean;
  visible: boolean;
  isEmpty: boolean;
  needRefresh: boolean;
};
export type CurrentDashboardGroups = {
  id: string;
  title: string;
  originalTitle: string;
  order: number;
};
export type CurrentDashboardWidgetGroupLayout = {
  id: string;
  widgetID: string;
  x: number;
  y: number;
  width: number;
  height: number;
};
export type CurrentDashboardWidgetGroup = {
  id: string;
  title: string;
  originalTitle: string;
  order: number;
  collapsed: boolean;
  layout: CurrentDashboardWidgetGroupLayout[];
};
export type CurrentDashboardWidgetsBase = {
  id: string;
  dashboardID: string;
  title: string;
  originalTitle: string;
  type: string;
  collapsed: boolean;
  expanded: boolean;
  maximized: boolean;
  filter: object;
};
export type CurrentDashboardWidgetsSettingsDataSource = {
  id: string;
  selectedItems: any[];
};
export type CurrentDashboardWidgetsSettings = {
  dataSources: CurrentDashboardWidgetsSettingsDataSource[];
  merge: boolean;
};
export type CurrentDashboardWidget = {
  base: CurrentDashboardWidgetsBase;
  settings: CurrentDashboardWidgetsSettings;
};
export type Survey = {
  id: string;
  title: string;
  status: string;
  ai_status: string;
  models_num: number;
  coverage: number;
  accuracy: number;
  source: string;
  updatedAt: number;
  responsesTotal: number;
  dashboards?: any;
  enrichmentSources?: any;
};
export type CurrentDashboardInfo = {
  id: string;
  title: string;
  createdAt: string;
  logo: string;
  brandName: string;
  lastUpdated: string;
  total: number;
  intervalTotal: number;
  filteredTotal: number;
  hasWeights: boolean;
  hasSentiment: boolean;
  dataSources: CurrentDashboardDataSource[];
  groups: CurrentDashboardGroups[];
  widgetGroups: CurrentDashboardWidgetGroup[];
  widgets: CurrentDashboardWidget[];
  countries?: any;
  underMaintenance: boolean;
  latestUpdatedResponse: string;
  status: string;
  surveys: Survey[];
  withValidation: boolean;
};
export type DataSourceItemStats = {
  value: number;
  percentage: number;
};
export type DataSourceItem = {
  response: string;
  originalResponse: string;
  base: DataSourceItemStats;
  weighted: DataSourceItemStats;
  original: string;
  sentimentPercentage: number;
  sentiment: string;
  children?: string[];
};
export type DataSource = {
  dataSourceID: string;
  total: number;
  weightedTotal: number;
  items: DataSourceItem[];
  duration?: string;
};
export type DashboardSentimentSummariesSentiment = {
  positive: number;
  negative: number;
  neutral: number;
};
export type SentimentSummary = {
  dataSourceID: string;
  sentiment: DashboardSentimentSummariesSentiment;
};
export type WidgetGroupLayout = {
  id: string;
  widgetID: string;
  x: number;
  y: number;
  width: number;
  height: number;
};
export type WidgetGroup = {
  id: string;
  title: string;
  originalTitle: string;
  order: number;
  collapsed: boolean;
  layout: WidgetGroupLayout[];
  isOverview: boolean;
  isDefault?: boolean;
};
export type DashboardWidgetsBaseFilter = {
  valid: boolean;
};
export type DashboardWidgetsBase = {
  id: string;
  dashboardID: string;
  title: string;
  originalTitle: string;
  type: string;
  collapsed: boolean;
  expanded: boolean;
  maximized: boolean;
  filter: DashboardWidgetsBaseFilter;
};
export type DashboardWidgetsSettingsDataSources = {
  id: string;
  selectedItems: any[];
};
export type DashboardWidgetsSettings = {
  dataSources: DashboardWidgetsSettingsDataSources[];
  merge: boolean;
};
export type DashboardWidgetsValidationErrors = {
  missedDataSourceIds: string[];
};
export type DashboardWidgetsValidation = {
  valid: boolean;
  errors: DashboardWidgetsValidationErrors;
};
export type Widget = {
  base: DashboardWidgetsBase;
  settings: DashboardWidgetsSettings;
  validation: DashboardWidgetsValidation;
};
export type ResponsesByDataSourceId = {
  [key: string]: Partial<string[]>;
};
export enum LabelOperationType {
  Create = 'CREATE',
  Rename = 'RENAME',
  Delete = 'DELETE',
}
export type LabelState = {
  isLoading: boolean;
  isDoneSuccessful: boolean;
  operation: LabelOperationType | null;
  isError: boolean;
};
export type PendingRefreshDataSource = {
  dataSourceID: string;
  status: DataSourceRegeneratingStatus;
};
export enum DataSourceRegeneratingStatus {
  PendingRefresh = 'PENDING_REFRESH',
  PendingRefreshAfterInit = 'PENDING_REFRESH_AFTER_INIT',
  PendingRegenerating = 'PENDING_REGENERATING',
}
export type RegeneratingDataSourceState = {
  status: DataSourceRegeneratingStatus | null;
  isError: boolean;
};
export type DataSourceState = {
  isLoading: boolean;
  isLoadingSentiment: boolean;
  isError: boolean;
};
