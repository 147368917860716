import { ClickAwayListener, Grow, MenuItem, MenuList, Popper } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

const SavedFilterMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 13px;
  }
`;

export const SavedFilterMenu = ({ anchorEl, onClose, onEditClick, onDuplicateClick, onDeleteClick }) => {
  return (
    <Popper open={!!anchorEl} anchorEl={anchorEl} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {/* TODO: BDTC-3891 Hidden until time to fix the issue */}
                {/* <SavedFilterMenuItem onClick={onEditClick}>
                                Edit
                            </SavedFilterMenuItem>
                            <SavedFilterMenuItem onClick={onDuplicateClick}>
                                Duplicate
                            </SavedFilterMenuItem> */}
                <SavedFilterMenuItem onClick={onDeleteClick}>Delete</SavedFilterMenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default SavedFilterMenu;
