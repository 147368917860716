import React, { useMemo, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { DragIndicator } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import SettingsSwitchFormControl from '../../../UI/SettingsSwitchFormControl';
import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import { GridWithCenterVerticalAlign } from '../../../overview/style';
import palette from '../../../tools/palette';
import { dashboardUpdateWidget } from '../../../../store/actions/dashboard';
import { OverviewItemModel } from '../../../../models/Overview';
import { CancelButton, SaveButton, TextOverflowEllipsis } from '../../../UI';
import * as api from '../../../tools/api';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { WIDGET_TYPE } from '../../../../constants';

const ReactGridLayout = WidthProvider(RGL);

const OverviewWidgetSettings = ({ config, onClose, groupTitle }) => {
  const { translationUsed } = useSelector((state) => state.app);
  const dataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const [dataSourcesSettings, setDataSourcesSettings] = useState(
    config.settings.dataSources
      .filter((ds) => {
        const dashboardDataSource = dataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === ds.id);
        return !(!dashboardDataSource || !dashboardDataSource.visible || dashboardDataSource.type !== OverviewItemModel.TYPE_OTHER);
      })
      .map((ds) => ({ ...ds })),
  );
  const [saving, setSaving] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const dispatch = useDispatch();

  const dataSourceNamesById = useMemo(() => {
    return dataSources.reduce(
      (acc, ds) => ({
        ...acc,
        [ds.dataSourceID]: translationUsed ? ds.title : ds.originalTitle,
      }),
      {},
    );
  }, [dataSources, translationUsed]);

  const handleSave = async () => {
    const oldOrderIds = config.settings.dataSources.sort((a, b) => a.order - b.order).map((ds) => ds.id);
    const newOrderIds = dataSourcesSettings.sort((a, b) => a.order - b.order).map((ds) => ds.id);
    eventsTracker.track(EVENTS.OVERVIEW_EDIT_SAVE, {
      'Data Sources': dataSourcesSettings.length,
      'Data Sources Active': dataSourcesSettings.filter((ds) => ds.visible).length,
      'Data Sources Order Change': !isEqual(oldOrderIds, newOrderIds),
    });
    setSaving(true);
    const updatedConfig = {
      ...config,
      settings: { dataSources: dataSourcesSettings },
    };
    await api.updateWidget(updatedConfig);
    dispatch(dashboardUpdateWidget(updatedConfig));
    setSaving(false);
    onClose();
  };

  const processSettingsChange = (updatedSettings) => {
    setDataSourcesSettings(updatedSettings);
    setSaveDisabled(isEqual(config.settings.dataSources, updatedSettings));
  };

  const handleVisibilityToggle = (id) => {
    const updatedSettings = dataSourcesSettings.map((s) => {
      if (s.id === id) {
        return { ...s, visible: !s.visible };
      }
      return s;
    });
    processSettingsChange(updatedSettings);
  };

  const handleDragStop = (layout) => {
    const orderById = layout.reduce((acc, item) => ({ ...acc, [item.i]: item.y + 1 }), {});
    const updatedSettings = dataSourcesSettings.map((s) => ({
      ...s,
      order: orderById[s.id],
    }));
    processSettingsChange(updatedSettings);
  };

  const onDragStart = (layout, oldItem, newItem, placeholder, e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleCancelClick = () => {
    eventsTracker.track(EVENTS.WIDGET_EDIT_CANCEL, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
    });
    onClose();
  };

  const orderedDataSourcesSettings = [...dataSourcesSettings].sort((a, b) => a.order - b.order);

  return (
    <Box style={{ padding: '16px' }}>
      <strong style={{ color: '#2F3151' }}>Display</strong>
      <ReactGridLayout
        className='layout'
        cols={1}
        margin={[16, 16]}
        containerPadding={[0, 0]} // workaround https://github.com/react-grid-layout/react-grid-layout/issues/1990
        draggableHandle='.dragHandle'
        autoSize={true}
        transformScale={1}
        onDragStop={handleDragStop}
        onDragStart={onDragStart}
        rowHeight={40}
        isDraggable={true}
        isResizable={false}
        isBounded={true}
      >
        {orderedDataSourcesSettings.map((source, i) => (
          <div key={source.id} unselectable='on' draggable={true} data-grid={{ x: 0, y: i, h: 1, w: 1, i: source.id }}>
            <Grid container direction='row'>
              <GridWithCenterVerticalAlign item xs='auto'>
                <DragIndicator className='dragHandle' style={{ cursor: 'grab' }} />
              </GridWithCenterVerticalAlign>
              <GridWithCenterVerticalAlign item xs={6} zeroMinWidth>
                <TextOverflowEllipsis data-tooltip-id='base-tooltip' data-tooltip-content={dataSourceNamesById[source.id]}>
                  {dataSourceNamesById[source.id]}
                </TextOverflowEllipsis>
              </GridWithCenterVerticalAlign>
              <GridWithCenterVerticalAlign item xs={1} style={{ paddingLeft: '10px' }}>
                <SettingsSwitchFormControl checked={source.visible} onChange={() => handleVisibilityToggle(source.id)} />
              </GridWithCenterVerticalAlign>
            </Grid>
          </div>
        ))}
      </ReactGridLayout>

      <span style={{ color: palette.linkColorLight2, marginLeft: '16px' }}>Drag-drop to reorder the fields.</span>

      <Grid item style={{ padding: '16px 16px 0 16px' }}>
        <Grid container direction='row' justifyContent='flex-end'>
          <Grid item style={{ marginRight: '16px' }}>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          </Grid>
          <Grid item>
            <SaveButton variant='outlined' onClick={handleSave} data-action='save-settings' disabled={saving || saveDisabled}>
              <LoadingLabel loading={saving} showText={false} iconSize={10} />
              Save
            </SaveButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewWidgetSettings;
