export const NOTIFICATIONS = {
  ConnectorsLoadingError: 'connectors-loading-error',
  ConnectorsSurveysLoadingError: 'connectors-surveys-loading-error',
  ConnectorsUploadMoreData: 'connectors-upload-more-data',
  ConnectorsFileUploadError: 'connectors-file-upload-error',
  ConnectorsFileUploadSuccess: 'connectors-file-upload-success',
  ConnectorsConnectionDeleteError: 'connectors-connection-delete-error',
  ConnectorsConnectionDeleteSuccess: 'connectors-connection-delete-success',
  ConnectorsConnectionUpdateError: 'connectors-connection-update-error',
  ConnectorsAuthUpdateSuccess: 'connectors-auth-update-success',
  ConnectorsAuthDeleteError: 'connectors-auth-delete-error',
  ConnectorsAuthDeleteSuccess: 'connectors-auth-delete-success',
  ConnectorsReAuthSuccess: 'connectors-re-auth-success',
  ConnectorsReAuthError: 'connectors-re-auth-error',
  ConnectorsImportSuccess: 'connectors-import-success',
  ConnectorsImportError: 'connectors-import-error',
  ConnectorsExportSuccess: 'connectors-export-success',
  ConnectorsExportError: 'connectors-export-error',
  AzureImportSuccess: 'azure-import-success',
  AzureImportError: 'azure-import-error',
  AzureExportSuccess: 'azure-export-success',
  AzureExportError: 'azure-export-error',
  AIInsightsFeedbackError: 'ai-insights-feedback-error',
  AIInsightsFeedbackSuccess: 'ai-insights-feedback-success',
};
