import { MOVING_AVERAGE_WINDOW_SIZE } from '../../../constants';

export const transform = {
  type: 'transformers:movingAverage',
  transform: (params) => {
    const upstream = params.upstream;
    const config = params.config;
    const windowSize = config.windowSize || MOVING_AVERAGE_WINDOW_SIZE;
    const dimension = config.dimension;
    const data = upstream.cloneRawData();
    const dimensionsInfo = upstream.cloneAllDimensionInfo();

    const dimensionIndex = dimensionsInfo.find((dimensionInfo) => dimensionInfo.name === dimension).index;
    const averageData = [];
    const averageValues = [];

    for (let index = 0; index < data.length; index++) {
      const row = data[index];

      averageValues.push(row[dimensionIndex]); // cannot use optimized version because of 0.1 + 0.2 - 0.1 - 0.2 !== 0
      if (index >= windowSize) {
        averageValues.shift();
      }

      const averageValue = averageValues.reduce((acc, v) => acc + v, 0) / averageValues.length;
      const averageRow = row.map((value, index) => (index === dimensionIndex ? averageValue : value));
      averageData.push(averageRow);
    }

    return {
      dimensions: dimensionsInfo,
      data: averageData,
    };
  },
};
