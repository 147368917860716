import { Box, Button, ClickAwayListener, Fade, Popper } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { TPTable } from '../lib/statistics';
import { ThresholdRange, Thresholds } from '../compare';
import { FADE_TIMEOUT } from '../../../constants';
import { ApplyButton, SelectorButton, SelectorContainer } from './UI';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import ThresholdControl from './ThresholdControl';
import { PopperModifiers } from '../constants';

const MAX_PERCENTAGE_THRESHOLD = 100;
const PERCENTAGE_THRESHOLD_RANGE: ThresholdRange = [0, MAX_PERCENTAGE_THRESHOLD];
const P_VALUE_THRESHOLD_RANGE: ThresholdRange = [0.001, 0.5];

const pValueMarks = TPTable.map((record, index) => ({
  value: record.p,
  label: index === 0 || index === TPTable.length - 1 ? record.p : '',
})).reverse();

const ThresholdName: Record<keyof Thresholds, string> = {
  pValue: 'P-value',
  differencePercentage: 'Difference (%)',
  differenceAbsolute: 'Difference (Absolute)',
  dataSourceTotal: 'Data Source: Answered',
};

const ThresholdSelectorContainer = styled(SelectorContainer)`
  width: 400px;
`;

interface IThresholdsSelectorProps {
  maxDataSourceTotal: number;
  maxAbsoluteDifference: number;
  thresholds: Thresholds;
  onChange: (thresholds: Thresholds) => void;
  onReset: () => void;
}

const ThresholdsSelector: React.FC<IThresholdsSelectorProps> = ({
  maxDataSourceTotal,
  maxAbsoluteDifference,
  thresholds,
  onChange,
  onReset,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [localThresholds, setLocalThresholds] = React.useState<Thresholds>(thresholds);

  useEffect(() => {
    setLocalThresholds(thresholds);
  }, [thresholds]);

  function closeThresholdsSelector() {
    setLocalThresholds(thresholds);
    setOpen(false);
  }

  function handleClick(event: React.SyntheticEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    if (open) {
      closeThresholdsSelector();
    } else {
      eventsTracker.track(EVENTS.COMPARED_TABLE_THRESHOLDS_SELECT);
      setOpen(true);
    }
  }

  function handleChange(field: keyof Thresholds, value: ThresholdRange) {
    setLocalThresholds((prev) => ({ ...prev, [field]: value }));
  }

  function handleResetClick() {
    eventsTracker.track(EVENTS.COMPARED_TABLE_THRESHOLDS_RESET);
    onReset();
    setOpen(false);
  }

  function handleApplyClick() {
    const eventProps = (Object.entries(localThresholds) as [keyof Thresholds, ThresholdRange][])
      .filter(([, value]) => value)
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [ThresholdName[key]]: `[${value[0]} - ${value[1]}]`,
        }),
        {},
      );
    eventsTracker.track(EVENTS.COMPARED_TABLE_THRESHOLDS_APPLY, eventProps);
    onChange(localThresholds);
    setOpen(false);
  }

  const selected = (Object.keys(thresholds) as Array<keyof Thresholds>).filter((key) => thresholds[key] !== undefined).length > 0;
  const disabled =
    (Object.keys(localThresholds) as Array<keyof Thresholds>).filter((key) => localThresholds[key] !== undefined).length === 0;

  return (
    <>
      <SelectorButton size='small' onClick={handleClick} selected={selected}>
        Set threshold(s)
      </SelectorButton>
      <Popper open={open} anchorEl={anchorEl} placement='bottom-end' transition={true} modifiers={PopperModifiers}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closeThresholdsSelector}>
            <Fade {...TransitionProps} timeout={FADE_TIMEOUT}>
              <ThresholdSelectorContainer>
                <Box>
                  <strong>{ThresholdName.dataSourceTotal}</strong>
                  <ThresholdControl
                    value={localThresholds.dataSourceTotal ?? [0, maxDataSourceTotal]}
                    max={maxDataSourceTotal}
                    onChange={handleChange.bind(null, 'dataSourceTotal')}
                  />

                  <strong>{ThresholdName.differenceAbsolute}</strong>
                  <ThresholdControl
                    value={localThresholds.differenceAbsolute ?? [0, maxAbsoluteDifference]}
                    max={maxAbsoluteDifference}
                    onChange={handleChange.bind(null, 'differenceAbsolute')}
                  />

                  <strong>{ThresholdName.differencePercentage}</strong>
                  <ThresholdControl
                    value={localThresholds.differencePercentage ?? PERCENTAGE_THRESHOLD_RANGE}
                    max={100}
                    marks={[
                      { value: 0, label: '0%' },
                      {
                        value: MAX_PERCENTAGE_THRESHOLD,
                        label: `${MAX_PERCENTAGE_THRESHOLD}%`,
                      },
                    ]}
                    onChange={handleChange.bind(null, 'differencePercentage')}
                    valueLabelFormat={(value) => `${value}%`}
                  />

                  <strong>{ThresholdName.pValue}</strong>
                  <ThresholdControl
                    value={localThresholds.pValue ?? P_VALUE_THRESHOLD_RANGE}
                    marks={pValueMarks}
                    onChange={handleChange.bind(null, 'pValue')}
                    max={pValueMarks[pValueMarks.length - 1].value}
                    min={pValueMarks[0].value}
                    step={null}
                    showManualValue={false}
                  />
                </Box>
                <Box display='flex' flexDirection='row' justifyContent='flex-end'>
                  {selected && <Button onClick={handleResetClick}>Reset</Button>}
                  <ApplyButton variant='contained' onClick={handleApplyClick} disabled={disabled}>
                    Apply
                  </ApplyButton>
                </Box>
              </ThresholdSelectorContainer>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ThresholdsSelector;
