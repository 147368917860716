import { useEffect, useMemo, useState } from 'react';

import { ICompareRow } from '../../compare';
import { DashboardDataSource } from '../../../../types';
import CompareChart from './CompareChart';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';

const PAGE_SIZE = 4;

interface ICompareChartProps {
  rows: ICompareRow[];
  dashboardDataSources: DashboardDataSource[];
  filters: any[];
  filtersById: any;
  selectedFilterIds: string[];
  selectedRowIds: string[];
  expanded: boolean;
}

function CompareChartContainer({
  rows,
  dashboardDataSources,
  filters,
  filtersById,
  selectedFilterIds,
  selectedRowIds,
  expanded,
}: ICompareChartProps) {
  const [page, setPage] = useState<number>(0);

  const sortedRows = useMemo(() => {
    return [...rows]
      .filter((row) => selectedRowIds.includes(`${row.concept}-${row.dataSourceID}`))
      .sort((a, b) => {
        const aMaxDifferencePercentage = a.data.reduce((maxDiff, row) => {
          if (selectedFilterIds.includes(row.filterMaxID) && selectedFilterIds.includes(row.filterMinID)) {
            return Math.max(maxDiff, row.differencePercentage);
          }
          return maxDiff;
        }, 0);
        const bMaxDifferencePercentage = b.data.reduce((maxDiff, row) => {
          if (selectedFilterIds.includes(row.filterMaxID) && selectedFilterIds.includes(row.filterMinID)) {
            return Math.max(maxDiff, row.differencePercentage);
          }
          return maxDiff;
        }, 0);
        return bMaxDifferencePercentage - aMaxDifferencePercentage;
      });
  }, [rows, selectedFilterIds, selectedRowIds]);

  const orderedFilterIds = useMemo(
    () =>
      [...selectedFilterIds].sort((a, b) => {
        return filters.findIndex((f) => f.id === a) - filters.findIndex((f) => f.id === b);
      }),
    [filters, selectedFilterIds],
  );

  const pageRows = useMemo(() => sortedRows.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE), [sortedRows, page]);
  const pagesCount = Math.floor((sortedRows.length - 1) / PAGE_SIZE);

  useEffect(() => {
    setPage(0);
  }, [selectedRowIds]);

  function handlePrevPageClick() {
    setPage(page - 1);
  }

  function handleNextPageClick() {
    setPage(page + 1);
  }

  function handleExportClick() {
    eventsTracker.track(EVENTS.SAVE_IMAGE, {
      'Applied Saved Segments': selectedFilterIds.map((id) => filtersById[id].name),
      'Number of table rows': selectedRowIds.length,
    });
  }

  return (
    <CompareChart
      pageRows={pageRows}
      page={page}
      pagesCount={pagesCount}
      orderedFilterIds={orderedFilterIds}
      dashboardDataSources={dashboardDataSources}
      filtersById={filtersById}
      expanded={expanded}
      onExportClick={handleExportClick}
      onPrevPageClick={handlePrevPageClick}
      onNextPageClick={handleNextPageClick}
    />
  );
}

export default CompareChartContainer;
