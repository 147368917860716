import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { NotificationsContext } from '../../../Notification/NotificationsContext';
import { SEVERITY } from '../../../Notification/types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { submitAzureAuth, updateAzureAuth } from '../../../../store/thunks/connectors';
import { NOTIFICATIONS } from '../../../Notification/constants';
import { AuthSourceEnum, Integration } from '../../../../types/connections';
import { clearLastEditedAuth } from '../../../../store/actions/connectors';

import AuthSAS from './AuthSAS';

const AzureTokenExpiryRegex = /&se=(.*?)&/;
const AzureTokenPermissionsRegex = /sp=(.*?)&/;
const PermissionsRegex = /^(?=.*r)(?=.*w)(?=.*a)(?=.*c)(?=.*l).*$/;

function getTokenPermissions(token: string) {
  return AzureTokenPermissionsRegex.exec(token)?.[1] ?? '';
}

function getTokenExpiry(token: string) {
  return AzureTokenExpiryRegex.exec(token)?.[1] ?? '';
}

function isPermissionsValid(token: string) {
  const permissions = getTokenPermissions(token);
  if (!permissions) {
    return false;
  }
  return PermissionsRegex.test(permissions);
}

function isExpiryValid(token: string) {
  const expiry = getTokenExpiry(token);
  if (!expiry) {
    return false;
  }
  const expiryTime = new Date(expiry).getTime();
  if (!expiryTime) {
    return false;
  }
  return expiryTime > Date.now();
}

const validateAccount = (account: string) => {
  if (!account) {
    return 'Please enter an account name.';
  }
  return '';
};

const validateContainer = (container: string) => {
  if (!container) {
    return 'Please enter a container name.';
  }
  return '';
};

const validateSASToken = (token: string) => {
  if (!token) {
    return 'Please enter an SAS token.';
  }

  if (!isExpiryValid(token)) {
    return 'SAS token must have a valid expiry.';
  }

  if (!isPermissionsValid(token)) {
    return 'SAS token must have the necessary permissions.';
  }

  return '';
};

interface IAuthSASContainer {
  integration: Integration;
  isEditMode: boolean;
  onUpdateError: (err: string) => void;
}

function AuthSASContainer({ isEditMode, integration, onUpdateError }: IAuthSASContainer) {
  const existingAuth = integration.authentication;
  const existingConfig = existingAuth?.config ? JSON.parse(existingAuth.config) : null;

  const dispatch = useAppDispatch();
  const lastEditedAuth = useAppSelector((state) => state.connectors).addConnection.lastEditedAuth;
  const isSubmitting = useAppSelector((state) => state.connectors).addConnection.submittingAuth;
  const { showNewMessages } = useIntercom();
  const { addNotification } = useContext(NotificationsContext);
  const [accountName, setAccountName] = useState(existingConfig?.accountName ?? '');
  const [containerName, setContainerName] = useState(existingConfig?.containerName ?? '');
  const [sasToken, setSasToken] = useState('');
  const [containerError, setContainerError] = useState('');
  const [accountError, setAccountError] = useState('');
  const [tokenError, setTokenError] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const validateForm = useCallback(() => {
    setAccountError(validateAccount(accountName));
    setContainerError(validateContainer(containerName));
    setTokenError(validateSASToken(sasToken));
  }, [accountName, containerName, sasToken]);

  function handleSubmit() {
    validateForm();
    if (accountError || containerError || tokenError) {
      setIsSubmitDisabled(true);
      return;
    }
    if (isEditMode && !existingAuth?.id) {
      return;
    }
    if (isEditMode) {
      dispatch(
        updateAzureAuth({
          auth: {
            accountName,
            containerName,
            sasToken,
          },
          id: existingAuth?.id ?? '',
        }),
      );
      return;
    }

    dispatch(
      submitAzureAuth({
        auth: {
          accountName,
          containerName,
          sasToken,
        },
        authProviderID: integration.authProviderID,
      }),
    );
  }

  useEffect(() => {
    if (lastEditedAuth?.source !== AuthSourceEnum.AZURE_SAS) {
      return;
    }
    dispatch(clearLastEditedAuth());
    if (lastEditedAuth.success) {
      if (!isEditMode) {
        return;
      }
      addNotification({
        id: NOTIFICATIONS.ConnectorsAuthUpdateSuccess,
        severity: SEVERITY.SUCCESS,
        title: 'Successfully updated authentication configuration.',
        duration: 5000,
        persistent: false,
      });
    } else {
      onUpdateError('AUTHENTICATION ERROR');
      setTokenError('Please enter a valid token');
    }
  }, [addNotification, dispatch, isEditMode, lastEditedAuth, onUpdateError]);

  useEffect(() => {
    if (!accountName || !containerName || !sasToken) {
      setIsSubmitDisabled(true);
      return;
    }
    setIsSubmitDisabled(Boolean(accountError || containerError || tokenError));
  }, [accountError, containerError, tokenError, accountName, containerName, sasToken]);

  function sendHelpMessage() {
    showNewMessages();
  }

  function handleBlurAccountName() {
    setAccountError(validateAccount(accountName));
  }
  function handleBlurContainerName() {
    setContainerError(validateContainer(containerName));
  }
  function handleBlurSasToken() {
    setTokenError(validateSASToken(sasToken));
  }
  function handleChangeAccountName(e: React.ChangeEvent<HTMLInputElement>) {
    setAccountName(e.target.value);
    setAccountError('');
  }
  function handleChangeContainerName(e: React.ChangeEvent<HTMLInputElement>) {
    setContainerName(e.target.value);
    setContainerError('');
  }
  function handleChangeSasToken(e: React.ChangeEvent<HTMLInputElement>) {
    setSasToken(e.target.value);
    setTokenError('');
  }

  return (
    <AuthSAS
      accountError={accountError}
      containerError={containerError}
      tokenError={tokenError}
      accountName={accountName}
      containerName={containerName}
      sasToken={sasToken}
      isEditMode={isEditMode}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmitting ?? false}
      onSubmit={handleSubmit}
      onBlurAccountName={handleBlurAccountName}
      onBlurContainerName={handleBlurContainerName}
      onBlurSasToken={handleBlurSasToken}
      onChangeAccountName={handleChangeAccountName}
      onChangeContainerName={handleChangeContainerName}
      onChangeSasToken={handleChangeSasToken}
      onSendHelpMessage={sendHelpMessage}
    />
  );
}

export default AuthSASContainer;
