import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import { emulateDocumentMouseUp } from '../../../UI/lib';
import AddSingleWidget from './AddSingleWidget';
import AddGroup from './AddGroup';
import AddMultipleWidgets from './AddMultipleWidgets';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { WIDGET_TYPE } from '../../../../constants';
import { SavedFilterButton } from '../../../UI/Button';
import { dashboardCreateOverview } from '../../../../store/thunks/dashboard';

const MENU_ITEM_ID = {
  OVERVIEW: 'OVERVIEW',
  BARS: 'BARS',
  OPEN_ENDED: 'OPEN_ENDED',
  INTERVIEW: 'INTERVIEW',
  TRENDS: 'TRENDS',
  NEW_GROUP: 'NEW_GROUP',
  BULK_ADD: 'BULK_ADD',
};

const MENU_ITEM_ID_TO_WIDGET_TYPE = {
  [MENU_ITEM_ID.OVERVIEW]: WIDGET_TYPE.OVERVIEW,
  [MENU_ITEM_ID.BARS]: WIDGET_TYPE.BARS,
  [MENU_ITEM_ID.OPEN_ENDED]: WIDGET_TYPE.OPEN_ENDED,
  [MENU_ITEM_ID.INTERVIEW]: WIDGET_TYPE.INTERVIEW,
  [MENU_ITEM_ID.TRENDS]: WIDGET_TYPE.TRENDS,
};

const CREATE_WIDGET_MENU_ITEM_IDS = [MENU_ITEM_ID.BARS, MENU_ITEM_ID.TRENDS, MENU_ITEM_ID.OPEN_ENDED, MENU_ITEM_ID.INTERVIEW];

const MenuButton = styled(SavedFilterButton)`
  &.MuiButtonBase-root {
    display: block;
    margin-top: 8px;
  }
`;

const SidebarItemAddToDashboardOverlay = () => {
  const widgetGroups = useSelector((state) => state.dashboard.widgetGroups);
  const [selected, setSelected] = useState(MENU_ITEM_ID.BARS);
  const dispatch = useDispatch();

  const showOverview = widgetGroups.every((widgetGroup) => !widgetGroup.isOverview);

  const handleMenuClick = (id) => {
    if (id in MENU_ITEM_ID_TO_WIDGET_TYPE) {
      eventsTracker.track(EVENTS.ADD_NEW_WIDGET, {
        'Widget Type': capitalize(MENU_ITEM_ID_TO_WIDGET_TYPE[id]),
      });
    }
    if (id === MENU_ITEM_ID.BULK_ADD) {
      eventsTracker.track(EVENTS.ADD_MULTIPLE_WIDGETS);
    }
    setSelected(id);
  };

  const handleOverviewClick = () => {
    dispatch(dashboardCreateOverview());
    emulateDocumentMouseUp();
    handleClose();
  };

  const handleClose = () => {
    emulateDocumentMouseUp();
  };

  return (
    <Grid container direction='column' style={{ flex: 1 }}>
      <Grid item>
        <strong>Add to Dashboard</strong>
      </Grid>
      <Grid item style={{ flexGrow: 1, display: 'flex' }}>
        <Grid container direction='row'>
          <Grid item xs='auto' style={{ width: '180px' }}>
            {showOverview && (
              <MenuButton size='small' selected={false} onClick={handleOverviewClick}>
                Overview
              </MenuButton>
            )}
            <MenuButton size='small' onClick={handleMenuClick.bind(null, MENU_ITEM_ID.BARS)} selected={selected === MENU_ITEM_ID.BARS}>
              Bar Widget
            </MenuButton>
            <MenuButton
              size='small'
              onClick={handleMenuClick.bind(null, MENU_ITEM_ID.OPEN_ENDED)}
              selected={selected === MENU_ITEM_ID.OPEN_ENDED}
            >
              Open-ended Widget
            </MenuButton>
            <MenuButton
              size='small'
              onClick={handleMenuClick.bind(null, MENU_ITEM_ID.INTERVIEW)}
              selected={selected === MENU_ITEM_ID.INTERVIEW}
            >
              Interview widget
            </MenuButton>
            <MenuButton size='small' onClick={handleMenuClick.bind(null, MENU_ITEM_ID.TRENDS)} selected={selected === MENU_ITEM_ID.TRENDS}>
              Trends Widget
            </MenuButton>
            <MenuButton
              size='small'
              onClick={handleMenuClick.bind(null, MENU_ITEM_ID.NEW_GROUP)}
              selected={selected === MENU_ITEM_ID.NEW_GROUP}
            >
              New Group
            </MenuButton>
            <MenuButton
              size='small'
              onClick={handleMenuClick.bind(null, MENU_ITEM_ID.BULK_ADD)}
              selected={selected === MENU_ITEM_ID.BULK_ADD}
            >
              Bulk Add
            </MenuButton>
          </Grid>
          <Grid item xs style={{ paddingTop: '8px' }}>
            {CREATE_WIDGET_MENU_ITEM_IDS.includes(selected) && (
              <AddSingleWidget type={MENU_ITEM_ID_TO_WIDGET_TYPE[selected]} onClose={handleClose} />
            )}
            {selected === MENU_ITEM_ID.NEW_GROUP && <AddGroup onClose={handleClose} />}
            {selected === MENU_ITEM_ID.BULK_ADD && <AddMultipleWidgets onClose={handleClose} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SidebarItemAddToDashboardOverlay;
