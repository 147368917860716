import * as api from '../../components/tools/api';
import { Query } from '../../types/Query';
import { Action } from '../hooks';
import {
  loadAnswersFailure,
  loadAnswersStart,
  loadAnswersSuccess,
  loadExtraConceptsFailure,
  loadExtraConceptsStart,
  loadExtraConceptsSuccess,
  toggleBookmarkResponseFailure,
  toggleBookmarkResponseStart,
  toggleBookmarkResponseSuccess,
} from '../actions/openEndedWidget';
import { CurrentDashboardInfo } from '../reducers/dashboard/types';
import { ResponsesSorting } from '../reducers/openEndedWidget';

export type Responses = {
  total: number;
  items: Response[];
};
export type Response = {
  id: string;
  value: string;
  labels: string[];
  date: string;
  originalValue: string;
  sentimentScore: number;
  sentiment: string;
  bookmarked: boolean;
};

interface ILoadResponses {
  widgetID: string;
  filterQuery: Query;
  offset: number;
  sorting: ResponsesSorting;
  isBookmarkOnly: boolean;
}
export const DEFAULT_PAGE_SIZE = 50;
export const loadResponses =
  ({ widgetID, filterQuery, offset, sorting, isBookmarkOnly }: ILoadResponses): Action =>
  async (dispatch, getState) => {
    dispatch(
      loadAnswersStart({
        widgetID,
        filterQuery,
        offset,
        sorting,
        isBookmarkOnly,
      }),
    );

    const state = getState();
    const { id: dashboardId } = state.dashboard.dashboard as CurrentDashboardInfo;
    const { dataSourceID } = state.openEndedWidgets.configsByWidgetID[widgetID];

    try {
      const response = await api.fetchResponses(dashboardId, dataSourceID, offset, DEFAULT_PAGE_SIZE, filterQuery, sorting, isBookmarkOnly);
      dispatch(
        loadAnswersSuccess({
          widgetID,
          responses: response.items,
          totalResponses: response.total,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(loadAnswersFailure({ widgetID }));
    }
  };

interface ILoadAdditionalLabels {
  widgetID: string;
  responseID: string;
  rowIndex: number;
}
export const loadAdditionalLabels =
  ({ widgetID, responseID, rowIndex }: ILoadAdditionalLabels): Action =>
  async (dispatch, getState) => {
    dispatch(loadExtraConceptsStart({ widgetID, responseID, rowIndex }));
    const { id: dashboardId } = getState().dashboard.dashboard as CurrentDashboardInfo;

    try {
      const extraConcepts = await api.fetchExtendLabels(dashboardId, responseID);
      dispatch(
        loadExtraConceptsSuccess({
          widgetID,
          responseID,
          rowIndex,
          extraConcepts,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(loadExtraConceptsFailure({ widgetID, responseID, rowIndex }));
    }
  };

interface IToggleResponseBookmark {
  widgetID: string;
  responseID: string;
  rowIndex: number;
  isBookmarked: boolean;
}
export const toggleResponseBookmark =
  ({ widgetID, responseID, rowIndex, isBookmarked }: IToggleResponseBookmark): Action =>
  async (dispatch, getState) => {
    dispatch(toggleBookmarkResponseStart({ widgetID, responseID, rowIndex }));
    const { id: dashboardId } = getState().dashboard.dashboard as CurrentDashboardInfo;
    const newState = !isBookmarked;
    try {
      if (isBookmarked) {
        await api.unbookmarkResponse(dashboardId, responseID);
      } else {
        await api.bookmarkResponse(dashboardId, responseID);
      }
      dispatch(
        toggleBookmarkResponseSuccess({
          widgetID,
          responseID,
          rowIndex,
          isBookmarked: newState,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(toggleBookmarkResponseFailure({ widgetID, responseID, rowIndex }));
    }
  };
