import React from 'react';
import styled from 'styled-components';
import { TableRow, TableBody } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { OutlinedButton, OutlinedButtonNarrow } from '../../../UI/Button';
import { Survey, SurveySourceEnum, SurveyStatus } from '../../../../types/connections';
import LoadingLabel from '../../../LoadingLabel/LoadingLabel';

import { getStatusCellContent } from '../../utils';
import { CONNECTORS_COLUMN } from '../../types';
import { StyledTable, StyledTableHead, getColumnStyles, StyledConnectionCell } from '../../style';

const CSV_COLUMNS: Record<string, CONNECTORS_COLUMN> = {
  DATA_SOURCE: {
    width: '30%',
    align: 'left',
  },
  RESPONSES: {
    width: '10%',
    align: 'right',
  },
  LAST_UPDATED: {
    width: '15%',
    align: 'left',
  },
  DASHBOARDS_CONNECTED_TO: {
    width: '15%',
    align: 'right',
  },
  ENRICHMENT_DATA: {
    width: '15%',
    align: 'center',
  },
  CONNECTION_STATUS: {
    width: '15%',
    align: 'center',
  },
};

const TableContainer = styled.div`
  overflow: auto;
  max-height: 55vh;
  @media (min-height: 1300px) {
    max-height: 65vh;
  }
`;

const Container = styled.div`
  background: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const HeaderCell = styled(StyledConnectionCell)<{ $paddingLeft?: string }>`
  && {
    font-weight: bold;
    ${({ $paddingLeft }) => $paddingLeft && `padding-left: ${$paddingLeft};`}
  }
`;

const StyledCell = styled(StyledConnectionCell)<{ $paddingLeft?: string }>`
  && {
    padding: 8px 10px;
    ${({ $paddingLeft }) => $paddingLeft && `padding-left: ${$paddingLeft};`}
  }
`;

const EnrichmentData = styled.span`
  color: var(--gray);
`;

const AddButton = styled(OutlinedButton)`
  && {
    margin-top: 55px;
    width: fit-content;
  }
`;

interface ICSVTab {
  csvSurveys: Partial<Survey>[];
  isSurveysLoading: boolean;
  handleAdd: () => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string) => void;
}

function CSVTab({ csvSurveys, isSurveysLoading, handleAdd, handleEnrich }: ICSVTab) {
  if (isSurveysLoading) {
    return <LoadingLabel loading />;
  }

  return (
    <>
      <Container data-testid='csv-tab-container'>
        <TableContainer>
          <StyledTable>
            <StyledTableHead>
              <TableRow data-testid='csv-tab-header-row'>
                <HeaderCell $colProps={getColumnStyles(CSV_COLUMNS.DATA_SOURCE)}>Data Source(s)</HeaderCell>
                <HeaderCell $colProps={getColumnStyles(CSV_COLUMNS.RESPONSES)}>Responses</HeaderCell>
                <HeaderCell $colProps={getColumnStyles(CSV_COLUMNS.LAST_UPDATED)} $paddingLeft='30px'>
                  Last Updated
                </HeaderCell>
                <HeaderCell $colProps={getColumnStyles(CSV_COLUMNS.DASHBOARDS_CONNECTED_TO)}>Connected Dashboards</HeaderCell>
                <HeaderCell $colProps={getColumnStyles(CSV_COLUMNS.ENRICHMENT_DATA)}>Enrichment Data</HeaderCell>
                <HeaderCell $colProps={getColumnStyles(CSV_COLUMNS.CONNECTION_STATUS)}>Connection Status</HeaderCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {csvSurveys.map(({ title = '', updatedAtString, dashboardsConnectedTo, responsesTotal, enrichmentSource, status }) => (
                <React.Fragment key={title}>
                  <TableRow data-testid='csv-tab-surveys-row' key={title}>
                    <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.DATA_SOURCE)}>{title}</StyledCell>
                    <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.RESPONSES)}>{responsesTotal}</StyledCell>
                    <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.LAST_UPDATED)} $paddingLeft='30px'>
                      {updatedAtString}
                    </StyledCell>
                    <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.DASHBOARDS_CONNECTED_TO)}>{dashboardsConnectedTo}</StyledCell>
                    <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.ENRICHMENT_DATA)}>
                      {status !== SurveyStatus.PROCESSING && (
                        <OutlinedButtonNarrow
                          onClick={handleEnrich.bind(null, SurveySourceEnum.CSV, title, enrichmentSource?.name ?? '')}
                          size='small'
                        >
                          Enrich
                        </OutlinedButtonNarrow>
                      )}
                    </StyledCell>
                    <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.CONNECTION_STATUS)}>{getStatusCellContent(status)}</StyledCell>
                  </TableRow>
                  {enrichmentSource && (
                    <TableRow data-testid='csv-tab-enrichment-row' key={enrichmentSource.id}>
                      <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.DATA_SOURCE)} $paddingLeft='40px'>
                        {enrichmentSource.name} <EnrichmentData>(enrichment data)</EnrichmentData>
                      </StyledCell>
                      <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.RESPONSES)}></StyledCell>
                      <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.LAST_UPDATED)} $paddingLeft='30px'>
                        {enrichmentSource.updatedAtString}
                      </StyledCell>
                      <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.DASHBOARDS_CONNECTED_TO)}>{dashboardsConnectedTo}</StyledCell>
                      <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.ENRICHMENT_DATA)}></StyledCell>
                      <StyledCell $colProps={getColumnStyles(CSV_COLUMNS.CONNECTION_STATUS)}></StyledCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <AddButton data-testid='csv-tab-add' startIcon={<AddIcon />} onClick={handleAdd}>
          Add
        </AddButton>
      </Container>
    </>
  );
}

export default CSVTab;
