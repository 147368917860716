import cloneDeep from 'lodash/cloneDeep';
import {
  SETTINGS_SET_TOTAL_BADGE_EXPANDED,
  SETTINGS_TOGGLE_WIDGET_GROUP_STATE,
  settingsSetTotalBadgeExpanded,
  settingsToggleWidgetGroupState,
} from '../actions/settings';

export type GroupState = {
  isCollapsed: boolean;
  groupID: string;
};

export interface ISettingsReducer {
  groupsState: GroupState[];
  totalBadgeExpanded: { [key: string]: boolean };
  openedGroups?: string[];
}
export const defaultSettingsState: ISettingsReducer = {
  openedGroups: [],
  groupsState: [],
  totalBadgeExpanded: {},
};
type settingsReducerActionTypes = ReturnType<typeof settingsToggleWidgetGroupState> & ReturnType<typeof settingsSetTotalBadgeExpanded>;

const settingsReducer = (state = defaultSettingsState, action: settingsReducerActionTypes) => {
  switch (action.type) {
    case SETTINGS_TOGGLE_WIDGET_GROUP_STATE:
      return handleToggleWidgetGroupState(state, action);
    case SETTINGS_SET_TOTAL_BADGE_EXPANDED:
      const totalBadgeExpanded = {
        ...state.totalBadgeExpanded,
        [action.payload.dashboardId]: action.payload.state,
      };
      return {
        ...state,
        totalBadgeExpanded,
      };
    default:
      return state;
  }
};

function handleToggleWidgetGroupState(
  state: ISettingsReducer,
  action: ReturnType<typeof settingsToggleWidgetGroupState>,
): ISettingsReducer {
  const { widgetGroupId, isCollapsed } = action.payload;
  const clonedGroupsState = cloneDeep(state.groupsState);
  const groupState = clonedGroupsState.find((gs) => gs.groupID === widgetGroupId);
  if (groupState) {
    groupState.isCollapsed = !isCollapsed;
  } else {
    clonedGroupsState.push({
      groupID: widgetGroupId,
      isCollapsed: !isCollapsed,
    });
  }

  return {
    ...state,
    groupsState: clonedGroupsState,
  };
}

export default settingsReducer;
