import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import palette from '../tools/palette';
import Button from '@material-ui/core/Button';

export const ButtonBox = styled(Grid)`
  ${(props) => (props.leftside ? 'margin-right: 16px;' : 'margin-left: 16px;')}
`;
export const SaveButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: ${palette.primaryColorLight};

    .MuiButton-label {
      color: #fff;
    }
  }

  &.MuiButtonBase-root:hover {
    background-color: ${palette.primaryColorLightHover};
  }
`;
