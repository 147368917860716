// @ts-ignore
import { utils } from 'react-grid-layout';
import { v4 } from 'uuid';

import { groupToRglLayout, rglToGroupLayout } from '../../utils/rglLayout';
import { overviewSetBreakdownFilter } from '../actions/overview';
import { createOverview } from '../../components/DashboardGenerationDialog/lib';
import { EMPTY_FILTER_QUERY } from '../../components/FilterForm/lib';
import * as api from '../../components/tools/api';
import { DATA_SOURCE_TYPE, WIDGET_HEIGHT, WIDGET_TYPE } from '../../constants';
import {
  addWidget,
  dashboardCreateLabelFailure,
  dashboardCreateLabelStart,
  dashboardCreateLabelSuccess,
  dashboardDeleteLabelFailure,
  dashboardDeleteLabelStart,
  dashboardDeleteLabelSuccess,
  dashboardFetchSentimentFailure,
  dashboardFetchSentimentStart,
  dashboardFetchSentimentSuccess,
  dashboardFetchSummaryFailure,
  dashboardFetchSummaryStart,
  dashboardFetchSummarySuccess,
  dashboardRegenerationInProgress,
  dashboardRenameLabelFailure,
  dashboardRenameLabelStart,
  dashboardRenameLabelSuccess,
  dashboardReset,
  dashboardSetDataSourcesPendingRefresh,
  dashboardShouldReloadResponses,
  fetchFail,
  fetchStart,
  fetchSuccess,
  removeWidget,
  setDataSources,
  setSentimentSummaries,
  setWidgetGroups,
  updateDashboardTitle,
} from '../actions/dashboard';
import { Action, AppDispatch, RootState } from '../hooks';
import { DashboardPayload } from '../../types/incoming/dashboard';
import { SummariesPayload } from '../../types/incoming/summary';
import {
  CurrentDashboardWidgetGroup,
  CurrentDashboardWidgetGroupLayout,
  DataSource,
  DataSourceRegeneratingStatus,
  PendingRefreshDataSource,
  ResponsesByDataSourceId,
  SentimentSummary,
  Widget,
  WidgetGroup,
} from '../reducers/dashboard/types';
import { RegenerationStatus } from '../../types/DataSource';
import { selectConcept as interviewWidgetSelectConcept } from '../actions/interviewWidget';

import { loadParticipants } from './interviewWidget';

const GRID_COMPACT_TYPE = 'vertical';

export const dashboardFetch =
  (id: string, isUnsafe = false): Action =>
  async (dispatch, getState) => {
    try {
      dispatch(dashboardReset());
      dispatch(fetchStart());
      dispatch(overviewSetBreakdownFilter(null));

      /*eslint-disable prefer-const*/
      let [dashboard, dataSources] = await Promise.all([
        api.fetchDashboard(id) as Promise<DashboardPayload>,
        api.fetchSummary(id, [], EMPTY_FILTER_QUERY) as Promise<SummariesPayload>,
      ]);

      // If latestUpdated is less than createdAt, set latestUpdated to createdAt
      if (new Date(dashboard.lastUpdated) < new Date(dashboard.createdAt)) {
        dashboard.lastUpdated = dashboard.createdAt;
      }

      // If latestUpdatedResponse is less than createdAt, set latestUpdatedResponse to createdAt
      if (new Date(dashboard.latestUpdatedResponse) < new Date(dashboard.createdAt)) {
        dashboard.latestUpdatedResponse = dashboard.createdAt;
      }

      const dataSourcesByStatuses = dashboard?.dataSources?.reduce(
        (acc, ds) => {
          // if dataSource is in generating or refreshing status needRefresh should be ignored
          if (ds.status === RegenerationStatus.Generating || ds.status === RegenerationStatus.Refreshing) {
            acc.refreshing.push(ds.dataSourceID);
            return acc;
          }
          if (ds.needRefresh) {
            acc.needRefresh.push({
              dataSourceID: ds.dataSourceID,
              status: DataSourceRegeneratingStatus.PendingRefreshAfterInit,
            });
          }
          return acc;
        },
        { refreshing: [] as Array<string>, needRefresh: [] as Array<PendingRefreshDataSource> },
      );

      if (dataSourcesByStatuses?.refreshing?.length) {
        dispatch(dashboardRegenerationInProgress({ dataSourceIDs: dataSourcesByStatuses.refreshing }));
      }

      if (dataSourcesByStatuses?.needRefresh?.length) {
        dispatch(dashboardSetDataSourcesPendingRefresh({ pendingRefreshDataSources: dataSourcesByStatuses.needRefresh }));
      }

      if (dashboard.underMaintenance && !isUnsafe) {
        dashboard = {
          ...dashboard,
          widgets: [],
          widgetGroups: [],
        };
      }

      const responsesByDataSourceId: ResponsesByDataSourceId = dataSources.reduce((acc, dataSource) => {
        return { ...acc, [dataSource.dataSourceID]: dataSource.items.map((item) => item.response) };
      }, {});

      if (dataSources && (!dashboard.underMaintenance || isUnsafe)) {
        const dashboardDataSources = dashboard.dataSources.map((dds) => {
          if (dataSources.some((ds) => ds.dataSourceID === dds.dataSourceID)) {
            return { ...dds, isEmpty: false };
          }
          return { ...dds, isEmpty: true };
        });

        // add missed data sources as empty
        for (const dashboardDataSource of dashboardDataSources) {
          if (dashboardDataSource.isEmpty) {
            dataSources.push({
              dataSourceID: dashboardDataSource.dataSourceID,
              items: [],
              total: 0,
              weightedTotal: 0,
            });
          }
        }
        const typedDataSources = dataSources as DataSource[];
        dispatch(setDataSources({ dataSources: typedDataSources }));
        dashboard = { ...dashboard, dataSources: dashboardDataSources };

        if (dashboard.hasSentiment) {
          const dataSourceIdsWithSentiment = dashboard.dataSources
            .filter((dds) => (dds.type === DATA_SOURCE_TYPE.OPEN_ENDED || dds.type === DATA_SOURCE_TYPE.INTERVIEW) && dds.visible)
            .map((dds) => dds.dataSourceID);
          const sentimentSummaries = await api.fetchSentiment(id, dataSourceIdsWithSentiment);

          dispatch(setSentimentSummaries({ sentimentSummaries }));
        }

        // load participants for focus group widgets
        const interviewDataSources = dashboard.dataSources.filter((ds) => ds.type === DATA_SOURCE_TYPE.INTERVIEW);
        interviewDataSources.forEach((ds) => {
          dispatch(loadParticipants({ dashboardID: dashboard.id, dataSourceID: ds.dataSourceID }));
        });
      }
      const state = getState();
      const isInternal = state.profile.user?.isInternal || false;
      dispatch(
        fetchSuccess({
          dashboard,
          responsesByDataSourceId,
          isInternal,
        }),
      );
    } catch {
      dispatch(
        fetchFail({
          error: 'Cannot get dashboard information',
        }),
      );
    }
  };

interface IDashboardDataSourceReload {
  dataSourceID: string;
  widgetID?: string;
  shouldReloadResponses?: boolean;
}
export const dashboardDataSourceReload =
  ({ dataSourceID, widgetID, shouldReloadResponses = true }: IDashboardDataSourceReload): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, dataSources: originalDataSources } = state.dashboard;

    if (!dashboard) {
      console.error('dashboard has not initilazed');
      return;
    }
    if (dashboard.dataSources.findIndex((x) => x.dataSourceID === dataSourceID) === -1) {
      console.error('dataSourceID is not found in the dashboard');
      return;
    }
    if (originalDataSources.findIndex((x) => x.dataSourceID === dataSourceID) === -1) {
      console.error('dataSourceID is not found in the dataSources');
      return;
    }

    try {
      dispatch(dashboardFetchSummaryStart({ dataSourceID }));
      const dataSources = await api.fetchSummary(dashboard.id, [dataSourceID], EMPTY_FILTER_QUERY);
      if (dataSources.length !== 1) {
        dispatch(dashboardFetchSummaryFailure({ dataSourceID }));
        return;
      }
      const updatedDataSource = dataSources[0] as DataSource;
      dispatch(dashboardFetchSummarySuccess({ dataSourceID, dataSource: updatedDataSource }));
      if (widgetID) {
        dashboard.widgets.forEach((widget) => {
          if (widget.settings.dataSources.map((x) => x.id).includes(dataSourceID)) {
            if (widget.base.id === widgetID) {
              return;
            }
            // reset concept selection for interview widgets if doesn't exist in the new data source
            if (widget.base.type === WIDGET_TYPE.INTERVIEW) {
              const selected = state.interviewWidgets?.configsByWidgetID[widget.base.id]?.selectedConcept;
              if (selected?.title) {
                if (updatedDataSource.items.findIndex((uds) => uds.response === selected.title) === -1) {
                  dispatch(interviewWidgetSelectConcept({ widgetID: widget.base.id, title: null }));
                }
              }
            }
          }
        });
      }
    } catch {
      dispatch(dashboardFetchSummaryFailure({ dataSourceID }));
      return;
    }

    if (dashboard.hasSentiment) {
      try {
        dispatch(dashboardFetchSentimentStart({ dataSourceID }));
        const dsSentimentSummaries = await api.fetchSentiment(dashboard.id, [dataSourceID]);
        if (dsSentimentSummaries.length !== 1) {
          dispatch(dashboardFetchSentimentFailure({ dataSourceID }));
          return;
        }
        const dsSentiment = dsSentimentSummaries[0] as SentimentSummary;
        dispatch(dashboardFetchSentimentSuccess({ dataSourceID, sentiment: dsSentiment }));
      } catch {
        dispatch(dashboardFetchSentimentFailure({ dataSourceID }));
        return;
      }
    }
    if (shouldReloadResponses) {
      dispatch(dashboardShouldReloadResponses({ dataSourceID }));
    }
  };

export const dashboardSetDataSourcePendingRefresh =
  (dataSourceID: string): Action =>
  async (dispatch, getState) => {
    dispatch(
      dashboardSetDataSourcesPendingRefresh({
        pendingRefreshDataSources: [
          {
            dataSourceID,
            status: DataSourceRegeneratingStatus.PendingRefresh,
          },
        ],
      }),
    );
  };

export const dashboardWidgetGroupUpdate =
  (group: CurrentDashboardWidgetGroup, save: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (dashboard?.id) {
      const newWidgetGroups = widgetGroups.map((widgetGroup) => {
        if (widgetGroup.id === group.id) {
          return group as WidgetGroup;
        }
        return widgetGroup as WidgetGroup;
      });

      dispatch(setWidgetGroups({ widgetGroups: newWidgetGroups }));
      if (save) {
        await api.updateWidgetGroup(dashboard.id, group);
      }
    }
  };

interface IDashboardUpdateTitle {
  id: string;
  title: string;
}
export const dashboardUpdateTitle =
  ({ id, title }: IDashboardUpdateTitle): Action =>
  async (dispatch) => {
    try {
      await api.patchDashboard(id, { title });
      dispatch(updateDashboardTitle({ id, title }));
    } catch (e) {
      console.error('Dashboard title was not updated', e);
    }
  };

export const dashboardSwapWidgetGroupsOrder =
  (groupId1: string, groupId2: string): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (dashboard?.id) {
      const { id } = dashboard;
      const widgetGroup1 = widgetGroups.find((group) => group.id === groupId1);
      const widgetGroup2 = widgetGroups.find((group) => group.id === groupId2);

      const newWidgetGroup1 = { ...widgetGroup1, order: widgetGroup2?.order };
      const newWidgetGroup2 = { ...widgetGroup2, order: widgetGroup1?.order };

      try {
        await api.updateWidgetGroup(id, newWidgetGroup1);
        const newWidgetGroups: WidgetGroup[] = widgetGroups.map((widgetGroup) => {
          if (widgetGroup.id === groupId1) {
            return newWidgetGroup1 as WidgetGroup;
          }
          if (widgetGroup.id === groupId2) {
            return newWidgetGroup2 as WidgetGroup;
          }
          return widgetGroup as WidgetGroup;
        });

        dispatch(setWidgetGroups({ widgetGroups: newWidgetGroups }));
      } catch (e) {
        console.error('Widget groups are not updated', e);
      }
    }
  };

export const dashboardWidgetDimensionChange =
  (groupId: string, widgetId: string, width: number, height: number, save: boolean): Action =>
  (dispatch, getState) => {
    const state = getState();
    const group = state.dashboard.widgetGroups.find((widgetGroup) => widgetGroup.id === groupId);

    if (!group) {
      console.error('Widget group was not found: ', groupId, widgetId, width, height, save);
      return;
    }

    let isChanged = false;
    const newLayout = [];
    for (const item of group.layout) {
      if (item.widgetID === widgetId && (item.width !== width || item.height !== height)) {
        newLayout.push({ ...item, width, height });
        isChanged = true;
      } else {
        newLayout.push(item);
      }
    }

    if (isChanged) {
      const updatedGroup = { ...group, layout: newLayout };
      dispatch(dashboardWidgetGroupUpdate(updatedGroup, save));
    }
  };

export const dashboardUpdateGroupLayout =
  (groupId: string, layout: CurrentDashboardWidgetGroupLayout[]): Action =>
  (dispatch, getState) => {
    const state = getState();
    const group = state.dashboard.widgetGroups.find((widgetGroup) => widgetGroup.id === groupId);
    if (group) {
      const updatedGroup = { ...group, layout } as CurrentDashboardWidgetGroup;
      dispatch(dashboardWidgetGroupUpdate(updatedGroup, true));
    } else {
      console.error('dashboardUpdateGroupLayout >>> Widget group was not found: ', groupId, layout);
    }
  };

export const dashboardCloneWidget =
  (config: Widget): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const widgetGroup = state.dashboard.widgetGroups.find((widgetGroup) => {
      return widgetGroup.layout.some((item) => item.widgetID === config.base.id);
    });

    if (widgetGroup) {
      const rglLayout = groupToRglLayout(widgetGroup.layout);
      const compactedRglLayout = utils.compact(rglLayout, GRID_COMPACT_TYPE, 2);
      const layoutItem = compactedRglLayout.find((item: { i: any }) => item.i === config.base.id);

      dispatch(dashboardAddWidget(config, widgetGroup.id, null, { ...layoutItem, y: layoutItem.y + 1 }));
    } else {
      console.error('dashboardCloneWidget >>> Widget group was not found: ', config);
    }
  };

export const dashboardAddWidget =
  (widget: Widget, widgetGroupId: string | null, widgetGroupTitle: string | null, baseLayoutItem: any = null): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (!dashboard?.id) {
      console.error('dashboardAddWidget >>> Dashboard is not present: ', state.dashboard);
      return;
    }
    const { id: dashboardId } = dashboard;
    const widgetId = await api.createWidget(dashboardId, widget);
    let widgetGroup: WidgetGroup;
    if (widgetGroupId === null) {
      const maxOrder = widgetGroups.reduce((order, widgetGroup) => Math.max(order, widgetGroup.order), 0);
      const payload = {
        title: widgetGroupTitle as string,
        originalTitle: widgetGroupTitle as string,
        order: maxOrder + 1,
        collapsed: true,
        layout: [],
        isOverview: false,
      };
      const newWidgetGroupId = await api.createWidgetGroup(dashboardId, payload);
      widgetGroup = {
        ...payload,
        id: newWidgetGroupId,
      };
    } else {
      widgetGroup = widgetGroups.find((widgetGroup) => widgetGroup.id === widgetGroupId) as WidgetGroup;
    }

    let width = 1;
    if (widget.base.type === WIDGET_TYPE.OPEN_ENDED || widget.base.type === WIDGET_TYPE.INTERVIEW) {
      width = 2;
    }

    let height = WIDGET_HEIGHT.DEFAULT;
    if (widget.base.type === WIDGET_TYPE.OPEN_ENDED) {
      height = WIDGET_HEIGHT.DEFAULT_OPEN_ENDED;
    }
    if (widget.base.type === WIDGET_TYPE.INTERVIEW) {
      height = WIDGET_HEIGHT.DEFAULT_INTERVIEW;
    }

    let layoutItem = {
      x: 0,
      y: 0,
      w: width,
      h: height,
      i: widgetId,
    };

    if (baseLayoutItem) {
      layoutItem = { ...baseLayoutItem, i: widgetId };
    } else {
      if (widgetGroup.layout.length > 0) {
        const itemsSortedByPosition = [...widgetGroup.layout].sort((a, b) => {
          const dy = b.y - a.y;
          if (dy !== 0) {
            return dy;
          }
          return b.x - a.x;
        });
        const lastItem = itemsSortedByPosition[0];
        layoutItem.x = 0;
        layoutItem.y = lastItem.y + 1;
        // if here is a space next to the last item
        if (lastItem.x === 0 && lastItem.width === 1 && layoutItem.w === 1) {
          layoutItem.x = 1;
          layoutItem.y = lastItem.y;
        }
      }
    }

    const rglLayout = utils.compact(groupToRglLayout(widgetGroup.layout), GRID_COMPACT_TYPE, 2).concat(layoutItem);
    const compactedRglLayout = utils.compact(rglLayout, GRID_COMPACT_TYPE, 2);
    const groupLayout = rglToGroupLayout(compactedRglLayout, [...widgetGroup.layout, { id: v4(), widgetID: widgetId }]);

    const updatedWidgetGroup = {
      ...widgetGroup,
      layout: groupLayout,
    };

    const newWidgetGroups = widgetGroups.map((widgetGroup) => {
      if (widgetGroup.id === updatedWidgetGroup.id) {
        return updatedWidgetGroup;
      }
      return widgetGroup;
    });

    if (widgetGroupId === null) {
      newWidgetGroups.push(updatedWidgetGroup);
    }

    await api.updateWidgetGroup(dashboardId, updatedWidgetGroup);
    dispatch(
      addWidget({
        widget: {
          ...widget,
          base: {
            ...widget.base,
            id: widgetId,
          },
        },
      }),
    );

    dispatch(
      setWidgetGroups({
        widgetGroups: newWidgetGroups,
      }),
    );
  };

export const dashboardRemoveWidget =
  (widgetId: string): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (!dashboard?.id) {
      console.error('dashboardRemoveWidget >>> Dashboard is not present: ', state.dashboard);
      return;
    }
    const { id: dashboardId } = dashboard;

    await api.deleteWidget(dashboardId, widgetId);

    const widgetGroup = widgetGroups.find((widgetGroup) => {
      return widgetGroup.layout.some((item) => item.widgetID === widgetId);
    });

    const updatedWidgetGroup = {
      ...widgetGroup,
      layout: widgetGroup?.layout.filter((item) => item.widgetID !== widgetId),
    };

    const newWidgetGroups = widgetGroups.map((widgetGroup) => {
      if (widgetGroup.id === updatedWidgetGroup.id) {
        return updatedWidgetGroup;
      }
      return widgetGroup;
    });

    await api.updateWidgetGroup(dashboardId, updatedWidgetGroup);

    dispatch(
      setWidgetGroups({
        widgetGroups: newWidgetGroups as WidgetGroup[],
      }),
    );

    dispatch(
      removeWidget({
        widgetId,
      }),
    );
  };

export const dashboardCreateOverview = (): Action => async (dispatch, getState) => {
  const state = getState();
  const { dashboard, widgetGroups } = state.dashboard;
  if (!dashboard?.id) {
    console.error('dashboardCreateOverview >>> Dashboard is not present: ', state.dashboard);
    return;
  }
  const { id: dashboardId, dataSources: dashboardDataSources } = dashboard;
  const [widgetConfig, widgetsGroupConfig] = await createOverview(dashboardId, dashboardDataSources);
  const newWidgetGroups = widgetGroups.concat(widgetsGroupConfig as WidgetGroup);

  dispatch(
    addWidget({
      widget: widgetConfig as Widget,
    }),
  );

  dispatch(
    setWidgetGroups({
      widgetGroups: newWidgetGroups,
    }),
  );
};

export const dashboardRemoveOverview =
  (widgetID: string): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (!dashboard?.id) {
      console.error('dashboardRemoveOverview >>> Dashboard is not present: ', state.dashboard);
      return;
    }
    const { id: dashboardId } = dashboard;
    const overviewWidgetGroup = widgetGroups.find((wg) => wg.layout.length === 1 && wg.layout[0].widgetID === widgetID);

    await api.deleteWidget(dashboardId, widgetID);
    await api.deleteWidgetGroup(dashboardId, overviewWidgetGroup?.id);

    const newWidgetGroups = widgetGroups.filter((wg) => wg.id !== overviewWidgetGroup?.id);

    dispatch(
      setWidgetGroups({
        widgetGroups: newWidgetGroups,
      }),
    );

    dispatch(
      removeWidget({
        widgetId: widgetID,
      }),
    );
  };

export const dashboardCreateWidgetGroup =
  (title: string): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (!dashboard?.id) {
      console.error('dashboardCreateWidgetGroup >>> Dashboard is not present: ', state.dashboard);
      return;
    }
    const { id: dashboardId } = dashboard;
    const maxOrder = widgetGroups.reduce((order, widgetGroup) => Math.max(order, widgetGroup.order), 0);

    const widgetsGroupConfig = {
      title,
      originalTitle: title,
      order: maxOrder + 1,
      collapsed: true,
      layout: [],
    } as Partial<WidgetGroup>;
    widgetsGroupConfig.id = await api.createWidgetGroup(dashboardId, widgetsGroupConfig);

    const newWidgetGroups = widgetGroups.concat(widgetsGroupConfig as WidgetGroup);

    dispatch(
      setWidgetGroups({
        widgetGroups: newWidgetGroups,
      }),
    );
  };

export const dashboardRemoveWidgetGroup =
  (id: string): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (!dashboard?.id) {
      console.error('dashboardRemoveWidgetGroup >>> Dashboard is not present: ', state.dashboard);
      return;
    }
    const { id: dashboardId } = dashboard;
    const widgetGroup = widgetGroups.find((widgetGroup) => widgetGroup.id === id);
    let defaultWidgetGroup = widgetGroups.find((widgetGroup) => widgetGroup.isDefault) as Partial<WidgetGroup>;

    // create default group if it's missing
    if (!defaultWidgetGroup) {
      const maxOrder = widgetGroups.reduce((order, widgetGroup) => Math.max(order, widgetGroup.order), 0);
      defaultWidgetGroup = {
        title: '',
        originalTitle: '',
        order: maxOrder + 1,
        collapsed: false,
        layout: [],
        isOverview: false,
      };
    }

    // move layout items to the default group
    if (widgetGroup && widgetGroup.layout.length > 0) {
      const offsetY = (defaultWidgetGroup!.layout?.reduce((y, item) => Math.max(item.y, y), -1) || 0) + 1;
      for (const item of widgetGroup.layout) {
        defaultWidgetGroup!.layout?.push({
          ...item,
          y: item.y + offsetY,
          id: v4(),
        });
      }
    }

    // remove group
    await api.deleteWidgetGroup(dashboardId, id);
    const newWidgetGroups = widgetGroups.filter((widgetGroup) => widgetGroup.id !== id);

    if (widgetGroup && widgetGroup.layout.length > 0) {
      if (defaultWidgetGroup.id) {
        // update default group
        await api.updateWidgetGroup(dashboardId, defaultWidgetGroup);
      } else {
        // create default group
        defaultWidgetGroup.id = await api.createWidgetGroup(dashboardId, defaultWidgetGroup);
        newWidgetGroups.push(defaultWidgetGroup as WidgetGroup);
      }
    }

    dispatch(
      setWidgetGroups({
        widgetGroups: newWidgetGroups,
      }),
    );
  };

export const dashboardRenameWidgetGroup =
  (id: string, newTitle: string, newOriginalTitle: string): Action =>
  async (dispatch, getState) => {
    const state = getState();
    const { dashboard, widgetGroups } = state.dashboard;
    if (!dashboard?.id) {
      console.error('dashboardRenameWidgetGroup >>> Dashboard is not present: ', state.dashboard);
      return;
    }
    const { id: dashboardId } = dashboard;
    const widgetGroup = widgetGroups.find((widgetGroup) => widgetGroup.id === id);
    const updatedWidgetGroup = {
      ...widgetGroup,
      title: newTitle,
      originalTitle: newOriginalTitle,
    } as WidgetGroup;

    await api.updateWidgetGroup(dashboardId, updatedWidgetGroup);

    const newWidgetGroups: WidgetGroup[] = widgetGroups.map((widgetGroup) => {
      if (widgetGroup.id === id) {
        return updatedWidgetGroup;
      }
      return widgetGroup;
    });

    dispatch(setWidgetGroups({ widgetGroups: newWidgetGroups }));
  };

interface ICreateLabel {
  dataSourceID: string;
  label: string;
}
export const dashboardCreateLabel =
  ({ dataSourceID, label }: ICreateLabel): Action =>
  async (dispatch, getState) => {
    dispatch(dashboardCreateLabelStart({ dataSourceID, label }));
    const state = getState();
    const { dashboard } = state.dashboard;
    if (!dashboard) {
      dispatch(dashboardCreateLabelFailure({ dataSourceID, label }));
      return;
    }
    const datasources = state.dashboard.dataSources;
    const dataSource = datasources.find((ds) => ds.dataSourceID === dataSourceID);
    if (!dataSource) {
      dispatch(dashboardCreateLabelFailure({ dataSourceID, label }));
      return;
    }

    if (dataSource.items.find((x) => x.response === label)) {
      dispatch(dashboardCreateLabelFailure({ dataSourceID, label }));
      return;
    }
    try {
      await api.createLabel(dashboard.id, dataSourceID, {
        title: label,
      });
      dispatch(dashboardCreateLabelSuccess({ dataSourceID, label }));
      // Refresh AI
      dispatch(startRegeneration({ dataSourceIDs: [dataSourceID] }));
      dispatch(dashboardRegenerationInProgress({ dataSourceIDs: [dataSourceID] }));
    } catch (e) {
      dispatch(dashboardCreateLabelFailure({ dataSourceID, label }));
    }
  };

interface IRenameLabel {
  dataSourceID: string;
  widgetID: string;
  originalLabel: string;
  newLabel: string;
}
export const dashboardRenameLabel =
  ({ dataSourceID, widgetID, originalLabel, newLabel }: IRenameLabel) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dashboardRenameLabelStart({ dataSourceID, label: originalLabel }));
    const state = getState();
    const { dashboard } = state.dashboard;
    if (!dashboard) {
      dispatch(dashboardRenameLabelFailure({ dataSourceID, label: originalLabel }));
      return false;
    }
    const datasources = state.dashboard.dataSources;
    const dataSource = datasources.find((ds) => ds.dataSourceID === dataSourceID);
    if (!dataSource) {
      dispatch(dashboardRenameLabelFailure({ dataSourceID, label: originalLabel }));
      return false;
    }

    if (!dataSource.items.find((x) => x.response === originalLabel)) {
      dispatch(dashboardRenameLabelFailure({ dataSourceID, label: originalLabel }));
      return false;
    }

    try {
      await api.renameLabel(dashboard.id, dataSourceID, originalLabel, {
        title: newLabel,
      });
      dispatch(dashboardRenameLabelSuccess({ dataSourceID, originalLabel, newLabel }));
      dispatch(dashboardSetDataSourcePendingRefresh(dataSourceID));
      dispatch(dashboardDataSourceReload({ dataSourceID, widgetID }));
      return true;
    } catch (e) {
      dispatch(dashboardRenameLabelFailure({ dataSourceID, label: originalLabel }));
      return false;
    }
  };

interface IDeleteLabel {
  dataSourceID: string;
  widgetID: string;
  label: string;
}
export const dashboardDeleteLabel =
  ({ dataSourceID, widgetID, label }: IDeleteLabel) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dashboardDeleteLabelStart({ dataSourceID, label }));
    const state = getState();
    const { dashboard } = state.dashboard;
    if (!dashboard) {
      dispatch(dashboardDeleteLabelFailure({ dataSourceID, label }));
      return false;
    }
    const datasources = state.dashboard.dataSources;
    const dataSource = datasources.find((ds) => ds.dataSourceID === dataSourceID);
    if (!dataSource) {
      dispatch(dashboardDeleteLabelFailure({ dataSourceID, label }));
      return false;
    }

    if (!dataSource.items.find((x) => x.response === label)) {
      dispatch(dashboardDeleteLabelFailure({ dataSourceID, label }));
      return false;
    }

    try {
      await api.deleteLabel(dashboard.id, dataSourceID, label);
      dispatch(dashboardDeleteLabelSuccess({ dataSourceID, label }));
      dispatch(dashboardSetDataSourcePendingRefresh(dataSourceID));
      dispatch(dashboardDataSourceReload({ dataSourceID, widgetID }));
      return true;
    } catch (e) {
      dispatch(dashboardDeleteLabelFailure({ dataSourceID, label }));
      return false;
    }
  };

interface IStartRegeneration {
  dataSourceIDs: string[];
}
export const startRegeneration =
  ({ dataSourceIDs }: IStartRegeneration): Action =>
  async (dispatch, getState) => {
    try {
      const dashboardID = getState().dashboard.dashboard?.id;
      dispatch(dashboardRegenerationInProgress({ dataSourceIDs: dataSourceIDs }));
      await Promise.all([dataSourceIDs.map((dsID) => api.startRegeneration(dashboardID, dsID))]);
    } catch (e) {
      console.error(e);
    }
  };
