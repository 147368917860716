import React, { useCallback, useContext, useEffect, useState } from 'react';

import useFilters from '../../hooks/useFilters';
import { getTotalsByDataSourcesAndFiltersIds, SummaryDataContext } from '../../contexts/SummaryDataContext';
import { useSelector } from 'react-redux';
import { getSummariesWithQuery } from '../../contexts/lib';

export const WidgetTotalsContext = React.createContext();

export const WidgetTotalsProvider = ({ query, widgetDataSources, children, forceFetch }) => {
  const [filters] = useFilters();
  const { loading: globalLoading, totalsByDataSourcesAndFiltersIds: globalTotals } = useContext(SummaryDataContext);
  const breakdownFilter = useSelector((state) => state.overview.breakdownFilter);
  const {
    dashboard: { id: dashboardId },
  } = useSelector((state) => state.dashboard);
  const [totalsByDataSourcesAndFiltersIds, setTotalsByDataSourcesAndFiltersIds] = useState({});
  const [loading, setLoading] = useState(false);

  const setTotals = useCallback(
    (totals) => {
      setTotalsByDataSourcesAndFiltersIds(totals);
    },
    [setTotalsByDataSourcesAndFiltersIds],
  );

  useEffect(() => {
    let mounted = true;
    const fetchTotals = async () => {
      setLoading(true);
      setTotalsByDataSourcesAndFiltersIds({});
      const dataSourcesIDs = widgetDataSources.map((widgetDataSource) => widgetDataSource.id);
      const responses = await getSummariesWithQuery(dashboardId, dataSourcesIDs, filters, query);
      if (mounted) {
        setLoading(false);
        setTotals(getTotalsByDataSourcesAndFiltersIds(responses, filters));
      }
    };

    if (!query) {
      setLoading(globalLoading);
      setTotalsByDataSourcesAndFiltersIds(globalTotals);
    } else if (breakdownFilter || forceFetch) {
      fetchTotals();
    }

    return () => {
      mounted = false;
    };
  }, [query, globalTotals, globalLoading, breakdownFilter, dashboardId, filters, widgetDataSources, forceFetch, setTotals]);

  const value = {
    setTotals,
    loading,
    totalsByDataSourcesAndFiltersIds,
  };

  return <WidgetTotalsContext.Provider value={value}>{children}</WidgetTotalsContext.Provider>;
};
