import { datadogLogs } from '@datadog/browser-logs';

import {
  getAuthProvidersUrl,
  getAzureFilesUrl,
  getBookmarkResponseUrl,
  getBrandsUrl,
  getBreakdownUrl,
  getConnectionsUrl,
  getConnectionUrl,
  getCreateFilterUrl,
  getCsrfToken,
  getDashboardsUrl,
  getDashboardUrl,
  getDashboardViewsUrl,
  getDashboardViewUrl,
  getExportUrl,
  getFetchSettings,
  getFullRecord,
  getLabelsUrl,
  getLanguagesUrl,
  getProcessingCSVsUrl,
  getProfileUrl,
  getProviderAuthsUrl,
  getProviderAuthUrl,
  getQualtricsSurveysUrl,
  getResponsesUrl,
  getSavedFiltersUrl,
  getSavedFilterUrl,
  getSegmentsUrl,
  getSentimentSummaryUrl,
  getSummaryUrl,
  getSurveysUrl,
  getTotalTrendsUrl,
  getTotalUrl,
  getTrendsBreakDownUrl,
  getTrendsUrl,
  getUpdateFilterUrl,
  getUploadCSVUrl,
  getWidgetGroupUrl,
  getWidgetUrl,
  postAIInsightsFeedbackUrl,
  postAuthUrl,
  putAIInsightsRequestUrl,
  getCustomersUrl,
  getLabelsManagementUrl,
  getRegenerationStatusUrl,
  putInterviewInsightsRequestUrl,
  putRegeneratingUrl,
  getReposnonseLabelsManagementUrl,
  getSignupWorkspaceUrl,
} from '../../contexts/api-routes';
import { createDateRule, EMPTY_FILTER_QUERY } from '../FilterForm/lib';
import { COMBINATOR, OPERATOR_NAME } from '../FilterForm/constants';
import { API_BASE_URL, LOG_MESSAGE_TYPE } from '../../constants';
import { getTestsLinks } from './index';
import CONFIG from '../../config';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';

const SEGMENTS_FETCH_LIMIT = 10;

class ApiException {
  constructor(status, text, url, method) {
    this.status = status;
    this.text = text;
    this.url = url;
    this.method = method;
  }

  toString() {
    return `${this.status}: ${this.text}`;
  }
}

const apiFetch = (url, method = 'GET', body = null, options = {}, customSettings = null) => {
  const settings = customSettings ? customSettings : { ...getFetchSettings(method, body), ...options };
  const startTime = new Date().getTime();
  return fetch(url, settings)
    .then((response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      if (response.ok && isJson) {
        return response.json().then((json) => [response, json]);
      }
      return response.text().then((text) => [response, text]);
    })
    .then(([response, value]) => {
      if (response.ok) {
        return value;
      }
      throw new ApiException(response.status, value, url, method);
    })
    .catch((error) => {
      if (options?.withAbortRethrow) {
        throw error;
      }
      if (error.name !== 'AbortError') {
        const endTime = new Date().getTime();

        const links = getTestsLinks();
        datadogLogs.logger.error(`[API Error][${error}]`, {
          type: LOG_MESSAGE_TYPE.API_ERROR,
          details: error,
          duration: endTime - startTime,
          unsafeLink: links.unsafe,
          debugLink: links.debug,
        });
        return Promise.resolve();
      }
    });
};

export const createWidget = async (dashboardId, config) => {
  const widgetUrl = getWidgetUrl(dashboardId);
  const widget = await apiFetch(widgetUrl, 'POST', config);
  return widget.id;
};

export const updateWidget = async (config) => {
  const widgetUrl = getWidgetUrl(config.base.dashboardID, config.base.id);
  let { filter, ...base } = config.base;
  if (filter) {
    if (filter.filterID) {
      base = { ...base, filter: { filterID: filter.filterID } };
    } else if (filter.query) {
      base = { ...base, filter: { query: filter.query } };
    }
  }
  await apiFetch(widgetUrl, 'PUT', { base, settings: config.settings });
};

export const deleteWidget = async (dashboardId, widgetId) => {
  const widgetUrl = getWidgetUrl(dashboardId, widgetId);
  await apiFetch(widgetUrl, 'DELETE');
};

export const putAIInsightsGenerationRequest = async (
  signal,
  dashboardId,
  widgetId,
  dataSourceID,
  selectedConcept,
  customPrompt,
  totalAnswersAmount,
  filter,
  force,
) => {
  const generateInsightsURL = putAIInsightsRequestUrl(dashboardId, widgetId);
  return await apiFetch(
    generateInsightsURL,
    'PUT',
    {
      dataSourceId: dataSourceID,
      totalAnswersAmount,
      selectedConcept: selectedConcept ? selectedConcept : null,
      customPrompt: customPrompt ? customPrompt : null,
      filter,
      force: force,
    },
    { signal, withAbortRethrow: true },
  );
};

export const putInterviewInsightsGenerationRequest = async (
  signal,
  dashboardId,
  widgetId,
  dataSourceID,
  selectedConcept,
  customPrompt,
  totalAnswersAmount,
  filter,
  force,
) => {
  const generateInsightsURL = putInterviewInsightsRequestUrl(dashboardId, widgetId);
  return await apiFetch(
    generateInsightsURL,
    'PUT',
    {
      dataSourceId: dataSourceID,
      totalAnswersAmount,
      selectedConcept: selectedConcept ? selectedConcept : null,
      customPrompt: customPrompt ? customPrompt : null,
      filter,
      force: force,
    },
    { signal, withAbortRethrow: true },
  );
};

export const postAIInsightsFeedback = async (dashboardId, widgetId, requestBody) => {
  const generateFeedbackURL = postAIInsightsFeedbackUrl(dashboardId, widgetId);
  return await apiFetch(generateFeedbackURL, 'POST', requestBody);
};

export const createWidgetGroup = async (dashboardId, config) => {
  const widgetGroupUrl = getWidgetGroupUrl(dashboardId);
  const widgetGroup = await apiFetch(widgetGroupUrl, 'POST', config);
  return widgetGroup.id;
};

export const updateWidgetGroup = async (dashboardId, config) => {
  const widgetGroupUrl = getWidgetGroupUrl(dashboardId, config.id);
  await apiFetch(widgetGroupUrl, 'PUT', config);
};

export const deleteWidgetGroup = async (dashboardId, widgetGroupId) => {
  const url = getWidgetGroupUrl(dashboardId, widgetGroupId);
  await apiFetch(url, 'DELETE');
};

export const fetchDashboards = async (dateRange = null, params = {}) => {
  const { abortController } = params;
  const filter = dateRange
    ? {
        combinator: COMBINATOR.AND,
        rules: [
          createDateRule(OPERATOR_NAME.GREATER_THAN_OR_EQUAL_TO, dateRange.startDate),
          createDateRule(OPERATOR_NAME.LESS_THAN_OR_EQUAL_TO, dateRange.endDate),
        ],
      }
    : {
        combinator: COMBINATOR.AND,
        rules: [],
      };
  const options = {};
  if (abortController) {
    options.signal = abortController.signal;
  }
  return await apiFetch(getDashboardsUrl(), 'POST', filter, options);
};

export const fetchDashboardViews = async () => {
  return await apiFetch(getDashboardViewsUrl());
};

export const deleteDashboardView = async (id) => {
  const dashboardViewUrl = getDashboardViewUrl(id);
  await apiFetch(dashboardViewUrl, 'DELETE');
};

export const createDashboardsView = async (config) => {
  const dashboardView = await apiFetch(getDashboardViewUrl(), 'POST', config);
  return dashboardView.id;
};

export const updateDashboardsView = async (config) => {
  const dashboardViewUrl = getDashboardViewUrl(config.id);
  await apiFetch(dashboardViewUrl, 'PUT', config);
};

export const fetchSummaries = async (dashboardId, params) => {
  const summaryUrl = getSummaryUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceIDs, filter, reduced }) =>
      apiFetch(summaryUrl, 'POST', {
        dataSourceIDs,
        filter,
        reduced: !!reduced,
      }),
    ),
  );
};

export const fetchSummary = async (dashboardId, dataSourceIDs, filter, reduced) => {
  const summaries = await fetchSummaries(dashboardId, [{ dataSourceIDs, filter, reduced }]);
  return Promise.resolve(summaries.pop());
};

export const fetchSummariesBreakdown = async (dashboardId, breakdown, params) => {
  const summaryUrl = getBreakdownUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceIDs, filter }) =>
      apiFetch(summaryUrl, 'POST', {
        dataSourceIDs,
        breakdown,
        filter,
      }),
    ),
  );
};

export const fetchTrends = async (dashboardId, params) => {
  const trendsUrl = getTrendsUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceID, select, groupBy, filter }) =>
      apiFetch(trendsUrl, 'POST', {
        dataSourceID,
        select,
        groupBy,
        filter,
      }),
    ),
  );
};

export const fetchTrend = async (dashboardId, dataSourceID, select, groupBy, filter) => {
  const trends = await fetchTrends(dashboardId, [{ dataSourceID, select, groupBy, filter }]);
  return Promise.resolve(trends.pop());
};

export const fetchTrendsBreakdown = async (dashboardId, breakdown, params) => {
  const trendsBreakdownUrl = getTrendsBreakDownUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceID, select, groupBy, filter }) =>
      apiFetch(trendsBreakdownUrl, 'POST', {
        breakdown,
        dataSourceID,
        select,
        groupBy,
        filter,
      }),
    ),
  );
};

export const fetchMultipleTotals = async (dashboardId, queries) => {
  const totalUrl = getTotalUrl(dashboardId);
  return await Promise.all(queries.map((query) => apiFetch(totalUrl, 'POST', query)));
};

export const fetchTotals = async (dashboardId, query = EMPTY_FILTER_QUERY) => {
  const multipleTotals = await fetchMultipleTotals(dashboardId, [query]);
  return Promise.resolve(multipleTotals.pop());
};

export const fetchTotalTrends = async (dashboardId, groupBy) => {
  const totalTrendsUrl = getTotalTrendsUrl(dashboardId, groupBy);
  return await apiFetch(totalTrendsUrl, 'GET');
};

export const fetchSentiment = async (dashboardId, dataSourceIDs, filter = EMPTY_FILTER_QUERY) => {
  const sentimentUrl = getSentimentSummaryUrl(dashboardId);
  return await apiFetch(sentimentUrl, 'POST', { dataSourceIDs, filter });
};

export const fetchDashboardExport = async (dashboardId, isTranslated, payload) => {
  const exportUrl = getExportUrl(dashboardId, isTranslated);
  return await apiFetch(exportUrl, 'POST', payload);
};

export const fetchSegments = async (dashboardId, offset) => {
  const segmentsUrl = getSegmentsUrl(dashboardId) + `?offset=${offset}&limit=${SEGMENTS_FETCH_LIMIT}`;
  return await apiFetch(segmentsUrl, 'POST');
};

const getAuthorizationOptions = (bearerToken) => ({
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
});

export const fetchCsrfToken = async (bearerToken) => {
  const options = getAuthorizationOptions(bearerToken);
  return await apiFetch(postAuthUrl, 'GET', null, null, options);
};

export const fetchUserInformation = async (bearerToken) => {
  const options = getAuthorizationOptions(bearerToken);
  const url = `https://${CONFIG.AUTH0.DOMAIN}/userinfo`;
  return await apiFetch(url, 'GET', null, null, options);
};

export const fetchUser = async () => {
  const url = `${API_BASE_URL}/api/user`;
  return await apiFetch(url);
};

export const fetchDashboard = async (dashboardId) => {
  const url = getDashboardUrl(dashboardId);
  return await apiFetch(url);
};

export const patchDashboard = async (dashboardId, data) => {
  const url = getDashboardUrl(dashboardId);
  return await apiFetch(url, 'PATCH', data);
};

export const fetchSavedFilters = (dashboardId) => {
  const url = getSavedFiltersUrl(dashboardId);
  return apiFetch(url);
};

export const createSavedFilter = async (dashboardId, name, query, description) => {
  const url = getCreateFilterUrl(dashboardId, name, description);
  return await apiFetch(url, 'POST', query);
};

export const updateSavedFilter = async (dashboardId, savedFilterId, name, query, description) => {
  const url = getUpdateFilterUrl(dashboardId, savedFilterId, name, description);
  return await apiFetch(url, 'PUT', query);
};

export const deleteSavedFilter = async (dashboardId, filterId) => {
  const url = getSavedFilterUrl(dashboardId, filterId);
  return await apiFetch(url, 'DELETE');
};

export const fetchFullRecord = async (dashboardId, answerId) => {
  const url = getFullRecord(dashboardId, answerId);
  return await apiFetch(url);
};

export const fetchExtendLabels = async (dashboardId, responseId) => {
  const url = getLabelsUrl(dashboardId, responseId);
  return await apiFetch(url);
};

export const fetchResponses = async (dashboardId, dataSourceID, offset, limit, filter, sorting, bookmarkedOnly) => {
  const url = getResponsesUrl(dashboardId);
  const data = { dataSourceID, offset, limit, filter, sorting, bookmarkedOnly };
  return await apiFetch(url, 'POST', data);
};

export const bookmarkResponse = async (dashboardId, answerID) => {
  const url = getBookmarkResponseUrl(dashboardId);
  const data = { answerID };
  return await apiFetch(url, 'POST', data);
};

export const unbookmarkResponse = async (dashboardId, answerID) => {
  const url = getBookmarkResponseUrl(dashboardId, answerID);
  await apiFetch(url, 'DELETE');
};

export const fetchProfile = async () => {
  const url = getProfileUrl();
  return await apiFetch(url);
};

export const updateProfile = async (profile) => {
  const url = getProfileUrl();
  return await apiFetch(url, 'PUT', profile);
};

export const fetchSurveys = async () => {
  const url = getSurveysUrl();
  return await apiFetch(url);
};

export const uploadCSV = async (arrayBuffer, metadata) => {
  const url = getUploadCSVUrl();
  const formData = new FormData();
  const blob = new Blob([arrayBuffer]);
  formData.append('file', blob, metadata.fileName);
  formData.append('metadata', JSON.stringify(metadata));

  return await fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      Accept: '*/*',
    },
  });
};

export const fetchProcessingCSVs = async () => {
  const url = getProcessingCSVsUrl();
  return await apiFetch(url);
};

export const fetchConnections = async () => {
  const url = getConnectionsUrl();
  return await apiFetch(url);
};

export const createConnection = async (operation, source, connection) => {
  const url = getConnectionsUrl() + `?operation=${operation}&source=${source}`;
  return await apiFetch(url, 'POST', connection);
};

export const updateConnection = async (connectionUpdate) => {
  const url = getConnectionUrl(connectionUpdate.id);
  return await apiFetch(url, 'PUT', connectionUpdate);
};

export const deleteConnection = async (connectionId) => {
  const url = getConnectionUrl(connectionId);
  return await apiFetch(url, 'DELETE');
};

export const fetchAzureFiles = async (authID) => {
  const url = getAzureFilesUrl(authID);
  return await apiFetch(url);
};

export const fetchQualtricsSurveys = async (authID) => {
  const url = getQualtricsSurveysUrl(authID);
  return await apiFetch(url);
};

export const fetchProviderAuths = async () => {
  const url = getProviderAuthsUrl();
  return await apiFetch(url);
};

export const authenticateProvider = async (source, authBody) => {
  const url = getProviderAuthsUrl() + `?source=${source}`;
  return await apiFetch(url, 'POST', authBody);
};

export const updateProviderAuth = async (id, source, authBody) => {
  const url = getProviderAuthUrl(id) + `?source=${source}`;
  return await apiFetch(url, 'PUT', authBody);
};

export const deleteProviderAuth = async (id) => {
  const url = getProviderAuthUrl(id);
  return await apiFetch(url, 'DELETE');
};

export const fetchBrands = async () => {
  const url = getBrandsUrl();
  return await apiFetch(url);
};

export const fetchLanguages = async () => {
  const url = getLanguagesUrl();
  return await apiFetch(url);
};

export const fetchAuthProviders = async () => {
  const url = getAuthProvidersUrl();
  return await apiFetch(url);
};

export const fetchCustomers = async (dashboardID, dataSourceID) => {
  const url = getCustomersUrl(dashboardID, dataSourceID);
  return await apiFetch(url);
};

export const createLabel = async (dashboardID, dataSourceID, body) => {
  const url = getLabelsManagementUrl(dashboardID, dataSourceID);
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const renameLabel = async (dashboardID, dataSourceID, label, body) => {
  const url = getLabelsManagementUrl(dashboardID, dataSourceID, label);
  return await apiFetch(url, 'PUT', body, { withAbortRethrow: true });
};

export const deleteLabel = async (dashboardID, dataSourceID, label) => {
  const url = getLabelsManagementUrl(dashboardID, dataSourceID, label);
  return await apiFetch(url, 'DELETE', {}, { withAbortRethrow: true });
};

export const getRegenerationStatus = async (dashboardID, dataSourcesIDs, signal) => {
  const url = getRegenerationStatusUrl(dashboardID, dataSourcesIDs.map((ds) => `dataSourceID=${ds}`).join('&'));
  return await apiFetch(url, 'GET', null, { signal, withAbortRethrow: true });
};

export const startRegeneration = async (dashboardID, dataSourceID) => {
  const url = putRegeneratingUrl(dashboardID, dataSourceID);
  return await apiFetch(url, 'PUT');
};

export const assignTopicToResponse = async (dashboardID, datasourceID, responseID, body) => {
  const url = getReposnonseLabelsManagementUrl(dashboardID, datasourceID, responseID);
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const removeTopicFromResponse = async (dashboardID, datasourceID, responseID, body) => {
  const url = getReposnonseLabelsManagementUrl(dashboardID, datasourceID, responseID);
  return await apiFetch(url, 'DELETE', body, { withAbortRethrow: true });
};

export const createWorkspace = async (body) => {
  const url = getSignupWorkspaceUrl();
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};
