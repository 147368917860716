import React from 'react';
import AverageFilterBoxes from '../../../../UI/FiltersBars/AverageFilterBoxes';
import { FILTER_ID_ALL, FILTER_ID_AVERAGE, FILTER_NAME_AVERAGE, PERCENT_DECIMAL_NUM } from '../../../../../constants';
import { numberWithCommas } from '../../../../tools';
import { Filters } from '../../../../../types/Label';
import { Filter } from '../../../../../types/Query';
import { Number, BarContainer, Container, ContainerItem, Percent, PercentageBar, Bar } from './style';

const getAverage = (dataByFilterId: Filters) => {
  const [value, totalResponses, filtersCount] = Object.keys(dataByFilterId).reduce(
    ([value, totalResponses, filtersCount], filterId) => {
      if (filterId === FILTER_ID_ALL) {
        return [value, totalResponses, filtersCount];
      }
      const data = dataByFilterId[filterId];
      const responsesCount = data.realPercent === 0 ? 0 : (data.number / data.realPercent) * 100;
      return [value + data.number, totalResponses + responsesCount, filtersCount + 1];
    },
    [0, 0, 0],
  );

  return {
    [FILTER_ID_AVERAGE]: {
      number: filtersCount === 0 ? 0 : Math.round(value / filtersCount),
      realPercent: totalResponses === 0 ? 0 : (value / totalResponses) * 100,
    },
  };
};
interface IPercentageBars {
  dataByFilterId: Filters;
  filtersById: { [p: string]: Filter };
  selectedFilterIds: string[];
  average?: boolean;
}
const PercentageBars = ({ dataByFilterId, average, filtersById, selectedFilterIds }: IPercentageBars) => {
  if (!dataByFilterId) {
    return null;
  }
  const averageData = getAverage(dataByFilterId);
  const dataById = average ? averageData : dataByFilterId;
  const { number: avgNum, realPercent: avgPct } = averageData[FILTER_ID_AVERAGE];
  const showAverageMarker = Object.keys(dataById).filter((id) => id !== FILTER_ID_ALL).length > 1;
  const extendedFiltersById = {
    ...filtersById,
    [FILTER_ID_AVERAGE]: {
      name: FILTER_NAME_AVERAGE,
      color: filtersById[FILTER_ID_ALL].color,
    },
  } as { [key: string]: { name: string; color: string } };

  return (
    <Container>
      {Object.entries(dataById).map(([filterId, data]) => {
        return (
          <ContainerItem key={filterId}>
            <Percent data-testid='label-percentage'>
              {data.realPercent.toFixed(PERCENT_DECIMAL_NUM)}%{average ? <span>(Avg)</span> : null}
            </Percent>
            {average && (
              <div>
                <AverageFilterBoxes filtersById={filtersById} selectedFilterIds={selectedFilterIds} />
              </div>
            )}
            <BarContainer>
              <Bar
                data-tooltip-id='base-tooltip'
                data-tooltip-content={extendedFiltersById[filterId].name}
                style={{
                  position: 'relative',
                  backgroundColor: 'var(--grey-bar)',
                  borderRadius: 'var(--double-border-radius)',
                  border: `1px solid "var(--grey-bar)"`,
                }}
              >
                {showAverageMarker && filterId !== FILTER_ID_ALL && (
                  <div
                    data-tooltip-id='base-tooltip'
                    data-tooltip-content={`Average value: ${avgNum} (${avgPct.toFixed(PERCENT_DECIMAL_NUM)}%)`}
                    style={{
                      position: 'absolute',
                      top: '0',
                      height: '100%',
                      borderLeft: '2px dashed var(--white)',
                      left: `${avgPct}%`,
                    }}
                  />
                )}

                <PercentageBar $color={extendedFiltersById[filterId].color} $percent={data.realPercent} />
                <Number
                  data-cy='label-count'
                  data-tooltip-id='base-tooltip'
                  data-tooltip-content={`${extendedFiltersById[filterId].name}: ${numberWithCommas(data.number)}`}
                >
                  {numberWithCommas(data.number)}
                </Number>
              </Bar>
            </BarContainer>
          </ContainerItem>
        );
      })}
    </Container>
  );
};

export default PercentageBars;
