import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { AnswerBar, AnswerBarContainer } from '../../../components/UI';
import palette from '../../../components/tools/palette';

export const DeltaPlotWrapper = styled(Grid)`
  width: calc(50% - 8px);
  background-color: #fff;
  border-radius: var(--border-radius);
  padding: 16px;
`;

export const SegmentItemValueBarBackgroundGrid = styled(Grid)`
  padding-bottom: 16px;
  background-size: 25%, 100%;
  background-image: linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to left, lightgrey 1px, transparent 1px);
`;

export const SegmentItemValueBar = styled('div')`
  font-size: 13px;
  padding: 2px 0;
  border: 0;
`;

export const SegmentItemValueBarNegative = styled(SegmentItemValueBar)`
  float: right;
  background-color: #ebeff4;
  border-radius: 0.5em 0 0 0.5em;

  div {
    float: right;
    padding-right: 5px;
  }
`;

export const SegmentItemValueBarPositive = styled(SegmentItemValueBar)`
  background-color: #9ca6b5;
  border-radius: 0 0.5em 0.5em 0;

  div {
    padding-left: 5px;
  }
`;
export const SegmentAnswerBarBackgroundGrid = styled(Grid)`
  background-size: 20%, 100%;
  background-image: linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to left, lightgrey 1px, transparent 1px);
  padding-bottom: 16px;
`;

export const SegmentAnswerBarContainer = styled(AnswerBarContainer)`
  width: calc(100%);
  border-radius: 0;
  margin-left: 0;
  border: 0;
`;

export const SegmentAnswerBar = styled(AnswerBar)`
  border-radius: 0;
`;

export const SegmentHeader = styled('h3')`
  padding: 0;
  margin: 0 0 16px 0;
  font-size: 22px;
  font-weight: 300;
`;
export const SegmentItem = styled('div')`
  border-width: 1px;
  border-style: solid;
  border-color: ${palette.dividerColor};
  border-radius: 8px;
  background-color: ${palette.linkColorLight3};
  padding: 16px;
`;
export const SegmentWrapper = styled('div')`
  width: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  text-align: left;
`;
