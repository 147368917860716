import React, { MutableRefObject } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import { Config } from '../../../../types/Config';
import { WidgetDataSource } from '../../../../types/DataSource';
import { BreakdownLabel, Label } from '../../../../types/Label';
import { Filter } from '../../../../types/Query';
import BreakdownLabelsList from './LabelsList/BreakdownLabelsList';
import ResponsesList from './ResponsesList/ResponsesListContainer';
import { FETCH_STATUS, FETCH_STATUS_TYPE } from '../../../../constants';
import LoadingOverlay from '../../../UI/LoadingOverlay';
import StatusPanelContainer, { SentimentSummary } from '../StatusPanel/StatusPanelContainer';
import { Concept } from '../../../../store/reducers/aiInsights';
import { ResponsesSorting } from '../../../../store/reducers/openEndedWidget';
import { ReactComponent as SparkleIcon } from '../../../../assets/icons/sparkle-icon.svg';

import HierarchyLabelsList from './LabelsList/HierarchyLabelsList';
import AIInsightsContainer from './AIInsights/AIInsightsContainer';
import SegmentsRowContainer from '../../components/SegmentsRow/SegmentsRowContainer';
import {
  FilteringButtonsAndContentContainer,
  InsightsHeader,
  InsightsTitleContainer,
  ItemContainer,
  ItemSecondaryTitle,
  ItemTitleContainer,
  ResponsesWidgetContainer,
} from './style';
import { TextOverflowEllipsis } from '../../../UI';

export type TotalsByDataSourcesAndFiltersIDS = {
  [key: string]: AllFilterID;
};

export type AllFilterID = {
  base: number;
  weighted: number;
};

const SparkleIconStyled = styled(SparkleIcon)`
  width: 13px;
  height: 14px;
`;

interface IOpenEndedWidgetData {
  analyzedAnswers: number;
  areFiltersEmpty: boolean;
  config: Config;
  dataSource: WidgetDataSource;
  dataSourceID: string;
  filters: Filter[];
  filtersById: { [p: string]: Filter };
  hasResponses: boolean;
  hasSentiment: boolean;
  hiddenLabels: string[];
  isBreakdownView: boolean;
  isPreview: boolean;
  labels: Label[];
  labelToParent: Map<string, string>;
  loading: FETCH_STATUS_TYPE;
  responsesCount: number;
  responsesLoading: boolean;
  selectedFilterIds: string[];
  selectedItems: string[];
  selectedLabelItem: Concept | null;
  sentimentSummary: SentimentSummary;
  showResponses: boolean;
  totalAnswers: number;
  totalsByDataSourcesAndFiltersIds: Record<string, TotalsByDataSourcesAndFiltersIDS>;
  totalsLoading: boolean;
  totalWeightedAnswers: number;
  average?: boolean;
  breakdownLabels?: BreakdownLabel[];
  onExpand: () => void;
  onFeedbackClick: (title: string, widgetTitle: string, displayAnswerText?: string) => void;
  onFilterButtonChange: (ids: string[]) => void;
  onLabelClick: (label: string) => void;
  onRefContent?: MutableRefObject<HTMLDivElement> | null;
  onWidgetFiltersClearClick: () => void;
  onLabelExpand: () => void;
  onLoadMoreResponses: () => void;
  onGenerateInsightsClick: (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => void;
  onResponsesSortingChange: (sorting: ResponsesSorting) => void;
  onResponsesBookmarkedToggle: () => void;
}

function OpenEndedWidgetData({
  analyzedAnswers,
  areFiltersEmpty,
  average,
  breakdownLabels,
  config,
  dataSource,
  dataSourceID,
  filters,
  filtersById,
  hasResponses,
  hasSentiment,
  hiddenLabels,
  isBreakdownView,
  isPreview,
  labels,
  labelToParent,
  loading,
  onExpand,
  responsesCount,
  responsesLoading,
  selectedFilterIds,
  selectedItems,
  selectedLabelItem,
  sentimentSummary,
  showResponses,
  totalAnswers,
  totalsByDataSourcesAndFiltersIds,
  totalsLoading,
  totalWeightedAnswers,
  onRefContent,
  onFeedbackClick,
  onFilterButtonChange,
  onLabelClick,
  onLabelExpand,
  onWidgetFiltersClearClick,
  onLoadMoreResponses,
  onGenerateInsightsClick,
  onResponsesSortingChange,
  onResponsesBookmarkedToggle,
}: IOpenEndedWidgetData) {
  if (!hasResponses) {
    return (
      <>
        <Grid item style={{ flex: 1, display: 'inherit' }}>
          <Grid container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
            <Grid
              item
              style={{
                color: '#8b92a6',
                padding: '0 30px 20px',
                textAlign: 'center',
              }}
            >
              This widget doesn't have any responses yet. We'll message you when someone responds.
            </Grid>
          </Grid>
        </Grid>
        {renderLoading()}
      </>
    );
  }

  return (
    <>
      <Grid item style={!config.settings.showStatus ? { display: 'none' } : { marginBottom: '16px' }}>
        <StatusPanelContainer
          analyzedCount={analyzedAnswers}
          answeredCount={totalAnswers}
          sentimentSummary={sentimentSummary}
          hasSentiment={hasSentiment}
          weightedTotal={totalWeightedAnswers}
        />
      </Grid>
      <Grid item style={{ display: 'flex', flex: '1' }} ref={onRefContent}>
        <FilteringButtonsAndContentContainer>
          {renderFilteringButtons()}
          <ResponsesWidgetContainer>
            {renderLabels()}
            {renderResponses()}
            {renderInsights()}
          </ResponsesWidgetContainer>
        </FilteringButtonsAndContentContainer>
      </Grid>
      {renderLoading()}
    </>
  );

  function renderLoading() {
    if (loading !== FETCH_STATUS.LOADING) {
      return null;
    }

    return <LoadingOverlay />;
  }

  function renderFilteringButtons() {
    if (isPreview || !config.settings.showFilters) {
      return null;
    }

    return (
      <Grid item style={{ width: '100%' }}>
        <SegmentsRowContainer
          onChange={onFilterButtonChange}
          value={selectedFilterIds}
          filters={filters}
          disabled={responsesLoading && !!config.settings.showResponses}
          totalsPerFilter={totalsByDataSourcesAndFiltersIds[dataSourceID]}
          loading={totalsLoading}
          widgetTitle={config.base.title}
        />
      </Grid>
    );
  }

  function renderLabels() {
    if (areFiltersEmpty) {
      return null;
    }

    const Component = isBreakdownView ? BreakdownLabelsList : HierarchyLabelsList;
    return (
      <ItemContainer>
        <ItemTitleContainer>Categorization</ItemTitleContainer>
        <Component
          average={average}
          clickable={showResponses}
          disabled={responsesLoading}
          filtersById={filtersById}
          labels={isBreakdownView ? breakdownLabels : labels}
          onLabelClick={onLabelClick}
          onLabelExpand={onLabelExpand}
          selectedFilterIds={selectedFilterIds}
          selectedLabelItem={selectedLabelItem}
        />
      </ItemContainer>
    );
  }

  function renderResponses() {
    return (
      <ItemContainer>
        {renderTitle()}
        <ResponsesList
          config={config}
          hiddenConcepts={hiddenLabels}
          labels={labels}
          conceptToParent={labelToParent}
          selectedConcepts={selectedItems}
          selectedConcept={selectedLabelItem}
          onFeedbackClick={onFeedbackClick}
          onLabelClick={onLabelClick}
          onLoadMoreResponses={onLoadMoreResponses}
          onSortingChange={onResponsesSortingChange}
          onBookmarkedToggle={onResponsesBookmarkedToggle}
        />
      </ItemContainer>
    );
    function renderTitle() {
      let conceptPart: JSX.Element | null = null;
      if (selectedLabelItem) {
        conceptPart = (
          <ItemSecondaryTitle title={selectedLabelItem.title}>
            {' '}
            / <TextOverflowEllipsis>{selectedLabelItem.title}</TextOverflowEllipsis>
          </ItemSecondaryTitle>
        );
      }

      return <ItemTitleContainer>Responses{conceptPart}</ItemTitleContainer>;
    }
  }

  function renderInsights() {
    let selectedConcept;
    if (selectedLabelItem?.title) {
      selectedConcept = {
        title: selectedLabelItem.title,
        isEnabled: true,
      };
    } else {
      selectedConcept = undefined;
    }

    return (
      <ItemContainer>
        <InsightsHeader>
          <InsightsTitleContainer>
            AI Insights <SparkleIconStyled />
          </InsightsTitleContainer>
        </InsightsHeader>
        <AIInsightsContainer
          widgetID={config.base.id ?? 'preview'}
          dataSource={dataSource}
          selectedConcept={selectedConcept}
          conceptsFilteredInWidgetSettings={selectedItems}
          onFiltersClearClick={onWidgetFiltersClearClick}
          onGenerateInsightsClick={onGenerateInsightsClick}
        />
      </ItemContainer>
    );
  }
}

export default OpenEndedWidgetData;
