import { TRENDS_DISPLAY_MODE } from '../Widgets/TrendsWidget/Settings/TrendsWidgetSettings';
import { OverviewItemModel } from '../../models/Overview';
import { PERIOD, WIDGET_TYPE } from '../../constants';

export const prepareTrendsWidgetConfig = (config) => {
  let dataSources = config.settings.dataSources;

  return {
    ...config,
    base: {
      ...config.base,
      type: WIDGET_TYPE.TRENDS,
      expanded: false,
    },
    settings: {
      ...config.settings,
      dataSources,
      displayMode: TRENDS_DISPLAY_MODE.PERCENTAGE,
      aggregationRange: PERIOD.MONTH,
    },
  };
};

export const prepareBarsWidgetConfig = (config, dataSource, dashboardDataSource) => {
  const isOpenEnded = dashboardDataSource.type === OverviewItemModel.TYPE_OPEN_ENDED;
  const settings = {
    dataSources: config.settings.dataSources,
  };

  if (isOpenEnded) {
    settings.detailed = true;
    settings.showFilters = true;
    settings.showResponses = true;
    settings.showStatus = true;
  }

  return {
    ...config,
    settings,
    base: {
      ...config.base,
      type: isOpenEnded ? WIDGET_TYPE.OPEN_ENDED : WIDGET_TYPE.BARS,
    },
  };
};
