import Landing from './Landing';

const LandingError = ({ debug }) => {
  const text = (
    <>
      Something went wrong.
      <br />
      It has been reported, and we are
      <br />
      looking into it. Stay tuned!
    </>
  );

  return <Landing title='Sorry!' text={text} debug={debug} />;
};

export default LandingError;
