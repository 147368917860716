import { useState } from 'react';

const useOAuthRedirect = (redirect: (state: string) => string | undefined, name: string) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = (state: string) => {
    return new Promise<boolean>((resolve, _) => {
      const url = redirect(state);
      if (!url) {
        resolve(false);
        return;
      }

      const oauthWindow = window.open(url, name, 'width=600,height=600');
      if (oauthWindow) {
        setIsOpen(true);
        const interval = setInterval(function () {
          if (oauthWindow.closed) {
            setIsOpen(false);
            clearInterval(interval);
            resolve(false);
            return;
          }
          try {
            if (oauthWindow.location.href.includes('oauth')) {
              if (oauthWindow.location.href.includes('error')) {
                clearInterval(interval);
                oauthWindow.close();
                setIsOpen(false);
                resolve(false);
              }
              if (oauthWindow.location.href.includes('success')) {
                clearInterval(interval);
                oauthWindow.close();
                setIsOpen(false);
                resolve(true);
              }
            }
          } catch {
            // different origin, and we can't access its location.
          }
        }, 1000);
      }
    });
  };

  return {
    isOpen,
    open,
  };
};

export default useOAuthRedirect;
