import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const CustomSelect = styled(Select)`
  &.MuiInputBase-root {
    background-color: var(--white);
    font-size: 14px;
    min-width: 160px;
    height: 100%;
    border-radius: var(--double-border-radius);
  }

  &.MuiSelect-root {
    background-color: var(--white);
  }

  .MuiSelect-select {
    padding: 3.5px 24px 3.5px 6px;
    &:focus {
      background-color: var(--white);
    }
  }

  fieldset {
    border-color: var(--medium-gray);
  }
`;
export const CompactMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 6px;
    font-size: 12px;
  }
`;
export const CustomAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    border-radius: var(--double-border-radius);
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    padding: 0;
    width: 100%;

    & .MuiTextField-root {
      height: 100%;
      border-radius: var(--double-border-radius);

      & .MuiInputBase-root {
        height: 100%;
      }
    }
  }

  &&& .MuiAutocomplete-paper {
    color: red;
    font-size: 12px;
  }
`;
export const FilteringTextField = styled(TextField)`
  background-color: var(--white);

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input {
    font-size: 12px;
    padding: 6px 0 6px 2px;
    box-sizing: border-box;
    height: 100%;
  }

  &&& .MuiInputBase-root {
    flex-wrap: nowrap;
    padding: 0;
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 6px;
  }

  fieldset {
    border-color: var(--medium-gray);
    border-radius: var(--double-border-radius);
  }
`;
