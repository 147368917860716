import { useContext, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteConnection } from '../../../store/thunks/connectors';
import { NOTIFICATIONS } from '../../Notification/constants';
import { SEVERITY } from '../../Notification/types';
import { NotificationsContext } from '../../Notification/NotificationsContext';
import { clearLastDeletedConnection, closeDeleteConnectionModal } from '../../../store/actions/connectors';

import DeleteConnectionModal from './DeleteConnectionModal';

function DeleteConnectionModalContainer() {
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const {
    deleteConnection: { submitting: isSubmitting, open: isOpen, lastDeletedId, lastDeletedSuccess },
  } = useAppSelector((state) => state.connectors);
  const dispatch = useAppDispatch();

  function onDelete() {
    dispatch(deleteConnection());
  }

  useEffect(() => {
    if (!lastDeletedId) {
      return;
    }

    if (lastDeletedSuccess) {
      removeNotification(NOTIFICATIONS.ConnectorsConnectionDeleteSuccess);
      addNotification({
        id: NOTIFICATIONS.ConnectorsConnectionDeleteSuccess,
        title: 'Connection deleted successfully.',
        severity: SEVERITY.SUCCESS,
        persistent: false,
      });
    } else {
      addNotification({
        id: NOTIFICATIONS.ConnectorsConnectionDeleteError,
        title: 'Failed to delete connection.',
        severity: SEVERITY.ERROR,
        persistent: true,
      });
    }
    dispatch(clearLastDeletedConnection());
  }, [addNotification, dispatch, lastDeletedId, lastDeletedSuccess, removeNotification]);

  function onClose() {
    dispatch(closeDeleteConnectionModal());
  }

  return <DeleteConnectionModal isOpen={isOpen} isSubmitting={isSubmitting} onClose={onClose} onDelete={onDelete} />;
}

export default DeleteConnectionModalContainer;
