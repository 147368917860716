import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import { FormControlLabel, Radio, RadioGroup, Paper } from '@material-ui/core';
import styled from 'styled-components';

import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { setColorSchemeName } from '../../../store/actions/actionsCreators';
import { availableColorSchemes, getSchemeColors } from '../../../style/colors';
import SidebarItemWithOverlay from './SidebarItemWithOverlay';

const PaletteLabel = ({ name, count }) => {
  const colors = getSchemeColors(name, count).reverse();

  return (
    <div style={{ height: '100%', display: 'flex' }}>
      {colors.map((color, i) => (
        <div key={color + i} style={{ marginLeft: '2px', backgroundColor: color, flexGrow: 1 }} />
      ))}
    </div>
  );
};

const PaletteFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    height: 20px;
    width: 100%;
  }
`;

const SidebarItemColorPalette = ({ index }) => {
  const dispatch = useDispatch();
  const { colorSchemeName } = useSelector((state) => state.app);

  const handleColorSchemeChange = useCallback(
    (event) => {
      const colorSchemeName = event.target.value;
      eventsTracker.track(EVENTS.TOGGLE_COLOR_PALETTE, {
        'Scheme name': colorSchemeName,
      });
      dispatch(setColorSchemeName(colorSchemeName));
    },
    [dispatch],
  );

  return (
    <SidebarItemWithOverlay icon={<PaletteOutlinedIcon />} tooltip='Color Palette' index={index}>
      <Paper
        style={{
          overflow: 'auto',
          padding: '10px',
          width: '200px',
          maxHeight: '500px',
        }}
      >
        <RadioGroup onChange={handleColorSchemeChange} value={colorSchemeName}>
          {availableColorSchemes.map((schemeName) => (
            <PaletteFormControlLabel
              key={schemeName}
              value={schemeName}
              control={<Radio />}
              label={<PaletteLabel name={schemeName} count={7} />}
            />
          ))}
        </RadioGroup>
      </Paper>
    </SidebarItemWithOverlay>
  );
};

export default SidebarItemColorPalette;
