import { ChangeEvent } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { StyledFormControl } from './style';

interface IConnectionBrandSelect {
  brands: { id: string; name: string }[];
  selectedBrand: string;
  onSelectBrand: (brand: string) => void;
}

function ConnectionBrandSelect({ brands, selectedBrand, onSelectBrand }: IConnectionBrandSelect) {
  function handleSelectBrand(e: ChangeEvent<{ value: unknown }>) {
    onSelectBrand(e.target.value as string);
  }

  if (brands.length <= 1) {
    return null;
  }

  return (
    <StyledFormControl>
      <InputLabel id='brand-select-label'>Select Brand</InputLabel>
      <Select data-testid='connection-brand-select' value={selectedBrand} labelId='brand-select-label' onChange={handleSelectBrand}>
        {brands.map((brand) => (
          <MenuItem value={brand.id} key={brand.id}>
            {brand.name}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

export default ConnectionBrandSelect;
