import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { WidgetMessageContent } from '../UI';
import useFormatLogMessage from '../../hooks/useFormatLogMessage';
import { LOG_MESSAGE_LEVEL, LOG_MESSAGE_TYPE } from '../../constants';
import { getTestsLinks } from '../tools';

const WidgetErrorContent = ({ config }) => {
  const links = getTestsLinks();
  const formatLogMessage = useFormatLogMessage();

  const lines = [
    `[${LOG_MESSAGE_LEVEL.ERROR}]`,
    'Invalid widget configuration',
    `Widget: ${config.base.title}`,
    `Widget ID: ${config.base.id}`,
    `Data sources: ${config.settings.dataSources.map((dataSource) => dataSource.id).join(', ')}`,
  ];
  console.log(lines.join('\n'));

  const message = formatLogMessage('Invalid widget configuration');
  datadogLogs.logger.error(message, {
    type: LOG_MESSAGE_TYPE.INVALID_WIDGET_CONFIGURATION,
    unsafeLink: links.unsafe,
    debugLink: links.debug,
  });

  return <WidgetMessageContent>Oops, something went wrong. We're working on it! Please come back later.</WidgetMessageContent>;
};

export default React.memo(WidgetErrorContent);
