import { CONNECTORS_COLUMN } from '../types';

export const COLUMNS: Record<string, CONNECTORS_COLUMN> = {
  NAME: {
    width: '23%',
    align: 'left',
  },
  TYPE: {
    width: '5%',
    align: 'right',
  },
  NEXT_RUN: {
    width: '15%',
    align: 'right',
  },
  LAST_RUN: {
    width: '17%',
    align: 'right',
  },
  SCHEDULE: {
    width: '15%',
    align: 'center',
  },
  STATUS: {
    width: '15%',
    align: 'center',
  },
  EDIT: {
    width: '5%',
    align: 'center',
  },
  DELETE: {
    width: '5%',
    align: 'center',
  },
};
