import React from 'react';
import { CommonWidgetContainer } from '../dashboard/style';
import { useSelector } from 'react-redux';
import OverviewWidget from './OverviewWidget/OverviewWidget';
import OpenEndedWidget from './OpenEndedWidget/OpenEndedWidget';
import InterviewWidget from './InterviewWidget/InterviewWidget';
import BarsWidget from './BarsWidget/BarsWidget';
import TrendsWidget from './TrendsWidget/TrendsWidget';
import { highlightService } from '../../services/highlight';
import { WIDGET_TYPE } from '../../constants';

const Widget = ({ id, onDimensionsChange, isNew, groupTitle }) => {
  const config = useSelector((state) => state.dashboard.widgets.find((widget) => widget.base.id === id));
  let WidgetComponent;
  let isWrapped = false;
  switch (config.base.type) {
    case WIDGET_TYPE.OVERVIEW:
      WidgetComponent = OverviewWidget;
      isWrapped = true;
      break;
    case WIDGET_TYPE.OPEN_ENDED:
      WidgetComponent = OpenEndedWidget;
      break;
    case WIDGET_TYPE.INTERVIEW:
      WidgetComponent = InterviewWidget;
      break;
    case WIDGET_TYPE.BARS:
      WidgetComponent = BarsWidget;
      break;
    case WIDGET_TYPE.TRENDS:
      WidgetComponent = TrendsWidget;
      break;
    default:
      WidgetComponent = ({ config }) => <>widget: {config.base.title}</>;
  }

  const dimensionsChangeHandle = onDimensionsChange.bind(this, id);

  if (isNew) {
    highlightService.add(`w-${config.base.id}`);
  }

  return isWrapped ? (
    <WidgetComponent config={config} onDimensionsChange={dimensionsChangeHandle} groupTitle={groupTitle} />
  ) : (
    <CommonWidgetContainer item style={{ minWidth: '0', height: '100%' }}>
      <WidgetComponent config={config} onDimensionsChange={dimensionsChangeHandle} groupTitle={groupTitle} />
    </CommonWidgetContainer>
  );
};

export default React.memo(Widget);
