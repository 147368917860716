import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import TrendsWidgetChartWrapper from '../TrendsWidgetChartWrapper';
import useTrendsBreakdownFetch from '../../../../hooks/fetch/useTrendsBreakdownFetch';
import { FETCH_STATUS } from '../../../../constants';

const TrendsWidgetContentBreakdown = ({
  config,
  filters,
  dashboardId,
  dataSourceId,
  aggregationRange,
  breakdownFilter,
  selectedItems,
  refCsvExport,
  refContent,
  refFiltersCount,
  query,
}) => {
  const skipFetching = config.base.collapsed;
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);
  const { responses, status } = useTrendsBreakdownFetch(
    dashboardId,
    filters,
    dataSourceId,
    selectedItems,
    aggregationRange,
    breakdownFilter,
    skipFetching,
    query,
  );

  const breakdownIndexes = useMemo(() => {
    const isOrdered = dashboardDataSources.find(
      (dashboardDataSource) => dashboardDataSource.dataSourceID === breakdownFilter.dataSourceID,
    ).ordered;
    const items = [...dataSources.find((dataSource) => dataSource.dataSourceID === breakdownFilter.dataSourceID).items];
    items.sort((a, b) => (isOrdered ? 1 : a.value - b.value));
    return items.reduce((acc, item, index) => {
      return {
        ...acc,
        [item.response]: index,
      };
    }, {});
  }, [breakdownFilter, dataSources, dashboardDataSources]);

  const plainResponses = [];
  if (status === FETCH_STATUS.LOADED) {
    for (let response of responses) {
      const sortedBuckets = [...response.buckets].sort((a, b) => breakdownIndexes[a.value] - breakdownIndexes[b.value]);
      for (let bucket of sortedBuckets) {
        plainResponses.push({
          filterId: response.filter.id,
          filterName: response.filter.name,
          bucketName: bucket.value,
          items: bucket.items,
        });
      }
    }
  }

  return (
    <TrendsWidgetChartWrapper
      config={config}
      loading={status !== FETCH_STATUS.LOADED}
      plainResponses={plainResponses}
      breakdownFilter={breakdownFilter}
      filters={filters}
      refCsvExport={refCsvExport}
      refContent={refContent}
      refFiltersCount={refFiltersCount}
    />
  );
};

export default TrendsWidgetContentBreakdown;
