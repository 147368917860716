import React from 'react';
import styled from 'styled-components';

import { ReactComponent as NotificationIcon } from '../../assets/icons/notification-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/cross-transparent-icon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle-icon.svg';

import { SEVERITY } from './types';

type NotificationProps = {
  severity: SEVERITY;
  title: string;
  subtitle?: string;
  position?: {
    right?: string;
    top?: string;
  };
  onClose: () => void;
};

const Container = styled.div<{ top: string }>`
  position: fixed;
  top: ${(props) => props.top};
  width: 100%;
  overflow: hidden;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  background-color: var(--transparent);
  pointer-events: none;
`;

const NotificationBox = styled.div<{ right: string; color: string }>`
  background-color: ${(props) => props.color};
  display: flex;
  padding: 8px 16px;
  max-width: 400px;
  width: 400px;
  margin-right: ${(props) => props.right};
  border-radius: 8px;
  pointer-events: auto;
`;

const Message = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: ${(props) => props.color};
  margin-left: 8px;
  margin-right: 12px;
  flex: 1;
  justify-content: center;
  height: max-content;
  min-height: 26px;
`;

const Title = styled.div`
  font-weight: 500;
`;

const StyledIconButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const IconContainer = styled.div<{ $color: string }>`
  padding: 3px;
  & svg {
    width: 20px;
    height: 20px;
    fill: ${(props) => props.$color};
  }
`;

const CloseIconStyled = styled(CloseIcon)<{ $color: string }>`
  width: 10px;
  height: 10px;
  fill: ${(props) => props.$color};
`;

const getBoxColor = (severity: SEVERITY) => {
  switch (severity) {
    case SEVERITY.INFO:
      return 'var(--light-purple-50-transparency)';
    case SEVERITY.WARNING:
      return 'var(--light-yellow)';
    case SEVERITY.ERROR:
      return 'var(--red-50-transparency)';
    case SEVERITY.SUCCESS:
      return 'var(--green-50-transparency)';
    default:
      return '';
  }
};

const getTextColor = (severity: SEVERITY) => {
  switch (severity) {
    case SEVERITY.INFO:
      return 'var(--dark-purple)';
    case SEVERITY.WARNING:
    case SEVERITY.ERROR:
    case SEVERITY.SUCCESS:
    default:
      return 'var(--text-primary)';
  }
};

interface IIcon {
  severity: SEVERITY;
}
const Icon = ({ severity }: IIcon) => {
  switch (severity) {
    case SEVERITY.INFO:
      return <NotificationIcon />;
    case SEVERITY.WARNING:
      return <WarningIcon />;
    case SEVERITY.ERROR:
      return <ErrorIcon />;
    case SEVERITY.SUCCESS:
      return <CheckIcon />;
    default:
      return null;
  }
};

export default function Notification({ title, subtitle, severity, position, onClose }: NotificationProps) {
  const right = position?.right || '8px';
  const top = position?.top || '64px';
  const boxColor = getBoxColor(severity);
  const textColor = getTextColor(severity);

  return (
    <Container top={top}>
      <NotificationBox right={right} color={boxColor}>
        <IconContainer $color={textColor}>
          <Icon severity={severity} />
        </IconContainer>
        <Message color={textColor}>
          {subtitle ? (
            <>
              <Title>{title}</Title>
              <div>{subtitle}</div>
            </>
          ) : (
            <div>{title}</div>
          )}
        </Message>
        <StyledIconButton onClick={onClose}>
          <CloseIconStyled $color={textColor} />
        </StyledIconButton>
      </NotificationBox>
    </Container>
  );
}
