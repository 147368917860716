import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import HttpsApp from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import fetchIntercept from 'fetch-intercept';
import { Auth0Provider } from '@auth0/auth0-react';
import { API_BASE_URL } from './constants';
import CONFIG from './config';

WebFont.load({
  google: {
    families: ['Rubik', 'Material+Icons'],
  },
});

fetchIntercept.register({
  response: function (response) {
    if (response.status === 401 || response.status === 403) {
      window.location.href = API_BASE_URL;
    }
    return response;
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={CONFIG.AUTH0.DOMAIN}
    clientId={CONFIG.AUTH0.CLIENT_ID}
    audience={CONFIG.AUTH0.AUDIENCE}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation='localstorage'
  >
    <HttpsApp />
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
