import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import palette from '../tools/palette';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';

const ContentWrapper = styled('div')`
  transition: 0.7s;
  background-color: transparent;
  border-radius: var(--border-radius);

  &:hover {
    background-color: ${palette.linkColorLight4};
  }
`;

const SaveButton = styled(IconButton)`
  height: 40px;
  width: 40px;

  .MuiSvgIcon-root {
    color: ${palette.primaryColorDark};
  }

  .MuiSvgIcon-root:hover {
    ${palette.primaryColorDarkHover};
  }
`;
export const FilterNameInput = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiInputLabel-root {
    color: ${palette.primaryColorDark};
    border-color: ${palette.primaryColorDark};
  }

  fieldset {
    margin-top: -2px;
    height: 43px;
    top: -3px;
    border: 1px solid ${palette.primaryColorDark};
  }

  .MuiInputBase-root.Mui-focused fieldset {
    height: 43px;
    top: -3px;
    border: 1px solid ${palette.primaryColorDark};
  }
`;

const STATUS_EDIT = '1';
const STATUS_IDLE = '2';

interface IRenameField {
  label: string;
  onSaveData: (name: string) => void;
}
const RenameField = ({ label, onSaveData }: IRenameField) => {
  const [status, setStatus] = useState(STATUS_IDLE);
  const [value, setValue] = useState('');

  function handleSaveClick(e: SyntheticEvent) {
    e.stopPropagation();
    e.preventDefault();
    setStatus(STATUS_IDLE);
    onSaveData(value);
  }

  function handleStatusChange(newStatus: string) {
    setStatus(newStatus);
  }

  function handleFilterNameKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === 'Enter') {
      handleSaveClick(e);
    }
  }

  function handleFilterNameChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setValue(e.target.value);
  }

  if (status === STATUS_IDLE) {
    return <ContentWrapper onClick={handleStatusChange.bind(this, STATUS_EDIT)}>{value ? value : label}</ContentWrapper>;
  }

  return (
    <>
      <FilterNameInput
        value={value ? value : label}
        onChange={handleFilterNameChange}
        onKeyDown={handleFilterNameKeyDown}
        variant='outlined'
        size='small'
      />
      <SaveButton onClick={handleSaveClick} aria-label='delete' size='small'>
        <DoneIcon />
      </SaveButton>
    </>
  );
};

export default RenameField;
